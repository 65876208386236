import { Button, Card, Col, Descriptions, Row, Space, Typography } from "antd"
import { policyholderMap } from "models/policyholder"
import { ICompany, IPolicyholder } from "platform-client/types"
import { IActiveProduct } from "../ChooseProduct"

const ReviewCreateOpportunityView = ({
  product,
  policyholder,
  broker,
  isLoading = false,
  isQuickQuote = false,
  onCreate
}: IReviewCreateOpportunityViewProps): JSX.Element => {
  return (
    <Row>
      <Col span={10} offset={7} style={{ padding: "50px 0" }}>
        <Space direction="vertical" size={15} style={{ width: "100%" }}>
          <Typography.Title level={3} style={{ marginBottom: "0" }}>
            {isQuickQuote ? "Quick quote summary" : "Opportunity summary"}
          </Typography.Title>
          <Typography.Paragraph style={{ marginBottom: "0" }}>
            Please review below and click create to continue
          </Typography.Paragraph>
          {product && (
            <Card title="Product">
              <Card.Meta
                style={{ marginBottom: "10px" }}
                title={product.label}
                description={product.productType}
              />
              {product.desc}
            </Card>
          )}
          {policyholder && (
            <Card title="Policyholder">
              <Card.Meta
                style={{ marginBottom: "10px" }}
                title={policyholder.legalName}
                description={policyholderMap[policyholder.policyholderType]}
              />
              <Descriptions size="small" column={1}>
                {policyholder.businessName && (
                  <Descriptions.Item label="Business name">
                    {policyholder.businessName || ""}
                  </Descriptions.Item>
                )}
                {policyholder.contact.name.firstName && (
                  <Descriptions.Item label="Contact name">
                    {policyholder.contact.name.firstName}{" "}
                    {policyholder.contact.name.lastName}
                  </Descriptions.Item>
                )}
                {policyholder.contact.phoneNumber && (
                  <Descriptions.Item label="Phone number">
                    {policyholder.contact.phoneNumber}
                  </Descriptions.Item>
                )}
                {policyholder.contact.emailAddress && (
                  <Descriptions.Item label="Email address">
                    {policyholder.contact.emailAddress}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Card>
          )}
          {broker && (
            <Card title="Assigned to">
              <Card.Meta
                style={{ marginBottom: "10px" }}
                title={broker.name}
                description={broker.companyType}
              />
            </Card>
          )}

          <div>
            <Button
              type="primary"
              size="large"
              style={{ width: "100%" }}
              loading={isLoading}
              onClick={onCreate}
            >
              Create {isQuickQuote ? "quick quote" : "opportunity"}
            </Button>
          </div>
        </Space>
      </Col>
    </Row>
  )
}

interface IReviewCreateOpportunityViewProps {
  product: IActiveProduct
  policyholder?: IPolicyholder
  broker: ICompany
  isLoading: boolean
  isQuickQuote: boolean
  onCreate: () => void
}

export default ReviewCreateOpportunityView
