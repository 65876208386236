import React from "react"
import { Input } from "antd"
import styled from "styled-components"
import CellContentText from "../Text"

const StyledInput = styled(Input)`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 30px;
  padding: 0 5px;
  font-size: 14px;
  border: 1px solid #d9d9d9;
`

const CellContentTextInput: React.FC<ITextInput> = ({
  name,
  id,
  value,
  disabled,
  onChange,
  onBlur,
  placeholder,
  readOnly = false
}): JSX.Element => {
  if (readOnly) {
    return <CellContentText>{value}</CellContentText>
  }

  return (
    <StyledInput
      name={name}
      aria-label="text-input"
      id={id}
      autoFocus
      disabled={disabled}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      placeholder={placeholder}
    />
  )
}

interface ITextInput {
  name: string
  id?: string
  value: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  disabled?: boolean
  error?: boolean
  warning?: boolean
  placeholder?: string
  readOnly?: boolean
}

export default CellContentTextInput
