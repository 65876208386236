import { useState, useRef, useEffect, useCallback } from "react"
import useOpus from "contexts/opus/hooks/use-opus"
import { notification } from "antd"

export const useDownloadReceipt = (
  policyID: string | undefined
): IUseSetPolicyRenewals => {
  const [isDownloading, setIsDownloading] = useState(false)
  const [isError, setIsError] = useState(false)

  const isMounted = useRef(true)
  const { policyClient } = useOpus()

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const downloadReceipt = useCallback(
    async (paymentId) => {
      if (isDownloading) return

      setIsDownloading(true)

      try {
        if (policyID) {
          const a = document.createElement("a")
          a.style.display = "none"
          document.body.appendChild(a)

          const response = await policyClient.downloadTransactionReceipt(
            policyID,
            paymentId
          )
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          )
          a.href = url
          a.download = response.headers["x-filename"]
          a.click()
          window.URL.revokeObjectURL(url)
          notification.success({
            message:
              "Receipt downloaded successfully. Please check your downloads folder."
          })
        }
      } catch (error) {
        notification.error({
          message:
            "An error occurred when attempting to download the transaction receipt for this payment. Please try again, or contact support."
        })
        setIsError(true)
      }

      setIsDownloading(false)

      if (isMounted.current) setIsDownloading(false)
    },
    [isDownloading, policyClient, policyID]
  )

  return { downloadReceipt, isDownloading, isError }
}

interface IUseSetPolicyRenewals {
  downloadReceipt: (paymentId: string) => Promise<void>
  isDownloading: boolean
  isError: boolean
}
