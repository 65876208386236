import { Nullable } from "./generics"
import { IModifierOwner } from "./policy"

export type EndorsementDetails = Omit<
  IEndorsementDefinition,
  "id" | "version" | "versionReferenceID"
>

export interface IEndorsementController {
  listEndorsements: (
    schemeReferenceID: Nullable<string>,
    sectionReferenceID?: Nullable<string>
  ) => Promise<IEndorsementDefinition[]>

  /**
   * TODO
   * These need to be typed correctly
   */

  createEndorsement: (data: EndorsementDetails) => Promise<unknown>
  updateEndorsement: (
    endorsementID: string,
    data: EndorsementDetails
  ) => Promise<unknown>
  deleteEndorsement: (endorsementID: string) => Promise<unknown>
  createEndorsementVersion: (
    referenceID: string,
    data: unknown
  ) => Promise<unknown>
}

export type EndorsementType = IEndorsement | IEndorsementV6

export const isV7Endorsement = (
  endorsement: EndorsementType
): endorsement is IEndorsement => "providerReferenceID" in endorsement

export interface IEndorsement {
  referenceID: string
  id: string
  modifierOwners: IModifierOwner[]
  systemEndorsementID: string
  providerReferenceID: string
  text: string
  title: string
  modifierType: string
}

export interface IEndorsementV6 {
  id: string
  endorsementID: number
  referenceID: string
  responsibleRiskPaths: Nullable<string[]>
  source: ModifierSourceV6
  text: string
  title: string
  type: EndorsementTypeV6
}

export interface IEndorsementDefinition {
  id: string
  providerReference: Nullable<string>
  referenceID: Nullable<string>
  schemeReferenceID: Nullable<string>
  sectionReferenceID: Nullable<string>
  text: Nullable<string>
  title: Nullable<string>
  validFromDateTime: string
  validToDateTime: Nullable<string>
  version: number
  versionReferenceID: Nullable<string>
}

export type ModifierSourceV6 = "None" | "Ratings" | "Custom"

export type EndorsementTypeV6 = "Library" | "Custom"

export type EComponentType =
  | "None"
  | "Section"
  | "Subsection"
  | "Coverage"
  | "Policy"
