import { useOrgSettings } from "contexts/organisation/hooks/use-org-settings"
import { TPlatformResponse } from "contexts/platform/client"
import { Nullable } from "models/underwriting"
import {
  ICompanyInRole,
  IExcess,
  PolicyDetailType,
  SectionTypes,
  IFinanceFee
} from "platform-client/types"
import Section from "./Section"
import Endorsements from "./Endorsements"
import FeesAndFinalPremium from "./Subsection/Fees"
import FeesWrapper from "./Subsection/Fees/FeesWrapper"
import { IEndorsement } from "platform-client/types/endorsement"
import Excesses from "./Excesses"
import { IUnderwritingConsolePermissions } from "services/Console/use-console"

const UnderwritingConsole: React.FC<IUnderwritingConsoleProps> = ({
  editSessionID,
  endorsements,
  companies,
  excesses,
  sections,
  fees,
  onChange,
  policyDetail,
  readOnly = false,
  sectionsConfiguration,
  permissions = { endorsements: false, excesses: false }
}) => {
  const { orgSettings } = useOrgSettings()
  const totalIncludingFees =
    (policyDetail &&
      "totalIncludingFees" in policyDetail &&
      policyDetail.totalIncludingFees) ||
    null

  return (
    <>
      <Endorsements
        startExpanded={sectionsConfiguration?.expandMainSections}
        endorsements={endorsements}
        readOnly={!permissions.endorsements ?? readOnly}
        isMainSection
      />

      <Excesses
        startExpanded={sectionsConfiguration?.expandMainSections}
        excesses={excesses}
        readOnly={!permissions.excesses ?? readOnly}
        isMainSection
      />

      {sections &&
        sections.length > 0 &&
        sections.map((section) => {
          return (
            <Section
              key={section.id}
              organisationSettings={orgSettings}
              startExpanded={sectionsConfiguration?.expandSubSections}
              editSessionID={editSessionID}
              productID={policyDetail?.productID}
              endorsements={endorsements}
              excesses={excesses}
              companies={companies}
              section={section}
              onChange={(response) => {
                if (typeof onChange === "function") {
                  onChange(response)
                }
              }}
              readOnly={readOnly}
              permissions={permissions}
            ></Section>
          )
        })}
      <FeesWrapper>
        <FeesAndFinalPremium
          fees={fees}
          onChange={(response) => {
            if (typeof onChange === "function") {
              onChange(response)
            }
          }}
          editSessionId={editSessionID}
          sections={sections ? sections : null}
          reference={"Fees and final premium"}
          totalPriceSectionWithFees={totalIncludingFees}
          readOnly={readOnly}
          organisationSettings={orgSettings}
        />
      </FeesWrapper>
    </>
  )
}

interface IUnderwritingConsoleProps {
  editSessionID?: string
  endorsements: IEndorsement[]
  excesses: IExcess[]
  sections: SectionTypes[]
  fees: Nullable<IFinanceFee[]>
  policyDetail?: PolicyDetailType
  onChange?: (response: TPlatformResponse) => void
  readOnly?: boolean
  companies?: ICompanyInRole[]
  sectionsConfiguration?: {
    expandMainSections: boolean
    expandSubSections: boolean
  }
  permissions?: IUnderwritingConsolePermissions
}

export default UnderwritingConsole
