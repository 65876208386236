import React, { useState } from "react"
import { Modal } from "antd"
import { ModalProps } from "antd/lib/modal"

export const useModal = (modalProps: IUseModalProps): IUseModal => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  return {
    modal: (
      <Modal
        visible={isModalVisible}
        onCancel={(e) => {
          setIsModalVisible(false)
          modalProps.onCancel && modalProps.onCancel(e)
        }}
        {...modalProps}
      >
        {modalProps.children}
      </Modal>
    ),
    setIsModalVisible
  }
}

interface IUseModal {
  modal: React.ReactNode
  setIsModalVisible: (arg: boolean) => void
}

interface IUseModalProps extends ModalProps {
  children?: React.ReactNode
  type?: string
}
