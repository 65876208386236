import { Button, Form, Input, Modal, Select } from "antd"
import JoditEditor from "jodit-react"
import { ExcessType, IExcess, ISection } from "platform-client/types"
import getCurrencySymbol from "utils/pocketknife/get-currency-symbol"
import styled from "styled-components"

export type ExcessModalModeType = "EDIT" | "ADD"

export type ExcessFormDataType = {
  amount: string
  title: string
  text: string
  section: string
  type: ExcessType
}

type CustomExcessModalType = {
  mode?: ExcessModalModeType
  excess?: IExcess
  visible: boolean
  loading?: boolean
  sections?: ISection[]
  setVisible?: (visible: boolean) => void
  onAddExcess?: (data: ExcessFormDataType, callbackFn: () => void) => void
  onUpdateExcess?: (data: ExcessFormDataType, callbackFn: () => void) => void
}

const StyledFormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`

const StyledWidthFormItem = styled(Form.Item)<{ $width?: string }>`
  width: calc(${({ $width }) => $width || "100%"} - 10px);
  margin-left: 5px;
  margin-right: 5px;
  padding-bottom: 10px;
`

export const CustomExcessModal: React.FC<CustomExcessModalType> = ({
  mode = "ADD",
  excess,
  visible = false,
  loading = false,
  sections = [],
  onAddExcess,
  onUpdateExcess,
  setVisible
}) => {
  const [form] = Form.useForm()

  const title = mode === "ADD" ? "Add custom excess" : "Update custom excess"

  const handleSubmit = (data: ExcessFormDataType) => {
    if (mode === "ADD") {
      onAddExcess?.(data, form.resetFields)
    } else {
      onUpdateExcess?.(data, form.resetFields)
    }
  }

  return (
    <>
      <Modal
        title={title}
        visible={visible}
        onCancel={() => setVisible?.(false)}
        footer={
          <>
            <Button
              onClick={() => setVisible?.(false)}
              aria-label="Close modal"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              aria-label="Submit excess"
              loading={loading}
              onClick={() => form.submit()}
            >
              Submit
            </Button>
          </>
        }
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <StyledFormWrapper>
            <StyledWidthFormItem
              $width="50%"
              name="title"
              label="Excess Reference/Name:"
              required
              rules={[
                {
                  required: true,
                  message: "Please enter the Excess name/reference"
                }
              ]}
            >
              <Input />
            </StyledWidthFormItem>
            <StyledWidthFormItem
              $width="50%"
              name="amount"
              label="Amount:"
              required
              rules={[
                {
                  required: true,
                  message: "Please enter the Excess amount"
                }
              ]}
            >
              <Input type={"number"} prefix={getCurrencySymbol("GBP")} />
            </StyledWidthFormItem>
            <StyledWidthFormItem
              $width="50%"
              name="type"
              label="Type:"
              required
              rules={[
                {
                  required: true,
                  message: "Please select an Excess type"
                }
              ]}
            >
              <Select>
                <Select.Option value={"None"}>None</Select.Option>
                <Select.Option value={"Compulsory"}>Compulsory</Select.Option>
                <Select.Option value={"Voluntary"}>Voluntary</Select.Option>
              </Select>
            </StyledWidthFormItem>
            {sections.length > 0 && (
              <StyledWidthFormItem $width="50%" name="section" label="Section:">
                <Select>
                  <Select.Option value={""} disabled>
                    None (Policy Level)
                  </Select.Option>
                  {sections.map((section) => (
                    <Select.Option value={section.id}>
                      {section.name}
                    </Select.Option>
                  ))}
                </Select>
              </StyledWidthFormItem>
            )}
            <StyledWidthFormItem name="text" label="Text:">
              <JoditEditor
                value=""
                test-id="WYSIWYG_Editor"
                config={{
                  readonly: false, // all options from https://xdsoft.net/jodit/doc/
                  showCharsCounter: false,
                  showWordsCounter: false,
                  showXPathInStatusbar: false,
                  toolbarAdaptive: false,
                  enter: "BR",
                  buttons: [
                    "bold",
                    "italic",
                    "underline",
                    "strikethrough",
                    "ul",
                    "ol",
                    "link"
                  ],
                  tabIndex: 0,
                  placeholder: "Start typing here..."
                }}
              />
            </StyledWidthFormItem>
          </StyledFormWrapper>
        </Form>
      </Modal>
    </>
  )
}
