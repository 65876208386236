import { BookOutlined } from "@ant-design/icons"
import { capitalize } from "lodash"
import {
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  notification,
  Radio,
  Row,
  Select,
  Spin,
  Typography
} from "antd"
import { AxiosError } from "axios"
import { LayoutLoading, LayoutPageContent, LayoutPageHeader } from "components"
import Fieldset from "components/Fieldset"
import usePlatform from "contexts/platform/use-platform"
import { TErrorState } from "models/errors"
import { ICreateCompanyRequest } from "platform-client/types"
import React from "react"
import { useMutation, useQuery } from "react-query"
import { RouteComponentProps, useHistory, withRouter } from "react-router"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { UniversalAddress } from "components/UniversalAddress"
import moment from "moment"
import { useOrgSettings } from "contexts/organisation/hooks/use-org-settings"
import getCurrencySymbol from "utils/pocketknife/get-currency-symbol"

const StyledFieldset = styled(Fieldset)`
  legend {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
  }

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const StyledWidthFormItem = styled(Form.Item)<{ $width?: string }>`
  width: calc(${({ $width }) => $width || "50%"} - 10px);
  margin-left: 5px;
  margin-right: 5px;
  padding-bottom: 10px;
`

const CreateDistributor: React.FC<CreateDistributorProps> = (props) => {
  const { id: distributorId, subpage } = props.match.params as {
    id?: string
    subpage: string
  }
  const [form] = Form.useForm()
  const platform = usePlatform()
  const history = useHistory()
  const [apiError, setApiError] = React.useState<TErrorState>()
  const { orgSettings } = useOrgSettings()

  const getProducts = useQuery(
    ["getProducts"],
    () => platform.product.getProducts(),
    {
      onError: (error: AxiosError) => {
        setApiError({
          errorNumber: error.code ? parseInt(error.code) : 500,
          validationMessage: "Unable to load opportunities"
        })
      }
    }
  )

  const getDistributor = useQuery(
    ["getDistributor", distributorId],
    () => platform.company.getCompany(distributorId as string),
    {
      enabled: subpage === "edit" && !!distributorId,
      onSuccess: (data) => {
        form.setFieldsValue({
          name: data?.name,
          companyType: data?.companyType,
          brokerCanBindBusiness: Number(data?.brokerCanBindBusiness),
          title: data?.contactName?.title,
          firstName: data?.contactName?.firstName,
          lastName: data?.contactName?.lastName,
          emailAddress: data?.contactEmailAddress,
          phoneNumber: data?.contactPhoneNumber,
          uniqueReference: data?.address?.uniqueReference,
          professionalIndemnityExpiryDate: moment(
            data.professionalIndemnityExpiryDate
          ),
          professionalIndemnityInsurer: data?.professionalIndemnityInsurer,
          professionalIndemnityExcess: data?.professionalIndemnityExcess?.value,
          professionalIndemnityLimit: data?.professionalIndemnityLimit?.value,
          professionalIndemnityPolicyNumber:
            data?.professionalIndemnityPolicyNumber,
          address: data?.address
            ? {
                ...data.address,
                country: JSON.stringify(data.address?.country)
              }
            : undefined,
          enabledProducts: (data?.enabledProducts || []).map(
            (item) => item.referenceID
          ),
          tobaDocument: data?.tobaDocument
        })
      }
    }
  )

  const createDistributor = useMutation(
    (request: ICreateCompanyRequest) => platform.company.createCompany(request),
    {
      onError: (error: AxiosError) => {
        setApiError({
          errorNumber: error.code ? parseInt(error.code) : 500,
          validationMessage: "Unable to create distributor, please try again."
        })
      },
      onSuccess: (response) => {
        notification.success({
          message: "Distributor created successfully. Redirecting you..."
        })
        history.push(`/distributor/${response.id}/`)
      }
    }
  )

  const updateDistributor = useMutation(
    (request: ICreateCompanyRequest) =>
      platform.company.updateCompany(distributorId as string, request),
    {
      onError: (error: AxiosError) => {
        setApiError({
          errorNumber: error.code ? parseInt(error.code) : 500,
          validationMessage: "Unable to update distributor, please try again."
        })
      },
      onSuccess: (response) => {
        notification.success({
          message: "Distributor updated successfully. Redirecting you..."
        })
        history.push(`/distributor/${response.id}/`)
      }
    }
  )

  const onFinish = (formData: ICreateDistributorForm) => {
    const requestBody: ICreateCompanyRequest = {
      name: formData.name,
      companyType: "Broker",
      brokerCanBindBusiness: Boolean(formData.brokerCanBindBusiness),
      contactName: {
        title: formData.title || null,
        firstName: formData.firstName || null,
        lastName: formData.lastName || null
      },
      contactEmailAddress: formData.emailAddress || null,
      contactPhoneNumber: formData.phoneNumber || null,
      address: {
        line1: formData?.address?.line1 || null,
        line2: formData?.address?.line2 || null,
        line3: formData?.address?.line3 || null,
        locality: formData?.address?.locality || null,
        province: formData?.address?.province || null,
        postalCode: formData?.address?.postalCode || null,
        provinceReferenceID: formData?.address?.provinceReferenceID,
        uniqueReference: formData?.address?.uniqueReference || null,
        country: formData?.address?.country
          ? JSON.parse(formData?.address?.country)
          : { referenceID: null, text: null }
      },
      professionalIndemnityExpiryDate:
        moment(formData?.professionalIndemnityExpiryDate).format(
          "YYYY-MM-DDT00:00:00"
        ) || null,
      professionalIndemnityInsurer:
        formData.professionalIndemnityInsurer || null,
      professionalIndemnityExcess: {
        value: Number(formData.professionalIndemnityExcess),
        currencyCode: orgSettings?.currencyCode || "GBP"
      },
      professionalIndemnityLimit: {
        value: Number(formData.professionalIndemnityLimit),
        currencyCode: orgSettings?.currencyCode || "GBP"
      },
      professionalIndemnityPolicyNumber:
        formData.professionalIndemnityPolicyNumber || null,
      enabledProducts: formData.enabledProducts,
      tobaDocument: formData.tobaDocument || false
    }

    if (subpage === "edit") {
      updateDistributor.mutate(requestBody)
    }

    if (subpage === "create") {
      createDistributor.mutate(requestBody)
    }
  }

  if (getProducts.isLoading) {
    return <LayoutLoading withWrapper />
  }

  return (
    <Layout>
      <LayoutPageHeader
        ghost={false}
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item key="/distributors">
              <Link to="/distributors">
                <BookOutlined />
                &nbsp;Distributors
              </Link>
            </Breadcrumb.Item>
            {subpage === "edit" && (
              <Breadcrumb.Item>
                <Link to={`/distributor/${distributorId}`}>
                  {getDistributor.data?.referenceID ||
                    getDistributor.data?.name ||
                    ""}
                </Link>
              </Breadcrumb.Item>
            )}
            <Breadcrumb.Item>{capitalize(subpage)}</Breadcrumb.Item>
          </Breadcrumb>
        }
        title={subpage === "create" ? "Create Distributor" : "Edit Distributor"}
        style={{ marginBottom: 20 }}
      />
      <Spin spinning={getDistributor.isLoading}>
        <LayoutPageContent error={apiError}>
          <Form form={form} onFinish={onFinish} layout="vertical">
            <Row>
              <Col span={12} offset={6} style={{ padding: "50px 15px 15px" }}>
                <Typography.Title level={3}>
                  {subpage === "create"
                    ? "Create Distributor"
                    : `Editing Distributor: ${getDistributor.data?.name || ""}`}
                </Typography.Title>
                <Typography.Paragraph>
                  Please complete the form below to create a distributor. You
                  will be redirected to the summary page on creation.
                </Typography.Paragraph>
                <StyledFieldset title="Distributor details">
                  <StyledWidthFormItem
                    name="name"
                    label="Name:"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please enter the distributor's name"
                      }
                    ]}
                  >
                    <Input />
                  </StyledWidthFormItem>
                  <StyledWidthFormItem
                    name="brokerCanBindBusiness"
                    label="Can bind business:"
                    required
                    rules={[
                      {
                        required: true,
                        message:
                          "Please select whether the distributor can bind business"
                      }
                    ]}
                  >
                    <Radio.Group buttonStyle="solid" style={{ width: "100%" }}>
                      <Radio.Button value={1} style={{ width: "50%" }}>
                        Yes
                      </Radio.Button>
                      <Radio.Button value={0} style={{ width: "50%" }}>
                        No
                      </Radio.Button>
                    </Radio.Group>
                  </StyledWidthFormItem>
                  <StyledWidthFormItem
                    name="enabledProducts"
                    label="Enabled products:"
                    $width="100%"
                  >
                    <Checkbox.Group style={{ width: "100%" }}>
                      <Row>
                        {getProducts.data &&
                          getProducts.data.length > 0 &&
                          getProducts.data.map((item) => (
                            <Col key={item.id} span={12}>
                              <Checkbox value={item.referenceID}>
                                {item.name}
                              </Checkbox>
                            </Col>
                          ))}
                      </Row>
                    </Checkbox.Group>
                  </StyledWidthFormItem>
                  <StyledWidthFormItem
                    name="tobaDocument"
                    valuePropName="checked"
                    label="Distributor documents:"
                    $width="100%"
                  >
                    <Checkbox
                      value={true}
                      defaultChecked={getDistributor.data?.tobaDocument}
                    >
                      Terms of Business Agreement (TOBA)
                    </Checkbox>
                  </StyledWidthFormItem>
                </StyledFieldset>

                <StyledFieldset title="Professional Indemnity Insurance">
                  <StyledWidthFormItem
                    name="professionalIndemnityInsurer"
                    label="Insurer"
                    rules={[
                      {
                        required: true,
                        message:
                          "Professional indemnity insurer name is required"
                      }
                    ]}
                    required
                  >
                    <Input />
                  </StyledWidthFormItem>
                  <StyledWidthFormItem
                    name="professionalIndemnityPolicyNumber"
                    label="Policy No."
                    rules={[
                      {
                        required: true,
                        message:
                          "Professional indemnity policy number is required"
                      }
                    ]}
                    required
                  >
                    <Input />
                  </StyledWidthFormItem>
                  <StyledWidthFormItem
                    name="professionalIndemnityExcess"
                    label="Excess"
                    rules={[
                      {
                        required: true,
                        message: "Professional indemnity excess is required"
                      }
                    ]}
                    $width="33%"
                    required
                  >
                    <Input
                      type="number"
                      {...(orgSettings?.currencyCode
                        ? {
                            prefix: getCurrencySymbol(orgSettings?.currencyCode)
                          }
                        : {})}
                    />
                  </StyledWidthFormItem>
                  <StyledWidthFormItem
                    name="professionalIndemnityLimit"
                    label="Limit"
                    rules={[
                      {
                        required: true,
                        message: "Professional indemnity limit is required"
                      }
                    ]}
                    $width="33%"
                    required
                  >
                    <Input
                      type="number"
                      {...(orgSettings?.currencyCode
                        ? {
                            prefix: getCurrencySymbol(orgSettings?.currencyCode)
                          }
                        : {})}
                    />
                  </StyledWidthFormItem>
                  <StyledWidthFormItem
                    name="professionalIndemnityExpiryDate"
                    label="Expiry date"
                    rules={[
                      {
                        required: true,
                        message:
                          "Professional indemnity expiry date is required"
                      }
                    ]}
                    required
                    $width="33%"
                  >
                    <DatePicker format="DD/MM/YYYY" style={{ width: "100%" }} />
                  </StyledWidthFormItem>
                </StyledFieldset>

                <StyledFieldset title="Business Address">
                  <StyledWidthFormItem
                    name={["address"]}
                    label="Business Address"
                    rules={[
                      {
                        validator: (_, v) => {
                          return new Promise((resolve, reject) => {
                            if (v?.line1 && v?.locality && v?.postalCode) {
                              resolve(true)
                            }

                            reject("Business address field is required.")
                          })
                        }
                      }
                    ]}
                    $width="100%"
                    required
                  >
                    <UniversalAddress name={"address"}>
                      <UniversalAddress.ManualInput />
                    </UniversalAddress>
                  </StyledWidthFormItem>
                </StyledFieldset>

                <StyledFieldset title="Contact details">
                  <StyledWidthFormItem name="title" label="Title:" $width="20%">
                    <Select
                      options={[
                        { value: "Mr" },
                        { value: "Mrs" },
                        { value: "Miss" },
                        { value: "Ms" },
                        { value: "Dr" }
                      ]}
                    />
                  </StyledWidthFormItem>
                  <StyledWidthFormItem
                    name="firstName"
                    label="Firstname:"
                    $width="40%"
                  >
                    <Input />
                  </StyledWidthFormItem>
                  <StyledWidthFormItem
                    name="lastName"
                    label="Lastname:"
                    $width="40%"
                  >
                    <Input />
                  </StyledWidthFormItem>
                  <StyledWidthFormItem name="phoneNumber" label="Telephone:">
                    <Input type="tel" />
                  </StyledWidthFormItem>
                  <StyledWidthFormItem
                    name="emailAddress"
                    label="Email address:"
                  >
                    <Input type="email" />
                  </StyledWidthFormItem>
                </StyledFieldset>

                <div>
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => form.submit()}
                    loading={
                      createDistributor.isLoading || updateDistributor.isLoading
                    }
                  >
                    Save and continue
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </LayoutPageContent>
      </Spin>
    </Layout>
  )
}

interface ICreateDistributorForm {
  brokerCanBindBusiness: boolean
  emailAddress?: string
  enabledProducts?: string[]
  address?: {
    line1?: string
    line2?: string
    line3?: string
    locality?: string
    postalCode?: string
    province?: string
    country?: string
    provinceReferenceID?: string
    uniqueReference?: string
  }
  firstName?: string
  lastName?: string
  name: string
  phoneNumber?: string
  title?: string
  tobaDocument?: boolean
  professionalIndemnityExpiryDate?: string
  professionalIndemnityInsurer?: string
  professionalIndemnityExcess?: string
  professionalIndemnityLimit?: string
  professionalIndemnityPolicyNumber?: string
}

type CreateDistributorProps = RouteComponentProps

export default withRouter(CreateDistributor)
