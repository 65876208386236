import React from "react"
import { EmbargoesBoundary } from "./boundary"
import { AllEmbargoes } from "./views/AllEmbargoes"
import { NewEmbargo } from "./views/NewEmbargo"
import { EmbargoDetails, IEmbargoDetails } from "./views/EmbargoDetails"

export const VAllEmbargoes: React.FC = (props) => (
  <EmbargoesBoundary>
    <AllEmbargoes {...props} />
  </EmbargoesBoundary>
)

export const VNewEmbargo: React.FC = (props) => (
  <EmbargoesBoundary>
    <NewEmbargo {...props} />
  </EmbargoesBoundary>
)

export const VEmbargoDetails: React.FC<IEmbargoDetails> = (props) => (
  <EmbargoesBoundary>
    <EmbargoDetails {...props} />
  </EmbargoesBoundary>
)
