import * as React from "react"
import { Collapse } from "../../../Collapse"
import Row from "../../Row"
import CellContentTitle from "../../CellContent/Title"
import CellContentText from "../../CellContent/Text"
import { ExpandButton } from "../../CellContent/Button"
import styled from "styled-components"

const HeaderRow = ({ expanded = false, ...props }: IFullTableHeaderRow) => (
  <Row
    type="subHeader"
    disabled={props.disabled}
    toggle={
      !props.disabled && (
        <ExpandButton expanded={expanded} onClick={props.toggle} />
      )
    }
    {...(props.values && props.values.length && { values: props.values })}
    reference={
      <CellContentTitle {...(props.disabled && { aside: <i>(disabled)</i> })}>
        {props.reference}
      </CellContentTitle>
    }
    control={props.controls}
    customValueSpan={props.customValueSpan}
  />
)

const StyledWrapper = styled.div<{ isLoading?: boolean }>((props) => ({
  opacity: props.isLoading ? 0.5 : 1
}))

export const FullTable: React.FunctionComponent<IFullTable> = (props) => {
  return (
    <Collapse
      startExpanded={props.startExpanded}
      collapsedPanel={
        <HeaderRow
          disabled={props.disabled}
          reference={props.reference}
          values={[
            props.coverPeriod,
            null,
            props.summary ? (
              <CellContentText strong>{props.summary.label}</CellContentText>
            ) : null,
            null,
            props.summary ? props.summary.value : null
          ]}
          controls={props.controls}
        />
      }
      onCollapse={props.onCollapse}
      expandedPanel={
        <HeaderRow
          disabled={props.disabled}
          reference={props.reference}
          values={[props.coverPeriod]}
          controls={props.controls}
          expanded
        />
      }
      disabled={props.disabled}
    >
      <StyledWrapper isLoading={props.isLoading}>
        {props.meta && (
          <Row
            type="meta"
            reference={<CellContentText>{props.meta}</CellContentText>}
            customValueSpan={props.customValueSpan}
          />
        )}
        {props.children}
      </StyledWrapper>
    </Collapse>
  )
}

export interface IFullTable {
  reference: React.ReactNode
  summary?: {
    label: string
    value: React.ReactNode
  }
  coverPeriod?: React.ReactNode
  meta?: string
  startExpanded?: boolean
  onCollapse?: (isCollapsed: boolean) => void
  controls?: React.ReactNode
  isLoading?: boolean
  disabled?: boolean
  customValueSpan?: number
}

export interface IFullTableHeaderRow {
  reference: React.ReactNode
  values?: React.ReactNode[]
  toggle?: () => void
  controls?: React.ReactNode
  isLoading?: boolean
  disabled?: boolean
  expanded?: boolean
  customValueSpan?: number
}
