import { Alert, Col, Layout, notification, Row } from "antd"
import axios from "axios"
import { LayoutLoading } from "components"
import { LayoutPageContent } from "components/LayoutPageContent"
import { LayoutPageHeader } from "components/LayoutPageHeader"
import ClaimForm, { IClaimForm } from "containers/claims/components/ClaimForm"
import { useOrgSettings } from "contexts/organisation/hooks/use-org-settings"
import usePlatform from "contexts/platform/use-platform"
import { IValidationError } from "models/errors"
import { IPolicyClaimEditRequest } from "platform-client/types/claims"
import { useState } from "react"
import { useMutation, useQuery } from "react-query"
import { RouteComponentProps, useHistory, useParams } from "react-router"
import styled from "styled-components"

const StyledFormWrapper = styled.div`
  padding: 30px 0;
`

const EditClaim = (props: EditClaimProps): JSX.Element => {
  const { orgSettings } = useOrgSettings()
  const { policy, claims } = usePlatform()
  const history = useHistory()
  const { id: claimID } = useParams<{ id: string }>()

  const [policyID, setPolicyID] = useState<string>()
  const [policyReferenceID, setPolicyReferenceID] = useState<
    string | undefined
  >()
  const [productReferenceID, setProductReferenceID] = useState<
    string | undefined
  >()
  const [validationErrors, setValidationErrors] = useState<IValidationError[]>()

  const { data: claim, isFetched } = useQuery(
    ["claim", claimID],
    () => claims.getClaimByID(claimID),
    {
      onSuccess: (response) => {
        setPolicyID(response.policyID)
      }
    }
  )

  const { isFetched: isPolicyFetched } = useQuery(
    ["policyBundle", policyID],
    () => {
      if (!policyID) {
        throw new Error("Policy not found.")
      }

      return policy.getPolicy(policyID)
    },
    {
      enabled: !!policyID,
      onSuccess: (response) => {
        setProductReferenceID(response.product.referenceID)
        setPolicyReferenceID(
          response.policyDetails.policy.policyReferenceID || undefined
        )
      }
    }
  )

  const getProductClaimTypes = useQuery(
    ["getProductByReferenceID", productReferenceID],
    () => {
      if (!productReferenceID) {
        throw new Error("Product reference missing.")
      }

      return claims.getProductClaimTypes(productReferenceID)
    },
    {
      enabled: !!productReferenceID
    }
  )

  const editClaim = useMutation((vars: EditClaimVars) =>
    claims.editClaim(vars.claimID, vars.request)
  )

  const handleFormSubmit = (formData: IClaimForm) => {
    const request: IPolicyClaimEditRequest = {
      claimReference: formData.claimReference,
      claimStage: formData.claimStage,
      claimStatus: formData.claimStatus,
      claimSubStatus: formData.claimSubStatus,
      claimType: formData.claimType,
      claimValue: {
        value: Number(formData.claimValue),
        currencyCode: orgSettings?.currencyCode || "GBP"
      },
      dateOccurred: formData.dateOccurred.format("YYYY-MM-DDT00:00:00"),
      dateReported: formData.dateReported
        ? formData.dateReported.format("YYYY-MM-DDT00:00:00")
        : "",
      dateClosed: formData.dateClosed
        ? formData.dateClosed.format("YYYY-MM-DDT00:00:00")
        : undefined,
      description: formData.description,
      sourceClaimType: formData.sourceClaimType
    }

    if (formData.sameDayReported === true) {
      request.dateReported = request.dateOccurred
    }

    editClaim
      .mutateAsync({ claimID, request })
      .then((response) => {
        notification.success({ message: "Claim updated successfully." })

        if (response.policyID) {
          setTimeout(() => history.push(`/policy/${response.policyID}/claims`))
        }
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.validationErrors &&
            error.response.data.validationErrors.length > 0
          ) {
            setValidationErrors(error.response.data.validationErrors)
          }

          notification.error({
            message: "Something went wrong adding this claim. Please try again."
          })

          return
        }

        throw new Error(error)
      })
  }

  if (!isFetched || !isPolicyFetched) {
    return <LayoutLoading withWrapper />
  }

  return (
    <Layout>
      <LayoutPageHeader
        ghost={false}
        title={
          policyReferenceID ? `${policyReferenceID} / Edit Claim` : "Edit Claim"
        }
      />
      <LayoutPageContent>
        <Row justify="center" aria-label="Add new claim">
          <Col span={10}>
            <StyledFormWrapper>
              <h2>
                Edit claim{" "}
                {policyReferenceID ? ` for policy ${policyReferenceID}` : ""}
              </h2>
              {validationErrors && (
                <Alert
                  message="Sorry, we found a problem when submitting this claim:"
                  description={
                    <ul>
                      {validationErrors.map((error) => (
                        <li>{error.message}</li>
                      ))}
                    </ul>
                  }
                  type="error"
                  closable
                  style={{ marginBottom: "15px" }}
                />
              )}
              <ClaimForm
                claim={claim}
                policyReferenceID={policyReferenceID || undefined}
                handleFormSubmit={handleFormSubmit}
                isSubmitting={editClaim.isLoading}
                claimTypes={getProductClaimTypes.data}
                formButtonText="Update claim"
                currencyCode={orgSettings?.currencyCode}
              />
            </StyledFormWrapper>
          </Col>
        </Row>
      </LayoutPageContent>
    </Layout>
  )
}

export type EditClaimVars = {
  claimID: string
  request: IPolicyClaimEditRequest
}

export type EditClaimProps = RouteComponentProps<{
  id: string
}>

export default EditClaim
