import { Button, DatePicker, notification, Space } from "antd"
import { AxiosError } from "axios"
import usePlatform from "contexts/platform/use-platform"
import React, { useEffect } from "react"
import { useMutation } from "react-query"
import styled from "styled-components"
import { handleValidationErrors } from "utils/pocketknife/handle-validation-errors"
import { IExtendQuoteExpiryParams } from "platform-client/types/quote"
import moment from "moment"
import { DateDisplay } from "components/display/DateDisplay"
import { RangePickerProps } from "antd/lib/date-picker"

const StyledFloatRightButton = styled(Button)`
  float: right;
`

const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

export const QuoteExpiryDate: React.FunctionComponent<IQuoteExpiry> = (
  props
) => {
  const { quote } = usePlatform()
  const originalExpiryDate = props.expiryDate

  const extendExpiryDate = useMutation(
    (params: IExtendQuoteExpiryParams) =>
      quote.extendExpiry(params.quoteId, params.expiryDate),
    {
      onSuccess: () => {
        notification.success({
          message: `Expiry date updated`
        })
        setisEdit(false)
        setIsLoading(false)
      },
      onError: (error: AxiosError) => {
        handleValidationErrors(error)
        setisEdit(true)
        setIsLoading(false)
      }
    }
  )

  const submitChange = (params: IExtendQuoteExpiryParams) => {
    if (moment(params.expiryDate).isBefore(originalExpiryDate)) {
      notification.error({
        message: `New date is before the original expiry date`
      })
    } else {
      setIsLoading(true)
      extendExpiryDate.mutate(params)
    }
  }
  const [isEdit, setisEdit] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [expiryDate, setExpiryDate] = React.useState(moment(props.expiryDate))

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return props.maxExpiry != null && current > moment(props.maxExpiry)
  }

  useEffect(() => {
    setExpiryDate(moment(props.expiryDate))
  }, [props.expiryDate])

  return (
    <InputWrapper>
      {isEdit && (
        <>
          <DatePicker
            value={moment(expiryDate.startOf("day"))}
            onChange={(value) => {
              setExpiryDate(value || moment(props.expiryDate).startOf("day"))
            }}
            allowClear={false}
            disabledDate={disabledDate}
          />
          <StyledFloatRightButton
            type="primary"
            onClick={() => {
              submitChange({
                quoteId: props.quoteId,
                expiryDate: expiryDate.format("YYYY-MM-DD")
              })
            }}
            loading={isLoading}
          >
            Save
          </StyledFloatRightButton>
          <StyledFloatRightButton
            onClick={() => {
              setisEdit(false)
              setExpiryDate(moment(originalExpiryDate))
            }}
          >
            Cancel
          </StyledFloatRightButton>
        </>
      )}{" "}
      {!isEdit && (
        <>
          <Space size="middle">
            <DateDisplay date={expiryDate.toISOString()} showTime />
            {props.isExternal === false && (
              <StyledFloatRightButton
                type="primary"
                onClick={() => setisEdit(true)}
              >
                Extend
              </StyledFloatRightButton>
            )}
          </Space>
        </>
      )}
    </InputWrapper>
  )
}

export interface IQuoteExpiry {
  quoteId: string
  expiryDate: string
  isExternal: boolean
  maxExpiry?: string
}
