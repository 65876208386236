import { Layout } from "antd"
import { LayoutPageContent, LayoutPageHeader } from "components"

export const IFrameContainer: React.FunctionComponent<IIFrameContainer> = (
  props
) => {
  return (
    <Layout>
      <LayoutPageHeader
        ghost={false}
        title={props.title}
        onBack={props.goBack}
      />
      <LayoutPageContent>
        <iframe
          src={props.url}
          /*Stops the Iframe caching in Chrome
          https://stackoverflow.com/questions/25732519/preventing-iframe-caching-in-chrome*/
          name={props.name + Date.now()}
          title={props.title}
          id="quoteIframe"
          frameBorder="0"
          height={960}
          width="100%"
        ></iframe>
      </LayoutPageContent>
    </Layout>
  )
}

export interface IIFrameContainer {
  name: string
  id?: string | undefined
  url: string | undefined
  title: string
  goBack?: () => void
}
