/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import styled from "styled-components"
import { TQuestionType } from "models/quotes/quoteReview"
import { Typography } from "antd"
import { isEmpty, is, isNil } from "ramda"
import { ColumnsType } from "antd/lib/table"
import { formatQuestionAnswer } from "containers/quotes/services/format-question-answer"

const { Text } = Typography
const StyledUl = styled.ul`
  list-style-type: none;
  padding-left: 0;
  li {
    margin-bottom: 6px;
  }
`

export const COLUMNS = (): ColumnsType<any> => [
  {
    title: "Question",
    dataIndex: "question",
    key: "question",
    width: "50%",
    render: (question: string) => <Text>{question}</Text>
  },
  {
    title: "Response",
    key: "answer",
    width: "20%",
    render: (question: { answer: unknown; questionType: TQuestionType }) => {
      return formatQuestionAnswer(question.questionType, question.answer)
    }
  },
  {
    title: "Provisions",
    dataIndex: "endorsements",
    key: "Provisions",
    width: "30%",
    render: (endorsements: { title: string }[]) => {
      if (
        !isEmpty(endorsements) &&
        is(Array, endorsements) &&
        !isNil(endorsements)
      ) {
        const endorsementsTitles: string[] = []
        endorsements.forEach((endorsement) => {
          if (endorsement?.title) endorsementsTitles.push(endorsement?.title)
        })
        return (
          <div>
            <StyledUl>
              {endorsementsTitles.map((title) => (
                <li>{title}</li>
              ))}
            </StyledUl>
          </div>
        )
      } else {
        return null
      }
    }
  }
]
