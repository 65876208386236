import axios from "axios"
import { getApiUrl } from "utils/api/apiUrl"
import { AddressServiceType } from "./types"

const API_URL = `${getApiUrl(window.location.host)}/v2/lookup/address`

export const useAddressService: AddressServiceType = () => {
  const client = axios.create({
    baseURL: API_URL,
    headers: {
      "Content-Type": "application/json"
    }
  })

  const search = async (query: string, countryCodes?: string[]) => {
    const response = await client.post("/search", {
      query,
      countryCodes
    })

    return response?.data?.data
  }

  const format = async (query: string) => {
    const response = await client.post(`/detail`, { query })

    return response?.data?.data
  }

  return {
    search,
    format
  }
}
