import { Button } from "antd"
import { IPolicy, IProduct } from "platform-client/types"
import { Link } from "react-router-dom"
import PolicyAdjustment from "../policy-adjustment"
import PolicyCancellationWithSummary from "../policy-cancellation-with-summary"

const PolicyActions: React.FC<IPolicyActionsProps> = (props) => {
  switch (props.policy.policyStatus) {
    case "Defaulted":
    case "Cancelled":
      // Show reinstatement?
      return null
    case "Active":
      return (
        <>
          <Link
            className="ant-btn"
            to={`/claims/add?policyReferenceID=${props.policy.policyReferenceID}&productReferenceID=${props.product.referenceID}`}
          >
            Add Claim
          </Link>
          <Button onClick={props.onNoteButtonClick}>New Note</Button>
          <PolicyAdjustment
            policyID={props.policy.id}
            coverStartDate={props.policy.coverStartDate}
            coverEndDate={props.policy.coverEndDate}
          />
          <PolicyCancellationWithSummary policy={props.policy} />
        </>
      )
    case "Lapsed":
    case "Pending":
    default:
      return null
  }
}

export default PolicyActions

interface IPolicyActionsProps {
  policy: IPolicy
  product: IProduct
  onNoteButtonClick: () => void
}
