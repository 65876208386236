import React from "react"
import { Form } from "antd"
import { ICreateEmbargoForm } from "containers/embargoes/views/NewEmbargo/components/EmbargoDetails/types"
import { FormInstance } from "antd/lib/form"
import { useHistory } from "react-router-dom"
import { TErrorState } from "models/errors"
import useOpus from "contexts/opus/hooks/use-opus"

const EMBARGO_FIELD_MAP: {
  [key: string]: string
} = {
  schemereferenceid: "schemeReferenceID",
  schemeid: "schemeID",
  starttime: "startTime",
  endtime: "endTime",
  errormessage: "errorMessage",
  riskpath: "riskPath"
}

interface IValidationError {
  name: string
  errors?: string[]
}

interface IValidationResponse {
  message: string
  propertyIndentifier: string
}

export const useCreateEmbargo = (): IUseCreateEmbargo => {
  const [form] = Form.useForm()
  const [days, setDays] = React.useState<number | undefined>()
  const [embargoes, setEmbargoes] = React.useState<string[] | []>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const [isError, setIsError] = React.useState<TErrorState>()

  const { embargoesClient } = useOpus()

  const history = useHistory()

  const onDatepickerChange = (): void => {
    const startTime = form.getFieldValue("startTime")
    const endTime = form.getFieldValue("endTime")
    if (startTime && endTime) {
      const days = endTime.diff(startTime, "days")
      setDays(days)
    }
  }

  const onAddEmbargoValues = () => {
    const data = form.getFieldValue("values")
    if (data) {
      setEmbargoes((current) => {
        const cleanData = data
          .split(",")
          .map((item: string) => item.trim())
          .filter((item: string) => item)

        if (current && current.length) {
          return [...current, ...cleanData]
        }

        return cleanData || []
      })
      form.setFieldsValue({ values: "" })
    }
  }

  const onEmbargoRemove = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    event.preventDefault()
    setEmbargoes((current) => {
      if (current && current.length) {
        return current?.filter(
          (embargo) => embargo.toLowerCase() !== value.toLowerCase()
        )
      }
      return []
    })
  }

  const handleValidation = (errors: IValidationResponse[]) => {
    if (errors && errors.length) {
      const validationErrors: IValidationError[] = []
      errors.forEach((error) => {
        const field = EMBARGO_FIELD_MAP[error.propertyIndentifier.toLowerCase()]
        if (field) {
          validationErrors.push({
            name: field,
            errors: ["Please check this field"]
          })
        }
      })
      if (validationErrors && validationErrors.length) {
        form.setFields(validationErrors)
      }
    }
  }

  const onEmbargoSubmit = async (formData: ICreateEmbargoForm) => {
    let data = { ...formData, ...{ values: embargoes } }

    if (typeof data.startTime !== "string") {
      data.startTime = data.startTime.format("YYYY-MM-DD")
    }

    if (typeof data.endTime !== "string") {
      data.endTime = data.endTime.format("YYYY-MM-DD")
    }

    Object.keys(data).forEach((key: string) => {
      const value = data[key as keyof ICreateEmbargoForm]
      if (typeof value === "string") {
        data = { ...data, ...{ [key]: value.trim() } }
      }
    })

    setLoading(true)

    try {
      const embargo = await embargoesClient.addEmbargo(data)

      if (embargo) {
        setLoading(false)
        setIsError(undefined)
        history.replace(`/embargo/${embargo.id}`)
        return
      }
      setIsError({ errorNumber: 400 })
    } catch (error) {
      setLoading(false)
      if (error.response?.data?.validationErrors) {
        setIsError({ errorNumber: 422 })
        handleValidation(error.response.data.validationErrors)
      } else {
        setIsError({ errorNumber: error.response?.status })
      }
    }
    return
  }

  return {
    days,
    form,
    loading,
    isError,
    embargoes,
    onDatepickerChange,
    onAddEmbargoValues,
    onEmbargoSubmit,
    onEmbargoRemove
  }
}

export interface IUseCreateEmbargo {
  days: number | undefined
  form: FormInstance
  loading: boolean
  isError: TErrorState
  embargoes: string[] | undefined
  onDatepickerChange: () => void
  onAddEmbargoValues: () => void
  onEmbargoSubmit: (formData: ICreateEmbargoForm) => void
  onEmbargoRemove: (event: React.MouseEvent<HTMLElement>, value: string) => void
}
