import { useState, useEffect } from "react"
import { useConfigurationContext } from "contexts/configuration/context"
import { IApiInformation } from "contexts/configuration/hooks/use-api-info"

export const useGetNewQuoteUrl = (
  quoteSection: string | undefined,
  apiInfo: IApiInformation | undefined,
  productRef: string | undefined,
  productCover: string | undefined,
  questionSet: string | undefined,
  policyId: string | undefined,
  quoteId: string | undefined,
  userId: string | undefined
): IUseGetNewQuoteUrl => {
  const [opusFinalUrl, setOpusFinalUrl] = useState<string>()

  const configContext = useConfigurationContext()

  useEffect(() => {
    if (
      quoteSection &&
      configContext &&
      configContext.opus.baseOpusUrl &&
      apiInfo &&
      productRef &&
      productCover
    ) {
      const newOrSaved = quoteSection === "saved" ? "saved" : "new"
      const journey =
        quoteSection === "purchase"
          ? "additional"
          : quoteSection === "renew"
          ? "renew/"
          : newOrSaved

      let extra = ""

      if (quoteSection === "purchase" && questionSet) {
        extra = `&questionSet=${questionSet}`
      }

      if (policyId !== undefined && quoteSection === "renew") {
        extra += `&policyId=${policyId}`
      }

      if (quoteId !== undefined && quoteSection === "renew") {
        extra += `&quoteId=${quoteId}`
      }

      if (userId !== undefined) {
        extra += `&userId=${userId}`
      }

      setOpusFinalUrl(
        `${configContext.opus.baseOpusUrl}/quote/${journey}?` +
          `productReference=${productRef}&` +
          `productCoverReference=${productCover}&` +
          `tenantReference=${apiInfo?.orgInfo.tenantReferenceId}&` +
          `tenantOrgReference=${apiInfo?.orgInfo.referenceId}${extra}`
      )
    }
  }, [
    quoteSection,
    configContext,
    apiInfo,
    productCover,
    productRef,
    questionSet,
    policyId,
    quoteId,
    userId
  ])

  return { finalUrl: opusFinalUrl }
}

export interface IUseGetNewQuoteUrl {
  finalUrl: string | undefined
}
