import { QUOTES_ROUTES, QUOTE_NAVIGATION } from "./containers/quotes/routes"
import {
  INVOICES_NAVIGATION,
  INVOICES_ROUTES
} from "./containers/invoices/routes"
import {
  SETTINGS_ROUTES
  // SETTINGS_NAVIGATION
} from "./containers/settings/routes"
import { ACCOUNT_ROUTES } from "./containers/account/routes"
import {
  EMBARGOES_ROUTES,
  EMBARGOES_NAVIGATION
} from "./containers/embargoes/routes"
import { USERS_ROUTES, USERS_NAVIGATION } from "./containers/users/routes"
import {
  POLICIES_NAVIGATION,
  POLICIES_ROUTES
} from "./containers/policies/routes"

import {
  OPPORTUNITIES_NAVIGATION,
  OPPORTUNITIES_ROUTES
} from "./containers/opportunities/routes"
import {
  DISTRIBUTORS_NAVIGATION,
  DISTRIBUTORS_ROUTES
} from "containers/distributors/routes"
import { CLAIMS_ROUTES } from "containers/claims/routes"
import { POLICYHOLDERS_ROUTES } from "containers/policyholders/routes"
import { ERIC_NAVIGATION, ERIC_ROUTES } from "containers/eric/routes"
import { REPORTS_NAVIGATION, REPORTS_ROUTES } from "containers/reports/routes"
import {
  BLACKLIST_NAVIGATION,
  BLACKLIST_ROUTES
} from "containers/blocklist/routes"
import { RATINGS_NAVIGATION, RATINGS_ROUTES } from "containers/ratings/routes"

export const MAIN_ROUTES = [
  ...QUOTES_ROUTES,
  ...OPPORTUNITIES_ROUTES,
  ...EMBARGOES_ROUTES,
  ...ACCOUNT_ROUTES,
  ...POLICIES_ROUTES,
  ...DISTRIBUTORS_ROUTES,
  ...USERS_ROUTES,
  ...SETTINGS_ROUTES,
  ...INVOICES_ROUTES,
  ...CLAIMS_ROUTES,
  ...POLICYHOLDERS_ROUTES,
  ...ERIC_ROUTES,
  ...REPORTS_ROUTES,
  ...BLACKLIST_ROUTES,
  ...RATINGS_ROUTES
]
export const NAVIGATION: TNavigation[] = [
  ...OPPORTUNITIES_NAVIGATION,
  ...QUOTE_NAVIGATION,
  // ...SETTINGS_NAVIGATION,
  // TODO: This is for phase 2 of Westcor
  ...POLICIES_NAVIGATION,
  ...EMBARGOES_NAVIGATION,
  ...DISTRIBUTORS_NAVIGATION,
  ...INVOICES_NAVIGATION,
  ...USERS_NAVIGATION,
  ...ERIC_NAVIGATION,
  ...REPORTS_NAVIGATION,
  ...BLACKLIST_NAVIGATION,
  ...RATINGS_NAVIGATION
]

export type TNavigation = {
  title: string
  path: string
  childPaths?: string[]
  submenu: boolean
  permission?: string
  groups?: string[]
  icon: React.ReactNode
  iconActive: React.ReactNode
  version?: string
}
