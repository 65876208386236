import { Button, Form, Input, Modal } from "antd"
import { IInviteUserRequestBody } from "platform-client/types"

const AddUserModal: React.FC<IAddUserModalProps> = ({
  visible,
  onCancel,
  companyId,
  companyName,
  onSubmit,
  isLoading
}) => {
  const [form] = Form.useForm()
  const handleSubmit = (data: IAddUserForm) => {
    onSubmit({
      ...{
        firstName: data.firstName,
        lastName: data.lastName,
        username: data.username
      },
      companyId,
      companyName,
      userGroups: [],
      userClaims: [],
      externalID: null
    })
  }

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={"Add new user"}
      footer={
        <>
          <Button onClick={onCancel} disabled={isLoading}>
            Cancel
          </Button>
          <Button type="primary" onClick={form.submit} loading={isLoading}>
            Submit
          </Button>
        </>
      }
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        name="addUser"
        layout="horizontal"
      >
        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item
            label={"First name"}
            name="firstName"
            style={{
              display: "inline-block",
              width: "calc(50% - 16px)",
              marginBottom: 10
            }}
            rules={[
              {
                required: true,
                message: "Please input first name"
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={"Last name"}
            name="lastName"
            style={{
              display: "inline-block",
              width: "50%",
              margin: "0 0px 0 16px",
              marginBottom: 10
            }}
            rules={[
              {
                required: true,
                message: "Please input last name"
              }
            ]}
          >
            <Input />
          </Form.Item>
        </Form.Item>
        <Form.Item
          style={{ marginBottom: 10 }}
          labelCol={{ span: 24 }}
          name="username"
          label={"Email:"}
          rules={[
            {
              required: true,
              message: "Please input email"
            },
            { type: "email", message: "Please input correct email address" }
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  )
}

interface IAddUserForm {
  firstName: string
  lastName: string
  username: string
}

interface IAddUserModalProps {
  visible: boolean
  onCancel: () => void
  onSubmit: (body: IInviteUserRequestBody) => void
  isLoading: boolean
  companyId: string
  companyName: string
}

export default AddUserModal
