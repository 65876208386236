import React from "react"
import {
  OnReviewQuote,
  IQuoteRenewalReview,
  OnUpdateReferral,
  OnOverridePremium
} from "../../view"
import { IConfigurationContext } from "contexts/configuration/context"
import {
  useOpusPermissions,
  EOpusPermission
} from "contexts/authorization/hooks/use-opus-permissions"
import ReviewView from "./Review"
import { IMoney, IReferral } from "platform-client/types"
import { IQuote, IQuoteBundle } from "platform-client/types/quote"
import { UseMutationResult } from "react-query"
import {
  IConfirmReferralQuoteProps,
  IDeclineReferralQuoteProps
} from "platform-client/client/controllers/quote"

const Review: React.FunctionComponent<IReviewProps> = (props) => {
  const { havePermission: canProcessReferral } = useOpusPermissions(
    EOpusPermission.ProcessRefferal,
    "maintain"
  )
  return (
    <ReviewView
      quote={props.quote}
      referrals={props.referrals}
      isQuoteLoading={props.isQuoteLoading}
      canProcessReferral={canProcessReferral}
      onUpdateReferral={props.onUpdateReferral}
      confirmReferralQuote={props.confirmReferralQuote}
      declineReferralQuote={props.declineReferralQuote}
    />
  )
}

export default Review

export interface IReviewProps {
  configuration: IConfigurationContext
  quote: IQuote
  isQuoteLoading?: boolean
  referrals?: IReferral[]
  onUpdateReferral?: OnUpdateReferral
  confirmReferralQuote?: UseMutationResult<
    IQuoteBundle,
    unknown,
    IConfirmReferralQuoteProps
  >
  declineReferralQuote?: UseMutationResult<
    IQuoteBundle,
    unknown,
    IDeclineReferralQuoteProps
  >
  onReviewQuote?: OnReviewQuote
  onOverridingPremium?: OnOverridePremium
  quoteRenewalReview?: IQuoteRenewalReview
  currentPrice: IMoney
  isHybrid: boolean
  onQuoteChange?: () => void
}
