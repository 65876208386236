import React from "react"
import styled from "styled-components"
import { useModal } from "services/hooks/use-modal"
import { Input, Checkbox, Divider } from "antd"
import { update, find } from "ramda"
import { OnEditEndorsements } from "../../view"
// Note that these are the old models,
// but there are no equivalients in v3
import { IProductItemOpus } from "models/quotes/productOpus"
import { IEndorsement } from "contexts/opus/context"
import { IEndorsementOpus } from "models/quotes/endorsementOpus"

const EndorsementsStyled = styled.div`
  display: flex;
  flex-direction: column;
`

const EndorsementsCheckboxStyled = styled.div`
  display: flex;
  flex-direction: column;
`

export const useEditEndorsements = (
  quoteId: string,
  productItems?: IProductItemOpus[],
  onEditEndorsements?: OnEditEndorsements
): IUseEditEndorsements => {
  const [isWorking, setIsWorking] = React.useState<boolean>(false)
  const [isDirty, setIsDirty] = React.useState<boolean>(false)
  const [comment, setComment] = React.useState<string>("")

  // An endorsement is switch on or off depending on whether the
  // product item is included. Endorsements behave inversely to the product items,
  // so if you have a product item included, then you will not have the associated
  // endorsement, and vice-versa.

  // That is why we are mapping the inverse of the product items list
  // as the endorsements list here. (See property `checked` logic.)

  const _endorsements =
    productItems && productItems.length > 0
      ? productItems.map((productItem) => ({
          referenceID: productItem.excludeEndorsementReferenceID,
          text: productItem.description,
          title: productItem.description,
          checked: !productItem.active
        }))
      : []

  const [endorsements, setEndorsements] =
    React.useState<IEndorsement[]>(_endorsements)

  const updateEndorsement = ({
    endorsement,
    i
  }: {
    endorsement: IEndorsement
    i: number
  }) => {
    const updatedEndorsements = update(i, endorsement, endorsements)
    setEndorsements(updatedEndorsements)
  }

  const mapEndorsementsToAddAndDelete = () => {
    const endorsementsToAdd: IEndorsementOpus[] = []
    const endorsementsToDelete: IEndorsementOpus[] = []

    for (const endorsement of endorsements) {
      const originalProductItem = find(
        (productItem: IProductItemOpus) =>
          productItem.excludeEndorsementReferenceID === endorsement.referenceID,
        productItems || []
      )

      // Mapping Matrix
      // --------------
      // Scenario  PI      Endorsement (inferred state)    Endorsement (final state)     Action
      // A         true    false                           false                         do nothing
      // B         true    false                           true                          add this endorsement
      // C         false   true                            true                          do nothing
      // D         false   true                            false                         delete this endorsement

      if (endorsement.checked === true) {
        // Scenario B
        if (originalProductItem?.active === true) {
          endorsementsToAdd.push({
            referenceID: endorsement.referenceID,
            text: endorsement.text,
            title: endorsement.title
          })
        }
      }

      if (endorsement.checked === false) {
        // Scenario D
        if (originalProductItem?.active === false) {
          endorsementsToDelete.push({
            referenceID: endorsement.referenceID,
            text: endorsement.text,
            title: endorsement.title
          })
        }
      }
    }

    return {
      endorsementsToAdd,
      endorsementsToDelete
    }
  }

  const {
    modal: editEndorsementsModal,
    setIsModalVisible: showEditEndorsementsModal
  } = useModal({
    title: "Edit Provisions",
    centered: true,
    destroyOnClose: true,
    okText: "Submit",
    okButtonProps: {
      disabled: !comment || !isDirty
    },
    afterClose: () => {
      setComment("")
      setEndorsements(_endorsements)
    },
    confirmLoading: isWorking,
    onOk: async () => {
      const { endorsementsToAdd, endorsementsToDelete } =
        mapEndorsementsToAddAndDelete()

      if (quoteId) {
        setIsWorking(true)

        await onEditEndorsements?.(
          {
            quoteId,
            addedEndorsements: endorsementsToAdd,
            comment,
            deletedEndorsements: endorsementsToDelete
          },
          () => showEditEndorsementsModal(false)
        )

        setIsWorking(false)
      }
    },
    children: (
      <EndorsementsStyled>
        <EndorsementsCheckboxStyled>
          {endorsements.map((endorsement, i) => (
            <Checkbox
              key={i}
              checked={endorsement.checked}
              style={{ marginLeft: "0px", marginBottom: "5px" }}
              onChange={(e) => {
                if (e.target.checked) {
                  updateEndorsement({
                    endorsement: {
                      ...endorsement,
                      ...{ checked: true }
                    },
                    i
                  })
                } else {
                  updateEndorsement({
                    endorsement: {
                      ...endorsement,
                      ...{ checked: false }
                    },
                    i
                  })
                }

                if (!isDirty) {
                  setIsDirty(true)
                }
              }}
            >
              {endorsement.text}
            </Checkbox>
          ))}
        </EndorsementsCheckboxStyled>

        <Divider />

        <label style={{ marginBottom: "10px" }}>Add comment</label>

        <Input.TextArea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          maxLength={999}
          style={{ minHeight: 100 }}
          rows={5}
          showCount
        ></Input.TextArea>
      </EndorsementsStyled>
    )
  })

  return {
    editEndorsementsModal,
    showEditEndorsementsModal
  }
}

export interface IUseEditEndorsements {
  editEndorsementsModal: React.ReactNode
  showEditEndorsementsModal: (arg: boolean) => void
}
