import { useState, useEffect } from "react"
import { TEventType } from "models/underwriting"
import { produce } from "immer"
import { TPlatformResponse } from "contexts/platform/client"
import usePlatform from "contexts/platform/use-platform"
import { useMutation } from "react-query"
import { notification } from "antd"
import { useOrgSettings } from "contexts/organisation/hooks/use-org-settings"
import { percentageToDecimal } from "utils/pocketknife/converters"
import {
  IAddCustomCoverage,
  IRemoveCoverage,
  IToggleCoverage,
  IUpdateCoverage,
  ICoverage,
  IUpdateCoverageCoverPeriod,
  ILockCustomCoverage
} from "platform-client/types"

const useCoverage: UseCoverage = (
  coverage,
  onChange,
  subsectionID,
  editSessionID
) => {
  const [_coverage, setCoverage] = useState<ICoverage>(coverage)
  const { orgSettings } = useOrgSettings()
  const { quoteBuilder } = usePlatform()

  const updateCoverage = useMutation((updateCoverage: IUpdateCoverage) =>
    quoteBuilder.updateCoverage(
      updateCoverage.editSessionID,
      updateCoverage.coverage.id,
      updateCoverage.coverage
    )
  )

  const addCustomCoverage = useMutation(
    (addCustomCoverage: IAddCustomCoverage) =>
      quoteBuilder.addCustomCoverage(
        addCustomCoverage.editSessionID,
        addCustomCoverage.coverage
      )
  )

  const removeCoverage = useMutation((removeCoverage: IRemoveCoverage) =>
    quoteBuilder.deleteCoverage(
      removeCoverage.editSessionID,
      removeCoverage.coverageID
    )
  )

  const lockCoverage = useMutation((lockCoverage: ILockCustomCoverage) =>
    quoteBuilder.lockCoverage(
      lockCoverage.editSessionID,
      lockCoverage.coverageID,
      lockCoverage.data
    )
  )

  const toggleCoverage = useMutation((props: IToggleCoverage) =>
    quoteBuilder.toggleCoverage(
      props.editSessionID,
      props.coverageID,
      props.enable
    )
  )

  const updateCoverPeriod = useMutation((props: IUpdateCoverageCoverPeriod) =>
    quoteBuilder.updateCoverageCoverPeriod(
      props.editSessionID,
      props.coverageID,
      props.data
    )
  )

  /**
   * This is required to update the state
   * when we pass down the session from above.
   */
  useEffect(() => {
    if (coverage) {
      setCoverage(coverage)
    }
  }, [coverage])

  const handleToggle = (coverage: ICoverage) => {
    // POST - /v3/quotebuilder/coverages/{coverageID}/enable
    // body - {data: { enable: boolean }, editSessionID: string}
    if (editSessionID) {
      toggleCoverage
        .mutateAsync({
          editSessionID,
          coverageID: coverage.id,
          enable: !coverage.enabled
        })
        .then((response) => {
          if (typeof onChange === "function") {
            onChange(response)
          }
        })
        .catch((error) => {
          notification.error({
            message: "Unable to update coverage, please try again"
          })
        })
    }
  }

  const handleUpdateCoverPeriod = (
    coverageID: string,
    coverStartDateTime: string,
    coverEndDateTime: string
  ) => {
    // POST - /v3/quotebuilder/coverages/{coverageID}/enable
    // body - {data: { enable: boolean }, editSessionID: string}
    if (editSessionID) {
      updateCoverPeriod
        .mutateAsync({
          editSessionID,
          coverageID: coverageID,
          data: {
            coverStartDateTime,
            coverEndDateTime
          }
        })
        .then((response) => {
          if (typeof onChange === "function") {
            onChange(response)
          }
        })
        .catch((error) => {
          notification.error({
            message: "Unable to update coverage cover dates, please try again"
          })
        })
    }
  }

  const saveCustomCoverage = async () => {
    // POST - /v3/quotebuilder/coverages/addcustom
    // body - {data: any, editSessionID: string}
    if (editSessionID) {
      try {
        /**
         * Added a switch here as i expect
         * we will need to manipulate the more
         * complex coverage types before we add them.
         */
        const formData = produce(
          { editSessionID, coverage: { ..._coverage, subsectionID } },
          (draft) => {
            switch (draft.coverage.type) {
              case "Rated":
              case "RatedWithFee":
                draft.coverage.declaredAmount = draft.coverage.sumInsured
                break
              case "NonRated":
                break
              default:
                break
            }

            return draft
          }
        )

        const response = await addCustomCoverage.mutateAsync(formData)

        if (response) {
          onChange(response)
        }
      } catch (error) {
        notification.error({
          message: "Unable to add custom coverage. Please try again."
        })
      }
    }
  }

  const handleUpdate = (event: TEventType) => {
    const path = event.target.name.split(".")
    const name = path[0] as keyof ICoverage

    setCoverage(
      produce((draft: ICoverage) => {
        switch (name) {
          case "sumInsured":
            draft.sumInsured = {
              value: event.target.value as number,
              currencyCode:
                draft.sumInsured?.currencyCode ||
                orgSettings?.currencyCode ||
                "GBP"
            }
            break
          case "declaredAmount":
            draft.declaredAmount = {
              value: event.target.value as number,
              currencyCode:
                draft.declaredAmount?.currencyCode ||
                orgSettings?.currencyCode ||
                "GBP"
            }
            break
          case "fee":
            draft.fee = {
              value: event.target.value as number,
              currencyCode:
                draft.fee?.currencyCode || orgSettings?.currencyCode || "GBP"
            }
            break
          case "rateMultiplier":
            if (["Rated", "DeclaredValueWithUplift"].includes(draft.type)) {
              draft.rateMultiplier = percentageToDecimal(
                event.target.value as number
              )
            } else {
              draft.rateMultiplier = event.target.value as number
            }
            break
          default:
            draft = { ...draft, ...{ [name]: event.target.value } }
            break
        }
        return draft
      })
    )
  }

  const handleLock = async (lock: boolean) => {
    if (editSessionID) {
      try {
        const response = await lockCoverage.mutateAsync({
          editSessionID: editSessionID,
          coverageID: coverage.id,
          data: {
            lock
          }
        })

        if (response) {
          onChange(response)
        }
      } catch (error) {
        notification.error({
          message: "Unable to lock  coverage. Please try again."
        })
      }
    }
  }

  const syncCoverage = async () => {
    // PUT - /v3/quotebuilder/coverages/{coverageID}
    // body - {data: any, editSessionID: string}
    if (editSessionID) {
      try {
        /**
         * Some coverage types need to be
         * formatted before submittion to
         * platform.
         *
         * We can use this switch statement to
         * make any amends to the structure.
         */
        const formData = produce(_coverage, (draft) => {
          if (draft.sumInsured && !draft.sumInsured.value) {
            draft.sumInsured = null
          }

          switch (draft.type) {
            case "Rated":
            case "RatedWithFee":
              draft.declaredAmount = draft.sumInsured
              break
            case "Premium":
              draft.sumInsured = draft.declaredAmount
              break
            case "DeclaredValueWithUplift":
              draft.sumInsured = null
              break
            default:
              break
          }
          return draft
        })

        if (
          formData.type !== "NonRated" &&
          (formData.sumInsured === null || formData.declaredAmount === null)
        ) {
          return
        }

        const response = await updateCoverage.mutateAsync({
          editSessionID,
          coverage: formData
        })

        if (response) {
          onChange(response)
        }
      } catch (error: unknown) {
        notification.error({
          message: "Unable to update coverage. Please try again."
        })
      }
    }
  }

  const handleRemove = async () => {
    // DELETE - /v3/quotebuilder/coverages/{coverageID}
    // body - {editSessionID: string}
    if (editSessionID) {
      try {
        const response = await removeCoverage.mutateAsync({
          editSessionID,
          coverageID: _coverage.id
        })

        if (response) {
          onChange(response)
        }
      } catch (error) {
        notification.error({
          message: "Unable to remove coverage. Please try again."
        })
      }
    }
  }

  return {
    _coverage,
    updating:
      updateCoverage.isLoading ||
      addCustomCoverage.isLoading ||
      removeCoverage.isLoading ||
      toggleCoverage.isLoading ||
      updateCoverPeriod.isLoading,
    syncCoverage,
    updateCoverage: handleUpdate,
    removeCoverage: handleRemove,
    toggleCoverage: handleToggle,
    saveCustomCoverage,
    lockCoverage: handleLock,
    updateCoverPeriod: handleUpdateCoverPeriod
  }
}

type UseCoverage = (
  coverage: ICoverage,
  onChange: (data: TPlatformResponse) => void,
  subsectionID: string,
  editSessionID?: string
) => {
  _coverage: ICoverage
  updating: boolean
  syncCoverage: () => void
  updateCoverage: (event: TEventType) => void
  removeCoverage: () => void
  toggleCoverage: (coverage: ICoverage) => void
  saveCustomCoverage: () => void
  lockCoverage: (lock: boolean, coverageID: string) => void
  updateCoverPeriod: (
    coverageID: string,
    coverStartDateTime: string,
    coverEndDateTime: string
  ) => void
}

export default useCoverage
