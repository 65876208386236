import { useState } from "react"
import { useAsyncEffect } from "use-async-effect"
import { useFirebaseAuthContext } from "../firebase-auth-contex"

export const useToken = (): IUserToken => {
  const [userToken, setUserToken] = useState<string | undefined>(undefined)

  const authContext = useFirebaseAuthContext()

  useAsyncEffect(async (isMounted) => {
    const token = await authContext.user?.getIdToken(true)
    if (isMounted() && token) {
      setUserToken(token)
    }
  }, [])

  return {
    userToken
  }
}

interface IUserToken {
  userToken: string | undefined
}
