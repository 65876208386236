import React from "react"
import { Breadcrumb, Layout } from "antd"
import { LayoutPageContent, LayoutPageHeader } from "components"
import { StopOutlined } from "@ant-design/icons"
import { EmbargoDetails } from "./components/EmbargoDetails"
import { useCreateEmbargo } from "containers/embargoes/services/hooks"
import { Link } from "react-router-dom"

export const NewEmbargo: React.FunctionComponent = (props) => {
  const {
    days,
    form,
    loading,
    isError,
    embargoes,
    onDatepickerChange,
    onAddEmbargoValues,
    onEmbargoSubmit,
    onEmbargoRemove
  } = useCreateEmbargo()

  return (
    <Layout>
      <LayoutPageHeader
        ghost={false}
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item key="/embargoes">
              <Link to="/embargoes">
                <StopOutlined />
                &nbsp;Embargoes
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="/embargoes/new">New</Breadcrumb.Item>
          </Breadcrumb>
        }
        title="Create a new embargo"
      />
      <LayoutPageContent error={isError} transparent>
        <EmbargoDetails
          days={days}
          form={form}
          loading={loading}
          embargoes={embargoes}
          onDatepickerChange={onDatepickerChange}
          onAddEmbargoValues={onAddEmbargoValues}
          onEmbargoSubmit={onEmbargoSubmit}
          onEmbargoRemove={onEmbargoRemove}
        />
      </LayoutPageContent>
    </Layout>
  )
}
