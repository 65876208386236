import React from "react"
import {
  VAllQuotes,
  VNewQuote,
  VReviewQuote,
  VChooseQuote,
  VCheckoutQuote,
  VQuote,
  VQuickQuote,
  VCreateQuote,
  VSymphony,
  VNewQuickQuote,
  VConvertQuickQuote
} from "./"
import { FileTextOutlined } from "@ant-design/icons"

export const QUOTES_ROUTES = [
  {
    path: "/quotes",
    component: VAllQuotes
  },
  {
    path: "/quotes/:status",
    component: VAllQuotes
  },
  {
    path: "/quote/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/:subpage?",
    component: VQuote
  },
  {
    path: "/quick-quote/:productID/:questionSetReferenceID/:quoteID/create",
    component: VNewQuickQuote
  },
  {
    path: "/quick-quote/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/convert",
    component: VConvertQuickQuote
  },
  {
    path: "/quick-quote/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/:subpage?",
    component: VQuickQuote
  },
  {
    path: "/review-quote/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    component: VReviewQuote
  },
  {
    path: "/opus/:section/:productRef/:productCover/:id",
    component: VNewQuote
  },
  {
    path: "/opus/:section/:productRef/:productCover/:id/:policyId/:userId",
    component: VNewQuote
  },
  {
    path: "/opus/:section/:productRef/:productCover",
    component: VNewQuote
  },
  {
    path: "/opus/:section/",
    component: VNewQuote
  },
  {
    path: "/opus/checkout/:quoteId",
    component: VCheckoutQuote
  },
  {
    path: "/create-quote/:productReference",
    component: VCreateQuote
  },
  {
    path: "/choose-quote",
    component: VChooseQuote
  },
  {
    path: "/symphony/:section/:productId/:productReference",
    component: VSymphony
  },
  {
    path: "/symphony/:section/:productId",
    component: VSymphony
  },
  {
    path: "/symphony/:section/:productId/:productReference/:quoteId",
    component: VSymphony
  }
]

export const QUOTE_NAVIGATION = [
  {
    title: "Quotes",
    path: "/quotes",
    childPaths: [
      "/quote",
      "/review-quote",
      "/opus",
      "/create-quote",
      "/symphony"
    ],
    submenu: false,
    icon: <FileTextOutlined />,
    iconActive: <FileTextOutlined />
  }
]
