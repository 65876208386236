import { Modal } from "antd"
import { debounce } from "debounce"
import { OnReferQuote } from "../../view"

export const useReferQuote = (
  quoteId: string,
  onReferQuote?: OnReferQuote
): IUseReferQuote => {
  return {
    showReferQuoteModal: () => {
      Modal.confirm({
        title: "Are you sure you want to refer this quote?",
        content: "You can't undo this action",
        centered: true,
        okText: "Refer",
        onOk: debounce(async () => {
          if (quoteId && onReferQuote) {
            await onReferQuote({ quoteId })
          }
        }, 200)
      })
    }
  }
}

export interface IUseReferQuote {
  showReferQuoteModal: () => void
}
