import React from "react"
import { Input } from "antd"
import { debounce } from "debounce"
import { useModal } from "services/hooks/use-modal"
import { OnAddNote } from "../../view"

export const useNotes = (quoteId: string, onAddNote?: OnAddNote): IUseNotes => {
  const [note, setNote] = React.useState<string | undefined>(undefined)

  const { modal: noteModal, setIsModalVisible: showNoteModal } = useModal({
    title: "Add Note",
    centered: true,
    destroyOnClose: true,
    okText: "Add Note",
    okButtonProps: { disabled: !note },
    onOk: debounce(async () => {
      if (quoteId && note && onAddNote) {
        await onAddNote({ quoteId, content: note.trim() }, (noteId) => {
          if (noteId) {
            setNote(undefined)
          }
          showNoteModal(false)
        })
      }
    }, 200),
    children: (
      <Input.TextArea
        maxLength={999}
        showCount
        onChange={(e) => setNote(e.target.value)}
        style={{ minHeight: 100 }}
      ></Input.TextArea>
    )
  })

  return {
    noteModal,
    showNoteModal
  }
}

export interface IUseNotes {
  noteModal: React.ReactNode
  showNoteModal: (arg: boolean) => void
}
