import React from "react"
import { getApiUrl } from "utils/api/apiUrl"
import OpusContext, {
  CreateOpusClient,
  ICommandClient,
  IEmbargoesClient,
  IOpusPayOnAccount,
  IPolicyClient,
  IQuoteClient,
  IRenewalClient,
  ISetQuoteWithdrawReason,
  IShiftCancellationRequest,
  IUserClient,
  IUserUpdateRequest,
  IBlacklistClient,
  IRatingsClient
} from "./context"
import Axios, { AxiosInstance, AxiosRequestConfig } from "axios"
import { path, pathOr } from "ramda"
import { ICreateEmbargoForm } from "containers/embargoes/views/NewEmbargo/components/EmbargoDetails/types"
import { LayoutLoading } from "components"
import { useMutation, useQuery } from "react-query"
import UnauthorizedView from "containers/default/views/UnauthorizedView"
import qs from "qs"
import { Nullable } from "platform-client/types"
import { useFirebaseAuthContext } from "contexts/authorization/firebase-auth-contex"

export interface IOpusProviderOptions {
  children?: React.ReactNode
}

const defaultClientOptions: AxiosRequestConfig = {
  timeout: 30000,
  baseURL: `${getApiUrl(window.location.host)}/opus/v1`,
  headers: {
    "Cache-Control": "no-cache, no-store, must-revalidate",
    Pragma: "no-cache",
    "Content-Type": "application/json",
    Accept: "application/json"
  }
}

export const createOpusClient: CreateOpusClient = (
  token,
  options
): AxiosInstance => {
  return Axios.create({
    ...defaultClientOptions,
    ...options,
    headers: {
      authorization: `Bearer ${token}`
    }
  })
}

const sessionStart = async (
  userToken: string,
  options?: AxiosRequestConfig
) => {
  return createOpusClient(userToken)
    .get(`${getApiUrl(window.location.host)}/opus/session/start`, options)
    .then(pathOr("", ["data", "data"]))
}

const OpusProvider = (opts: IOpusProviderOptions): JSX.Element => {
  const authContext = useFirebaseAuthContext()

  const { children } = opts

  const {
    data: userToken,
    isLoading: isUserLoading,
    isError: isUserError
  } = useQuery(
    "",
    async () => {
      const token = await authContext.user?.getIdToken(true)
      return token
    },
    { enabled: !!authContext.user }
  )

  const {
    data: token,
    isLoading: isTokenLoading,
    isError: isTokenError
  } = useQuery<string>(
    ["opusToken", userToken],
    () => {
      if (userToken === undefined) {
        throw new Error("auth token not found.")
      }

      return sessionStart(userToken)
    },
    {
      enabled: !!userToken
    }
  )

  const fetchToken = useMutation<
    string,
    unknown,
    {
      userToken: string
      options?: AxiosRequestConfig
    }
  >(({ userToken, options }) => sessionStart(userToken, options))

  const getToken = async (options?: AxiosRequestConfig) => {
    if (userToken === undefined) {
      throw new Error("Auth token not found.")
    }

    return await fetchToken.mutateAsync({ userToken, options })
  }

  const opusGet = async (url: string, options?: AxiosRequestConfig) => {
    return createOpusClient(token as string).get(url, options)
  }

  const opusPost = async (
    url: string,
    data?: unknown,
    options?: AxiosRequestConfig
  ) => {
    const opusToken = token || (await getToken())
    return createOpusClient(opusToken as string).post(url, data, options)
  }

  const opusPut = async (
    url: string,
    data?: unknown,
    options?: AxiosRequestConfig
  ) => {
    const opusToken = token || (await getToken())
    return createOpusClient(opusToken as string).put(url, data, options)
  }

  const opusDelete = async (
    url: string,
    data?: unknown,
    options?: AxiosRequestConfig
  ) => {
    const opusToken = token || (await getToken())
    return createOpusClient(opusToken as string).delete(url, options)
  }

  const embargoesClient: IEmbargoesClient = {
    getEmbargoes: async () =>
      opusGet(`embargoes/all`).then(path(["data", "data"])),

    addEmbargo: async (data: ICreateEmbargoForm) =>
      opusPost("embargoes", data).then(path(["data", "data"])),

    getEmbargo: async (id: string) =>
      opusGet(`embargoes/${id}`).then(path(["data", "data"])),

    setEmbargoCancel: async (id: string) =>
      opusPost(`/embargoes/${id}/cancel/now`).then(path(["data", "data"]))
  }

  const renewalClient: IRenewalClient = {
    setActiveRenewalQuote: async (policyID, quoteID) =>
      opusPost(`renewal/${policyID}/setquote?quoteID=${quoteID}`).then(
        path(["data", "data"])
      ),
    duplicateRenewalQuote: async (policyID, quoteID) =>
      opusPost(`renewal/${policyID}/duplicatequote?quoteID=${quoteID}`).then(
        path(["data", "data"])
      )
  }

  const commandClient: ICommandClient = {
    regenerateDocument: async (quoteID, policyID, isPolicyCancellation) =>
      opusPost(`command`, {
        command: `job queue generatedocument ${
          policyID ? `--policyID ${policyID}` : ""
        } ${quoteID ? `--quoteID ${quoteID}` : ""} ${
          isPolicyCancellation ? "--documentType policyCancellation" : ""
        } --generateAction GenerateOnly`,
        commands: []
      }).then(path(["data", "data"]))
  }

  const payOnAccount: IOpusPayOnAccount = {
    bindQuote: async (
      quoteID,
      paymentPlanReferenceID,
      encryptedDiscount,
      paymentReference
    ) => {
      const response = await opusPost(`payonaccount/${quoteID}/bind`, {
        paymentPlanReferenceID,
        encryptedDiscount,
        paymentReference
      })

      return response.data.data
    }
  }

  const policyClient: IPolicyClient = {
    getPolicyHistory: async (policyId: string) =>
      opusGet(`/policies/${policyId}`).then(path(["data", "data"])),

    getPolicies: async () =>
      opusGet(`/portal/policies/list`).then(path(["data", "data"])),

    getPolicyEvents: async (policyID: string) =>
      opusGet(`/policies/${policyID}/events`).then(path(["data", "data"])),

    getPolicyBundle: async (policyId: string) =>
      opusGet(`/policies/${policyId}/bundle`).then(path(["data", "data"])),

    getPolicyActions: async (policyId: string) =>
      opusGet(`/policies/${policyId}/actions`).then(path(["data", "data"])),

    getPolicyRenewals: async (policyId: string) =>
      opusGet(`renewal/${policyId}/state`).then(path(["data", "data"])),

    getPolicyRenewalsSettings: async (policyProductID: string) =>
      opusGet(`product/${policyProductID}/renewalsettings`).then(
        path(["data", "data"])
      ),

    getPolicyRenewalQuotes: async (policyId: string) =>
      opusGet(`policies/${policyId}/renewalquotes`, {
        params: {
          completeOnly: false
        }
      }).then(path(["data", "data"])),

    getPolicySearch: async (searchQuery: string) =>
      opusGet(`/search`, {
        params: {
          q: searchQuery,
          entityType: "Policy"
        }
      }).then((response) => response.data.data),

    setPolicyRenewalQuote: async (policyID: string) =>
      opusPost(`/renewal/${policyID}/createquote`, { policyID }).then(
        path(["data", "data"])
      ),

    setPolicyRenewalWithdraw: async (policyID: string) =>
      opusPost(`/renewal/${policyID}/withdraw`, { policyID }).then(
        path(["data", "data"])
      ),
    setPolicyRenewals: async (
      values: {
        requiresReview: boolean
        allowAutoBind: boolean
        doNotRenew: boolean
      },
      policyID: string
    ) =>
      opusPost(`/renewal/${policyID}/settings`, values).then(
        path(["data", "data"])
      ),
    getPolicyCancelInfo: async (policyID) =>
      opusGet(`policies/cancel/${policyID}/info`).then((response) => {
        return response.data.data
      }),

    getPolicyCancellationInformation: async (policyID, request) =>
      opusPost(`policies/cancel/${policyID}/information`, request).then(
        (res) => res.data.data
      ),

    setPolicyCancellation: async (subjectuserid, requestBody) => {
      const newToken = await getToken({ params: { subjectuserid } })
      return createOpusClient(newToken as string)
        .post("policies/cancel", requestBody)
        .then((response) => {
          return response.data.data
        })
    },
    reinstatePolicy: async (policyID, reinstatement) =>
      opusPost(`policies/${policyID}/reinstate`, reinstatement).then(
        (response) => response.data.data
      ),
    updateCoverStartDate: async (policyID: string, newCoverStartDate: string) =>
      opusPost(
        `/policies/${policyID}/updatecoverstart?newCoverStart=${newCoverStartDate}`
      ).then((response) => response.data.data),
    getPolicyPaymentPlan: async (policyId: string) =>
      opusGet(`/paymentplan?policyID=${policyId}`).then(path(["data", "data"])),
    manualRefund: async (subjectUserID, requestBody) => {
      const newToken = await getToken({ params: { subjectUserID } })
      return createOpusClient(newToken as string)
        .post("payments/manual/refund", requestBody)
        .then((response) => {
          return response.data.data
        })
    },
    manualPayment: async (subjectUserID, requestBody) => {
      const newToken = await getToken({ params: { subjectUserID } })
      return createOpusClient(newToken as string)
        .post("payments/manual/payment", requestBody)
        .then((response) => {
          return response.data.data
        })
    },
    getPolicyPaymentMethod: async (policyID: string) =>
      opusGet(`policies/${policyID}/paymentmethod`).then(
        (response) => response.data.data
      ),
    payScheduledPayment: async (scheduledPaymentId: string) =>
      opusPost(`/paymentplan/${scheduledPaymentId}/pay`).then(
        (response) => response.data.data
      ),
    getPolicyPayments: async (policyID: string) =>
      opusGet(`policies/${policyID}/payments`).then(
        (response) => response.data.data
      ),
    downloadTransactionReceipt: async (policyID: string, paymentID: string) =>
      opusGet(`policies/${policyID}/payments/${paymentID}/downloadreceipt`, {
        responseType: "arraybuffer"
      }).then((response) => {
        return response
      }),
    emailTransactionReceipt: async (
      policyID: string,
      paymentID: string,
      emailAddress: string
    ) =>
      opusPost(
        `policies/${policyID}/payments/${paymentID}/emailreceipt?emailAddress=${encodeURIComponent(
          emailAddress
        )}`
      ).then((response) => {
        return response.data.data
      }),
    getTransactionalMessages: async (entityID: string) =>
      opusGet(`messages?entityID=${entityID}`).then(
        (response) => response.data.data
      ),
    resendTransactionalEmail: async (id: string, emailAddress: string) =>
      opusPost(
        `messages/resendemail/${id}?emailAddress=${encodeURIComponent(
          emailAddress
        )}`
      ).then((response) => {
        return response.data.data
      }),
    resendDocument: async (
      documentId: string,
      quoteId: Nullable<string>,
      policyId: Nullable<string>,
      emailAddress: string,
      isCancellation: boolean
    ) =>
      opusPost(`document/${documentId}/resend`, {
        quoteId,
        policyId,
        emailAddress,
        isCancellation
      }).then((response) => {
        return response.data.data
      }),
    shiftCancellation: async (request: IShiftCancellationRequest) =>
      opusPost(`policies/${request.policyID}/shiftcancellation`, request).then(
        (response) => {
          return response.data.data
        }
      )
  }

  const userClient: IUserClient = {
    getUsers: async () =>
      opusGet("opususers/list").then(path(["data", "data"])),

    getUsersGroups: async () =>
      opusGet("opusorgs/groups/list").then(path(["data", "data"])),

    setUserInvite: async (data: unknown) =>
      opusPost(`opususers/invite`, data).then((response) => response),

    addUser: async (data: unknown) =>
      opusPost(`opususers/add`, data).then((response) => response),

    editUser: async (userId: string, data: IUserUpdateRequest) =>
      opusPut(`opususers/${userId}`, data).then(path(["data", "data"])),

    getUser: async (userId: string) =>
      opusGet(`opususers/${userId}`).then(path(["data", "data"])),

    setUserDisable: async (userID: string) =>
      opusPost(`opususers/${userID}/disable`, {}).then(path(["data", "data"])),

    setUserResend: async (userID: string) =>
      opusPost(`opususers/${userID}/sendinvite`, {}).then(
        path(["data", "data"])
      ),

    setUserGroup: async (data: { name: string }) =>
      opusPost(`opusorgs/groups/add`, data).then(path(["data", "data"])),

    createUser: async (userEmail: string) =>
      opusPost(`user/create`, { email: userEmail }).then(
        path(["data", "data"])
      ),

    search: async (query, userRoles) =>
      opusGet(`opususers/search`, { params: { query, userRoles } }).then(
        path(["data", "data"])
      )
  }

  const quoteClient: IQuoteClient = {
    setQuoteReview: async (quoteID: string, reviewValue: boolean) =>
      opusPost(`renewal/quote/${quoteID}/review?result=${reviewValue}`).then(
        path(["data", "data"])
      ),

    getQuotes: async (quoteTypes: string[], status?: string) => {
      return opusGet("portal/quotes/list", {
        params: {
          quoteState: status,
          quoteTypes: quoteTypes
        },
        paramsSerializer: (params) => {
          return qs.stringify(params)
        }
      }).then(path(["data", "data"]))
    },
    getQuoteBundle: async (quoteID: string) =>
      opusGet(`/quotes/${quoteID}/bundle`).then(path(["data", "data"])),

    getQuote: async (quoteID: string) =>
      opusGet(`/quotes/${quoteID}`).then(path(["data", "data"])),

    getQuotePaymentPlans: (quoteID: string) =>
      opusGet(`/quotes/${quoteID}/paymentplans`).then(path(["data", "data"])),

    getQuoteProduct: async (quoteID: string) =>
      opusGet(`/quotes/${quoteID}/product`).then(path(["data", "data"])),

    getQuoteNotes: async (quoteID: string) =>
      opusGet(`/notes/quote/${quoteID}`).then(path(["data", "data"])),

    getQuoteEvents: async (quoteID: string) =>
      opusGet(`/quotes/${quoteID}/events`).then(path(["data", "data"])),

    getQuoteSearch: async (searchQuery: string) =>
      opusGet(`/search`, {
        params: {
          q: searchQuery,
          entityType: "Quote"
        }
      }).then(path(["data", "data"])),

    getQuoteDocuments: async (quoteID: string) =>
      opusGet(`/quotes/${quoteID}/documents`).then(path(["data", "data"])),

    getQuotesWithdrawReasons: async (quoteID: string) =>
      opusGet(`/quotes/${quoteID}/withdrawalreasons`).then(
        path(["data", "data"])
      ),

    getQuoteReview: async (quoteID: string) =>
      opusGet(`/quotes/${quoteID}/statementoffact`).then(
        path(["data", "data"])
      ),

    getQuoteRenewalReview: async (quoteID: string) =>
      opusGet(`/quotes/${quoteID}/renewal`).then(path(["data", "data"])),

    setQuoteWithdraw: async (quoteID: string, data: ISetQuoteWithdrawReason) =>
      opusPost(`/quotes/${quoteID}/withdraw`, data).then(
        path(["data", "data"])
      ),

    setQuoteNote: async (
      quoteID: string,
      data: {
        content: string | undefined
      }
    ) => opusPost(`/notes/quote/${quoteID}`, data).then(path(["data", "data"])),

    setQuoteRefer: async (quoteID: string) =>
      opusPost(`/quotes/${quoteID}/refer`, {}).then(path(["data", "data"])),

    addRemoveEndorsements: async (
      quoteID,
      addedEndorsements,
      comment,
      deletedEndorsements
    ) =>
      opusPost(`/quotes/${quoteID}/edit/endorsements`, {
        addedEndorsements,
        comment,
        deletedEndorsements
      }).then(path(["data", "data"])),

    editEndorsement: async (quoteID, referenceID, title, text) =>
      opusPost(`/quotes/${quoteID}/edit/endorsements/${referenceID}`, {
        title,
        text
      }).then(path(["data", "data"])),

    setQuoteConfirm: async (quoteID: string, comment?: string) =>
      opusPost(`/quotes/${quoteID}/confirm`, { comment }).then(
        path(["data", "data"])
      ),

    setQuotePaymentLink: async (quoteID: string) =>
      opusPost(`/quotes/${quoteID}/paymentlink`, {}).then(
        path(["data", "data"])
      ),

    getQuotePaymentLink: async (quoteID: string) =>
      opusGet(`/quotes/${quoteID}/paymentlink`).then(path(["data", "data"])),

    invalidateQuotePaymentLink: async (quoteID: string) =>
      opusPost(`/quotes/${quoteID}/invalidatepaymentlink`).then(
        path(["data", "data"])
      ),

    assignQuoteToUser: async (quoteID: string, userID: string) =>
      opusGet(`/quotes/${quoteID}/assignuser?userID=${userID}`).then(
        path(["data", "data"]),
        (error) => {
          // This error code is returned when the quote is already
          // assigned to the specific user. This feels a little hacky
          // but I've chosen to lets the client return a success in this
          // scenario as while the action has failed, it failed because
          // the desired result is there.
          if (error.response.data.error === 4) {
            return true
          } else {
            return false
          }
        }
      ),
    referQuote: async (quoteID: string, comment?: string) =>
      opusPost(`/quotes/${quoteID}/refer`, { comment }).then(
        path(["data", "data"])
      ),
    anonymiseQuote: async (quoteID: string) =>
      opusPost(`/quotes/${quoteID}/anonymise`).then(path(["data", "data"]))
  }

  const blacklistClient: IBlacklistClient = {
    getBlacklist: async () =>
      opusGet("phonenumberblacklist").then(path(["data", "data"])),
    blacklistPhoneNumber: async (phoneNumber: string) =>
      opusPost(`phonenumberblacklist`, {
        phoneNumber
      }).then(path(["data", "data"])),
    removePhoneNumberFromBlacklist: async (phoneNumber: string) =>
      opusDelete(
        `phonenumberblacklist/${encodeURIComponent(phoneNumber)}`
      ).then(path(["data", "data"])),
    phoneNumberIsBlacklisted: async (phoneNumber: string) =>
      opusGet(`phonenumberblacklist/isBlacklisted`, {
        params: {
          phoneNumber: encodeURIComponent(phoneNumber)
        }
      }).then(path(["data", "data"]))
  }

  const ratingsClient: IRatingsClient = {
    list: async () => opusGet("ratings/list").then(path(["data", "data"])),
    productRateVersions: async () =>
      opusGet("ratings/products").then(path(["data", "data"])),
    uploadRatings: async (data) => {
      const response = await opusPost("ratings/upload", data, {
        headers: {
          "content-type": "multipart/form-data"
        },
        timeout: 180000
      })

      return response.data.data
    },
    updateGoLiveDate: async (rateFileID: string, newGoLiveDate: string) =>
      opusPost(
        `/ratings/${rateFileID}/updategolivedate?newGoLiveDate=${newGoLiveDate}`
      ).then((response) => response.data.data)
  }

  if (isTokenLoading || isUserLoading) {
    return <LayoutLoading withWrapper />
  }

  if (isUserError || isTokenError) {
    return <UnauthorizedView />
  }

  return (
    <OpusContext.Provider
      value={{
        opusToken: token,
        getToken,
        userClient,
        quoteClient,
        policyClient,
        renewalClient,
        payOnAccount,
        embargoesClient,
        commandClient,
        blacklistClient,
        ratingsClient
      }}
    >
      {token ? children : <LayoutLoading withWrapper />}
    </OpusContext.Provider>
  )
}

export default OpusProvider
