import { QuoteState, QuoteStatus } from "platform-client/types/quote"

type IsQuoteComplete = (state: QuoteState, status: QuoteStatus) => boolean

export const isQuoteComplete: IsQuoteComplete = (state, status) => {
  // Attempting to map what determins an incomplete quote
  // Please add to this where required.

  if (status === "Draft") {
    return false
  } else if (state === "NotSet" || state === "Open") {
    return false
  }

  return true
}
