import { useMemo } from "react"
import { Select, Space } from "antd"
import { SelectValue } from "antd/lib/select"
import { useHistory } from "react-router-dom"
const { Option } = Select

interface ISearchCompanies {
  options: { name: string; id: string }[]
}

export const getCompanyId =
  (companiesList: { name: string; id: string }[]) =>
  (selectedCompany: SelectValue): string | undefined => {
    const { id } =
      companiesList?.find(({ name }) => name === selectedCompany) || {}
    return id
  }
export const SearchCompanies: React.FC<ISearchCompanies> = ({ options }) => {
  const history = useHistory()
  const searchParams = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search]
  )

  const value = useMemo(() => {
    const companyId = searchParams.get("companyId")
    return options?.find(({ id }) => companyId === id)?.name
  }, [options, searchParams])

  const extractCompanyId = useMemo(() => getCompanyId(options), [options])

  const handleChange = (companyName: string): void => {
    const companyId = extractCompanyId(companyName)
    if (companyId) {
      searchParams.set("companyId", companyId)
    } else {
      searchParams.delete("companyId")
    }

    history.push({
      pathname: history.location.pathname,
      search: searchParams.toString()
    })
  }

  return (
    <Space style={{ marginLeft: 16 }}>
      <Select
        showSearch
        allowClear
        value={value}
        style={{ minWidth: 425 }}
        placeholder="Select Company"
        onChange={handleChange}
      >
        {(options || []).map(({ name, id }) => (
          <Option key={id} value={name} label={name}>
            {name}
          </Option>
        ))}
      </Select>
    </Space>
  )
}
