import usePlatform from "contexts/platform/use-platform"
import React, { useState } from "react"
import { useQuery } from "react-query"
import { IPolicyholder } from "platform-client/types"
import { isObjectNull } from "utils/pocketknife/object-is-null"
import { StringIsEmptyNullable } from "utils/pocketknife/string-is-empty"
import ListCard from "components/ListCard"
import { Address } from "components/Address"
import { LayoutCardEmpty } from "components/LayoutCardEmpty"
import { CardHeader } from "./PolicyholderCardHeader"
import { Button } from "antd"

export const PolicyHolder: React.FunctionComponent<IPolicyHolderInfo> = (
  props
) => {
  const [policyHolderUsage, setpolicyHolderUsage] = useState<
    IPolicyholder | undefined | null
  >(props.policyHolder)
  const platform = usePlatform()

  const { isLoading } = useQuery(
    ["policyHolder", props.referenceId],
    async () => platform.policyholders.getPolicyholder(props.referenceId),
    {
      enabled: !!props.referenceId && !props.policyHolder,
      onSuccess: (result) => {
        setpolicyHolderUsage(result)
      }
    }
  )

  if (
    isLoading === false &&
    StringIsEmptyNullable(policyHolderUsage?.legalName) &&
    isObjectNull(policyHolderUsage?.contact?.name) &&
    isObjectNull(policyHolderUsage?.contactAddress) &&
    StringIsEmptyNullable(policyHolderUsage?.contact?.emailAddress) &&
    !policyHolderUsage?.contact?.phoneNumber
  ) {
    return (
      <LayoutCardEmpty
        title="Policyholder"
        className={props.className}
        emptyText="No Policyholder details"
      />
    )
  }

  return (
    <>
      <ListCard
        loading={isLoading}
        title={
          <CardHeader title="Policyholder">
            {typeof props.onEditClick === "function" && (
              <Button
                onClick={() => {
                  if (props.onEditClick) props.onEditClick()
                }}
              >
                Edit
              </Button>
            )}
          </CardHeader>
        }
        className={props.className}
      >
        {policyHolderUsage !== undefined && policyHolderUsage !== null && (
          <>
            {policyHolderUsage?.legalName &&
              !StringIsEmptyNullable(policyHolderUsage?.legalName) && (
                <ListCard.Item label="Legal Name">
                  {policyHolderUsage?.legalName}
                </ListCard.Item>
              )}
            {policyHolderUsage?.contact &&
              !isObjectNull(policyHolderUsage?.contact?.name) && (
                <ListCard.Item label="Contact Name">
                  {`${
                    policyHolderUsage.contact.name.title
                      ? policyHolderUsage.contact.name.title
                      : ""
                  } ${
                    policyHolderUsage?.contact.name.firstName
                      ? policyHolderUsage?.contact.name.firstName
                      : ""
                  } ${
                    policyHolderUsage.contact.name.lastName
                      ? policyHolderUsage.contact.name.lastName
                      : ""
                  }`}
                </ListCard.Item>
              )}
            {policyHolderUsage?.contact?.emailAddress &&
              !StringIsEmptyNullable(
                policyHolderUsage.contact?.emailAddress
              ) && (
                <ListCard.Item label="Contact Email address">
                  {policyHolderUsage?.contact?.emailAddress}
                </ListCard.Item>
              )}

            {policyHolderUsage?.businessName && (
              <ListCard.Item label="Business Name">
                {`${policyHolderUsage?.businessName}`}
              </ListCard.Item>
            )}
            {policyHolderUsage?.contact &&
              policyHolderUsage?.contact?.phoneNumber && (
                <ListCard.Item label="Contact Telephone">
                  {policyHolderUsage?.contact?.phoneNumber}
                </ListCard.Item>
              )}
            {!isObjectNull(policyHolderUsage?.contactAddress) && (
              <ListCard.Item label="Contact Address">
                <Address address={policyHolderUsage?.contactAddress} />
              </ListCard.Item>
            )}
          </>
        )}
      </ListCard>
    </>
  )
}

export interface IPolicyHolderInfo {
  referenceId?: string
  policyHolder?: IPolicyholder
  className?: string
  onEditClick?: (id?: string) => void
}
