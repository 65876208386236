import React, { useState } from "react"
import { Table, Alert, Button, Space, Modal } from "antd"
import { IDocument, Nullable } from "platform-client/types"
import { isEmpty } from "ramda"
import { DateDisplay } from "components/display/DateDisplay"
import {
  FileTextOutlined,
  ReloadOutlined,
  SendOutlined
} from "@ant-design/icons"
import { useResendDocument } from "contexts/opus/hooks/use-resend-document"
import { ConfirmEmailModal } from "../../../../../../components/ConfirmEmailModal"
import { useRegenerateDocument } from "contexts/opus/hooks/use-regenerate-document"
import {
  DocTypeMappings,
  PolicyCancellationReference
} from "utils/data/document-mappings"

export const PolicyDocumentsView: React.FC<IPolicyDocumentsViewProps> = (
  props
) => {
  const [selectedDocument, setSelectedDocument] =
    useState<Nullable<IDocument>>(null)

  const { isResending, resendDocument } = useResendDocument()

  const { isRegenerating, regenerateDocument } = useRegenerateDocument()

  const previewDocument = (document: IDocument) => {
    return () => {
      if (typeof document.documentUrl === "string") {
        window.open(document.documentUrl, "_blank")
      }
    }
  }

  const resendDoc = async (emailAddress: string) => {
    selectedDocument &&
      (await resendDocument(
        selectedDocument.directDocumentID,
        null,
        props.policyId ?? "",
        emailAddress,
        selectedDocument.referenceID === "avt_doc_p_canx_default"
      ))
    setSelectedDocument(null)
  }

  const modalRegenerateDocument = (document: IDocument) =>
    Modal.confirm({
      title: "Are you sure you want to regenerate this document?",
      content: "You won't be able to undo this action",
      centered: true,
      okText: "Regenerate",
      onOk: async () => {
        await regenerateDocument(
          null,
          props.policyId ?? "",
          document.referenceID === PolicyCancellationReference
        )
      }
    })

  return (
    <>
      {!isEmpty(props.documents) ? (
        <>
          <Table
            loading={props?.loading}
            dataSource={props.documents}
            scroll={{ x: "max-content" }}
            rowKey="id"
            pagination={{
              defaultPageSize: 10,
              hideOnSinglePage: true,
              position: ["bottomCenter"],
              size: "small"
            }}
          >
            <Table.Column
              dataIndex="fileName"
              title="Document Name"
              render={(value: string, document: IDocument) => (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={document.documentUrl ?? ""}
                >
                  {value}
                </a>
              )}
            />
            <Table.Column
              dataIndex="referenceID"
              title="Document Type"
              render={(value: string) => DocTypeMappings[value]}
            />
            <Table.Column
              dataIndex="localDateCreated"
              title="Created"
              render={(value: string) => <DateDisplay date={value} />}
            />
            <Table.Column
              key="tools"
              align="right"
              render={(document: IDocument, _, index) => (
                <Space>
                  <Button
                    icon={<FileTextOutlined />}
                    size="small"
                    onClick={previewDocument(document)}
                    disabled={!(typeof document.documentUrl === "string")}
                    loading={
                      document.policyDocumentStatus === "PendingGeneration"
                    }
                  >
                    Preview
                  </Button>
                  {index === 0 && (
                    <Button
                      icon={<ReloadOutlined />}
                      size="small"
                      onClick={() => modalRegenerateDocument(document)}
                      loading={isRegenerating}
                    >
                      Regenerate
                    </Button>
                  )}
                  <Button
                    icon={<SendOutlined />}
                    size="small"
                    onClick={() => setSelectedDocument(document)}
                  >
                    Send
                  </Button>
                </Space>
              )}
            />
          </Table>
          <ConfirmEmailModal
            emailAddress={props.emailAddress ?? ""}
            title={`Send document ${selectedDocument?.fileName}`}
            description="Please provide the email address you would like to send the
          document to."
            isVisible={!!selectedDocument}
            onCancel={(e) => setSelectedDocument(null)}
            onSend={resendDoc}
            isLoading={isResending}
          />
        </>
      ) : (
        <Alert message="There are no documents available." />
      )}
    </>
  )
}

export interface IPolicyDocumentsViewProps {
  policyId?: string
  documents: IDocument[] | undefined
  loading?: boolean
  emailAddress?: string
}
