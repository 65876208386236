import Axios, { AxiosRequestConfig, AxiosInstance } from "axios"
import * as rax from "retry-axios"
import { getApiUrl } from "./apiUrl"

export const options = (token: string | undefined): AxiosRequestConfig => ({
  timeout: 30000,
  baseURL: `${getApiUrl(window.location.host)}/opus/v1`,
  headers: {
    "Cache-Control": "no-cache, no-store, must-revalidate",
    Pragma: "no-cache",
    "Content-Type": "application/json",
    Accept: "application/json",
    authorization: `Bearer ${token}`
  }
})

export const unAuthenticatedOptions = (): AxiosRequestConfig => ({
  timeout: 30000,
  baseURL: getApiUrl(window.location.host),
  headers: {
    "Cache-Control": "no-cache, no-store, must-revalidate",
    Pragma: "no-cache",
    "Content-Type": "application/json",
    Accept: "application/json"
  }
})

export const createOpusClient = (token: string | undefined): AxiosInstance =>
  Axios.create({
    ...options(token)
  })

export const createUnauthenticatedPlatformClient = (): AxiosInstance => {
  const _unauthenticatedPlatformClient = Axios.create({
    ...unAuthenticatedOptions()
  })

  ;(_unauthenticatedPlatformClient.defaults as rax.RaxConfig).raxConfig = {
    instance: _unauthenticatedPlatformClient,
    retry: 3
  }
  rax.attach(_unauthenticatedPlatformClient)

  return _unauthenticatedPlatformClient
}
