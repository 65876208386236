import React from "react"
import Row from "components/Console/Row"
import CellContentText from "components/Console/CellContent/Text"
import CellContentTitle from "components/Console/CellContent/Title"
import CellContentMoney from "components/Console/CellContent/Money"
import CellContentRate, { RateType } from "components/Console/CellContent/Rate"
import { isEmpty } from "ramda"
import { Table } from "components/Console/Table"
import {
  IFinanceTax,
  FinanceTaxType,
  IMoney,
  Nullable
} from "platform-client/types"
import { IOrgSettings } from "contexts/organisation/context"

const TAX_TYPES: Record<FinanceTaxType, RateType> = {
  Percentage: "Percentage",
  FixedRate: "Fixed",
  None: "None"
}

const TaxesAndTotals: React.FC<ITaxesAndTotals> = ({
  reference = "Taxes & Total Premium",
  summaryClosedText,
  summaryOpenText,
  taxValues,
  premiumafterCommission,
  totalCoverageFees,
  notExpandable,
  startExpanded,
  organisationSettings
}): JSX.Element => {
  const taxes = taxValues || []
  const [firstValue, ...restValues] = taxes

  const afterTaxes = () => {
    let summaryValue = premiumafterCommission?.value || 0

    taxes.map((item) => {
      const itemTax = summaryValue * item.ruleAmount
      summaryValue = summaryValue + itemTax

      return item
    })

    return summaryValue
  }

  return (
    <Table.HalfTable
      notExpandable={notExpandable}
      reference={reference}
      startExpanded={!notExpandable && startExpanded}
      {...(firstValue
        ? {
            firstRowValues: [
              <CellContentText align="right">
                {firstValue.name}
              </CellContentText>,
              <CellContentRate
                value={firstValue?.ruleAmount}
                type={
                  firstValue.taxType ? TAX_TYPES[firstValue.taxType] : undefined
                }
              />,
              <CellContentMoney
                value={firstValue.amount?.value || 0}
                currencyCode={
                  firstValue.amount?.currencyCode ||
                  organisationSettings?.currencyCode
                }
              />
            ]
          }
        : { firstRowValues: [] })}
      summaryValues={[
        <CellContentTitle align="right">{summaryClosedText}</CellContentTitle>,
        null,
        premiumafterCommission ? (
          <CellContentMoney {...premiumafterCommission} />
        ) : (
          <CellContentText align="right">-</CellContentText>
        )
      ]}
      summaryProps={{ highLight: { values: [1, 2, 3, 4], control: true } }}
    >
      {!isEmpty(restValues) &&
        restValues.map((tax) => (
          <Row
            type="halftable"
            values={[
              <CellContentText align="right">{tax.name}</CellContentText>,
              <CellContentRate
                value={tax.ruleAmount}
                type={tax.taxType ? TAX_TYPES[tax.taxType] : undefined}
              />,
              <CellContentMoney
                value={tax.amount?.value || 0}
                currencyCode={
                  tax.amount?.currencyCode || organisationSettings?.currencyCode
                }
              />
            ]}
          />
        ))}
      {totalCoverageFees !== null && (
        <Row
          type="halftable"
          values={[
            <CellContentText align="right">
              Fees from coverages
            </CellContentText>,
            null,
            <CellContentMoney {...totalCoverageFees} />
          ]}
        />
      )}
      <Row
        type="halftable"
        highLight={{ values: [0, 1, 2, 3, 4], control: true }}
        values={[
          <CellContentText align="right" strong>
            {summaryOpenText}
          </CellContentText>,
          null,
          premiumafterCommission ? (
            <CellContentMoney
              {...premiumafterCommission}
              value={afterTaxes()}
            />
          ) : (
            <CellContentText align="right">-</CellContentText>
          )
        ]}
      />
    </Table.HalfTable>
  )
}

export interface ITaxesAndTotals {
  reference?: string
  notExpandable?: boolean
  summaryClosedText: string
  summaryOpenText: string
  taxValues?: Nullable<IFinanceTax[]>
  premiumafterCommission: Nullable<IMoney>
  totalCoverageFees: Nullable<IMoney>
  startExpanded?: boolean
  organisationSettings?: IOrgSettings
}

export default TaxesAndTotals
