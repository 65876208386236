import { useState } from "react"
import { useAsyncEffect } from "use-async-effect"
import { TErrorState } from "models/errors"
import {
  errorValidationMessage,
  IErrorState
} from "utils/pocketknife/error-validation-message"
import { TPolicyRenewalsSettings } from "models/policies/policyRenewals"
import useOpus from "contexts/opus/hooks/use-opus"

export const useGetPolicyRenewalsSettings = (
  policyProductID: string | undefined
): IPolicyHistory => {
  const [policyRenewalsSettings, setPolicyRenewalsSettings] = useState<
    TPolicyRenewalsSettings | undefined
  >(undefined)
  const [isError, setIsError] = useState<TErrorState>(undefined)
  const [isPolicyRenewalsSettingsLoading, setPolicyRenewalsSettingsLoading] =
    useState<boolean>(false)
  const { policyClient } = useOpus()

  useAsyncEffect(async () => {
    try {
      setPolicyRenewalsSettingsLoading(true)
      if (policyProductID) {
        const _renewalsSettings = await policyClient.getPolicyRenewalsSettings(
          policyProductID
        )

        if (_renewalsSettings) {
          setPolicyRenewalsSettings(_renewalsSettings)
          setIsError(undefined)
        }
      }
      setPolicyRenewalsSettingsLoading(false)
    } catch (error) {
      setPolicyRenewalsSettings(undefined)
      setIsError({
        errorNumber: error?.response?.status,
        validationMessage: errorValidationMessage(error)
      })
      setPolicyRenewalsSettingsLoading(false)
    }
  }, [policyProductID])

  return {
    policyRenewalsSettings,
    isError,
    isPolicyRenewalsSettingsLoading
  }
}

interface IPolicyHistory extends IErrorState {
  policyRenewalsSettings: TPolicyRenewalsSettings | undefined
  isPolicyRenewalsSettingsLoading: boolean
}
