import React from "react"
import { Card, Skeleton } from "antd"
export const LayoutCardLoader: React.FunctionComponent<ILayoutCardLoader> = (
  props
) => (
  <Card title={props.title} style={props.style}>
    <Skeleton loading={true}></Skeleton>
  </Card>
)

export interface ILayoutCardLoader {
  title?: string
  style?: React.CSSProperties
}
