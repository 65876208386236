import usePlatform from "contexts/platform/use-platform"
import { match, useRouteMatch } from "react-router"
import { useQuery, useMutation } from "react-query"
import { ICommissionSetup } from "platform-client/types/commission"
import { CommissionTable } from "./CommissionTable"
import { Card, notification, Spin, Typography } from "antd"
import { useConfigurationContext } from "contexts/configuration/context"
import styled from "styled-components"

const StyledContainer = styled.div`
  margin-bottom: 24px;
`

interface ICommissionsProps {
  companyName?: string
}

export const Commissions: React.FC<ICommissionsProps> = (props) => {
  const platform = usePlatform()
  const { app: { organisation = "" } = {} } = useConfigurationContext() || {}
  const match: match<{ id: string }> = useRouteMatch()
  const commissions = useQuery(
    ["commissionSetup", match.params.id],
    () => platform.commission.getCommissionSetup(match.params.id),
    { onError: () => notification.error({ message: "Couldn't fetch " }) }
  )

  const setOverride = useMutation(
    (params: {
      amount: number
      schemeReferenceID: string
      companyID: string
    }) =>
      platform.commission.setCommissionOverride({
        companyID: params.companyID,
        schemeReferenceID: params.schemeReferenceID,
        amount: params.amount
      }),
    {
      onSuccess: commissions.refetch,
      onError: () =>
        notification.error({
          message: "Something went wrong, please ty again later"
        })
    }
  )

  return (
    <Spin spinning={commissions.isLoading}>
      {(commissions.data || []).map((data: ICommissionSetup) => {
        return (
          <StyledContainer>
            <Card title={data.productName}>
              {data.companyProductEnabled ? (
                <CommissionTable
                  organisation={organisation}
                  companyName={props.companyName}
                  schemeAllocations={data.schemeAllocations}
                  isLoading={commissions.isLoading || setOverride.isLoading}
                  setOverride={(amount: number, schemeReferenceID: string) =>
                    setOverride.mutateAsync({
                      amount,
                      schemeReferenceID,
                      companyID: data.companyID
                    })
                  }
                />
              ) : (
                <Typography.Text italic>{`Product not enabled for ${
                  props.companyName || "distributor"
                }. Enable the product for this distributor to modify commission values.`}</Typography.Text>
              )}
            </Card>
          </StyledContainer>
        )
      })}
    </Spin>
  )
}
