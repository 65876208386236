import React, { useContext, useEffect, useState } from "react"
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  User,
  UserCredential,
  sendPasswordResetEmail
} from "firebase/auth"
import { useConfigurationContext } from "contexts/configuration/context"
import { initializeApp, getApp } from "firebase/app"
import { useAuthenticatedUserContext } from "./authenticated-user-context"

type FirebaseAuthContextType = {
  user?: User
  logout: () => Promise<void>
  signin: (email: string, password: string) => Promise<UserCredential>
  sendForgottenPasswordEmail: (email: string) => Promise<void>
}

const stub = (): never => {
  throw new Error(
    "You forgot to wrap your component in <FirebaseAuthContext.Provider>"
  )
}

const FirebaseAuthContext = React.createContext<FirebaseAuthContextType>({
  user: undefined,
  logout: stub,
  signin: stub,
  sendForgottenPasswordEmail: stub
})

export const useFirebaseAuthContext = (): FirebaseAuthContextType => {
  const context = useContext(FirebaseAuthContext)

  if (context === undefined) {
    throw new Error(
      "You must wrap your component in `FirebaseAuthContext.Provider` before trying to access this."
    )
  }

  return context
}

export default FirebaseAuthContext

export const FirebaseAuthContextProvider: React.FC = ({ children }) => {
  const configuration = useConfigurationContext()
  const authenticatedUserContext = useAuthenticatedUserContext()

  const [user, setUser] = useState<User | undefined>(undefined)

  let app

  try {
    app = getApp()
  } catch {
    const firebaseConfig = {
      apiKey: configuration.firebase.apiKey,
      authDomain: configuration.firebase.authDomain,
      projectId: configuration.firebase.projectId,
      appId: configuration.firebase.appId
    }
    app = initializeApp(firebaseConfig)
  }

  const auth = getAuth(app)

  const signin = (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password)
  }

  const logout = () => {
    return signOut(auth)
  }

  const sendForgottenPasswordEmail = (email: string) => {
    return sendPasswordResetEmail(auth, email)
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser != null) {
        setUser(currentUser)

        authenticatedUserContext.setUser({
          email: currentUser.email || undefined,
          name: currentUser.displayName || undefined,
          id: currentUser.uid || undefined
        })
      } else {
        setUser(undefined)
        authenticatedUserContext.setUser(undefined)
      }
    })
    return () => {
      unsubscribe()
    }
    // eslint-disable-next-line
  }, [auth])

  return (
    <FirebaseAuthContext.Provider
      value={{ user, logout, signin, sendForgottenPasswordEmail }}
    >
      {children}
    </FirebaseAuthContext.Provider>
  )
}
