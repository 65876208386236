import React from "react"
import Row from "components/Console/Row"
import CellContentMoneyInput from "components/Console/CellContent/MoneyInput"
import { DeleteButton, SaveButton } from "../../../CellContent/Button"
import { TPlatformResponse } from "contexts/platform/client"
import useFee from "../useFees"
import { SyncOutlined } from "@ant-design/icons"
import CellContentText from "components/Console/CellContent/Text"
import { IFinanceFee } from "platform-client/types"

const FeeRow: React.FC<IFeeRow> = ({ readOnly = false, ...props }) => {
  const { _fee, updating, syncFees, handleUpdate, handleRemove } = useFee(
    props.fee,
    props.editSessionId,
    props.onChange
  )

  return (
    <Row
      name={_fee.name}
      key={_fee.name}
      updating={updating}
      reference={<CellContentText>{_fee.name}</CellContentText>}
      values={[
        null,
        null,
        null,
        null,
        <CellContentMoneyInput
          name={`net.${_fee.id}`}
          value={_fee.net.value}
          watchValueChange={true}
          onChange={handleUpdate}
          {...(_fee.net.currencyCode && {
            currencyCode: _fee.net.currencyCode
          })}
          readOnly={readOnly}
        />
      ]}
      control={
        !readOnly && (
          <>
            {updating && <SyncOutlined spin />}
            <SaveButton onClick={syncFees} />
            <DeleteButton onClick={() => handleRemove()} />
          </>
        )
      }
    />
  )
}

export interface IFeeRow {
  fee: IFinanceFee
  onChange: (response: TPlatformResponse) => void
  editSessionId?: string
  readOnly?: boolean
}

export default FeeRow
