import { parsePhoneNumberFromString, CountryCode } from "libphonenumber-js"

export function mustBeValidPhoneNumber(
  value = "",
  country: CountryCode = "GB"
): string | undefined {
  const phoneRegex = RegExp(/^([\\+]{0,1})([0-9\\-\\ ]+)$/)

  const defaultValue = value === null ? "" : value.toString()
  const phoneNumber = parsePhoneNumberFromString(defaultValue, country)

  if (phoneNumber && phoneRegex.test(value)) {
    if (
      phoneNumber.isValid() ||
      (phoneNumber.isPossible() && phoneNumber?.country?.trim() === country)
    ) {
      return undefined
    } else {
      return `Please enter a valid ${country} phone number`
    }
  } else {
    return "Please enter a valid phone number"
  }
}
