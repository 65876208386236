import React from "react"
import usePlatform from "contexts/platform/use-platform"
import produce from "immer"
import {
  IPolicyDetailEditSession,
  IPolicyDetailHeader
} from "models/underwriting"
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult
} from "react-query"
import { IPaymentPlan } from "platform-client/types"
import { notification } from "antd"
import { QuoteStatus } from "models/quotes/quoteStatus"
import { IQuoteBundle, QuoteState } from "platform-client/types/quote"
import { checkUserPermission } from "contexts/authorization/hooks/use-opus-permissions"
import { decodeJWT } from "utils/pocketknife/decode-jwt"
import { IQuoteBuilderResponse } from "platform-client/client/controllers"

export enum EditSessionState {
  "NOTSTARTED" = "NotStarted",
  "ACTIVE" = "Active",
  "COMPLETE" = "Complete",
  "CONFIRMED" = "Confirmed",
  "REFERRED" = "Referred",
  "PURCHASED" = "Purchased",
  "DISCARDED" = "Discarded",
  "DECLINED" = "Declined"
}

const useConsole: UseConsole = (
  quoteId,
  quoteState,
  quoteStatus,
  onQuoteChange,
  quotePaymentPlans
) => {
  const platformClient = usePlatform()
  const [editSession, setEditSession] =
    React.useState<IPolicyDetailEditSession>()

  const decodedToken = platformClient.token
    ? decodeJWT(platformClient.token)
    : undefined

  const permissions: IUnderwritingConsolePermissions = {
    endorsements: checkUserPermission("endorsement:modify", decodedToken),
    excesses: checkUserPermission("excess:modify", decodedToken)
  }

  const [sessionState, setSessionState] = React.useState<EditSessionState>(
    () => {
      if (quoteState === "Purchased") {
        return EditSessionState.PURCHASED
      } else if (quoteState === "Referred") {
        return EditSessionState.REFERRED
      } else if (quoteState === "Declined") {
        return EditSessionState.DECLINED
      } else if (quoteStatus === "Confirmed") {
        return EditSessionState.CONFIRMED
      } else if (quotePaymentPlans && quotePaymentPlans.length > 0) {
        return EditSessionState.COMPLETE
      } else {
        return EditSessionState.NOTSTARTED
      }
    }
  )

  // Checking for updated quote payment plans
  // when we receive them we know the quote "could"
  // be complete and confirmed (may still have validation errors)
  React.useEffect(() => {
    if (
      quotePaymentPlans &&
      quotePaymentPlans.length > 0 &&
      [EditSessionState.NOTSTARTED, EditSessionState.DISCARDED].includes(
        sessionState
      )
    ) {
      setSessionState(EditSessionState.COMPLETE)
    }
  }, [sessionState, quotePaymentPlans])

  const startEditSession = useQuery(
    ["startEditSession", quoteId],
    () => platformClient.startEditSession(quoteId),
    {
      enabled: false,
      onSuccess: (data) => {
        setEditSession(produce(() => data))
        setSessionState(EditSessionState.ACTIVE)
      },
      onError: (err) => {
        notification.error({
          message:
            "Unable to start a new session, please refresh and try again."
        })
      }
    }
  )

  const getActiveEditSessions = useQuery(
    ["getActiveEditSessions", quoteId],
    () => platformClient.getActiveEditSessions(quoteId),
    {
      enabled: !!quoteId,
      onSuccess: (data) => {
        if (data && data.length) {
          const latestSession = data.reduce((latest, iterator) => {
            if (latest.version <= iterator.version) {
              return iterator
            }

            return latest
          })
          setEditSession(produce(() => latestSession))
          setSessionState(EditSessionState.ACTIVE)
        }
      },
      onError: () => {
        notification.error({
          message:
            "We had trouble fetching any active sessions, please try and start a new session or refresh the page."
        })
      }
    }
  )

  const saveEditSession = useMutation(
    ({ editSessionID, note }: saveEditSessionType) =>
      platformClient.saveEditSession(editSessionID, note)
  )

  const discardEditSession = useMutation((editSessionID: string) =>
    platformClient.discardEditSession(editSessionID)
  )

  const confirmQuote = useMutation((quoteID: string) =>
    platformClient.quote.confirmQuote(quoteID)
  )

  const handleSaveEditSession = async (note?: string) => {
    if (editSession) {
      saveEditSession
        .mutateAsync({ editSessionID: editSession.id, note })
        .then(() => {
          setSessionState(EditSessionState.NOTSTARTED)
          if (typeof onQuoteChange === "function") {
            onQuoteChange()
          }
        })
        .catch(() => {
          notification.error({
            message: "Sorry we've encountered an error. Please try again."
          })
        })
    }
  }

  const handleDiscardEditSession = async () => {
    if (editSession) {
      discardEditSession
        .mutateAsync(editSession.id)
        .then((response) => {
          setEditSession(response)
          setSessionState(EditSessionState.DISCARDED)
          if (typeof onQuoteChange === "function") {
            onQuoteChange()
          }
        })
        .catch(() => {
          notification.error({
            message: "Sorry we've encountered an error. Please try again."
          })
        })
    }
  }

  const handleConfirmQuote = async () => {
    /**
     * Need to confirm the behaviour when
     * a confirm call comes back with validation errors
     * and decide how we display that.
     */
    confirmQuote
      .mutateAsync(quoteId)
      .then((response) => {
        if (response.quoteDetails.quote.quoteStatus === "Confirmed") {
          setSessionState(EditSessionState.CONFIRMED)
        } else if (response.quoteDetails.quote.quoteState === "Referred") {
          setSessionState(EditSessionState.REFERRED)
        }
        if (typeof onQuoteChange === "function") {
          onQuoteChange()
        }
      })
      .catch(() => {
        notification.error({
          message: "Sorry there has been an error confirming quote."
        })
      })
  }

  const syncEditSession = (
    response: IPolicyDetailEditSession | IQuoteBuilderResponse
  ) => {
    setEditSession(
      produce((draft) => {
        if (draft) {
          draft.policyDetail = {
            ...draft.policyDetail,
            ...response.policyDetail
          }
        }
      })
    )
  }

  return {
    editSession,
    sessionState,
    syncEditSession,
    getActiveEditSessions,
    startEditSession,
    saveEditSession,
    handleSaveEditSession,
    discardEditSession,
    handleDiscardEditSession,
    confirmQuote,
    handleConfirmQuote,
    permissions
  }
}

type saveEditSessionType = {
  editSessionID: string
  note?: string
}

type UseConsole = (
  quoteId: string,
  quoteState: QuoteState,
  quoteStatus: QuoteStatus,
  onQuoteChange?: () => void,
  quotePaymentPlans?: IPaymentPlan[]
) => {
  editSession: IPolicyDetailEditSession | undefined
  sessionState: EditSessionState
  syncEditSession: (
    response: IPolicyDetailEditSession | IQuoteBuilderResponse
  ) => void
  getActiveEditSessions: UseQueryResult<IPolicyDetailEditSession[], unknown>
  startEditSession: UseQueryResult<IPolicyDetailEditSession, unknown>
  saveEditSession: UseMutationResult<
    IPolicyDetailHeader,
    unknown,
    saveEditSessionType
  >
  handleSaveEditSession: (note?: string) => Promise<void>
  discardEditSession: UseMutationResult<
    IPolicyDetailEditSession,
    unknown,
    string
  >
  handleDiscardEditSession: () => void
  confirmQuote: UseMutationResult<IQuoteBundle, unknown, string>
  handleConfirmQuote: () => void
  permissions: IUnderwritingConsolePermissions
}

export interface IConvertQuote {
  quoteId: string
  paymentPlanRef: string
  voucherCode?: string
}

export interface IUnderwritingConsolePermissions {
  endorsements: boolean
  excesses: boolean
}

export default useConsole
