import { Card } from "antd"
import {
  IComparrison,
  IQuestionSetsDifference
} from "platform-client/types/difference"
import { DiffViewerTable } from "./diffViewerTable"

export const DiffViewer: React.FunctionComponent<IDiffViewer> = (props) => {
  return (
    <div>
      <Card title={<h3>Core Question set</h3>} bodyStyle={{ padding: "0" }}>
        {props.questionSetDifference.coreComparisons.map((cc: IComparrison) => {
          return Object.keys(cc.pageQuestionDifferences).map((key, i) => {
            return (
              <DiffViewerTable
                key={`${cc.questionsetReferenceID}_${key}_${i}`}
                questionData={cc.pageQuestionDifferences[key]}
                title={key}
              />
            )
          })
        })}
      </Card>
      <br />
      <Card title={<h3>Exposures</h3>} bodyStyle={{ padding: "0" }}>
        {props.questionSetDifference.exposuresComparisons.map((cc) => {
          return cc.comparisons.map((ccc) => {
            return Object.keys(ccc.pageQuestionDifferences).map((pqd, i) => {
              return (
                <DiffViewerTable
                  key={`${cc.exposureID}_${pqd}_${i}`}
                  questionData={ccc.pageQuestionDifferences[pqd]}
                  title={Object.keys(ccc.pageQuestionDifferences)[i] || ""}
                />
              )
            })
          })
        })}
      </Card>
    </div>
  )
}

export interface IDiffViewer {
  questionSetDifference: IQuestionSetsDifference
}
