import React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import ConfigurationContext from "contexts/configuration/context"
import { LayoutLoading } from "components"
import { useGetApplicationConfiguration } from "contexts/configuration/hooks/use-get-configuration"
import { useGetOrgSettings } from "contexts/organisation/hooks/use-get-org-settings"
import OrgSettingsContext from "contexts/organisation/context"
import { AppChrome } from "./AppChrome"
import { QueryClient, QueryClientProvider } from "react-query"
import "antd/dist/antd.variable.min.css"
import { FirebaseAuthContextProvider } from "contexts/authorization/firebase-auth-contex"
import RequireFirebaseAuthenticatedUser from "contexts/authorization/require-firebase-authenticated-user"
import { AuthenticatedUserContextProvider } from "contexts/authorization/authenticated-user-context"

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

const App = (): JSX.Element => {
  const { applicationConfigurationLoaded, applicationConfiguration } =
    useGetApplicationConfiguration()

  const { orgSettings, orgSettingsLoaded } = useGetOrgSettings()

  if (applicationConfigurationLoaded && orgSettingsLoaded) {
    return (
      <Router>
        <QueryClientProvider client={queryClient}>
          <ConfigurationContext.Provider value={applicationConfiguration}>
            <AuthenticatedUserContextProvider>
              <FirebaseAuthContextProvider>
                <OrgSettingsContext.Provider value={orgSettings}>
                  <RequireFirebaseAuthenticatedUser>
                    <AppChrome />
                  </RequireFirebaseAuthenticatedUser>
                </OrgSettingsContext.Provider>
              </FirebaseAuthContextProvider>
            </AuthenticatedUserContextProvider>
          </ConfigurationContext.Provider>
        </QueryClientProvider>
      </Router>
    )
  } else {
    return <LayoutLoading withWrapper />
  }
}

export default App
