import * as React from "react"
import useOpus from "./use-opus"
import { useAsyncError } from "utils/hooks/use-catch-async-error"

export const useAssignQuoteToUser = function (
  quoteID?: string,
  userID?: string
): IUseAssignQuoteToUser {
  const { quoteClient } = useOpus()
  const throwError = useAsyncError()

  const [isQuoteAssigned, setIsQuoteAssigned] = React.useState<
    boolean | undefined
  >(undefined)
  const [isAssigningQuote, setIsAssigningQuote] = React.useState<boolean>(false)

  React.useEffect(() => {
    ;(async function () {
      if (quoteID && userID && throwError && quoteClient) {
        setIsAssigningQuote(true)

        try {
          const _isQuoteAssigned = await quoteClient.assignQuoteToUser(
            quoteID,
            userID
          )
          setIsQuoteAssigned(_isQuoteAssigned)
        } catch (error) {
          throwError(error)
        }

        setIsAssigningQuote(false)
      }
    })()
  }, [quoteID, userID, quoteClient, throwError])

  return {
    isQuoteAssigned,
    isAssigningQuote
  }
}

export interface IUseAssignQuoteToUser {
  isQuoteAssigned?: boolean
  isAssigningQuote?: boolean
}
