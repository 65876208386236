import { RouteComponentProps, useHistory } from "react-router"
import { Breadcrumb, Button, Col, Layout, Row, Table, notification } from "antd"
import { useEffect, useState } from "react"
import { IOrgSettings } from "contexts/organisation/context"
import { useOrgSettings } from "contexts/organisation/hooks/use-org-settings"

import { LayoutPageContent, LayoutPageHeader } from "components"
import { CarOutlined, PlusOutlined } from "@ant-design/icons"
import { ERIC_POLICY_COLUMNS } from "./constants"
import { IEricListItem } from "models/eric/ericList"
import { useQuery } from "react-query"
import { AxiosError } from "axios"
import usePlatform from "contexts/platform/use-platform"
import { EricPolicyFilter, IEricFilterParams } from "./components/filter"

export type IEric = RouteComponentProps<{
  status?: string | undefined
}>

export const EricPolicies: React.FunctionComponent<IEric> = (props) => {
  const [organisationSettings, setOrganisationSettings] = useState<
    IOrgSettings | undefined
  >(undefined)

  const history = useHistory()
  const { orgSettings } = useOrgSettings()
  const searchValue = true //for now
  const [ericPolicyData, setEricPolicyData] = useState<IEricListItem[] | []>([])
  const platform = usePlatform()
  const [filterParams, setFilterParams] = useState<IEricFilterParams>({
    expiringToday: false,
    isBusness: false,
    importStatus: null,
    searchTerm: null
  })

  useEffect(() => {
    setOrganisationSettings(orgSettings)
  }, [orgSettings])

  const { isFetching, refetch } = useQuery<
    IEricListItem[] | undefined,
    AxiosError
  >(
    ["eric-policy-list", props.match.params.status],
    async () =>
      platform.eric.getPolicyList(
        filterParams?.isBusness,
        filterParams?.expiringToday,
        filterParams?.importStatus ?? null,
        filterParams?.searchTerm ?? null
      ) || [],
    {
      onSuccess: (policyList) => {
        if (policyList) {
          setEricPolicyData(policyList)
        }
      }
    }
  )

  const filterParamsOnChange = (params: IEricFilterParams) => {
    setFilterParams(params)
    refetch()
  }

  const sendEmail = async (importPolicyID: string) => {
    if (importPolicyID) {
      const sendEmailResponse = await platform.eric.sendEmail(importPolicyID)
      if (sendEmailResponse) {
        notification.success({
          message: "Success",
          description: "Email has been successfully sent",
          placement: "bottomLeft",
          duration: 3
        })
      }
    }
  }

  useEffect(() => {
    if (!searchValue) {
      refetch()
    }
  }, [searchValue, refetch])

  return (
    <Layout>
      <LayoutPageHeader
        ghost={false}
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item key="/eric">
              <CarOutlined />
              &nbsp;Eric Policies
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        title="Eric Policies"
        extra={
          <Button
            type="primary"
            onClick={() => history.replace("/eric/upload")}
            icon={<PlusOutlined />}
          >
            Upload Eric CSV
          </Button>
        }
      />
      <LayoutPageContent>
        <Row justify="end" align="middle">
          <Col style={{ padding: 16 }}>
            <EricPolicyFilter
              filterParams={filterParams}
              setFilterParams={filterParamsOnChange}
            />
          </Col>
        </Row>
        <Table
          scroll={{ x: "max-content" }}
          loading={isFetching}
          columns={ERIC_POLICY_COLUMNS(organisationSettings?.timezone, {
            sendEmail
          })}
          dataSource={ericPolicyData}
          rowKey="id"
          pagination={{
            defaultPageSize: 10,
            hideOnSinglePage: true,
            position: ["bottomCenter"],
            size: "small"
          }}
        />
      </LayoutPageContent>
    </Layout>
  )
}
