import React, { useState } from "react"
import { Card, Button, Divider, Spin, Empty, notification, Alert } from "antd"
import {
  OnReviewQuote,
  IQuoteRenewalReview,
  OnOverridePremium
} from "../../view"
import { useHistory } from "react-router-dom"
import { IQuote } from "platform-client/types/quote"
import { IMoney } from "platform-client/types"
import styled from "styled-components"
import { useMutation } from "react-query"
import usePlatform from "contexts/platform/use-platform"
import MoneyInput from "components/Console/CellContent/MoneyInput"

export const Gap = styled.div`
  margin-top: 15px;
`

export const HalfWidthFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  flex-wrap: wrap;
  width: 48%;
  div {
    display: inline-block;
    margin-right: 5px;
  }
  button {
    display: inline-block;
    position: relative;
    bottom: 4px;
    margin-left: 5px;
  }
`

export const StyledMoneyInput = styled(MoneyInput)`
  width: 150px;
  position: relative;
  bottom: 4px;
`

const ReviewV2: React.FC<IReview> = (props) => {
  const history = useHistory()
  const platform = usePlatform()

  const [isReviewingQuote, setIsReviewingQuote] = React.useState<boolean>(false)
  const [isOverridingPremium, setIsOverridingPremium] =
    React.useState<boolean>(false)

  const isInReferredState = props.quote.quoteState === "Referred"

  const [overrideAmount, setOverrideAmount] = useState<IMoney>({
    value: props.defaultAmount.value,
    currencyCode: props.defaultAmount.currencyCode
  })

  const overridingPremium = useMutation(
    (body: IOverridePremium) =>
      platform.quote.overridePremium(body.quoteId, body.premium),
    {
      onSuccess: () => {
        if (typeof props.onQuoteChange === "function") {
          props.onQuoteChange()
        }
      },
      onError: () => {
        notification.error({
          message: "Unable to override premium please try again"
        })
      }
    }
  )

  if (!isInReferredState)
    return (
      <Card>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="This quote is not currently referred."
        />
      </Card>
    )

  return (
    <>
      {props.quote.quoteType === "Renewal" && (
        <>
          <Card title="Review this quote">
            {!isReviewingQuote && (
              <>
                <Button
                  type="primary"
                  {...(props.quoteRenewalReview?.review?.reviewActionTimestamp
                    ? {
                        disabled: Boolean(
                          props.quoteRenewalReview?.review
                            ?.reviewActionTimestamp
                        )
                      }
                    : {})}
                  onClick={async () => {
                    setIsReviewingQuote(true)
                    await props.onReviewQuote?.(
                      {
                        quoteId: props.quoteId,
                        review: false
                      },
                      () => {
                        history.push(`/quote/${props.quoteId}/summary`)
                      }
                    )
                    setIsReviewingQuote(false)
                  }}
                  danger
                >
                  Decline
                </Button>
                <Divider type="vertical" />
                <Button
                  type="primary"
                  {...(props.quoteRenewalReview?.review?.reviewActionTimestamp
                    ? {
                        disabled: Boolean(
                          props.quoteRenewalReview?.review
                            ?.reviewActionTimestamp
                        )
                      }
                    : {})}
                  onClick={async () => {
                    setIsReviewingQuote(true)
                    await props.onReviewQuote?.(
                      {
                        quoteId: props.quoteId,
                        review: true
                      },
                      () => {
                        history.push(`/quote/${props.quoteId}/summary`)
                      }
                    )
                    setIsReviewingQuote(false)
                  }}
                >
                  Approve
                </Button>
              </>
            )}

            {isReviewingQuote && (
              <>
                <Divider type="vertical" />
                <Spin />
              </>
            )}
          </Card>
          <Gap />
        </>
      )}
      {props.isHybrid && (
        <Card title="Override base premium">
          <Alert
            message="Please note that this adjusts the base premium excluding IPT, this does not effect add ons"
            type="info"
            showIcon
          />
          <HalfWidthFieldWrapper>
            <div>Amount:</div>
            <StyledMoneyInput
              value={overrideAmount.value}
              currencyCode={overrideAmount.currencyCode}
              watchValueChange={false}
              onChange={(e) =>
                setOverrideAmount({
                  currencyCode: overrideAmount.currencyCode,
                  value: Number(e.target.value)
                })
              }
            />

            <Button
              type="primary"
              disabled={isOverridingPremium}
              loading={isOverridingPremium}
              onClick={async () => {
                setIsOverridingPremium(true)
                await overridingPremium.mutateAsync({
                  quoteId: props.quoteId,
                  premium: {
                    currencyCode: overrideAmount.currencyCode,
                    value: overrideAmount.value * 100,
                    unit: "Minor"
                  }
                })
                setIsOverridingPremium(false)
              }}
            >
              Override
            </Button>
          </HalfWidthFieldWrapper>
        </Card>
      )}
    </>
  )
}

export default ReviewV2

export interface IReview {
  quoteId: string
  onReviewQuote?: OnReviewQuote
  onOverridePremium?: OnOverridePremium
  quoteRenewalReview?: IQuoteRenewalReview
  quote: IQuote
  defaultAmount: IMoney
  isHybrid: boolean
  onQuoteChange?: () => void
}

export interface IOverridePremium {
  quoteId: string
  premium: IMoney
}
