import React from "react"
import { IEvent } from "platform-client/types"
import { IConfigurationContext } from "contexts/configuration/context"
import { LightText } from "components/LayoutStyledComponents"
import { Tabs, Timeline, Skeleton } from "antd"
import { LayoutCardEmpty } from "components"
import { dateToShorthand } from "utils/pocketknife/date-to-shorthand"
import { capitalizeFirstLetter } from "utils/pocketknife/capitalize-first-letter"
import styled from "styled-components"

const TabsContainer = styled.div`
  background-color: #fff;
  padding: 10px 24px 0 24px;
  height: 668px;
`

const StyledTabPanel = styled(Tabs.TabPane)`
  height: 565px;
  overflow-y: auto;
`

export const QuoteAside: React.FC<IQuoteAside> = (props) => {
  return (
    <TabsContainer>
      <Skeleton loading={props.isGettingEvents || props.isGettingNotes}>
        <Tabs defaultActiveKey="1" animated={false}>
          <StyledTabPanel tab="Timeline" key="1">
            {props.events && (
              <Timeline mode="left" style={{ paddingBottom: 16 }}>
                {props.events.map((event: IEvent) => {
                  return (
                    <Timeline.Item
                      style={{ paddingTop: 5 }}
                      color={
                        //props.configuration.quote.action[event?.action] This doesnt make sense
                        undefined
                      }
                    >
                      <LightText>
                        {dateToShorthand(event.dateCreated)}
                      </LightText>
                      {`${capitalizeFirstLetter(event.displayText)}`}
                    </Timeline.Item>
                  )
                })}

                {props.events.length === 0 && (
                  <LayoutCardEmpty bordered={false} />
                )}
              </Timeline>
            )}

            {(!props.events || props.events?.length === 0) && (
              <LayoutCardEmpty bordered={false} />
            )}
          </StyledTabPanel>
        </Tabs>
      </Skeleton>
    </TabsContainer>
  )
}

export interface IQuoteAside {
  configuration: IConfigurationContext
  isGettingNotes: boolean
  events?: IEvent[]
  isGettingEvents: boolean
}
