import { useCallback, useState } from "react"
import { TErrorState } from "models/errors"
import {
  errorValidationMessage,
  IErrorState
} from "utils/pocketknife/error-validation-message"
import useOpus from "contexts/opus/hooks/use-opus"
import useAsyncEffect from "use-async-effect"
import { TPolicyPayment } from "models/policies/policyPayment"

export const useGetPolicyPayments = (
  policyID: string | undefined,
  shouldRefetch: boolean,
  setShouldRefetch: React.Dispatch<React.SetStateAction<boolean>>
): IPolicyPayments => {
  const [policyPayments, setPolicyPayments] = useState<
    TPolicyPayment[] | undefined
  >(undefined)
  const [isError, setIsError] = useState<TErrorState>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { policyClient } = useOpus()

  const getPayments = useCallback(async () => {
    try {
      if (policyID) {
        const _payments = await policyClient.getPolicyPayments(policyID)

        if (_payments) {
          setPolicyPayments(_payments)
          setIsError(undefined)
        }
      }
      setIsLoading(false)
    } catch (error) {
      setPolicyPayments(undefined)
      setIsError({
        errorNumber: error?.response?.status,
        validationMessage: errorValidationMessage(error)
      })
      setIsLoading(false)
    }
  }, [policyID, policyClient])

  useAsyncEffect(() => {
    if ((policyID && policyPayments === undefined) || shouldRefetch) {
      setIsLoading(true)
      getPayments()
      shouldRefetch && setShouldRefetch(false)
    }
  }, [getPayments, policyID, policyPayments, shouldRefetch])

  return {
    data: policyPayments,
    isError,
    isLoading
  }
}

interface IPolicyPayments extends IErrorState {
  data: TPolicyPayment[] | undefined
  isLoading: boolean
}

export default useGetPolicyPayments
