import moment from "moment"

export const dateToShorthand = (date: string | number): string | undefined => {
  const daysCount = Math.abs(moment().diff(moment(date), "days"))
  const hoursCount = Math.abs(moment().diff(moment(date), "hours"))
  const weeksCount = Math.abs(moment().diff(moment(date), "weeks"))

  if (hoursCount === 0) {
    return `Just now`
  } else if (hoursCount !== 0 && daysCount === 0) {
    return `${hoursCount} ${hoursCount === 1 ? "hour" : "hours"} ago`
  } else if (hoursCount !== 0 && daysCount !== 0 && weeksCount === 0) {
    return `${daysCount} ${daysCount === 1 ? "day" : "days"} ago`
  } else if (hoursCount !== 0 && daysCount !== 0 && weeksCount <= 2) {
    return `${weeksCount} ${weeksCount === 1 ? "week" : "weeks"} ago`
  } else if (hoursCount !== 0 && daysCount !== 0 && weeksCount > 2) {
    return moment(date).format("DD/MM/YY")
  }
}
