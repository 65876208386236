import React from "react"
import Row from "components/Console/Row"
import CellContentText from "components/Console/CellContent/Text"
import CellContentMoney from "components/Console/CellContent/Money"
import { Table } from "components/Console/Table"
import { isEmpty } from "ramda"
import {
  IFinanceNetLoadingDiscount,
  IMoney,
  Nullable,
  SectionTypes
} from "platform-client/types"
import { TPlatformResponse } from "contexts/platform/client"
import CellContentRateInput from "components/Console/CellContent/RateInput"
import { IOrgSettings } from "contexts/organisation/context"
import { decimalToPercentage } from "utils/pocketknife/converters"
import { useSection } from "components/Console/Section/use-section"

const PremiumAdjustments: React.FC<IPremiumAdjustments> = ({
  reference = "Premium Adjustment",
  summaryClosedText,
  summaryOpenText,
  totalSubsectionPremium,
  minimumPremium,
  totalNetDiscounts,
  discounts = [],
  editSessionId,
  section,
  onChange,
  readOnly = false,
  startExpanded
}): JSX.Element => {
  const [firstValue, ...restValues] = discounts || []
  const { updateSection, handlePremiumAdjustmentUpdate: handleUpdate } =
    useSection(editSessionId, onChange)

  const totalIncludingDiscounts =
    (totalSubsectionPremium ? totalSubsectionPremium.value : 0) -
    (totalNetDiscounts ? totalNetDiscounts.value : 0)

  const sectionTotalWithLoadings =
    totalIncludingDiscounts > (minimumPremium ? minimumPremium?.value : 0)
      ? totalIncludingDiscounts
      : minimumPremium?.value

  const sectionTotalWithoutLoadings =
    (totalSubsectionPremium ? totalSubsectionPremium.value : 0) >
    (minimumPremium ? minimumPremium?.value : 0)
      ? totalSubsectionPremium?.value
      : minimumPremium?.value

  const sectionTotal =
    firstValue || !isEmpty(restValues)
      ? sectionTotalWithLoadings
      : sectionTotalWithoutLoadings

  return (
    <Table.HalfTable
      updating={updateSection.isLoading}
      reference={reference}
      startExpanded={startExpanded}
      {...(firstValue
        ? {
            firstRowValues: [
              <CellContentText align="right">
                {firstValue?.name}
              </CellContentText>,
              <CellContentRateInput
                name={firstValue.referenceID}
                value={
                  firstValue.type === "Percentage"
                    ? decimalToPercentage(firstValue?.ruleAmount)
                    : firstValue?.ruleAmount
                }
                {...(firstValue.type && { type: firstValue.type })}
                currencyCode={firstValue.amount?.currencyCode}
                onBlur={(event) =>
                  handleUpdate(event, section, discounts || [])
                }
                readOnly={readOnly}
              />,
              <CellContentMoney
                value={firstValue?.amount?.value || 0}
                addonProps={{
                  content: "+",
                  exclude:
                    firstValue?.amount?.value && firstValue?.amount?.value > 0
                      ? [""]
                      : ["+"]
                }}
              />
            ]
          }
        : {
            firstRowValues: [
              <CellContentText align="right">Minimum premium</CellContentText>,
              null,
              minimumPremium ? (
                <CellContentMoney {...minimumPremium} />
              ) : (
                <CellContentText align="right">-</CellContentText>
              )
            ]
          })}
      summaryValues={[
        <CellContentText align="right" strong>
          {summaryClosedText}
        </CellContentText>,
        null,
        totalSubsectionPremium ? (
          <CellContentMoney
            value={sectionTotal ? sectionTotal : 0}
            currencyCode={totalSubsectionPremium.currencyCode}
          />
        ) : (
          <CellContentText align="right">-</CellContentText>
        )
      ]}
    >
      {!isEmpty(restValues) &&
        restValues.map((discount, index) => (
          <PremiumAdjustment
            key={index}
            adjustment={discount}
            handleUpdate={(event) =>
              handleUpdate(event, section, discounts || [])
            }
            readOnly={readOnly}
          />
        ))}

      {(firstValue || !isEmpty(restValues)) && (
        <>
          <Row
            type="halftable"
            values={[
              <CellContentText align="right" strong={true}>
                Premium after loading/discount
              </CellContentText>,
              null,
              totalNetDiscounts ? (
                <CellContentMoney
                  currencyCode={totalNetDiscounts.currencyCode}
                  value={
                    (totalSubsectionPremium
                      ? totalSubsectionPremium.value
                      : 0) - totalNetDiscounts.value
                  }
                />
              ) : (
                <CellContentText align="right">-</CellContentText>
              )
            ]}
          />

          <Row
            type="halftable"
            values={[
              <CellContentText align="right">Minimum premium</CellContentText>,
              null,
              minimumPremium ? (
                <CellContentMoney {...minimumPremium} />
              ) : (
                <CellContentText align="right">-</CellContentText>
              )
            ]}
          />
        </>
      )}

      <Row
        type="halftable"
        values={[
          <CellContentText align="right" strong>
            {summaryOpenText}
          </CellContentText>,
          null,
          sectionTotal ? (
            <CellContentMoney
              value={sectionTotal}
              currencyCode={totalSubsectionPremium?.currencyCode}
            />
          ) : (
            <CellContentText align="right">-</CellContentText>
          )
        ]}
      />
    </Table.HalfTable>
  )
}

export const PremiumAdjustment: React.FC<IAdjustmentProps> = ({
  adjustment,
  handleUpdate,
  readOnly = false
}) => {
  return (
    <Row
      type="default"
      values={[
        <CellContentText align="right">{adjustment?.name}</CellContentText>,
        <CellContentRateInput
          name={adjustment.referenceID}
          value={
            adjustment.type === "Percentage"
              ? decimalToPercentage(adjustment?.ruleAmount)
              : adjustment?.ruleAmount
          }
          {...(adjustment.type && { type: adjustment.type })}
          currencyCode={adjustment.amount?.currencyCode}
          onBlur={handleUpdate}
          readOnly={readOnly}
        />,
        <CellContentMoney
          addonProps={{
            content: "+",
            exclude:
              adjustment?.amount?.value && adjustment?.amount?.value > 0
                ? [""]
                : ["+"]
          }}
          value={adjustment?.amount?.value || 0}
        />
      ]}
    />
  )
}

export interface IPremiumAdjustments {
  reference: string
  summaryClosedText: string
  summaryOpenText: string
  totalSubsectionPremium: IMoney | null
  minimumPremium: IMoney | null
  discounts?: Nullable<IFinanceNetLoadingDiscount[]>
  totalNetDiscounts: IMoney | null
  section: SectionTypes
  editSessionId?: string
  onChange: (response: TPlatformResponse) => void
  readOnly?: boolean
  startExpanded?: boolean
  organisationSettings?: IOrgSettings
}

interface IAdjustmentProps {
  adjustment: IFinanceNetLoadingDiscount
  handleUpdate: (event: React.ChangeEvent<HTMLInputElement>) => void
  readOnly?: boolean
}

export default PremiumAdjustments
