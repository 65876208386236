import { Alert, Card, Col, Row } from "antd"

const ChooseProduct: React.FC<IChooseProductProps> = ({
  onChooseProduct,
  activeProducts = [],
  ...props
}) => {
  return (
    <>
      <Row gutter={16}>
        {activeProducts && activeProducts.length === 0 && (
          <Col span={24} flex="stretch" style={{ marginBottom: "14px" }}>
            <Alert
              type="info"
              showIcon
              banner
              message="Sorry, there are no products available at the moment."
            />
          </Col>
        )}
        {activeProducts &&
          activeProducts.length > 0 &&
          activeProducts.map((item) => (
            <Col
              key={item.productReferenceId}
              span={6}
              flex="stretch"
              style={{ marginBottom: "14px", padding: "50px 15px" }}
            >
              <Card
                style={{ height: "100%" }}
                hoverable
                onClick={() => onChooseProduct(item)}
                {...(item.bannerImage
                  ? {
                      cover: (
                        <img
                          src={item.bannerImage}
                          alt={`${item.label} cover`}
                        />
                      )
                    }
                  : {})}
              >
                <Card.Meta title={item.label} description={item.desc} />
              </Card>
            </Col>
          ))}
      </Row>
    </>
  )
}

interface IChooseProductProps {
  onChooseProduct: (product: IActiveProduct) => void
  activeProducts: IActiveProduct[]
}

export interface IActiveProduct {
  id: string | number
  callToActionLabel?: string
  desc?: string
  label?: string
  labelIcon?: string
  productReferenceId: string
  bannerImage?: string
  productType?: string
  dashboardOrder: number
  isActive: boolean
  name: string
  hasQuickQuote?: boolean
}

export type QuoteTypeCreation = "quickquote" | "quote"

export default ChooseProduct
