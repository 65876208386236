import { useState, useRef, useEffect, useCallback } from "react"
import { AxiosError } from "axios"
import { IPolicyCancelRequestBody } from "contexts/opus/context"
import useOpus from "contexts/opus/hooks/use-opus"
import { notification } from "antd"

const handleError = (err: AxiosError) => {
  const { response: { data: { error = undefined } = {} } = {} } = err || {}
  const message = ((errorCode) => {
    switch (errorCode) {
      case 1000:
        return "You can only cancel policy within cover period"
      default:
        return "Oops! Something went wrong"
    }
  })(error)

  notification.error({ message })
}

export const useSetPolicyCancellation = (): IUseSetPolicyCancel => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const isMounted = useRef(true)
  const { policyClient } = useOpus()

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const sendRequest = useCallback(
    async (
      subjectUserID: string,
      requestBody: IPolicyCancelRequestBody
    ): Promise<void> => {
      if (isLoading) return
      setLoading(true)

      try {
        return await policyClient.setPolicyCancellation(
          subjectUserID,
          requestBody
        )
      } catch (error) {
        handleError(error as AxiosError)
        setIsError(true)
      } finally {
        setLoading(false)
      }

      if (isMounted.current) setLoading(false)
    },
    [isLoading, policyClient]
  )

  return { sendRequest, isLoading, isError }
}

interface IUseSetPolicyCancel {
  isLoading: boolean
  isError: boolean
  sendRequest: (
    subjectUserID: string,
    requestBody: IPolicyCancelRequestBody
  ) => Promise<void>
}
