import { RouteComponentProps } from "react-router"
import { Breadcrumb, Card, Layout, notification } from "antd"

import { LayoutPageContent, LayoutPageHeader } from "components"
import { CarOutlined } from "@ant-design/icons"
import FileUpload from "components/FileUpload"
import { useMutation } from "react-query"
import usePlatform from "contexts/platform/use-platform"
import { Link } from "react-router-dom"

export type IEric = RouteComponentProps<{
  status?: string | undefined
}>

export const EricUpload: React.FunctionComponent<IEricUpload> = (props) => {
  const platform = usePlatform()

  const uploadDocument = useMutation((formData: FormData) =>
    platform.eric.uploadCsv(formData)
  )
  const handleDocumentUpload = async (
    document: FormData,
    callback?: (result: boolean) => void
  ) => {
    uploadDocument
      .mutateAsync(document)
      .then((result) => {
        if (typeof callback === "function") {
          callback(result)
        }
        // refetchUpload() maybe to show on a list?
      })
      .catch(() => {
        notification.error({
          message: "Unable to upload document, please try again."
        })
      })
  }

  return (
    <Layout>
      <LayoutPageHeader
        ghost={false}
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item key="/eric">
              <Link to="/eric">
                <CarOutlined />
                &nbsp;Eric Policies
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item key="/eric/upload">Upload</Breadcrumb.Item>
          </Breadcrumb>
        }
        title="Eric Upload CSV"
      />
      <LayoutPageContent>
        <Card title="Upload CSV">
          <FileUpload
            askForLabel={false}
            multiple={false}
            handleUpload={handleDocumentUpload}
          />
        </Card>
      </LayoutPageContent>
    </Layout>
  )
}

export interface IEricUpload {
  status?: string
}
