import { QuoteState } from "platform-client/types/quote"

export const QuoteStateLabels: IQuoteStateLabels = {
  NotSet: { label: "not set", color: "default" },
  Open: { label: "open", color: "blue" },
  Quoted: { label: "quoted", color: "green" },
  Referred: { label: "referred", color: "orange" },
  PendingPayment: { label: "pending payment", color: "green" },
  Purchased: { label: "purchased", color: "default" },
  Declined: { label: "declined", color: "red" },
  Withdrawn: { label: "Withdrawn", color: "default" },
  Expired: { label: "expired", color: "default" },
  Invalidated: { label: "invalidated", color: "red" },
  Failed: { label: "failed", color: "red" }
}

export interface IQuoteStateLabelConfig {
  label: string
  color: string
}

export type IQuoteStateLabels = Record<QuoteState, IQuoteStateLabelConfig>
