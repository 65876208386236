import { useEffect } from "react"
import { useNotifications } from "contexts/notification/hooks/use-notification"
import { notification } from "antd"
import {
  ENotificationContextNotificationsType,
  ENotificationContextNotificationsPlacement
} from "contexts/notification/context"
import { History } from "history"

export const useNotification = (
  action: string | undefined,
  history: History<{
    action?: string | undefined
    quoteState?: string | undefined
  }>,
  type: TNotificationType
): void => {
  const { notifications } = useNotifications()

  useEffect(() => {
    if (notifications) {
      if (action) {
        const actionTypeArray = action.split("-")
        const actionType = actionTypeArray[actionTypeArray.length - 1]

        const notificationData = {
          message: notifications?.notifications[type][actionType]?.title,
          description: notifications?.notifications[type][actionType]?.content,
          placement: ENotificationContextNotificationsPlacement.topRight
        }

        switch (notifications?.notifications[type][actionType]?.type) {
          case ENotificationContextNotificationsType.Success:
            notification.success(notificationData)
            break
          case ENotificationContextNotificationsType.Error:
            notification.error(notificationData)
            break

          case ENotificationContextNotificationsType.Info:
            notification.info(notificationData)
            break
          case ENotificationContextNotificationsType.Warning:
            notification.warning(notificationData)
            break

          case ENotificationContextNotificationsType.Open:
            notification.open(notificationData)
            break

          default:
            notification.success(notificationData)
        }
      }

      if (history.location.state?.action) {
        history.replace({
          state: {
            ...history.location.state,
            action: undefined
          }
        })
      }
    }
  }, [action, history, notifications, type])
}

// Here should go other types but first they need to be added in context
type TNotificationType = "quote"
