import React, { useState, useEffect } from "react"
import { AllPolicies } from "./view"
import { AxiosError } from "axios"
import { useQuery } from "react-query"
import { IPolicyTable } from "models/policies/policyTable"
import { IConfigurationContext } from "contexts/configuration/context"
import usePlatform from "contexts/platform/use-platform"

export const AllPoliciesV3: React.FC<IAllPoliciesV3> = (props) => {
  const { policy } = usePlatform()

  const [searchValue, setSearchValue] = useState<string | undefined | null>(
    undefined
  )

  const [isSearchLoading, setSearchLoading] = useState<boolean>(false)

  const [policiesData, setPoliciesData] = useState<IPolicyTable[] | undefined>()

  const { isFetching, refetch } = useQuery<
    IPolicyTable[] | undefined,
    AxiosError
  >("policies", async () => policy.getPolicies(), {
    onSuccess: (data) => setPoliciesData(data)
  })

  useEffect(() => {
    if (!searchValue) {
      refetch()
    }
  }, [searchValue, refetch])

  return (
    <AllPolicies
      setSearchLoading={setSearchLoading}
      setSearchValue={setSearchValue}
      searchValue={searchValue}
      isSearchLoading={isSearchLoading}
      policies={policiesData}
      arePoliciesLoading={isFetching}
      configuration={props.configuration}
      setPoliciesData={setPoliciesData}
      isPoliciesError={undefined}
    />
  )
}

interface IAllPoliciesV3 {
  configuration: IConfigurationContext
}
