import { Checkbox, Input, Select, Space } from "antd"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import debounce from "debounce"

const { Option } = Select
const { Search } = Input
export const EricPolicyFilter: React.FunctionComponent<IEricPolicyFilter> = (
  props
) => {
  const filterParams = props.filterParams

  const businessOnChange = (e: CheckboxChangeEvent) => {
    filterParams.isBusness = e.target.checked
    props.setFilterParams(filterParams)
  }

  const expiringOnChange = (e: CheckboxChangeEvent) => {
    filterParams.expiringToday = e.target.checked
    props.setFilterParams(filterParams)
  }

  const statusOnChange = (value: string) => {
    filterParams.importStatus = value === "NotSet" ? null : value
    props.setFilterParams(filterParams)
  }

  const searchTermOnChange = (value: string) => {
    filterParams.searchTerm = value
    props.setFilterParams(filterParams)
  }

  return (
    <>
      <Search
        style={{ width: 425 }}
        onSearch={debounce((value: string) => {
          searchTermOnChange(value)
        }, 200)}
        placeholder="search by eric policy number or stella quote numnber"
        enterButton="Search"
        defaultValue={props.filterParams.searchTerm || ""}
        allowClear={true}
      />

      <Space style={{ marginLeft: 16 }}>
        Filter by Status
        <Select
          placeholder="Pending, Renewal Declined, Cancelled..."
          defaultValue={props.filterParams.importStatus ?? undefined}
          allowClear
          style={{ width: 160 }}
          onChange={statusOnChange}
        >
          {(Object.keys(PolicyStateLabels) as ImportPolicyStatus[]).map(
            (key) => {
              const stateTitle =
                PolicyStateLabels[key].label.charAt(0).toUpperCase() +
                PolicyStateLabels[key].label.slice(1)

              if (
                key.toLocaleLowerCase() === props.filterParams.importStatus &&
                !props.filterParams.importStatus
              ) {
                filterParams.importStatus = key
                props.setFilterParams(filterParams)
              }
              return (
                <Option key={key} value={key}>
                  {stateTitle}
                </Option>
              )
            }
          )}
        </Select>
      </Space>
      <Space style={{ marginLeft: 16 }}>
        <Checkbox
          value={props.filterParams.expiringToday}
          onChange={expiringOnChange}
        >
          Expiring Today
        </Checkbox>
        <Checkbox
          value={props.filterParams.isBusness}
          onChange={businessOnChange}
        >
          Is a Business
        </Checkbox>
      </Space>
    </>
  )
}

export interface IEricPolicyFilter {
  filterParams: IEricFilterParams
  setFilterParams: (value: IEricFilterParams) => void
}

export interface IEricFilterParams {
  take?: number
  skip?: number
  isBusness: boolean
  expiringToday: boolean
  importStatus: string | null
  searchTerm: string | null
}

export const PolicyStateLabels: IPolicyStateLabelConfig = {
  Pending: { label: "Pending", color: "blue" },
  ProductDeclined: { label: "Product Declined", color: "red" },
  RenewalGenerated: { label: "Renewal Generated", color: "green" },
  RenewalDeclined: { label: "Renewal Declined", color: "red" },
  Error: { label: "Error", color: "red" },
  Cancelled: { label: "Cancelled", color: "orange" },
  Manual: { label: "Manual", color: "default" },
  Purchased: { label: "Purchased", color: "default" },
  Deleted: { label: "Deleted", color: "red" }
}

export type IPolicyStateLabelConfig = Record<
  ImportPolicyStatus,
  IImportPolicyStatusLabelConfig
>

export interface IImportPolicyStatusLabelConfig {
  label: string
  color: string
}

export enum ImportPolicyStatus {
  Pending = "Pending",
  ProductDeclined = "ProductDeclined",
  RenewalGenerated = "RenewalGenerated",
  RenewalDeclined = "RenewalDeclined",
  Error = "Error",
  Cancelled = "Cancelled",
  Manual = "Manual",
  Purchased = "Purchased",
  Deleted = "Deleted"
}
