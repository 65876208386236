/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import formatDate from "dateformat"
import { TQuestionType } from "models/quotes/quoteReview"
import { toString, is, isEmpty, isNil } from "ramda"
import { MoneyDisplay } from "components/display/MoneyDisplay"
import { IMoney } from "platform-client/types"

export const formatQuestionAnswer = (
  type: TQuestionType,
  answer: any
): React.ReactNode | string | null => {
  switch (type) {
    case "String":
      return answer

    case "Email":
      return answer

    case "Integer":
      return null

    case "MonthYear":
      return null

    case "Date":
      return formatDate(new Date(answer), "dd/mm/yyyy")

    case "Radio":
      return null

    case "Picklist":
      return is(Object, answer) ? answer?.text : null

    case "Imagelist":
      return null

    case "YesNo":
      return answer ? "Yes" : "No"

    case "Money":
      return is(Object, answer) ? (
        <MoneyDisplay value={answer as IMoney} />
      ) : (
        answer
      )

    case "Name":
      return `${answer?.firstName} ${answer?.lastName}`

    case "Address":
      return null

    case "PhoneNumber":
      return null

    case "ItemArray":
      return null

    case "MoneyStepper":
      return null

    case "BoxBoolean":
      return is(Boolean, answer) ? toString(answer) : null

    case "BoxModal": {
      if (!isEmpty(answer) && !isNil(answer)) {
        return (
          <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
            {answer.map(
              (item: {
                questions: {
                  questionType: number
                  answer: any
                  order: number
                }[]
                order: number
                title: string | null
              }) =>
                !isEmpty(item.questions) && (
                  <li>
                    {item.questions.map((el) => {
                      if (el.questionType === 10) {
                        return `${el.answer?.firstName} ${el.answer?.lastName}, `
                      } else if (el.questionType === 28) {
                        return formatDate(new Date(el.answer), "dd/mm/yyyy")
                      } else {
                        return null
                      }
                    })}
                  </li>
                )
            )}
          </ul>
        )
      } else {
        return "N/A"
      }
    }
    case "ItemArrayForm":
      return answer.map((item: any) => {
        return (
          <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
            {item.questions.map((question: any) => {
              switch (question.questionType) {
                case 10:
                  return (
                    <li>
                      {question.question}: {question.answer.firstName}{" "}
                      {question.answer.lastName}
                    </li>
                  )
                case 6:
                  return (
                    <li>
                      {question.question}: {question.answer.text}
                    </li>
                  )
                case 28:
                  return (
                    <li>
                      {question.question}:{" "}
                      {formatDate(new Date(question.answer), "dd/mm/yyyy")}
                    </li>
                  )
                default:
                  return null
              }
            })}
          </ul>
        )
      })
    case "BoxMoneyStepper":
      return null

    case "SingleEntityModal":
      return null

    case "SingleEntityBoxModal":
      return null

    case "PagedBoolean":
      return null

    case "BoxConfirmationBoolean":
      return null

    case "DynamicMoneyBoundedSlider":
      return null

    case "MotorWeb":
      return null

    case "Grouped":
      return `${answer?.line1} ${answer?.locality} ${answer?.postalCode}`

    case "CustomObjectList":
      return null

    case "Segmented":
      return null

    case "DatePicker":
      return null

    case "InputDate":
      return formatDate(new Date(answer), "dd/mm/yyyy")

    case "MoneyBoundedSlider":
      return null

    case "Excesses":
      return null

    case "CustomObject":
      return null
  }
}
