export type IQuoteState =
  | "NotSet"
  | "Open"
  | "Quoted"
  | "Referred"
  | "PendingPayment"
  | "Purchased"
  | "Declined"
  | "Withdrawn"
  | "Expired"
  | "Invalidated"
  | "Failed"

export enum EQuoteState {
  NotSet = "NotSet",
  Open = "Open",
  Quoted = "Quoted",
  Referred = "Referred",
  PendingPayment = "PendingPayment",
  Purchased = "Purchased",
  Declined = "Declined",
  Withdrawn = "Withdrawn",
  Expired = "Expired",
  Invalidated = "Invalidated",
  Confirmed = "Confirmed",
  Failed = "Failed"
}
