import { LayoutLoading } from "components"
import usePlatform from "contexts/platform/use-platform"
import { useQuery } from "react-query"
import UserContext from "./context"

export const UserProvider: React.FC = (props) => {
  const { user } = usePlatform()

  const { data: userData, isLoading } = useQuery(["user", "me"], () =>
    user.getCurrentUser()
  )

  if (isLoading || userData === undefined) {
    return <LayoutLoading withWrapper />
  }

  return (
    <UserContext.Provider
      value={{
        user: userData,
        canBindBusiness: userData?.company?.brokerCanBindBusiness,
        professionalIndemnityInsuranceExpired:
          userData?.company?.professionalIndemnityInsuranceExpired
      }}
      {...props}
    />
  )
}
