import React from "react"
import {
  OnReviewQuote,
  IQuoteRenewalReview,
  OnUpdateReferral,
  OnOverridePremium
} from "../../view"
import { IConfigurationContext } from "contexts/configuration/context"
import {
  useOpusPermissions,
  EOpusPermission
} from "contexts/authorization/hooks/use-opus-permissions"
import ReviewV2 from "./index.v2"
import ReviewV3 from "./index.v3"
import { IDeclineReason, IMoney, IReferral } from "platform-client/types"
import { IQuote, IQuoteBundle } from "platform-client/types/quote"
import { UseMutationResult } from "react-query"
import {
  IConfirmReferralQuoteProps,
  IDeclineReferralQuoteProps
} from "platform-client/client/controllers/quote"

const Review: React.FunctionComponent<IReviewProps> = (props) => {
  const { havePermission: canProcessReferral } = useOpusPermissions(
    EOpusPermission.ProcessRefferal,
    "maintain"
  )
  switch (props.configuration.flags.platformVersion || "v2") {
    case "v3":
      return (
        <ReviewV3
          quote={props.quote}
          referrals={props.referrals}
          isQuoteLoading={props.isQuoteLoading}
          canProcessReferral={canProcessReferral}
          onUpdateReferral={props.onUpdateReferral}
          confirmReferralQuote={props.confirmReferralQuote}
          declineReferralQuote={props.declineReferralQuote}
          declines={props.declines}
        />
      )
    default:
      return (
        <ReviewV2
          quoteId={props.quote.id}
          onReviewQuote={props.onReviewQuote}
          onOverridePremium={props.onOverridingPremium}
          quoteRenewalReview={props.quoteRenewalReview}
          quote={props.quote}
          defaultAmount={props.currentPrice}
          isHybrid={props.isHybrid}
          onQuoteChange={props.onQuoteChange}
        />
      )
  }
}

export default Review

export interface IReviewProps {
  configuration: IConfigurationContext
  quote: IQuote
  isQuoteLoading?: boolean
  referrals?: IReferral[]
  declines: IDeclineReason[]
  onUpdateReferral?: OnUpdateReferral
  confirmReferralQuote?: UseMutationResult<
    IQuoteBundle,
    unknown,
    IConfirmReferralQuoteProps
  >
  declineReferralQuote?: UseMutationResult<
    IQuoteBundle,
    unknown,
    IDeclineReferralQuoteProps
  >
  onReviewQuote?: OnReviewQuote
  onOverridingPremium?: OnOverridePremium
  quoteRenewalReview?: IQuoteRenewalReview
  currentPrice: IMoney
  isHybrid: boolean
  onQuoteChange?: () => void
}
