import styled from "styled-components"
import { useMemo } from "react"
import { formatAddress, TAddress } from "utils/addressService"
import { IUniversalAddress } from "platform-client/types"

const StyledAddressItem = styled.span`
  display: block;
  padding: 0 5px 0 0;
`

interface IAddressProps {
  component?: React.ComponentType
  className?: string
  address: TAddress | IUniversalAddress | null
  separator?: string
}

export const Address: React.FC<IAddressProps> = ({
  className,
  component,
  address,
  separator
}) => {
  const addressArray = useMemo(() => formatAddress(address || null), [address])
  const Container = component || StyledAddressItem

  return (
    <>
      {addressArray.map(({ value, key }, i) => (
        <Container
          key={key}
          className={[...(className ? [className] : []), key].join(" ")}
        >
          {value}
          {separator && i !== addressArray.length - 1 ? separator : ""}
        </Container>
      ))}
    </>
  )
}
