import { Button, Select, Table, notification } from "antd"
import { useEffect, useMemo, useState } from "react"
import formatDate from "dateformat"
import { useQuery } from "react-query"
import { AxiosError } from "axios"
import usePlatform from "contexts/platform/use-platform"
import Column from "antd/lib/table/Column"
import { ITransactionalMessage } from "models/documents/message"
import { DownloadOutlined } from "@ant-design/icons"
import moment from "moment"
import { Nullable } from "platform-client/types"
import styled from "styled-components"

const StyledDiv = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;

  > div:first-child {
    flex-grow: 1;
  }

  > div:last-child {
    text-align: right;
  }
`

interface ISelectOption {
  label: string
  value: string
}

export const BouncedSms: React.FunctionComponent = () => {
  const [bouncedMessages, setBouncedMessages] = useState<
    ITransactionalMessage[] | []
  >([])
  const platform = usePlatform()

  const options = useMemo(() => {
    return [
      {
        label: "last 24 hours",
        value: moment().subtract(1, "days").format("YYYY-MM-DDT00:00:00")
      },
      {
        label: "last 7 days",
        value: moment().subtract(7, "days").format("YYYY-MM-DDT00:00:00")
      },
      {
        label: "last 14 days",
        value: moment().subtract(14, "days").format("YYYY-MM-DDT00:00:00")
      },
      {
        label: "last 30 days",
        value: moment().subtract(30, "days").format("YYYY-MM-DDT00:00:00")
      }
    ]
  }, [])

  const [selectedSentAfterOption, setSelectedSentAfterOption] = useState<
    Nullable<ISelectOption>
  >(options[0])

  const { isFetching, refetch } = useQuery<
    ITransactionalMessage[] | undefined,
    AxiosError
  >(
    ["bounced-Sms"],
    async () =>
      platform.report.getAllTransactionalSms(
        "SendError",
        selectedSentAfterOption?.value ||
          moment().subtract(7, "days").format("YYYY-MM-DDT00:00:00") // 7 days worth of bounced Sms
      ) || [],
    {
      onSuccess: (result) => {
        if (result) {
          setBouncedMessages(result)
        }
      }
    }
  )

  const onDownloadReportSuccess = (response: {
    data: ArrayBuffer
    headers: {
      "x-filename": string
    }
  }) => {
    const a = document.createElement("a")
    a.style.display = "none"
    document.body.appendChild(a)

    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/csv" })
    )
    a.href = url
    a.download = response.headers["x-filename"]
    a.click()
    window.URL.revokeObjectURL(url)

    notification.success({
      message:
        "Report downloaded successfully. Please check your downloads folder."
    })
  }

  const {
    isFetching: isDownloadingAllTimeReport,
    refetch: downloadAllTimeReport
  } = useQuery<
    { data: ArrayBuffer; headers: { "x-filename": string } },
    AxiosError
  >(
    ["bounced-Sms-download-all-time"],
    async () =>
      platform.report.downloadAllTransactionalSms(
        "SendError",
        moment().subtract(5, "years").format("YYYY-MM-DDT00:00:00")
      ),
    {
      onSuccess: onDownloadReportSuccess,
      enabled: false
    }
  )

  const {
    isFetching: isDownloadingInViewReport,
    refetch: downloadInViewReport
  } = useQuery<
    { data: ArrayBuffer; headers: { "x-filename": string } },
    AxiosError
  >(
    ["bounced-Sms-download-in-view"],
    async () =>
      platform.report.downloadAllTransactionalSms(
        "SendError",
        selectedSentAfterOption?.value ?? ""
      ),
    {
      onSuccess: onDownloadReportSuccess,
      enabled: false
    }
  )

  const handleOnSentAfterChange = (_: string, option: unknown) => {
    setSelectedSentAfterOption(option as ISelectOption)
  }

  useEffect(() => {
    if (isFetching === false && bouncedMessages === null) {
      refetch()
    }
  })

  useEffect(() => {
    refetch()
  }, [selectedSentAfterOption, refetch])

  return (
    <>
      <StyledDiv>
        <div>
          <h4>
            {selectedSentAfterOption?.label} | {bouncedMessages.length} bounced
            SMSs
          </h4>
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => downloadAllTimeReport()}
            loading={isDownloadingAllTimeReport}
            icon={<DownloadOutlined />}
          >
            Download all-time report
          </Button>
        </div>
      </StyledDiv>

      <Table
        loading={isFetching}
        dataSource={bouncedMessages}
        rowKey={(message) => message.id}
        pagination={false}
        style={{ marginTop: "10px" }}
      >
        <Column
          dataIndex="localDateCreated"
          title="Timestamp"
          width="200px"
          render={(value: string) =>
            formatDate(new Date(value), "dd/mm/yyyy hh:MM:ss TT")
          }
        />
        <Column title="Sent To" dataIndex="sentTo" width="200px" />
        <Column
          title="Message"
          render={(message: ITransactionalMessage) => message.message}
        />
        <Column
          key="Actions"
          align="right"
          title={
            <StyledDiv>
              <div>
                <Button
                  onClick={() => downloadInViewReport()}
                  loading={isDownloadingInViewReport}
                  icon={<DownloadOutlined />}
                >
                  Download
                </Button>
              </div>
              <div>
                <Select
                  options={options}
                  onChange={handleOnSentAfterChange}
                  defaultValue="last 24 hours"
                  dropdownMatchSelectWidth
                />
              </div>
            </StyledDiv>
          }
          render={() => <></>}
        />
      </Table>
    </>
  )
}
