import { Button, Table, Typography } from "antd"
import { MoneyDisplay } from "components/display/MoneyDisplay"
import DOMPurify from "dompurify"
import { IExcess } from "platform-client/types/excess"
import { useState } from "react"
import styled from "styled-components"

const { Text } = Typography

const StyledExcessText = styled.div`
  display: block;
  position: relative;
  font-size: 1em;
  width: 600px;
  width: 70ch;
  margin-left: 7px;
  padding-left: 41px;
  border-left: 2px solid #1890ff;
`

export const ExcessesTable = ({
  excesses,
  onRemoveCustomExcess
}: IExcessesTableProps): JSX.Element => {
  const [currentlyLoading, setCurrentlyLoading] = useState<string | undefined>()
  return (
    <Table
      rowKey="id"
      expandable={{
        defaultExpandAllRows: false,
        expandedRowRender: (record: IExcess) => {
          const pureText = DOMPurify.sanitize(record.text, {
            USE_PROFILES: { html: true }
          })

          return (
            <StyledExcessText dangerouslySetInnerHTML={{ __html: pureText }} />
          )
        },
        rowExpandable: (record) => Boolean(record.text)
      }}
      dataSource={excesses}
      locale={{
        emptyText: "There are currently no excesses."
      }}
      pagination={false}
    >
      <Table.Column
        title="Excess Reference/Name"
        key="title"
        render={(_, row: IExcess) => <Text>{row.title}</Text>}
      />
      <Table.Column
        title="Status"
        width="20ch"
        key="status"
        render={(_, row: IExcess) =>
          row.modifierOwners.some((x) => x.enabled === true)
            ? "Enabled"
            : "Disabled"
        }
      />
      <Table.Column
        title="Type"
        width="20ch"
        key="type"
        render={(_, row: IExcess) => row.excessType}
      />
      <Table.Column
        title="Amount"
        width="20ch"
        key="amount"
        render={(_, row: IExcess) => <MoneyDisplay value={row.amount} />}
      />
      <Table.Column
        title=""
        width="140px"
        render={(_, row: IExcess) => {
          if (row.modifierType === "Custom") {
            return (
              <Button
                onClick={() => {
                  onRemoveCustomExcess?.(row.id, (loading: boolean) => {
                    setCurrentlyLoading(loading === true ? row.id : undefined)
                  })
                }}
                style={{ width: "100%" }}
                loading={currentlyLoading === row.id}
              >
                Remove
              </Button>
            )
          }
        }}
      />
    </Table>
  )
}

interface IExcessesTableProps {
  quoteID?: string
  excesses: IExcess[]
  platformVersion?: string
  canManageExcesses?: boolean
  onRemoveCustomExcess?: (
    excessID: string,
    callbackFn: (loading: boolean) => void
  ) => void
}
