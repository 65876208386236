import { useState } from "react"
import { useAsyncEffect } from "use-async-effect"
import { IDocument } from "models/documents/document"
import { sortBy, compose, negate, prop } from "ramda"
import useOpus from "contexts/opus/hooks/use-opus"

const sortByVersion = sortBy(compose(negate, prop("version")))

export const useGetQuoteDocuments = (
  quoteID: string | undefined,
  quoteState: string | undefined
): IQuoteEvents => {
  const [documents, setDocuments] = useState<IDocument[]>([])
  const [isDocumentsReady, setIsDocumentsReady] = useState<boolean>(false)
  const [isDocumentsFailed, setIsDocumentsFailed] = useState<boolean>(false)
  const [isDocumentsLoading, setDocumentsLoading] = useState<boolean>(false)
  const { quoteClient } = useOpus()

  const checkDocumentsStatus = async (quoteIDArg: string) => {
    const delay = (ms: number) => new Promise((res) => setTimeout(res, ms))
    const documentsMaxCalls = 5
    let delayTime = 2000

    for (let i = 1; i <= documentsMaxCalls; i++) {
      await delay(delayTime)
      const _documents = await quoteClient.getQuoteDocuments(quoteIDArg)

      if (_documents) {
        const _sortedDocuments = sortByVersion(_documents)
        const documentInReady =
          _sortedDocuments[0].policyDocumentStatus === "Ready"

        if (documentInReady) {
          setIsDocumentsReady(true)
          setDocuments(_documents)
          i = 5
        } else {
          if (i !== documentsMaxCalls) {
            setIsDocumentsReady(false)
            delayTime = delayTime * 2
          } else {
            setIsDocumentsReady(true)
            setDocumentsLoading(false)
            setIsDocumentsFailed(true)
          }
        }
      } else {
        setIsDocumentsReady(false)
      }
    }
  }

  useAsyncEffect(async () => {
    try {
      setDocumentsLoading(true)
      if (quoteID && !isDocumentsReady) {
        await checkDocumentsStatus(quoteID)
      }
      setDocumentsLoading(false)
    } catch (error) {
      setDocuments([])
      setDocumentsLoading(false)
    }
  }, [quoteID, quoteState])

  return {
    documents,
    isDocumentsReady,
    isDocumentsFailed,
    isDocumentsLoading
  }
}

interface IQuoteEvents {
  documents: IDocument[]
  isDocumentsReady: boolean
  isDocumentsFailed: boolean
  isDocumentsLoading: boolean
}
