import axios, { AxiosInstance, AxiosRequestConfig } from "axios"
import { MockPolicyDetailEditSession } from "mocks/underwriting/quote-edit-session"
import {
  IFinanceFee,
  IPolicyDetailEditSession,
  IPolicyDetailHeader,
  IPriceHeader,
  Nullable
} from "models/underwriting/quote-edit-session"
import {
  ICompanyController,
  IQuoteBuilderController,
  ICoverageController,
  IExcessController,
  IOpportunityController,
  ITobesController,
  IInvoiceController,
  IProductController,
  IUserController,
  ICommissionController,
  IQuoteBuilderResponse
} from "platform-client/client/controllers"
import { IClaimsController } from "platform-client/client/controllers/claims"
import { IDifferenceController } from "platform-client/client/controllers/difference"
import { IDocumentController } from "platform-client/client/controllers/document"
import { IPayOnAccountController } from "platform-client/client/controllers/pay-on-account"
import { IPolicyholdersController } from "platform-client/client/controllers/policyholders"
import { IQuoteController } from "platform-client/client/controllers/quote"
import { IPolicyController } from "platform-client/client/controllers/policy"
import { IVariantController } from "platform-client/client/controllers/variant"
import { IEndorsementController, IMoney } from "platform-client/types"
import {
  ILockData,
  UpdateCoverPeriod
} from "platform-client/types/quote-builder"
import { getApiUrl } from "utils/api/apiUrl"
import { IOrganizationManagementController } from "platform-client/client/controllers/organization-management"
import { IQuickQuoteController } from "platform-client/client/controllers/quick-quote"
import { mockSection } from "mocks/underwriting/console"
import { IEricController } from "platform-client/client/controllers/eric"
import { IReportController } from "platform-client/client/controllers/reports"
import { IFatZebraController } from "platform-client/client/controllers/fat-zebra"
import { IFatZebraVerificationProps } from "models/fatzebra"

export type TAddFee = Pick<IFinanceFee, "name" | "referenceID" | "net"> & {
  taxRuleSetReferenceID: string
}

export interface IPlatformClient {
  token?: string
  client: AxiosInstance

  /**
   * TODO: Move these functions to a
   * quoteEditSession property to keep
   * things clean/grouped
   */
  startEditSession: (quoteId: string) => Promise<IPolicyDetailEditSession>
  getActiveEditSessions: (
    quoteId: string
  ) => Promise<IPolicyDetailEditSession[]>
  saveEditSession: (
    editSessionId: string,
    note?: string
  ) => Promise<IPolicyDetailHeader>
  rerateEditSession: (
    editSessionId: string
  ) => Promise<IPolicyDetailEditSession>
  priceEditSession: (editSessionId: string) => Promise<IPriceHeader>
  discardEditSession: (
    editSessionId: string
  ) => Promise<IPolicyDetailEditSession>
  getEditSession: (editSessionId: string) => Promise<IPolicyDetailEditSession>

  product: IProductController
  quoteBuilder: IQuoteBuilderController
  coverage: ICoverageController
  quote: IQuoteController
  policy: IPolicyController
  endorsement: IEndorsementController
  invoice: IInvoiceController
  company: ICompanyController
  tobes: ITobesController
  excess: IExcessController
  opportunity: IOpportunityController
  policyholders: IPolicyholdersController
  payOnAccount: IPayOnAccountController
  variant: IVariantController
  user: IUserController
  commission: ICommissionController
  difference: IDifferenceController
  claims: IClaimsController
  document: IDocumentController
  organizationManagement: IOrganizationManagementController
  quickQuote: IQuickQuoteController
  eric: IEricController
  report: IReportController
  fatZebra: IFatZebraController
}

export type TPlatformResponse = IQuoteBuilderResponse

type TCreatePlatformClient = (
  token?: string,
  options?: AxiosRequestConfig
) => IPlatformClient

export const createPlatformClient: TCreatePlatformClient = (
  token?: string,
  options = {}
) => {
  const platform = axios.create({
    ...options,
    ...{
      timeout: 30000,
      baseURL: `${getApiUrl(window.location.host)}/v3`,
      headers: {
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`
      }
    }
  })

  const platformv2 = axios.create({
    ...options,
    ...{
      timeout: 30000,
      baseURL: `${getApiUrl(window.location.host)}/v2`,
      headers: {
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`
      }
    }
  })

  const startEditSession = async (
    quoteId: string
  ): Promise<IPolicyDetailEditSession> => {
    const response = await platform.get(`quote/editsessions/${quoteId}/start`)

    return response.data.data
  }

  const getActiveEditSessions = async (
    quoteId: string
  ): Promise<IPolicyDetailEditSession[]> => {
    const response = await platform.get(
      `quote/editsessions/${quoteId}/active`,
      {
        params: { all: true }
      }
    )

    return response.data.data
  }

  const saveEditSession = async (
    editSessionId: string,
    note?: string
  ): Promise<IPolicyDetailHeader> => {
    const response = await platform.post(
      `quote/editsessions/${editSessionId}/save`,
      {
        note
      }
    )

    return response.data.data
  }

  const rerateEditSession = async (
    editSessionId: string
  ): Promise<IPolicyDetailEditSession> => {
    const response = await platform.post(
      `quote/editsessions/${editSessionId}/rerate`
    )

    return response.data.data
  }

  const priceEditSession = async (
    editSessionId: string
  ): Promise<IPriceHeader> => {
    const response = await platform.get(
      `quote/editsessions/${editSessionId}/paymentplans`
    )
    return response.data.data
  }

  const discardEditSession = async (
    editSessionId: string
  ): Promise<IPolicyDetailEditSession> => {
    const response = await platform.post(
      `quote/editsessions/${editSessionId}/discard`
    )

    return response.data.data
  }

  const getEditSession = async (
    editSessionId: string
  ): Promise<IPolicyDetailEditSession> => {
    const response = await platform.get(`quote/editsessions/${editSessionId}`)

    return response.data.data
  }

  const eric: IEricController = {
    uploadCsv: async (data) => {
      const response = await platform.post(`import/eric/upload`, data, {
        headers: {
          "content-type": "multipart/form-data"
        },
        timeout: 180000
      })

      return response.data.data
    },
    getPolicyList: async (
      isBusiness,
      expiringToday,
      statusFilter,
      searchTerm
    ) => {
      let url = `portal/eric/list?isBusiness=${isBusiness}&expiringToday=${expiringToday}`
      if (statusFilter) {
        url = url + `&statusFilter=${statusFilter}`
      }
      if (searchTerm) {
        url = url + `&searchTerm=${searchTerm}`
      }
      const response = await platform.get(url)

      return response.data.data
    },
    sendEmail: async (importPolicyID) => {
      const response = await platform.post(
        `import/eric/${importPolicyID}/sendemail`
      )
      return response.data.data
    }
  }

  const organizationManagement: IOrganizationManagementController = {
    listOrgClaims: async () => {
      const response = await platform.get(`opusorgs/claims/list`)
      return response.data.data
    },
    listOrgRoles: async () => {
      const response = await platform.get(`opusorgs/roles/list`)
      return response.data.data
    }
  }

  const product: IProductController = {
    getProductConfigurationByReference: async (productReference: string) => {
      const response = await platform.get(
        `products/configuration?productReference=${productReference}`
      )
      return response.data.data
    },
    getProductConfigurationById: async (productId: string) => {
      const response = await platform.get(`products/configuration/${productId}`)
      return response.data.data
    },
    getProducts: async () => {
      const response = await platform.get(`products/active`)
      return response.data.data
    },
    getProductById: async (productId: string, coverType?: Nullable<string>) => {
      const response = await platform.get(
        `products/${productId}${coverType ? "?coverType=" + coverType : ""}`
      )
      return response.data.data
    },
    getProductDeclineReasons: async (productID) => {
      const response = await platform.get(
        `products/${productID}/declinereasons`
      )
      return response.data.data
    }
  }

  const payOnAccount: IPayOnAccountController = {
    bindQuote: async (quoteID: string, pricingPlanReferenceID: string) => {
      const response = await platform.post(`payonaccount/${quoteID}/bind`, {
        pricingPlanReferenceID
      })

      return response.data.data
    }
  }

  const company: ICompanyController = {
    listCompanies: async (typeFilter?: string) => {
      const response = typeFilter
        ? await platform.get(`companies/list?typeFilter=${typeFilter}`)
        : await platform.get("companies/list")

      return response.data.data
    },
    createCompany: async (request) => {
      const response = await platform.post(`companies`, request)
      return response.data.data
    },
    updateCompany: async (id, request) => {
      const response = await platform.post(`companies/${id}`, request)
      return response.data.data
    },
    getCompany: async (id) => {
      const response = await platform.get(`companies/${id}`)
      return response.data.data
    },
    listCompanyUsers: async (companyID: string) => {
      const response = await platform.get(`companies/${companyID}/users`)
      return response.data.data
    }
  }

  const coverage: ICoverageController = {
    listCoverageDefinitions: async (
      productID: number,
      sectionReferenceID?: string
    ) => {
      const response = await platform.get(
        `coverages?productID=${productID}&sectionReferenceID=${sectionReferenceID}`
      )

      return response.data.data
    }
  }

  const quoteBuilder: IQuoteBuilderController = {
    addCoverage: async (editSessionID, data) => {
      const response = await platform.post(`quotebuilder/coverages`, {
        editSessionID,
        data
      })
      return response.data.data
    },
    updateCoverage: async (editSessionID, coverageID, data) => {
      const response = await platform.put(
        `quotebuilder/coverages/${coverageID}`,
        {
          editSessionID,
          data
        }
      )
      return response.data.data
    },
    deleteCoverage: async (editSessionID, coverageID) => {
      const response = await platform.delete(
        `quotebuilder/coverages/${coverageID}`,
        {
          data: {
            editSessionID
          }
        }
      )
      return response.data.data
    },
    updateCoverageCoverPeriod: async (
      editSessionID: string,
      coverageID: string,
      data: UpdateCoverPeriod
    ) => {
      const response = await platform.post(
        `quotebuilder/coverages/${coverageID}/coverperiod`,
        {
          editSessionID,
          data
        }
      )
      return response.data.data
    },
    addCustomCoverage: async (editSessionID, data) => {
      const response = await platform.post(`quotebuilder/coverages/addcustom`, {
        editSessionID,
        data
      })
      return response.data.data
    },

    addFee: async (editSessionID, data) => {
      const response = await platform.post(`quotebuilder/fees`, {
        editSessionID,
        data
      })
      return response.data.data
    },

    updateFee: async (editSessionID, feeId, data) => {
      const response = await platform.put(`quotebuilder/fees/${feeId}`, {
        editSessionID,
        data
      })
      return response.data.data
    },

    deleteFee: async (editSessionID, feeId) => {
      const response = await platform.delete(`quotebuilder/fees/${feeId}`, {
        data: { editSessionID: editSessionID }
      })
      return response.data.data
    },

    updateSection: async (editSessionId, sessionId, data) => {
      const response = await platform.put(
        `quotebuilder/sections/${sessionId}`,
        {
          editSessionId,
          data
        }
      )
      return response.data.data
    },

    updateSectionCoverPeriod: async (
      editSessionID: string,
      sectionID: string,
      data: UpdateCoverPeriod
    ) => {
      const response = await platform.post(
        `quotebuilder/sections/${sectionID}/coverperiod`,
        {
          editSessionID,
          data
        }
      )
      return response.data.data
    },

    addSubsection: async (editSessionID, data) => {
      const response = await platform.post(`quotebuilder/subsections`, {
        editSessionID: editSessionID,
        data: data
      })

      return response.data.data
    },

    updateSubsection: async (editSessionID, subsectionID, data) => {
      const response = await platform.put(
        `quotebuilder/subsections/${subsectionID}`,
        {
          editSessionID,
          data
        }
      )

      return response.data.data
    },

    updateSubsectionCoverPeriod: async (
      editSessionID: string,
      subsectionID: string,
      data: UpdateCoverPeriod
    ) => {
      const response = await platform.post(
        `quotebuilder/subsections/${subsectionID}/coverperiod`,
        {
          editSessionID,
          data
        }
      )
      return response.data.data
    },

    deleteSubsection: async (editSessionID, subsectionID) => {
      const response = await platform.delete(
        `quotebuilder/subsections/${subsectionID}`,
        {
          data: {
            editSessionID
          }
        }
      )

      return response.data.data
    },

    addEndorsement: async (
      editSessionID,
      componentID,
      componentType,
      endorsementID
    ) => {
      const response = await platform.post(`quotebuilder/endorsements`, {
        editSessionID,
        data: {
          componentID: componentID,
          componentType: componentType,
          systemEndorsementID: endorsementID
        }
      })

      return response.data.data
    },

    disableEndorsement: async (
      editSessionID,
      componentID,
      componentType,
      endorsementID
    ) => {
      const response = await platform.delete(
        `quotebuilder/endorsements/${endorsementID}?componentType=${componentType}&componentID=${componentID}`,
        {
          data: {
            editSessionID
          }
        }
      )

      return response.data.data
    },

    enableEndorsement: async (
      editSessionID,
      componentID,
      componentType,
      endorsementID
    ) => {
      const response = await platform.post(
        `quotebuilder/endorsements/${endorsementID}/enable?componentType=${componentType}&componentID=${componentID}`,
        {
          editSessionID
        }
      )

      return response.data.data
    },

    addExcess: async (editSessionID, componentID, componentType, excessID) => {
      const response = await platform.post(`quotebuilder/excesses`, {
        editSessionID,
        data: {
          componentID: componentID,
          componentType: componentType,
          systemExcessID: excessID
        }
      })

      return response.data.data
    },

    disableExcess: async (
      editSessionID,
      componentID,
      componentType,
      excessID
    ) => {
      const response = await platform.delete(
        `quotebuilder/excesses/${excessID}?componentType=${componentType}&componentID=${componentID}`,
        {
          data: {
            editSessionID
          }
        }
      )

      return response.data.data
    },

    enableExcess: async (
      editSessionID,
      componentID,
      componentType,
      excessID
    ) => {
      const response = await platform.post(
        `quotebuilder/excesses/${excessID}/enable?componentType=${componentType}&componentID=${componentID}`,
        {
          editSessionID
        }
      )

      return response.data.data
    },

    forceAddCustomExcess: async (quoteID, request) => {
      const response = await platform.post(
        `quotebuilder/excesses/addcustom/force/${quoteID}`,
        request
      )

      return response.data.data
    },

    forceRemoveCustomExcess: async (quoteID, request) => {
      const response = await platform.post(
        `quotebuilder/excesses/removecustom/force/${quoteID}`,
        request
      )

      return response.data.data
    },

    toggleSection: async (editSessionID, sectionID, enable) => {
      const response = await platform.post(
        `quotebuilder/sections/${sectionID}/enable`,
        {
          data: { enable },
          editSessionID
        }
      )

      return response.data.data
    },

    toggleSubsection: async (editSessionID, subsectionID, enable) => {
      const response = await platform.post(
        `quotebuilder/subsections/${subsectionID}/enable`,
        {
          data: { enable },
          editSessionID
        }
      )

      return response.data.data
    },

    toggleCoverage: async (editSessionID, coverageID, enable) => {
      const response = await platform.post(
        `quotebuilder/coverages/${coverageID}/enable`,
        {
          data: { enable },
          editSessionID
        }
      )

      return response.data.data
    },

    addCustomEndorsementForce: async (
      quoteId,
      text,
      title,
      componentId,
      compnentType
    ) => {
      const response = await platform.post(
        `quotebuilder/endorsements/addcustom/force/${quoteId}`,
        {
          title: title,
          text: text,
          componentID: componentId,
          componentType: compnentType
        }
      )
      return response.data.data
    },

    removeCustomEndorsementForce: async (
      quoteId,
      endorsementId,
      componentId,
      compnentType
    ) => {
      const response = await platform.post(
        `quotebuilder/endorsements/removecustom/force/${quoteId}`,
        {
          endorsementID: endorsementId,
          componentID: componentId,
          componentType: compnentType
        }
      )
      return response.data.data
    },
    /**
     * These return a mock for the moment
     * Will update as needed.
     */
    deleteExposure: async () =>
      Promise.resolve({
        policyDetail: MockPolicyDetailEditSession.policyDetail,
        section: mockSection
      }),
    addSection: async () =>
      Promise.resolve({
        policyDetail: MockPolicyDetailEditSession.policyDetail,
        section: mockSection
      }),
    deleteSection: async () =>
      Promise.resolve({
        policyDetail: MockPolicyDetailEditSession.policyDetail,
        section: mockSection
      }),
    updateSubsectionExposures: async () =>
      Promise.resolve({
        policyDetail: MockPolicyDetailEditSession.policyDetail,
        section: mockSection
      }),
    lockCoverage: async (
      editSessionID: string,
      coverageID: string,
      data: ILockData
    ) => {
      const response = await platform.post(
        `quotebuilder/coverages/${coverageID}/lock`,
        {
          editSessionID,
          data
        }
      )
      return response.data.data
    }
  }

  const policy: IPolicyController = {
    getPolicy: async (policyID) => {
      const response = await platform.get(`policies/${policyID}`)
      return response.data.data
    },

    cancelPolicy: async (policyID, cancellationDetails) => {
      const response = await platform.post(
        `policies/${policyID}/cancel`,
        cancellationDetails
      )
      return response.data.data
    },

    previewCancellation: async (policyID, cancellationDetails) => {
      const response = await platform.post(
        `policies/${policyID}/cancel/preview`,
        cancellationDetails
      )
      return response.data.data
    },

    getPolicies: async () => {
      const response = await platform.get(`portal/policies/list`)
      return response.data.data
    },

    getPolicyCompanies: async (policyID) => {
      const response = await platform.get(`policies/${policyID}/companies`)
      return response.data.data
    },

    getPolicySearch: async (searchQuery) => {
      const response = await platform.get(`search`, {
        params: {
          q: searchQuery,
          entityType: "Policy"
        }
      })
      return response.data.data
    },

    getPolicyDocuments: async (policyID) => {
      const response = await platform.get(`policies/${policyID}/documents`)
      return response.data.data
    },

    cancellationReasons: async (policyID) => {
      const response = await platform.get(`policies/${policyID}/cancel/reasons`)
      return response.data.data
    },

    getPolicyHistorySummary: async (policyID) => {
      const response = await platform.get(
        `policies/${policyID}/history/summary`
      )
      return response.data.data
    },

    getNotes: async (policyId: string) => {
      const response = await platform.get(`notes/policies/${policyId}`)
      return response.data.data
    },

    createNote: async (policyId, requestBody) => {
      const response = await platform.post(
        `notes/policies/${policyId}`,
        requestBody
      )
      return response.data.data
    },

    getAnswers: async (policyId: string) => {
      const response = await platform.get(`policies/${policyId}/answers`)
      return response.data.data
    },
    getOracleResults: async (policyId: string) => {
      const response = await platform.get(`policies/${policyId}/oracleresults`)
      return response.data.data
    },
    updatePaymentDetails: async (policyID: string, paymentMethodID: string) => {
      const response = await platformv2.post(
        `policies/${policyID}/updatepaymentmethod?paymentMethodId=${paymentMethodID}`
      )
      return response.data.data
    }
  }

  const invoice: IInvoiceController = {
    listInvoices: async ({ status, companyId }) => {
      const response = await platform.get("invoices", {
        params: {
          invoicestatus: status,
          companyId
        }
      })
      return response.data.data
    },

    setInvoiceStatus: async (invoiceId, status) => {
      const response = await platform.post(
        `invoices/${invoiceId}/status/${status}`
      )
      return response.data
    }
  }

  const variant: IVariantController = {
    getEditSession: async (editSessionId: string) => {
      const response = await platform.get(
        `variant/editsessions/${editSessionId}`
      )
      return response.data.data
    },
    completeSession: async (editSessionId: string) => {
      const response = await platform.get(
        `variant/editsessions/${editSessionId}/complete`
      )
      return response.data.data
    },
    discardSession: async (editSessionId: string) => {
      const response = await platform.post(
        `variant/editsessions/${editSessionId}/discard`
      )
      return response.data.data
    },
    validateSession: async (editSessionId: string) => {
      const response = await platform.get(
        `variant/editsessions/${editSessionId}/validated`
      )
      return response.data.data
    },
    getActiveEditSessions: async (variantId: string) => {
      const response = await platform.get(
        `variant/editsessions/${variantId}/active`
      )
      return response.data.data
    },
    startEditSession: async (variantId: string) => {
      const response = await platform.get(
        `variant/editsessions/${variantId}/start`
      )
      return response.data.data
    },
    getAnswers: async (variantId: string) => {
      const response = await platform.get(`variant/${variantId}/answers`)
      return response.data.data
    }
  }

  const policyholders: IPolicyholdersController = {
    createPolicyholder: async (request) => {
      const response = await platform.post(`policyholders`, request)
      return response.data.data
    },
    updatePolicyholder: async (id, request) => {
      const response = await platform.post(`policyholders/${id}`, request)
      return response.data.data
    },
    getPolicyholder: async (id) => {
      const response = await platform.get(`policyholders/${id}`)
      return response.data.data
    },
    listPolicyholders: async () => {
      const response = await platform.get(`policyholders/list`)
      return response.data.data
    },
    searchPolicyholders: async (params) => {
      const response = await platform.get(`policyholders/search`, {
        params
      })
      return response.data.data
    }
  }

  const opportunity: IOpportunityController = {
    createOpportunity: async (request) => {
      const response = await platform.post(`opportunities/create`, {
        openSession: request.openSession,
        answers: request.answers,
        partnerID: request.partnerID,
        name: request.name,
        partnerReference: request.partnerReference,
        productReferenceID: request.productReferenceID,
        policyholderID: request.policyholderID,
        introducer: request.introducer
      })
      return response.data.data
    },

    createMTAOpportunity: async (policyID, request) => {
      const response = await platform.post(
        `opportunities/${policyID}/createmta`,
        request
      )
      return response.data.data
    },

    getOpportunity: async (id) => {
      const response = await platform.get(`opportunities/${id}`)
      return response.data.data
    },

    variantDuplicate: async (id, name) => {
      const response = await platform.post(`variants/${id}/duplicate`, { name })
      return response.data.data
    },

    listOpportunities: async (params = {}) => {
      const response = await platform.get(`portal/opportunities/list`, {
        params
      })
      return response.data.data
    },
    createNote: async (id, requestBody) => {
      const response = await platform.post(
        `notes/opportunities/${id}`,
        requestBody
      )
      return response.data.data
    },
    getNotes: async (id) => {
      const response = await platform.get(`notes/opportunities/${id}`)
      return response.data.data
    }
  }

  const tobes: ITobesController = {
    getQuestionPage: async (
      exposureID,
      page,
      questionSetReferenceID,
      editSessionID
    ) => {
      const response = await platform.post(`tobes/questionpage`, {
        data: {
          questionSetReferenceID,
          exposureID,
          page
        },
        editSessionID
      })
      return response.data.data
    },
    tobesStart: async (editSessionID, exposureID, questionSetReferenceID) => {
      const response = await platform.post(`tobes/start`, {
        data: {
          exposureReferenceID: exposureID,
          questionSetReferenceID
        },
        editSessionID
      })
      return response.data.data
    },
    tobesSubmit: async (
      editSessionID,
      page,
      questionSetReferenceID,
      answers,
      exposureID = null
    ) => {
      const response = await platform.post(`tobes/submit`, {
        data: { answers, exposureID, page, questionSetReferenceID },
        editSessionID
      })
      return response.data.data
    }
  }

  const endorsement: IEndorsementController = {
    listEndorsements: async (schemeReferenceID, sectionReferenceID = null) => {
      const response = await platform.get(`endorsements`, {
        params: {
          schemeReferenceID,
          sectionReferenceID
        }
      })
      return response.data.data
    },

    createEndorsement: async () => Promise.resolve(),
    updateEndorsement: async () => Promise.resolve(),
    deleteEndorsement: async () => Promise.resolve(),
    createEndorsementVersion: async () => Promise.resolve()
  }

  const excess: IExcessController = {
    listExcesses: async (schemeReferenceID, sectionReferenceID = null) => {
      const response = await platform.get(`excesses`, {
        params: {
          schemeReferenceID,
          sectionReferenceID
        }
      })
      return response.data.data
    },

    createExcess: async () => Promise.resolve(),
    updateExcess: async () => Promise.resolve(),
    deleteExcess: async () => Promise.resolve(),
    createExcessVersion: async () => Promise.resolve()
  }

  const document: IDocumentController = {
    uploadDocument: async (data) => {
      const response = await platform.post(`documents/upload`, data, {
        headers: {
          "content-type": "multipart/form-data"
        }
      })

      return response.data.data
    }
  }

  const quickQuote: IQuickQuoteController = {
    createQuickQuote: async (request) => {
      const response = await platform.post(`quickquotes/create`, request)
      return response.data.data
    },
    convertQuickQuote: async (quoteID, body) => {
      const response = await platform.post(
        `quickquotes/${quoteID}/createopportunity`,
        body
      )
      return response.data.data
    }
  }

  const quote: IQuoteController = {
    getQuote: async (quoteID) => {
      const response = await platform.get(`quotes/${quoteID}`)
      return response.data.data
    },
    getQuoteActions: async (quoteID) => {
      const response = await platform.get(`quotes/${quoteID}/actions`)
      return response.data.data
    },
    revokeManualReferral: async (quoteID) => {
      const response = await platform.post(`quotes/${quoteID}/refer/revoke`)
      return response.data.data
    },
    nominateQuote: async (opportunityID, quoteID) => {
      const response = await platform.post(
        `opportunities/${opportunityID}/nominatequote`,
        {
          quoteID
        }
      )
      return response.data.data
    },
    confirmQuote: async (quoteID) => {
      const response = await platform.post(`quotes/${quoteID}/confirm`)
      return response.data.data
    },
    getDocuments: async (quoteID, historyID) => {
      const response = await platform.get(
        `quotes/${quoteID}/documents${
          historyID ? `&historyID=${historyID}` : ""
        }`
      )
      return response.data.data
    },
    getQuoteEvents: async (quoteID) => {
      const response = await platform.get(`quotes/${quoteID}/events`)
      return response.data.data
    },
    createQuote: async (quoteData) => {
      const response = await platform.post("quotes/create", quoteData)
      return response.data.data
    },
    convertQuote: async (quoteID, paymentPlanRef, voucherCode = undefined) => {
      const response = await platform.get(
        `debug/quote/${quoteID}/convert?paymentPlanRef=${
          paymentPlanRef || ""
        }&voucherCode=${voucherCode || ""}`
      )
      return response.data.data
    },
    quoteCompanies: async (quoteID) => {
      const response = await platform.get(`quotes/${quoteID}/companies`)
      return response.data.data
    },
    updateReferral: async (quoteID, referralData) => {
      const response = await platform.post(
        `quotes/${quoteID}/referral`,
        referralData
      )
      return response.data.data
    },
    confirmReferralQuote: async (quoteID, note) => {
      const response = await platform.post(
        `quotes/${quoteID}/referral/confirm`,
        { note }
      )
      return response.data.data
    },
    declineReferralQuote: async (quoteID, declineReasonReferenceID, note) => {
      const response = await platform.post(
        `quotes/${quoteID}/referral/decline`,
        { declineReasonReferenceID, note }
      )
      return response.data.data
    },
    getAnswers: async (quoteId) => {
      const response = await platform.get(`quotes/${quoteId}/answers`)
      return response.data.data
    },
    getOracleResults: async (quoteId: string) => {
      const response = await platform.get(`quotes/${quoteId}/oracleresults`)
      return response.data.data
    },
    referQuote: async (quoteId: string, comment: string) => {
      const response = await platform.post(`quotes/${quoteId}/refer`, {
        comment
      })
      return response.data.data
    },
    overridePremium: async (quoteId: string, money: IMoney) => {
      const response = await platform.post(
        `quotes/hybrid/${quoteId}/overridepremium`,
        {
          premium: {
            value: money.value,
            currencyCode: money.currencyCode,
            unit: "Minor"
          }
        }
      )
      return response.data.data
    },
    createNote: async (id, requestBody) => {
      const response = await platform.post(`notes/quotes/${id}`, requestBody)
      return response.data.data
    },
    getNotes: async (id) => {
      const response = await platform.get(`notes/quotes/${id}`)
      return response.data.data
    },
    extendExpiry: async (id, newExpiryDate) => {
      const response = await platform.post(
        `quotes/${id}/extend?extendTo=${newExpiryDate}`
      )
      return response.data.data
    }
  }

  const user: IUserController = {
    getCurrentUser: async () => {
      const response = await platform.get(`user/me`)
      return response.data.data
    },
    inviteUser: async (requestBody) => {
      const response = await platform.post(`users/invite`, requestBody)
      return response.data.data
    },
    editUserEmail: async (userId, requestBody) => {
      const response = await platform.post(
        `user/${userId}/changeemail`,
        requestBody
      )
      return response.data.data
    },
    editUserEmailExistingAccount: async (userId, requestBody) => {
      const response = await platform.post(
        `user/${userId}/changeowner`,
        requestBody
      )
      return response.data.data
    },
    validateEditUserEmail: async (userId, requestBody) => {
      const response = await platform.post(
        `user/${userId}/changeemail/validate`,
        requestBody
      )
      return response.data.data
    },
    search: async (query, userRoles) => {
      const response = await platform.get(`users/search`, {
        params: {
          query,
          userRoles
        }
      })
      return response.data.data
    }
  }

  const commission: ICommissionController = {
    setCommissionOverride: async (requestBody) => {
      const response = await platform.post("/commission/set", requestBody)
      return response.data.data
    },
    getCommissionSetup: async (companyId) => {
      const response = await platform.get(
        `commission/setup/company/${companyId}`
      )
      return response.data.data
    }
  }

  const difference: IDifferenceController = {
    mtaQuote: async (quoteId) => {
      const response = await platform.get(
        `/difference/risk/mtaquote/${quoteId}`
      )
      return response.data.data
    },
    mtaVariant: async (variantId) => {
      const response = await platform.get(
        `/difference/risk/mtavariant/${variantId}`
      )
      return response.data.data
    },
    policyHistory: async (policyId) => {
      const response = await platform.get(`/difference/risk/policy/${policyId}`)
      return response.data.data
    }
  }

  const claims: IClaimsController = {
    getClaimByID: async (claimID) => {
      const response = await platform.get(`claims/${claimID}`)
      return response.data.data
    },
    addClaim: async (request) => {
      const response = await platform.post(`claims/add`, request)
      return response.data.data
    },
    editClaim: async (claimID, request) => {
      const response = await platform.post(`claims/update/${claimID}`, request)
      return response.data.data
    },
    getByReferenceId: async (referenceId: string) => {
      const response = await platform.get(`claims/policy/${referenceId}`)
      return response.data.data
    },
    getProductClaimTypes: async (productReferenceID: string) => {
      const response = await platform.get(
        `claims/types/product/${productReferenceID}`
      )
      return response.data.data
    }
  }

  const report: IReportController = {
    getPaymentPlansPastDue: async () => {
      const url = `portal/paymentplans/pastDue`
      const response = await platform.get(url)

      return response.data.data
    },
    getTransactionalEmails: async (
      deliveryStatus: string,
      sentOnOrAfter: string
    ) => {
      const url = `portal/transactionalemails/list`
      const response = await platform.get(url, {
        params: { deliveryStatus, sentOnOrAfter }
      })
      return response.data.data
    },
    downloadTransactionalEmails: async (
      deliveryStatus: string,
      sentOnOrAfter: string
    ) => {
      const url = `portal/transactionalemails/download`
      const response: { data: ArrayBuffer; headers: { "x-filename": string } } =
        await platform.get(url, {
          params: { deliveryStatus, sentOnOrAfter },
          responseType: "arraybuffer"
        })
      return response
    },
    getAllTransactionalSms: async (status: string, sentOnOrAfter: string) => {
      const url = `portal/transactionalsms/list`
      const response = await platform.get(url, {
        params: { status, sentOnOrAfter }
      })
      return response.data.data
    },
    downloadAllTransactionalSms: async (
      status: string,
      sentOnOrAfter: string
    ) => {
      const url = `portal/transactionalsms/download`
      const response: { data: ArrayBuffer; headers: { "x-filename": string } } =
        await platform.get(url, {
          params: { status, sentOnOrAfter },
          responseType: "arraybuffer"
        })
      return response
    }
  }

  const fatZebra: IFatZebraController = {
    getConfig: async (quoteID?: string, policyID?: string) => {
      const response = await platformv2.get(
        `fatzebra/config?${quoteID ? "&quoteID=" + quoteID : ""}${
          policyID ? "&policyID=" + policyID : ""
        }`
      )
      return response.data.data
    },
    createPaymentIntentVerification: async (
      props: IFatZebraVerificationProps
    ) => {
      const response = await platformv2.post(
        "fatzebra/paymentintent/verification",
        props
      )
      return response.data.data
    }
  }

  return {
    client: platform,
    startEditSession,
    getActiveEditSessions,
    saveEditSession,
    rerateEditSession,
    priceEditSession,
    discardEditSession,
    getEditSession,
    product,
    quote,
    policy,
    invoice,
    endorsement,
    quoteBuilder,
    excess,
    claims,
    company,
    coverage,
    tobes,
    opportunity,
    policyholders,
    variant,
    payOnAccount,
    user,
    commission,
    difference,
    document,
    organizationManagement,
    quickQuote,
    eric,
    report,
    fatZebra
  }
}

export default createPlatformClient
