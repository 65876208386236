import { ClearSessionStorage } from "contexts/use-session-storage"
import { useHistory } from "react-router"
import { useFirebaseAuthContext } from "contexts/authorization/firebase-auth-contex"

export const AccountLogOutRedirect: React.FunctionComponent = (props) => {
  const history = useHistory()
  const authContext = useFirebaseAuthContext()

  ClearSessionStorage() // Clears out Opus token from session storage

  authContext
    .logout()
    .then(() => {
      // Signed out
      history.push("/")
    })
    .catch((error) => {
      const errorCode = error.code
      const errorMessage = error.message
      console.log(errorCode, errorMessage)
    })

  return <></>
}
