import React from "react"
import { Row, Col, Space, Tag } from "antd"
import { IConfigurationContext } from "contexts/configuration/context"
import { IPolicyMeta, IQuote, IRisk } from "platform-client/types/quote"
import {
  ICompanyInRole,
  IPolicyDetailSet,
  IPolicyholder
} from "platform-client/types"
import {
  IQuoteProduct,
  IQuotePaymentPlans,
  IQuotePaymentLink,
  IQuoteLifecycles,
  IQuoteOpportunity
} from "../../view"
import { QuoteCover } from "../../components/quote-cover"
import { QuotePayment } from "../../components/quote-payment"
import { QuotePremium } from "../../components/quote-premium"
import { QuoteDeclinedAlert } from "../../components/quote-declined-alert"
import { PolicyHolder } from "components/PolicyHolder"
import SummaryOfCover from "components/SummaryOfCover"
import { QuoteReferredAlert } from "../../components/quote-referred-alert"
import ListCard from "components/ListCard"
import { Address } from "components"
import { MoneyDisplay } from "components/display/MoneyDisplay"
import { DateDisplay } from "components/display/DateDisplay"
import styled from "styled-components"
import { QuoteStateLabels } from "containers/quotes/constants"
import { Link } from "react-router-dom"
import { QuoteExpiryDate } from "../../components/quote-extend-expiry-date"
import { IScheme } from "models/quotes/scheme"

const FullHeightListCard = styled(ListCard)`
  height: 100%;
`

const FullHeightPolicyHolder = styled(PolicyHolder)`
  height: 100%;
`

export const Summary: React.FC<ISummary> = (props) => {
  // Since this portal was initially developed with Westcor in mind,
  // we only show the annual payment plan. This will change in the future.
  const [annual] = props.quotePaymentPlans?.paymentPlans || []

  const insuranceProduct = annual?.price?.total?.rows?.find(
    (item) => item.type === "InsuranceProduct"
  )
  const fees = annual?.price?.total?.rows?.find((item) => item.type === "Fee")

  const basePremium = insuranceProduct
    ? {
        value: insuranceProduct.net.value,
        currencyCode: insuranceProduct.net.currencyCode
      }
    : annual?.price.total.net

  const taxTotal = insuranceProduct
    ? insuranceProduct.tax.total
    : annual?.price.total.tax?.total

  const totalPremium =
    insuranceProduct && fees
      ? {
          value:
            insuranceProduct.net.value +
            insuranceProduct.tax.total.value +
            fees.gross.value,
          currencyCode: insuranceProduct.net.currencyCode
        }
      : annual?.price.total.gross

  return (
    <Space
      direction="vertical"
      size={24}
      style={{ width: "100%", maxWidth: "1200px", display: "flex" }}
    >
      {props.configuration.flags.platformVersion === "v2" && (
        <QuoteReferredAlert
          isReferred={props.quote.referred}
          referralReasons={props.quote.referralReasons}
        />
      )}
      <QuoteDeclinedAlert
        isDeclined={props.quote.declined}
        declineReasons={props.quote.declineReasons}
      />

      <Row gutter={[24, 24]} style={{ gridAutoRows: "1fr" }}>
        <Col xs={24} lg={12}>
          <FullHeightListCard title="Quote details">
            <ListCard.Item label="Quote type">
              {props.configuration.quote.types[props.quote.quoteType]}
            </ListCard.Item>
            <ListCard.Item label="Product">
              {props.quoteProduct?.product?.name} (v
              {props.quoteProduct?.product?.version})
            </ListCard.Item>
            <ListCard.Item label="Underwriter">
              {props.quoteScheme?.insurerName}
            </ListCard.Item>
            <ListCard.Item label="Quote Status">
              <Tag color={QuoteStateLabels[props.quote.quoteState].color}>
                {QuoteStateLabels[props.quote.quoteState].label.toUpperCase()}
              </Tag>
              {props.quote.quoteState === "Purchased" &&
                props?.quotePolicyMeta?.policyID && (
                  <Link to={`/policy/${props?.quotePolicyMeta?.policyID}`}>
                    {props?.quotePolicyMeta.policyReferenceID}
                  </Link>
                )}
            </ListCard.Item>

            <ListCard.Item label="Created">
              {props.quote.dateCreated && (
                <DateDisplay date={props.quote.dateCreated} showTime />
              )}
            </ListCard.Item>
            <ListCard.Item label="Expiry">
              {props.quote.expiryDate && (
                <QuoteExpiryDate
                  expiryDate={props.quote.expiryDate}
                  quoteId={props.quote.id}
                  isExternal={props.quote.isExternal}
                  maxExpiry={props.maxQuoteExpiry}
                />
              )}
            </ListCard.Item>
            {props.quote.quoteType === "MTA" ? (
              <ListCard.Item label="Purchase price">
                <MoneyDisplay value={props.quote.estimateGross} />
              </ListCard.Item>
            ) : (
              annual?.price.total.gross && (
                <ListCard.Item label="Purchase price">
                  <MoneyDisplay value={annual?.price.total.gross} />
                </ListCard.Item>
              )
            )}

            {props.companies && props.companies.length > 0 && (
              <>
                {props.companies.map((company) => (
                  <ListCard.Item
                    key={company.companyRoleID}
                    label={company.role}
                  >
                    {company.company.name}
                  </ListCard.Item>
                ))}
              </>
            )}
            <ListCard.Item label="Opportunity">
              {props.quoteOpportunity && (
                <Link to={`/opportunity/${props.quoteOpportunity.id}`}>
                  {props.quoteOpportunity.reference}
                </Link>
              )}
            </ListCard.Item>
          </FullHeightListCard>
        </Col>
        <Col xs={24} lg={12}>
          {props.configuration.flags.platformVersion === "v2" && (
            <FullHeightListCard title="Proposer details">
              <ListCard.Item label="Name">
                {props.risk?.core.proposer.personName?.firstName &&
                props.risk?.core.proposer.personName?.lastName
                  ? `${props.risk.core.proposer.personName.firstName} ${props.risk.core.proposer.personName.lastName}`
                  : "-"}
              </ListCard.Item>
              <ListCard.Item label="Email">
                {props.risk?.core.proposer.emailAddress || "-"}
              </ListCard.Item>
              <ListCard.Item label="Address">
                <Address address={props.risk?.core.proposer.address} />
              </ListCard.Item>
            </FullHeightListCard>
          )}
          {props.configuration.flags.platformVersion === "v3" &&
            (props.quote.policyholderID || props.quotePolicyHolder) && (
              <FullHeightPolicyHolder
                referenceId={props.quote.policyholderID}
                policyHolder={props.quotePolicyHolder}
              />
            )}
        </Col>
      </Row>

      <Row gutter={24}>
        {props.configuration.flags.platformVersion === "v3" &&
        props.policyDetailSet ? (
          <Col span={14}>
            <SummaryOfCover policyDetailSet={props.policyDetailSet} />
          </Col>
        ) : (
          <Col span={14}>
            <QuoteCover
              quote={props.quote}
              product={props.quoteProduct}
              risk={props.risk}
            />
          </Col>
        )}
        <Col span={10}>
          {props.quote.quoteState === "PendingPayment" &&
            (props.configuration.flags.paymentType === "PaymentLink" ||
              props.configuration.flags.paymentTypes?.includes(
                "PaymentLink"
              )) && (
              <QuotePayment
                paymentLink={props.quotePaymentLink?.paymentLink}
                paymentLinkError={props.quotePaymentLink?.error}
                isWorking={props.quotePaymentLink?.isGettingPaymentLink}
                isLoaded={
                  !!props.quotePaymentLink &&
                  props.quotePaymentLink.error === false
                }
                quoteID={props.quote.id}
                quoteCoverType={props.quoteProductCoverReference}
                quoteProductReferenceID={props.quoteProductReferenceId}
                onInvalidatePaymentLink={
                  props.quoteLifecycles?.onInvalidatePaymentLink
                }
                handleCreatePaymentLink={props.handleCreatePaymentLink}
              />
            )}
          <QuotePremium
            configuration={props.configuration}
            isWorking={props.quotePaymentPlans?.isGettingPaymentPlans || false}
            grossPremium={
              props.quote.quoteType === "MTA"
                ? props.quote.estimateGross
                : totalPremium
            }
            netPremium={
              props.quote.quoteType === "MTA"
                ? props.quote.estimateNet
                : basePremium
            }
            taxTotal={
              props.quote.quoteType === "MTA"
                ? props.quote.tax?.total
                : taxTotal
            }
            fees={fees?.gross}
            iptPercentage={undefined}
          />
        </Col>
      </Row>
    </Space>
  )
}

export interface ISummary {
  configuration: IConfigurationContext
  quote: IQuote
  risk?: IRisk
  quoteOpportunity?: IQuoteOpportunity
  quotePolicyMeta?: IPolicyMeta
  quoteProduct?: IQuoteProduct
  quoteScheme?: IScheme
  quotePaymentPlans?: IQuotePaymentPlans
  quotePaymentLink?: IQuotePaymentLink
  quoteLifecycles?: IQuoteLifecycles
  quoteProductReferenceId?: string
  quoteProductCoverReference?: string
  quotePolicyHolder?: IPolicyholder
  policyDetailSet?: IPolicyDetailSet
  companies?: ICompanyInRole[]
  handleCreatePaymentLink?: () => void
  maxQuoteExpiry?: string
}
