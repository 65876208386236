import { useContext, createContext } from "react"

export type TNotificationContextNotificationsType =
  | "success"
  | "error"
  | "info"
  | "warning"
  | "open"

export enum ENotificationContextNotificationsType {
  Success = "success",
  Error = "error",
  Info = "info",
  Warning = "warning",
  Open = "open"
}

export enum ENotificationContextNotificationsPlacement {
  topLeft = "topLeft",
  topRight = "topRight",
  bottomLeft = "bottomLeft",
  bottomRight = "bottomRight"
}

export enum ENotificationContextMessagesType {
  Success = "success",
  Error = "error",
  Info = "info",
  Warning = "warning"
}

export type INotificationContextMessagesType =
  | "success"
  | "error"
  | "info"
  | "warning"
  | undefined

const notificationContextData = {
  messages: {
    200: {
      message: "Success!",
      type: ENotificationContextMessagesType.Error
    },
    400: {
      message: "Sorry something went wrong. please try again",
      type: ENotificationContextMessagesType.Error
    },
    401: {
      message: "Sorry something went wrong. please try again",
      type: ENotificationContextMessagesType.Error
    },
    422: {
      message: "Validation failed, please check fields and try again.",
      type: ENotificationContextMessagesType.Error
    },
    500: {
      message: "Sorry something went wrong. please try again",
      type: ENotificationContextMessagesType.Error
    }
  },
  notifications: {
    quote: {
      refer: {
        title: "Quote Referred",
        content: "Quote was referred successfully",
        type: ENotificationContextNotificationsType.Success
      },
      withdraw: {
        title: "Quote Withdrawn",
        content: "Quote was withdrawn successfully",
        type: ENotificationContextNotificationsType.Success
      },
      create: {
        title: "Quote Created",
        content: "Quote was created successfully",
        type: ENotificationContextNotificationsType.Success
      },
      save: {
        title: "Quote Saved",
        content: "Quote was saved successfully",
        type: ENotificationContextNotificationsType.Success
      },
      duplicate: {
        title: "Quote Duplicated",
        content: "Quote was duplicated successfully",
        type: ENotificationContextNotificationsType.Success
      },
      payment: {
        title: "Quote Payment link",
        content: "Quote payment link generated successfully",
        type: ENotificationContextNotificationsType.Success
      },
      renewal: {
        title: "Renewal Quote Saved",
        content: "Renewal Quote was saved successfully",
        type: ENotificationContextNotificationsType.Success
      }
    }
  }
}

export const NotificationContext = createContext<
  INotificationContext | undefined
>(notificationContextData)

export const useNotificationContext = (): INotificationContext | undefined => {
  const client = useContext(NotificationContext)
  return client
}

export interface INotificationContext {
  messages: {
    [key: number]: {
      message: string
      type: INotificationContextMessagesType
    }
  }
  notifications: {
    quote: {
      [key: string]: {
        title: string
        content: string
        type: TNotificationContextNotificationsType
      }
    }
  }
}
