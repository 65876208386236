import { ClaimTable } from "components/ClaimsTable"
import usePlatform from "contexts/platform/use-platform"
import { Nullable } from "platform-client/types"
import { IPolicyClaim } from "platform-client/types/claims"
import { useQuery } from "react-query"

export const PolicyClaims: React.FunctionComponent<IPolicyClaims> = (props) => {
  const { claims } = usePlatform()

  const { isLoading, data } = useQuery<IPolicyClaim[] | undefined>(
    ["policyClaim", props.policyReference],
    async () => claims.getByReferenceId(props.policyReference || "")
  )

  return (
    <ClaimTable
      claimSummary={data}
      isLoading={isLoading}
      policyReferenceId={props.policyReference || ""}
    />
  )
}

export interface IPolicyClaims {
  policyReference: Nullable<string>
}
