import { notification } from "antd"
import { Collapse } from "components/Collapse"
import {
  SaveButton,
  DeleteButton,
  AddButton,
  RemoveButton,
  ExpandButton
} from "components/Console/CellContent/Button"
import CellContentLinkButton from "components/Console/CellContent/Link"
import CellContentText from "components/Console/CellContent/Text"
import CellContentTitle from "components/Console/CellContent/Title"
import Row from "components/Console/Row"
import { TPlatformResponse } from "contexts/platform/client"
import usePlatform from "contexts/platform/use-platform"
import {
  EModiferOwnerComponentType,
  ICoverage,
  IExcess,
  IModifierOwner,
  ISubsection,
  SectionTypes
} from "platform-client/types"
import { IExcessParams } from "platform-client/types/quote-builder"
import React from "react"
import { useMutation, useQuery } from "react-query"
import styled from "styled-components"
import CellContentMoney from "../CellContent/Money"
import CellContentSelect from "../CellContent/Select"

const StyledExcesses = styled.div<{ $isMainSection: boolean }>`
  background: ${(props) => (!props.$isMainSection ? "none" : "transparent")};
  border: ${(props) => (!props.$isMainSection ? "none" : "1px solid #ccc")};
  margin-bottom: ${(props) => (!props.$isMainSection ? "none" : "10px")};
`
const StyledExcessesHeader = styled.div<{
  $isOpen: boolean
  $isMainSection: boolean
}>`
  background: none;
  border-bottom: ${(props) =>
    props.$isOpen && props.$isMainSection ? "1px solid #ccc" : "none"};
`

const StyledExcessesSummary = styled.span`
  min-width: 155px;
`

const ExcessesHeader: React.FC<IExcessesHeader> = ({
  title,
  aside,
  isOpen = false,
  toggle,
  isMainSection,
  endorsmentsStatus
}) => {
  return (
    <StyledExcessesHeader $isMainSection={isMainSection} $isOpen={isOpen}>
      <Row
        type={isMainSection ? "header" : "subHeader"}
        toggle={<ExpandButton expanded={isOpen} onClick={toggle} />}
        isFirstRow={true}
        reference={
          <>
            <CellContentTitle
              aside={aside}
              {...(isMainSection && {
                header: true
              })}
            >
              {title}
            </CellContentTitle>
            {endorsmentsStatus && (
              <StyledExcessesSummary>
                (
                {`${endorsmentsStatus?.applied} Applied / ${endorsmentsStatus?.revoked} Revoked`}
                )
              </StyledExcessesSummary>
            )}
          </>
        }
      />
    </StyledExcessesHeader>
  )
}

const Excesses: React.FC<IExcesses> = ({
  readOnly = false,
  isMainSection = false,
  ...props
}): JSX.Element => {
  const [newExcess, setNewExcess] = React.useState<boolean>(false)
  const [selectedExcess, setSelectedExcess] = React.useState<
    string | undefined
  >()
  const { excess: excessController, quoteBuilder } = usePlatform()

  const excesssList = useQuery(
    ["excesss", props.section && props.section.id],
    async () => {
      if (props.section) {
        const data = await excessController.listExcesses(
          props.section.schemeReferenceID,
          props.section.referenceID
        )

        return data.map((excess) => {
          return {
            label: excess.title,
            value: excess.id
          }
        })
      }
    },
    { enabled: !isMainSection && props.section && !!newExcess }
  )

  const addExcess = useMutation((addExcess: IExcessParams) =>
    quoteBuilder.addExcess(
      addExcess.editSessionID,
      addExcess.componentID,
      addExcess.componentType,
      addExcess.excessID
    )
  )

  const enableExcess = useMutation((params: IExcessParams) =>
    quoteBuilder.enableExcess(
      params.editSessionID,
      params.componentID,
      params.componentType,
      params.excessID
    )
  )

  const disableExcess = useMutation((params: IExcessParams) =>
    quoteBuilder.disableExcess(
      params.editSessionID,
      params.componentID,
      params.componentType,
      params.excessID
    )
  )

  const handleAddExcess = () => {
    // Currently this only handles adding section excesss
    // It is unlikely that we will need to add
    // subsection and coverage but we will need
    // to allow for global excesss at some stage.
    if (props.editSessionID && props.section && selectedExcess) {
      addExcess
        .mutateAsync({
          editSessionID: props.editSessionID,
          componentID: props.section.id,
          componentType: "Section",
          excessID: selectedExcess
        })
        .then((response) => {
          if (typeof props.onChange === "function") {
            props.onChange(response)
            setSelectedExcess(undefined)
            setNewExcess(false)
          } else {
            throw new Error("Unable to update console state")
          }
        })
        .catch((error) => {
          notification.error({
            message: "Unable to add excess, please try again"
          })
        })
    }
  }

  const handleEnableExcess = (excessID: string, owner: IModifierOwner) => {
    if (props.editSessionID) {
      enableExcess
        .mutateAsync({
          editSessionID: props.editSessionID,
          componentID: owner.componentID,
          componentType: owner.componentType,
          excessID: excessID
        })
        .then((response) => {
          if (typeof props.onChange === "function") {
            props.onChange(response)
          } else {
            throw new Error("Unable to update console state")
          }
        })
        .catch((error) => {
          notification.error({
            message: "Unable to enable excess, please try again"
          })
        })
    }
  }

  const handleDisableExcess = (excessID: string, owner: IModifierOwner) => {
    if (props.editSessionID && props.section) {
      disableExcess
        .mutateAsync({
          editSessionID: props.editSessionID,
          componentID: owner.componentID,
          componentType: owner.componentType,
          excessID: excessID
        })
        .then((response) => {
          if (typeof props.onChange === "function") {
            props.onChange(response)
          } else {
            throw new Error("Unable to update console state")
          }
        })
        .catch((error) => {
          notification.error({
            message: "Unable to disable excess, please try again"
          })
        })
    }
  }

  const componentIDCheck = (
    id: string | undefined,
    type: EModiferOwnerComponentType
  ) => {
    switch (type) {
      case "Section":
        return props.section && id === props.section.id
      case "Subsection":
        return (
          props.section &&
          props.section.subsections.some(
            (subsection: ISubsection) => subsection.id === id
          )
        )
      case "Coverage":
        return (
          props.section &&
          props.section.subsections.some((subsection: ISubsection) =>
            subsection.coverages.some(
              (coverage: ICoverage) => coverage.id === id
            )
          )
        )
      default:
        return false
    }
  }

  const filteredExcesses = isMainSection
    ? props.excesses
    : props.excesses.filter((excess) =>
        excess.modifierOwners.some(
          (owner) =>
            owner.componentID &&
            componentIDCheck(owner.componentID, owner.componentType)
        )
      )

  const isExcessRevoked = (excess: IExcess) =>
    excess.modifierOwners.every((owner) => !owner.enabled)

  const isExcessApplied = (excess: IExcess) =>
    excess.modifierOwners.some((owner) => owner.enabled)

  const excessesStatus = {
    revoked: filteredExcesses.reduce(
      (acc, excess) => acc + (isExcessRevoked(excess) ? 1 : 0),
      0
    ),
    applied: filteredExcesses.reduce(
      (acc, excess) => acc + (isExcessApplied(excess) ? 1 : 0),
      0
    )
  }

  return (
    <StyledExcesses $isMainSection={isMainSection}>
      <Collapse
        startExpanded={props.startExpanded}
        collapsedPanel={
          <ExcessesHeader
            title="Excesses"
            isMainSection={isMainSection}
            endorsmentsStatus={excessesStatus}
          />
        }
        expandedPanel={
          <ExcessesHeader
            title="Excesses"
            isOpen
            isMainSection={isMainSection}
            endorsmentsStatus={excessesStatus}
          />
        }
      >
        <Row
          reference={
            <CellContentTitle>Excess Reference / Name</CellContentTitle>
          }
          values={[
            null,
            <CellContentTitle>Status</CellContentTitle>,
            <CellContentTitle>Type</CellContentTitle>,
            <CellContentTitle>Amount</CellContentTitle>,
            null
          ]}
        />
        {filteredExcesses &&
          filteredExcesses.map((excess) => {
            const [modifierOwner] = excess.modifierOwners
            let excessStatus

            if (
              excess.modifierOwners.length === 1 &&
              modifierOwner.source === "Manual" &&
              modifierOwner?.enabled
            ) {
              excessStatus = {
                status: "Manually applied",
                controls: (
                  <>
                    <DeleteButton
                      onClick={() =>
                        handleDisableExcess(excess.id, modifierOwner)
                      }
                    />
                  </>
                )
              }
            } else if (
              excess.modifierOwners.length >= 1 &&
              modifierOwner?.enabled
            ) {
              excessStatus = {
                status: "Applied",
                controls: (
                  <>
                    <RemoveButton
                      onClick={() =>
                        handleDisableExcess(excess.id, modifierOwner)
                      }
                    />
                  </>
                )
              }
            } else {
              excessStatus = {
                status: "Manually revoked",
                controls: (
                  <>
                    <AddButton
                      onClick={() =>
                        handleEnableExcess(excess.id, modifierOwner)
                      }
                    />
                  </>
                )
              }
            }

            return (
              <Row
                key={excess.id}
                reference={
                  <CellContentText strikethrough={!modifierOwner?.enabled}>
                    {excess.title}
                  </CellContentText>
                }
                values={[
                  null,
                  <CellContentText>{excessStatus.status}</CellContentText>,
                  <CellContentText strikethrough={!modifierOwner?.enabled}>
                    {excess.modifierType}
                  </CellContentText>,
                  excess.amount ? (
                    <CellContentMoney textAlignment="left" {...excess.amount} />
                  ) : (
                    <CellContentText>-</CellContentText>
                  ),
                  null
                ]}
                control={!readOnly && !isMainSection && excessStatus.controls}
              />
            )
          })}

        {!newExcess && !isMainSection && !readOnly && (
          <Row
            reference={
              <CellContentLinkButton onClick={() => setNewExcess(true)}>
                Add Excess
              </CellContentLinkButton>
            }
          />
        )}

        {newExcess && !readOnly && (
          <Row
            updating={addExcess.isLoading}
            reference={
              <CellContentSelect
                loading={excesssList.isLoading}
                name="excess"
                defaultValue={selectedExcess}
                options={excesssList.data || []}
                onChange={(e) => {
                  setSelectedExcess(e.target.value?.toString())
                }}
              />
            }
            values={[
              null,
              null,
              <CellContentText>-</CellContentText>,
              null,
              null
            ]}
            control={
              <>
                <SaveButton onClick={handleAddExcess} />
                <DeleteButton onClick={() => setNewExcess(false)} />
              </>
            }
          />
        )}
      </Collapse>
    </StyledExcesses>
  )
}

interface IExcessesHeader {
  title: string
  aside?: string
  isOpen?: boolean
  toggle?: () => boolean
  isMainSection: boolean
  endorsmentsStatus?: { revoked: number; applied: number }
}

export interface IExcesses {
  editSessionID?: string
  isMainSection?: boolean
  excesses: IExcess[]
  addExcess?: (excessToAdd: IExcess) => void
  section?: SectionTypes
  readOnly?: boolean
  onChange?: (response: TPlatformResponse) => void
  startExpanded?: boolean
}

export default Excesses
