import React from "react"
import { Select, Space } from "antd"
import { useHistory } from "react-router-dom"
import { capitalizeFirstLetter } from "utils/pocketknife/capitalize-first-letter"
import { IConfigurationContextQuote } from "contexts/configuration/context"
import { QuoteState } from "platform-client/types/quote"
import { QuoteStateLabels } from "containers/quotes/constants"

const { Option } = Select

export const FilterComponent: React.FunctionComponent<IFilterComponent> = (
  props
) => {
  const history = useHistory()

  return (
    <Space style={{ marginLeft: 16 }}>
      <Select
        placeholder="Referred, Declined, Purchased..."
        defaultValue={
          props.quoteStatus
            ? capitalizeFirstLetter(props.quoteStatus)
            : props.quotesType?.key
        }
        allowClear
        style={{ width: 160 }}
        onChange={(option: QuoteState | string) => {
          if (option) {
            history.push(`/quotes/${option.toLocaleLowerCase()}`)
            props.setQuotesTypes({
              key: option,
              value: option,
              label: option
            })
          } else {
            history.push(`/quotes`)
            props.setQuotesTypes(undefined)
          }
        }}
      >
        {props.quoteConfiguration &&
          (Object.keys(QuoteStateLabels) as QuoteState[]).map((key) => {
            const stateTitle =
              QuoteStateLabels[key].label.charAt(0).toUpperCase() +
              QuoteStateLabels[key].label.slice(1)

            if (
              key.toLocaleLowerCase() === props.quoteStatus &&
              !props.quotesType
            ) {
              props.setQuotesTypes({ key, value: key, label: stateTitle })
            }
            return (
              <Option key={key} value={key}>
                {stateTitle}
              </Option>
            )
            return false
          })}
      </Select>
    </Space>
  )
}
interface IFilterComponent {
  quotesType: { value: string; label: string; key: string } | undefined
  quoteStatus: string | undefined
  setQuotesTypes: (
    value: { value: string; label: string; key: string } | undefined
  ) => void
  quoteConfiguration: IConfigurationContextQuote
}
