import React from "react"
import styled from "styled-components"
import { Layout, Alert } from "antd"
import { useNotifications } from "contexts/notification/hooks/use-notification"
import { TErrorState } from "models/errors"

const { Content } = Layout

const StyledError = styled(Alert)`
  margin: 0 0 20px 0;
`
const centredContent = `display: flex; align-content: center; justify-content: center;`

const StyledContent = styled(Content)`
  ${(props: ILayoutContent) =>
    props.withMargin ? "margin: 24px 0px;" : "padding: 24px 0;"}
  background-color: #fff;
  ${(props: ILayoutContent) =>
    props.alignDirection === "center" ? centredContent : ""}
  ${(props: ILayoutContent) =>
    props.transparent && `background-color: transparent`}
`
export const LayoutContent: React.FunctionComponent<ILayoutContent> = (
  props
) => {
  const { notifications } = useNotifications()

  return (
    <StyledContent {...props}>
      {props?.error?.errorNumber && notifications && (
        <StyledError
          banner
          message={notifications.messages[props.error.errorNumber]?.message}
          type={notifications.messages[props.error.errorNumber]?.type}
          showIcon
        />
      )}
      {props.children}
    </StyledContent>
  )
}

interface ILayoutContent {
  children: React.ReactNode
  error?: TErrorState
  withMargin?: boolean
  transparent?: boolean
  alignDirection?: string
}
