import React, { useState, useMemo } from "react"
import { Row, Col, Table, Divider, notification } from "antd"
import {
  SearchCompanies,
  FilterComponent
} from "containers/invoices/views/AllInvoices/components"
import {
  filterStates,
  INVOICE_COLUMNS,
  state
} from "containers/invoices/views/AllInvoices/constants"
import { ISetInvoiceStatusParams } from "platform-client/types/invoice"
import { useMutation, useQuery } from "react-query"
import usePlatform from "contexts/platform/use-platform"
import { ITable } from "models/common/ITable"

export const InvoiceTable: React.FC<IInvoiceTable> = (props) => {
  const [invoicesStatus, setInvoicesStatus] = useState<string | undefined>(
    undefined
  )

  const handleInvoiceStatusChange = (invoiceId: string, status: string) => {
    setInvoiceStatus({ invoiceId, status })
  }

  const searchParams = new URLSearchParams(props.search)

  const { invoice, company } = usePlatform()
  const {
    isLoading: isGetRequestLoading,
    data,
    refetch
  } = useQuery(
    ["invoices", props.search],
    async () =>
      invoice.listInvoices({
        status: searchParams.get("status"),
        companyId: searchParams.get("companyId")
      }),
    {
      onError: () =>
        notification.error({
          message: "Unable to list invoices, please try again"
        })
    }
  )

  const { data: companies } = useQuery(["companies"], async () => {
    return company.listCompanies()
  })

  const { isLoading: isPostRequestLoading, mutate: setInvoiceStatus } =
    useMutation(
      (params: ISetInvoiceStatusParams) =>
        invoice.setInvoiceStatus(params.invoiceId, params.status),
      {
        onSuccess: () => refetch(),
        onError: () =>
          notification.error({
            message: "Unable to update invoice status, please try again"
          })
      }
    )

  const isLoading = useMemo(
    () =>
      [isGetRequestLoading, isPostRequestLoading].some((loading) => !!loading),
    [isGetRequestLoading, isPostRequestLoading]
  )

  return (
    <>
      {props.showFilter && (
        <Row justify="end">
          <Col style={{ padding: 16 }}>
            Filter by status:
            <FilterComponent
              invoicesStatus={invoicesStatus}
              setInvoicesStatus={setInvoicesStatus}
              states={filterStates}
            />
            <Divider style={{ height: 20 }} type="vertical" />
            Search Companies:
            <SearchCompanies
              options={companies as { name: string; id: string }[]}
            />
            {/* <SearchComponent
            setSearchValue={setSearchValue}
            searchValue={searchValue}
          /> */}
          </Col>
        </Row>
      )}

      <Table
        loading={isLoading}
        columns={INVOICE_COLUMNS(state, {
          handleInvoiceStatusChange
        })}
        scroll={{ x: "max-content" }}
        dataSource={data}
        pagination={{ style: { marginRight: 20 } }}
      />
    </>
  )
}

export type IInvoiceTable = ITable
