import { Table } from "antd"
import { Link } from "react-router-dom"
import Column from "rc-table/lib/sugar/Column"

import { IMoney, IPolicyholder } from "platform-client/types"
import { IOpportunityTable } from "models/opportunties/opportunityTable"
import { DateDisplay } from "components/display/DateDisplay"
import { MoneyDisplay } from "components/display/MoneyDisplay"

export const createPolicyHolderName = (
  legalName: string | null,
  referenceID: string | null
): string | null => {
  if (legalName && !referenceID) return legalName
  if (!legalName || !referenceID) return null
  return `${legalName} (${referenceID})`
}

type IOpportunitiesTableProps = {
  isLoading: boolean
  opportunityData: IOpportunityTable[] | undefined
  setCurrentPage?: (page: number, pageSize?: number | undefined) => void
  currentPage?: number
}
export const OpportunitiesTable: React.FC<IOpportunitiesTableProps> = ({
  isLoading,
  opportunityData,
  setCurrentPage,
  currentPage
}) => {
  return (
    <Table
      scroll={{ x: "max-content" }}
      loading={isLoading}
      dataSource={opportunityData}
      rowKey="id"
      pagination={{
        onChange: setCurrentPage,
        current: currentPage,
        defaultPageSize: 10,
        hideOnSinglePage: true,
        position: ["bottomCenter"],
        size: "small"
      }}
    >
      <Column
        title="Created"
        dataIndex="localDateCreated"
        width="18ch"
        render={(date: string) => <DateDisplay date={date} />}
      />

      <Column
        title="Reference"
        dataIndex="referenceID"
        width="24ch"
        render={(referenceID: string | null, row: IOpportunityTable) => (
          <Link to={`/opportunity/${row.id}`}>{referenceID}</Link>
        )}
      />
      <Column title="Product" dataIndex="productName" />
      <Column
        title="Sub-broker"
        dataIndex="broker"
        render={(value) => (!!value && !!value.name ? value.name : "")}
      />
      <Column
        title="Policy Holder"
        dataIndex="policyHolder"
        render={(value: IPolicyholder) =>
          createPolicyHolderName(value.legalName, value.referenceID) || ""
        }
      />
      <Column
        title="Nominated quote"
        dataIndex="nominatedQuoteReferenceID"
        render={(value: string | null, row: IOpportunityTable) => {
          return value ? (
            <Link
              title={`View quote ${row.nominatedQuoteReferenceID}`}
              to={`/quote/${row.nominatedQuoteID}`}
            >
              {value}
            </Link>
          ) : (
            ""
          )
        }}
      />
      <Column
        title="Premium"
        dataIndex="nominatedQuoteGross"
        align="right"
        render={(value: IMoney | null) => (
          <MoneyDisplay value={value} isMajorCurrency />
        )}
      />
      <Column
        title="Policy"
        dataIndex="policyID"
        render={(policyID: string | null, row: IOpportunityTable) => {
          return policyID ? (
            <Link
              title={`View policy ${row.policyReferenceID}`}
              to={`/policy/${policyID}`}
            >
              {row.policyReferenceID}
            </Link>
          ) : (
            ""
          )
        }}
      />
      <Column
        key="actions"
        align="right"
        width="auto"
        render={(value, row: IOpportunityTable) => (
          <Link to={`/opportunity/${row.id}`} className="ant-btn ant-btn-sm">
            View {`${row.referenceID}`}
          </Link>
        )}
      />
    </Table>
  )
}
