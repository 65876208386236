import { useState, useEffect } from "react"
import { TEventType } from "models/underwriting"
import { produce } from "immer"
import { TAddFee, TPlatformResponse } from "contexts/platform/client"
import usePlatform from "contexts/platform/use-platform"
import { useMutation } from "react-query"
import { notification } from "antd"
import { useOrgSettings } from "contexts/organisation/hooks/use-org-settings"
import { IFinanceFee } from "platform-client/types"

const useFee: UseFee = (fee, editSessionID, onChange) => {
  const [_fee, setFee] = useState<IFinanceFee>(fee)
  const [updating, setUpdating] = useState<boolean>(false)
  const { quoteBuilder } = usePlatform()
  const { orgSettings } = useOrgSettings()

  const updateFee = useMutation((updateFee: IUpdateFee) =>
    quoteBuilder.updateFee(updateFee.editSessionID, updateFee.fee.id, {
      referenceID: updateFee.fee.referenceID,
      taxRuleSetReferenceID: updateFee.fee.tax.taxRuleReferenceID,
      net: updateFee.fee.net,
      name: updateFee.fee.name
    })
  )

  const addFee = useMutation((addFee: IAddFee) =>
    quoteBuilder.addFee(addFee.editSessionID, addFee.fee)
  )

  const removeFee = useMutation((removeCoverage: IRemoveFee) =>
    quoteBuilder.deleteFee(removeCoverage.editSessionID, removeCoverage.feeId)
  )

  /**
   * This is required to update the state
   * when we pass down the session from above.
   */
  useEffect(() => {
    if (fee) {
      setFee(fee)
    }
  }, [fee])

  const addNewFee = async () => {
    if (editSessionID) {
      try {
        setUpdating(true)
        const response = await addFee.mutateAsync({
          editSessionID,
          fee: {
            referenceID: _fee.referenceID,
            taxRuleSetReferenceID: _fee.tax.taxRuleReferenceID,
            net: _fee.net,
            name: _fee.name
          }
        })

        if (response) {
          if (typeof onChange === "function") {
            onChange(response)
          }
        }
      } catch (error) {
        notification.error({
          message: "Unable to add fee. Please try again."
        })
      }
      setUpdating(false)
    }
  }

  const handleUpdate = (event: TEventType) => {
    const path = event.target.name.split(".")
    const name = path[0] as keyof IFinanceFee

    setFee(
      produce((draft: IFinanceFee) => {
        draft.id = path[1]
        switch (name) {
          case "net":
            draft.net = {
              value: event.target.value as number,
              currencyCode:
                draft.net?.currencyCode || orgSettings?.currencyCode || "GBP"
            }
            break
          default:
            draft = { ...draft, ...{ [name]: event.target.value } }
            break
        }

        return draft
      })
    )
  }

  const syncFees = async () => {
    if (editSessionID) {
      try {
        setUpdating(true)

        const response = await updateFee.mutateAsync({
          editSessionID,
          fee: {
            referenceID: _fee.referenceID,
            net: _fee.net,
            name: _fee.name,
            gross: _fee.gross,
            id: _fee.id,
            tax: _fee.tax,
            order: _fee.order
          }
        })

        if (response) {
          if (typeof onChange === "function") {
            onChange(response)
          }
        }
      } catch (error: unknown) {
        notification.error({
          message: "Unable to update fee. Please try again."
        })
      }
      setUpdating(false)
    }
  }

  const handleRemove = async () => {
    if (editSessionID) {
      try {
        setUpdating(true)

        const response = await removeFee.mutateAsync({
          editSessionID,
          feeId: _fee.id
        })

        if (response) {
          if (typeof onChange === "function") {
            onChange(response)
          }
        }
      } catch (error) {
        notification.error({
          message: "Unable to remove fee. Please try again."
        })
      }
      setUpdating(false)
    }
  }

  return {
    _fee,
    updating,
    syncFees,
    handleUpdate,
    handleRemove,
    addNewFee
  }
}

interface IUpdateFee {
  editSessionID: string
  fee: IFinanceFee
}

interface IAddFee {
  editSessionID: string
  fee: TAddFee
}

interface IRemoveFee {
  editSessionID: string
  feeId: string
}

type UseFee = (
  fee: IFinanceFee,
  editSessionID?: string,
  onChange?: (data: TPlatformResponse) => void
) => {
  _fee: IFinanceFee
  updating: boolean
  syncFees: () => void
  handleUpdate: (event: TEventType) => void
  handleRemove: () => void
  addNewFee: () => void
}

export default useFee
