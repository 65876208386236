import { Modal, Form, Input, Radio, message } from "antd"
import React from "react"
import { isEmpty, isNil } from "ramda"
import useOpus from "contexts/opus/hooks/use-opus"
import { useQuery } from "react-query"
import usePlatform from "contexts/platform/use-platform"
import { OptionType } from "components/CheckboxList"
import { IUser } from "contexts/opus/context"

export const UserModal: React.FunctionComponent<IUserModalProps> = (props) => {
  const [form] = Form.useForm()
  const { userClient } = useOpus()

  const platform = usePlatform()

  const allOrgRoles = useQuery(
    ["organisationManagement", "listOrgRoles", platform.token],
    () => platform.organizationManagement.listOrgRoles()
  )

  const orgRoles = allOrgRoles.data?.map(
    (item) =>
      ({
        label: item.name,
        value: item.id
      } as OptionType)
  )

  if (props.isEditing && props.isModalVisible && props.user) {
    form.setFieldsValue(props.user)

    if (props.user.roles?.length > 0) {
      form.setFields([
        {
          name: "role",
          value: props.user.roles[0]?.id
        }
      ])
    }
  }

  return (
    <Modal
      visible={props.isModalVisible}
      onCancel={(e) => {
        props.setIsModalVisible(false)
        form.resetFields()
      }}
      title={props.isEditing ? "Edit User" : "Add User"}
      centered={true}
      okText={props.isEditing ? "Save Changes" : "Add User"}
      destroyOnClose={true}
      onOk={async () =>
        form.validateFields().then(async (values) => {
          if (values) {
            let data = values

            Object.keys(data).forEach((key: string) => {
              const value = data[key]
              if (typeof value === "string") {
                data = { ...data, ...{ [key]: value.trim() } }
              }
            })

            const userRoles = [data.role]

            if (props.isEditing && props.user) {
              userClient
                .editUser(props.user.id, {
                  ...data,
                  userRoles
                })
                .then((response) => {
                  props.refetchUsers()
                  props.setIsModalVisible(false)
                  form.resetFields()
                })
                .catch((error) => {
                  const validationErrors =
                    error?.response?.data?.validationErrors
                  if (!isEmpty(validationErrors) && !isNil(validationErrors)) {
                    form.setFields([
                      ...validationErrors.map(
                        (validationElement: {
                          propertyIndentifier: string
                          message: string
                        }) => ({
                          name: validationElement.propertyIndentifier.toLowerCase(),
                          errors: [validationElement.message]
                        })
                      )
                    ])
                  } else {
                    message.error("Something has gone wrong. Please try again.")
                  }
                })
            } else if (data.authID !== "") {
              userClient
                .addUser({
                  ...data,
                  userRoles
                })
                .then((response) => {
                  props.refetchUsers()
                  props.setIsModalVisible(false)
                  form.resetFields()
                })
                .catch((error) => {
                  const validationErrors =
                    error?.response?.data?.validationErrors
                  if (!isEmpty(validationErrors) && !isNil(validationErrors)) {
                    form.setFields([
                      ...validationErrors.map(
                        (validationElement: {
                          propertyIndentifier: string
                          message: string
                        }) => ({
                          name: validationElement.propertyIndentifier.toLowerCase(),
                          errors: [validationElement.message]
                        })
                      )
                    ])
                  } else {
                    message.error("Something has gone wrong. Please try again.")
                  }
                })
            } else {
              userClient
                .setUserInvite({
                  ...data,
                  userRoles
                })
                .then((response) => {
                  props.refetchUsers()
                  props.setIsModalVisible(false)
                  form.resetFields()
                })
                .catch((error) => {
                  const validationErrors =
                    error?.response?.data?.validationErrors
                  if (!isEmpty(validationErrors) && !isNil(validationErrors)) {
                    form.setFields([
                      ...validationErrors.map(
                        (validationElement: {
                          propertyIndentifier: string
                          message: string
                        }) => ({
                          name: validationElement.propertyIndentifier.toLowerCase(),
                          errors: [validationElement.message]
                        })
                      )
                    ])
                  } else {
                    message.error("Something has gone wrong. Please try again.")
                  }
                })
            }
          }
        })
      }
    >
      <Form form={form} name="addUser" layout="horizontal">
        <Form.Item
          style={{ display: "block", marginBottom: 10 }}
          label={"Role"}
          name="role"
          rules={[
            {
              required: true,
              message: "Please select role"
            }
          ]}
        >
          <Radio.Group options={orgRoles} />
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item
            label={"First name"}
            name="firstName"
            style={{
              display: "inline-block",
              width: "calc(50% - 16px)",
              marginBottom: 10
            }}
            rules={[
              {
                required: true,
                message: "Please input first name"
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={"Last name"}
            name="lastName"
            style={{
              display: "inline-block",
              width: "50%",
              margin: "0 0px 0 16px",
              marginBottom: 10
            }}
            rules={[
              {
                required: true,
                message: "Please input last name"
              }
            ]}
          >
            <Input />
          </Form.Item>
        </Form.Item>
        <Form.Item
          style={{ marginBottom: 10 }}
          labelCol={{ span: 24 }}
          name="username"
          label={"Email"}
          rules={[
            {
              required: true,
              message: "Please input email"
            },
            { type: "email", message: "Please input correct email address" }
          ]}
        >
          <Input readOnly={props.isEditing} />
        </Form.Item>

        <Form.Item
          style={{ marginBottom: 10 }}
          labelCol={{ span: 24 }}
          name="authID"
          label={"Auth ID"}
          rules={[]}
        >
          <Input readOnly={props.isEditing} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export interface IUserModalProps {
  isModalVisible?: boolean
  setIsModalVisible: (value: boolean) => void
  isEditing: boolean
  user?: IUser
  refetchUsers: () => void
}
