import React from "react"
import { ColumnsType } from "antd/lib/table"
import formatDate from "dateformat"
import { IPolicyDocument } from "platform-client/types/policy"

export const POLICY_DOCUMENTS_COLUMNS: ColumnsType<IPolicyDocument> = [
  {
    title: "File Name",
    key: "fileName",
    render: (row: {
      fileName: string
      documentUrl: string
    }): React.ReactNode => {
      return (
        <a href={row.documentUrl} target="_blank" rel="noreferrer">
          {row.fileName}
        </a>
      )
    }
  },
  {
    title: "Created",
    dataIndex: "localDateCreated",
    key: "localDateCreated",
    render: (date: string): string =>
      formatDate(new Date(date), "dd/mm/yyyy h:MMtt")
  }
]
