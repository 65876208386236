import { mergeDeepRight } from "ramda"
import { useState } from "react"
import { useAsyncEffect } from "use-async-effect"
import { getActiveHostname } from "utils/api/apiUrl"
import { useAsyncError } from "utils/hooks/use-catch-async-error"
import ConfigurationClient from "../client"
import { defaultConfig, IConfigurationContext } from "../context"

const getHostname = (): string => {
  return window.location.hostname
}

export const useGetApplicationConfiguration =
  (): IUseGetApplicationConfiguration => {
    const [applicationConfiguration, setApplicationConfiguration] =
      useState<IConfigurationContext>(defaultConfig)

    const [applicationConfigurationLoaded, setApplicationConfigurationLoaded] =
      useState<boolean>(false)

    const throwError = useAsyncError()

    useAsyncEffect(async () => {
      const hostname = getHostname()
      try {
        if (applicationConfigurationLoaded === false) {
          const _applicationConfiguration =
            await ConfigurationClient.getConfiguration(hostname)
          if (_applicationConfiguration) {
            setApplicationConfiguration(
              mergeDeepRight(
                defaultConfig,
                _applicationConfiguration
              ) as IConfigurationContext
            )
            setApplicationConfigurationLoaded(true)
          } else {
            throwError(new Error("No configuration returned"))
          }
        }
      } catch {
        // If the config isn't avialable for normal hostname,
        // try loading using smart hostname
        const smartHostname = getActiveHostname(hostname).activeHostname

        if (smartHostname === hostname)
          throwError(new Error("Unable to load configuration"))

        try {
          if (applicationConfigurationLoaded === false) {
            const _applicationConfiguration =
              await ConfigurationClient.getConfiguration(smartHostname)
            if (_applicationConfiguration) {
              setApplicationConfiguration(
                mergeDeepRight(
                  defaultConfig,
                  _applicationConfiguration
                ) as IConfigurationContext
              )
              setApplicationConfigurationLoaded(true)
            } else {
              throwError(new Error("No configuration returned"))
            }
          }
        } catch {
          throwError(new Error("Unable to load configuration"))
        }
      }
    }, [applicationConfigurationLoaded])

    return {
      applicationConfiguration,
      applicationConfigurationLoaded
    }
  }

interface IUseGetApplicationConfiguration {
  applicationConfiguration: IConfigurationContext
  applicationConfigurationLoaded: boolean
}
