import React from "react"
import styled from "styled-components"
import { Layout, Alert } from "antd"
import { useNotifications } from "contexts/notification/hooks/use-notification"
import { TErrorState } from "models/errors"
import CSS from "csstype"

const { Content, Sider } = Layout

const StyledError = styled(Alert)`
  margin: 0 0 20px 0;
`

const StyledContentWrapper = styled(Content)`
  padding: 24px;
`

const StyledContent = styled.div<{ transparent?: boolean }>`
  position: relative;
  ${(props) =>
    props.transparent === true
      ? "background: transparent"
      : "background: #fff"};
`

const StyledSider = styled(Sider)`
  border-left: 1px solid var(--borders);
`

export const LayoutPageContent: React.FunctionComponent<ILayoutPageContent> = (
  props
) => {
  const { notifications } = useNotifications()

  const [collapsed, setCollapsed] = React.useState<boolean>(false)

  const toggleMenu = () => {
    setCollapsed(!collapsed)
  }

  return (
    <Layout {...props}>
      <StyledContentWrapper>
        {props?.error?.errorNumber && notifications && (
          <StyledError
            banner
            message={notifications.messages[props.error.errorNumber]?.message}
            type={notifications.messages[props.error.errorNumber]?.type}
            showIcon
          />
        )}
        <StyledContent transparent={props.transparent}>
          {props.children}
        </StyledContent>
      </StyledContentWrapper>
      {props.siderContent && (
        <StyledSider
          theme="light"
          collapsible
          collapsed={collapsed}
          onCollapse={toggleMenu}
          reverseArrow={true}
          width={350}
          collapsedWidth={60}
        >
          {collapsed ? null : props.siderContent}
        </StyledSider>
      )}
    </Layout>
  )
}

export interface ILayoutPageContent {
  style?: CSS.Properties
  error?: TErrorState
  transparent?: boolean
  alignDirection?: string
  siderContent?: React.ReactNode
}
