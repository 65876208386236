import { useState } from "react"
import { useAsyncEffect } from "use-async-effect"
import { IUser } from "contexts/opus/context"
import { TErrorState } from "models/errors"
import {
  errorValidationMessage,
  IErrorState
} from "utils/pocketknife/error-validation-message"
import useOpus from "contexts/opus/hooks/use-opus"

export const useGetUsers = (): IUseGetUsers => {
  const [users, setUsers] = useState<IUser[] | []>([])
  const [isError, setIsError] = useState<TErrorState>(undefined)
  const [usersLoaded, setUsersLoaded] = useState<boolean>(false)
  const { userClient } = useOpus()

  useAsyncEffect(
    async (isMounted) => {
      try {
        if (usersLoaded === false) {
          const _users = await userClient.getUsers()
          if (isMounted() && _users) {
            setUsers(_users)
            setIsError(undefined)
            setUsersLoaded(true)
          }
        }
      } catch (error) {
        setUsers([])
        setIsError({
          errorNumber: error?.response?.status,
          validationMessage: errorValidationMessage(error)
        })
        setUsersLoaded(false)
      }
    },
    [usersLoaded]
  )

  return {
    users,
    isError,
    isUsersLoaded: usersLoaded
  }
}

interface IUseGetUsers extends IErrorState {
  users: IUser[] | []
  isUsersLoaded: boolean
}

export enum EUserStatus {
  Disabled = "Disabled",
  Inactive = "Inactive",
  Invited = "Stub"
}

export enum EUserType {
  Insurer = "Insurer",
  Distributor = "Distributor"
}
