import { useCallback, useState } from "react"
import { TErrorState } from "models/errors"
import {
  errorValidationMessage,
  IErrorState
} from "utils/pocketknife/error-validation-message"
import { IPolicyPaymentMethod } from "models/policies/policyPaymentMethod"
import useOpus from "contexts/opus/hooks/use-opus"
import useAsyncEffect from "use-async-effect"

export const useGetPaymentMethod = (
  policyID: string | undefined
): IPaymentMethod => {
  const [paymentMethod, setPaymentMethod] = useState<
    IPolicyPaymentMethod | undefined
  >(undefined)
  const [isError, setIsError] = useState<TErrorState>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { policyClient } = useOpus()

  const getPaymentPlan = useCallback(async () => {
    try {
      if (policyID) {
        const _paymentMethod = await policyClient.getPolicyPaymentMethod(
          policyID
        )

        if (_paymentMethod) {
          setPaymentMethod(_paymentMethod)
          setIsError(undefined)
        }
      }
      setIsLoading(false)
    } catch (error) {
      setPaymentMethod(undefined)
      setIsError({
        errorNumber: error?.response?.status,
        validationMessage: errorValidationMessage(error)
      })
      setIsLoading(false)
    }
  }, [policyID, policyClient])

  useAsyncEffect(() => {
    if (policyID && paymentMethod === undefined) {
      setIsLoading(true)
      getPaymentPlan()
    }
  }, [getPaymentPlan, policyID, paymentMethod])

  return {
    data: paymentMethod,
    isError,
    isLoading
  }
}

interface IPaymentMethod extends IErrorState {
  data: IPolicyPaymentMethod | undefined
  isLoading: boolean
}

export default useGetPaymentMethod
