import React, { useState } from "react"
import styled from "styled-components"
import { Card, Divider, Typography, Checkbox, Alert, Skeleton } from "antd"
import { TPolicyRenewals } from "models/policies/policyRenewals"
import { IConfigurationContextPolicyDetails } from "contexts/configuration/context"

const PolicyRenewalBox = styled.div`
  position: relative;
  padding-left: 65px;
`

const PolicyStyledSwitch = styled(Checkbox)`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`

const { Title } = Typography

export const PolicyRenewals: React.FunctionComponent<IPolicyRenewals> = ({
  renewalsConfiguration,
  isSending,
  isError,
  sendRequest,
  values,
  policyPlanReferenceID,
  renewalsSettings = [],
  isLoading,
  setPolicyRenewalWithdraw,
  isPolicyRenewalsWithdrawed
}) => {
  const [renewalValue, setRenewalValue] = useState<{
    requiresReview: boolean
    allowAutoBind: boolean
    doNotRenew: boolean
  }>(values)

  const [autoRenewalSettings = { supportsAutoBind: false }] =
    renewalsSettings &&
    renewalsSettings.filter(
      (plan) => plan.referenceID === policyPlanReferenceID
    )

  const [isWithdrawTouched, setIsWithdrawTouched] = useState<boolean>(false)

  const autoBindSettings = {
    isDisabled:
      isSending || renewalValue.doNotRenew || isPolicyRenewalsWithdrawed,
    isMessage: renewalValue.doNotRenew || isPolicyRenewalsWithdrawed
  }

  const withdrawSettings = {
    isDisabled: isSending || renewalValue.doNotRenew,
    isMessage: isPolicyRenewalsWithdrawed && isWithdrawTouched
  }

  const manualSettings = {
    isDisabled:
      isSending || renewalValue.doNotRenew || isPolicyRenewalsWithdrawed,
    isMessage: renewalValue.doNotRenew || isPolicyRenewalsWithdrawed
  }

  const intentionSettings = {
    isDisabled: isSending || isPolicyRenewalsWithdrawed,
    isMessage: isPolicyRenewalsWithdrawed
  }

  return (
    <Card title={"Options"}>
      <Skeleton loading={isLoading}>
        {isError && (
          <>
            <PolicyRenewalBox>
              <Alert
                message={
                  renewalsConfiguration?.errorRenewal ||
                  "Something went wrong. please try again later"
                }
                type="error"
              />
            </PolicyRenewalBox>
            <Divider />
          </>
        )}
        {autoRenewalSettings.supportsAutoBind && (
          <PolicyRenewalBox>
            <PolicyStyledSwitch
              checked={renewalValue.allowAutoBind}
              disabled={autoBindSettings.isDisabled}
              onChange={(event) => {
                setRenewalValue({
                  ...renewalValue,
                  allowAutoBind: event.target.checked
                })
                sendRequest({
                  ...renewalValue,
                  allowAutoBind: event.target.checked
                })
              }}
            />
            <Title level={5}>
              {renewalsConfiguration?.autoRenewal?.title || "Auto-renew policy"}
            </Title>
            {renewalsConfiguration?.autoRenewal?.content ||
              "When set to true, this policies renewal quote will automatically bind and payment collected when the current policy expires."}
            {autoBindSettings.isMessage && (
              <Alert
                style={{ marginTop: 10 }}
                message={
                  renewalValue.doNotRenew
                    ? renewalsConfiguration?.autoRenewal?.disabledLabel
                    : renewalsConfiguration?.autoRenewal?.withdrawnLabel
                }
                type="info"
              />
            )}
          </PolicyRenewalBox>
        )}
        {autoRenewalSettings.supportsAutoBind && <Divider />}

        <PolicyRenewalBox>
          <PolicyStyledSwitch
            checked={renewalValue.requiresReview}
            disabled={manualSettings.isDisabled}
            onChange={(event) => {
              setRenewalValue({
                ...renewalValue,
                requiresReview: event.target.checked
              })
              sendRequest({
                ...renewalValue,
                requiresReview: event.target.checked
              })
            }}
          />
          <Title level={5}>
            {renewalsConfiguration?.manualRenewal?.title ||
              "Manual Renewal Review"}
          </Title>
          {renewalsConfiguration?.manualRenewal?.content ||
            "Marks the renewal quote for this policy as requiring manual review before being sent/made available to customer."}
          {manualSettings.isMessage && (
            <Alert
              style={{ marginTop: 10 }}
              message={
                renewalValue.doNotRenew
                  ? renewalsConfiguration?.manualRenewal?.disabledLabel
                  : renewalsConfiguration?.manualRenewal?.withdrawnLabel
              }
              type="info"
            />
          )}
        </PolicyRenewalBox>
        <Divider />
        <PolicyRenewalBox>
          <PolicyStyledSwitch
            checked={isPolicyRenewalsWithdrawed}
            disabled={withdrawSettings.isDisabled}
            onChange={(event) => {
              if (isPolicyRenewalsWithdrawed) {
                event.preventDefault()
                setIsWithdrawTouched(true)
              } else {
                setPolicyRenewalWithdraw()
              }
            }}
          />
          <Title level={5}>
            {renewalsConfiguration?.withdrawRenewal?.title || "Withdraw"}
          </Title>
          {renewalsConfiguration?.withdrawRenewal?.content ||
            "Withdrawing a policy"}

          {withdrawSettings.isMessage && (
            <Alert
              style={{ marginTop: 10 }}
              message={
                renewalsConfiguration?.withdrawRenewal?.withdrawnLabel ||
                "To undo this withdrawal, please contact us directly"
              }
              type="info"
            />
          )}
        </PolicyRenewalBox>

        <Divider />
        <PolicyRenewalBox>
          <PolicyStyledSwitch
            checked={renewalValue.doNotRenew}
            disabled={intentionSettings.isDisabled}
            onChange={(event) => {
              setRenewalValue({
                doNotRenew: event.target.checked,
                requiresReview: false,
                allowAutoBind: false
              })
              sendRequest({
                doNotRenew: event.target.checked,
                requiresReview: false,
                allowAutoBind: false
              })
            }}
          />
          <Title level={5}>
            {renewalsConfiguration?.intentionRenewal?.title ||
              "No intention to renew"}
          </Title>
          {renewalsConfiguration?.intentionRenewal?.content ||
            `Tags the policy indicating the customer has no intention to renew. A renewal quote will not be assigned, and the customer will not receive any renewal pricing or follow-up notifications.`}
          {intentionSettings.isMessage && (
            <Alert
              style={{ marginTop: 10 }}
              message={
                renewalsConfiguration?.autoRenewal?.withdrawnLabel ||
                "Renewals have been withdrawn"
              }
              type="info"
            />
          )}
        </PolicyRenewalBox>
      </Skeleton>
    </Card>
  )
}
export interface IPolicyRenewals {
  renewalsConfiguration?: IConfigurationContextPolicyDetails
  policyPlanReferenceID: string | undefined
  isError: boolean
  isLoading: boolean
  errorMessage?: string | undefined
  isSending: boolean
  isPolicyRenewalsWithdrawed: boolean
  sendRequest: (args: {
    requiresReview: boolean
    allowAutoBind: boolean
    doNotRenew: boolean
  }) => void
  setPolicyRenewalWithdraw: () => void
  renewalsSettings?: {
    referenceID: string
    supportsAutoBind: boolean
  }[]
  values: TPolicyRenewals
}
