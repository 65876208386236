import {
  BookOutlined,
  CheckSquareTwoTone,
  CloseSquareTwoTone,
  PlusOutlined
} from "@ant-design/icons"
import { Breadcrumb, Button, Layout, Table } from "antd"
import { AxiosError } from "axios"
import { LayoutPageContent, LayoutPageHeader } from "components"
import usePlatform from "contexts/platform/use-platform"
import { TErrorState } from "models/errors"
import { ICompany, IName } from "platform-client/types"
import React from "react"
import { useQuery } from "react-query"
import { RouteComponentProps, useHistory, withRouter } from "react-router"
import { Link } from "react-router-dom"
import { getUserTokenValue } from "utils/pocketknife/get-user-token-value"

const columns = (
  {
    onEditClick
  }: {
    onEditClick: (companyId: string) => void
  },
  userGroup: unknown
) => [
  {
    title: "Reference",
    dataIndex: "referenceID",
    key: "referenceID",
    render: (value: string | null) => value || "-"
  },
  {
    title: "Name",
    key: "name",
    sorter: (a: ICompany, b: ICompany) =>
      (a.name || "").localeCompare(b.name || ""),
    render: (c: { name: string; id: string }) => (
      <Link to={`/distributor/${c.id}`}>
        <u>{c.name}</u>
      </Link>
    )
  },
  {
    title: "Distributor type",
    dataIndex: "companyType",
    key: "companyType"
  },
  {
    title: "Can bind business",
    dataIndex: "brokerCanBindBusiness",
    key: "brokerCanBindBusiness",
    render: (value: boolean) => {
      return value ? (
        <CheckSquareTwoTone
          style={{ fontSize: "20px" }}
          twoToneColor="#52c41a"
        />
      ) : (
        <CloseSquareTwoTone
          style={{ fontSize: "20px" }}
          twoToneColor="#eb2f96"
        />
      )
    }
  },
  {
    title: "TOBA",
    dataIndex: "tobaDocument",
    key: "termsOfBusinessAgreement",
    render: (value: boolean) => {
      return value ? (
        <CheckSquareTwoTone
          style={{ fontSize: "20px" }}
          twoToneColor="#52c41a"
        />
      ) : (
        <CloseSquareTwoTone
          style={{ fontSize: "20px" }}
          twoToneColor="#eb2f96"
        />
      )
    }
  },
  {
    title: "Contact name",
    dataIndex: "contactName",
    key: "contactName",
    render: (contactName: IName) => {
      return contactName && contactName.firstName
        ? `${contactName.firstName} ${contactName.lastName}`
        : "-"
    }
  },
  {
    title: "Contact email",
    dataIndex: "contactEmailAddress",
    key: "contactEmailAddress",
    render: (email: string) => {
      return email ? <a href={`mailto:${email}`}>{email}</a> : "-"
    }
  },
  {
    title: "Contact tel",
    dataIndex: "contactPhoneNumber",
    key: "contactPhoneNumber",
    render: (tel: string) => {
      return tel ? <a href={`tel:${tel}`}>{tel}</a> : "-"
    }
  },
  {
    title: "Actions",
    key: "actions",
    render: ({ id }: { id: string }) => {
      return (
        userGroup === "InsurerAdministrator" && (
          <Button onClick={() => onEditClick(id)}>Edit</Button>
        )
      )
    }
  }
]

const ListDistributors: React.FC<ListDistributorsProps> = (props) => {
  const [apiError, setApiError] = React.useState<TErrorState>()
  const history = useHistory()
  const platform = usePlatform()
  const userGroupType = getUserTokenValue("grouptype", platform.token || "")

  const listDistributors = useQuery(
    ["listDistributors", "Broker"],
    () => platform.company.listCompanies("Broker"),
    {
      onError: (error: AxiosError) => {
        setApiError({
          errorNumber: error.code ? parseInt(error.code) : 500,
          validationMessage: "Unable to load opportunities"
        })
      }
    }
  )

  return (
    <Layout>
      <LayoutPageHeader
        ghost={false}
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item key="/distributors">
              <BookOutlined />
              &nbsp;Distributors
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        title={"All Distributors"}
        extra={
          userGroupType === "InsurerAdministrator" && (
            <Link
              to={"/distributors/create"}
              className="ant-btn ant-btn-primary"
            >
              <PlusOutlined /> Create distributor
            </Link>
          )
        }
      />
      <LayoutPageContent error={apiError}>
        <Table
          rowKey="id"
          scroll={{ x: "max-content" }}
          loading={listDistributors.isLoading}
          columns={columns(
            {
              onEditClick: (companyId: string) =>
                history.push(`/distributors/edit/${companyId}`)
            },
            userGroupType
          )}
          dataSource={listDistributors.data}
          pagination={{
            defaultPageSize: 10,
            hideOnSinglePage: true,
            position: ["bottomCenter"],
            size: "small"
          }}
        />
      </LayoutPageContent>
    </Layout>
  )
}

export type ListDistributorsProps = RouteComponentProps<{
  type: string
}>

export default withRouter(ListDistributors)
