import React from "react"
import { Button } from "antd"
import {
  ReloadOutlined,
  LoadingOutlined,
  LinkOutlined,
  RightOutlined,
  DownOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  CloseCircleOutlined,
  SaveOutlined,
  FormOutlined,
  CalendarOutlined,
  LockOutlined,
  UnlockOutlined
} from "@ant-design/icons"
import styled from "styled-components"

export const ButtonWithIcon = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  background-color: transparent;

  .anticon {
    color: grey;
  }

  .addIcon,
  .minusIcon,
  .deleteIcon,
  .adjustIcon,
  .lockIcon,
  .calendarIcon,
  .saveIcon {
    transition: all 0.3s linear;
  }

  :hover,
  :focus {
    background-color: transparent;

    .addIcon,
    .minusIcon,
    .deleteIcon,
    .lockIcon,
    .adjustIcon,
    .calendarIcon,
    .saveIcon {
      transform: scale(1.1);
    }
  }
`

export const AddButton: React.FC<IButton> = (props): JSX.Element => (
  <ButtonWithIcon
    {...props}
    aria-label="add-button"
    type="text"
    title="Add/Enable"
    icon={<PlusCircleOutlined className="addIcon" />}
    className="addButton"
  />
)

export const SaveButton: React.FC<IButton> = ({ onClick }): JSX.Element => (
  <ButtonWithIcon
    aria-label="save-button"
    onClick={onClick}
    type="text"
    title="Save"
    icon={<SaveOutlined className="saveIcon" />}
  />
)

export const RemoveButton: React.FC<IButton> = (props): JSX.Element => (
  <ButtonWithIcon
    {...props}
    aria-label="remove-button"
    title="Remove"
    type="text"
    icon={<MinusCircleOutlined className="minusIcon" />}
  />
)

export const DeleteButton: React.FC<IButton> = (props): JSX.Element => (
  <ButtonWithIcon
    {...props}
    type="text"
    title="Delete"
    aria-label="delete-button"
    icon={<CloseCircleOutlined className="deleteIcon" />}
  />
)

export const LockButton: React.FC<ILockButton> = (props): JSX.Element => (
  <ButtonWithIcon
    {...props}
    type="text"
    title={props.locked ? "Unlock" : "Lock"}
    aria-label="lock-button"
    icon={
      props.locked ? (
        <UnlockOutlined className="lockIcon" />
      ) : (
        <LockOutlined className="lockIcon" />
      )
    }
  />
)

export const RefreshButton: React.FC<IButton> = (props): JSX.Element => (
  <ButtonWithIcon
    {...props}
    aria-label="refresh-button"
    title="Refresh"
    type="text"
    icon={<ReloadOutlined />}
  />
)

export const WorkingButton: React.FC<IButton> = ({ onClick }): JSX.Element => (
  <ButtonWithIcon
    onClick={onClick}
    aria-label="working-indicator"
    title="Loading"
    type="text"
    icon={<LoadingOutlined />}
  />
)

export const AnchorButton: React.FC<ILink> = ({ href }): JSX.Element => (
  <ButtonWithIcon
    href={href}
    aria-label="anchor-link"
    type="link"
    icon={<LinkOutlined />}
  />
)

export const DisableButton: React.FC<IButton> = ({ onClick }): JSX.Element => (
  <ButtonWithIcon
    onClick={onClick}
    aria-label="disable-button"
    title="Disable"
    type="text"
    icon={<MinusCircleOutlined className="minusIcon" />}
  />
)

export const ExpandButton: React.FC<IExpandButton> = ({
  onClick,
  expanded
}): JSX.Element => (
  <ButtonWithIcon
    onClick={onClick}
    aria-label="expand-button"
    type="text"
    title={expanded ? "Close" : "Open"}
    icon={expanded ? <DownOutlined /> : <RightOutlined />}
  />
)

export const AdjustButton: React.FC<IButton> = ({ onClick }) => (
  <ButtonWithIcon
    onClick={onClick}
    aria-label="adjust-button"
    title="Make Adjustment"
    type="text"
    icon={<FormOutlined className="adjustIcon" />}
  />
)

export const CalendarButton: React.FC<IButton> = ({ onClick }) => (
  <ButtonWithIcon
    onClick={onClick}
    aria-label="calendar-button"
    type="text"
    icon={<CalendarOutlined className="calendarIcon" />}
  />
)

interface ILink {
  href: string
}

interface IButton {
  onClick?: () => void
}

interface IExpandButton extends IButton {
  expanded: boolean
}

interface ILockButton extends IButton {
  locked: boolean
}
