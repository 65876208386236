import { Card, Table } from "antd"
import { IMoney, IPolicyDetailSet, Nullable } from "platform-client/types"
import styled from "styled-components"
import moment from "moment"
import { currencyToHumanReadable } from "utils/pocketknife/currency-to-human-readable"
import { useOrgSettings } from "contexts/organisation/hooks/use-org-settings"

const { Column } = Table

const StyledTable = styled(Table)`
  .fixed-left {
    width: 200px;
  }

  .section {
    font-weight: 600;
  }

  .fixed-right {
    width: 150px;
    text-align: right;
  }

  .cover-period {
    min-width: 150px;
    width: auto;
    text-align: right;
  }

  .ant-table-thead tr th {
    padding: 0;
    vertical-align: bottom;
    border-bottom: 1px solid #ccc;
  }

  .ant-table-tbody tr td {
    padding: 7px 16px;
  }

  .ant-table-tbody tr:last-child td {
    border-top: 1px solid #ccc;
    font-weight: 600;
  }

  .ant-table-thead tr th span {
    display: block;
    width: 100%;
    padding: 5px 16px;
  }

  .ant-table-thead tr th span:nth-child(2) {
    border-top: 1px solid #f0f0f0;
  }

  .secondary {
    color: #aaa;
  }
`

type DataType = {
  id?: string
  name: string | null
  total: Nullable<IMoney>
  [key: number]: IMoney | string | null
}

const SummaryOfCover: React.FC<ISummaryOfCoverProps> = (props) => {
  const { orgSettings } = useOrgSettings()
  const columns: {
    index: number
    start: string | null | undefined
    end: string | null | undefined
  }[] = []
  const data: DataType[] = []
  const emptyTotal = {
    value: 0,
    currencyCode: orgSettings?.currencyCode || "GBP"
  }

  const isMoney = (
    money: string | IMoney | null | undefined
  ): money is IMoney => {
    return (
      money !== null &&
      money !== undefined &&
      (money as IMoney).value !== undefined
    )
  }

  props.policyDetailSet.items.forEach((item, index) => {
    // Create columns array with friendly dates.
    columns.push({
      index,
      start:
        item.coverStartDateTime &&
        moment(item.coverStartDateTime).format("Do MMM YYYY"),
      end:
        item.coverEndDateTime &&
        moment(item.coverEndDateTime).format("Do MMM YYYY")
    })

    // Loop through the sections within the PolicyDetail.
    // Check if an object for the section exists.
    // IF YES: Add the current index value to the section + total.
    // IF NO: Add new row with base values.
    item.sections?.forEach((section) => {
      const current = data.findIndex((item) => item.id === section.id)
      if (current !== -1) {
        const total = data[current].total
        data[current] = {
          ...data[current],
          ...{
            [index]: section.totalNetPremium,
            total: {
              value: isMoney(total)
                ? total.value +
                  (isMoney(section.totalNetPremium)
                    ? section.totalNetPremium.value
                    : 0)
                : 0,
              currencyCode: isMoney(total)
                ? total.currencyCode
                : emptyTotal.currencyCode
            }
          }
        }
      } else {
        data.push({
          id: section.id,
          name: section.name,
          [index]: section.totalNetPremium,
          total: section.totalNetPremium || emptyTotal
        })
      }
    })
  })

  // The final row just needs a total, but
  // we have to included blank sections so that they
  // do not show "Not Included".
  if (data && data.length > 0) {
    data.push({
      name: null,
      total: props.policyDetailSet.totalPremium.netPremium || emptyTotal,
      ...columns.reduce((a, c, i) => {
        return {
          ...a,
          ...{
            [i]: ""
          }
        }
      }, {})
    })
  }

  if (data.length === 0) {
    // Fall back incase we have no data.
    return (
      <Card title="Sections and premiums">
        <span>No cover periods to display</span>
      </Card>
    )
  }

  // Attempts to set the scroll position to the right.
  const tableElement = document.getElementsByClassName("ant-table-content")
  if (tableElement && tableElement.length > 0) {
    tableElement[0].scrollLeft = 800
  }

  return (
    <Card
      title="Sections and premiums"
      headStyle={{
        borderTop: "1px solid #f0f0f0",
        borderLeft: "1px solid #f0f0f0",
        borderRight: "1px solid #f0f0f0"
      }}
      bodyStyle={{ padding: 0 }}
      bordered={false}
    >
      <StyledTable
        dataSource={data}
        bordered
        pagination={false}
        scroll={{ x: "max-content" }}
      >
        <Column
          title={
            <>
              <span>Cover start date</span>
              <span>Cover end date</span>
            </>
          }
          fixed="left"
          dataIndex="name"
          className="fixed-left section"
        />
        {columns.map((column) => (
          <Column
            title={
              <>
                <span>{column.start}</span>
                <span>{column.end}</span>
              </>
            }
            className="cover-period"
            dataIndex={column.index}
            render={(value: IMoney | string | null) => {
              if (isMoney(value)) {
                return currencyToHumanReadable(value, false)
              }

              return value !== undefined ? (
                value
              ) : (
                <span className="secondary">Not Included</span>
              )
            }}
          />
        ))}
        <Column
          title={
            <>
              <span>Total premium</span>
            </>
          }
          dataIndex="total"
          fixed="right"
          className="fixed-right"
          render={(value: IMoney | string | null) => {
            if (isMoney(value)) {
              return currencyToHumanReadable(value, false)
            }

            return value !== null ? (
              value
            ) : (
              <span className="secondary">Not Included</span>
            )
          }}
        />
      </StyledTable>
    </Card>
  )
}

interface ISummaryOfCoverProps {
  policyDetailSet: IPolicyDetailSet
}

export default SummaryOfCover
