import { useState } from "react"
import { useAsyncEffect } from "use-async-effect"
import { TErrorState } from "models/errors"
import {
  errorValidationMessage,
  IErrorState
} from "utils/pocketknife/error-validation-message"
import { IPolicyHistoryData } from "models/policies/policyHistory"
import useOpus from "contexts/opus/hooks/use-opus"

export const useGetPolicyHistory = (
  policyID: string | undefined
): IPolicyHistory => {
  const [policyHistory, setPolicyHistory] = useState<
    IPolicyHistoryData | undefined
  >(undefined)
  const [isError, setIsError] = useState<TErrorState>(undefined)
  const [isPolicyHistoryLoading, setPolicyHistoryLoading] =
    useState<boolean>(false)
  const { policyClient } = useOpus()

  useAsyncEffect(async () => {
    try {
      setPolicyHistoryLoading(true)
      if (policyID) {
        const _policy = await policyClient.getPolicyHistory(policyID)

        if (_policy) {
          setPolicyHistory(_policy)
          setIsError(undefined)
        }
      }
      setPolicyHistoryLoading(false)
    } catch (error) {
      setPolicyHistory(undefined)
      setIsError({
        errorNumber: error?.response?.status,
        validationMessage: errorValidationMessage(error)
      })
      setPolicyHistoryLoading(false)
    }
  }, [policyID])

  return {
    policyHistory,
    isError,
    isPolicyHistoryLoading
  }
}

interface IPolicyHistory extends IErrorState {
  policyHistory: IPolicyHistoryData | undefined
  isPolicyHistoryLoading: boolean
}
