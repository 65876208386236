import RichTextEditor from "components/RichTextEditor"
import { Button, Modal, Typography } from "antd"

const { Title } = Typography

interface IRichTextEditorModalProps {
  visible: boolean
  content: string
  setContent: (content: string) => void
  onSubmit: (content: string) => void
  onCancel: () => void
  isLoading: boolean
  headerText?: string
}

export const RichTextEditorModal: React.FC<IRichTextEditorModalProps> = ({
  visible = false,
  content = "",
  setContent = () => null,
  onCancel = () => null,
  onSubmit = () => null,
  isLoading = false,
  headerText = "Create New Note"
}) => (
  <Modal
    visible={visible}
    onCancel={onCancel}
    footer={
      <>
        <Button onClick={onCancel} disabled={isLoading}>
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={() => onSubmit(content)}
          loading={isLoading}
        >
          Submit
        </Button>
      </>
    }
  >
    <Title level={4}>{headerText}</Title>
    <RichTextEditor content={content} setContent={setContent} />
  </Modal>
)
