import React, { useEffect, useState } from "react"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { PolicyHistory } from "../../v2_components"
import { IPolicyHistoryData } from "models/policies/policyHistory"
import { useGetPolicyHistory } from "containers/policies/services/hooks/"

export const PolicyHistorySubViewComponent: React.FunctionComponent<IPolicyHistorySubView> =
  (props) => {
    const [policyHistory, setPolicyHistory] = useState<
      IPolicyHistoryData | undefined
    >()

    const { policyHistory: _policyHistory, isPolicyHistoryLoading } =
      useGetPolicyHistory(props.policyID)

    useEffect(() => {
      setPolicyHistory(_policyHistory)
    }, [_policyHistory])
    return (
      <PolicyHistory
        isPolicyHistoryLoading={isPolicyHistoryLoading}
        policyHistory={policyHistory?.policyHistories}
      />
    )
  }

export const PolicyHistorySubView = withRouter(PolicyHistorySubViewComponent)

export interface IPolicyHistorySubView extends RouteComponentProps {
  policyID: string
}
