import { Modal } from "antd"
import { useHistory } from "react-router"

export const useRevalidateQuote = (
  quoteId: string,
  productReference: string | undefined,
  productCoverReference: string | undefined
): IUseRevalidateQuote => {
  const history = useHistory()

  return {
    showRevalidateQuoteModal: () => {
      Modal.confirm({
        title: "Are you sure you want to revalidate this quote?",
        centered: true,
        okText: "Revalidate",
        onOk: () => {
          history.push(
            `/opus/duplicate/${productReference}/${productCoverReference}/${quoteId}`
          )
        }
      })
    }
  }
}

export interface IUseRevalidateQuote {
  showRevalidateQuoteModal: () => void
}
