import { useState } from "react"
import { useAsyncEffect } from "use-async-effect"
import ConfigurationClient from "contexts/configuration/client"

export const useGetAppConfig = (): IUseGetAppConfig => {
  const [applicationConfig, setApplicationConfig] = useState<
    IApplicationConfig | undefined
  >(undefined)

  useAsyncEffect(
    async (isMounted) => {
      if (!applicationConfig) {
        try {
          const _applicationCofiguration =
            await ConfigurationClient.getApplicationConfiguration()
          if (isMounted()) {
            setApplicationConfig(_applicationCofiguration)
          }
        } catch (e) {
          if (isMounted()) {
            setApplicationConfig(undefined)
          }
        }
      }
    },
    [applicationConfig]
  )

  return { applicationConfig }
}

interface IUseGetAppConfig {
  applicationConfig: IApplicationConfig | undefined
}

export interface IActiveLineOfBusiness {
  coverTypes: TCoverElement[]
  productType?: string //Could this be an Enum?
  dashboardOrder: number
  id: number
  isActive: boolean
  name: string
  hasQuickQuote?: boolean
}

export interface IApplicationConfig {
  activeLinesOfBusiness: IActiveLineOfBusiness[]
}

export type TCoverElement = {
  callToActionLabel: string
  dashboardOrder: number
  desc: string
  label: string
  underwriter?: string
  labelIcon: string
  name: string
  productReferenceId: string
  bannerImage?: string
}
