import styled from "styled-components"
import Row from "components/Console/Row"
import { ExpandButton } from "components/Console/CellContent/Button"
import CellContentText from "components/Console/CellContent/Text"
import React from "react"
import { DateDisplay } from "components/display/DateDisplay"
import { IOracleResult } from "platform-client/types/oracle"
import CellContentTitle from "components/Console/CellContent/Title"
import { transformOracleValue } from "components/diffviewer/diffViewerInnerRow/helper/riskDisplayer"

export const OracleDisplayRow: React.FunctionComponent<IClaimRow> = (props) => {
  const [expanded, setExpanded] = React.useState<boolean>(false)

  const InfoPanel = styled.div`
    padding: 20px;
    background-color: #fff;
  `

  return (
    <>
      <Row
        reference={props.result.oracleName}
        toggle={
          <ExpandButton
            expanded={expanded}
            onClick={() => setExpanded(!expanded)}
          />
        }
        values={[
          <>
            <CellContentText>
              {props.result.requestTime != null ? (
                <DateDisplay date={props.result.requestTime} />
              ) : (
                "-"
              )}
            </CellContentText>
            <CellContentText>{props.result.exposureID}</CellContentText>
          </>
        ]}
      />
      {expanded && (
        <InfoPanel data-testid={`info-panel-${props.result.oracleName}`}>
          <Row
            type="subHeader"
            reference={<CellContentTitle>{"Label"}</CellContentTitle>}
            values={[
              <>
                <CellContentTitle>{"Value"}</CellContentTitle>
              </>
            ]}
          />
          {props.result.values.map((resultRow) => {
            return (
              <Row
                reference={<CellContentText>{resultRow.label}</CellContentText>}
                values={[
                  <>{transformOracleValue(resultRow.value, resultRow.type)}</>
                ]}
              ></Row>
            )
          })}
          {props.result.error && (
            <Row
              reference={<CellContentText>{"Error"}</CellContentText>}
              values={[
                <>{transformOracleValue(props.result.error, "String")}</>
              ]}
            ></Row>
          )}
        </InfoPanel>
      )}
    </>
  )
}

export interface IClaimRow {
  result: IOracleResult
}
