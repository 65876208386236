import { Form, Input, Modal, Spin, notification } from "antd"
import useOpus from "contexts/opus/hooks/use-opus"
import produce from "immer"
import { useState } from "react"
import styled from "styled-components"
import { useOrgSettings } from "contexts/organisation/hooks/use-org-settings"
import { handleValidationErrors } from "utils/pocketknife/handle-validation-errors"
import { AxiosError } from "axios"
import MoneyInput from "components/MoneyInput"
import { IPolicyPaymentMethod } from "models/policies/policyPaymentMethod"
import TextArea from "antd/lib/input/TextArea"

const StyledText = styled.span<{ secondary?: boolean; emphasise?: boolean }>`
  color: ${(props) => (props.secondary ? "#bfbfbf" : "inherit")};
  font-style: ${(props) => (props.emphasise ? "italic" : "inherit")};
`

export const useManualPayment = (
  ownerID: string | undefined,
  policyID: string | undefined,
  refetchTimelineEvents: VoidFunction,
  setRefetchTransactions: React.Dispatch<React.SetStateAction<boolean>>
): IUseManualPayment => {
  const { policyClient } = useOpus()
  const { orgSettings } = useOrgSettings()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isWorking, setIsWorking] = useState<boolean>(false)
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [paymentMethod, setPaymentMethod] = useState<IPolicyPaymentMethod>()

  const [payment, setPayment] = useState<IManualPaymentStore>({
    amount: 0,
    reference: null,
    reason: null
  })

  const [errors, setErrors] = useState<{
    amount: null | string
    reference: null | string
    reason: null | string
  }>({
    amount: null,
    reference: null,
    reason: null
  })

  const clearErrors = () => {
    setErrors({
      amount: null,
      reference: null,
      reason: null
    })
  }

  const clearState = () => {
    setPaymentMethod(undefined)
    setPayment({
      amount: 0,
      reference: null,
      reason: null
    })
    clearErrors()
  }

  const submitPayment = async () => {
    setIsWorking(true)
    let hasError = false

    if (payment.amount === undefined || payment.amount <= 0) {
      setErrors(
        produce((draft) => {
          draft.amount = "Payment amount must be greater than $0"
        })
      )
      hasError = true
    }

    if (
      payment.reference === null ||
      payment.reference === undefined ||
      payment.reference === ""
    ) {
      setErrors(
        produce((draft) => {
          draft.reference = "Unique reference is required"
        })
      )
      hasError = true
    }

    if (!hasError && ownerID) {
      const result = await policyClient
        .manualPayment(ownerID, {
          policyID: policyID || "",
          amount: {
            value: Number(payment.amount),
            currencyCode: orgSettings?.currencyCode || "AUD"
          },
          reference: payment.reference,
          reason: payment.reason
        })
        .catch((error: AxiosError) => {
          handleValidationErrors(error)
        })

      if (result && result.paymentStatus === "Settled") {
        notification.success({
          message: null,
          description: `Payment issued successfully: ${result.paymentReference}`
        })

        setIsModalVisible(false)
        refetchTimelineEvents()
        setRefetchTransactions(true)
      } else {
        const errorMessage = result ? result.providerFailureMessage : ""

        notification.error({
          message: null,
          description: `Error attempting the payment: ${errorMessage}`
        })
      }
    }

    setIsWorking(false)
  }

  const showManualPaymentModal = async (isVisible: boolean) => {
    clearState()
    setIsModalVisible(isVisible)

    if (isVisible === true) {
      if (policyID) {
        setIsLoading(true)
        const paymentDetails = await policyClient.getPolicyPaymentMethod(
          policyID
        )
        setPaymentMethod(paymentDetails)
        setIsLoading(false)
      }
    }
  }

  const modal = (
    <Modal
      title="Manual Payment"
      centered
      destroyOnClose
      visible={isModalVisible}
      onCancel={(e) => {
        setIsModalVisible(false)
      }}
      okText="Charge Card"
      okButtonProps={{
        disabled: paymentMethod && paymentMethod.last4Digits ? false : true
      }}
      onOk={submitPayment}
      confirmLoading={isWorking}
    >
      <Spin spinning={isLoading}>
        <StyledText>
          {paymentMethod &&
            paymentMethod.last4Digits &&
            `The payment will be taken using card number ending in ${paymentMethod.last4Digits} expiring ${paymentMethod.expiryMonth}/${paymentMethod.expiryYear}`}
        </StyledText>
        <StyledText>
          {paymentMethod &&
            paymentMethod.last4Digits === null &&
            `A manual payment is not supported against this policy as no card details are stored`}
        </StyledText>
        <Form
          layout="vertical"
          id="manualPaymentForm"
          style={{ margin: "20px 0 0" }}
        >
          <Form.Item
            name="amount"
            label="Amount:"
            help={errors.amount}
            validateStatus={errors.amount ? "error" : "success"}
            rules={[{ required: true }]}
          >
            <MoneyInput
              currencyCode={orgSettings?.currencyCode}
              value={payment.amount ? Number(payment.amount) : undefined}
              valueChanged={(newValue) => {
                setPayment(
                  produce((draft) => {
                    draft.amount = newValue ? Number(newValue) : 0
                  })
                )
                clearErrors()
              }}
            />
          </Form.Item>
          <Form.Item
            name="Unique Reference"
            label="Unique Reference:"
            extra="This is your reference (e.g. Credit Note reference, or similar). This will not show up on the customer's statement."
            help={errors.reference}
            validateStatus={errors.reference ? "error" : "success"}
            rules={[{ required: true }]}
          >
            <Input
              name="reference"
              type="string"
              value={payment.reference ? payment.reference : undefined}
              onChange={(event) => {
                setPayment(
                  produce((draft) => {
                    draft.reference = event.target.value
                      ? event.target.value
                      : null
                  })
                )
                clearErrors()
              }}
            />
          </Form.Item>
          <Form.Item
            name="Reason"
            label="Reason:"
            extra="Please provide a reason for taking this manual payment"
            help={errors.reason}
            validateStatus={errors.reference ? "error" : "success"}
            rules={[{ required: true }]}
          >
            <TextArea
              name="reason"
              rows={3}
              value={payment.reason ? payment.reason : undefined}
              onChange={(event) => {
                setPayment(
                  produce((draft) => {
                    draft.reason = event.target.value
                      ? event.target.value
                      : null
                  })
                )
                clearErrors()
              }}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  )

  return {
    manualPaymentModal: modal,
    showManualPaymentModal
  }
}

interface IManualPaymentStore {
  amount: number | 0
  reference: string | null
  reason: string | null
}

export interface IUseManualPayment {
  manualPaymentModal: React.ReactNode
  showManualPaymentModal: (arg: boolean) => void
}
