import { useState } from "react"
import { IEmbargoesListItem } from "models/embargoes/embargoesList"
import { useAsyncEffect } from "use-async-effect"
import { TErrorState } from "models/errors"
import {
  errorValidationMessage,
  IErrorState
} from "utils/pocketknife/error-validation-message"
import useOpus from "contexts/opus/hooks/use-opus"

export const useGetEmbargo = (
  embargoID: string | undefined,
  shouldReload: boolean
): IUseGetEmbargo => {
  const [embargo, setEmbargo] = useState<IEmbargoesListItem | undefined>(
    undefined
  )
  const [isError, setIsError] = useState<TErrorState>(undefined)
  const [embargoLoaded, setEmbargoLoaded] = useState<boolean>(false)
  const { embargoesClient } = useOpus()

  useAsyncEffect(async () => {
    try {
      if (embargoID) {
        const _embargo = await embargoesClient.getEmbargo(embargoID)
        if (_embargo) {
          setEmbargo(_embargo)
          setEmbargoLoaded(true)
        }
      }
    } catch (error) {
      setEmbargo(undefined)
      setIsError({
        errorNumber: error?.response?.status,
        validationMessage: errorValidationMessage(error)
      })
      setEmbargoLoaded(false)
    }
  }, [shouldReload, embargoLoaded])

  return {
    embargo,
    isError,
    isEmbargoLoaded: embargoLoaded
  }
}

interface IUseGetEmbargo extends IErrorState {
  embargo: IEmbargoesListItem | undefined
  isEmbargoLoaded: boolean
}
