import { Breadcrumb, Layout, Tabs } from "antd"
import { ReportsBoundary } from "./boundary"
import { PaymentPlansPastDue } from "./views/PaymentPlansPastDue"
import { LayoutPageContent, LayoutPageHeader } from "components"
import { FileTextOutlined } from "@ant-design/icons"
import { BouncedEmails } from "./views/BouncedEmails"
import { Route, StaticContext, Switch, useHistory } from "react-router"
import { BouncedSms } from "./views/BouncedSms"
import { RouteComponentProps } from "react-router-dom"

type Props = RouteComponentProps<
  {
    id: string
    subpage: string
  },
  StaticContext,
  {
    data?: string
  }
>

export const Reports: React.FC<Props> = (props: Props) => {
  const history = useHistory()

  const onTabClick = (activeKey: string) => {
    switch (activeKey) {
      case "pendingCancellations":
        history.push(`/reports/pendingCancellations`)
        break
      case "bouncedEmails":
        history.push(`/reports/bouncedEmails`)
        break
      case "bouncedSMSs":
        history.push(`/reports/bouncedSMSs`)
        break
    }
  }

  return (
    <ReportsBoundary>
      <Layout>
        <LayoutPageHeader
          ghost={false}
          breadcrumb={
            <Breadcrumb>
              <Breadcrumb.Item key="/reports">
                <FileTextOutlined />
                &nbsp;Reports
              </Breadcrumb.Item>
            </Breadcrumb>
          }
          title="Reports"
          footer={
            <Tabs
              onTabClick={onTabClick}
              defaultActiveKey={props.match.params.subpage}
              activeKey={props.match.params.subpage}
            >
              <Tabs.TabPane
                tab="Pending Policy Cancellations due to Non-Payment"
                key="pendingCancellations"
              ></Tabs.TabPane>
              <Tabs.TabPane
                tab="Bounced Emails"
                key="bouncedEmails"
              ></Tabs.TabPane>
              <Tabs.TabPane tab="Bounced SMSs" key="bouncedSMSs"></Tabs.TabPane>
            </Tabs>
          }
        />
        <LayoutPageContent transparent>
          <Switch>
            <Route
              exact
              path={`/reports`}
              render={() => <PaymentPlansPastDue />}
            ></Route>
            <Route
              path={`/reports/pendingCancellations`}
              render={() => <PaymentPlansPastDue />}
            ></Route>
            <Route
              path={`/reports/bouncedEmails`}
              render={() => <BouncedEmails />}
            ></Route>
            <Route
              path={`/reports/bouncedSMSs`}
              render={() => <BouncedSms />}
            ></Route>
          </Switch>
        </LayoutPageContent>
      </Layout>
    </ReportsBoundary>
  )
}
