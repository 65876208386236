import { decodeJWT } from "../decode-jwt"

export const getUserTokenValue = (
  key: string,
  token?: string
): string | number | string[] | null => {
  if (token === undefined) {
    return null
  }

  const decoded = decodeJWT(token)

  return decoded[key] ?? null
}
