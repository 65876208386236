import React from "react"
import formatDate from "dateformat"
import { Card, Descriptions } from "antd"
import { IMoney } from "models/quotes/money"
import { MoneyDisplay } from "components/display/MoneyDisplay"
import { Address } from "components"
import { TAddress } from "utils/addressService"
import { DateDisplay } from "components/display/DateDisplay"
import { PolicyEmailAdjustment } from "../policy-email-adjustment"
import { PolicyCoverStartDate } from "../policy-coverstartdate-adjustment"
import { PolicyPhoneNumber } from "../policy-phone-number"

export const PolicyInfo: React.FunctionComponent<IPolicyInfo> = (props) => {
  const canEditCoverStart =
    (!props.isExternal ?? true) && props.lineOfBusiness !== "travel"

  return (
    <>
      <Card
        title="Policy Details"
        loading={props.isPolicyLoading}
        style={{ marginBottom: "20px" }}
      >
        <Descriptions size="small" column={3} bordered>
          <Descriptions.Item label="Created">
            {props.created && formatDate(new Date(props.created), "dd/mm/yyyy")}
          </Descriptions.Item>
          <Descriptions.Item label="Name Insured">
            {`${props?.personName?.firstName} ${props?.personName?.lastName}`}
          </Descriptions.Item>
          {props.solicitor && (
            <Descriptions.Item label="Solicitor">
              {props?.solicitor}
            </Descriptions.Item>
          )}

          <Descriptions.Item label="Email Address">
            <PolicyEmailAdjustment
              emailAddressValue={props.emailAddress}
              policyId={props.policyId ? props.policyId : ""}
              ownerId={props.ownerId ? props.ownerId : ""}
              isExternal={props.isExternal ? props.isExternal : false}
              refetchTimelineEvents={props.refetchTimelineEvents}
            />
          </Descriptions.Item>
          {props.address && (
            <Descriptions.Item label="Address">
              <Address address={props?.address as TAddress} />
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Product">
            {props.product?.name} (v{props.product?.version})
          </Descriptions.Item>

          {props.limitOfLiability && (
            <Descriptions.Item label="Purchase Price">
              <MoneyDisplay value={props.limitOfLiability} />
            </Descriptions.Item>
          )}
          {props.coverStartDate && (
            <Descriptions.Item label="Cover Start Date">
              <PolicyCoverStartDate
                coverStartDate={props.coverStartDate}
                policyId={props.policyId ? props.policyId : ""}
                canEdit={canEditCoverStart}
                refetchPolicyBundle={props.refetchPolicyBundle}
              />
            </Descriptions.Item>
          )}
          {props.phoneNumber && (
            <Descriptions.Item label="Phone Number">
              <PolicyPhoneNumber
                phoneNumber={props.phoneNumber}
                refetchTimelineEvents={props.refetchTimelineEvents}
              />
            </Descriptions.Item>
          )}
          {props.userId && (
            <Descriptions.Item label="User ID">
              {props.userId}
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Underwriter">
            {props.scheme?.insurerName}
          </Descriptions.Item>
          {props.effectiveDate && (
            <Descriptions.Item label="Effective Date">
              <DateDisplay date={props.effectiveDate} />
            </Descriptions.Item>
          )}
        </Descriptions>
      </Card>
    </>
  )
}

export interface IPolicyInfo {
  isPolicyLoading: boolean
  lineOfBusiness: string | undefined
  created?: string
  effectiveDate?: string
  product?: { name: string; version: number }
  scheme?: { insurerName: string }
  personName:
    | {
        firstName: string
        lastName: string
      }
    | undefined
  solicitor?: string
  emailAddress: string
  phoneNumber: string
  address?:
    | {
        line1: string
        line2: string
        locality: string
        postalCode: string
      }
    | undefined
  policyType?: string
  limitOfLiability?: IMoney
  ownerId?: string
  policyId?: string
  isExternal?: boolean
  coverStartDate?: string
  refetchPolicyBundle?: () => void
  refetchTimelineEvents: VoidFunction
  userId?: string
}
