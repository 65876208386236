import { notification } from "antd"
import usePlatform from "contexts/platform/use-platform"
import produce from "immer"
import { IQuoteBuilderResponse } from "platform-client/client/controllers"
import {
  IFinanceCommission,
  IFinanceNetLoadingDiscount,
  IUpdateSection,
  SectionTypes
} from "platform-client/types"
import React from "react"
import { useMutation, UseMutationResult } from "react-query"
import { percentageToDecimal } from "utils/pocketknife/converters"

export const useSection: UseSectionType = (editSessionID, onChange) => {
  const { quoteBuilder } = usePlatform()

  const updateSection = useMutation((updateSection: IUpdateSection) =>
    quoteBuilder.updateSection(
      updateSection.editSessionID,
      updateSection.sectionID,
      {
        ...updateSection.section
      }
    )
  )

  const handleUpdate = async (
    payload: IUpdateSection,
    errorMessage = "Unable to update section, please try again"
  ) => {
    updateSection
      .mutateAsync(payload)
      .then((response) => {
        if (typeof onChange === "function") {
          onChange(response)
        }
      })
      .catch((error) => {
        notification.error({
          message: errorMessage
        })
      })
  }

  const handleCommissionUpdate = async (
    event: React.ChangeEvent<HTMLInputElement>,
    section: SectionTypes,
    commission: IFinanceCommission
  ) => {
    if (editSessionID) {
      const draftCommission = produce(commission, (draft) => {
        if (draft) {
          draft["ruleAmount"] =
            draft.commissionType === "Percentage"
              ? percentageToDecimal(Number(event.target.value))
              : Number(event.target.value)
        }

        return draft
      })

      handleUpdate({
        editSessionID: editSessionID,
        sectionID: section.id,
        section: {
          netLoadingDiscounts: section.netLoadingDiscounts,
          grossDiscounts: section.grossDiscounts,
          name: section.name,
          schemeID: section.schemeID,
          commission: {
            referenceID: draftCommission ? draftCommission.referenceID : "",
            ruleAmount: draftCommission ? draftCommission.ruleAmount : 0,
            name: draftCommission ? draftCommission.name : "",
            breakdown: {
              introducerRuleAmount: commission.breakdown.introducerRuleAmount
            }
          }
        }
      })
    }
  }

  const handleIntroducerUpdate = async (
    event: React.ChangeEvent<HTMLInputElement>,
    section: SectionTypes,
    commission: IFinanceCommission
  ) => {
    if (editSessionID) {
      const draftCommission = produce(commission, (draft) => {
        if (draft) {
          draft.breakdown.introducerRuleAmount =
            commission.commissionType === "Percentage"
              ? percentageToDecimal(Number(event.target.value))
              : Number(event.target.value)
        }
      })

      handleUpdate({
        editSessionID,
        sectionID: section.id,
        section: {
          netLoadingDiscounts: section.netLoadingDiscounts,
          grossDiscounts: section.grossDiscounts,
          name: section.name,
          schemeID: section.schemeID,
          commission: {
            name: commission.name,
            referenceID: commission.referenceID,
            ruleAmount: commission.ruleAmount,
            breakdown: {
              introducerRuleAmount:
                draftCommission.breakdown.introducerRuleAmount
            }
          }
        }
      })
    }
  }

  const handlePremiumAdjustmentUpdate = async (
    event: React.ChangeEvent<HTMLInputElement>,
    section: SectionTypes,
    discounts: IFinanceNetLoadingDiscount[]
  ) => {
    if (editSessionID) {
      const dataArray = produce(discounts, (draft) => {
        if (draft && draft.length) {
          draft = draft.map((item) => {
            if (item.referenceID === event.target.name) {
              item["ruleAmount"] =
                item.type === "Percentage"
                  ? percentageToDecimal(Number(event.target.value))
                  : Number(event.target.value)
            }
            return item
          })
        }
      })

      handleUpdate({
        editSessionID: editSessionID,
        sectionID: section.id,
        section: {
          commission: section.commission,
          grossDiscounts: section.grossDiscounts,
          netLoadingDiscounts: dataArray,
          name: section.name,
          schemeID: section.schemeID
        }
      })
    }
  }

  return {
    updateSection,
    handleCommissionUpdate,
    handleIntroducerUpdate,
    handlePremiumAdjustmentUpdate
  }
}

export interface IUseSection {
  updateSection: UseMutationResult<
    IQuoteBuilderResponse,
    unknown,
    IUpdateSection,
    unknown
  >
  handleCommissionUpdate: (
    event: React.ChangeEvent<HTMLInputElement>,
    section: SectionTypes,
    commission: IFinanceCommission
  ) => void
  handleIntroducerUpdate: (
    event: React.ChangeEvent<HTMLInputElement>,
    section: SectionTypes,
    commission: IFinanceCommission
  ) => void
  handlePremiumAdjustmentUpdate: (
    event: React.ChangeEvent<HTMLInputElement>,
    section: SectionTypes,
    discounts: IFinanceNetLoadingDiscount[]
  ) => void
}

export type UseSectionType = (
  editSessionID?: string,
  onChange?: (response: IQuoteBuilderResponse) => void
) => IUseSection
