import React from "react"
import styled from "styled-components"
import { Menu, Dropdown, Button } from "antd"
import {
  UserOutlined,
  PlusOutlined,
  DownOutlined,
  ExportOutlined
} from "@ant-design/icons"
import { Link } from "react-router-dom"
import {
  useOpusPermissions,
  EOpusPermission
} from "contexts/authorization/hooks/use-opus-permissions"
import { useConfigurationContext } from "contexts/configuration/context"
import { useUserContext } from "contexts/user/context"

const StyledContent = styled.div`
  padding: 0 6px;
  flex-basis: auto;
`

export const StyledDropdown = styled(Dropdown)`
  padding-left: 10px;
  cursor: pointer;
  margin-left: 1ch;
`

const UserBlock = styled.div`
  display: inline-block;
`

const StyledUsername = styled.span`
  padding: 0 1ch;
`

const UserMenu = (
  <Menu>
    <Menu.Item icon={<ExportOutlined />}>
      <Link to="/logoutRedirect">Log out</Link>
    </Menu.Item>
  </Menu>
)

export const LayoutNewButton: React.FunctionComponent = () => {
  const configuration = useConfigurationContext()
  const { professionalIndemnityInsuranceExpired } = useUserContext()
  const { havePermission: canUseNewQuote } = useOpusPermissions(
    EOpusPermission.Quote,
    "create"
  )

  if (!canUseNewQuote) return null

  const NewButtonObj =
    configuration.flags.platformVersion === "v3"
      ? { text: "New Quote", link: "/opportunity/create" }
      : { text: "New Quote", link: "/choose-quote" }

  if (
    configuration.flags.platformVersion === "v3" &&
    professionalIndemnityInsuranceExpired === true
  ) {
    return (
      <Button type="primary" disabled>
        <PlusOutlined /> {NewButtonObj.text}
      </Button>
    )
  }

  return (
    <Link to={NewButtonObj.link} className="ant-btn ant-btn-primary">
      <PlusOutlined /> {NewButtonObj.text}
    </Link>
  )
}

export const LayoutUserbar: React.FunctionComponent<ILayoutUserbar> = (
  props
) => {
  if (props.userDetails) {
    return (
      <StyledContent>
        <LayoutNewButton />
        <StyledDropdown trigger={["click"]} overlay={UserMenu}>
          <UserBlock>
            <UserOutlined />
            <StyledUsername>{props.userDetails.nickname}</StyledUsername>
            <DownOutlined />
          </UserBlock>
        </StyledDropdown>
      </StyledContent>
    )
  } else {
    return (
      <div>
        <Link to="/loginRedirect">
          <span>
            <ExportOutlined />
            Log in
          </span>
        </Link>
      </div>
    )
  }
}

interface ILayoutUserbar {
  userDetails: IUserDetails | undefined
}

export type IUserDetails = {
  nickname: string
}
