/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dropdown, Menu, Tag, Typography } from "antd"
import { Link } from "react-router-dom"
import { ColumnsType } from "antd/lib/table"
import formatDate from "dateformat"
import { startCase } from "lodash"
import { MoreOutlined } from "@ant-design/icons"
import { MoneyDisplay } from "components/display/MoneyDisplay"
import { IMoney } from "platform-client/types"

const { Text } = Typography

export const filterStates = ["Open", "Paid", "Cancelled", "Void"]
export const statusOptions = ["Paid", "Void"]
export const state = {
  Open: { tag: "open", color: "blue" },
  None: { tag: "none", color: "lime" },
  Paid: { tag: "paid", color: "green" },
  Cancelled: { tag: "cancelled", color: "red" },
  Void: { tag: "void", color: "red" }
}

export const INVOICE_COLUMNS = (
  state:
    | {
        [key: string]: {
          color: string
          tag: string
        }
      }
    | undefined,
  actions = {} as {
    handleInvoiceStatusChange: (invoiceId: string, status: string) => void
  }
): ColumnsType<any> => {
  return [
    {
      title: "Created",
      dataIndex: "dateCreated",
      key: "dateCreated",
      render: (date: string) => formatDate(new Date(date), "dd/mm/yyyy")
    },
    {
      title: "Invoice ID",
      dataIndex: "referenceID",
      key: "referenceID",
      render: (referenceID) => <Text>{referenceID || "-"}</Text>
    },
    {
      title: "Company Name",
      key: "companyName",
      dataIndex: "companyName",
      render: (companyName: string) => <Text>{companyName}</Text>
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: string) => {
        const quoteType = state && state[text]
        return (
          <Tag color={quoteType?.color}>{quoteType?.tag.toUpperCase()}</Tag>
        )
      }
    },
    {
      title: "Purpose",
      dataIndex: "invoicePurpose",
      key: "invoicePurpose",
      render: (invoicePurpose) => <Text>{startCase(invoicePurpose)}</Text>
    },
    {
      title: "Type",
      dataIndex: "invoiceType",
      key: "invoiceType",
      render: (invoiceType) => <Text>{startCase(invoiceType)}</Text>
    },
    {
      title: "Total Amount",
      dataIndex: "total",
      key: "total",
      align: "right",
      render: (total: IMoney) => <MoneyDisplay value={total} />
    },
    {
      title: "Policy ID",
      key: "policyReferenceID",
      render: (row: {
        policyReferenceID: string
        policyID: string | undefined
      }) => (
        <Link to={`/policy/${row.policyID}/summary`}>
          <u>{row.policyReferenceID}</u>
        </Link>
      )
    },
    {
      title: "Action",
      key: "action",
      render: ({ status, id } = {}) => {
        const showOptions = !statusOptions.some((option) => option === status)
        return showOptions ? (
          <Dropdown
            overlay={
              <Menu>
                {statusOptions.map((option) => (
                  <Menu.Item
                    onClick={() =>
                      actions.handleInvoiceStatusChange(id, option)
                    }
                  >{`Mark Invoice as ${option}`}</Menu.Item>
                ))}
              </Menu>
            }
          >
            <MoreOutlined
              style={{ cursor: "pointer", padding: "0 5px", fontSize: 19 }}
            />
          </Dropdown>
        ) : null
      }
    }
  ]
}
