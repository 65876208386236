import { Button, Card, Col, notification, Row } from "antd"
import usePlatform from "contexts/platform/use-platform"
import { ICreatePolicyholder } from "platform-client/client/controllers/policyholders"
import { IPolicyholder } from "platform-client/types"
import React from "react"
import { useMutation } from "react-query"
import PolicyholderForm, {
  IPolicyholderData
} from "components/PolicyHolder/PolicyholderForm"
import PolicyholderLookup from "components/PolicyHolder/PolicyholderLookup"
import PolicyholderCard from "components/PolicyHolder/PolicyholderCard"
import styled from "styled-components"

const StyledButtonWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;

  button {
    width: 100%;
  }
`

const CreatePolicyholder: React.FC<ICreatePolicyholderProps> = ({
  onBack,
  onSavePolicyholder,
  showCreateForm = false,
  isLoading = false,
  ...props
}) => {
  const [showForm, setShowForm] = React.useState<boolean>(showCreateForm)
  const [policyholder, setPolicyholder] = React.useState<
    IPolicyholder | undefined
  >(props.policyholder)
  const platform = usePlatform()

  const createPolicyholder = useMutation(
    (createPolicyholderRequest: ICreatePolicyholder) =>
      platform.policyholders.createPolicyholder(createPolicyholderRequest)
  )

  const onFinish = async (formData: IPolicyholderData) => {
    if (formData.legalName && formData.policyholderType) {
      createPolicyholder
        .mutateAsync({
          businessName: formData.businessName || null,
          contact: {
            name: {
              firstName: formData.firstName || null,
              lastName: formData.lastName || null,
              title: formData.title || null
            },
            dateOfBirth: formData.dateOfBirth
              ? formData.dateOfBirth.format("YYYY-MM-DD")
              : null,
            phoneNumber: formData.phoneNumber || null,
            emailAddress: formData.emailAddress || null
          },
          contactAddress: {
            line1: formData?.contactAddress?.line1 || null,
            line2: formData?.contactAddress?.line2 || null,
            line3: formData?.contactAddress?.line3 || null,
            locality: formData?.contactAddress?.locality || null,
            province: formData?.contactAddress?.province || null,
            postalCode: formData?.contactAddress?.postalCode || null,
            country: formData?.contactAddress?.country
              ? JSON.parse(formData?.contactAddress?.country)
              : { referenceID: null, text: null },
            provinceReferenceID: null,
            uniqueReference: null
          },
          legalName: formData.legalName,
          policyholderType: formData.policyholderType
        })
        .then((response) => onSavePolicyholder(response))
        .catch(() => {
          // Check response errors here and return relevant messages.
          notification.error({
            message: "Unable to create policyholder, please try again."
          })
        })
    } else {
      notification.error({
        message: "Unable to submit form, missing required fields."
      })
    }
  }

  if (policyholder && !showForm) {
    return (
      <Row>
        <Col span={10} offset={7} style={{ padding: "50px 0 " }}>
          <PolicyholderCard
            policyholder={policyholder}
            onEdit={() => {
              setShowForm(true)
            }}
            onClear={() => {
              setPolicyholder(undefined)
            }}
          />
          <StyledButtonWrapper>
            {typeof onBack === "function" && (
              <Button
                style={{ marginRight: "15px" }}
                size="large"
                type="link"
                onClick={() => onBack()}
              >
                Go back
              </Button>
            )}
            <Button
              size="large"
              type="primary"
              onClick={() => onSavePolicyholder(policyholder)}
              loading={isLoading}
            >
              Save and continue
            </Button>
          </StyledButtonWrapper>
        </Col>
      </Row>
    )
  }

  if (!showForm) {
    return (
      <Row>
        <Col
          xs={{ span: 24, offset: 0 }}
          lg={{ span: 10, offset: 7 }}
          style={{ padding: "50px 0 " }}
        >
          <Card style={{ marginBottom: "15px" }}>
            <Card.Meta
              style={{ marginBottom: "15px" }}
              title={"Search Policyholders"}
              description="Use the field below to search policyholders, you can search by business name, legal name, firstname and lastname fields."
            />
            <PolicyholderLookup onSelectPolicyholder={setPolicyholder} />
          </Card>

          <StyledButtonWrapper>
            {typeof onBack === "function" && (
              <Button
                style={{ marginRight: "15px" }}
                size="large"
                type="link"
                onClick={() => onBack()}
              >
                Go back
              </Button>
            )}
            <Button size="large" onClick={() => setShowForm(true)}>
              Create new policyholder
            </Button>
          </StyledButtonWrapper>
        </Col>
      </Row>
    )
  }

  return (
    <PolicyholderForm
      onCloseForm={() => setShowForm(false)}
      onFinish={onFinish}
      policyholder={policyholder}
      isLoading={createPolicyholder.isLoading}
    />
  )
}

interface ICreatePolicyholderProps {
  policyholder?: IPolicyholder
  onSavePolicyholder: (data: IPolicyholder) => void
  isLoading?: boolean
  onBack?: () => void
  showCreateForm?: boolean
}

export default CreatePolicyholder
