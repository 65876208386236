import axios from "axios"
import { getApiUrl } from "utils/api/apiUrl"
import { IProduct } from "./use-get-product"

export const getProduct = (
  productRef: string,
  coverType: string
): Promise<IProduct | undefined> =>
  axios
    .create({
      timeout: 30000,
      baseURL: `${getApiUrl(window.location.host)}/v2`,
      headers: {
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    })
    .get(
      `${getApiUrl(
        window.location.host
      )}/v2/product?productReference=${productRef}&coverType=${coverType}`
    )
    .then((response) => response.data.data)
