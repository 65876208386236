import { Button, Result, Space, Typography } from "antd"
import { useHistory } from "react-router"
import styled from "styled-components"

const StyledExtraWrapper = styled.div`
  display: block;
  position: relative;
  width: 100%;
`

const LayoutError: React.FC<ILayoutErrorProps> = (props) => {
  const history = useHistory()

  return (
    <Result
      status="500"
      title={props.title}
      subTitle={props.subTitle}
      extra={
        <StyledExtraWrapper>
          <Space direction="vertical">
            <Button
              onClick={() => {
                if (typeof props.onClick === "function") {
                  props.onClick()
                  return
                }

                history.push(`/`)
              }}
              type="primary"
            >
              {props.buttonText || "Back to home"}
            </Button>
            {props.requestID && (
              <Typography.Text type="secondary">
                Request ID: {props.requestID}
              </Typography.Text>
            )}
          </Space>
        </StyledExtraWrapper>
      }
    />
  )
}

interface ILayoutErrorProps {
  title: string
  subTitle?: string
  buttonText?: string
  onClick?: () => void
  requestID?: string
}

export default LayoutError
