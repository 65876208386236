import {
  Alert,
  Button,
  Col,
  Form,
  Layout,
  notification,
  Row,
  Select
} from "antd"
import { Content } from "antd/lib/layout/layout"
import { LayoutLoading, LayoutPageContent, LayoutPageHeader } from "components"
import mapQuestionToField from "containers/quotes/services/map-question-to-fields"
import usePlatform from "contexts/platform/use-platform"
import { IQuestion, IQuestionAnswer } from "platform-client/types"
import { ICreateQuote } from "platform-client/types/quote"
import React from "react"
import { useMutation, useQuery } from "react-query"
import { RouteComponentProps, withRouter } from "react-router"

const CreateQuote: React.FC<NewQuoteProps> = (props) => {
  const platform = usePlatform()

  const newQuote = useMutation(
    (formattedData: ICreateQuote) => platform.quote.createQuote(formattedData),
    {
      onSuccess: (data) => {
        notification.success({
          message: "Quote created successfully.",
          onClick: () => {
            props.history?.push({
              pathname: `/quote/${data.quoteDetails.quote.id}`
            })
          }
        })
        setTimeout(() => {
          props.history?.push({
            pathname: `/quote/${data.quoteDetails.quote.id}`
          })
        }, 2000)
      },
      onError: (error, vars, context) => {
        notification.error({
          message: "Unable to create quote. Please try again."
        })
      }
    }
  )

  const {
    isLoading,
    isFetched,
    isError,
    error,
    data: productConfiguration
  } = useQuery(
    ["getProductConfiguration", props.match.params.productReference],
    async () => {
      const data = await platform.product.getProductConfigurationByReference(
        props.match.params.productReference
      )

      if (!data.newQuoteQuestionPage.questions.length) {
        await newQuote
          .mutateAsync({
            answers: [],
            partnerID: "",
            partnerReference: "",
            productReferenceID: props.match.params.productReference
          })
          .then((data) => {
            props.history?.push({
              pathname: `/quote/${data.quoteDetails.quote.id}`
            })
          })
          .catch((error) => {
            notification.error({
              message: "Unable to create quote. Please try again."
            })
          })
      }

      return data
    },
    {
      enabled: !!platform.token
    }
  )

  const listCompanies = useQuery(["listCompanies", "Broker"], () =>
    platform.company.listCompanies("Broker")
  )

  const [form] = Form.useForm()

  const handleFormSubmit = async (values: IFormValues) => {
    const answerData =
      productConfiguration?.newQuoteQuestionPage.questions.reduce(
        (acc: IQuestionAnswer[], question: IQuestion) => {
          return createAnswersArray(acc, question, values)
        },
        []
      )

    if (answerData) {
      newQuote.mutate({
        answers: answerData,
        partnerID: "",
        partnerReference: "",
        productReferenceID: props.match.params.productReference,
        ...(values.avt_assign_broker
          ? {
              introducer: {
                companyID: values.avt_assign_broker,
                role: "Broker"
              }
            }
          : {})
      })
    }
  }

  const renderQuestions =
    productConfiguration?.newQuoteQuestionPage.questions.map(mapQuestionToField)

  if (isLoading || !isFetched) {
    return <LayoutLoading />
  }

  if (isError) {
    return (
      <Alert
        message={
          error instanceof Error
            ? error.message
            : "Sorry something went wrong. please try again."
        }
        type="error"
      />
    )
  }

  return (
    <Layout>
      <LayoutPageHeader
        ghost={false}
        title={productConfiguration?.newQuoteQuestionPage.title || "New Quote"}
        onBack={() => props.history.goBack()}
      >
        {productConfiguration?.newQuoteQuestionPage.description && (
          <Content>
            <p>{productConfiguration?.newQuoteQuestionPage.description}</p>
          </Content>
        )}
      </LayoutPageHeader>
      <LayoutPageContent>
        {!productConfiguration?.newQuoteQuestionPage.questions.length && (
          <Alert
            message="No questions are required to create this quote, please wait whilst we create the quote for you. You will be redirected automatically."
            type="info"
          />
        )}
        {productConfiguration?.newQuoteQuestionPage.questions &&
          productConfiguration?.newQuoteQuestionPage.questions.length > 0 && (
            <Row justify="center">
              <Col span={12}>
                <Form
                  layout="vertical"
                  size="large"
                  initialValues={getInitialValues(
                    productConfiguration?.newQuoteQuestionPage.questions || []
                  )}
                  form={form}
                  onFinish={handleFormSubmit}
                  style={{ paddingTop: "24px", paddingBottom: "24px" }}
                >
                  {productConfiguration?.newQuoteQuestionPage.questions &&
                    renderQuestions}
                  <Form.Item
                    name={"avt_assign_broker"}
                    label={"Assign to Broker?"}
                  >
                    <Select
                      showSearch
                      size="large"
                      loading={listCompanies.isLoading}
                      options={listCompanies.data?.map((item) => {
                        return {
                          label: item.name,
                          value: item.id
                        }
                      })}
                      filterOption={(value, option) =>
                        String(option?.label)
                          .toLowerCase()
                          .indexOf(value.toLowerCase()) >= 0
                      }
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={newQuote.isLoading}
                    >
                      Create Quote
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          )}
      </LayoutPageContent>
    </Layout>
  )
}

const getInitialValues = (questions: IQuestion[]): IFormValues => {
  return questions.reduce(combineInitialValues, {})
}

const createAnswersArray = (
  acc: IQuestionAnswer[],
  question: IQuestion,
  values: IFormValues
): IQuestionAnswer[] => {
  const answer: IQuestionAnswer = {
    answer: null,
    questionReferenceID: question.referenceID,
    fields: []
  }

  switch (question.questionType) {
    case "Select":
      answer.answer = JSON.parse(String(values[question.referenceID]))
      break
    default:
      answer.answer = values[question.referenceID]
  }

  if (
    question.dataType === "Object" &&
    question.fields &&
    question.fields.length
  ) {
    answer.fields = question.fields.reduce(
      (acc: IQuestionAnswer[], question: IQuestion) => {
        return createAnswersArray(acc, question, values)
      },
      []
    )
  }

  acc.push(answer)

  return acc
}

const combineInitialValues = (acc: IFormValues, question: IQuestion) => {
  let combined = acc

  acc[question.referenceID] = question.existingValue

  if (
    question.dataType === "Object" &&
    question.fields &&
    question.fields.length
  ) {
    combined = {
      ...acc,
      ...question.fields.reduce(combineInitialValues, {})
    }
  }

  return combined
}

type NewQuoteProps = RouteComponentProps<{ productReference: string }>

interface IFormValues {
  [key: string]: unknown
}

export default withRouter(CreateQuote)
