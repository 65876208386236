import {
  IPolicyDetailEditSession,
  EModiferOwnerSource
} from "models/underwriting"

export const MockPolicyDetailEditSession: IPolicyDetailEditSession = {
  createdByID: "jonathan",
  dateCreated: "today",
  dateUpdated: "today",
  ownerID: "d013cebf-d173-46f2-8ce2-578738bf7b52",
  id: "4828a487-5e5f-8c8c-7855-2315db8f1ab3",
  policyDetailHeaderID: "9aff7561-c5a3-417d-7e99-73cb449e73e5",
  sourcePolicyDetailHistoryID: "cc35b58e-ecfc-2c29-66c5-1baea2924e21",
  policyDetail: {
    sections: [
      {
        tax: [
          {
            name: "IPT",
            referenceID: "avt_txr_ipt",
            ruleAmount: 0.12,
            order: 1,
            taxType: "Percentage",
            applicationMethod: "Cumulative",
            amount: null
          }
        ],
        commission: {
          commissionCalculationType: "NetPremiumInsurer",
          commissionType: "FixedRate",
          baseRuleAmount: 4.12,
          amount: null,
          name: "Commission",
          referenceID: "avt_comm_base",
          ruleAmount: 4.12,
          breakdown: {
            introducerAmount: { value: 0, currencyCode: "GBP " },
            introducerBaseRuleAmount: 0,
            introducerRuleAmount: 0.12,
            mgaAmount: { value: 0, currencyCode: "GBP " },
            mgaRuleAmount: 0
          }
        },
        netLoadingDiscounts: [],
        grossDiscounts: [],
        totalGrossPremium: {
          value: 400,
          currencyCode: "GBP"
        },
        totalNetPremium: {
          value: 500,
          currencyCode: "GBP"
        },
        totalTax: {
          value: 100,
          currencyCode: "GBP"
        },
        totalCommission: {
          value: 50,
          currencyCode: "GBP"
        },
        totalNetPremiumInsurer: null,
        totalNetDiscount: null,
        totalGrossDiscount: null,
        totalSubsectionPremium: {
          value: 650.0,
          currencyCode: "GBP"
        },
        totalCoverageFees: null,
        id: "cb36a050-d400-6a4a-3420-c02a5783a827",
        referenceID: "avt_sect_materialDamages",
        name: "Material Damages",
        enabled: true,
        schemeID: 180,
        schemeReferenceID: "avt_s_something_else",
        subsections: [
          {
            netLoadingDiscounts: [
              {
                amount: null,
                name: "Location Load",
                type: "Percentage",
                ruleAmount: 0.15,
                id: "0",
                order: 1,
                referenceID: "avt_ld_location",
                baseRuleAmount: 0.15
              },
              {
                amount: null,
                name: "Location Type Load",
                type: "Percentage",
                ruleAmount: 0.05,
                id: "1",
                order: 2,
                referenceID: "avt_ld_locationtype",
                baseRuleAmount: 0.05
              }
            ],
            riskScores: [{ name: "Meh", score: 9 }],
            totalPremium: null,
            totalNetDiscount: null,
            totalCoverageFees: null,
            id: "ed427dc3-490d-fcf8-bf21-c6c4a3255a51",
            referenceID: "avt_sect_materialDamages",
            name: "Material Damages",
            enabled: true,
            dependentOnSectionReference: null,
            coverages: [
              {
                premium: null,
                commission: null,
                rateMultiplier: 0.897749808103661,
                baseRateMultiplier: 0.897749808103661,
                baseRatedAmount: null,
                id: "dedd07cb-c88f-dd76-3574-e66bea0f17ba",
                referenceID: "avt_cov_buildings",
                name: "Buildings",
                enabled: true,
                locked: false,
                declaredAmount: null,
                sumInsured: {
                  value: 400,
                  currencyCode: "GBP"
                },
                type: "Rated",
                coverStartDateTime: "2021-09-09T10:50:37.9906098",
                coverEndDateTime: "2022-09-09T11:59:37.9906098",
                annualisedPremium: {
                  value: 0.0,
                  currencyCode: "GBP"
                },
                uplift: 115,
                prorataFraction: 1
              },
              {
                premium: null,
                commission: null,
                rateMultiplier: 0.915020297707534,
                baseRateMultiplier: 0.915020297707534,
                baseRatedAmount: null,
                id: "57c24e38-ed70-4f79-3119-02005ea0c223",
                referenceID: "avt_cov_tenantImprovements",
                name: "Tenants Improvements",
                enabled: true,
                declaredAmount: null,
                locked: false,
                sumInsured: {
                  value: 2200,
                  currencyCode: "GBP"
                },
                type: "Rated",
                coverStartDateTime: "2021-09-09T10:50:37.9906098",
                coverEndDateTime: "2022-09-09T11:59:37.9906098",
                annualisedPremium: {
                  value: 0.0,
                  currencyCode: "GBP"
                },
                uplift: 115,
                prorataFraction: 1
              },
              {
                premium: null,
                commission: null,
                rateMultiplier: 0.183484056584297,
                baseRateMultiplier: 0.183484056584297,
                baseRatedAmount: null,
                id: "f9561c3d-ccde-776e-3f60-2cc1e499ace9",
                referenceID: "avt_cov_contents",
                name: "Contents",
                enabled: true,
                locked: false,
                declaredAmount: null,
                sumInsured: {
                  value: 11100,
                  currencyCode: "GBP"
                },
                type: "Rated",
                coverStartDateTime: "2021-09-09T10:50:37.9906098",
                coverEndDateTime: "2022-09-09T11:59:37.9906098",
                annualisedPremium: {
                  value: 0.0,
                  currencyCode: "GBP"
                },
                uplift: 115,
                prorataFraction: 1
              },
              {
                premium: null,
                commission: null,
                rateMultiplier: 0.353898291175206,
                baseRateMultiplier: 0.353898291175206,
                baseRatedAmount: null,
                id: "71f7e300-9c3c-94c8-d811-96f901094bec",
                referenceID: "avt_cov_stock",
                name: "Stock",
                enabled: true,
                locked: false,
                declaredAmount: null,
                sumInsured: {
                  value: 213400,
                  currencyCode: "GBP"
                },
                type: "Rated",
                coverStartDateTime: "2021-09-09T10:50:37.9906098",
                coverEndDateTime: "2022-09-09T11:59:37.9906098",
                annualisedPremium: {
                  value: 0.0,
                  currencyCode: "GBP"
                },
                uplift: 115,
                prorataFraction: 1
              },
              {
                premium: null,
                commission: null,
                rateMultiplier: 0.583193438399208,
                baseRateMultiplier: 0.583193438399208,
                baseRatedAmount: null,
                id: "3ee30150-2a36-1f77-c40e-ba58420653a9",
                referenceID: "avt_cov_machineryplant",
                name: "Machinery & Plant",
                enabled: true,
                locked: false,
                declaredAmount: null,
                sumInsured: {
                  value: 12312341,
                  currencyCode: "GBP"
                },
                type: "Rated",
                coverStartDateTime: "2021-09-09T10:50:37.9906098",
                coverEndDateTime: "2022-09-09T11:59:37.9906098",
                annualisedPremium: {
                  value: 0.0,
                  currencyCode: "GBP"
                },
                uplift: 115,
                prorataFraction: 1
              }
            ],
            exposureIDs: [],
            coverStartDateTime: "2021-01-01 10:00:00",
            coverEndDateTime: "2021-01-01 10:00:00"
          }
        ],
        coverStartDateTime: "2021-01-01 10:00:00",
        coverEndDateTime: "2021-01-01 10:00:00"
      },
      {
        tax: [
          {
            name: "IPT",
            referenceID: "avt_txr_ipt",
            ruleAmount: 0.12,
            order: 1,
            taxType: "Percentage",
            applicationMethod: "Cumulative",
            amount: null
          }
        ],
        commission: {
          commissionCalculationType: "NetPremiumInsurer",
          commissionType: "FixedRate",
          baseRuleAmount: 4.12,
          amount: null,
          name: "Commission",
          referenceID: "avt_comm_base",
          ruleAmount: 4.12,
          breakdown: {
            introducerAmount: { value: 0, currencyCode: "GBP " },
            introducerBaseRuleAmount: 0,
            introducerRuleAmount: 0.12,
            mgaAmount: { value: 0, currencyCode: "GBP " },
            mgaRuleAmount: 0
          }
        },
        netLoadingDiscounts: [],
        grossDiscounts: [],
        totalGrossPremium: {
          value: 40,
          currencyCode: "GBP"
        },
        totalNetPremium: {
          value: 50,
          currencyCode: "GBP"
        },
        totalTax: {
          value: 10,
          currencyCode: "GBP"
        },
        totalCommission: {
          value: 50,
          currencyCode: "GBP"
        },
        totalNetPremiumInsurer: null,
        totalNetDiscount: null,
        totalGrossDiscount: null,
        totalSubsectionPremium: {
          value: 250.0,
          currencyCode: "GBP"
        },
        totalCoverageFees: null,
        id: "eb20ec73-2528-f53f-dffd-6c4dcdd0712f",
        referenceID: "avt_sect_employersliability",
        name: "Employers' Liability",
        enabled: true,
        schemeID: 180,
        schemeReferenceID: "avt_s_something",
        subsections: [
          {
            netLoadingDiscounts: [],
            riskScores: [{ name: "Meh", score: 9 }],
            totalPremium: null,
            totalNetDiscount: null,
            totalCoverageFees: null,
            id: "1df0c1ee-aeb6-39bf-bd82-f354355c897c",
            referenceID: "avt_sect_employersliability",
            name: "Employers' Liability",
            enabled: true,
            dependentOnSectionReference: null,
            coverages: [
              {
                premium: null,
                commission: null,
                rateMultiplier: 0.937927516614053,
                baseRateMultiplier: 0.937927516614053,
                baseRatedAmount: null,
                id: "8c23cbe9-25a3-2cab-85d5-4e99871fbcee",
                referenceID: "avt_cov_clerical",
                name: "Clerical",
                enabled: true,
                locked: false,
                declaredAmount: null,
                sumInsured: {
                  value: 400,
                  currencyCode: "GBP"
                },
                type: "Rated",
                coverStartDateTime: "2021-09-09T10:50:37.9906098",
                coverEndDateTime: "2022-09-09T11:59:37.9906098",
                annualisedPremium: {
                  value: 0.0,
                  currencyCode: "GBP"
                },
                uplift: 115,
                prorataFraction: 1
              },
              {
                premium: null,
                commission: null,
                rateMultiplier: 0.919331271629469,
                baseRateMultiplier: 0.919331271629469,
                baseRatedAmount: null,
                id: "f7f73af8-fad1-3e8a-9e11-a6b08db74c67",
                referenceID: "avt_cov_manual",
                name: "Manual",
                enabled: true,
                locked: false,
                declaredAmount: null,
                sumInsured: {
                  value: 220,
                  currencyCode: "GBP"
                },
                type: "Rated",
                coverStartDateTime: "2021-09-09T10:50:37.9906098",
                coverEndDateTime: "2022-09-09T11:59:37.9906098",
                annualisedPremium: {
                  value: 0.0,
                  currencyCode: "GBP"
                },
                uplift: 115,
                prorataFraction: 1
              },
              {
                premium: null,
                commission: null,
                rateMultiplier: 0.160468985866974,
                baseRateMultiplier: 0.160468985866974,
                baseRatedAmount: null,
                id: "d230184e-bb3b-5ac6-5ffd-b16c074e6e11",
                referenceID: "avt_cov_workaway",
                name: "Work Away",
                enabled: true,
                locked: false,
                declaredAmount: null,
                sumInsured: {
                  value: 1240,
                  currencyCode: "GBP"
                },
                type: "Rated",
                coverStartDateTime: "2021-09-09T10:50:37.9906098",
                coverEndDateTime: "2022-09-09T11:59:37.9906098",
                annualisedPremium: {
                  value: 0.0,
                  currencyCode: "GBP"
                },
                uplift: 115,
                prorataFraction: 1
              },
              {
                premium: null,
                commission: null,
                rateMultiplier: 0.998122139832993,
                baseRateMultiplier: 0.998122139832993,
                baseRatedAmount: null,
                id: "670f877d-a851-1fc9-2db0-e0f708f19eee",
                referenceID: "avt_cov_losc",
                name: "LOSC",
                locked: false,
                enabled: true,
                declaredAmount: null,
                sumInsured: {
                  value: 2230,
                  currencyCode: "GBP"
                },
                type: "Rated",
                coverStartDateTime: "2021-09-09T10:50:37.9906098",
                coverEndDateTime: "2022-09-09T11:59:37.9906098",
                annualisedPremium: {
                  value: 0.0,
                  currencyCode: "GBP"
                },
                uplift: 115,
                prorataFraction: 1
              }
            ],
            exposureIDs: [],
            coverStartDateTime: "2021-01-01 10:00:00",
            coverEndDateTime: "2021-01-01 10:00:00"
          }
        ],
        coverStartDateTime: "2021-01-01 10:00:00",
        coverEndDateTime: "2021-01-01 10:00:00"
      }
    ],
    totalGrossPremium: null,
    totalNetPremium: null,
    totalSectionTax: null,
    totalSectionTaxAggregate: [],
    totalCommission: null,
    totalNetPremiumInsurer: null,
    totalNetDiscount: null,
    totalGrossDiscount: null,
    totalIntroducerCommission: null,
    fees: [
      {
        name: "Blessing of the sith fee",
        id: "",
        referenceID: "",
        gross: {
          value: 3000,
          currencyCode: "GBP"
        },
        order: 3,
        net: {
          value: 3000,
          currencyCode: "GBP"
        },
        tax: {
          taxRuleID: "Oh",
          taxRuleReferenceID: "Meh",
          total: {
            value: 300,
            currencyCode: "GBP"
          },
          taxes: [
            {
              amount: {
                value: 300,
                currencyCode: "GBP"
              },
              name: "Evil Tax",
              order: 0,
              referenceID: "evil_tax",
              ruleAmount: 3,
              applicationMethod: "Fixed"
            }
          ]
        }
      },
      {
        name: "Admin Fee",
        id: "",
        referenceID: "",
        gross: {
          value: 3000,
          currencyCode: "GBP"
        },
        order: 3,
        net: {
          value: 3000,
          currencyCode: "GBP"
        },
        tax: {
          taxRuleID: "Oh",
          taxRuleReferenceID: "Meh",
          total: {
            value: 300,
            currencyCode: "GBP"
          },
          taxes: [
            {
              amount: {
                value: 300,
                currencyCode: "GBP"
              },
              name: "Evil Tax",
              order: 0,
              referenceID: "evil_tax",
              ruleAmount: 3,
              applicationMethod: "Fixed"
            }
          ]
        }
      }
    ],
    totalIncludingFees: {
      value: 5500,
      currencyCode: "GBP"
    },
    productID: 40063,
    productReferenceID: "diamond_pr_platinumlite",
    productVersion: 1,
    version: 1,
    declineReasons: [],
    referrals: [],
    endorsements: [
      {
        id: "id",
        systemEndorsementID: "test",
        referenceID: "endorsement-1",
        title: "Example Endorsement 1 Section",
        providerReferenceID: "ID - Example Endorsement 1 Section",
        text: "",
        modifierType: "Library",
        modifierOwners: [
          {
            componentID: "cb36a050-d400-6a4a-3420-c02a5783a827",
            componentType: "Section",
            enabled: true,
            source: EModiferOwnerSource.Ratings
          }
        ]
      },
      {
        id: "id",
        systemEndorsementID: "test",
        referenceID: "endorsement-2",
        title: "Example Endorsement 2 Global Manual",
        providerReferenceID: "ID - Example Endorsement 2 Global Manual",
        text: "",
        modifierType: "Library",
        modifierOwners: [
          {
            componentID: null,
            componentType: "None",
            enabled: true,
            source: EModiferOwnerSource.Manual
          }
        ]
      },
      {
        id: "id",
        systemEndorsementID: "test",
        referenceID: "endorsement-3",
        title: "Example Endorsement 3 Rating",
        providerReferenceID: "ID - Example Endorsement 3 Rating",
        text: "",
        modifierType: "System",
        modifierOwners: [
          {
            componentID: "cb36a050-d400-6a4a-3420-c02a5783a827",
            componentType: "Section",
            enabled: false,
            source: EModiferOwnerSource.Ratings
          }
        ]
      },
      {
        id: "id",
        systemEndorsementID: "test",
        referenceID: "endorsement-4",
        title: "Example Endorsement 4 Section Rules",
        providerReferenceID: "ID - Example Endorsement 4 Section Rules",
        text: "",
        modifierType: "System",
        modifierOwners: [
          {
            componentID: "eb20ec73-2528-f53f-dffd-6c4dcdd0712f",
            componentType: "Section",
            enabled: false,
            source: EModiferOwnerSource.Rules
          }
        ]
      },
      {
        id: "id",
        systemEndorsementID: "test",
        referenceID: "endorsement-5",
        title: "Example Endorsement 5 Section Rules",
        providerReferenceID: "ID - Example Endorsement 5 Section Rules",
        text: "",
        modifierType: "System",
        modifierOwners: [
          {
            componentID: "eb20ec73-2528-f53f-dffd-6c4dcdd0712f",
            componentType: "Section",
            enabled: true,
            source: EModiferOwnerSource.Rules
          }
        ]
      },
      {
        id: "id",
        systemEndorsementID: "test",
        referenceID: "endorsement-6",
        title: "Example Endorsement 6 Subsection",
        providerReferenceID: "ID - Example Endorsement 6 Subsection",
        text: "",
        modifierType: "System",
        modifierOwners: [
          {
            componentID: "1df0c1ee-aeb6-39bf-bd82-f354355c897c",
            componentType: "Subsection",
            enabled: true,
            source: EModiferOwnerSource.Manual
          }
        ]
      },
      {
        id: "id",
        systemEndorsementID: "test",
        referenceID: "endorsement-7",
        title: "Example Endorsement 7 Coverage",
        providerReferenceID: "ID - Example Endorsement 7 Coverage",
        text: "",
        modifierType: "System",
        modifierOwners: [
          {
            componentID: "d230184e-bb3b-5ac6-5ffd-b16c074e6e11",
            componentType: "Coverage",
            enabled: true,
            source: EModiferOwnerSource.Manual
          }
        ]
      },
      {
        id: "id",
        systemEndorsementID: "test",
        referenceID: "endorsement-8",
        title: "Example Endorsement 8 Global",
        providerReferenceID: "ID - Example Endorsement 8 Global",
        text: "",
        modifierType: "System",
        modifierOwners: [
          {
            componentID: null,
            componentType: "None",
            enabled: true,
            source: EModiferOwnerSource.Manual
          }
        ]
      },
      {
        id: "id",
        systemEndorsementID: "test",
        referenceID: "endorsement-9",
        title: "Example Endorsement 9 Global",
        providerReferenceID: "ID - Example Endorsement 9 Global",
        text: "",
        modifierType: "System",
        modifierOwners: [
          {
            componentID: null,
            componentType: "None",
            enabled: false,
            source: EModiferOwnerSource.Manual
          }
        ]
      }
    ],
    excesses: [
      {
        id: "id",
        systemExcessID: "test",
        exposureID: "excess-1",
        title: "Example Excess 1 Section",
        text: "",
        modifierType: "Library",
        modifierOwners: [
          {
            componentID: "cb36a050-d400-6a4a-3420-c02a5783a827",
            componentType: "Section",
            enabled: true,
            source: EModiferOwnerSource.Ratings
          }
        ],
        claimTypeID: "claim-type-id",
        excessType: "Compulsory",
        amount: {
          value: 1000,
          currencyCode: "GBP"
        }
      }
    ]
  },
  risk: {
    core: {
      coverStartDate: null,
      coverEndDate: null,
      productID: 40063,
      productReferenceID: "diamond_pr_platinumlite",
      excesses: {
        voluntaryExcess: null,
        compulsoryExcess: null,
        totalExcess: null,
        totalExcessSelection: null
      },
      proposer: {
        personName: {
          firstName: null,
          lastName: null,
          title: null
        },
        emailAddress: null
      },
      businessDescription: null
    },
    exposures: []
  },
  state: "Active",
  version: 1
}
