import { useMemo } from "react"
import JoditEditor from "jodit-react"

interface IRichTextEditorProps {
  content: string
  setContent: (newContent: string) => void
  placeholder?: string
}

export const RichTextEditor: React.FC<IRichTextEditorProps> = ({
  content,
  setContent,
  placeholder = ""
}) => {
  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/doc/
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      toolbarAdaptive: false,
      enter: "BR",
      buttons: [
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "ul",
        "ol",
        "link"
      ],
      tabIndex: 0,
      placeholder: placeholder || "Start typing here..."
    }),
    [placeholder]
  )

  return (
    <JoditEditor
      test-id="WYSIWYG_Editor"
      value={content}
      config={config}
      onChange={(newContent) => setContent(newContent)}
    />
  )
}

export default RichTextEditor
