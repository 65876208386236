import React from "react"
import { Input } from "antd"
import { debounce } from "debounce"

const { Search } = Input

export const SearchComponent: React.FC<ISearchComponent> = (props) => {
  const sendRequest = (value: string) => null

  return (
    <Search
      style={{ width: 425 }}
      onSearch={debounce((value: string) => {
        sendRequest(value)
      }, 200)}
      placeholder="search by INVOICE PROPERTIES"
      enterButton="Search"
      defaultValue={props.searchValue || ""}
      allowClear={true}
    />
  )
}

interface ISearchComponent {
  searchValue: string | undefined | null
  setSearchValue: (value: string) => void
}
