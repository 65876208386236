import axios from "axios"
import { createContext } from "react"
import { IPlatformClient } from "./client"

const stub = (): never => {
  throw new Error(
    "You forgot to wrap your component in <PlatformContext.Provider>"
  )
}

export const initialPlatformState = {
  token: undefined,
  client: axios.create(),
  startEditSession: stub,
  getActiveEditSessions: stub,
  saveEditSession: stub,
  rerateEditSession: stub,
  priceEditSession: stub,
  discardEditSession: stub,
  getEditSession: stub,
  product: {
    getProductConfigurationById: stub,
    getProductConfigurationByReference: stub,
    getProductById: stub,
    getProductDeclineReasons: stub,
    getProducts: stub
  },
  quoteBuilder: {
    addCoverage: stub,
    updateCoverage: stub,
    deleteCoverage: stub,
    updateCoverageCoverPeriod: stub,
    addCustomCoverage: stub,
    deleteExposure: stub,
    addFee: stub,
    updateFee: stub,
    deleteFee: stub,
    addSection: stub,
    updateSection: stub,
    deleteSection: stub,
    addSubsection: stub,
    updateSectionCoverPeriod: stub,
    updateSubsection: stub,
    deleteSubsection: stub,
    updateSubsectionCoverPeriod: stub,
    updateSubsectionExposures: stub,
    addEndorsement: stub,
    enableEndorsement: stub,
    disableEndorsement: stub,
    addExcess: stub,
    enableExcess: stub,
    disableExcess: stub,
    forceAddCustomExcess: stub,
    forceRemoveCustomExcess: stub,
    toggleSection: stub,
    toggleSubsection: stub,
    toggleCoverage: stub,
    addCustomEndorsementForce: stub,
    removeCustomEndorsementForce: stub,
    lockCoverage: stub
  },
  quote: {
    getQuote: stub,
    getQuoteActions: stub,
    revokeManualReferral: stub,
    nominateQuote: stub,
    getQuoteProduct: stub,
    confirmQuote: stub,
    getDocuments: stub,
    getQuoteEvents: stub,
    createQuote: stub,
    convertQuote: stub,
    quoteCompanies: stub,
    updateReferral: stub,
    confirmReferralQuote: stub,
    declineReferralQuote: stub,
    getAnswers: stub,
    getOracleResults: stub,
    referQuote: stub,
    overridePremium: stub,
    getNotes: stub,
    createNote: stub,
    extendExpiry: stub
  },
  policy: {
    getPolicy: stub,
    cancelPolicy: stub,
    previewCancellation: stub,
    getPolicies: stub,
    getPolicySearch: stub,
    getPolicyCompanies: stub,
    getPolicyDocuments: stub,
    cancellationReasons: stub,
    getPolicyHistorySummary: stub,
    getNotes: stub,
    createNote: stub,
    getAnswers: stub,
    getOracleResults: stub,
    updatePaymentDetails: stub
  },
  endorsement: {
    listEndorsements: stub,
    createEndorsement: stub,
    updateEndorsement: stub,
    deleteEndorsement: stub,
    createEndorsementVersion: stub
  },
  excess: {
    listExcesses: stub,
    createExcess: stub,
    updateExcess: stub,
    deleteExcess: stub,
    createExcessVersion: stub
  },
  invoice: {
    listInvoices: stub,
    setInvoiceStatus: stub
  },
  claims: {
    getByReferenceId: stub,
    getClaimByID: stub,
    addClaim: stub,
    editClaim: stub,
    getProductClaimTypes: stub
  },
  company: {
    listCompanies: stub,
    createCompany: stub,
    getCompany: stub,
    updateCompany: stub,
    listCompanyUsers: stub
  },
  coverage: {
    listCoverageDefinitions: stub
  },
  tobes: {
    getQuestionPage: stub,
    tobesStart: stub,
    tobesSubmit: stub
  },
  opportunity: {
    createOpportunity: stub,
    createMTAOpportunity: stub,
    getOpportunity: stub,
    variantDuplicate: stub,
    listOpportunities: stub,
    createNote: stub,
    getNotes: stub
  },
  policyholders: {
    createPolicyholder: stub,
    updatePolicyholder: stub,
    getPolicyholder: stub,
    listPolicyholders: stub,
    searchPolicyholders: stub
  },
  payOnAccount: {
    bindQuote: stub
  },
  variant: {
    getEditSession: stub,
    completeSession: stub,
    validateSession: stub,
    discardSession: stub,
    getActiveEditSessions: stub,
    startEditSession: stub,
    getAnswers: stub
  },
  user: {
    getCurrentUser: stub,
    inviteUser: stub,
    editUserEmail: stub,
    editUserEmailExistingAccount: stub,
    validateEditUserEmail: stub,
    search: stub,
    blacklistPhoneNumber: stub,
    removePhoneNumberFromBlacklist: stub,
    phoneNumberIsBlacklisted: stub
  },
  policyHolder: {
    getPolicyHolder: stub
  },
  commission: {
    setCommissionOverride: stub,
    getCommissionSetup: stub
  },
  difference: {
    mtaQuote: stub,
    mtaVariant: stub,
    policyHistory: stub
  },
  document: {
    uploadDocument: stub
  },
  organizationManagement: {
    listOrgClaims: stub,
    listOrgRoles: stub
  },
  quickQuote: {
    createQuickQuote: stub,
    convertQuickQuote: stub
  },
  eric: {
    uploadCsv: stub,
    getPolicyList: stub,
    sendEmail: stub
  },
  report: {
    getPaymentPlansPastDue: stub,
    getTransactionalEmails: stub,
    downloadTransactionalEmails: stub,
    getAllTransactionalSms: stub,
    downloadAllTransactionalSms: stub
  },
  fatZebra: {
    getConfig: stub,
    createPaymentIntentVerification: stub
  }
}

const PlatformContext = createContext<IPlatformClient>(initialPlatformState)

export default PlatformContext
