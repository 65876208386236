import { currencyPenceToPounds } from "../currency-pence-to-pounds"

/**
 * Convert our currency object, into a human readable format. A string with the
 * correct currency symbol, and locale formatting.
 * For example: { value: '5000', currencyCode: 'GBP' } = "£5,000"
 * @param {number} value - The monetary value in cents or pence. The major value / 100.
 * @param {string} currencyCode - The three letter symbol for a specific currency.
 * @returns {string}
 */
export function currencyToHumanReadable(
  money: IMoney,
  isPennies = true, // isMajorCurrency (inverse of)
  hideZeroPennies = true // hideZeroMinorCurrency
): string {
  const { value = 0, currencyCode = "GBP" } = money || {}

  const poundsPenceValue: number | string = isPennies
    ? currencyPenceToPounds(value)
    : value
  const locale = convertLocale(currencyCode)
  const localeCurrencyCode = convertCurrencyCode(currencyCode)

  const decimal = Number(poundsPenceValue) % 1

  const formattingConfig: Intl.NumberFormatOptions = {
    style: "currency",
    currency: localeCurrencyCode
  }

  if (hideZeroPennies) {
    formattingConfig.minimumFractionDigits = decimal === 0 ? 0 : 2
  }

  if (typeof Intl !== "undefined" && Intl.NumberFormat) {
    const formatter = new Intl.NumberFormat(locale, formattingConfig)
    return formatter.format(Number(poundsPenceValue))
  } else {
    return Number(poundsPenceValue).toLocaleString(locale, formattingConfig)
  }
}

// in case we will start displaying different currencies
// these methods below are for automatically customising options for the 'toLocaleString() method.
// Add additional cases for additional currencyCodes

export function convertLocale(code: string): string {
  switch (code) {
    case "GBP":
      return "en-GB"
    case "AUD":
      return "en-AU"
    case "USD":
      return "en-US"
    default:
      return "en-GB"
  }
}

function convertCurrencyCode(code: string) {
  switch (code) {
    default:
      return code
  }
}

interface IMoney {
  value: number
  currencyCode: string
}
