import React, { useCallback, useState } from "react"
import { Dropdown, Menu, Table } from "antd"
import formatDate from "dateformat"
import { MoneyDisplay } from "components/display/MoneyDisplay"
import {
  TPayment,
  TPolicyPaymentPlan,
  TPolicyScheduledPayment
} from "models/policies/policyPaymentPlan"
import styled from "styled-components"
import Column from "antd/lib/table/Column"
import {
  DollarOutlined,
  DownloadOutlined,
  MoreOutlined,
  SendOutlined
} from "@ant-design/icons"
import { PaymentPlanStatus, ScheduledPaymentStatus } from "."
import { Nullable } from "models/underwriting"
import { ConfirmEmailModal } from "../../../../../../components/ConfirmEmailModal"
import { IMoney } from "platform-client/types"
import {
  EOpusPermission,
  useOpusPermissions
} from "contexts/authorization/hooks/use-opus-permissions"
import moment from "moment"

const Styles = styled.div`
  .scheduled-payment-row td {
    border-top: 1px solid #ccc;
  }
`

const StyledPaymentTable = styled(Table)`
  border-left: 2px solid #1890ff;
  margin-left: 7px;

  th {
    font-style: italic;
  }
`

const ScheduledPaymentsTable: React.FC<IScheduledPaymentsTableProps> = ({
  paymentPlan,
  scheduledPaymentRows,
  isProcessing,
  payScheduledPayment,
  downloadReceipt,
  emailReceipt,
  isDownloading,
  isEmailing,
  linkedEmailAddress
}): JSX.Element => {
  const [payment, setPayment] = useState<Nullable<TPayment>>(null)

  const onSend = async (emailAddress: string) => {
    if (payment?.id) {
      await emailReceipt(payment.id, emailAddress)
      setPayment(null)
    }
  }

  const { havePermission: isAdmin } = useOpusPermissions(
    EOpusPermission.aventusadmin,
    "true"
  )

  const paymentCanBeProcessed = useCallback(
    (payment: TPolicyScheduledPayment) => {
      return moment(payment.paymentDate).startOf("day") >
        moment.utc().startOf("day") && !isAdmin
        ? false
        : true
    },
    [isAdmin]
  )

  return (
    <>
      <Styles>
        <Table
          rowClassName="scheduled-payment-row"
          loading={isProcessing}
          pagination={false}
          dataSource={scheduledPaymentRows}
          expandable={{
            defaultExpandAllRows: false,
            expandedRowRender: (record) => {
              return (
                <StyledPaymentTable
                  pagination={false}
                  dataSource={record.payments}
                  loading={isDownloading || isEmailing}
                >
                  <Column
                    title="Timestamp"
                    dataIndex="localTimestamp"
                    render={(date: string) =>
                      formatDate(new Date(date), "dd/mm/yyyy hh:MM:ss TT")
                    }
                  />
                  <Column
                    title="Amount"
                    dataIndex="amount"
                    render={(amount: IMoney) => <MoneyDisplay value={amount} />}
                  />
                  <Column title="Reference" dataIndex="paymentReference" />
                  <Column title="Status" dataIndex="paymentStatus" />
                  <Column
                    title="Failure Reason"
                    dataIndex="providerFailureMessage"
                  />
                  <Column
                    title="Actions"
                    render={(record: TPayment) => (
                      <>
                        {record.paymentStatus === "Settled" && (
                          <Dropdown
                            overlay={
                              <Menu>
                                <Menu.Item
                                  key="download"
                                  onClick={() => downloadReceipt(record.id)}
                                >
                                  <DownloadOutlined /> Download Receipt
                                </Menu.Item>
                                <Menu.Item
                                  key="email"
                                  onClick={() => setPayment(record)}
                                >
                                  <SendOutlined /> Email Receipt
                                </Menu.Item>
                              </Menu>
                            }
                          >
                            <MoreOutlined
                              style={{
                                cursor: "pointer",
                                padding: "0 5px",
                                fontSize: 19
                              }}
                            />
                          </Dropdown>
                        )}
                      </>
                    )}
                  />
                </StyledPaymentTable>
              )
            },
            rowExpandable: (record) => Boolean(record.attemptCount > 0)
          }}
          rowKey={(scheduledPaymentRows) => scheduledPaymentRows.id}
        >
          <Column
            title="Scheduled Date"
            dataIndex="paymentDate"
            render={(date: string) => formatDate(new Date(date), "dd/mm/yyyy")}
          />
          <Column
            title="Amount"
            dataIndex="amount"
            render={(amount: IMoney) => <MoneyDisplay value={amount} />}
          />
          <Column title="Status" dataIndex="status" />
          <Column title="Payment Attempts" dataIndex="attemptCount" />
          <Column
            title="Action"
            key="action"
            render={(record: TPolicyScheduledPayment) => {
              return paymentPlan?.status !== PaymentPlanStatus.Cancelled &&
                (record.status === ScheduledPaymentStatus.Uncollectible ||
                  record.status === ScheduledPaymentStatus.Scheduled) &&
                paymentCanBeProcessed(record) ? (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        key="process"
                        onClick={() => payScheduledPayment(record.id)}
                      >
                        <DollarOutlined /> Process Payment
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <MoreOutlined
                    style={{
                      cursor: "pointer",
                      padding: "0 5px",
                      fontSize: 19
                    }}
                  />
                </Dropdown>
              ) : null
            }}
          />
        </Table>
        <ConfirmEmailModal
          title={`Email Receipt - Payment Ref: ${payment?.paymentReference}`}
          description="Please provide the email address you would like to send the
          transaction receipt to. This has been auto-filled with the address
          stored against the policy."
          emailAddress={linkedEmailAddress}
          isVisible={!!payment?.id}
          onCancel={(e) => setPayment(null)}
          onSend={onSend}
          isLoading={isEmailing}
        />
      </Styles>
    </>
  )
}

interface IScheduledPaymentsTableProps {
  isProcessing: boolean
  paymentPlan: TPolicyPaymentPlan | undefined
  scheduledPaymentRows: TPolicyScheduledPayment[]
  payScheduledPayment: (scheduledPaymentID: string) => void
  downloadReceipt: (paymentId: string) => Promise<void>
  emailReceipt: (paymentId: string, emailAddress: string) => Promise<void>
  isDownloading: boolean
  isEmailing: boolean
  linkedEmailAddress: string
}

export default ScheduledPaymentsTable
