import React from "react"
import styled from "styled-components"

const StyledContainer = styled.div`
  width: 100%;
  ${(props: ILayoutContainer) =>
    !props.fluid ? "max-width: 1360px;" : "max-width: 100%;"}
  margin: 0 auto;
  ${(props: ILayoutContainer) =>
    props.background && `background-color: ${props.background};`}
  ${(props: ILayoutContainer) =>
    props.transparent && `background-color: transparent`}
`

export const LayoutContainer: React.FunctionComponent<ILayoutContainer> = (
  props
) => {
  return <StyledContainer {...props}>{props.children}</StyledContainer>
}

export interface ILayoutContainer {
  children?: React.ReactNode
  fluid?: boolean
  transparent?: boolean
  background?: string
}
