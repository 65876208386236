import jwt_decode from "jwt-decode"

export const decodeJWT = (token: string): IDecodeJWT => {
  return jwt_decode(token)
}

export type IDecodeJWT = {
  aud: string[]
  azp: string
  quote: string
  exp: number
  iat: number
  insuranceorgid: string
  iss: string
  nameid: string
  nbf: number
  orgid: string
  orgname: string
  policysearch: string
  subjectorgid: string
  tokentypes: string
  embargoesmanagement: string
  processreferral: string
  usermanagement: string
  [key: string]: string | number | string[]
}
