import React, { ReactNode } from "react"
import styled from "styled-components"
import Cell from "../Cell"

const RowTypeStyles: TRowTypeStyles = {
  header: {
    minHeight: "60px",
    backgroundColor: "#fafafa"
  },
  subHeader: {
    minHeight: "50px",
    backgroundColor: "#f0f0f0"
  },
  default: {
    minHeight: "50px",
    backgroundColor: "#fff"
  },
  halftable: {
    minHeight: "50px",
    backgroundColor: "#fff"
  },
  meta: {
    minHeight: "60px",
    backgroundColor: "#fafafa"
  },
  sticky: {
    minHeight: "60px",
    backgroundColor: "#fff"
  },
  removed: {
    minHeight: "50px",
    backgroundColor: "#f8e3e7"
  },
  added: {
    minHeight: "50px",
    backgroundColor: "#f3ffe3"
  },
  changesmade: {
    minHeight: "50px",
    backgroundColor: "#f7f9de"
  }
}

const StyledRow = styled.div.attrs<
  {
    $type: TRowType
  },
  {
    minHeight: string
    backgroundColor: string
  }
>((props) => RowTypeStyles[props.$type])<{
  $type: TRowType
  $updating: boolean
  $disabled: boolean
}>`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100%;
  min-width: 100%;
  min-height: ${(props) => props.minHeight};
  background-color: ${(props) => props.backgroundColor};
  opacity: ${(props) => (props.$updating ? 0.6 : 1)};
  transition: background 0.2s linear;

  :hover {
    background-color: ${(props) =>
      ["meta", "default", "halftable"].includes(props.$type)
        ? "#F8F8F8"
        : props.backgroundColor};

    .addIcon {
      color: #20cb2b;
    }

    .deleteIcon,
    .minusIcon {
      color: #a21212;
    }

    .lockIcon {
      color: #a39224;
    }

    .saveIcon,
    .adjustIcon,
    .calendarIcon {
      color: #1187ee;
    }
  }
`

const Row: React.FC<IRow> = ({
  name,
  type = "default",
  toggle,
  reference,
  values = [null], // default to one spanned column in rows
  control,
  disabled = false,
  updating = false,
  isFirstRow = false,
  highLight,
  children,
  referenceWidth,
  valueWidth,
  customValueSpan
}): JSX.Element => {
  const hideTopBorder =
    isFirstRow || type === "header" || type === "subHeader" || type === "meta"

  const renderValueCells = (values: TValueCell[]) => {
    // We always expect 5 value cells.
    // The cells can be empty but they must be present
    // in the array.

    const firstCellSpan = 6 - values.length

    const cells: JSX.Element[] = []
    values.forEach((value, index) => {
      const isHighLighted =
        highLight?.values && highLight?.values.includes(index)

      //However if we want a custom span we will use that instead
      const spanLength = customValueSpan
        ? customValueSpan
        : index === 0
        ? firstCellSpan
        : 1

      cells.push(
        <Cell
          span={spanLength}
          key={`value-${index}`}
          type="value"
          highlighted={isHighLighted}
          disabled={disabled}
          hideTopBorder={hideTopBorder}
          width={valueWidth}
        >
          {values[index] || ""}
        </Cell>
      )
    })
    return cells
  }

  const rowChildren = children ?? renderValueCells(values)
  return (
    <StyledRow $disabled={disabled} $updating={updating} $type={type}>
      {
        <Cell
          key="toggle"
          type="toggle"
          hideTopBorder={type === "halftable" || hideTopBorder}
          disabled={disabled}
          {...(highLight?.collapse ? { highlighted: highLight?.collapse } : {})}
        >
          {toggle || ""}
        </Cell>
      }
      {
        <Cell
          key="reference"
          type="reference"
          hideTopBorder={type === "halftable" || hideTopBorder}
          disabled={disabled}
          width={referenceWidth}
          {...(highLight?.reference
            ? { highlighted: highLight?.reference }
            : {})}
        >
          {reference || ""}
        </Cell>
      }
      {rowChildren}
      {
        <Cell
          key="control"
          type="control"
          hideTopBorder={hideTopBorder}
          {...(highLight?.control ? { highlighted: highLight?.control } : {})}
        >
          {control || ""}
        </Cell>
      }
    </StyledRow>
  )
}

type TRowType =
  | "header"
  | "subHeader"
  | "default"
  | "meta"
  | "sticky"
  | "halftable"
  | "removed"
  | "added"
  | "changesmade"

type TRowTypeStyles = Record<
  TRowType,
  { minHeight: string; backgroundColor: string }
>
type TValueCell = ReactNode | undefined

export interface IRow {
  name?: string
  type?: TRowType
  toggle?: ReactNode | undefined
  reference?: ReactNode | undefined
  values?: TValueCell[]
  control?: ReactNode
  updating?: boolean
  disabled?: boolean
  isFirstRow?: boolean
  highLight?: {
    control?: boolean
    reference?: boolean
    collapse?: boolean
    values?: number[]
  }
  referenceWidth?: number
  valueWidth?: number
  customValueSpan?: number
}

export default Row
