import React from "react"
import Row from "components/Console/Row"
import CellContentText from "components/Console/CellContent/Text"
import CellContentTitle from "components/Console/CellContent/Title"
import CellContentMoney from "components/Console/CellContent/Money"
import CellContentRateInput from "components/Console/CellContent/RateInput"
import CellContentRate, { RateType } from "components/Console/CellContent/Rate"
import { Table } from "components/Console/Table"
import {
  FinanceCommissionType,
  IFinanceCommission,
  IMoney,
  Nullable
} from "models/underwriting"
import { TPlatformResponse } from "contexts/platform/client"
import { IOrgSettings } from "contexts/organisation/context"
import { decimalToPercentage } from "utils/pocketknife/converters"
import { useSection } from "components/Console/Section/use-section"
import { SectionTypes } from "platform-client/types"

export const COMMISSION_RATE_TYPES: Record<FinanceCommissionType, RateType> = {
  Percentage: "Percentage",
  FixedRate: "Fixed"
}

const Commission: React.FC<ICommission> = ({
  reference = "Commission Adjustment",
  summaryClosedText,
  summaryOpenText,
  commission,
  totalNetPremium,
  totalNetPremiumInsurer,
  section,
  editSessionId,
  onChange,
  readOnly = false,
  startExpanded,
  organisationSettings
}): JSX.Element => {
  const { updateSection, handleCommissionUpdate } = useSection(
    editSessionId,
    onChange
  )

  return (
    <Table.HalfTable
      reference={reference}
      startExpanded={startExpanded}
      firstRowValues={[
        <CellContentText align="right">Net premium to carrier</CellContentText>,
        <CellContentText align="right">-</CellContentText>,
        totalNetPremiumInsurer ? (
          <CellContentMoney {...totalNetPremiumInsurer} />
        ) : (
          <CellContentText align="right">-</CellContentText>
        )
      ]}
      summaryValues={[
        <CellContentTitle align="right">{summaryClosedText}</CellContentTitle>,
        null,
        totalNetPremium ? (
          <CellContentMoney {...totalNetPremium} />
        ) : (
          <CellContentText align="right">-</CellContentText>
        )
      ]}
    >
      {commission !== null && (
        <>
          <Row
            type="halftable"
            values={[
              <CellContentText align="right">
                Commission from carrier
              </CellContentText>,
              commission.ruleAmount ? (
                <CellContentRate
                  value={commission.baseRuleAmount || 0}
                  {...(commission.commissionType && {
                    type: COMMISSION_RATE_TYPES[commission.commissionType]
                  })}
                />
              ) : (
                <CellContentText align="right">-</CellContentText>
              ),
              // https://aventusplatform.atlassian.net/browse/IMPL-1824
              <CellContentText align="right">-</CellContentText>
            ]}
          />
          <Row
            type="halftable"
            values={[
              <CellContentText align="right">
                Commission Reduction Discount
              </CellContentText>,
              commission.ruleAmount && commission.baseRuleAmount ? (
                <CellContentRate
                  {...(commission.commissionType && {
                    type: COMMISSION_RATE_TYPES[commission.commissionType]
                  })}
                  value={commission.ruleAmount - commission.baseRuleAmount || 0}
                  addonProps={{ content: "-" }}
                />
              ) : (
                <CellContentText align="right">-</CellContentText>
              ),
              <CellContentText align="right">-</CellContentText>
            ]}
          />
          <Row
            type="halftable"
            updating={updateSection.isLoading}
            values={[
              <CellContentText align="right">
                Effective Commission
              </CellContentText>,
              <CellContentRateInput
                {...(commission.commissionType && {
                  type: COMMISSION_RATE_TYPES[commission.commissionType]
                })}
                currencyCode={organisationSettings?.currencyCode}
                value={
                  commission.commissionType === "Percentage"
                    ? decimalToPercentage(commission.ruleAmount)
                    : commission.ruleAmount
                }
                readOnly={readOnly}
                onBlur={(event) => {
                  handleCommissionUpdate(event, section, commission)
                }}
              />,

              commission?.amount ? (
                <CellContentMoney {...commission?.amount} />
              ) : (
                <CellContentText align="right">-</CellContentText>
              )
            ]}
          />
        </>
      )}
      <Row
        type="halftable"
        values={[
          <CellContentText align="right" strong>
            {summaryOpenText}
          </CellContentText>,
          null,
          totalNetPremium ? (
            <CellContentMoney {...totalNetPremium} />
          ) : (
            <CellContentText align="right">-</CellContentText>
          )
        ]}
      />
    </Table.HalfTable>
  )
}

export interface ICommission {
  reference?: string
  summaryClosedText: string
  summaryOpenText: string
  commission: Nullable<IFinanceCommission>
  totalNetPremium: Nullable<IMoney>
  totalNetPremiumInsurer: Nullable<IMoney>
  readOnly?: boolean
  startExpanded?: boolean
  section: SectionTypes
  editSessionId?: string
  onChange: (response: TPlatformResponse) => void
  organisationSettings?: IOrgSettings
}

export default Commission
