/**
 * Converts a currency value that is in pence or cents into pounds.
 *
 * There are certain cases where we want to return the value as
 * a string. For example, if we've got 10.70, when it's converted
 * to a number, we end up with 10.7. If the caller needs the pounds value
 * to do some string formatting, then we're going to need the '70'.
 *
 * @param {number} value - The monetary value in pence or cents.
 * @param {boolean} returnAsString - Toggle the return type between number or string.
 * @returns {number|string}
 */
export function currencyPenceToPounds(
  value: number,
  returnAsString?: boolean
): number | string {
  const poundsAndPence = Number(value / 100)
  if (poundsAndPence % 1 !== 0) {
    const valueAsDecimal = poundsAndPence.toFixed(2)
    if (returnAsString) {
      return valueAsDecimal
    } else {
      return Number(valueAsDecimal)
    }
  } else {
    return poundsAndPence
  }
}

export function currencyPoundsToPence(value: number): number {
  return Number(value * 100)
}
