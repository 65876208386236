import React from "react"
import usePlatform from "contexts/platform/use-platform"
import { Answers } from "components"
import { useQuery } from "react-query"

interface IAnswersProps {
  quoteId: string
}

export const QuoteAnswers: React.FC<IAnswersProps> = (props) => {
  const platform = usePlatform()
  const answers = useQuery(["quote", "answers", props.quoteId], () =>
    platform.quote.getAnswers(props.quoteId)
  )

  return (
    <Answers
      data={answers.data}
      isLoading={answers.isLoading}
      isError={answers.isError}
    />
  )
}
