import { useState, useEffect } from "react"
import { useNotificationContext, INotificationContext } from "../context"

export const useNotifications = (): IUseNotifications => {
  const [notifications, setNotifications] = useState<
    INotificationContext | undefined
  >(undefined)

  const client = useNotificationContext()

  useEffect(() => {
    setNotifications(client)
  }, [client])

  return {
    notifications
  }
}

interface IUseNotifications {
  notifications: INotificationContext | undefined
}
