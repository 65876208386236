import CSS from "csstype"

const Fieldset: React.FC<IFieldsetProps> = (props) => (
  <fieldset className={props.className} style={props.style}>
    <legend>{props.title}</legend>
    {props.children}
  </fieldset>
)

export interface IFieldsetProps {
  title: string
  className?: string
  style?: CSS.Properties
}

export default Fieldset
