import React from "react"
import { Card, List } from "antd"
import { LayoutCardLoader, LayoutCardEmpty } from "components"
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons"
// TODO needs some organising
import { IRisk, IQuote } from "platform-client/types/quote"
import { IQuoteProduct } from "../../view"
import TravelPackageList from "components/TravelPackageList"

const CARD_TITLE = "Sections of cover"

const IncludedIcon = (
  <CheckCircleTwoTone style={{ fontSize: 20 }} twoToneColor="#52c41a" />
)
const ExcludedIcon = (
  <CloseCircleTwoTone style={{ fontSize: 20 }} twoToneColor="#F5222D" />
)

export const QuoteCover: React.FC<IQuoteCover> = (props) => {
  if (props.product?.isGettingQuoteProduct || false) {
    return <LayoutCardLoader title={CARD_TITLE} />
  }

  if (
    props.risk?.core.addon &&
    props.quote.lineOfBusiness === "travel" &&
    props.product?.product !== undefined
  ) {
    return (
      <TravelPackageList
        packages={props.risk.core.addon}
        product={props.product.product}
        excess={props.risk?.core.excesses.totalExcess}
      />
    )
  } else if (
    props.product?.productItems_v2 &&
    props.product?.productItems_v2.length > 0 &&
    props.quote.quoteState !== "Open"
  ) {
    return (
      <Card title={CARD_TITLE}>
        <List
          dataSource={props.product?.productItems_v2.map((coverItem) => ({
            title: coverItem.description,
            content: coverItem.active ? IncludedIcon : ExcludedIcon
          }))}
          itemLayout="horizontal"
          size="small"
          renderItem={(
            item: { title: string; content: React.ReactNode },
            i
          ) => (
            <List.Item key={i}>
              <List.Item.Meta description={item.title} />
              <div>{item.content}</div>
            </List.Item>
          )}
        />
      </Card>
    )
  } else {
    const quoteOpenText =
      props.quote.quoteState !== "Open"
        ? undefined
        : "Summary not available for open quotes."
    return <LayoutCardEmpty emptyText={quoteOpenText} title={CARD_TITLE} />
  }
}

export interface IQuoteCover {
  quote: IQuote
  product?: IQuoteProduct
  risk?: IRisk
}
