import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Typography
} from "antd"
import Fieldset from "components/Fieldset"
import moment, { Moment } from "moment"
import { IPolicyholder, PolicyholderType } from "platform-client/types"
import { mustBeValidPhoneNumber } from "utils/validators/must-be-valid-phone-number"
import { mustBeValidEmail } from "utils/validators/must-be-valid-email"
import { noFutureDates } from "utils/validators/no-future-dates"
import { validateMinAge } from "utils/validators/validate-min-age"
import styled from "styled-components"
import { useEffect } from "react"
import { UniversalAddress } from "components/UniversalAddress"

interface IPolicyholderOption {
  label: string
  value: PolicyholderType
}

const policyholderOptions: IPolicyholderOption[] = [
  {
    label: "Individual",
    value: "Individual"
  },
  {
    label: "Sole Trader",
    value: "SoleTrader"
  },
  {
    label: "Limited Company",
    value: "LimitedCompany"
  },
  {
    label: "Partnership",
    value: "Partnership"
  },
  {
    label: "Limited Liability Partnership",
    value: "LimitedLiabilityPartnership"
  },
  {
    label: "Public Limited Company",
    value: "PublicLimitedCompany"
  }
]

const StyledFieldset = styled(Fieldset)`
  legend {
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
  }

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const StyledWidthFormItem = styled(Form.Item)<{ $width?: string }>`
  width: calc(${({ $width }) => $width || "50%"} - 10px);
  margin-left: 5px;
  margin-right: 5px;
  padding-bottom: 10px;
`

const PolicyholderForm = ({
  onBack,
  onFinish,
  onCloseForm,
  isLoading = false,
  policyholder
}: IPolicyholderFormProps): JSX.Element => {
  const [form] = Form.useForm()

  const mapPolicyholderData = (
    policyholder?: IPolicyholder
  ): IPolicyholderData | undefined => {
    if (policyholder === undefined) {
      return undefined
    }

    return {
      legalName: policyholder.legalName || undefined,
      policyholderType: policyholder.policyholderType || null,
      businessName: policyholder.businessName || null,
      title: policyholder.contact.name.title || null,
      firstName: policyholder.contact.name.firstName || null,
      lastName: policyholder.contact.name.lastName || null,
      dateOfBirth: policyholder.contact.dateOfBirth
        ? moment(policyholder.contact.dateOfBirth)
        : null,
      phoneNumber: policyholder.contact.phoneNumber || null,
      emailAddress: policyholder.contact.emailAddress || null,
      contactAddress: policyholder.contactAddress
        ? {
            ...policyholder.contactAddress,
            country: JSON.stringify(policyholder.contactAddress.country)
          }
        : undefined
    }
  }

  useEffect(() => {
    const values = mapPolicyholderData(policyholder)
    form.setFieldsValue(values)
  }, [form, policyholder])

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Row justify="center">
        <Col span={12} style={{ padding: "50px 15px 50px 15px" }}>
          <Typography.Title level={3}>Policyholder details</Typography.Title>
          <Typography.Paragraph>
            Complete the form below to create the policyholder for this
            opportunity.
          </Typography.Paragraph>

          <StyledFieldset title="General">
            <StyledWidthFormItem
              name="legalName"
              label="Legal name:"
              required
              rules={[
                { required: true, message: "Legal Name is required" },
                { whitespace: true, message: "Legal Name cannot be empty" }
              ]}
            >
              <Input />
            </StyledWidthFormItem>
            <StyledWidthFormItem
              name="policyholderType"
              label="Policyholder Type:"
              required
              rules={[
                { required: true, message: "Policy Holder Type is required" }
              ]}
            >
              <Select options={policyholderOptions} />
            </StyledWidthFormItem>
            <StyledWidthFormItem
              name="businessName"
              label="Business name:"
              rules={[
                { whitespace: true, message: "Business Name is required" }
              ]}
              $width="100%"
            >
              <Input />
            </StyledWidthFormItem>
          </StyledFieldset>
          <StyledFieldset title="Contact details">
            <StyledWidthFormItem name="title" label="Title:" $width="20%">
              <Select
                options={[
                  { value: "Mr" },
                  { value: "Mrs" },
                  { value: "Miss" },
                  { value: "Ms" },
                  { value: "Dr" },
                  { value: "Prof." },
                  { value: "Capt." },
                  { value: "Lord" },
                  { value: "Lady" },
                  { value: "Major" },
                  { value: "Rev." },
                  { value: "Master" },
                  { value: "Exce(s) of" },
                  { value: "Mx" }
                ]}
              />
            </StyledWidthFormItem>
            <StyledWidthFormItem
              name="firstName"
              label="Firstname:"
              rules={[
                { whitespace: true, message: "First Name cannot be empty" }
              ]}
              $width="40%"
            >
              <Input />
            </StyledWidthFormItem>
            <StyledWidthFormItem
              name="lastName"
              label="Lastname:"
              rules={[
                { whitespace: true, message: "Last Name cannot be empty" }
              ]}
              $width="40%"
            >
              <Input />
            </StyledWidthFormItem>
            <StyledWidthFormItem
              name="dateOfBirth"
              label="Date of birth:"
              rules={[
                { required: true, message: "Birth date is required" },
                {
                  validator: (_, value) => {
                    if (value) {
                      const result = noFutureDates(value.format("DD/MM/YYYY"))

                      if (result === true) {
                        return Promise.resolve()
                      }

                      return Promise.reject(result)
                    }

                    return Promise.resolve()
                  }
                },
                {
                  validator: (_, value) => {
                    if (value) {
                      const result = validateMinAge(
                        value.format("DD/MM/YYYY"),
                        "Policyholder must be 18 years old or older",
                        18
                      )

                      if (result === true) {
                        return Promise.resolve()
                      }

                      return Promise.reject(result)
                    }

                    return Promise.resolve()
                  }
                }
              ]}
              $width="20%"
            >
              <DatePicker format="DD/MM/YYYY" />
            </StyledWidthFormItem>
            <StyledWidthFormItem
              name="phoneNumber"
              label="Telephone:"
              rules={[
                {
                  validator: (_, value) => {
                    const newValue = (value || "").trim()
                    if (newValue) {
                      const result = mustBeValidPhoneNumber(newValue)

                      if (result === undefined) {
                        return Promise.resolve(true)
                      }

                      return Promise.reject(result)
                    }

                    return Promise.resolve()
                  }
                }
              ]}
              $width="40%"
            >
              <Input />
            </StyledWidthFormItem>
            <StyledWidthFormItem
              name="emailAddress"
              label="Email address:"
              rules={[
                { required: true, message: "Email address is required" },
                {
                  validator: (_, value) => {
                    if (value) {
                      const result = mustBeValidEmail(value)

                      if (result === undefined) {
                        return Promise.resolve()
                      }

                      return Promise.reject(result)
                    }

                    return Promise.resolve()
                  }
                }
              ]}
              $width="40%"
            >
              <Input />
            </StyledWidthFormItem>
            <StyledWidthFormItem
              name={["contactAddress"]}
              label="Contact Address"
              rules={[
                {
                  validator: (_, v) => {
                    return new Promise((resolve, reject) => {
                      if (v?.line1 && v?.locality && v?.postalCode) {
                        resolve(true)
                      }

                      reject("Contact address field is required.")
                    })
                  }
                }
              ]}
              $width="100%"
              required
            >
              <UniversalAddress name={"contactAddress"}>
                <UniversalAddress.ManualInput />
              </UniversalAddress>
            </StyledWidthFormItem>
          </StyledFieldset>

          <div>
            {typeof onBack === "function" && (
              <>
                <Button
                  style={{ marginRight: "15px" }}
                  size="large"
                  type="link"
                  onClick={() => onBack()}
                >
                  Go back
                </Button>
              </>
            )}
            {typeof onCloseForm === "function" && (
              <>
                <Button
                  style={{ marginRight: "15px" }}
                  size="large"
                  type="link"
                  onClick={() => onCloseForm()}
                >
                  Back to search
                </Button>
              </>
            )}
            <Button
              size="large"
              type="primary"
              onClick={() => form.submit()}
              loading={isLoading}
            >
              Save and continue
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export interface IPolicyholderData {
  legalName: string | undefined
  policyholderType: PolicyholderType
  businessName: string | null | undefined
  title: string | null | undefined
  firstName: string | null | undefined
  lastName: string | null | undefined
  dateOfBirth: Moment | null | undefined
  phoneNumber: string | null | undefined
  emailAddress: string | null | undefined
  contactAddress?: {
    line1: string | null
    line2?: string | null
    line3?: string | null
    locality: string | null
    province?: string | null
    postalCode: string | null
    country?: string | null
  }
}

interface IPolicyholderFormProps {
  policyholder?: IPolicyholder
  isLoading?: boolean
  onFinish: (data: IPolicyholderData) => void
  onCloseForm?: () => void
  onBack?: () => void
}

export default PolicyholderForm
