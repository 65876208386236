import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Select,
  Space
} from "antd"
import { IPolicyReinstatementRequest } from "contexts/opus/context"
import useOpus from "contexts/opus/hooks/use-opus"
import produce from "immer"
import { PaymentPlanType } from "platform-client/types"
import { useState } from "react"
import { useMutation } from "react-query"
import styled from "styled-components"

const StyledRadioWrapper = styled.div`
  padding: 5px 0;
`

const StyledText = styled.span<{ secondary?: boolean; emphasise?: boolean }>`
  color: ${(props) => (props.secondary ? "#bfbfbf" : "inherit")};
  font-style: ${(props) => (props.emphasise ? "italic" : "inherit")};
`

const PolicyReinstatement = ({
  recurringPaymentDates = [],
  hasReinstatementEmail = false,
  policyReferenceID,
  refetchPolicyBundle,
  paymentPlanType,
  policyID
}: IPolicyReinstatementProps): JSX.Element => {
  const { policyClient } = useOpus()

  const [visible, setVisible] = useState<boolean>(false)

  const [reinstatement, setReinstatement] = useState<IReinstatementStore>({
    instalmentsTakenManually: null,
    newPaymentDate: null,
    suppressEmails: !hasReinstatementEmail
  })

  const [errors, setErrors] = useState<{
    instalmentsTakenManually: null | string
    newPaymentDate: null | string
  }>({
    instalmentsTakenManually: null,
    newPaymentDate: null
  })

  const onCloseModal = () => {
    setVisible(false)
  }

  const clearErrors = () => {
    setErrors({
      instalmentsTakenManually: null,
      newPaymentDate: null
    })
  }

  const reinstatePolicy = useMutation((vars: IPolicyReinstatementRequest) =>
    policyClient.reinstatePolicy(policyID, vars)
  )

  const isMonthlySubscription =
    paymentPlanType === "AnnualMonthlySubscription" ||
    paymentPlanType === "MonthlySubscription"

  const futurePaymentDates = recurringPaymentDates
    .filter((date) => Date.parse(date) >= Date.now())
    .sort((a, b) => Date.parse(a) - Date.parse(b))
    .map((date) => {
      const localDate = new Date(Date.parse(date))
      return {
        value: date,
        label: localDate.toLocaleDateString()
      }
    })

  const handleReinstatement = () => {
    let hasError = false

    if (
      futurePaymentDates.length > 0 &&
      reinstatement.newPaymentDate === null &&
      isMonthlySubscription
    ) {
      setErrors(
        produce((draft) => {
          draft.newPaymentDate = "Please select a date to restart payments."
        })
      )
      hasError = true
    }

    if (!hasError) {
      reinstatePolicy
        .mutateAsync({
          instalmentsTakenManually: reinstatement.instalmentsTakenManually,
          newPaymentDate: reinstatement.newPaymentDate,
          suppressEmails: reinstatement.suppressEmails
        })
        .then(() => {
          if (typeof refetchPolicyBundle === "function") {
            refetchPolicyBundle()
          }
          // Add slight delay to allow for refetch.
          setTimeout(() => setVisible(false), 1000)
        })
        .catch(() => {
          notification.error({
            message:
              "Unable to reinstate this policy. Please try again or contact support."
          })
        })
    }
  }

  return (
    <>
      <Button
        loading={false}
        onClick={() => setVisible(true)}
        disabled={
          paymentPlanType === "AnnualMonthlyCredit" ||
          paymentPlanType === "NotSet"
        }
      >
        Reinstate Policy
      </Button>
      <Modal
        title="Policy reinstatement"
        visible={visible}
        onCancel={onCloseModal}
        footer={
          <Space direction="horizontal">
            <Button onClick={onCloseModal}>Close</Button>
            <Button
              type="primary"
              onClick={handleReinstatement}
              loading={reinstatePolicy.isLoading}
            >
              Reinstate
            </Button>
          </Space>
        }
      >
        <Space direction="vertical">
          <StyledText>
            Reinstating this policy{" "}
            {policyReferenceID && `(${policyReferenceID})`} will return this
            policy on cover and create a new "Reinstatement" transaction on the
            policy and any applicable transactional bordereaux/reporting.
          </StyledText>
          <StyledText>
            This action does not reclaim any unpaid premium.
          </StyledText>
          {isMonthlySubscription && (
            <>
              <StyledText>
                As this is a monthly subscription policy managed by the payment
                provider, this action will recreate the subscription with the
                next payment being taken on the payment date selected below.
              </StyledText>
              <h3>Monthly subscription options</h3>
              <Form.Item
                label="Number of instalments taken manually:"
                labelAlign="left"
                style={{ flexDirection: "column", margin: 0 }}
              >
                <Input
                  name="instalments"
                  type="number"
                  style={{ width: "140px", marginTop: "5px" }}
                  value={
                    reinstatement.instalmentsTakenManually
                      ? Number(reinstatement.instalmentsTakenManually)
                      : undefined
                  }
                  onChange={(event) => {
                    setReinstatement(
                      produce((draft) => {
                        draft.instalmentsTakenManually = event.target.value
                          ? Number(event.target.value)
                          : null
                      })
                    )
                    clearErrors()
                  }}
                />
              </Form.Item>
              <Form.Item
                label="What date would you like to restart payments:"
                help={errors.newPaymentDate}
                validateStatus={errors.newPaymentDate ? "error" : undefined}
                labelAlign="left"
                style={{ flexDirection: "column" }}
              >
                <Select
                  style={{ width: "140px", marginTop: "5px" }}
                  onChange={(option) => {
                    setReinstatement(
                      produce((draft) => {
                        draft.newPaymentDate = option || null
                      })
                    )
                    clearErrors()
                  }}
                  value={reinstatement.newPaymentDate || undefined}
                >
                  {futurePaymentDates &&
                    futurePaymentDates.length > 0 &&
                    futurePaymentDates.map((option) => (
                      <Select.Option value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </>
          )}
          <h3>Communication</h3>
          {!hasReinstatementEmail && (
            <StyledText emphasise>
              Reinstatement emails have not been configured and no communication
              will be sent.
            </StyledText>
          )}
          <StyledText {...(!hasReinstatementEmail ? { secondary: true } : {})}>
            Would you like to send a notification to the customer advising them
            that their policy has been reinstated?
          </StyledText>
          <StyledRadioWrapper>
            <Radio.Group
              disabled={!hasReinstatementEmail}
              value={reinstatement.suppressEmails}
              onChange={(event) =>
                setReinstatement(
                  produce((draft) => {
                    draft.suppressEmails = event.target.value as boolean
                  })
                )
              }
            >
              <Radio value={false}>Send reinstatement email</Radio>
              <Radio value={true}>Do not send any emails</Radio>
            </Radio.Group>
          </StyledRadioWrapper>
        </Space>
      </Modal>
    </>
  )
}

interface IReinstatementStore {
  instalmentsTakenManually: number | null
  newPaymentDate: string | null
  suppressEmails: boolean
}

export interface IPolicyReinstatementProps {
  policyID: string
  policyReferenceID?: string
  paymentPlanType: PaymentPlanType
  hasReinstatementEmail?: boolean
  recurringPaymentDates?: string[]
  refetchPolicyBundle?: () => void
}

export default PolicyReinstatement
