import { Button, Modal, Row, notification } from "antd"
import { AxiosError } from "axios"
import useOpus from "contexts/opus/hooks/use-opus"
import { IBlacklistPhoneNumberParams } from "platform-client/types"
import React, { useEffect, useState } from "react"
import { useMutation, useQuery } from "react-query"
import styled from "styled-components"

const StyledDiv = styled.div`
  margin: 20px 0;
`

const StyledSpan = styled.span`
  flex: 2;
`
const StyledFloatRightButton = styled(Button)`
  float: right;
`

const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

export const PolicyPhoneNumber: React.FunctionComponent<IPolicyPhoneNumber> = (
  props
) => {
  const opus = useOpus()

  const [
    blacklistPhoneNumberModalVisible,
    setBlacklistPhoneNumberModalVisible
  ] = useState<boolean>(false)

  const [phoneNumber, setPhoneNumber] = useState<string>(props.phoneNumber)
  const [isUpdating, setIsUpdating] = useState<boolean>(false)

  const [isOnBlacklist, setIsOnBlacklist] = useState<boolean>()

  const [error, setError] = useState<AxiosError>()

  const handleModalCancel = () => {
    setIsUpdating(false)
    setBlacklistPhoneNumberModalVisible(false)
  }

  const { isFetching: isGettingBlacklistStatus, refetch } = useQuery<
    boolean | undefined,
    AxiosError
  >(
    ["blocklist-status"],
    async () => opus.blacklistClient.phoneNumberIsBlacklisted(phoneNumber),
    {
      onSuccess: (result) => {
        if (result !== undefined) {
          setIsOnBlacklist(result)
        }
      },
      onError: (error: AxiosError) => {
        setError(error)
      }
    }
  )

  const blacklistPhoneNumber = useMutation(
    (param: IBlacklistPhoneNumberParams) =>
      opus.blacklistClient.blacklistPhoneNumber(param.phoneNumber),
    {
      onSuccess: (data) => {
        if (data === true) {
          setBlacklistPhoneNumberModalVisible(false)
          setIsUpdating(false)
          refetch()
          notification.success({
            message: `Phone number blocked successfully`
          })
        }
      },
      onError: (error: AxiosError) => {
        setError(error)
      }
    }
  )

  const removeFromBlacklist = useMutation(
    (param: IBlacklistPhoneNumberParams) =>
      opus.blacklistClient.removePhoneNumberFromBlacklist(param.phoneNumber),
    {
      onSuccess: (data) => {
        if (data === true) {
          setBlacklistPhoneNumberModalVisible(false)
          setIsUpdating(false)
          refetch()
          notification.success({
            message: `Phone number removed from blocklist successfully`
          })
        }
      },
      onError: (error: AxiosError) => {
        setError(error)
      }
    }
  )

  const renderModalFooter = () => {
    return (
      <Row justify="space-between">
        <Button onClick={handleModalCancel}>Cancel</Button>
        {isOnBlacklist === false && (
          <Button
            type="primary"
            loading={isUpdating}
            onClick={() => {
              setIsUpdating(true)
              blacklistPhoneNumber.mutate({
                phoneNumber
              })
            }}
            disabled={isUpdating || isGettingBlacklistStatus}
          >
            Add to blocklist
          </Button>
        )}
        {isOnBlacklist === true && (
          <Button
            type="primary"
            loading={isUpdating}
            onClick={() => {
              setIsUpdating(true)
              removeFromBlacklist.mutate({
                phoneNumber
              })
            }}
            disabled={isUpdating || isGettingBlacklistStatus}
          >
            Remove from blocklist
          </Button>
        )}
      </Row>
    )
  }

  useEffect(() => {
    setPhoneNumber(props.phoneNumber)
  }, [props.phoneNumber])

  return (
    <>
      <InputWrapper>
        <StyledSpan>{phoneNumber}</StyledSpan>
        {isOnBlacklist != null && (
          <StyledFloatRightButton
            type="primary"
            onClick={() => setBlacklistPhoneNumberModalVisible(true)}
            disabled={!!error || isGettingBlacklistStatus}
          >
            {isOnBlacklist ? "Remove from blocklist" : "Add to blocklist"}
          </StyledFloatRightButton>
        )}
      </InputWrapper>

      <Modal
        title={`Blocklist Phone Number`}
        visible={blacklistPhoneNumberModalVisible}
        onCancel={handleModalCancel}
        footer={renderModalFooter()}
      >
        {isOnBlacklist
          ? "You are about to remove the below phone number from the blocklist, meaning that this number will no longer be blocked from receiving SMS messages."
          : "You are about to add the below phone number to the blocklist, meaning that this number will be blocked from receiving any further SMS messages."}
        <StyledDiv>
          Phone Number: <strong>{phoneNumber} </strong>
        </StyledDiv>
        {isOnBlacklist
          ? "Are you sure you wish to remove this phone number from the blocklist?"
          : "Are you sure you wish to blocklist this phone number?"}
      </Modal>
    </>
  )
}

export interface IPolicyPhoneNumber {
  phoneNumber: string
  refetchTimelineEvents: VoidFunction
}
