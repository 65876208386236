import { useState, useRef, useEffect, useCallback } from "react"
import { TPolicyRenewalsBundle } from "models/policies/policyRenewals"
import useOpus from "contexts/opus/hooks/use-opus"

export const useSetPolicyRenewalsWithdraw = (
  policyID: string | undefined,
  successCallback?: () => void
): IUseSetPolicyRenewalsWithdraw => {
  const [isSending, setIsSending] = useState(false)
  const [isError, setIsError] = useState(false)
  const [withdrawResponse, setWithdrawResponse] = useState<
    TPolicyRenewalsBundle | undefined
  >(undefined)

  const isMounted = useRef(true)
  const { policyClient } = useOpus()

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const sendRequest = useCallback(async () => {
    if (isSending) return

    setIsSending(true)

    try {
      if (policyID) {
        const _withdraw = await policyClient.setPolicyRenewalWithdraw(policyID)
        setWithdrawResponse(_withdraw)
        successCallback && successCallback()
      }
    } catch (error) {
      setIsError(true)
    }

    setIsSending(false)

    if (isMounted.current) setIsSending(false)
  }, [isSending, policyClient, policyID, successCallback])

  return { response: withdrawResponse, sendRequest, isSending, isError }
}

interface IUseSetPolicyRenewalsWithdraw {
  sendRequest: () => Promise<void>
  response: TPolicyRenewalsBundle | undefined
  isError: boolean
  isSending: boolean
}
