import ConfigurationClient from "contexts/configuration/client"
import { useAsyncEffect } from "use-async-effect"
import { useAsyncError } from "utils/hooks/use-catch-async-error"
import { useState } from "react"

export interface ITravelPackageConfig {
  riskPath: string
  defaultLevel: string
  excessApplies: boolean
  packageLabel: string
  packageType: number
}

interface IUseGetTravelPackageConfig {
  travelPackageConfigLoaded: boolean
  travelPackageConfig: ITravelPackageConfig[]
}

export const useGetTravelPackageConfig = (
  productReference: string,
  productVersion: number
): IUseGetTravelPackageConfig => {
  const [travelPackageConfig, setTravelPackagesConfig] = useState<
    ITravelPackageConfig[]
  >([])

  const [travelPackageConfigLoaded, setTravelPackageConfigLoaded] =
    useState<boolean>(false)

  const throwError = useAsyncError()

  useAsyncEffect(
    async (isMounted) => {
      try {
        if (!travelPackageConfigLoaded) {
          const _travelPackages = await ConfigurationClient.getTravelPackages(
            productReference,
            productVersion
          )
          if (isMounted() && _travelPackages) {
            setTravelPackagesConfig(
              _travelPackages.map((travelPackage) => ({
                ...travelPackage,
                riskPath: travelPackage.riskPath.replace("addon.", "")
              }))
            )
            setTravelPackageConfigLoaded(true)
          } else {
            throw new Error("No travel packages loaded")
          }
        }
      } catch (e) {
        throwError(new Error("Unable to load travel packages"))
      }
    },
    [travelPackageConfigLoaded]
  )

  return {
    travelPackageConfigLoaded,
    travelPackageConfig
  }
}
