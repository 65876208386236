import { useCallback, useState } from "react"

type IUseAsyncError = (error: Error) => void

export const useAsyncError = (): IUseAsyncError => {
  const [, setError] = useState()
  return useCallback(
    (error: Error) => {
      // React work-around so that we can catch
      // errors that happen during async methods
      // such as useAsyncEffect and so on.
      setError(() => {
        throw error
      })
    },
    [setError]
  )
}
