import axios from "axios"
import LayoutError from "components/LayoutError"
import { IErrorResponse } from "models/errors"
import React, { ReactNode, ErrorInfo } from "react"

interface IRatingsBoundaryProps {
  children: ReactNode
}

interface IRatingsBoundaryState {
  hasError: boolean
  error?: Error
  errorInfo?: ErrorInfo
}

export class RatingsBoundary extends React.Component<
  IRatingsBoundaryProps,
  IRatingsBoundaryState
> {
  constructor(props: IRatingsBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(): IRatingsBoundaryState {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ hasError: true, errorInfo, error })
  }

  render(): ReactNode {
    if (this.state.hasError) {
      if (axios.isAxiosError(this.state.error)) {
        const error = this.state.error as IErrorResponse

        return (
          <LayoutError
            title="Sorry, something went wrong."
            subTitle="Please contact support quoting the request ID below."
            requestID={error.response.data.requestID}
          />
        )
      } else {
        return (
          <LayoutError
            title="Sorry, something went wrong."
            subTitle="Please contact support if this error continues."
          />
        )
      }
    }

    return this.props.children
  }
}
