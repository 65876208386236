import React from "react"
import { Card, Descriptions, Alert } from "antd"
import { MoneyDisplay } from "components/display/MoneyDisplay"
import { TPolicyPaymentPlan } from "models/policies/policyPaymentPlan"
import ScheduledPaymentsTable from "./scheduledpayments"

export const PaymentPlan: React.FunctionComponent<IPaymentPlan> = (props) => {
  if (props.isLoading === false && !props.data) {
    return (
      <Alert
        message="No payment plan exists for this policy as the premium was paid in full at the time of purchase."
        type="info"
      />
    )
  } else {
    return (
      <>
        <Card
          title="Payment Plan"
          loading={props.isLoading}
          style={{ marginBottom: "20px" }}
        >
          <Descriptions size="small" column={3} bordered>
            <Descriptions.Item label="Payment Provider">
              {props.data?.paymentProvider}
            </Descriptions.Item>
            <Descriptions.Item label="Status" span={2}>
              {props.data?.status}
            </Descriptions.Item>
            <Descriptions.Item label="Total Instalments">
              {props.data?.totalInstalments}
            </Descriptions.Item>
            <Descriptions.Item label="Total Amount Payable">
              <MoneyDisplay value={props.data?.totalAmountPayable} />
            </Descriptions.Item>
            <Descriptions.Item label="Billing Day">
              {props.data?.billingDay}
            </Descriptions.Item>
          </Descriptions>

          <Card loading={props.isLoading} style={{ marginTop: "20px" }}>
            <Card.Grid
              hoverable={false}
              style={{
                width: "calc(100% - 2px)",
                minWidth: "100%",
                padding: 0,
                margin: "1px 1px 0 1px",
                border: "none",
                boxShadow: "none"
              }}
            >
              <ScheduledPaymentsTable
                isProcessing={props.isProcessing}
                paymentPlan={props.data}
                scheduledPaymentRows={props.data?.scheduledPayments || []}
                payScheduledPayment={props.payScheduledPayment}
                downloadReceipt={props.downloadReceipt}
                emailReceipt={props.emailReceipt}
                isDownloading={props.isDownloading}
                isEmailing={props.isEmailing}
                linkedEmailAddress={props.emailAddress}
              />
            </Card.Grid>
          </Card>
        </Card>
      </>
    )
  }
}
export interface IPaymentPlan {
  isLoading: boolean
  isProcessing: boolean
  data: TPolicyPaymentPlan | undefined
  payScheduledPayment: (scheduledPaymentID: string) => void
  downloadReceipt: (paymentId: string) => Promise<void>
  emailReceipt: (paymentId: string, emailAddress: string) => Promise<void>
  isDownloading: boolean
  isEmailing: boolean
  emailAddress: string
}

export default PaymentPlan

export enum ScheduledPaymentStatus {
  Scheduled = "Scheduled",
  Paid = "Paid",
  Uncollectible = "Uncollectible",
  Deleted = "Deleted",
  Pending = "Pending"
}

export enum PaymentPlanStatus {
  Active = "Active",
  PastDue = "PastDue",
  Cancelled = "Cancelled"
}
