import React from "react"
import { Select } from "antd"
import styled from "styled-components"
import { SelectValue } from "antd/lib/select"

const StyledSelect = styled(Select)<{ name: string }>`
  position: relative;
  display: inline-block;
  width: 100%;
`

const CellContentSelect: React.FC<ISelectProps> = ({
  name,
  value,
  disabled,
  options,
  loading = false,
  autoFocus = false,
  showSearch = false,
  ...props
}): JSX.Element => {
  return (
    <StyledSelect
      {...props}
      name={name}
      autoFocus={autoFocus}
      disabled={disabled}
      loading={loading}
      defaultValue={props.defaultValue}
      onChange={(value: SelectValue) => {
        if (typeof props.onChange === "function") {
          props.onChange({
            target: {
              name,
              value
            }
          })
        }
      }}
      value={value}
      options={options}
      showSearch={showSearch}
    >
      {props.children}
    </StyledSelect>
  )
}

export type SelectOptionsType = {
  label: string
  value: string | number
}

export type SelectedOptionType = {
  target: { name: string; value: SelectValue }
}

interface ISelectProps {
  name: string
  value?: string
  options?: SelectOptionsType[]
  onChange?: (data: SelectedOptionType) => void
  disabled?: boolean
  loading?: boolean
  defaultValue?: string | number | undefined
  autoFocus?: boolean
  showSearch?: boolean
  placeholder?: string
}

export default CellContentSelect
