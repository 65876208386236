export function getItem<Item>(key: string): Item | null {
  const item = window.sessionStorage.getItem(key)
  if (item) {
    return JSON.parse(item)
  }
  return null
}

export const setItem = (key: string, value: string): void => {
  try {
    window.sessionStorage.setItem(key, JSON.stringify(value))
  } catch (error) {
    console.error(`Item ${key} was not set`)
  }
}

export const removeItem = (key: string): void => {
  try {
    window.sessionStorage.removeItem(key)
  } catch (error) {
    console.error(`Item ${key} could not be removed`)
  }
}
