import React from "react"
import styled from "styled-components"

const StyledCard = styled.div`
  background-color: #fff;
  align-self: center;
  min-width: 400px;
  padding: 24px;
`

export const LayoutLogin: React.FunctionComponent<ILayoutContent> = ({
  children
}) => <StyledCard>{children}</StyledCard>

interface ILayoutContent {
  children: React.ReactNode
}
