import React, { useEffect } from "react"
import { History } from "history"
import { LayoutPageContent, LayoutLoading, LayoutPageHeader } from "components"
import { Layout } from "antd"

interface IMTAPolicyProps {
  url: string
  history?: History
}

export type PolicyEvent = {
  data: { event: string; policyId: string; height: string }
}

export enum OpusPolicyEvent {
  scroll = "aventus.app.scrollHeightChanged",
  policyBound = "aventus.policy.bind"
}

const MTAPolicy: React.FC<IMTAPolicyProps> = ({
  url,
  history
}): JSX.Element => {
  useEffect(() => {
    const handler = (event: PolicyEvent) => {
      const data = event.data

      if (data.event === OpusPolicyEvent.policyBound && data.policyId) {
        history?.push({
          pathname: `/policy/${data.policyId}`
        })
      }

      if (data.event === OpusPolicyEvent.scroll) {
        const eventIframeHeight = Number(data.height)
        if (eventIframeHeight > 380) {
          const iframeEl = document.getElementById("quoteIframe")

          iframeEl?.setAttribute("height", eventIframeHeight.toString())
        }
      }
    }
    window.addEventListener("message", handler)
    return () => window.removeEventListener("message", handler)
  })

  if (url) {
    const goBack = () => {
      history?.push({
        pathname: `/policies/`
      })
    }

    return (
      <Layout>
        <LayoutPageHeader
          ghost={false}
          title={"Policy MTA"}
          onBack={() => goBack()}
        />
        <LayoutPageContent>
          <iframe
            src={url}
            /*Stops the Iframe caching in Chrome
            https://stackoverflow.com/questions/25732519/preventing-iframe-caching-in-chrome*/
            name={"policy-mta" + Date.now()}
            title={"Policy MTA"}
            id="policyIframe"
            frameBorder="0"
            height={960}
            width="100%"
          ></iframe>
        </LayoutPageContent>
      </Layout>
    )
  } else {
    return <LayoutLoading />
  }
}

export default MTAPolicy
