import LayoutUnauthorized from "components/LayoutUnauthorized"
import { useFirebaseAuthContext } from "contexts/authorization/firebase-auth-contex"

const UnauthorizedView: React.FC = (props) => {
  const authContext = useFirebaseAuthContext()

  return (
    <LayoutUnauthorized
      userEmail={authContext.user?.email || undefined}
      onLogout={() => {
        authContext.logout()
      }}
    />
  )
}

export default UnauthorizedView
