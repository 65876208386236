import moment, { Moment } from "moment"

export const dateFromTodayToSpecifiedDate = (
  current: Moment,
  check: Moment | undefined = undefined
): boolean => {
  const today = moment().format("YYYY-MM-DD")
  const date = current && current.format("YYYY-MM-DD")
  const endDate = check && check.format("YYYY-MM-DD")

  if (endDate) {
    return date >= today && date < endDate
  }

  return date >= today
}

export const dateAfterTodayOrSpecifiedDate = (
  current: Moment,
  check: Moment | undefined = undefined
): boolean => {
  const today = moment().format("YYYY-MM-DD")
  const date = current && current.format("YYYY-MM-DD")
  const startTime = (check && check.format("YYYY-MM-DD")) || today

  return date > startTime
}
