import { IConfigurationContext } from "contexts/configuration/context"
import { IProductOpus } from "models/quotes/productOpus"
import { IProduct } from "platform-client/types"
import { ICompanyInRole } from "platform-client/types/company"
import { PolicyDetailType } from "platform-client/types/policy"
import { IPaymentPlan } from "platform-client/types/pricing"
import {
  IOriginalPolicyMeta,
  IQuoteAction,
  IQuoteChange,
  IWithdrawReason,
  Proposer,
  QuoteState,
  QuoteType
} from "platform-client/types/quote"
import { IQuoteLifecycles, IQuoteProduct } from "../../view"
import { QuoteActionsHybrid } from "./index.hybrid"
import { QuoteActionsLegacy } from "./index.v2"
import { QuoteActionsDU } from "./index.v3"

export type QuoteActionsProps = {
  configuration: IConfigurationContext
  quoteId: string
  quoteState?: QuoteState
  quoteReferenceID: string
  quoteProductReferenceId: string | undefined
  quoteProductCoverReference: string | undefined
  quoteProductCoverType: string | undefined
  quoteActions?: IQuoteAction[]
  quoteType: QuoteType
  quoteChanges?: IQuoteChange[]
  proposerEmail?: string
  withdrawReasons?: IWithdrawReason[]
  renderQuoteDocuments?: React.ReactNode
  quoteProduct?: IQuoteProduct
  quoteLifecycles?: IQuoteLifecycles
  originalPolicyMeta?: IOriginalPolicyMeta
  userId?: string
  handleBindQuote?: (paymentPlan: IPaymentPlan, callback?: () => void) => void
  handleConfirmQuote?: () => void
  handleManualReferral?: () => void
  handleRevokeManualReferral?: () => void
  isManualReferralLoading?: boolean
  paymentPlans?: IPaymentPlan[]
  policyDetail?: PolicyDetailType
  proposer?: Proposer
  quoteCompanies?: ICompanyInRole[]
  disableBind?: boolean
  showReferQuoteModal?: () => void
  showAnonymiseQuoteModal?: () => void
  isHybrid?: boolean
  isExternal: boolean
  productId: number
  coverStartDate?: string
  coverEndDate?: string
  onNoteButtonClick: () => void
}

const productHasType = (
  product?: IProduct | IProductOpus
): product is IProduct => !!product && "productType" in product

export const QuoteActions: React.FC<QuoteActionsProps> = (props) => {
  const product = props.quoteProduct?.product
  const productReferenceID = product?.referenceID
  const productType = productHasType(product) ? product?.productType : undefined
  const productConfig = productReferenceID
    ? props.configuration?.products?.[productReferenceID]
    : undefined

  if (props.isExternal === true) return null

  switch (productType || productConfig?.productType) {
    case "DigitalUW":
      return <QuoteActionsDU {...props} />
    case "Hybrid":
      return <QuoteActionsHybrid {...props} />
    default:
      return <QuoteActionsLegacy {...props} />
  }
}
