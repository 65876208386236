import React from "react"
import { IMoney } from "platform-client/types"
import { currencyToHumanReadable } from "utils/pocketknife/currency-to-human-readable"
import { useConfigurationContext } from "contexts/configuration/context"
import { Statistic, StatisticProps } from "antd"

type IMoneyStatisticProps = Omit<StatisticProps, "value"> & {
  value: IMoney | null | undefined
  isMajorCurrency?: boolean
  hideZeroMinorCurrency?: boolean
  nullValueString?: string
}

export const MoneyStatistic: React.FC<IMoneyStatisticProps> = ({
  value,
  isMajorCurrency,
  hideZeroMinorCurrency = false,
  nullValueString = "-",
  ...restProps
}) => {
  const configuration = useConfigurationContext()

  if (
    value === undefined ||
    value === null ||
    value.value === undefined ||
    value.value === null
  )
    return <Statistic value={nullValueString} {...restProps} />

  const isNotApiV3 = configuration.flags.platformVersion !== "v3"
  const isPennies =
    isMajorCurrency !== undefined ? !isMajorCurrency : isNotApiV3

  return (
    <Statistic
      value={currencyToHumanReadable(value, isPennies, hideZeroMinorCurrency)}
      {...restProps}
    />
  )
}
