import { useCallback, useEffect, useState } from "react"
import useOpus from "contexts/opus/hooks/use-opus"
import { IPolicyRenewalQuote } from "contexts/opus/context"
import { TErrorState } from "models/errors"
import { errorValidationMessage } from "utils/pocketknife/error-validation-message"
import useSetActiveRenewalQuote from "containers/renewals/services/hooks/use-set-active-renewal-quote"

const useGetPolicyRenewalQuotes = (
  policyId: string
): IUseGetPolicyRenewalQuotes => {
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<TErrorState>()
  const [renewalQuotes, setRenewalQuotes] = useState<
    IPolicyRenewalQuote[] | [] | undefined
  >(undefined)
  const { setActiveRenewalQuote } = useSetActiveRenewalQuote()
  const { policyClient, renewalClient } = useOpus()

  const getPolicyRenewalQuotes = useCallback(async () => {
    setIsLoading(true)
    try {
      const quotes = await policyClient.getPolicyRenewalQuotes(policyId)

      if (quotes !== undefined) {
        setIsLoading(false)
        setRenewalQuotes(quotes)
        Promise.resolve()
      }
    } catch (e) {
      setError({
        errorNumber: e?.response?.status,
        validationMessage: errorValidationMessage(e)
      })
      setIsLoading(false)
    }
  }, [policyId, policyClient])

  useEffect(() => {
    if (policyId && renewalQuotes === undefined) {
      getPolicyRenewalQuotes().then(() => setIsFirstLoad(false))
    }
  }, [getPolicyRenewalQuotes, policyId, renewalQuotes])

  const setAssignedQuote = async (quoteId: string) => {
    try {
      setIsLoading(true)
      await setActiveRenewalQuote(policyId, quoteId)
      getPolicyRenewalQuotes()
    } catch (e) {
      setError({
        errorNumber: e?.response?.status,
        validationMessage: errorValidationMessage(e)
      })
    }
  }

  const duplicateRenewalQuote = async (quoteId: string) => {
    try {
      setIsLoading(true)
      await renewalClient.duplicateRenewalQuote(policyId, quoteId)
      getPolicyRenewalQuotes()
    } catch (e) {
      setError({
        errorNumber: e?.response?.status,
        validationMessage: errorValidationMessage(e)
      })
    }
  }

  const reloadQuotes = async () => {
    try {
      setIsLoading(true)
      getPolicyRenewalQuotes()
      setIsLoading(false)
    } catch (e) {
      setError({
        errorNumber: e?.response?.status,
        validationMessage: errorValidationMessage(e)
      })
      setIsLoading(false)
    }
  }

  return {
    isFirstLoad,
    isLoading,
    error,
    renewalQuotes,
    setAssignedQuote,
    reloadQuotes,
    duplicateRenewalQuote
  }
}

interface IUseGetPolicyRenewalQuotes {
  isFirstLoad: boolean
  isLoading: boolean
  error: TErrorState
  renewalQuotes: IPolicyRenewalQuote[] | undefined
  setAssignedQuote: (quoteID: string) => void
  reloadQuotes: () => void
  duplicateRenewalQuote: (quoteID: string) => void
}

export default useGetPolicyRenewalQuotes
