import { Alert, Col, Row, Tag, Typography } from "antd"
import { QuoteStateLabels } from "containers/quotes/constants"
import { IQuoteDeclineReason } from "platform-client/types/quote"

const { Text } = Typography

interface IQuoteDeclinedAlertProps {
  isDeclined?: boolean
  declineReasons?: IQuoteDeclineReason[]
}

export const QuoteDeclinedAlert: React.FC<IQuoteDeclinedAlertProps> = (
  props
) => {
  return props.isDeclined ? (
    <Alert
      type="warning"
      closable={true}
      closeText="Hide"
      description={
        <Row>
          <Col flex="80px">
            <Tag color={QuoteStateLabels["Declined"].color}>
              {QuoteStateLabels["Declined"].label.toUpperCase()}
            </Tag>
          </Col>
          <Col flex="auto">
            {(props.declineReasons || []).length ? (
              <>
                <Text>This quote was declined with the following reasons:</Text>
                <ul>
                  {(props.declineReasons || []).map((item) => (
                    <li>{item.text}</li>
                  ))}
                </ul>
              </>
            ) : (
              <Text>This quote was declined</Text>
            )}
          </Col>
        </Row>
      }
    />
  ) : null
}
