import React, { useMemo, useState } from "react"
import { ShoppingOutlined } from "@ant-design/icons"
import { Layout, Breadcrumb, Row, Divider } from "antd"
import { LayoutPageContent, LayoutPageHeader } from "components"
import { SearchComponent } from "../components"

import {
  OpportunitiesTable,
  createPolicyHolderName
} from "./opportunities-table"
import usePlatform from "contexts/platform/use-platform"
import { useQuery, useQueryClient } from "react-query"
import { TErrorState } from "models/errors"
import { AxiosError } from "axios"
import { useHistory } from "react-router"
import { ICompany, IPolicyholder } from "platform-client/types"

const formatCompanies = (
  companies?: ICompany[]
): { id: string; name: string }[] =>
  (companies || []).map(({ id, name }) => ({ id, name: String(name) }))

const formatPolicyholders = (policyholders?: IPolicyholder[]) =>
  (policyholders || []).reduce((acc: { name: string; id: string }[], item) => {
    const newName = createPolicyHolderName(item.legalName, item.referenceID)
    if (!newName || !item.id) return acc
    return [...acc, { name: newName, id: item.id }]
  }, [])

export const AllOpportunities: React.FunctionComponent = () => {
  const queryClient = useQueryClient()
  const history = useHistory()
  const searchParams = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search]
  )
  const [policyholderTerm, setPolicyholderTerm] = useState<string>("")
  const [apiError, setApiError] = React.useState<TErrorState>()
  const platform = usePlatform()
  const [currentPage, setCurrentPage] = useState(1)

  const { data: companies } = useQuery(["companies", "Broker"], async () => {
    return platform.company.listCompanies("Broker")
  })

  useQuery(
    ["policyholder", searchParams.get("policyholder")],
    async () =>
      platform.policyholders.getPolicyholder(
        searchParams.get("policyholder") as string
      ),
    {
      enabled: !policyholderTerm && !!searchParams.get("policyholder"),
      onSuccess: (data) => setPolicyholderTerm(data.legalName || "")
    }
  )

  const policyholders = useQuery(
    ["policyholders", policyholderTerm],
    async () => {
      return platform.policyholders.searchPolicyholders({
        searchTerm: policyholderTerm
      })
    },
    { enabled: !!policyholderTerm }
  )

  const { data: opportunityData, isFetching } = useQuery(
    ["opportunityList", history.location.search],
    async () =>
      platform.opportunity.listOpportunities({
        companyID: searchParams.get("companyID"),
        policyHolderID: searchParams.get("policyholder")
      }),
    {
      onError: (error: AxiosError) => {
        setApiError({
          errorNumber: error.code ? parseInt(error.code) : 0,
          validationMessage: "Unable to load opportunities"
        })
      }
    }
  )

  return (
    <Layout>
      <LayoutPageHeader
        ghost={false}
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item key="/opportunities">
              <ShoppingOutlined />
              &nbsp; Opportunities
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        title="All Opportunities"
      />
      <LayoutPageContent error={apiError}>
        <Row style={{ padding: "16px" }} justify="end" align="middle">
          <SearchComponent
            label="Search Distributors:"
            queryParam="companyID"
            options={formatCompanies(companies)}
            placeholder="Select Distributor"
            onChange={() => setCurrentPage(1)}
          />
          <Divider style={{ height: 20 }} type="vertical" />
          <SearchComponent
            label="Search Policy Holder:"
            queryParam="policyholder"
            options={formatPolicyholders(policyholders.data)}
            placeholder="Select Policy Holder"
            onSearch={setPolicyholderTerm}
            onChange={() => setCurrentPage(1)}
            onClear={() =>
              queryClient.setQueryData(["policyholders", policyholderTerm], [])
            }
          />
        </Row>
        <OpportunitiesTable
          opportunityData={opportunityData}
          isLoading={isFetching}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
        />
      </LayoutPageContent>
    </Layout>
  )
}
