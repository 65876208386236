import { Alert, Button, Card, notification } from "antd"
import { ExcessesTable } from "components/Excesses"
import {
  CustomExcessModal,
  ExcessFormDataType,
  ExcessModalModeType
} from "components/Excesses/CustomExcessModal"
import { LayoutPageContent } from "components/LayoutPageContent"
import { useOrgSettings } from "contexts/organisation/hooks/use-org-settings"
import usePlatform from "contexts/platform/use-platform"
import {
  AddCustomExcessType,
  RemoveCustomExcessType
} from "platform-client/client/controllers/quote-builder"
import { IExcess } from "platform-client/types/excess"
import { QuoteState, QuoteStatus } from "platform-client/types/quote"
import { SectionTypes } from "platform-client/types/section"
import { useState } from "react"
import { useMutation } from "react-query"
import { isQuoteComplete } from "utils/pocketknife/is-quote-complete"

type ExcessesViewType = {
  sections?: SectionTypes[]
  quoteState: QuoteState
  quoteStatus: QuoteStatus
  excesses?: IExcess[]
  quoteID: string
  platformVersion?: string
  onQuoteChange?: () => void
}

export const ExcessesView: React.FC<ExcessesViewType> = ({
  quoteID,
  excesses = [],
  sections = [],
  platformVersion,
  onQuoteChange,
  ...props
}) => {
  const [visible, setVisible] = useState(false)
  const [mode, setMode] = useState<ExcessModalModeType | undefined>()
  const { quoteBuilder } = usePlatform()
  const { orgSettings } = useOrgSettings()

  const addCustomExcess = useMutation(
    ({ quoteID, request }: { quoteID: string; request: AddCustomExcessType }) =>
      quoteBuilder.forceAddCustomExcess(quoteID, request)
  )

  const removeCustomExcess = useMutation(
    ({
      quoteID,
      request
    }: {
      quoteID: string
      request: RemoveCustomExcessType
    }) => quoteBuilder.forceRemoveCustomExcess(quoteID, request)
  )

  const onAddCustomExcess = (
    data: ExcessFormDataType,
    callbackFn: () => void
  ) => {
    addCustomExcess
      .mutateAsync({
        quoteID,
        request: {
          amount: {
            value: Number(data.amount),
            currencyCode: orgSettings?.currencyCode || "GBP"
          },
          title: data.title,
          text: data.text,
          componentID: data.section || null,
          componentType: data.section ? "Section" : "Policy", // Default to policy level
          excessType: data.type
        }
      })
      .then(() => {
        setVisible(false)
        onQuoteChange?.()
        callbackFn()
        notification.success({ message: "Excess added successfully" })
      })
      .catch(() => {
        notification.error({
          message: "Unable to add custom excess. Please try again."
        })
      })
  }

  // const onUpdateCustomExcess = (
  //   excessID: string,
  //   data: ExcessFormDataType,
  //   callbackFn: () => void
  // ) => {}

  const onRemoveCustomExcess = (
    excessID: string,
    callbackFn: (loading: boolean) => void
  ) => {
    callbackFn(true)
    removeCustomExcess
      .mutateAsync({ quoteID, request: { excessID } })
      .then(() => {
        callbackFn(false)
        onQuoteChange?.()
        notification.success({ message: "Excess removed" })
      })
      .catch(() => {
        callbackFn(false)
        notification.error({
          message: "Unable to remove custom excess. Please try again."
        })
      })
  }

  if (!isQuoteComplete(props.quoteState, props.quoteStatus)) {
    return (
      <Alert message="Excesses are not available for an incomplete quote" />
    )
  }

  return (
    <LayoutPageContent transparent>
      <Card style={{ marginBottom: "14px" }}>
        <Button
          type="primary"
          onClick={() => {
            setMode("ADD")
            setVisible(true)
          }}
        >
          Add custom excess
        </Button>
      </Card>
      <ExcessesTable
        quoteID={quoteID}
        excesses={excesses}
        platformVersion={platformVersion}
        onRemoveCustomExcess={onRemoveCustomExcess}
        canManageExcesses={true}
      />
      <CustomExcessModal
        mode={mode}
        visible={visible}
        setVisible={setVisible}
        sections={sections}
        loading={addCustomExcess.isLoading}
        onAddExcess={onAddCustomExcess}
      />
    </LayoutPageContent>
  )
}
