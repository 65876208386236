import { notification } from "antd"
import { AxiosError } from "axios"
import { NewNoteModal } from "components"
import usePlatform from "contexts/platform/use-platform"
import { INote } from "platform-client/types"
import React, { useState } from "react"
import { useMutation, UseQueryResult } from "react-query"
import { handleValidationErrors } from "utils/pocketknife/handle-validation-errors"

export const NewNote: React.FC<INewNote> = (props) => {
  const [noteContent, setNoteContent] = useState("")
  const [isInternalNote, setInternalNote] = useState(false)
  const platform = usePlatform()

  const newNote = useMutation(
    ({
      content = "",
      isInternalOnly = false
    }: {
      content: string
      isInternalOnly: boolean
    }) =>
      platform.quote.createNote(props.id || "", {
        content,
        isInternalOnly
      }),
    {
      onSuccess: () => {
        props.setModalVisability(false)
        setInternalNote(false)
        setNoteContent("")
        props.quoteNotes.refetch()
      },
      onError: (error: AxiosError) => handleValidationErrors(error)
    }
  )

  return (
    <NewNoteModal
      visible={props.isModalVisible}
      content={noteContent}
      setContent={setNoteContent}
      onCancel={() => {
        props.setModalVisability(false)
        setInternalNote(false)
        setNoteContent("")
      }}
      onSubmit={() => {
        if (!noteContent)
          return notification.error({
            message: "Please add some text to the note"
          })
        return newNote.mutate({
          content: noteContent,
          isInternalOnly: isInternalNote
        })
      }}
      isLoading={newNote.isLoading}
      onInternalCheck={() => setInternalNote((state) => !state)}
      isInternal={isInternalNote}
    />
  )
}

export interface INewNote {
  isModalVisible: boolean
  setModalVisability: (visible: boolean) => void
  id: string
  quoteNotes: UseQueryResult<INote[], unknown>
}
