import { CheckSquareTwoTone, CloseSquareTwoTone } from "@ant-design/icons"
import { Card, Descriptions, List } from "antd"
import { DateDisplay } from "components/display/DateDisplay"
import { MoneyDisplay } from "components/display/MoneyDisplay"
import { IDisplayCompany } from "platform-client/types"
import React from "react"

export const Summary: React.FC<ISummary> = (props) => {
  return (
    <>
      <Card style={{ marginBottom: "20px" }} loading={props.isLoading}>
        {
          <Descriptions size="small" column={2} bordered>
            {props.company?.name && (
              <Descriptions.Item label="Name">
                {props?.company?.name}
              </Descriptions.Item>
            )}
            {props.company?.contactName &&
              props.company.contactName != null && (
                <Descriptions.Item label="Contact Name">
                  {`${
                    props.company?.contactName?.title
                      ? props.company.contactName?.title
                      : ""
                  } ${
                    props.company?.contactName?.firstName
                      ? props.company?.contactName?.firstName
                      : ""
                  } ${
                    props.company.contactName?.lastName
                      ? props.company?.contactName?.lastName
                      : ""
                  }`}
                </Descriptions.Item>
              )}
            {props.company?.contactEmailAddress && (
              <Descriptions.Item label="Contact Email address">
                {props.company?.contactEmailAddress}
              </Descriptions.Item>
            )}
            {props.company?.contactPhoneNumber && (
              <Descriptions.Item label="Contact Telephone">
                {props.company.contactPhoneNumber}
              </Descriptions.Item>
            )}
            {props.company?.address && (
              <Descriptions.Item label="Contact Address">
                {props.company.address?.line1}
                {props.company.address?.line2 &&
                  `, ${props.company.address?.line2}`}
                {props.company.address?.locality &&
                  `, ${props.company.address?.locality}`}
                {props.company.address?.postalCode &&
                  `, ${props.company.address?.postalCode}`}
              </Descriptions.Item>
            )}

            <Descriptions.Item label="Can bind business">
              {props.company?.brokerCanBindBusiness ? (
                <CheckSquareTwoTone
                  style={{ fontSize: "20px" }}
                  twoToneColor="#52c41a"
                />
              ) : (
                <CloseSquareTwoTone
                  style={{ fontSize: "20px" }}
                  twoToneColor="#eb2f96"
                />
              )}
            </Descriptions.Item>

            <Descriptions.Item label="Terms of business agreeement (TOBA)">
              {props.company?.tobaDocument ? (
                <CheckSquareTwoTone
                  style={{ fontSize: "20px" }}
                  twoToneColor="#52c41a"
                />
              ) : (
                <CloseSquareTwoTone
                  style={{ fontSize: "20px" }}
                  twoToneColor="#eb2f96"
                />
              )}
            </Descriptions.Item>
          </Descriptions>
        }
      </Card>
      <Card style={{ marginBottom: "20px" }} title="Professional Indemnity">
        <Descriptions size="small" column={2} bordered>
          <Descriptions.Item label="Insurer">
            {props.company?.professionalIndemnityInsurer}
          </Descriptions.Item>
          <Descriptions.Item label="Policy number">
            {props.company?.professionalIndemnityPolicyNumber}
          </Descriptions.Item>
          {props?.company?.professionalIndemnityExpiryDate && (
            <Descriptions.Item label="Expiry date">
              <DateDisplay
                date={props?.company?.professionalIndemnityExpiryDate}
              />
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Limit">
            <MoneyDisplay value={props.company?.professionalIndemnityLimit} />
          </Descriptions.Item>
          <Descriptions.Item label="Excess">
            <MoneyDisplay value={props.company?.professionalIndemnityExcess} />
          </Descriptions.Item>
        </Descriptions>
      </Card>
      {props.company?.enabledProducts && (
        <Card style={{ marginBottom: "20px" }} title="Enabled Products">
          <List
            size="large"
            bordered
            dataSource={props.company?.enabledProducts}
            renderItem={(item) => <List.Item>{item.name}</List.Item>}
          />
        </Card>
      )}
    </>
  )
}

export interface ISummary {
  company?: IDisplayCompany
  isLoading: boolean
}
