import { Table } from "antd"
import { useEffect, useState } from "react"
import formatDate from "dateformat"
import { useQuery } from "react-query"
import { AxiosError } from "axios"
import usePlatform from "contexts/platform/use-platform"
import Column from "antd/lib/table/Column"
import { Link } from "react-router-dom"
import { IPaymentPlanPastDue } from "models/policies/policyPaymentPlan"

export const PaymentPlansPastDue: React.FunctionComponent = (props) => {
  const [plansPastDueData, setPlansPastDueData] = useState<
    IPaymentPlanPastDue[] | []
  >([])
  const platform = usePlatform()

  const { isFetching, refetch } = useQuery<
    IPaymentPlanPastDue[] | undefined,
    AxiosError
  >(
    ["payment-plan-past-due"],
    async () => platform.report.getPaymentPlansPastDue() || [],
    {
      onSuccess: (result) => {
        if (result) {
          setPlansPastDueData(result)
        }
      }
    }
  )

  useEffect(() => {
    if (isFetching === false && plansPastDueData === null) {
      refetch()
    }
  })

  return (
    <Table
      scroll={{ x: "max-content" }}
      loading={isFetching}
      dataSource={plansPastDueData}
      rowKey="policyId"
      pagination={{
        defaultPageSize: 10,
        hideOnSinglePage: true,
        position: ["bottomCenter"],
        size: "small"
      }}
    >
      <Column
        title="Policy Reference"
        dataIndex="policyReferenceID"
        key="policyReferenceID"
        render={(policyReferenceID, record: IPaymentPlanPastDue) =>
          policyReferenceID && (
            <Link to={`/policy/${record.policyID}/summary`}>
              {policyReferenceID}
            </Link>
          )
        }
      />
      <Column title="Product" dataIndex="productName" />
      <Column
        title="Scheduled Payment Date"
        dataIndex="scheduledPaymentDate"
        render={(date: string) => formatDate(new Date(date), "dd/mm/yyyy")}
      />
      <Column title="Payment Attempts" dataIndex="paymentAttempts" />
      <Column
        title="Scheduled Cancellation Date"
        dataIndex="cancellationDate"
        render={(date: string) => formatDate(new Date(date), "dd/mm/yyyy")}
      />
    </Table>
  )
}
