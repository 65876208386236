import { useState, useRef, useEffect, useCallback } from "react"
import useOpus from "contexts/opus/hooks/use-opus"
import { notification } from "antd"
import { Nullable } from "platform-client/types"

export const useResendDocument = (): IUseResendDocument => {
  const [isResending, setIsResending] = useState(false)
  const [isError, setIsError] = useState(false)

  const isMounted = useRef(true)
  const { policyClient } = useOpus()

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const resendDocument = useCallback(
    async (documentId, quoteId, policyId, emailAddress, isCancellation) => {
      if (isResending) return

      setIsResending(true)

      try {
        if (documentId) {
          const response = await policyClient.resendDocument(
            documentId,
            quoteId,
            policyId,
            emailAddress,
            isCancellation
          )

          if (response === true) {
            notification.success({
              message:
                "Document sent successfully. Please be aware, it may take a few minutes for the document to arrive in the recipient's mailbox."
            })
          } else {
            notification.error({
              message:
                "An error occurred when attempting to send the document. Please try again, or contact support."
            })
            setIsError(true)
          }
        }
      } catch (error) {
        notification.error({
          message:
            "An error occurred when attempting to send the document. Please try again, or contact support."
        })
        setIsError(true)
      }

      setIsResending(false)

      if (isMounted.current) setIsResending(false)
    },
    [isResending, policyClient]
  )

  return { resendDocument, isResending, isError }
}

interface IUseResendDocument {
  resendDocument: (
    documentId: string,
    quoteId: Nullable<string>,
    policyId: Nullable<string>,
    emailAddress: string,
    isCancellation: boolean
  ) => Promise<void>
  isResending: boolean
  isError: boolean
}
