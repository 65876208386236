import React, { useState } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import PaymentPlan from "../../v2_components/policy-paymentplan"
import useGetPolicyPaymentPlan from "containers/policies/services/hooks/use-get-policy-paymentplan"
import PaymentMethod from "../../v2_components/policy-payment-method"
import useGetPaymentMethod from "containers/policies/services/hooks/use-get-policy-paymentmethod"
import useGetPolicyPayments from "containers/policies/services/hooks/use-get-policy-payments"
import { Tabs } from "antd"
import PaymentTransactions from "../../v2_components/policy-payment-transactions"
import { PaymentPlanType } from "models/enums/paymentplantype"
import { useDownloadReceipt } from "containers/policies/services/hooks"
import { useEmailReceipt } from "containers/policies/services/hooks/use-email-receipt"

export const PolicyPaymentsComponent: React.FunctionComponent<IPolicyPaymentsSubView> =
  (props) => {
    const [refetchTransactions, setRefetchTransactions] = useState(false)

    const { data, isLoading, isProcessing, payScheduledPayment } =
      useGetPolicyPaymentPlan(props?.policyID, setRefetchTransactions)

    const { data: paymentMethod, isLoading: isLoadingPaymentMethod } =
      useGetPaymentMethod(props?.policyID)

    const { data: policyPayments, isLoading: isLoadingPolicyPayments } =
      useGetPolicyPayments(
        props?.policyID,
        refetchTransactions,
        setRefetchTransactions
      )

    const { downloadReceipt, isDownloading } = useDownloadReceipt(
      props?.policyID
    )

    const { emailReceipt, isEmailing } = useEmailReceipt(props?.policyID)

    return (
      <>
        <PaymentMethod
          data={paymentMethod}
          isLoading={isLoadingPaymentMethod}
          policyId={props.policyID}
          policyReferenceId={props.policyReferenceId}
          ownerId={props.ownerId}
          paymentPlanType={props.paymentPlanType}
          refetchTimelineEvents={props.refetchTimelineEvents}
          canEditCardDetails={props.canEditCardDetails}
          canActionManualPayments={props.canActionManualPayments}
          setRefetchTransactions={setRefetchTransactions}
        />
        <Tabs>
          <Tabs.TabPane tab="Payment Plan" key="item-payment-plan">
            <PaymentPlan
              data={data}
              isLoading={isLoading}
              isProcessing={isProcessing}
              payScheduledPayment={payScheduledPayment}
              downloadReceipt={downloadReceipt}
              emailReceipt={emailReceipt}
              isDownloading={isDownloading}
              isEmailing={isEmailing}
              emailAddress={props.emailAddress}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="All Transactions" key="item-all-transactions">
            <PaymentTransactions
              data={policyPayments}
              isLoading={isLoadingPolicyPayments}
              downloadReceipt={downloadReceipt}
              emailReceipt={emailReceipt}
              isDownloading={isDownloading}
              isEmailing={isEmailing}
              emailAddress={props.emailAddress}
            />
          </Tabs.TabPane>
        </Tabs>
      </>
    )
  }

export const PolicyPaymentsSubView = withRouter(PolicyPaymentsComponent)

export interface IPolicyPaymentsSubView extends RouteComponentProps {
  policyID: string
  policyReferenceId: string
  ownerId: string
  paymentPlanType?: PaymentPlanType
  refetchTimelineEvents: VoidFunction
  canEditCardDetails: boolean
  canActionManualPayments: boolean
  emailAddress: string
}
