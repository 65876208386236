import React, { useState } from "react"
import { LayoutLoading } from "components"
import {
  Layout,
  PageHeader,
  Row,
  Col,
  Alert,
  Breadcrumb,
  Space,
  Button,
  Tabs,
  notification
} from "antd"
import { ShoppingOutlined } from "@ant-design/icons"
import { LayoutPageContent, NewNoteModal } from "components"
import { Route, Switch, Link } from "react-router-dom"
import { RouteComponentProps, useHistory } from "react-router"
import { useMutation, useQuery } from "react-query"
import { Variants, Notes } from "./sub-views"
import usePlatform from "contexts/platform/use-platform"
import { PolicyHolder } from "components/PolicyHolder"
import styled from "styled-components"
import { handleValidationErrors } from "utils/pocketknife/handle-validation-errors"
import { AxiosError } from "axios"
import ListCard from "components/ListCard"
import { DateDisplay } from "components/display/DateDisplay"

const StyledOpportunityHeader = styled.div`
  background-color: #ffffff;
`

const FullHeightListCard = styled(ListCard)`
  height: 100%;
`

const FullHeightPolicyHolder = styled(PolicyHolder)`
  height: 100%;
`

export const OpportunityDetails: React.FunctionComponent<IOpportunityDetails> =
  (props) => {
    const { opportunity } = usePlatform()
    const history = useHistory()
    const [noteContent, setNoteContent] = useState("")
    const [isModalVisible, setModalVisibility] = useState(false)
    const [isInternalNote, setInternalNote] = useState(false)

    const {
      data: opportunityBundle,
      isError,
      refetch,
      isLoading
    } = useQuery(["opportunity", props.match.params.id], async () =>
      opportunity.getOpportunity(props.match.params.id)
    )

    if (!props.match.params.subpage) {
      history.replace(`/opportunity/${props.match.params.id}/summary`)
    }

    const opportunityNotes = useQuery(
      ["opportunityNotes", props.match.params.id],
      () => opportunity.getNotes(props.match.params.id || ""),
      {
        onError: () =>
          notification.error({
            message: "Couldn't load the notes, please try again"
          })
      }
    )

    const newNote = useMutation(
      ({
        content = "",
        isInternalOnly = false
      }: {
        content: string
        isInternalOnly: boolean
      }) =>
        opportunity.createNote(props.match.params.id || "", {
          content,
          isInternalOnly
        }),
      {
        onSuccess: () => {
          setModalVisibility(false)
          setInternalNote(false)
          setNoteContent("")
          opportunityNotes.refetch()
        },
        onError: (error: AxiosError) => handleValidationErrors(error)
      }
    )

    if (!isLoading && opportunityBundle) {
      return (
        <Layout>
          <NewNoteModal
            visible={isModalVisible}
            content={noteContent}
            setContent={setNoteContent}
            onCancel={() => {
              setModalVisibility(false)
              setInternalNote(false)
              setNoteContent("")
            }}
            onSubmit={() => {
              if (!noteContent)
                return notification.error({
                  message: "Please add some text to the note"
                })
              return newNote.mutate({
                content: noteContent,
                isInternalOnly: isInternalNote
              })
            }}
            onInternalCheck={() => setInternalNote((state) => !state)}
            isInternal={isInternalNote}
            isLoading={newNote.isLoading}
          />
          <StyledOpportunityHeader>
            <PageHeader
              title={opportunityBundle?.referenceID}
              breadcrumb={
                <Breadcrumb>
                  <Breadcrumb.Item key="/opportunities">
                    <Link to="/opportunities">
                      <ShoppingOutlined />
                      &nbsp; Opportunities
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>View opportunity</Breadcrumb.Item>
                </Breadcrumb>
              }
              subTitle={opportunityBundle?.productName}
              ghost={false}
              extra={
                <>
                  <Button onClick={() => setModalVisibility(true)}>
                    New Note
                  </Button>
                  {opportunityBundle.policyID && (
                    <Link
                      className="ant-btn"
                      to={`/policy/${opportunityBundle.policyID}`}
                    >
                      View Policy
                    </Link>
                  )}
                </>
              }
              footer={
                <Tabs
                  activeKey={props.match.params.subpage}
                  onTabClick={(key) => {
                    const url = `/opportunity/${props.match.params.id}/${key}`
                    if (history.location.pathname === url) history.replace(url)
                    else history.push(url)
                  }}
                >
                  <Tabs.TabPane tab="Summary" key="summary" />
                  <Tabs.TabPane tab="Notes" key="notes" />
                </Tabs>
              }
            />
          </StyledOpportunityHeader>
          <LayoutPageContent transparent>
            <></>
            {!isError ? (
              <Switch>
                <Route
                  path={`${props.match.path}/summary`}
                  render={() => (
                    <Space
                      direction="vertical"
                      size={24}
                      style={{
                        width: "100%",
                        maxWidth: "1200px",
                        display: "flex"
                      }}
                    >
                      <Row gutter={[24, 24]} style={{ gridAutoRows: "1fr" }}>
                        <Col xs={24} lg={12}>
                          <FullHeightListCard title="Opportunity details">
                            <ListCard.Item label="Opportunity Type">
                              {opportunityBundle?.quoteType}
                            </ListCard.Item>
                            <ListCard.Item label="Product">
                              {opportunityBundle?.productName}
                            </ListCard.Item>
                            <ListCard.Item label="Created">
                              <DateDisplay
                                date={opportunityBundle.localDateCreated}
                                showTime
                              />
                            </ListCard.Item>
                          </FullHeightListCard>
                        </Col>

                        <Col xs={24} lg={12}>
                          {opportunityBundle?.policyholderID && (
                            <FullHeightPolicyHolder
                              referenceId={opportunityBundle?.policyholderID}
                              onEditClick={() =>
                                history.push(
                                  `/policyholders/${
                                    opportunityBundle?.policyholderID
                                  }/edit?url=${encodeURIComponent(
                                    history.location.pathname
                                  )}`
                                )
                              }
                            />
                          )}
                        </Col>
                      </Row>
                      <Variants
                        opportunity={opportunityBundle}
                        refetchVariants={() => refetch()}
                      />
                    </Space>
                  )}
                />
                <Route
                  path={`${props.match.path}/notes`}
                  render={() => (
                    <Notes
                      isLoading={opportunityNotes.isLoading}
                      data={opportunityNotes.data}
                      isError={opportunityNotes.isError}
                      isEmpty={!(opportunityNotes.data || []).length}
                    />
                  )}
                />
              </Switch>
            ) : (
              <Alert
                type="error"
                message="Something went wrong. please try again later"
              />
            )}
          </LayoutPageContent>
        </Layout>
      )
    } else {
      return <LayoutLoading />
    }
  }

export type IOpportunityDetails = RouteComponentProps<{
  id?: string
  subpage: string
}>
