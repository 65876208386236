export const USER_CLAIMS: IUserClaimOption[] = [
  {
    label: "Excess",
    name: "excess",
    claim: "excess:modify",
    description: "User has permission to add, adjust or remove excesses"
  },
  {
    label: "Endorsement",
    name: "endorsement",
    claim: "endorsement:modify",
    description: "User has permission to add, adjust or remove endorsements"
  },
  {
    label: "Process Referral",
    name: "processreferral",
    claim: "processreferral:maintain",
    description: "User has permission to process and accept referrals"
  }
]

export interface IUserClaimOption {
  label: string
  name: string
  claim: string
  description?: string
}
