import { OracleResultDisplay } from "components/OracleResultDisplay"
import usePlatform from "contexts/platform/use-platform"
import { useQuery } from "react-query"

export const QuoteOracleResults: React.FunctionComponent<IOracleResultsProps> =
  (props) => {
    const platform = usePlatform()

    const { data, isLoading } = useQuery(
      ["quote", "oracleresults", props.quoteId],
      () => platform.quote.getOracleResults(props.quoteId)
    )
    return <OracleResultDisplay isLoading={isLoading} results={data} />
  }

interface IOracleResultsProps {
  quoteId: string
}
