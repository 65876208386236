import React from "react"
import { OpportunitiesBoundary } from "./boundary"
import { AllOpportunities } from "./views/AllOpportunities"
import CreateOpportunity, {
  ICreateOpportunityProps
} from "./views/CreateOpportunity"
import { EditVariant, IEditVariantRoutingProps } from "./views/EditVariant"
import {
  IRiskChanges,
  RiskChanges
} from "./views/SingleOpportunity/sub-views/riskChanges"
import {
  OpportunityDetails,
  IOpportunityDetails
} from "./views/SingleOpportunity"

export const VSingleOpportunity: React.FC<IOpportunityDetails> = (props) => (
  <OpportunitiesBoundary>
    <OpportunityDetails {...props} />
  </OpportunitiesBoundary>
)

export const VCreateOpportunity: React.FC<ICreateOpportunityProps> = (
  props
) => (
  <OpportunitiesBoundary>
    <CreateOpportunity {...props} />
  </OpportunitiesBoundary>
)

export const VEditVariant: React.FC<IEditVariantRoutingProps> = (props) => (
  <OpportunitiesBoundary>
    <EditVariant {...props} />
  </OpportunitiesBoundary>
)

export const VOpportunitiesList: React.FC = (props) => (
  <OpportunitiesBoundary>
    <AllOpportunities {...props} />
  </OpportunitiesBoundary>
)

export const VRiskChanges: React.FC<IRiskChanges> = (props) => (
  <OpportunitiesBoundary>
    <RiskChanges {...props} />
  </OpportunitiesBoundary>
)
