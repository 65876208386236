import React from "react"
import { withRouter } from "react-router"
import { useConfigurationContext } from "contexts/configuration/context"
import { IQuoteRouteProps } from "./view"
import { QuoteV2 } from "./index.v2"
import { QuoteV3 } from "./index.v3"

const _Quote: React.FunctionComponent<IQuoteRouteProps> = (props) => {
  const configuration = useConfigurationContext()
  switch (configuration.flags.platformVersion || "v2") {
    case "v2":
      return <QuoteV2 {...props} configuration={configuration} />
    case "v3":
      return <QuoteV3 {...props} configuration={configuration} />
  }
}

export const Quote = withRouter(_Quote)
