import React from "react"
import styled, { css } from "styled-components"
import { Link } from "react-router-dom"
import { LayoutUserbar } from "../LayoutUserBar"
import { LayoutContainer } from "../LayoutContainer"
import { useConfigurationContext } from "contexts/configuration/context"
import { Layout } from "antd"
import { HeaderMenu } from "./header-menu"
import { AventusLogo } from "components/AventusLogo"
import { useAuthenticatedUserContext } from "contexts/authorization/authenticated-user-context"

const DEFAULT_HEADER_HEIGHT = "60px"

const logoStyles = css`
  min-width: 100%;
  max-width: 182px;
  height: calc(var(--avt-header-height, ${DEFAULT_HEADER_HEIGHT}) - 20px);
  vertical-align: middle;
`

const StyledLogo = styled.img`
  ${logoStyles}
`
const StyledAventusLogo = styled(AventusLogo)`
  ${logoStyles}
  transition: filter 1s ease-out;
  filter: contrast(0%) brightness(175%);
  &:hover {
    filter: contrast(100%);
  }
`

const StyledLogoLink = styled(Link)`
  flex-basis: auto;
  display: block;
  margin-right: 1em;
  line-height: calc(var(--avt-header-height, ${DEFAULT_HEADER_HEIGHT}) - 20px);
`

const NavigationWrapper = styled.div`
  flex-grow: 1;
`

const StyledHeader = styled(Layout.Header)`
  padding: 0px;
  position: sticky;
  top: 0;
  z-index: 10;
  background: var(--avt-header-background, hsl(40 50% 99%));
  height: var(--avt-header-height, ${DEFAULT_HEADER_HEIGHT});
  line-height: var(--avt-header-height, ${DEFAULT_HEADER_HEIGHT});

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    height: 4px;
    background: linear-gradient(
      180deg,
      hsl(0 0% 20% / 0.13) 0,
      hsl(0 0% 20% / 0.13) 1px,
      hsl(0 0% 20% / 0.08) 1px,
      hsl(0 0% 20% / 0) 4px
    );
  }
`

const StyledLayoutContainer = styled(LayoutContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 24px;
`

export const LayoutHeader: React.FunctionComponent<ILayoutHeader> = (props) => {
  const configuration = useConfigurationContext()
  const userContext = useAuthenticatedUserContext()

  return (
    <StyledHeader>
      <StyledLayoutContainer fluid={true}>
        <StyledLogoLink to="/">
          {configuration && configuration?.app?.logo ? (
            <StyledLogo src={configuration?.app.logo} alt="logo" />
          ) : (
            <StyledAventusLogo />
          )}
        </StyledLogoLink>
        <NavigationWrapper>
          <HeaderMenu />
        </NavigationWrapper>

        <LayoutUserbar
          userDetails={{
            nickname:
              userContext.user?.name || userContext.user?.email || "<unknown>"
          }}
        />
      </StyledLayoutContainer>
    </StyledHeader>
  )
}

interface ILayoutHeader {
  collapsed?: boolean
  theme?: string
  mode?: string
  defaultSelected?: number
}
