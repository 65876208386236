import { useState, useRef, useEffect, useCallback } from "react"
import { TPolicyRenewalsBundle } from "models/policies/policyRenewals"
import useOpus from "contexts/opus/hooks/use-opus"

export const useSetPolicyRenewals = (
  policyID: string | undefined
): IUseSetPolicyRenewals => {
  const [isSending, setIsSending] = useState(false)
  const [isError, setIsError] = useState(false)
  const [renewals, setRenewals] = useState<TPolicyRenewalsBundle | undefined>(
    undefined
  )
  const isMounted = useRef(true)
  const { policyClient } = useOpus()

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const sendRequest = useCallback(
    async (values: {
      requiresReview: boolean
      allowAutoBind: boolean
      doNotRenew: boolean
    }) => {
      if (isSending) return

      setIsSending(true)

      try {
        if (policyID) {
          const _renewals = await policyClient.setPolicyRenewals(
            values,
            policyID
          )
          setRenewals(_renewals)
        }
      } catch (error) {
        setIsError(true)
      }

      setIsSending(false)

      if (isMounted.current) setIsSending(false)
    },
    [isSending, policyClient, policyID]
  )

  return { renewals, sendRequest, isSending, isError }
}

interface IUseSetPolicyRenewals {
  sendRequest: (values: {
    requiresReview: boolean
    allowAutoBind: boolean
    doNotRenew: boolean
  }) => Promise<void>
  renewals: TPolicyRenewalsBundle | undefined
  isError: boolean
  isSending: boolean
}
