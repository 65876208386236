import styled from "styled-components"
import { breakpoint } from "utils/styles/breakpoints"
import { AventusLogo } from "../AventusLogo"
import { Button } from "antd"
import Avatar from "antd/lib/avatar/avatar"

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  flex-wrap: no-wrap;
  background-color: #eee;

  .spacer {
    position: relative;
    display: block;
    flex-grow: 1;
  }

  .otherOrgs {
    display: block;
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: #3b2053;
    padding-bottom: 1rem;
  }
`

const StyledLogo = styled.div`
  position: relative;
  width: 150px;
  margin: 0 auto;
  padding: 2rem 0 0;
`

const StyledPopBox = styled.div`
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 50px auto 2rem;
  padding: 2rem;
  background-color: #fff;
  box-shadow: 0 0 10px #ccc;
  border-radius: 3px;

  .title {
    display: block;
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: #3b2053;
    padding-bottom: 1rem;
  }

  .message {
    display: block;
    width: 100%;
    padding-bottom: 1rem;
    text-align: center;
  }

  .hr {
    width: 100%;
    height: 1px;
    margin: 1rem 0;
    background-color: #ccc;
  }

  .buttons {
    padding: 1rem 0 0;
    text-align: center;
  }

  @media (min-width: ${breakpoint.tablet}) {
    margin-top: 70px;
  }
`

const StyledOrgCard = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  max-width: 400px;
  padding: 1rem;
  margin: 0 auto 1rem;
  background: #ffffff;
  box-shadow: 0 0 10px #ccc;
  border-radius: 3px;

  .logo {
    align-self: center;
    margin-right: 1rem;
  }

  .launch {
    margin-left: auto;
    align-self: center;
  }

  .details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 1rem;

    .name {
      font-size: 16px;
      font-weight: bold;
    }
  }
`

const LayoutUnauthorized = ({
  userEmail,
  authenticatedOrgs = [],
  onLogout
}: ILayoutUnauthorizedProps): JSX.Element => {
  return (
    <StyledContainer>
      <StyledLogo>
        <AventusLogo />
      </StyledLogo>
      <StyledPopBox>
        <span className="title">
          We're sorry, but you're unable to access{" "}
          <strong>{window.location.host}</strong>
        </span>
        {userEmail && (
          <span className="message">
            You're currently logged in as <strong>{userEmail}</strong>
          </span>
        )}
        {typeof onLogout === "function" && (
          <div className="buttons">
            <Button type="primary" size="large" onClick={onLogout}>
              Logout
            </Button>
          </div>
        )}
      </StyledPopBox>
      <div className="spacer"></div>
      {authenticatedOrgs.length > 1 && (
        <span className="otherOrgs">You're already signed in to...</span>
      )}{" "}
      {authenticatedOrgs.length > 1 &&
        authenticatedOrgs.map((org) => (
          <StyledOrgCard>
            <Avatar className="logo" size={32} shape="square" src={org.logo} />
            <div className="details">
              <span className="name">{org.name}</span>
              <span className="url">{org.url}</span>
            </div>
            <Button className="launch" onClick={() => alert("To the moon!")}>
              Launch
            </Button>
          </StyledOrgCard>
        ))}
    </StyledContainer>
  )
}

interface IOrganisation {
  name: string
  url: string
  logo: string
}

interface ILayoutUnauthorizedProps {
  userEmail?: string
  onLogout?: () => void
  authenticatedOrgs?: IOrganisation[]
}

export default LayoutUnauthorized
