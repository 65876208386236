import { useState } from "react"
import { useAsyncEffect } from "use-async-effect"
import { useAsyncError } from "utils/hooks/use-catch-async-error"
import OrgSettingsClient from "../../client"
import { IOrgSettings, initialOrgSettingsState } from "../../context"

export const useGetOrgSettings = (): IUseOrgSettings => {
  const [orgSettings, setOrgSettings] = useState<IOrgSettings>(
    initialOrgSettingsState
  )
  const [orgSettingsLoaded, setOrgSettingsLoaded] = useState<boolean>(false)

  const throwError = useAsyncError()

  useAsyncEffect(
    async (isMounted) => {
      try {
        if (!orgSettingsLoaded) {
          const _orgSettings = await OrgSettingsClient.getOrgSettings()
          if (isMounted() && _orgSettings) {
            setOrgSettings(_orgSettings)
            setOrgSettingsLoaded(true)
          } else {
            throw new Error("No organisation config loaded")
          }
        }
      } catch {
        throwError(new Error("Unable to load organisation config"))
      }
    },
    [orgSettingsLoaded]
  )

  return {
    orgSettingsLoaded,
    orgSettings
  }
}

interface IUseOrgSettings {
  orgSettings: IOrgSettings
  orgSettingsLoaded: boolean
}
