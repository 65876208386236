import formatDate from "dateformat"
import { IMoney, Nullable } from "platform-client/types"
import {
  IListItem,
  INameValue,
  IAddressValue
} from "platform-client/types/difference"
import {
  ETobesDataType,
  ETobesObjectType,
  ETobesQuestionType
} from "platform-client/types/tobes"
import { currencyToHumanReadable } from "utils/pocketknife/currency-to-human-readable"

export function transformRiskValue(
  risk: unknown,
  dataType: ETobesDataType,
  objectType: ETobesObjectType,
  questionType: Nullable<ETobesQuestionType>
): string {
  if (questionType != null) {
    switch (questionType) {
      case "Picklist":
      case "Select": {
        const _risk = risk as IListItem
        return _risk?.text ?? ""
      }
      case "yesno": {
        const _risk = risk as boolean
        const ynRisk = _risk === true ? "Yes" : "No"
        return ynRisk
      }
      case "claims":
      case "itemarray":
      case "ItemArrayForm":
      case "boxmodal":
      case "singleentitymodal":
      case "singleentityboxmodal":
        return Array.isArray(risk) && risk.length > 0
          ? `${risk.length} records (expand to view)`
          : ""
    }
  }

  switch (dataType) {
    case "Decimal":
    case "Integer":
    case "String": {
      const _risk = risk as string
      return _risk
    }
    case "DateTime":
    case "LocalDateTime": {
      const _risk = risk as string
      return _risk ? formatDate(new Date(_risk), "dd/mm/yyyy") : ""
    }
    case "Boolean": {
      const _risk = risk as boolean
      return _risk === true ? "True" : "False"
    }
  }

  switch (objectType) {
    case "Money": {
      const _risk = risk as IMoney
      if (risk === null) {
        return "-"
      }
      return currencyToHumanReadable(_risk, _risk.unit === "Minor")
    }
    case "Name": {
      const _risk = risk as INameValue
      return _risk ? handleStringArray([_risk.firstName, _risk.lastName]) : ""
    }
    case "UniversalAddress": {
      const _risk = risk as IAddressValue
      return _risk ? formatAddress(_risk) : ""
    }

    case "ListItem": {
      const _risk = ((risk as { [key: string]: string }) || {}).text || ""
      return _risk
    }
  }
  return "<Unsupported>"
}

const handleStringArray = (stringArray: Nullable<string>[], separator = " ") =>
  stringArray.filter((string) => !!string).join(separator) || ""

const formatAddress = (address: IAddressValue) => {
  return handleStringArray(
    [
      handleStringArray([address.line1, address.line2, address.line3]),
      handleStringArray([
        address.province,
        address.locality,
        address.postalCode
      ])
    ],
    ", "
  )
}
