/* eslint-disable @typescript-eslint/no-explicit-any */
import { Link } from "react-router-dom"
import { Tag, Typography } from "antd"
import { ColumnsType } from "antd/lib/table"
import formatDate from "dateformat"
import { MoneyDisplay } from "components/display/MoneyDisplay"
import { IMoney } from "platform-client/types"
import { QuoteStateLabels } from "containers/quotes/constants"
import { QuoteState, QuoteType } from "platform-client/types/quote"
import { PlatformVersions } from "contexts/configuration/context"

const { Text } = Typography

export const QUOTE_COLUMNS = (
  types: {
    [key: string]: string
  },
  platformVersion: PlatformVersions = "v2"
): ColumnsType<any> => [
  {
    title: "Created",
    dataIndex: "dateCreated",
    key: "dateCreated",
    render: (date: string) => formatDate(new Date(date), "dd/mm/yyyy")
  },
  {
    title: "Quote ID",
    key: "quoteReferenceID",
    render: (row: {
      quoteReferenceID: string
      id: string | undefined
      quoteID: string
      quoteType: QuoteType
    }) => (
      <Link
        to={`/${
          row.quoteType === "Vri" && platformVersion === "v3"
            ? "quick-quote"
            : "quote"
        }/${row.id || row.quoteID}/summary`}
      >
        <u>{row.quoteReferenceID}</u>
      </Link>
    )
  },
  {
    title: "State",
    dataIndex: "quoteState",
    key: "quoteState",
    render: (quoteState: QuoteState) => {
      const labelConfig = QuoteStateLabels[quoteState]
      return (
        <Tag color={labelConfig.color}>{labelConfig.label.toUpperCase()}</Tag>
      )
    }
  },
  {
    title: "Last updated",
    dataIndex: "dateCreated",
    key: "dateCreated",
    render: (date: string) => formatDate(new Date(date), "dd/mm/yyyy")
  },
  {
    title: "Name insured",
    dataIndex: ["risk", "proposer", "personName"],
    key: "nameInsured",
    render: (person: { firstName: string; lastName: string }) =>
      person ? (
        <Text>
          {person.firstName} {person.lastName}
        </Text>
      ) : (
        "-"
      )
  },
  {
    title: "Quote amount",
    dataIndex: "quoteGross",
    key: "quoteAmount",
    align: "right",

    render: (quoteGross: IMoney) =>
      quoteGross && <MoneyDisplay value={quoteGross} />
  },
  {
    title: "Type",
    key: "quoteType",
    render: (quote) => types[quote?.quoteType] || "-"
  },
  {
    title: "Product",
    dataIndex: ["product"],
    key: "product",
    render: (product: { name: string }) => (
      <Text style={{ width: "100%", display: "block" }}>{product?.name}</Text>
    )
  },
  {
    title: "Policy ID",
    key: "policyId",
    render: (quote: { policyReferenceID: string; policyID: string }) =>
      quote.policyID ? (
        <Link to={`/policy/${quote.policyID}`}>
          <u>{quote.policyReferenceID}</u>
        </Link>
      ) : (
        "-"
      )
  }
]
