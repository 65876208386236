import React, { ReactNode } from "react"
import styled from "styled-components"
import Row from "../Row"
import { Collapse } from "components/Collapse"
import {
  AddButton,
  DisableButton,
  ExpandButton,
  WorkingButton
} from "../CellContent/Button"
import CellContentTitle from "../CellContent/Title"
import {
  ICompanyInRole,
  IExcess,
  ISubsection,
  SectionTypes
} from "platform-client/types"
import { TPlatformResponse } from "contexts/platform/client"
import Subsection from "../Subsection"
import PremiumAdjustments from "../Subsection/PremiumAdjustments"
import Commission from "../Subsection/Commission"
import TaxesAndTotals from "../Subsection/TaxesAndTotals"
import { IOrgSettings } from "contexts/organisation/context"
import Endorsements from "../Endorsements"
import { IEndorsement } from "platform-client/types/endorsement"
import IntroducerCommission from "../Subsection/IntroducerCommission"
import usePlatform from "contexts/platform/use-platform"
import { useMutation } from "react-query"
import {
  IToggleSection,
  IUpdateSectionCoverPeriod
} from "platform-client/types/quote-builder"
import { notification } from "antd"
import Excesses from "../Excesses"
import CellContentCoverPeriod from "../CellContent/CoverPeriod"
import CoverPeriod from "../CoverPeriod"
import { IUnderwritingConsolePermissions } from "services/Console/use-console"

const StyledSection = styled.div`
  background: transparent;
  border: 1px solid #ccc;
  margin-bottom: 10px;
`

const StyledSectionHeader = styled.div<{ $isOpen: boolean }>`
  border-bottom: ${(props) => (props.$isOpen ? "1px solid #ccc" : "none")};
`

const SectionHeader: React.FC<ISectionHeader> = ({
  title,
  cells,
  isOpen = false,
  enabled,
  controls,
  toggle
}) => {
  return (
    <StyledSectionHeader $isOpen={isOpen}>
      <Row
        type="header"
        disabled={!enabled}
        toggle={enabled && <ExpandButton expanded={isOpen} onClick={toggle} />}
        reference={title}
        values={cells}
        control={controls}
      />
    </StyledSectionHeader>
  )
}

const Section: React.FC<ISectionProps> = ({
  editSessionID,
  productID,
  section,
  companies,
  endorsements,
  excesses,
  onChange,
  readOnly = false,
  startExpanded,
  organisationSettings,
  permissions
}): JSX.Element => {
  const { quoteBuilder } = usePlatform()
  const toggleSection = useMutation((toggleSectionProps: IToggleSection) =>
    quoteBuilder.toggleSection(
      toggleSectionProps.editSessionID,
      toggleSectionProps.sectionID,
      toggleSectionProps.enabled
    )
  )

  const discounts =
    "netLoadingDiscounts" in section ? section.netLoadingDiscounts : null
  const subsections: ISubsection[] = section.subsections || null
  const totalNetPremium =
    "totalNetPremium" in section ? section.totalNetPremium : null
  const totalGrossPremium =
    "totalGrossPremium" in section ? section.totalNetPremium : null
  const totalNetDiscount =
    "totalNetDiscount" in section ? section.totalNetDiscount : null
  const totalNetPremiumInsurer =
    "totalNetPremiumInsurer" in section ? section.totalNetPremiumInsurer : null
  const totalSubsectionPremium =
    "totalSubsectionPremium" in section ? section.totalSubsectionPremium : null
  const totalCoverageFees =
    "totalCoverageFees" in section ? section.totalCoverageFees : null
  const commission = "commission" in section ? section.commission : null
  const tax = "tax" in section ? section.tax : null

  const shouldExpandSubSections = subsections.length < 2 && startExpanded

  const handleToggle = (section: SectionTypes) => {
    if (editSessionID) {
      toggleSection
        .mutateAsync({
          editSessionID,
          sectionID: section.id,
          enabled: !section.enabled
        })
        .then((response) => {
          if (typeof onChange === "function") {
            onChange(response)
          }
        })
        .catch((error) => {
          notification.error({
            message: "Unable to update section, please try again"
          })
        })
    }
  }

  const updateCoverPeriod = useMutation((props: IUpdateSectionCoverPeriod) =>
    quoteBuilder.updateSectionCoverPeriod(
      props.editSessionID,
      props.sectionID,
      props.data
    )
  )

  const handleUpdateCoverPeriod = (
    sectionID: string,
    coverStartDateTime: string,
    coverEndDateTime: string
  ) => {
    if (editSessionID) {
      updateCoverPeriod
        .mutateAsync({
          editSessionID,
          sectionID,
          data: {
            coverStartDateTime,
            coverEndDateTime
          }
        })
        .then((response) => {
          if (typeof onChange === "function") {
            onChange(response)
          }
        })
        .catch((error) => {
          notification.error({
            message: "Unable to update section cover dates, please try again"
          })
        })
    }
  }

  const headerControls = (
    <>
      {toggleSection.isLoading && <WorkingButton />}
      {section.enabled &&
        section.coverStartDateTime &&
        section.coverEndDateTime && (
          <CoverPeriod
            componentID={section.id}
            coverStartDateTime={section.coverStartDateTime}
            coverEndDateTime={section.coverEndDateTime}
            onChange={onChange}
            onCoverPeriodChange={handleUpdateCoverPeriod}
          />
        )}
      {section.enabled ? (
        <DisableButton onClick={() => handleToggle(section)} />
      ) : (
        <AddButton onClick={() => handleToggle(section)} />
      )}
    </>
  )

  const sectionTitle = (
    <>
      <CellContentTitle header>{section.name}</CellContentTitle>
    </>
  )

  const sectionCells =
    section.coverStartDateTime && section.coverEndDateTime
      ? [
          <CellContentCoverPeriod
            align="left"
            coverStartDateTime={section.coverStartDateTime}
            coverEndDateTime={section.coverEndDateTime}
          />
        ]
      : []

  return (
    <StyledSection>
      <Collapse
        disabled={!section.enabled}
        startExpanded={startExpanded}
        collapsedPanel={
          <SectionHeader
            title={sectionTitle || ""}
            enabled={section.enabled}
            cells={sectionCells}
            {...(!section.enabled && { aside: <i>(disabled)</i> })}
            controls={!readOnly && headerControls}
          />
        }
        expandedPanel={
          <SectionHeader
            title={sectionTitle || ""}
            enabled={section.enabled}
            cells={sectionCells}
            {...(!section.enabled && { aside: <i>(disabled)</i> })}
            controls={!readOnly && headerControls}
            isOpen
          />
        }
      >
        <Endorsements
          editSessionID={editSessionID}
          section={section}
          endorsements={endorsements}
          readOnly={!permissions.endorsements ?? readOnly}
          onChange={onChange}
        />
        <Excesses
          editSessionID={editSessionID}
          section={section}
          excesses={excesses}
          readOnly={!permissions.excesses ?? readOnly}
          onChange={onChange}
        />

        {subsections &&
          subsections.length > 0 &&
          subsections.map((subsection) => (
            <Subsection
              section={section}
              subsection={subsection}
              editSessionID={editSessionID}
              productID={productID}
              onChange={onChange}
              readOnly={readOnly}
              startExpanded={shouldExpandSubSections}
              organisationSettings={organisationSettings}
            />
          ))}

        <PremiumAdjustments
          reference="Premium Adjustment"
          discounts={discounts}
          summaryClosedText={`${section.name} Calculated Premium`}
          summaryOpenText={`${section.name} Calculated Premium`}
          minimumPremium={null}
          totalSubsectionPremium={totalSubsectionPremium}
          totalNetDiscounts={totalNetDiscount}
          section={section}
          editSessionId={editSessionID}
          onChange={onChange}
          readOnly={readOnly}
          startExpanded={false}
          organisationSettings={organisationSettings}
        />

        <Commission
          summaryOpenText="Premium after commission adjustment"
          summaryClosedText="Premium after commission adjustment"
          totalNetPremium={totalNetPremium}
          totalNetPremiumInsurer={totalNetPremiumInsurer}
          commission={commission}
          section={section}
          editSessionId={editSessionID}
          onChange={onChange}
          readOnly={readOnly}
          startExpanded={false}
          organisationSettings={organisationSettings}
        />

        {companies && companies.length > 0 && commission && (
          <IntroducerCommission
            readOnly={readOnly}
            section={section}
            editSessionID={editSessionID}
            onChange={onChange}
            companies={companies}
            commission={commission}
            organisationSettings={organisationSettings}
          />
        )}

        <TaxesAndTotals
          summaryOpenText={`${section.name} Total Premium`}
          summaryClosedText={`${section.name} Total Premium`}
          taxValues={tax}
          premiumafterCommission={totalGrossPremium}
          totalCoverageFees={totalCoverageFees}
          notExpandable
          startExpanded={shouldExpandSubSections}
          organisationSettings={organisationSettings}
        />
      </Collapse>
    </StyledSection>
  )
}

interface ISectionHeader {
  title: ReactNode
  cells?: ReactNode[]
  isOpen?: boolean
  enabled: boolean
  controls?: ReactNode
  toggle?: () => void
}

interface ISectionProps {
  startExpanded?: boolean
  editSessionID?: string
  productID?: number
  section: SectionTypes
  companies?: ICompanyInRole[]
  endorsements: IEndorsement[]
  excesses: IExcess[]
  onChange: (response: TPlatformResponse) => void
  readOnly?: boolean
  organisationSettings?: IOrgSettings
  permissions: IUnderwritingConsolePermissions
}

export default Section
