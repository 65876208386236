import React, { useEffect, useState } from "react"
import { StopOutlined, PlusOutlined } from "@ant-design/icons"
import { Layout, Table, Button, Breadcrumb } from "antd"
import { LayoutPageContent, LayoutPageHeader } from "components"
import { EMBARGOES_COLUMNS } from "./constants"
import { useGetEmabrgoes } from "../../services/hooks"
import {
  EOpusPermission,
  useOpusPermissions
} from "contexts/authorization/hooks/use-opus-permissions"
import { useOrgSettings } from "contexts/organisation/hooks/use-org-settings"
import { IOrgSettings } from "contexts/organisation/context"
import { useHistory } from "react-router-dom"

export const AllEmbargoes: React.FunctionComponent = (props) => {
  const [organisationSettings, setOrganisationSettings] = useState<
    IOrgSettings | undefined
  >(undefined)
  const history = useHistory()
  const { orgSettings } = useOrgSettings()
  const { embargoes, isEmbargoesLoaded } = useGetEmabrgoes()

  useEffect(() => {
    setOrganisationSettings(orgSettings)
  }, [orgSettings])

  const { havePermission: canCreateEmbargo } = useOpusPermissions(
    EOpusPermission.EmbargoesManagment,
    "maintain"
  )

  return (
    <Layout>
      <LayoutPageHeader
        ghost={false}
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item key="/embargoes">
              <StopOutlined />
              &nbsp;Embargoes
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        title="All Embargoes"
        extra={
          canCreateEmbargo ? (
            <Button
              type="primary"
              onClick={() => history.replace("/embargoes/new")}
              icon={<PlusOutlined />}
            >
              New embargo
            </Button>
          ) : null
        }
      />
      <LayoutPageContent>
        <Table
          scroll={{ x: "max-content" }}
          loading={!isEmbargoesLoaded}
          columns={EMBARGOES_COLUMNS(organisationSettings?.timezone)}
          dataSource={embargoes}
          rowKey="id"
          pagination={{
            defaultPageSize: 10,
            hideOnSinglePage: true,
            position: ["bottomCenter"],
            size: "small"
          }}
        />
      </LayoutPageContent>
    </Layout>
  )
}
