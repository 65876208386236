import React from "react"
import { withRouter } from "react-router"
import { useConfigurationContext } from "contexts/configuration/context"
import { AllPoliciesV2 } from "./index.v2"
import { AllPoliciesV3 } from "./index.v3"

const AllPolicies: React.FunctionComponent = (props) => {
  const configuration = useConfigurationContext()
  switch (configuration.flags.platformVersion || "v2") {
    case "v2":
      return <AllPoliciesV2 {...props} configuration={configuration} />
    case "v3":
      return <AllPoliciesV3 {...props} configuration={configuration} />
  }
}

export default withRouter(AllPolicies)
