import React from "react"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { Alert } from "antd"
import {
  ICompanyInRole,
  IPolicyDetailNoPremium,
  IPolicyDetail
} from "platform-client/types"
import Console from "components/Console"

const _Underwriting: React.FC<IUnderwriting> = ({
  policyDetail,
  policyCompanies,
  manualUnderwriting
}) => {
  const endorsements = (policyDetail && policyDetail.endorsements) || []
  const excesses = (policyDetail && policyDetail.excesses) || []
  const sections = (policyDetail && policyDetail.sections) || []
  const fees =
    (policyDetail && "fees" in policyDetail && policyDetail.fees) || []

  return (
    <div className="console-wrapper">
      <Alert
        type="warning"
        message="The console is in a read-only state. Any changes made will not persist."
        style={{ marginBottom: "10px" }}
      />
      {manualUnderwriting && (
        <Alert
          type="warning"
          message="Flagged for manual underwriting"
          closable={true}
          description="This policy has been flagged for manual underwriting, this means that no underwriting rules or rates lookup will be performed and any adjustments will be referred, requiring an underwriting review. These values below are manually adjusted and need to be updated by an underwriter to reflect any pricing changes."
          style={{ marginBottom: "10px" }}
        />
      )}
      <Console
        endorsements={endorsements}
        excesses={excesses}
        sections={sections}
        fees={fees}
        policyDetail={policyDetail}
        companies={policyCompanies}
        readOnly
      />
    </div>
  )
}

export const Underwriting = withRouter(_Underwriting)

export interface IUnderwriting extends RouteComponentProps {
  policyDetail?: IPolicyDetailNoPremium | IPolicyDetail
  policyCompanies?: ICompanyInRole[]
  manualUnderwriting?: boolean
}
