import React from "react"
import { LayoutLoading } from "components"
import { Policy, IPolicyRouteProps } from "./view"
import { IConfigurationContext } from "contexts/configuration/context"
import { AxiosError } from "axios"
import {
  IPolicyBundle,
  ICompanyInRole,
  CoverPeriodType,
  IPolicyDetail,
  EUserGroups
} from "platform-client/types"
import { useQuery } from "react-query"
import usePlatform from "contexts/platform/use-platform"
import { useParams } from "react-router-dom"

export const PolicyDetailsV3: React.FC<IPolicyDetailsV3> = (props) => {
  const { policy, user } = usePlatform()
  const [policyDetail, setPolicyDetail] = React.useState<IPolicyDetail>()
  const { id: policyID } = useParams<{ id: string }>()

  const {
    isLoading: isLoadingPolicyBundle,
    isFetched: isPolicyBundleFetched,
    error: isErrorWithPolicyBundle,
    data: policyBundle
  } = useQuery<IPolicyBundle | undefined, AxiosError>(
    ["policy", policyID],
    async () => policy.getPolicy(policyID)
  )

  const {
    isLoading: isLoadingPublicCompanies,
    data: policyCompanies,
    error: isErrorWithPolicyCompanies
  } = useQuery<ICompanyInRole[] | undefined, AxiosError>(
    ["policy", policyID, "companies"],
    () => policy.getPolicyCompanies(policyID),
    { enabled: !!policyID }
  )

  const { isLoading: isLoadingPolicyHistory, data: policyHistory } = useQuery(
    ["policy", policyID, "history"],
    () => policy.getPolicyHistorySummary(policyID),
    {
      enabled: !!policyID
    }
  )

  const { data: currentUser, isLoading: isLoadingCurrentUser } = useQuery(
    ["currentUser"],
    async () => user.getCurrentUser()
  )

  const currentUserGroupType = currentUser?.groupType || EUserGroups.Distributor

  const coverPeriods: CoverPeriodType[] | undefined =
    policyBundle?.policyDetails.policyDetailSet.items.map((item, i) => ({
      policyDetailIndex: i,
      coverStartDate: item.coverStartDateTime || "",
      coverEndDate: item.coverEndDateTime || ""
    }))

  if (isLoadingPolicyBundle && isLoadingCurrentUser) {
    return <LayoutLoading />
  }

  if (policyBundle && isPolicyBundleFetched) {
    return (
      <Policy
        {...props}
        configuration={props.configuration}
        policy={policyBundle.policyDetails.policy}
        policyPrice={policyBundle.price}
        policyCompanies={policyCompanies}
        policyDetail={policyDetail || policyBundle.policyDetails.policyDetail}
        policyHistorySummary={{
          data: policyHistory,
          isLoading: isLoadingPolicyHistory
        }}
        policyEndorsements={
          policyBundle.policyDetails.policyDetailSet.latest.endorsements || []
        }
        policyDetailSet={policyBundle.policyDetails.policyDetailSet}
        coverPeriods={coverPeriods}
        policyHolder={policyBundle.policyDetails.policyholder}
        risk={policyBundle.policyDetails.risk}
        product={policyBundle.product}
        isWorking={isLoadingPublicCompanies}
        error={
          (isErrorWithPolicyBundle && {
            errorNumber: isErrorWithPolicyBundle.response?.status
          }) ||
          (isErrorWithPolicyCompanies && {
            errorNumber: isErrorWithPolicyCompanies.response?.status
          }) ||
          undefined
        }
        handleCoverPeriodChange={(selection: number) => {
          setPolicyDetail(
            selection !== -1
              ? policyBundle.policyDetails.policyDetailSet.items[selection]
              : undefined
          )
        }}
        currentUserGroupType={currentUserGroupType}
      />
    )
  }

  return null
}

export interface IPolicyDetailsV3 extends IPolicyRouteProps {
  configuration: IConfigurationContext
}
