import { AutoComplete, Input } from "antd"
import usePlatform from "contexts/platform/use-platform"
import { IPolicyholder } from "platform-client/types"
import React from "react"
import Highlighted from "components/Highlighted"
import { useQuery } from "react-query"
import styled from "styled-components"

const StyledPolicyholderRef = styled.span`
  color: #aaa;
`

const StyledPolicyholderEmail = styled.span`
  font-style: italic;
  color: #aaa;
`

const PolicyholderLookup = ({
  onSelectPolicyholder
}: IPolicyholderLookupProps): JSX.Element => {
  const [query, setQuery] = React.useState("")
  const platform = usePlatform()

  const { data: policyHolders, isLoading } = useQuery(
    ["policyholder", "search", query],
    () => platform.policyholders.searchPolicyholders({ searchTerm: query }),
    {
      enabled: query.length >= 3
    }
  )

  return (
    <AutoComplete
      style={{ width: "100%" }}
      value={query}
      onChange={setQuery}
      onSelect={(value) => {
        // We have to convert the value back from a string
        // because ANTD does not support object values.

        if (typeof onSelectPolicyholder === "function") {
          onSelectPolicyholder(JSON.parse(value) as IPolicyholder)
        }
      }}
      options={
        Array.isArray(policyHolders)
          ? policyHolders.map((policyholder) => ({
              value: JSON.stringify(policyholder),
              label: (
                <>
                  <Highlighted
                    text={[
                      policyholder.legalName,
                      policyholder.businessName,
                      policyholder.contact.name.firstName &&
                        policyholder.contact.name.lastName &&
                        policyholder.contact.name.firstName +
                          " " +
                          policyholder.contact.name.lastName
                    ]
                      .filter((item) => item)
                      .join(", ")
                      .trim()}
                    search={query}
                  />
                  {policyholder.contact.emailAddress && (
                    <>
                      {" - "}
                      <StyledPolicyholderEmail>
                        {policyholder.contact.emailAddress}
                      </StyledPolicyholderEmail>
                    </>
                  )}
                  {" - "}
                  <StyledPolicyholderRef>
                    {policyholder.referenceID}
                  </StyledPolicyholderRef>
                </>
              )
            }))
          : []
      }
    >
      <Input.Search size={"large"} loading={isLoading} />
    </AutoComplete>
  )
}

interface IPolicyholderLookupProps {
  onSelectPolicyholder?: (data: IPolicyholder) => void
}

export default PolicyholderLookup
