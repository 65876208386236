import React from "react"
import styled from "styled-components"

const StyledCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const CardHeader: React.FC<ICardHeaderProps> = (props) => (
  <StyledCardHeader>
    <div>{props.title}</div>
    {props.children}
  </StyledCardHeader>
)

interface ICardHeaderProps {
  title: string
  children: React.ReactNode
}
