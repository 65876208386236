import { useState } from "react"
import { useAsyncEffect } from "use-async-effect"
import { TErrorState } from "models/errors"
import {
  errorValidationMessage,
  IErrorState
} from "utils/pocketknife/error-validation-message"
import useOpus from "contexts/opus/hooks/use-opus"
import { IPolicyAction } from "models/policies/policyAction"

export const useGetPolicyActions = (
  policyID: string | undefined
): IPolicyActions => {
  const [actions, setPolicyActions] = useState<IPolicyAction[] | undefined>(
    undefined
  )
  const [isError, setIsError] = useState<TErrorState>(undefined)
  const [areActionsLoading, setActionsLoading] = useState<boolean>(false)
  const { policyClient } = useOpus()

  useAsyncEffect(async () => {
    try {
      setActionsLoading(true)
      if (policyID) {
        const actions = await policyClient.getPolicyActions(policyID)
        if (actions) {
          setPolicyActions(actions.actions)
          setIsError(undefined)
        }
      }
      setActionsLoading(false)
    } catch (error) {
      setPolicyActions(undefined)
      setIsError({
        errorNumber: error?.response?.status,
        validationMessage: errorValidationMessage(error)
      })
      setActionsLoading(false)
    }
  }, [policyID])

  return {
    actions,
    isError,
    areActionsLoading
  }
}

interface IPolicyActions extends IErrorState {
  actions?: IPolicyAction[]
  areActionsLoading: boolean
}
