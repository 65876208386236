import React from "react"
import { Card } from "antd"
import { TPolicyPayment } from "models/policies/policyPayment"
import AllTransactionsTable from "./alltransactions"

export const PaymentTransactions: React.FunctionComponent<IPolicyPaymentTransactions> =
  (props) => {
    return (
      <>
        <Card
          title="Payment Transactions"
          loading={props.isLoading}
          style={{ marginBottom: "20px" }}
        >
          <Card loading={props.isLoading}>
            <Card.Grid
              hoverable={false}
              style={{
                width: "calc(100% - 2px)",
                minWidth: "100%",
                padding: 0,
                margin: "1px 1px 0 1px",
                border: "none",
                boxShadow: "none"
              }}
            >
              <AllTransactionsTable
                isLoading={props.isLoading}
                policyPayments={props.data}
                downloadReceipt={props.downloadReceipt}
                emailReceipt={props.emailReceipt}
                isDownloading={props.isDownloading}
                isEmailing={props.isEmailing}
                linkedEmailAddress={props.emailAddress}
              />
            </Card.Grid>
          </Card>
        </Card>
      </>
    )
  }
export interface IPolicyPaymentTransactions {
  isLoading: boolean
  data: TPolicyPayment[] | undefined
  downloadReceipt: (paymentId: string) => Promise<void>
  emailReceipt: (paymentId: string, emailAddress: string) => Promise<void>
  isDownloading: boolean
  isEmailing: boolean
  emailAddress: string
}

export default PaymentTransactions
