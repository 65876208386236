import * as rax from "retry-axios"

import { IApiInformation } from "./hooks/use-api-info"
import { IApplicationConfig } from "./hooks/use-get-app-config"
import { IConfigurationContext } from "./context"
import { ITravelPackageConfig } from "./hooks/use-get-travel-package-config"
import axios from "axios"
import { createUnauthenticatedPlatformClient } from "utils/api/axiosConfig"
import { path } from "ramda"

const getConfiguration = (
  name: string | undefined
): Promise<IConfigurationContext | undefined> => {
  const configurationAxios = axios.create()

  configurationAxios.defaults.raxConfig = {
    instance: configurationAxios,
    retry: 3
  }

  rax.attach(configurationAxios)

  return configurationAxios
    .get(`/configuration/${name}.json`, {
      // Specificially force no-cache when requesting this item as it should
      // always load the latest copy. This is also a fix for private browsing.
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache"
      }
    })
    .then(path(["data"]))
}

const getApiInfo = (): Promise<IApiInformation | undefined> => {
  return createUnauthenticatedPlatformClient()
    .get("/info")
    .then(path(["data", "data"]))
}

const getApplicationConfiguration = (): Promise<
  IApplicationConfig | undefined
> =>
  createUnauthenticatedPlatformClient()
    .get("/v2/config/app")
    .then(path(["data", "data"]))

const getTravelPackages = (
  productReference: string,
  productVersion: number
): Promise<ITravelPackageConfig[]> =>
  createUnauthenticatedPlatformClient()
    .get(`/v2/config/travel/${productReference}/${productVersion}/packages`)
    .then(path(["data", "data"])) as Promise<ITravelPackageConfig[]>

const ConfigurationExports = {
  getApplicationConfiguration,
  getConfiguration,
  getApiInfo,
  getTravelPackages
}

export default ConfigurationExports
