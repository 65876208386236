import { useMemo } from "react"
import { Select, Space } from "antd"
import { useHistory } from "react-router-dom"
import { lowerCase, upperFirst } from "lodash"
import { IInvoiceStatus } from "contexts/configuration/context"

const { Option } = Select

export const FilterComponent: React.FC<IFilterComponent> = (props) => {
  const history = useHistory()
  const searchParams = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search]
  )

  const value = upperFirst(searchParams.get("status") || "")

  return (
    <Space style={{ marginLeft: 16 }}>
      <Select
        allowClear
        value={value}
        style={{ width: 160 }}
        onChange={(option: IInvoiceStatus | string) => {
          if (option) {
            searchParams.set("status", lowerCase(option))
          } else {
            searchParams.delete("status")
          }

          history.push({
            pathname: history.location.pathname,
            search: searchParams.toString()
          })
          props.setInvoicesStatus(option)
        }}
      >
        {props.states.map((key: string) => (
          <Option key={key} value={key}>
            {key}
          </Option>
        ))}
      </Select>
    </Space>
  )
}

interface IFilterComponent {
  invoicesStatus?: string
  setInvoicesStatus: (status: string | undefined) => void
  states: string[]
}
