import {
  Button,
  Form,
  Input,
  List,
  Modal,
  Row,
  notification,
  ConfigProvider,
  Empty
} from "antd"
import { AxiosError } from "axios"
import usePlatform from "contexts/platform/use-platform"
import {
  IChangeEmailExistingAccountCheckResult,
  IEditEmailParams
} from "platform-client/types"
import React, { useEffect } from "react"
import { useMutation } from "react-query"
import styled from "styled-components"
import { handleValidationErrors } from "utils/pocketknife/handle-validation-errors"

const StyledInput = styled(Input)`
  flex: 1;
`
const StyledSpan = styled.span`
  flex: 2;
`
const StyledFloatRightButton = styled(Button)`
  float: right;
`

const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`
export const PolicyEmailAdjustment: React.FunctionComponent<IPolicyEmailAdjusment> =
  (props) => {
    const { user } = usePlatform()
    const [form] = Form.useForm()

    const handleEditEmailAddress = () => {
      setEditEmailAddressModalVisible(true)
    }

    const handleModalCancel = () => {
      form.resetFields()
      setIsUpdating(false)
      setIsValidating(false)
      setValidateEmailAddressResult(undefined)
      setEditEmailAddressModalVisible(false)
    }

    const handleCheckEmailAddress = useMutation(
      (param: IEditEmailParams) =>
        user.validateEditUserEmail(param.ownerId, {
          email: param.emailValue,
          policyId: param.policyId
        }),
      {
        onSuccess: (data) => {
          setValidateEmailAddressResult(data)
          setIsValidating(false)
        },
        onError: (error: AxiosError) => {
          handleValidationErrors(error)
          setIsValidating(false)
        }
      }
    )

    const handleUpdate = useMutation(
      (param: IEditEmailParams) => {
        if (validateEmailAddressResult?.hasExistingAccount) {
          return user.editUserEmailExistingAccount(param.ownerId, {
            email: param.emailValue,
            policyId: param.policyId
          })
        } else {
          return user.editUserEmail(param.ownerId, {
            email: param.emailValue,
            policyId: param.policyId
          })
        }
      },
      {
        onSuccess: (data) => {
          if (data.email) {
            setEmailAddress(data.email)
          }
          notification.success({
            message: `Email updated`
          })
          form.resetFields()
          setIsUpdating(false)
          setIsValidating(false)
          props.refetchTimelineEvents()
          setValidateEmailAddressResult(undefined)
          setEditEmailAddressModalVisible(false)
        },
        onError: (error: AxiosError) => {
          handleValidationErrors(error)
          setIsUpdating(false)
        }
      }
    )

    const renderModalFooter = () => {
      return (
        <Row justify="space-between">
          <Button onClick={handleModalCancel}>Cancel</Button>
          <Button
            type="primary"
            loading={isUpdating}
            onClick={() => {
              form.validateFields().then((values) => {
                setIsUpdating(true)
                handleUpdate.mutate({
                  emailValue: values.emailAddress,
                  ownerId: props.ownerId,
                  policyId: props.policyId
                })
              })
            }}
            disabled={validateEmailAddressResult === undefined || isUpdating}
          >
            Update Email Address
          </Button>
        </Row>
      )
    }

    const [editEmailAddressModalVisible, setEditEmailAddressModalVisible] =
      React.useState<boolean>(false)

    const [emailAddress, setEmailAddress] = React.useState<string>(
      props.emailAddressValue
    )
    const [isValidating, setIsValidating] = React.useState<boolean>(false)
    const [isUpdating, setIsUpdating] = React.useState<boolean>(false)
    const [validateEmailAddressResult, setValidateEmailAddressResult] =
      React.useState<IChangeEmailExistingAccountCheckResult | undefined>(
        undefined
      )

    //this weirdness comes from here, the inital emailAddress sometimes isn't set
    //https://javascript.plainenglish.io/how-to-fix-the-react-usestate-hook-not-setting-initial-value-problem-7def65b44362
    useEffect(() => {
      setEmailAddress(props.emailAddressValue)
    }, [props.emailAddressValue])

    return (
      <>
        <InputWrapper>
          <>
            <StyledSpan>{emailAddress}</StyledSpan>
            {props.isExternal === false && (
              <StyledFloatRightButton
                type="primary"
                onClick={handleEditEmailAddress}
              >
                Edit
              </StyledFloatRightButton>
            )}
          </>
        </InputWrapper>
        <Modal
          title={`Update Email Address`}
          visible={editEmailAddressModalVisible}
          onCancel={handleModalCancel}
          footer={renderModalFooter()}
        >
          <Form form={form} name="editEmailAddress">
            <Form.Item label="Current email address">
              <StyledSpan>{emailAddress}</StyledSpan>
            </Form.Item>
            <Form.Item name="new_email_address" label="New email address">
              <Form.Item
                name="emailAddress"
                style={{ display: "inline-block", width: "calc(75% - 8px)" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter an email address"
                  }
                ]}
              >
                <StyledInput />
              </Form.Item>
              <Button
                style={{
                  display: "inline-block",
                  width: "25%",
                  float: "right"
                }}
                onClick={() => {
                  form.validateFields().then((values) => {
                    setIsValidating(true)
                    setValidateEmailAddressResult(undefined)
                    handleCheckEmailAddress.mutate({
                      emailValue: values.emailAddress,
                      ownerId: props.ownerId,
                      policyId: props.policyId
                    })
                  })
                }}
                loading={isValidating}
              >
                Check
              </Button>
            </Form.Item>
            <ConfigProvider
              renderEmpty={() => (
                <Empty
                  image={
                    validateEmailAddressResult
                      ? Empty.PRESENTED_IMAGE_SIMPLE
                      : false
                  }
                  description={
                    validateEmailAddressResult
                      ? "No policies owned by the email address"
                      : "Enter an email address first"
                  }
                />
              )}
            >
              <List
                header="Policies owned by the new email address"
                dataSource={validateEmailAddressResult?.referenceIDs}
                pagination={{
                  defaultPageSize: 5,
                  hideOnSinglePage: true,
                  position: "bottom",
                  size: "small"
                }}
                renderItem={(item) => (
                  <List.Item>
                    <span>{item}</span>
                  </List.Item>
                )}
              />
            </ConfigProvider>
          </Form>
        </Modal>
      </>
    )
  }

export interface IPolicyEmailAdjusment {
  emailAddressValue: string
  policyId: string
  ownerId: string
  isExternal: boolean
  refetchTimelineEvents: VoidFunction
}
