import { useState, useEffect } from "react"
import { AutoComplete, Spin } from "antd"
import {
  useAddressService as useAddressServiceDI,
  TAddress,
  TAddressResult,
  AddressServiceType
} from "utils/addressService"
import { useDebounce } from "use-debounce/lib"
import { useMutation, useQuery } from "react-query"
import { IUniversalAddress } from "platform-client/types"

const MIN_CHARACTERS = 4
const DEBOUNCE_DELAY = 200

interface IAddressAutocompleteProps {
  setAddress: (addressObject?: TAddress) => void
  address?: IUniversalAddress | TAddress
  useAddressService?: AddressServiceType
}

type TFormattedOption = {
  value: string
  label: string
  key: string
}

const formatOptions = (result: TAddressResult[] = []): TFormattedOption[] =>
  result.map((option) => ({
    value: option.address,
    label: option.address,
    key: option.key
  }))

export const AddressAutocomplete: React.FC<IAddressAutocompleteProps> = ({
  address,
  setAddress,
  useAddressService = useAddressServiceDI
}) => {
  const addressService = useAddressService()

  const addressAsString = address
    ? Object.keys(address)
        .map((key) => {
          const k = key as keyof TAddress
          return key &&
            !["country", "uniqueReference", "provinceReferenceID"].includes(key)
            ? address[k]
            : null
        })
        .filter((x) => x)
        .join(", ")
    : ""

  const [options, setOptions] = useState<TFormattedOption[]>([])
  const [query, setQuery] = useState("")
  const [debouncedQuery] = useDebounce<string>(query, DEBOUNCE_DELAY)

  useEffect(() => {
    if (addressAsString) {
      setQuery(addressAsString)
    }
  }, [addressAsString])

  useQuery(
    ["addressAutocomplete", debouncedQuery],
    () => addressService.search(debouncedQuery),
    {
      onSuccess: (responseData) => {
        const newOptions = formatOptions(responseData)
        setOptions(newOptions)
      },
      enabled: debouncedQuery?.length > MIN_CHARACTERS
    }
  )

  const handleSelect = useMutation(
    (option: TFormattedOption) => addressService.format(option.key),
    {
      onSuccess: (result) => {
        setAddress(result)
      }
    }
  )

  return (
    <Spin spinning={handleSelect.isLoading}>
      <AutoComplete
        // required for storybook - it was shrnking
        style={{ minWidth: "250px" }}
        placeholder={"Please start entering address"}
        onSelect={(_: unknown, option: unknown) =>
          handleSelect.mutate(option as TFormattedOption)
        }
        value={query}
        onChange={setQuery}
        options={options}
        allowClear
        backfill
      />
    </Spin>
  )
}
