import React from "react"
import { IMoney } from "platform-client/types"
import { currencyToHumanReadable } from "utils/pocketknife/currency-to-human-readable"
import { useConfigurationContext } from "contexts/configuration/context"

type IMoneyDisplayProps = {
  value: IMoney | null | undefined
  isMajorCurrency?: boolean
  hideZeroMinorCurrency?: boolean
  showSymbols?: boolean
  nullValueString?: string
}

export const MoneyDisplay: React.FC<IMoneyDisplayProps> = ({
  value,
  isMajorCurrency,
  hideZeroMinorCurrency = false,
  showSymbols = false,
  nullValueString = ""
}) => {
  const configuration = useConfigurationContext()

  if (
    value === undefined ||
    value === null ||
    value.value === undefined ||
    value.value === null
  )
    return <>{nullValueString}</>

  const isNotApiV3 = configuration.flags.platformVersion !== "v3"
  const isPennies =
    isMajorCurrency !== undefined ? !isMajorCurrency : isNotApiV3

  return (
    <>
      {showSymbols && value.value > 0 && "+"}
      {currencyToHumanReadable(value, isPennies, hideZeroMinorCurrency)}
    </>
  )
}
