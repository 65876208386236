import moment from "moment"
import styled from "styled-components"
import CSS from "csstype"

const StyledCoverPeriod = styled.span<{ $align: "left" | "right" | "center" }>`
  display: inline-block;
  min-width: 150px;
  margin-left: 10px;
  color: #888888;
  font-style: italic;
  text-align: ${(props) => props.$align || "left"};
`

const CellContentCoverPeriod: React.FC<ICellContentCoverPeriodProps> = ({
  align = "left",
  ...props
}) => {
  if (props.coverStartDateTime === null && props.coverEndDateTime === null) {
    return null
  }

  const coverStartDateTime = moment.utc(props.coverStartDateTime)
  const coverEndDateTime = moment.utc(props.coverEndDateTime)

  return (
    <StyledCoverPeriod $align={align} style={props.style}>
      {`${coverStartDateTime.format(
        "D MMM YY"
      )}\u00A0-\u00A0${coverEndDateTime.format("D MMM YY")}`}
    </StyledCoverPeriod>
  )
}

export interface ICellContentCoverPeriodProps {
  align?: "left" | "right" | "center"
  style?: CSS.Properties
  coverStartDateTime: string | null
  coverEndDateTime: string | null
}

export default CellContentCoverPeriod
