import { Layout, notification } from "antd"
import { Content } from "antd/lib/layout/layout"
import { LayoutPageContent, LayoutPageHeader } from "components"
import CreatePolicyholder from "containers/opportunities/views/CreateOpportunity/sub-views/CreatePolicyholder"
import usePlatform from "contexts/platform/use-platform"
import { IConvertQuickQuoteRequest } from "platform-client/client/controllers/quick-quote"
import { IPolicyholder } from "platform-client/types"
import { useMutation } from "react-query"
import { useHistory, useParams, withRouter } from "react-router-dom"

const ConvertQuickQuote = (): JSX.Element => {
  const { quickQuote } = usePlatform()
  const { id: quoteID } = useParams<{ id: string }>()
  const history = useHistory()

  const convertQuickQuote = useMutation(
    (params: { quoteID: string; body: IConvertQuickQuoteRequest }) =>
      quickQuote.convertQuickQuote(params.quoteID, params.body)
  )

  const handleConvertQuote = (policyholder: IPolicyholder) => {
    convertQuickQuote
      .mutateAsync({
        quoteID: quoteID,
        body: {
          policyholderID: policyholder.id,
          name: `Quick quote conversion for ${policyholder?.referenceID}`,
          openSession: true
        }
      })
      .then((response) => {
        notification.success({
          message: "Quote converted successfully. Redirecting..."
        })
        setTimeout(() => {
          history.push(
            `/opportunities/${response.opportunity.id}/variants/${response.variant.id}/edit?skipSummary=true`
          )
        }, 2000)
      })
      .catch((error) => {
        // Generic error for now.
        notification.error({
          message:
            "Sorry we were not able to create this opportunity, please try again."
        })
      })
  }

  return (
    <Layout>
      <LayoutPageHeader
        ghost={false}
        title={"Convert to full quote"}
        onBack={() => history.goBack()}
      >
        <Content>
          <p>Select or create a policyholder and continue to full quote.</p>
        </Content>
      </LayoutPageHeader>
      <LayoutPageContent>
        <CreatePolicyholder
          onSavePolicyholder={handleConvertQuote}
          isLoading={convertQuickQuote.isLoading}
        />
      </LayoutPageContent>
    </Layout>
  )
}

export default withRouter(ConvertQuickQuote)
