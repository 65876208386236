import React from "react"
import { LayoutLoading } from "components"
import { IQuoteRouteProps, OnUpdateReferral, Quote } from "./view"
import { IConfigurationContext } from "contexts/configuration/context"
import usePlatform from "contexts/platform/use-platform"
import { useMutation, useQuery, useQueryClient } from "react-query"
import {
  IConfirmReferralQuoteProps,
  IDeclineReferralQuoteProps,
  IUpdateReferralProps
} from "platform-client/client/controllers/quote"
import { useHistory } from "react-router"
import { IBindQuoteParams } from "platform-client/types/pay-on-account"
import { IPaymentPlan } from "platform-client/types/pricing"
import { notification } from "antd"
import { CoverPeriodType, IPolicyDetail } from "platform-client/types"
import { TErrorState } from "models/errors"

export const QuoteV3: React.FC<IQuoteV3> = (props) => {
  const quoteID = props.match.params.id
  const platform = usePlatform()
  const history = useHistory()
  const queryClient = useQueryClient()
  const [selectedPolicyDetail, setSelectedPolicyDetail] =
    React.useState<IPolicyDetail>()

  const {
    isLoading,
    isFetching,
    isFetched,
    data: quoteBundle,
    refetch: refetchQuote
  } = useQuery(["quote", quoteID], async () => platform.quote.getQuote(quoteID))

  const quoteCompaniesQuery = useQuery(["quoteCompanies", quoteID], () =>
    platform.quote.quoteCompanies(quoteID)
  )

  const quoteEvents = useQuery(
    ["quote", "events", quoteID],
    async () => {
      return platform.quote.getQuoteEvents(quoteID)
    },
    {
      enabled: !!quoteID
    }
  )

  const quoteActions = useQuery(
    ["quote", "actions", quoteID],
    async () => platform.quote.getQuoteActions(quoteID),
    { enabled: !!quoteID }
  )

  const {
    isLoading: isQuoteDocumentsLoading,
    data: quoteDocuments,
    refetch: refetchDocuments
  } = useQuery(["quoteDocments", quoteID], async () =>
    platform.quote.getDocuments(quoteID)
  )

  const updateReferral = useMutation(
    (updateReferralProps: IUpdateReferralProps) =>
      platform.quote.updateReferral(
        updateReferralProps.quoteID,
        updateReferralProps.referral
      )
  )

  const confirmReferralQuote = useMutation(
    (vars: IConfirmReferralQuoteProps) =>
      platform.quote.confirmReferralQuote(vars.quoteID, vars.note),
    {
      onSuccess: (data, vars) => {
        history.push(`/quote/${vars.quoteID}/summary`)
        queryClient.setQueriesData(["quote", vars.quoteID], data)
        quoteEvents.refetch()
      }
    }
  )

  const declineReferralQuote = useMutation(
    (vars: IDeclineReferralQuoteProps) =>
      platform.quote.declineReferralQuote(
        vars.quoteID,
        vars.declineReasonReferenceID,
        vars.note
      ),
    {
      onSuccess: (data, vars) => {
        history.push(`/quote/${vars.quoteID}/summary`)
        queryClient.setQueriesData(["quote", vars.quoteID], data)
        quoteEvents.refetch()
      }
    }
  )

  const payOnAccount = useMutation((bindQuoteParams: IBindQuoteParams) =>
    platform.payOnAccount.bindQuote(
      bindQuoteParams.quoteID,
      bindQuoteParams.pricingPlanReferenceID
    )
  )

  const createNote = useMutation((vars: { quoteId: string; content: string }) =>
    platform.quote.createNote(vars.quoteId, {
      content: vars.content,
      isInternalOnly: false
    })
  )

  const handleAddNote = (
    vars: { quoteId: string; content: string },
    onSuccess?: (noteId: string) => void,
    onFailure?: (error: TErrorState) => void
  ) => {
    createNote
      .mutateAsync(vars)
      .then((response) => {
        onSuccess?.((response as { id: string })?.id)
        queryClient.invalidateQueries(["quoteNotes", quoteID])
      })
      .catch((error) => {
        onFailure?.(error)
      })
  }

  const handleAcknowledgeReferral: OnUpdateReferral = async (
    vars,
    onSuccess,
    onFailure
  ) => {
    updateReferral
      .mutateAsync({
        quoteID: vars.quoteID,
        referral: {
          id: vars.referral.id,
          status: "Accepted"
        }
      })
      .then((response) => {
        refetchQuote()
        quoteEvents.refetch()
        if (typeof onSuccess === "function") {
          onSuccess(response)
        }
      })
  }

  const handleBindQuote = (
    pricingPlan: IPaymentPlan,
    callback?: () => void
  ) => {
    switch (pricingPlan.price.planType) {
      case "PayOnAccount":
        payOnAccount
          .mutateAsync({
            quoteID,
            pricingPlanReferenceID: pricingPlan.price.pricingPlanReferenceID
          })
          .then((response) => {
            notification.success({ message: "Quote bound successfully." })
            if (typeof callback === "function") {
              callback()
            }
            setTimeout(
              () => history.push(`/policy/${response.policyDetails.policy.id}`),
              1500
            )
          })
          .catch((error) => {
            notification.error({
              message: "Sorry there has been an error binding quote."
            })
          })
        break
      default:
        notification.error({
          message: "Payment plan type not supported"
        })
    }
  }

  const uploadDocument = useMutation((formData: FormData) =>
    platform.document.uploadDocument(formData)
  )

  const handleDocumentUpload = async (
    document: FormData,
    callback?: (result: boolean) => void
  ) => {
    // Need to add the quote ID
    document.append("quoteID", quoteID)

    uploadDocument
      .mutateAsync(document)
      .then((result) => {
        if (typeof callback === "function") {
          callback(result)
        }
        refetchDocuments()
      })
      .catch(() => {
        notification.error({
          message: "Unable to upload document, please try again."
        })
      })
  }

  const revokeManualReferral = useMutation((quoteID: string) =>
    platform.quote.revokeManualReferral(quoteID)
  )

  const handleRevokeManualReferral = () => {
    revokeManualReferral
      .mutateAsync(quoteID)
      .then(() => {
        handleQuoteUpdate()
      })
      .catch(() => {
        notification.error({
          message:
            "Unable to revoke referral, please try again or contact support if this issue persists."
        })
      })
  }

  const referrals =
    (quoteBundle && quoteBundle.quoteDetails.policyDetail?.referrals) || []

  const coverPeriods: CoverPeriodType[] | undefined =
    quoteBundle?.quoteDetails.policyDetailSet.items.map((item, i) => ({
      policyDetailIndex: i,
      coverStartDate: item.coverStartDateTime || "",
      coverEndDate: item.coverEndDateTime || ""
    }))

  const handleQuoteUpdate = () => {
    refetchQuote()
    quoteEvents.refetch()
    quoteActions.refetch()
  }

  if (isLoading || quoteCompaniesQuery.isLoading) {
    return <LayoutLoading />
  }

  if (quoteBundle && quoteCompaniesQuery.isFetched) {
    return (
      <Quote
        {...props}
        configuration={props.configuration}
        isWorking={isFetching}
        quote={quoteBundle.quoteDetails.quote}
        risk={quoteBundle.quoteDetails.risk}
        quoteProduct={{
          product: quoteBundle.product,
          isGettingQuoteProduct: isLoading
        }}
        quoteDocuments={{
          documents: quoteDocuments?.documents,
          isGettingDocuments: isQuoteDocumentsLoading,
          version: 3
        }}
        originalPolicyMeta={quoteBundle.policyMeta}
        quoteEndorsements={
          quoteBundle.quoteDetails.policyDetailSet.latest.endorsements ||
          undefined
        }
        quoteExcesses={
          quoteBundle.quoteDetails.policyDetailSet.latest.excesses || undefined
        }
        quoteCompanies={quoteCompaniesQuery.data}
        quotePaymentPlans={{
          paymentPlans: quoteBundle.pricingPlans,
          isGettingPaymentPlans: isLoading,
          isPaymentPlansFinished: isFetched
        }}
        quoteEvents={{
          events: quoteEvents.data,
          isGettingEvents: quoteEvents.isLoading
        }}
        quoteChanges={quoteBundle.changes}
        quoteActions={quoteActions?.data?.actions}
        quoteReferrals={referrals}
        isFetchingQuote={isFetching}
        onUpdateReferral={handleAcknowledgeReferral}
        confirmReferralQuote={confirmReferralQuote}
        declineReferralQuote={declineReferralQuote}
        onQuoteChange={handleQuoteUpdate}
        policyDetail={
          selectedPolicyDetail ||
          quoteBundle.quoteDetails.policyDetailSet.latest
        }
        policyDetailSet={quoteBundle.quoteDetails.policyDetailSet}
        quotePolicyHolder={quoteBundle.policyHolder}
        handleBindQuote={handleBindQuote}
        handleCoverPeriodChange={(selector: number) => {
          setSelectedPolicyDetail(
            selector !== -1
              ? quoteBundle?.quoteDetails.policyDetailSet.items[selector]
              : undefined
          )
        }}
        quoteLifecycles={{
          onAddNote: handleAddNote as () => Promise<void>
        }}
        coverPeriods={coverPeriods}
        handleDocumentUpload={handleDocumentUpload}
        handleRevokeManualReferral={handleRevokeManualReferral}
        isManualReferralLoading={revokeManualReferral.isLoading}
        declineReasons={quoteBundle.quoteDetails.policyDetail.declineReasons}
        quoteOpportunity={quoteBundle.opportunity}
      />
    )
  }

  return null
}

export interface IQuoteV3 extends IQuoteRouteProps {
  configuration: IConfigurationContext
}
