import { useState } from "react"
import { useAsyncEffect } from "use-async-effect"
import { IPolicyTable } from "models/policies/policyTable"
import useOpus from "contexts/opus/hooks/use-opus"
import { TErrorState } from "models/errors"
import {
  errorValidationMessage,
  IErrorState
} from "utils/pocketknife/error-validation-message"

export const useGetPolicies = (
  searchValue: string | undefined | null
): IUseGetPolicies => {
  const [policies, setPolicies] = useState<IPolicyTable[] | []>([])
  const [isError, setIsError] = useState<TErrorState>(undefined)
  const [arePoliciesLoading, setPoliciesLoading] = useState<boolean>(false)
  const { policyClient } = useOpus()

  useAsyncEffect(async () => {
    try {
      setPoliciesLoading(true)
      if (!searchValue) {
        const _policies = await policyClient.getPolicies()
        if (_policies) {
          setPolicies(_policies)
          setIsError(undefined)
        }
      }
      setPoliciesLoading(false)
    } catch (error) {
      setPolicies([])
      setIsError({
        errorNumber: error?.response?.status,
        validationMessage: errorValidationMessage(error)
      })
      setPoliciesLoading(false)
    }
  }, [searchValue])

  return {
    policies,
    isError,
    arePoliciesLoading
  }
}

interface IUseGetPolicies extends IErrorState {
  policies: IPolicyTable[] | undefined
  arePoliciesLoading: boolean
}
