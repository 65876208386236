import { Button, Card, Descriptions } from "antd"
import { Address } from "components/Address"
import { policyholderMap } from "models/policyholder"
import { IPolicyholder } from "platform-client/types"

const PolicyholderCard = ({
  policyholder,
  onEdit,
  onClear
}: IPolicyholderCardProps): JSX.Element => {
  return (
    <Card
      style={{ marginBottom: "15px" }}
      actions={[
        ...(typeof onEdit === "function"
          ? [
              <Button type="link" onClick={() => onEdit()}>
                Edit
              </Button>
            ]
          : []),
        ...(typeof onClear === "function"
          ? [
              <Button type="link" onClick={() => onClear()}>
                Clear
              </Button>
            ]
          : [])
      ]}
    >
      <Card.Meta
        style={{ marginBottom: "15px" }}
        title={policyholder.legalName}
        description={policyholderMap[policyholder.policyholderType]}
      />
      <Descriptions size="small" column={1}>
        {policyholder.businessName && (
          <Descriptions.Item label="Business name">
            {policyholder.businessName || ""}
          </Descriptions.Item>
        )}
        {policyholder.contact.name.firstName && (
          <Descriptions.Item label="Contact name">
            {policyholder.contact.name.firstName}{" "}
            {policyholder.contact.name.lastName}
          </Descriptions.Item>
        )}
        {policyholder.contact.phoneNumber && (
          <Descriptions.Item label="Phone number">
            {policyholder.contact.phoneNumber}
          </Descriptions.Item>
        )}
        {policyholder.contact.emailAddress && (
          <Descriptions.Item label="Email address">
            {policyholder.contact.emailAddress}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Contact address">
          <Address address={policyholder.contactAddress} separator="," />
        </Descriptions.Item>
      </Descriptions>
    </Card>
  )
}

interface IPolicyholderCardProps {
  policyholder: IPolicyholder
  onEdit?: () => void
  onClear?: () => void
}

export default PolicyholderCard
