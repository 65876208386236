import React from "react"
import { LayoutPageContent } from "components"
import {
  RouteComponentProps,
  StaticContext,
  useLocation,
  useRouteMatch,
  withRouter
} from "react-router"
import { useQueryClient } from "react-query"
import { PlusOutlined } from "@ant-design/icons"
import { useHistory, Route, Switch } from "react-router-dom"
import { useConfigurationContext } from "contexts/configuration/context"
import { Button, Layout, notification, Tabs } from "antd"

import { Summary } from "./sub-views/summary"
import { Header } from "./header"
import { Invoice } from "./sub-views/invoices"
import ListDistributorUsers from "./sub-views/users"
import { useMutation, useQuery } from "react-query"
import usePlatform from "contexts/platform/use-platform"
import { AxiosError } from "axios"
import { TErrorState } from "models/errors"
import { useDefaultPage } from "utils/hooks/use-default-page"
import AddUserModal from "./sub-views/AddUserModal"
import { Commissions } from "./sub-views/Commissions"
import { IInviteUserRequestBody } from "platform-client/types"
import { getUserTokenValue } from "utils/pocketknife/get-user-token-value"

const DistributorWithBaseRedirect: React.FC<DistributorProps> = (props) => {
  const history = useHistory()
  if (!props.match.params.subpage) {
    history.replace(`/distributor/${props.match.params.id}/summary`)
    return <></>
  }

  return <Distributor {...props} />
}

const Distributor: React.FC<DistributorProps> = (props) => {
  const history = useHistory()
  const location = useLocation()
  const queryClient = useQueryClient()

  const { path } = useRouteMatch()
  const configuration = useConfigurationContext()
  const [apiError, setApiError] = React.useState<TErrorState>()
  const [addUserVisibility, setAddUserVisibility] =
    React.useState<boolean>(false)

  useDefaultPage(
    apiError?.errorNumber ? Number(apiError.errorNumber) : undefined,
    ["404", "400"],
    {
      status: "404",
      title: `Sorry, we couldn't find a distributor that matched those details.`,
      buttonText: "Back to Distributor list",
      redirectTo: "/distributors"
    }
  )

  const platform = usePlatform()
  const userGroupType = getUserTokenValue("grouptype", platform.token || "")

  const getDistributor = useQuery(
    ["getDistributor", props.match.params.id],
    () => platform.company.getCompany(props.match.params.id),
    {
      onSuccess: () => setAddUserVisibility(false),
      onError: (error: AxiosError) => {
        setApiError({
          errorNumber: error.code ? parseInt(error.code) : 500,
          validationMessage: "Unable to load distributors"
        })
      }
    }
  )

  const inviteNewUser = useMutation(
    (body: IInviteUserRequestBody) => platform.user.inviteUser(body),
    {
      onSuccess: () => {
        setAddUserVisibility(false)
        queryClient.invalidateQueries("listDistributorUsers")
      },
      onError: () =>
        notification.error({
          message: "Unable to create user, please try again"
        })
    }
  )

  return (
    <Layout>
      <Header
        configuration={configuration}
        title={getDistributor.data?.name || undefined}
        breadcrumb={getDistributor.data?.referenceID}
        isWorking={getDistributor.isLoading}
        actions={(() => {
          switch (props.match.params.subpage) {
            case "users":
              return (
                <>
                  {(userGroupType === "InsurerAdministrator" ||
                    userGroupType === "Distributor") && (
                    <Button
                      type="primary"
                      onClick={() => setAddUserVisibility(true)}
                      icon={<PlusOutlined />}
                    >
                      Add new distributor user
                    </Button>
                  )}
                  {userGroupType === "InsurerAdministrator" && (
                    <Button
                      onClick={() =>
                        history.push(
                          `/distributors/edit/${props.match.params.id}`
                        )
                      }
                    >
                      Edit Distributor
                    </Button>
                  )}
                </>
              )

            default:
              return (
                userGroupType === "InsurerAdministrator" && (
                  <Button
                    onClick={() =>
                      history.push(
                        `/distributors/edit/${props.match.params.id}`
                      )
                    }
                  >
                    Edit Distributor
                  </Button>
                )
              )
          }
        })()}
        tabs={
          <Tabs
            activeKey={props.match.params.subpage}
            onTabClick={(key) => {
              const url = `/distributor/${props.match.params.id}/${key}`
              if (location.pathname === url) history.replace(url)
              else history.push(url)
            }}
          >
            <Tabs.TabPane tab="Summary" key="summary" />
            <Tabs.TabPane tab="Users" key="users" />
            <Tabs.TabPane tab="Invoices" key="invoices" />
            <Tabs.TabPane tab="Commissions" key="commissions" />
          </Tabs>
        }
      ></Header>
      <LayoutPageContent transparent>
        <Switch>
          <Route
            path={`${path}/summary`}
            render={() => (
              <Summary
                company={getDistributor.data}
                isLoading={getDistributor.isFetching}
              />
            )}
          />
          <Route
            path={`${path}/invoices`}
            render={() => <Invoice companyId={props.match.params.id} />}
          />
          <Route
            path={`${path}/users`}
            render={() => (
              <ListDistributorUsers companyID={props.match.params.id} />
            )}
          />
          <Route
            path={`${path}/commissions`}
            render={() => (
              <Commissions
                companyName={getDistributor.data?.name || undefined}
              />
            )}
          />
        </Switch>
      </LayoutPageContent>
      {(userGroupType === "InsurerAdministrator" ||
        userGroupType === "Distributor") && (
        <AddUserModal
          companyId={props.match.params.id}
          companyName={getDistributor.data?.name || ""}
          visible={addUserVisibility}
          onCancel={() => setAddUserVisibility(false)}
          onSubmit={inviteNewUser.mutate}
          isLoading={inviteNewUser.isLoading}
        />
      )}
    </Layout>
  )
}

type DistributorProps = RouteComponentProps<
  {
    id: string
    subpage: string
  },
  StaticContext,
  {
    data?: string
  }
>

export default withRouter(DistributorWithBaseRedirect)
