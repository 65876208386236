export enum TAventusApp {
  scroll = "aventus.app.scrollHeightChanged"
}
export enum TAventusQuote {
  confirm = "aventus.quote.confirm",
  saved = "aventus.quote.saved",
  answered = "aventus.quote.additionalQuestionsAnswered",
  bound = "aventus.policy.bind",
  renewalConfirm = "aventus.renewal.confirm",
  summary = "aventus.quote.summary",
  //v3 only
  createOpportunity = "aventus.quote.opportunity",
  createQuote = "aventus.quote.create",
  saveVariant = "aventus.variant.save",
  completeVariant = "aventus.variant.completed",
  discardVariant = "aventus.variant.discarded",
  completeQuickQuote = "aventus.quickQuote.completed",
  quickQuoteError = "aventus.quickQuote.error"
}

export enum TAventusQuoteSection {
  purchase = "purchase",
  duplicate = "duplicate",
  saved = "saved",
  edit = "edit",
  renew = "renew"
}

export type TNewQuoteMessage = {
  data: { event: string; quoteId: string; height: string; policyId: string }
}

export enum ENewQuoteState {
  NewQuote = "new"
}

export type IGenericEvent = {
  data: { event: string; id: string; height: string; policyId: string }
}
