import { loadFatZebra } from "../../../../../../utils/fatzebra"
import React, { useEffect, useMemo } from "react"
import { IPaymentIntent } from "models/fatzebra"
import styled from "styled-components"
import { IFatZebra } from "fatzebra.js"
import { IFatZebraConfigResponse } from "platform-client/types"

const StyledDiv = styled.div`
  iframe {
    display: block;
    position: relative;
    border: none;
    outline: none;
    min-width: 300px;
    min-height: 335px;
    width: 100%;
    background: transparent;
    overflow: hidden;
  }
`

export const FatZebraForm: React.FC<IFatZebraCardForm> = ({
  fzConfig,
  paymentIntent,
  onRecoverableError,
  onReady
}) => {
  const [initialized, setInitialzed] = React.useState<boolean>(false)

  const fatZebraPromise = useMemo(
    () =>
      loadFatZebra(null, {
        username: fzConfig.key,
        test: Boolean(fzConfig.isSandbox)
      }),
    [fzConfig]
  )

  // Initialize the FatZebra SDK on first render.
  // We store the SDK in a ref and update when we
  // have the access_token available.
  useEffect(() => {
    async function init() {
      if (!initialized) {
        fatZebraPromise
          .then((fatZebra) => {
            if (fatZebra) {
              fatZebra.renderPaymentsPage({
                containerId: "checkoutIframe",
                paymentIntent: paymentIntent,
                options: {
                  // Hpp display options
                  hideButton: true,
                  tokenizeOnly: true,
                  hideLogos: true,
                  enableSca: false,
                  css: fzConfig.cssUrl,
                  cssSignature: fzConfig.cssSignature
                }
              })

              setInitialzed(true)

              if (typeof onReady === "function") {
                onReady(fatZebra)
              }
            }
          })
          .catch(() => {
            onRecoverableError("Unable to initialize checkout.")
          })
      }
    }

    init()
  }, [
    initialized,
    fatZebraPromise,
    onRecoverableError,
    onReady,
    paymentIntent,
    fzConfig.cssUrl,
    fzConfig.cssSignature
  ])

  return <StyledDiv id="checkoutIframe"></StyledDiv>
}

interface IFatZebraCardForm {
  fzConfig: IFatZebraConfigResponse
  paymentIntent: IPaymentIntent
  onRecoverableError: (message: string) => void
  onReady?: (fatZebra: IFatZebra) => void
}
