import {
  IFatZebraEvent,
  IFatZebraPaymentRequest,
  IFatZebraVerificationProps,
  IFatZebraVerificationResponse,
  IPayment,
  IVerification
} from "models/fatzebra"
import { useCallback, useEffect } from "react"
import { useQuery } from "react-query"
import { FatZebraForm } from "./form"
import { notification } from "antd"
import { IFatZebraConfigResponse } from "platform-client/types"

export const FatZebraWrapper: React.FC<IFatZebraWrapperProps> = ({
  quoteID,
  policyID,
  payment,
  onPayNow,
  onComplete,
  setIsCheckingOut,
  verificationProps,
  fatZebraConfig,
  fatZebraPaymentIntentVerification
}) => {
  const _payNow = useCallback(
    (event: Event) => {
      onPayNow((event as unknown as IFatZebraEvent).detail.data.token)
    },
    [onPayNow]
  )

  const notifyError = (message: string) => {
    notification.error({ message })
  }

  const _validation = useCallback(() => {
    notifyError("Unable to create payment form, please try again.")

    setIsCheckingOut(false)
  }, [setIsCheckingOut])

  const _formValidation = useCallback(
    (event: Event) => {
      const ev = event as unknown as IFatZebraEvent
      let message =
        "Payment form incomplete, please check the fields and try again."
      if (
        event &&
        event.type === "fz.form_validation.error" &&
        ev.detail.errors.length > 0
      ) {
        message = "Payment form error. "
        for (let i = 0; i < ev.detail.errors.length; i++) {
          message += ev.detail.errors[i] + ". "
        }
      }
      notifyError(message)

      setIsCheckingOut(false)
    },
    [setIsCheckingOut]
  )

  // We need to setup the FatZebra events
  // ready to intercept them when checkout() is called
  // via payNow.
  useEffect(() => {
    window.addEventListener("fz.tokenization.success", _payNow)
    window.addEventListener("fz.validation.error", _validation)
    window.addEventListener("fz.form_validation.error", _formValidation)

    return () => {
      window.removeEventListener("fz.tokenization.success", _payNow)
      window.removeEventListener("fz.validation.error", _validation)
      window.removeEventListener("fz.form_validation.error", _formValidation)
    }
  }, [_payNow, _validation, _formValidation])

  // Request an access token from the API so we can
  // initialise fatzebra SDK and render the payment
  // form.
  const { data: fzConfig } = useQuery(
    ["FatZebra", "Config", payment],
    () => fatZebraConfig(quoteID, policyID),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        window.localStorage.setItem("fz-access-token", response.token)
      },
      onError: () => {
        notifyError("Unable to authenticate card provider.")
      }
    }
  )

  // To tokenize the card we must verify that the payment
  // intent matches a hash value. This stops the value being
  // manipulated before send on the frontend.
  const { data: verification } = useQuery(
    ["FatZebra", "Verification", verificationProps],
    () => fatZebraPaymentIntentVerification(verificationProps),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: () => {
        notifyError("Unable to verify payment intent. Please try again.")
      }
    }
  )

  return (
    <>
      {fzConfig && verification && (
        <FatZebraForm
          fzConfig={fzConfig}
          onRecoverableError={notifyError}
          paymentIntent={{
            payment,
            verification
          }}
          onReady={(fatZebra) => {
            onComplete({
              fatZebra,
              payment,
              verification: verification
            })
          }}
        />
      )}
    </>
  )
}

interface IFatZebraWrapperProps {
  payment: IPayment
  policyID?: string
  quoteID?: string
  verificationProps: IVerification
  onPayNow: (token?: string) => void
  onComplete: (paymentRequest: IFatZebraPaymentRequest) => void
  setIsCheckingOut: (toggle: boolean) => void
  fatZebraConfig: (
    quoteID?: string,
    policyID?: string
  ) => Promise<IFatZebraConfigResponse>
  fatZebraPaymentIntentVerification: (
    props: IFatZebraVerificationProps
  ) => Promise<IFatZebraVerificationResponse>
}
