export const replaceArray = (
  find: string[],
  replace: string,
  toReplace: string
): string => {
  let replaceString = toReplace
  for (let i = 0; i < find.length; i++) {
    replaceString = replaceString.replace(find[i], replace)
  }

  return replaceString
}
