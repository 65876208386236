import React from "react"
import {
  Card,
  Typography,
  Divider,
  Button,
  Tooltip,
  Input,
  message,
  Modal
} from "antd"
import { OnInvalidatePaymentLink } from "../../view"
import { useHistory } from "react-router-dom"
import { IPaymentLink } from "../../index.v2"

const { Group } = Input
const { Text } = Typography

export const QuotePayment: React.FunctionComponent<IQuotePayment> = (props) => {
  const history = useHistory()

  const modalRegeneratePaymentLink = () =>
    Modal.confirm({
      title: "Are you sure you want to regenerate this payment link?",
      centered: true,
      okText: "Regenerate",
      onOk: async () => {
        if (props.quoteID) {
          await props.onInvalidatePaymentLink?.(
            { quoteId: props.quoteID },
            () => {
              history.push(
                `/opus/purchase/${props.quoteProductReferenceID}/${props.quoteCoverType}/${props.quoteID}`
              )
            }
          )
        }
      }
    })

  return (
    <Card
      title="Payment link"
      style={{ marginBottom: 20 }}
      loading={props.isWorking}
      {...(props.paymentLink === undefined && {
        bodyStyle: { display: "none" }
      })}
      extra={
        <Tooltip
          title={
            props.paymentLink &&
            (props.paymentLink.expired || props.paymentLink.paymentURL)
              ? "Regenerating link will cause the existing payment link to no longer work. You will be required to confirm policy details again before a new link is generated."
              : undefined
          }
        >
          <Button
            onClick={
              props.paymentLink &&
              (props.paymentLink.expired || props.paymentLink.paymentURL)
                ? modalRegeneratePaymentLink
                : () => {
                    if (typeof props.handleCreatePaymentLink === "function") {
                      props.handleCreatePaymentLink()
                    }
                  }
            }
            type={
              props.paymentLink && props.paymentLink.expired
                ? "primary"
                : "default"
            }
            loading={props.isWorking}
          >
            {props.paymentLink &&
            (props.paymentLink?.expired || props?.paymentLink?.paymentURL)
              ? "Regenerate payment url"
              : "Create payment link"}
          </Button>
        </Tooltip>
      }
    >
      {!props.paymentLinkError &&
        props.paymentLink &&
        props.paymentLink.paymentURL && (
          <>
            <Group>
              <Input
                value={
                  !props.paymentLink.expired
                    ? props?.paymentLink.paymentURL
                    : "Link expired, please generate a new link by using the button above"
                }
                disabled={props.paymentLink.expired}
                style={{ width: "calc( 100% - 60px )" }}
              />
              <Button
                style={{ width: "60px", borderLeft: 0 }}
                disabled={props.paymentLink.expired}
                onClick={() => {
                  if (props.paymentLink && props.paymentLink.paymentURL) {
                    navigator.clipboard.writeText(props.paymentLink.paymentURL)
                    message.info("Link copied to clipboard")
                  }
                }}
              >
                Copy
              </Button>
            </Group>

            <Divider plain type="horizontal" style={{ margin: "12px 0" }} />

            <Text type="secondary" style={{ display: "block" }}>
              This payment link can be shared with the purchaser by email or
              text message. When opened, the customer will be emailed with a
              single-use link that verifies them and allows them to complete
              payment.
            </Text>
          </>
        )}

      {(props.paymentLinkError && !props.isWorking) ||
        (!props.isLoaded && (
          <Text type={"danger"}>
            Unable to retrieve Payment link, please reload and try again
          </Text>
        ))}
    </Card>
  )
}

export interface IQuotePayment {
  paymentLink?: IPaymentLink
  paymentLinkError?: boolean
  isWorking?: boolean
  isLoaded?: boolean
  quoteID?: string
  quoteCoverType?: string
  quoteProductReferenceID?: string
  onInvalidatePaymentLink?: OnInvalidatePaymentLink
  handleCreatePaymentLink?: () => void
}
