import React from "react"
import { IWithdrawReason } from "platform-client/types/quote"
import { Select } from "antd"
import { debounce } from "debounce"
import { useModal } from "services/hooks/use-modal"
import { OnWithdrawQuote } from "../../view"

export const useWithdrawQuote = (
  quoteId: string,
  withdrawReasons?: IWithdrawReason[],
  onWithdrawQuote?: OnWithdrawQuote
): IUseWithdrawQuote => {
  const [withdrawReason, setWithdrawReason] = React.useState<
    string | undefined
  >()

  const {
    modal: withdrawQuoteModal,
    setIsModalVisible: showWithdrawQuoteModal
  } = useModal({
    title: "Are you sure you want to withdraw this quote?",
    centered: true,
    destroyOnClose: true,
    okText: "Withdraw",
    okButtonProps: { disabled: !withdrawReason, danger: true },
    onOk: debounce(async () => {
      if (quoteId && withdrawReason && onWithdrawQuote) {
        const selectedResponse =
          withdrawReasons &&
          withdrawReasons.filter(
            ({ referenceID }) => referenceID === withdrawReason
          )[0]

        await onWithdrawQuote(
          {
            quoteId,
            comment: selectedResponse?.title,
            withdrawalReasonReferenceID: selectedResponse?.referenceID
          },
          (quoteId) => {
            if (quoteId) {
              setWithdrawReason(undefined)
            }
          },
          undefined,
          { shouldRefetchQuote: true }
        )
      }
    }, 200),
    children: (
      <Select
        placeholder={"Choose reason"}
        onChange={(value: string) => {
          setWithdrawReason(value)
        }}
        style={{ width: "100%" }}
      >
        {withdrawReasons &&
          withdrawReasons.map((withdrawReason: IWithdrawReason) => (
            <Select.Option value={withdrawReason.referenceID}>
              {withdrawReason.text}
            </Select.Option>
          ))}
      </Select>
    )
  })

  return {
    withdrawQuoteModal,
    showWithdrawQuoteModal
  }
}

export interface IUseWithdrawQuote {
  withdrawQuoteModal: React.ReactNode
  showWithdrawQuoteModal: (arg: boolean) => void
}
