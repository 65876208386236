import { useMemo } from "react"
import { TNavigation } from "routes"
import { useConfigurationContext } from "contexts/configuration/context"

// filter through nav items and check if it should be hidden

export const useNavItems = (navItems: TNavigation[]): TNavigation[] => {
  const { flags: { hiddenNavItems = [] } = {} } = useConfigurationContext()
  const filteredItems = useMemo(
    () =>
      navItems.filter(
        (navItem) =>
          !hiddenNavItems.some((hiddenTitle) => hiddenTitle === navItem.title)
      ),
    [navItems, hiddenNavItems]
  )

  return filteredItems
}
