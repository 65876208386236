import { useMemo } from "react"
import { Col, Select, Space } from "antd"
import { SelectValue } from "antd/lib/select"
import { useHistory } from "react-router-dom"
const { Option } = Select

interface ISearchComponent {
  queryParam: string
  options: { name: string; id: string }[]
  placeholder: string
  label: string
  width?: number
  onSearch?: (inputValue: string) => void
  onChange?: () => void
  onClear?: () => void
}

const getId =
  (data: { name: string; id: string }[]) =>
  (selectedOption: SelectValue): string | undefined => {
    const { id } = data?.find(({ name }) => name === selectedOption) || {}
    return id
  }

export const SearchComponent: React.FC<ISearchComponent> = ({
  queryParam,
  options,
  placeholder,
  label,
  width = 400,
  onSearch = () => null,
  onChange = () => null,
  onClear = () => null
}) => {
  const history = useHistory()
  const searchParams = useMemo(
    () => new URLSearchParams(history.location.search),
    [history.location.search]
  )

  const value = useMemo(() => {
    const selectedId = searchParams.get(queryParam)
    return options?.find(({ id }) => selectedId === id)?.name
  }, [options, searchParams, queryParam])

  const extractId = useMemo(() => getId(options), [options])

  const handleChange = (name: string): void => {
    const selectedId = extractId(name)
    onChange()
    if (selectedId) {
      searchParams.set(queryParam, selectedId)
    } else {
      searchParams.delete(queryParam)
    }

    history.push({
      pathname: history.location.pathname,
      search: searchParams.toString()
    })
  }

  return (
    <Col>
      {label}
      <Space style={{ marginLeft: 16 }}>
        <Select
          showSearch
          allowClear
          value={value}
          style={{ minWidth: width }}
          placeholder={placeholder}
          onChange={handleChange}
          onSearch={onSearch}
          onClear={onClear}
        >
          {(options || []).map(({ name, id }) => (
            <Option key={id} value={name} label={name}>
              {name}
            </Option>
          ))}
        </Select>
      </Space>
    </Col>
  )
}

export default SearchComponent
