import RichTextEditor from "components/RichTextEditor"
import { Button, Modal, Typography } from "antd"
import { MouseEventHandler } from "react"

interface IRequestReviewModalProps {
  visible: boolean
  content: string
  setContent: (content: string) => void
  onSubmit: MouseEventHandler<HTMLElement>
  onCancel: () => void
  isLoading: boolean
  headerText?: string
}

const { Title } = Typography

export const ReferQuoteModal: React.FC<IRequestReviewModalProps> = ({
  visible = true,
  content = "",
  setContent = () => null,
  onCancel = () => null,
  onSubmit = () => null,
  isLoading = false,
  headerText = "Reason for Manual Review"
}) => (
  <Modal
    visible={visible}
    onCancel={onCancel}
    footer={
      <>
        <Button onClick={onCancel} disabled={isLoading}>
          Cancel
        </Button>
        <Button type="primary" onClick={onSubmit} loading={isLoading}>
          Submit
        </Button>
      </>
    }
  >
    <Title level={4}>{headerText}</Title>
    <RichTextEditor content={content} setContent={setContent} />
  </Modal>
)
