import { PolicyholderType } from "platform-client/types/policyholders"

export const policyholderMap: Record<PolicyholderType, string> = {
  NotSet: "",
  Individual: "Individual",
  SoleTrader: "Sole Trader",
  LimitedCompany: "Limited Company",
  Partnership: "Partnership",
  LimitedLiabilityPartnership: "Limited Liability Partnership",
  PublicLimitedCompany: "Public Limited Company"
}
