import React from "react"
import styled from "styled-components"
import { LightText } from "components/LayoutStyledComponents"
import { Tabs, Timeline } from "antd"
import { LayoutCardLoader } from "components"
import { dateToShorthand } from "utils/pocketknife/date-to-shorthand"
import { capitalizeFirstLetter } from "utils/pocketknife/capitalize-first-letter"
import { IPolicyEvent } from "models/events/IEvent"
import { IConfigurationPolicyAction } from "contexts/configuration/context"

const TabsContainer = styled.div`
  background-color: #fff;
  padding: 10px 24px 0 24px;
  height: 668px;
`

const StyledTabPanel = styled(Tabs.TabPane)`
  height: 565px;
  overflow-y: auto;
`

export const PolicyTabs: React.FunctionComponent<IPolicyTabs> = ({
  eventsActions = {},
  ...props
}) => {
  return (
    <TabsContainer>
      <Tabs defaultActiveKey="1">
        <StyledTabPanel tab="Timeline" key="1">
          {props.events && !props.isEventsLoading ? (
            <Timeline mode="left" style={{ paddingTop: 16, paddingBottom: 16 }}>
              {props.events.map((event: IPolicyEvent) => {
                return (
                  <Timeline.Item
                    color={eventsActions && eventsActions[event?.action]}
                  >
                    <LightText>{dateToShorthand(event.dateCreated)}</LightText>
                    {`${capitalizeFirstLetter(event.displayText)}`}
                  </Timeline.Item>
                )
              })}
            </Timeline>
          ) : (
            <LayoutCardLoader style={{ marginTop: 21 }} />
          )}
        </StyledTabPanel>
      </Tabs>
    </TabsContainer>
  )
}

export interface IPolicyTabs {
  events: IPolicyEvent[] | undefined
  eventsActions: IConfigurationPolicyAction
  isEventsLoading: boolean
}
