import React, { useContext, useState } from "react"

type AuthenticatedUserContextType = {
  user?: IAuthenticatedUserDetails | undefined
  setUser: (user: IAuthenticatedUserDetails | undefined) => void
}

export interface IAuthenticatedUserDetails {
  email: string | undefined
  name?: string | undefined
  id: string | undefined
}

const stub = (): never => {
  throw new Error(
    "You forgot to wrap your component in <AuthenticatedUserContext.Provider>"
  )
}

const AuthenticatedUserContext =
  React.createContext<AuthenticatedUserContextType>({
    user: undefined,
    setUser: stub
  })

export const useAuthenticatedUserContext = (): AuthenticatedUserContextType => {
  const context = useContext(AuthenticatedUserContext)

  if (context === undefined) {
    throw new Error(
      "You must wrap your component in `AuthenticatedUserContext.Provider` before trying to access this."
    )
  }

  return context
}

export default AuthenticatedUserContext

export const AuthenticatedUserContextProvider: React.FC = ({ children }) => {
  const [user, storeUser] = useState<IAuthenticatedUserDetails | undefined>(
    undefined
  )

  const setUser = (user: IAuthenticatedUserDetails | undefined) => {
    storeUser(user)
  }

  return (
    <AuthenticatedUserContext.Provider value={{ user, setUser }}>
      {children}
    </AuthenticatedUserContext.Provider>
  )
}
