import { Button, Checkbox, DatePicker, Form, Input, Select } from "antd"
import TextArea from "antd/lib/input/TextArea"
import moment, { Moment } from "moment"
import {
  ClaimStage,
  ClaimStatus,
  ClaimSubStatus,
  IPolicyClaim,
  IPolicyClaimType
} from "platform-client/types/claims"
import styled from "styled-components"
import getCurrencySymbol from "utils/pocketknife/get-currency-symbol"

const HalfWidthFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  .ant-form-item {
    width: 48%;
  }
`

const ClaimForm = ({
  claim,
  policyReferenceID,
  handleFormSubmit,
  claimTypes = [],
  isSubmitting = false,
  currencyCode = "GBP",
  formButtonText = "Submit"
}: IClaimFormProps): JSX.Element => {
  const [form] = Form.useForm<IClaimForm>()

  return (
    <Form
      layout="vertical"
      size="large"
      form={form}
      onFinish={handleFormSubmit}
      onFinishFailed={() => {
        window.scrollTo({ top: 0, behavior: "smooth" })
      }}
      initialValues={
        claim
          ? {
              claimReference: claim.claimReference,
              claimType: claim.claimType,
              claimStage: claim.claimStage,
              claimStatus: claim.claimStatus,
              claimSubStatus: claim.claimSubStatus,
              claimValue: claim.claimValue.value,
              dateReported: claim.claimRaisedDateLocal
                ? moment.utc(claim.claimRaisedDateLocal)
                : undefined,
              dateOccurred: claim.claimOccurredDateLocal
                ? moment.utc(claim.claimOccurredDateLocal)
                : undefined,
              dateClosed: claim.claimClosedDateLocal
                ? moment.utc(claim.claimClosedDateLocal)
                : undefined,
              description: claim.description,
              sourceClaimType: claim.sourceClaimType,
              claimClosed: !!claim.claimClosedDateLocal,
              sameDayReported: false
            }
          : {
              policyReferenceID,
              sameDayReported: true,
              claimClosed: false
            }
      }
    >
      <Form.Item
        name="dateOccurred"
        label="Date of incident"
        rules={[
          {
            required: true,
            message: "Please select the date the incident occurred."
          }
        ]}
      >
        <DatePicker format="DD/MM/YYYY" style={{ width: "100%" }} />
      </Form.Item>

      <HalfWidthFieldWrapper>
        <Form.Item name="sameDayReported" valuePropName="checked">
          <Checkbox aria-label="same-day-reported">
            Incident reported on the same day?
          </Checkbox>
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prev, current) =>
            prev.sameDayReported !== current.sameDayReported
          }
        >
          {({ getFieldValue }) =>
            getFieldValue("sameDayReported") === false && (
              <Form.Item
                name="dateReported"
                label="Date incident reported"
                rules={[
                  {
                    required: true,
                    message: "Please select the date the incident was reported."
                  }
                ]}
              >
                <DatePicker format="DD/MM/YYYY" style={{ width: "100%" }} />
              </Form.Item>
            )
          }
        </Form.Item>
      </HalfWidthFieldWrapper>

      <HalfWidthFieldWrapper>
        <Form.Item name="claimClosed" valuePropName="checked">
          <Checkbox aria-label="claim-closed">Is this claim closed?</Checkbox>
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prev, current) =>
            prev.claimClosed !== current.claimClosed
          }
        >
          {({ getFieldValue }) =>
            getFieldValue("claimClosed") === true && (
              <Form.Item
                name="dateClosed"
                label="Date claim was closed"
                rules={[
                  {
                    required: true,
                    message: "Please select the date the claim was closed."
                  }
                ]}
              >
                <DatePicker format="DD/MM/YYYY" style={{ width: "100%" }} />
              </Form.Item>
            )
          }
        </Form.Item>
      </HalfWidthFieldWrapper>

      <HalfWidthFieldWrapper>
        <Form.Item
          name="claimReference"
          label="Claim provider reference"
          rules={[{ required: true, message: "Claim reference is required." }]}
        >
          <Input />
        </Form.Item>

        {claim === undefined && (
          <Form.Item
            hidden={!!policyReferenceID}
            name="policyReferenceID"
            label="Policy reference"
            rules={[
              { required: true, message: "Policy reference is required." }
            ]}
          >
            <Input />
          </Form.Item>
        )}

        <Form.Item
          name="claimStage"
          label="Claim stage"
          rules={[{ required: true, message: "Please select a claim stage." }]}
        >
          <Select>
            <Select.Option value={ClaimStage.None}>None</Select.Option>
            <Select.Option value={ClaimStage.Notification}>
              Notification
            </Select.Option>
            <Select.Option value={ClaimStage.Claim}>Claim</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="claimStatus"
          label="Claim status"
          aria-label="claim-status"
          rules={[{ required: true, message: "Please select a claim status." }]}
        >
          <Select>
            <Select.Option value={ClaimStatus.Open}>Open</Select.Option>
            <Select.Option value={ClaimStatus.Closed}>Closed</Select.Option>
            <Select.Option value={ClaimStatus.ReOpened}>ReOpened</Select.Option>
            <Select.Option value={ClaimStatus.ReClosed}>ReClosed</Select.Option>
            <Select.Option value={ClaimStatus.Cancelled}>
              Cancelled
            </Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prev, current) =>
            prev.claimStatus !== current.claimStatus
          }
        >
          {({ getFieldValue }) =>
            [ClaimStatus.Closed, ClaimStatus.ReClosed].includes(
              getFieldValue("claimStatus")
            ) && (
              <Form.Item
                name="claimSubStatus"
                label="Claim closed status"
                rules={[
                  {
                    required: true,
                    message: "Please select a closed claim status."
                  },
                  {
                    validator: (_, value) => {
                      if (
                        [
                          ClaimSubStatus.ClosedError,
                          ClaimSubStatus.ClosedRepudiated,
                          ClaimSubStatus.ClosedSettled,
                          ClaimSubStatus.ClosedTransferred,
                          ClaimSubStatus.ClosedWithdrawn
                        ].includes(value)
                      ) {
                        return Promise.resolve(true)
                      }

                      return Promise.reject(
                        "Please select a closed claim status."
                      )
                    }
                  }
                ]}
              >
                <Select>
                  <Select.Option value={ClaimSubStatus.ClosedWithdrawn}>
                    Withdrawn
                  </Select.Option>
                  <Select.Option value={ClaimSubStatus.ClosedSettled}>
                    Settled
                  </Select.Option>
                  <Select.Option value={ClaimSubStatus.ClosedRepudiated}>
                    Repudiated
                  </Select.Option>
                  <Select.Option value={ClaimSubStatus.ClosedTransferred}>
                    Transferred
                  </Select.Option>
                  <Select.Option value={ClaimSubStatus.ClosedError}>
                    Error
                  </Select.Option>
                </Select>
              </Form.Item>
            )
          }
        </Form.Item>

        <Form.Item
          name="claimValue"
          label="Claim value"
          required
          rules={[
            {
              validator: (_, value) => {
                if (/^[+-]?([0-9,]*[.])?[0-9]*$/g.test(String(value))) {
                  return Promise.resolve(true)
                }

                if (!value) {
                  return Promise.reject("Claim value is required.")
                }

                return Promise.reject("This field only support money values.")
              }
            }
          ]}
        >
          <Input prefix={getCurrencySymbol(currencyCode)} />
        </Form.Item>

        {claimTypes && claimTypes.length > 0 && (
          <Form.Item name="claimType" label="Claim type">
            <Select disabled={claimTypes.length === 0}>
              {claimTypes.map((claimType) => {
                return (
                  <Select.Option value={claimType.id}>
                    {claimType.name}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>
        )}

        <Form.Item name="sourceClaimType" label="Source claim type">
          <Input />
        </Form.Item>
      </HalfWidthFieldWrapper>

      <Form.Item
        name="description"
        label="Description"
        rules={[
          { required: true, message: "Please add a description of the claim." }
        ]}
      >
        <TextArea rows={4} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isSubmitting}>
          {formButtonText}
        </Button>
      </Form.Item>
    </Form>
  )
}

export interface IClaimFormProps {
  claim?: IPolicyClaim
  policyReferenceID?: string
  claimTypes?: IPolicyClaimType[]
  handleFormSubmit: (values: IClaimForm | IAddClaimForm) => void
  isSubmitting: boolean
  formButtonText?: string
  currencyCode: string | undefined
}

export interface IClaimForm {
  claimReference: string
  claimType: string | undefined
  claimStage: ClaimStage
  claimStatus: ClaimStatus
  claimSubStatus: ClaimSubStatus
  claimValue: string
  dateReported?: Moment
  dateOccurred: Moment
  dateClosed?: Moment
  description: string
  sameDayReported: boolean
  claimClosed: boolean
  sourceClaimType: string
}

export interface IAddClaimForm extends IClaimForm {
  policyReferenceID: string
}

export default ClaimForm
