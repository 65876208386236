import { ReactNode, useMemo } from "react"

import { IMoney } from "platform-client/types"
import { ITravelPackage } from "models/common/package"
import { MoneyDisplay } from "components/display/MoneyDisplay"
import { Tooltip } from "antd"
import { UserOutlined } from "@ant-design/icons"
import { resolvePackageLevel } from "./resolve-package-level"
import { useGetTravelPackageConfig } from "contexts/configuration/hooks/use-get-travel-package-config"
import { useOrgSettings } from "contexts/organisation/hooks/use-org-settings"

const variableLevelToolTip = (
  <Tooltip placement={"bottomLeft"} title={"Package level set by customer"}>
    <UserOutlined style={{ fontSize: 20 }} />
  </Tooltip>
)

interface IUseTravelPackageList {
  packageList: {
    packageName: string
    packageLevel: string | ReactNode
    excess: ReactNode
    customerDefinedLevel: ReactNode | null
  }[]
}

export const useTravelPackageList = (
  packages: { [key: string]: ITravelPackage },
  product: { referenceID: string; version: number },
  excess: IMoney
): IUseTravelPackageList => {
  const { orgSettings } = useOrgSettings()

  const { travelPackageConfig, travelPackageConfigLoaded } =
    useGetTravelPackageConfig(product.referenceID, product.version)

  const packageList = useMemo(() => {
    if (!travelPackageConfigLoaded) {
      return []
    }

    return Object.entries(packages)

      .filter(([_, packageValue]) => packageValue.selected)

      .map(([packageKey, packageValue]) => {
        const packageConfig = travelPackageConfig.find(
          (configPackage) => configPackage.riskPath === packageKey
        )

        const formattedExcess = (
          <MoneyDisplay
            hideZeroMinorCurrency
            value={{
              ...excess,
              value: packageConfig?.excessApplies ? excess.value : 0
            }}
          />
        )

        return {
          packageName: packageConfig?.packageLabel ?? packageKey,
          excess: formattedExcess,

          customerDefinedLevel: packageConfig?.defaultLevel
            ? null
            : variableLevelToolTip,

          packageLevel: resolvePackageLevel(
            packageValue,
            packageConfig,
            orgSettings?.currencyCode
          )
        }
      })
  }, [
    packages,
    excess,
    travelPackageConfig,
    travelPackageConfigLoaded,
    orgSettings?.currencyCode
  ])

  return { packageList }
}
