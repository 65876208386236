import React from "react"
import { Button } from "antd"
import { useHistory } from "react-router-dom"
import { PolicyDetailType, IPolicyDetail } from "platform-client/types"
import { useNotes } from "./use-notes"
import QuoteBindWithConfirmation from "../quote-bind-with-confirmation"
import { ManualReferToggle } from "components/ManualReferToggle"
import { QuoteActionsProps } from "."
import { useUserContext } from "contexts/user/context"

export const QuoteActionsDU: React.FC<QuoteActionsProps> = (props) => {
  const history = useHistory()
  const { professionalIndemnityInsuranceExpired } = useUserContext()

  const bindDisabled =
    professionalIndemnityInsuranceExpired === true || props.disableBind

  const { noteModal, showNoteModal } = useNotes(
    props.quoteId,
    props.quoteLifecycles?.onAddNote
  )

  const policyDetailHasPremium = (
    policyDetail?: PolicyDetailType
  ): policyDetail is IPolicyDetail =>
    !!policyDetail && "totalGrossPremium" in policyDetail

  const bindQuote = () => {
    if (bindDisabled === true) return

    history.push(`/opus/checkout/${props.quoteId}`, {
      proposerEmail: props.proposerEmail
    })
  }

  switch (props.quoteState) {
    case "Referred":
      return (
        <>
          <ManualReferToggle
            onManualReferral={() => props.handleManualReferral?.()}
            onRevokeManualReferral={() => props.handleRevokeManualReferral?.()}
            quoteActions={props.quoteActions}
            isLoading={props.isManualReferralLoading}
          />

          {props.renderQuoteDocuments}

          <Button onClick={() => showNoteModal(true)}>Add note</Button>

          {noteModal}
        </>
      )

    case "Purchased":
      return (
        <>
          {props.renderQuoteDocuments}

          <Button onClick={() => showNoteModal(true)}>Add note</Button>

          {noteModal}
        </>
      )

    case "Withdrawn":
      return null

    case "Open":
      return null

    case "Expired":
      return null

    case "PendingPayment":
      return (
        <>
          {props.renderQuoteDocuments}

          <Button onClick={() => showNoteModal(true)}>Add note</Button>

          {noteModal}

          {(props.configuration.flags?.paymentType === "SymphonyCheckout" ||
            props.configuration.flags?.paymentTypes?.includes(
              "SymphonyCheckout"
            )) && (
            <Button type="primary" onClick={bindQuote} disabled={bindDisabled}>
              Bind
            </Button>
          )}

          <ManualReferToggle
            onManualReferral={() => props.handleManualReferral?.()}
            onRevokeManualReferral={() => props.handleRevokeManualReferral?.()}
            quoteActions={props.quoteActions}
            isLoading={props.isManualReferralLoading}
          />
        </>
      )
    case "Quoted":
      return (
        <>
          <ManualReferToggle
            onManualReferral={() => props.handleManualReferral?.()}
            onRevokeManualReferral={() => props.handleRevokeManualReferral?.()}
            quoteActions={props.quoteActions}
            isLoading={props.isManualReferralLoading}
          />

          {props.renderQuoteDocuments}

          <Button onClick={() => showNoteModal(true)}>Add note</Button>

          {noteModal}

          {(props.configuration.flags.paymentType === "SymphonyCheckout" ||
            props.configuration.flags?.paymentTypes?.includes(
              "SymphonyCheckout"
            )) && (
            <Button type="primary" onClick={bindQuote} disabled={bindDisabled}>
              Bind
            </Button>
          )}

          {(props.configuration.flags.paymentType === "PayOnAccount" ||
            props.configuration.flags?.paymentTypes?.includes(
              "PayOnAccount"
            )) &&
            props.policyDetail &&
            policyDetailHasPremium(props.policyDetail) && (
              <QuoteBindWithConfirmation
                quoteReferenceID={props.quoteReferenceID}
                onBindQuote={props.handleBindQuote}
                quotePaymentPlans={props.paymentPlans}
                proposer={props.proposer}
                quoteCompanies={props.quoteCompanies}
                platformVersion={props.configuration.flags.platformVersion}
                blockBind={bindDisabled}
                quoteChanges={props.quoteChanges}
                coverEndDate={props.coverEndDate}
                coverStartDate={props.coverStartDate}
              />
            )}
        </>
      )
    default:
      return null
  }
}
