import React from "react"
import { FileProtectOutlined } from "@ant-design/icons"
import { VEditPolicyholder } from "."

export const POLICYHOLDERS_ROUTES = [
  {
    path: "/policyholders/:policyholderId/edit",
    component: VEditPolicyholder
  }
]

export const POLICYHOLDERS_NAVIGATION = [
  {
    title: "Policy Holder",
    path: "/policyholders",
    childPaths: ["/policyholders"],
    submenu: false,
    icon: <FileProtectOutlined twoToneColor="#ccc" />,
    iconActive: <FileProtectOutlined twoToneColor="#1890ff" />
  }
]
