import { IGenericEvent, TAventusQuote } from "models/quotes/externalmodels"
import { RouteComponentProps, useLocation } from "react-router"
import React, { useEffect, useState } from "react"
import usePlatform from "contexts/platform/use-platform"
import { useConfigurationContext } from "contexts/configuration/context"
import { IFrameContainer } from "components/IFrame"
import { useQuery } from "react-query"
import { notification } from "antd"
import { LayoutLoading } from "components"

export const EditVariant: React.FunctionComponent<IEditVariantRoutingProps> = (
  props
) => {
  const platform = usePlatform()
  const configContext = useConfigurationContext()
  const [noActiveSession, setNoActiveSession] = useState<boolean>(false)
  const [activeEditSessionId, setActiveEditSessionId] = useState<
    string | undefined
  >(undefined)
  const [product, setProduct] = React.useState<number | undefined>(undefined)
  const variantID = props.match.params.variantId

  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const quoteType = searchParams.get("quoteType") ?? "NewBusiness"
  const skipSummary = searchParams.get("skipSummary") === "true" ? true : false

  useEffect(() => {
    // Any events that come back from the Iframe will live here
    const eventHandler = (event: IGenericEvent) => {
      const data = event.data

      if (
        data.event === TAventusQuote.saveVariant ||
        data.event === TAventusQuote.completeVariant ||
        data.event === TAventusQuote.discardVariant
      ) {
        props.history?.push({
          pathname: `/opportunity/${props.match.params.opportuityId}`
        })
      }
    }
    window.addEventListener("message", eventHandler)
    return () => window.removeEventListener("message", eventHandler)
  })

  const goBack = () => {
    props.history?.push({
      pathname: `/opportunity/${props.match.params.opportuityId}`
    })
  }

  const { isFetched: getAllActiveSession } = useQuery(
    ["getAllActiveSessions", variantID],
    () => platform.variant.getActiveEditSessions(variantID),
    {
      onSuccess: (data) => {
        if (data.length < 1) {
          setNoActiveSession(true)
        } else {
          const lastActiveSession = data
            .filter((rf) => rf.state === "Active")
            .sort((a, b) => b.dateCreated.localeCompare(a.dateCreated))[0]
          if (lastActiveSession) {
            setActiveEditSessionId(lastActiveSession.id)
            setProduct(lastActiveSession.policyDetail.productID)
          }
        }
      },
      onError: () => {
        notification.error({
          message:
            "We had trouble fetching any active sessions, please try and start a new session or refresh the page."
        })
      }
    }
  )

  const { isFetched: startNewSessionFetching } = useQuery(
    ["StartNewSession", variantID],
    async () => platform.variant.startEditSession(variantID),
    {
      enabled: noActiveSession === true && getAllActiveSession,
      onSuccess: (data) => {
        if (data) {
          setActiveEditSessionId(data.id)
          setProduct(data.policyDetail.productID)
        }
      },
      onError: () => {
        notification.error({
          message: "Unable to start new session"
        })
      }
    }
  )

  const { isLoading: isLoadingProductConfig, data: productConfigData } =
    useQuery(
      ["getProductConfigurationByID", product],
      async () => platform.product.getProductConfigurationById(String(product)),
      {
        enabled: !!product && skipSummary
      }
    )

  if (
    ((getAllActiveSession && !noActiveSession) ||
      (startNewSessionFetching && noActiveSession)) &&
    activeEditSessionId
  ) {
    if (skipSummary) {
      // If we have more than one to choose from in the future or want to always
      // select a specific question set, we will need to setup a config reference value
      // or some mechanism to select this one first.
      const [firstQuestionSet] =
        (productConfigData && productConfigData.coreQuestionSets) || []

      if (isLoadingProductConfig || !firstQuestionSet) {
        return <LayoutLoading />
      }

      return (
        <IFrameContainer
          id={`Product_${product}_Variant_${variantID}_QuestionSet_${firstQuestionSet.referenceID}`}
          url={`${configContext.symphony.baseUrl}/products/${product}/quote/${quoteType}/${firstQuestionSet.referenceID}?editSessionId=${activeEditSessionId}&token=${platform.token}&variantID=${variantID}&chrome=false`}
          title={""}
          name={variantID}
          goBack={goBack}
        ></IFrameContainer>
      )
    } else {
      return (
        <IFrameContainer
          id={`VariantSummary_Variant_${variantID}`}
          url={`${configContext.symphony.baseUrl}/products/${product}/variantSummary/${quoteType}?editSessionId=${activeEditSessionId}&token=${platform.token}&variantID=${variantID}&chrome=false`}
          title={"Edit Risk"}
          name={variantID}
          goBack={goBack}
        ></IFrameContainer>
      )
    }
  }

  return null
}

export type IEditVariantRoutingProps = RouteComponentProps<{
  opportuityId: string
  variantId: string
}>
