import { useState } from "react"
import { useAsyncEffect } from "use-async-effect"
import {
  IQuoteReviewResponse,
  IQuoteReviewQuestion
} from "models/quotes/quoteReview"
import { TErrorState } from "models/errors"
import {
  errorValidationMessage,
  IErrorState
} from "utils/pocketknife/error-validation-message"
import useOpus from "contexts/opus/hooks/use-opus"

const parseResponse = (response: IQuoteReviewResponse) => {
  const questionMerged: IQuoteReviewQuestion[] = []

  response.pages.forEach((page) => {
    questionMerged.push(...page.questions)
  })

  return questionMerged
}

export const useGetQuoteReview = (
  quoteID: string | undefined
): IUseGetReview => {
  const [reviewQuestions, setReviewQuestions] = useState<
    IQuoteReviewQuestion[] | []
  >([])
  const [isReviewLoading, setReviewLoading] = useState<boolean>(false)
  const [isError, setIsError] = useState<TErrorState>(undefined)
  const { quoteClient } = useOpus()

  useAsyncEffect(
    async (isMounted) => {
      try {
        setReviewLoading(true)
        if (quoteID) {
          const _review = await quoteClient.getQuoteReview(quoteID)
          if (isMounted() && _review) {
            setReviewQuestions(parseResponse(_review))
            setIsError(undefined)
          }
        }
        setReviewLoading(false)
      } catch (error) {
        setReviewQuestions([])
        setReviewLoading(false)
        setIsError({
          errorNumber: error?.response?.status,
          validationMessage: errorValidationMessage(error)
        })
      }
    },
    [quoteID]
  )

  return {
    reviewQuestions,
    isError,
    isReviewLoading
  }
}

interface IUseGetReview extends IErrorState {
  reviewQuestions: IQuoteReviewQuestion[] | []
  isReviewLoading: boolean
}
