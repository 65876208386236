import RichTextEditor from "components/RichTextEditor"
import { Button, Input, Modal, Select, Space, Typography } from "antd"
import { ISection } from "platform-client/types"
import { useEffect, useState } from "react"

const { Title } = Typography
const { Option } = Select

export const RichTextEndorsementsEditorModal: React.FC<IRichTextEditorModalProps> =
  ({
    visible,
    sections,
    onCancel = () => null,
    onSubmit = () => null,
    isLoading = false,
    headerText = "Add New Endorsement",
    ...props
  }) => {
    const [content, setContent] = useState<string>(props.content || "")
    const [selectedSection, setSelectedSection] = useState<string | undefined>(
      props.section
    )
    const [title, setTitle] = useState<string>(props.title || "")

    // When editing an endorsement we
    // need to watch for changes to the
    // title/content and pass those to
    // our internal state.
    useEffect(() => {
      if (props.title !== undefined) {
        setTitle(props.title)
      }
      if (props.content !== undefined) {
        setContent(props.content)
      }
    }, [props.title, props.content, headerText])

    const resetFields = () => {
      setContent("")
      setSelectedSection(undefined)
      setTitle("")
    }

    return (
      <Modal
        visible={visible}
        onCancel={() => onCancel(resetFields)}
        footer={
          <>
            <Button
              onClick={() => {
                onCancel(resetFields)
              }}
              disabled={isLoading}
              aria-label="Close modal"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              aria-label="Add new endorsement"
              onClick={() => {
                onSubmit(
                  { title, content, section: selectedSection },
                  resetFields
                )
              }}
              loading={isLoading}
              disabled={
                title === "" ||
                content === "" ||
                (sections && sections.length > 0 && !selectedSection)
              }
            >
              Submit
            </Button>
          </>
        }
      >
        <Space direction="vertical" style={{ width: "100%" }}>
          <Title level={4}>{headerText}</Title>
          {sections && sections.length > 0 && (
            <>
              <label>Section</label>
              <Select
                style={{ width: "100%" }}
                value={selectedSection}
                onChange={(option: string) => setSelectedSection(option)}
              >
                {(sections || []).map(({ name, id }) => (
                  <Option key={id} value={id} label={name}>
                    {name}
                  </Option>
                ))}
              </Select>
            </>
          )}
          <label>Title</label>
          <Input
            name="title"
            value={title}
            onChange={(text) => {
              setTitle(text.target.value)
            }}
          />
          <label>Content</label>
          <RichTextEditor content={content} setContent={setContent} />
        </Space>
      </Modal>
    )
  }
interface IRichTextEditorModalProps {
  title?: string
  content?: string
  section?: string
  visible: boolean
  sections?: ISection[]
  onSubmit: (
    endorsement: {
      title: string
      content: string
      section?: string
    },
    callback: () => void
  ) => void
  onCancel: (callback: () => void) => void
  isLoading: boolean
  headerText?: string
}
