import { Modal, notification } from "antd"
import { OnAnonymiseQuote } from "../../view"
import useOpus from "contexts/opus/hooks/use-opus"
import { handleValidationErrors } from "utils/pocketknife/handle-validation-errors"
import { AxiosError } from "axios"

export const useAnonymiseQuote = (
  quoteId: string,
  onAnonymiseQuote?: OnAnonymiseQuote
): IUseAnonymiseQuote => {
  const { quoteClient } = useOpus()

  return {
    showAnonymiseQuoteModal: () => {
      Modal.confirm({
        title: "Are you sure you want to anonymise this quote?",
        content: "You can't undo this action",
        centered: true,
        okText: "Anonymise",
        onOk: async () => {
          if (quoteId && onAnonymiseQuote) {
            const result = await quoteClient
              .anonymiseQuote(quoteId)
              .catch((error: AxiosError) => {
                handleValidationErrors(error)
              })

            if (result) {
              notification.success({
                message: null,
                description: "Quote data has been anonymised."
              })
              onAnonymiseQuote({ quoteId })
            }
          }
        }
      })
    }
  }
}

export interface IUseAnonymiseQuote {
  showAnonymiseQuoteModal: () => void
}
