import React from "react"
import { FileProtectOutlined } from "@ant-design/icons"
import { Layout, Table, Row, Col, Breadcrumb, Tag } from "antd"
import { SearchComponent } from "./components/Search"
import { IPolicyTable } from "models/policies/policyTable"
import { LayoutPageContent, LayoutPageHeader } from "components"
import { TErrorState } from "models/errors"
import {
  IConfigurationContext,
  IPolicyStates
} from "contexts/configuration/context"
import { DateDisplay } from "components/display/DateDisplay"
import { Link } from "react-router-dom"
import { IMoney } from "platform-client/types"
import { MoneyDisplay } from "components/display/MoneyDisplay"

export const AllPolicies: React.FunctionComponent<IAllPolicies> = (props) => {
  return (
    <Layout>
      <LayoutPageHeader
        ghost={false}
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item key="/opportunities">
              <FileProtectOutlined />
              &nbsp;Policies
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        title="All Policies"
        style={{ marginBottom: 20 }}
      />
      <LayoutPageContent error={props.isPoliciesError}>
        <Row
          justify="end"
          align="middle"
          style={{ marginBottom: 16, paddingTop: 16 }}
        >
          <Col span={9}>
            <SearchComponent
              searchValue={props.searchValue}
              setSearchValue={props.setSearchValue}
              setSearchLoading={props.setSearchLoading}
              policiesUpdate={props.setPoliciesData}
            />
          </Col>
        </Row>
        <Table
          scroll={{ x: "max-content" }}
          loading={props.arePoliciesLoading || props.isSearchLoading}
          dataSource={props.policies}
          rowKey="id"
          pagination={{
            defaultPageSize: 10,
            hideOnSinglePage: true,
            position: ["bottomCenter"],
            size: "small"
          }}
        >
          <Table.Column
            title="Created"
            dataIndex="dateCreated"
            render={(date: string) => <DateDisplay date={date} />}
          />
          <Table.Column
            title="Reference ID"
            key="policyReferenceID"
            render={(row: {
              policyReferenceID: string
              id: string
              policyID: string
            }) => (
              <Link to={`/policy/${row.id || row.policyID}`}>
                {row.policyReferenceID}
              </Link>
            )}
          />
          <Table.Column title="Product" dataIndex="productName" />
          <Table.Column
            title="Name insured"
            dataIndex="risk"
            render={(policyRisk: {
              proposer?: { personName: { firstName: string; lastName: string } }
              core?: {
                proposer?: {
                  personName: { firstName: string; lastName: string }
                }
              }
            }) => (
              <>
                {policyRisk?.proposer?.personName && (
                  <>
                    {policyRisk?.proposer?.personName.firstName}{" "}
                    {policyRisk?.proposer?.personName.lastName}
                  </>
                )}

                {policyRisk?.core?.proposer?.personName && (
                  <>
                    {policyRisk?.core?.proposer?.personName.firstName}{" "}
                    {policyRisk?.core?.proposer?.personName.lastName}
                  </>
                )}
              </>
            )}
          />
          <Table.Column
            title="Status"
            dataIndex="policyStatus"
            align="center"
            render={(text: IPolicyStates) => {
              const policyType =
                props.configuration?.policy?.state &&
                props.configuration?.policy?.state[text]
              return (
                <Tag color={policyType?.color}>
                  {policyType?.tag.toUpperCase()}
                </Tag>
              )
            }}
          />
          <Table.Column
            title="Last updated"
            dataIndex="dateUpdated"
            render={(date: string) => <DateDisplay date={date} />}
          />
          <Table.Column
            title="Premium"
            dataIndex="grossPremium"
            align="right"
            render={(value: IMoney) => (
              <MoneyDisplay
                value={value}
                isMajorCurrency={value.unit === "Major"}
              />
            )}
          />
          <Table.Column key="actions" />
        </Table>
      </LayoutPageContent>
    </Layout>
  )
}

interface IAllPolicies {
  policies?: IPolicyTable[] | undefined
  configuration: IConfigurationContext
  isPoliciesError: TErrorState
  arePoliciesLoading: boolean
  isSearchLoading: boolean
  searchValue: string | null | undefined
  setSearchValue: (param: string | null) => void
  setSearchLoading: (param: boolean) => void
  setPoliciesData: (data: IPolicyTable[] | undefined) => void
  isMajorCurrency?: boolean
}
