import ClaimsBoundary from "./boundary"
import AddClaim, { IAddClaimProps } from "./views/AddClaim"
import EditClaim, { EditClaimProps } from "./views/EditClaim"

export const VAddClaim: React.FC<IAddClaimProps> = (props) => (
  <ClaimsBoundary>
    <AddClaim {...props} />
  </ClaimsBoundary>
)

export const VEditClaim: React.FC<EditClaimProps> = (props) => (
  <ClaimsBoundary>
    <EditClaim {...props} />
  </ClaimsBoundary>
)
