import React from "react"
import { Card, List } from "antd"
import { LayoutCardLoader, LayoutCardEmpty } from "components"

import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons"
import { IPolicyBundle } from "models/policies/policy"
import TravelPackageList from "components/TravelPackageList"

const CARD_TITLE = "Sections of cover"

const IncludedIcon = (
  <CheckCircleTwoTone style={{ fontSize: 20 }} twoToneColor="#52c41a" />
)
const ExcludedIcon = (
  <CloseCircleTwoTone style={{ fontSize: 20 }} twoToneColor="#F5222D" />
)

export const PolicyCover: React.FunctionComponent<IPolicyCover> = ({
  isWorking = false,
  policyBundle
}) => {
  if (isWorking) {
    return <LayoutCardLoader title={CARD_TITLE} />
  }

  const cover = policyBundle?.product?.productItems

  if (
    policyBundle?.policyDetails.addon &&
    policyBundle.policy.lineOfBusiness === "travel"
  ) {
    return (
      <TravelPackageList
        packages={policyBundle.policyDetails.addon}
        product={policyBundle.product}
        excess={policyBundle.policyDetails.excesses.totalExcess}
      />
    )
  } else if (cover && cover.length > 0) {
    return (
      <Card title={CARD_TITLE}>
        <List
          dataSource={cover.map((coverItem) => ({
            title: coverItem.description,
            content: coverItem.active ? IncludedIcon : ExcludedIcon
          }))}
          itemLayout="horizontal"
          size="small"
          renderItem={(item: { title: string; content: React.ReactNode }) => (
            <List.Item>
              <List.Item.Meta description={item.title} />
              <div>{item.content}</div>
            </List.Item>
          )}
        />
      </Card>
    )
  } else {
    return <LayoutCardEmpty title={CARD_TITLE} />
  }
}

export interface IPolicyCover {
  isWorking?: boolean
  policyBundle?: IPolicyBundle
}
