import { IPolicyDetailEditSession } from "./quote-edit-session"

export * from "./quote-edit-session"

export const InitialEditSessionState: IPolicyDetailEditSession = {
  createdByID: "",
  dateCreated: "",
  dateUpdated: "",
  ownerID: "",
  id: "",
  policyDetailHeaderID: "",
  sourcePolicyDetailHistoryID: "",
  policyDetail: {
    sections: [],
    totalIntroducerCommission: null,
    totalGrossPremium: null,
    totalNetPremium: null,
    totalSectionTax: null,
    totalSectionTaxAggregate: [],
    totalCommission: null,
    totalNetPremiumInsurer: null,
    totalNetDiscount: null,
    totalGrossDiscount: null,
    fees: [],
    totalIncludingFees: null,
    productID: 0,
    productReferenceID: "",
    productVersion: 0,
    version: 0,
    declineReasons: [],
    referrals: [],
    endorsements: [],
    excesses: []
  },
  risk: {
    core: null,
    exposures: []
  },
  state: "Active",
  version: 0
}
