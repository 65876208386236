import { ITravelPackage } from "models/common/package"
import { ITravelPackageConfig } from "contexts/configuration/hooks/use-get-travel-package-config"
import { MoneyDisplay } from "components/display/MoneyDisplay"
import { ReactNode } from "react"
import formatDate from "dateformat"

const dateFormat = "d mmm yyyy"

export const resolvePackageLevel = (
  packageValue: ITravelPackage,
  packageConfig: ITravelPackageConfig | undefined,
  currencyCode: string | undefined
): string | ReactNode => {
  if (packageConfig?.packageType === 2) {
    const dateFrom = formatDate(packageValue.dateRange.from, dateFormat)
    const dateTo = formatDate(packageValue.dateRange.to, dateFormat)

    return `${dateFrom} - ${dateTo}`
  }

  if (packageConfig?.defaultLevel === "1") {
    return "Covered"
  }

  const packageLevel = packageValue.limit?.value ?? packageConfig?.defaultLevel

  const monetaryLevel = parseFloat(packageLevel)

  if (isNaN(monetaryLevel)) {
    return packageLevel
  }

  return (
    <MoneyDisplay
      value={{
        value: monetaryLevel,
        currencyCode: currencyCode ?? "AUD"
      }}
      isMajorCurrency
      hideZeroMinorCurrency
    />
  )
}
