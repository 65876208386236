import axios from "axios"
import fileDownload from "js-file-download"
import { getApiUrl } from "utils/api/apiUrl"

export const handleDownload = (
  filename: string,
  quoteId: string,
  token?: string
): void => {
  axios
    .get(`/quotes/${quoteId}/rating`, {
      responseType: "blob",
      timeout: 30000,
      baseURL: `${getApiUrl(window.location.host)}/v3`,
      headers: {
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`
      }
    })
    .then((res) => {
      fileDownload(res.data, `${quoteId}-${filename}`)
    })
}
