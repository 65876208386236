import { useState } from "react"
import { IRenewalQuoteState } from "contexts/opus/context"
import useOpus from "contexts/opus/hooks/use-opus"

const useSetActiveRenewalQuote: TUseSetActiveRenewalQuote = () => {
  const { renewalClient } = useOpus()
  const [loading, setLoading] = useState<boolean>(false)
  const [renewalQuoteState, setRenewalQuoteState] =
    useState<IRenewalQuoteState>()

  const setActiveRenewalQuote = async (policyID: string, quoteID: string) => {
    setLoading(true)
    try {
      const quoteState = await renewalClient.setActiveRenewalQuote(
        policyID,
        quoteID
      )
      if (quoteState) {
        setRenewalQuoteState(quoteState)
        setLoading(false)
        Promise.resolve()
      }
    } catch (e) {
      Promise.reject(e)
    }
  }

  return {
    loading,
    renewalQuoteState,
    setActiveRenewalQuote
  }
}

type TUseSetActiveRenewalQuote = () => {
  loading: boolean
  renewalQuoteState: IRenewalQuoteState | undefined
  setActiveRenewalQuote: (policyID: string, quoteID: string) => Promise<void>
}

export default useSetActiveRenewalQuote
