import { path } from "ramda"
import { createUnauthenticatedPlatformClient } from "utils/api/axiosConfig"

export type OrgSettingsKeys =
  | "currencyCode"
  | "currencySymbol"
  | "timezone"
  | "defaultCountryCode"
  | "dateFormat"
  | "fatZebraKey"
  | "fatZebraIsSandbox"
  | "fatZebraCssUrl"
  | "fatZebraCssSignature"
export type OrgSettings = Record<OrgSettingsKeys, string>

const getOrgSettings = (): Promise<OrgSettings> =>
  createUnauthenticatedPlatformClient()
    .get("/v2/config/organisation")
    .then(path(["data", "data"])) as Promise<OrgSettings>

const OrgSettingsClient = {
  getOrgSettings
}

export default OrgSettingsClient
