import { useMemo } from "react"
import { useQuery } from "react-query"
import { isEmpty } from "lodash"
import { Typography, Card, Table, Spin, Alert, Row } from "antd"
import usePlatform from "contexts/platform/use-platform"
import formatDate from "dateformat"
import { IPolicyDocument } from "platform-client/types/policy"
import { POLICY_DOCUMENTS_COLUMNS } from "./constants"

const { Title } = Typography

type docItem = {
  date: string
  docs: IPolicyDocument[]
}

const formatDocuments = (documents: IPolicyDocument[]): docItem[] => {
  const result = documents.reduce(
    (acc: { [key: string]: IPolicyDocument[] }, document: IPolicyDocument) => {
      const date = formatDate(new Date(document.localDateCreated), "dd/mm/yyyy")
      return {
        ...acc,
        [date]: [...(acc[date] || []), document]
      }
    },
    {}
  )

  return Object.entries(result).map(([date, docs]) => ({
    date,
    docs
  }))
}

const DocumentsComponent: React.FC<IDocuments> = (props) => {
  const { policy } = usePlatform()
  const { data, isLoading, isError } = useQuery(
    ["policy", props.policyID, "documents"],
    async () => policy.getPolicyDocuments(props.policyID)
  )

  const documents = useMemo(
    () => formatDocuments(data?.documents || []),
    [data]
  )

  if (isLoading)
    return (
      <Row justify="center" align="bottom" style={{ height: 100 }}>
        <Spin />
      </Row>
    )

  if (isError)
    return (
      <Alert type="error" message="Something went wrong. Please try again" />
    )

  if (isEmpty(documents))
    return <Alert message="There are no documents available" />

  return (
    <>
      {documents?.map(({ date, docs }) => (
        <Card style={{ marginBottom: "20px" }}>
          <Title level={4}>{date}</Title>
          <Table
            loading={isLoading}
            columns={POLICY_DOCUMENTS_COLUMNS}
            scroll={{ x: "max-content" }}
            dataSource={docs}
            rowKey="id"
            pagination={{
              defaultPageSize: 10,
              hideOnSinglePage: true,
              position: ["bottomCenter"],
              size: "small"
            }}
          />
        </Card>
      ))}
    </>
  )
}

export const Documents = DocumentsComponent

export interface IDocuments {
  policyID: string
}
