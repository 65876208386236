import { Card, Table, Tag } from "antd"
import { DateDisplay } from "components/display/DateDisplay"
import { MoneyDisplay } from "components/display/MoneyDisplay"
import {
  IMoney,
  IPolicyHistorySummary,
  PolicyHistoryType
} from "platform-client/types"
import { Link } from "react-router-dom"

const { Column } = Table

const historyType: Record<PolicyHistoryType, { text: string; color?: string }> =
  {
    MTA: { text: "ADJUSTMENT", color: "blue" },
    InitialPurchase: { text: "INITIAL PURCHASE", color: "green" },
    Refund: { text: "REFUND", color: "orange" },
    Cancellation: { text: "CANCELLATION", color: "red" }
  }

const PolicyHistorySummary: React.FC<IPolicyHistorySummaryProps> = ({
  policyHistorySummary = [],
  isLoading = false,
  ...props
}) => {
  return (
    <Card
      title="Policy history"
      style={{ marginTop: "20px" }}
      headStyle={{
        borderTop: "1px solid #f0f0f0",
        borderLeft: "1px solid #f0f0f0",
        borderRight: "1px solid #f0f0f0"
      }}
      bodyStyle={{ padding: 0 }}
      bordered={false}
    >
      <Table
        bordered
        dataSource={policyHistorySummary}
        loading={isLoading}
        locale={{ emptyText: "No policy history found." }}
        size="small"
      >
        <Column
          title="Date"
          dataIndex="effectiveDate"
          render={(value: string) => <DateDisplay date={value} />}
        />
        <Column
          title="Event"
          dataIndex="type"
          render={(value: PolicyHistoryType) => (
            <Tag color={historyType[value].color}>
              {historyType[value].text}
            </Tag>
          )}
        />
        <Column
          title="Premium"
          dataIndex="premium"
          render={(value: IMoney) => (
            <MoneyDisplay value={value} isMajorCurrency />
          )}
          align="right"
        />
        <Column
          title="Difference"
          dataIndex="premiumDifference"
          render={(value: IMoney) => (
            <MoneyDisplay showSymbols value={value} isMajorCurrency />
          )}
          align="right"
        />
        <Column
          title="Invoice"
          render={(row: IPolicyHistorySummary) =>
            row.invoiceID ? (
              <Link to={`/invoices/${row.invoiceID}`}>
                {row.invoiceReferenceID}
              </Link>
            ) : (
              "-"
            )
          }
        />
      </Table>
    </Card>
  )
}

export default PolicyHistorySummary

interface IPolicyHistorySummaryProps {
  policyHistorySummary: IPolicyHistorySummary[]
  isLoading?: boolean
}
