import React from "react"
import { InputNumber } from "antd"
import getCurrencySymbol from "utils/pocketknife/get-currency-symbol"
import {
  currencyPenceToPounds,
  currencyPoundsToPence
} from "utils/pocketknife/currency-pence-to-pounds"

const MoneyInput: React.FC<IMoneyInput> = ({
  value,
  currencyCode,
  valueChanged,
  ...props
}) => {
  const inputValue = value ? currencyPenceToPounds(value) : undefined

  const onChange = (value: number | string) => {
    valueChanged(currencyPoundsToPence(Number(value)))
  }

  return (
    <InputNumber
      role="MoneyInput"
      defaultValue={inputValue}
      onChange={onChange}
      prefix={currencyCode && getCurrencySymbol(currencyCode)}
    />
  )
}

export interface IMoneyInput {
  value: number | undefined
  currencyCode?: string
  valueChanged: (newValue: number) => void
}

export default MoneyInput
