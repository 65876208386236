import { FullTable, IFullTable } from "./FullTable"
import { HalfTable, IHalfTable } from "./HalfTable"

export const Table: ITable = {
  FullTable,
  HalfTable
}

export interface ITable {
  FullTable: React.FC<IFullTable>
  HalfTable: React.FC<IHalfTable>
}
