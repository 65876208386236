import { DatePicker, Form, Input, Select, Typography } from "antd"
import { IQuestion } from "platform-client/types"
import validationRules from "utils/validators"

const mapQuestionToField = (question: IQuestion): JSX.Element => {
  /**
   * Map question to field item using ant design components.
   * These should be added as needed.
   */
  switch (question.questionType) {
    case "Group":
      return (
        <Form.Item
          name={question.referenceID || undefined}
          tooltip={question.text.help}
          style={{ marginBottom: 0 }}
        >
          {question.fields && question.fields.map(mapQuestionToField)}
        </Form.Item>
      )
    case "Number":
      return (
        <Form.Item
          name={question.referenceID || undefined}
          label={question.text.question}
          help={question.text.help}
          extra={question.text.detail}
          rules={validationRules(question.validation)}
        >
          <Typography.Text>{question.text.question}</Typography.Text>
          <Input size="large" type="number" />
        </Form.Item>
      )
    case "Textarea":
      return (
        <Form.Item
          name={question.referenceID || undefined}
          label={question.text.question}
          help={question.text.help}
          extra={question.text.detail}
          rules={validationRules(question.validation)}
        >
          <Input.TextArea size="large" />
        </Form.Item>
      )
    case "Select":
      return (
        <Form.Item
          name={question.referenceID || undefined}
          label={question.text.question}
          help={question.text.help}
          extra={question.text.detail}
          rules={validationRules(question.validation)}
        >
          <Select size="large" showSearch>
            {question.answerOptions &&
              question.answerOptions.map((option, i) => (
                <Select.Option
                  key={option.referenceID || i}
                  value={JSON.stringify(option)}
                >
                  {option.text}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      )
    case "DatePicker":
      return (
        <Form.Item
          name={question.referenceID || undefined}
          label={question.text.question}
          help={question.text.help}
          extra={question.text.detail}
          rules={validationRules(question.validation)}
        >
          <DatePicker />
        </Form.Item>
      )
      break
    case "Input":
    default:
      return (
        <Form.Item
          name={question.referenceID || undefined}
          label={question.text.question}
          help={question.text.help}
          extra={question.text.detail}
          rules={validationRules(question.validation)}
        >
          <Input size="large" />
        </Form.Item>
      )
  }
}

export default mapQuestionToField
