/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import styled from "styled-components"
import { ConfigProvider, Layout } from "antd"
import { LayoutHeader } from "components"
import { Switch, Route, Redirect } from "react-router-dom"
import { Helmet } from "react-helmet"
import { useConfigurationContext } from "contexts/configuration/context"
import { MAIN_ROUTES } from "./routes"
import { DefaultView } from "containers/default"
import PlatformProvider from "contexts/platform/provider"
import { UserProvider } from "contexts/user/provider"
import { LayoutAlerts } from "components/LayoutAlerts"
import OpusProvider from "contexts/opus/provider"

const { Content } = Layout

const AppWrapper = styled(Layout)`
  min-height: 100vh;
  .ant-layout-content,
  .ant-layout {
    height: 100%;
  }
`

export const AppChrome = (): JSX.Element => {
  const configuration = useConfigurationContext()

  const defaultPath =
    configuration.app.startingSection === "Opportunities"
      ? "/opportunities"
      : "/quotes"

  const theme = configuration?.app?.theme

  React.useEffect(() => {
    if (theme === undefined) return
    ConfigProvider.config({
      theme
    })
  }, [theme])

  return (
    <OpusProvider>
      <PlatformProvider>
        <UserProvider>
          <Helmet>
            <title>{configuration?.app?.meta?.title || "Aventus Portal"}</title>
            {configuration?.app?.meta?.favicon && (
              <link rel="icon" href={configuration?.app?.meta?.favicon} />
            )}
          </Helmet>
          <AppWrapper>
            <LayoutHeader />
            <LayoutAlerts
              platformVersion={configuration.flags.platformVersion}
            />
            <Layout>
              <Content>
                <Switch>
                  <Route key="defaultPath" path="/" exact>
                    <Redirect to={defaultPath} />
                  </Route>
                  {MAIN_ROUTES.map(({ path, component }, i) => (
                    <Route key={path} path={path} component={component} exact />
                  ))}
                  <Route key="fallback" component={DefaultView} />
                </Switch>
              </Content>
            </Layout>
          </AppWrapper>
        </UserProvider>
      </PlatformProvider>
    </OpusProvider>
  )
}
