import React, { useState, useEffect, useCallback, useRef } from "react"
import { Input } from "antd"
import { debounce } from "debounce"
import useOpus from "contexts/opus/hooks/use-opus"
import { IPortalQuoteTable } from "models/quotes/portalQuoteTable"

const { Search } = Input

export const SearchComponent: React.FunctionComponent<ISearchComponent> = (
  props
) => {
  const [isSending, setIsSending] = useState(false)
  const isMounted = useRef(true)
  const { quoteClient } = useOpus()

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const sendRequest = useCallback(
    async (value: string) => {
      if (isSending) return

      props.setSearchLoading(true)
      setIsSending(true)

      if (value) {
        props.setSearchValue(value)
        const _quotes = await quoteClient.getQuoteSearch(value)

        if (_quotes) {
          if (!props.quotesFilter) {
            _quotes.results && props.quotesUpdate(_quotes.results)
          } else {
            const quotesFiltered = _quotes.results.filter(
              (singleQuote) =>
                singleQuote.quoteState === props.quotesFilter?.key
            )
            _quotes.results && props.quotesUpdate(quotesFiltered)
          }
        }

        props.setSearchLoading(false)
      } else if (!value) {
        props.setSearchValue(null)
        props.setSearchLoading(false)
      }

      if (isMounted.current) {
        setIsSending(false)
        props.setSearchLoading(false)
      }
    },
    [isSending, quoteClient, props]
  )

  return (
    <Search
      style={{ width: 425 }}
      onSearch={debounce((value: string) => {
        sendRequest(value)
      }, 200)}
      placeholder="search by quote number, policy number, name, or address"
      enterButton="Search"
      defaultValue={props.searchValue || ""}
      allowClear={true}
    />
  )
}

interface ISearchComponent {
  quotesUpdate: (data: IPortalQuoteTable[]) => void
  setSearchValue: (value: string | null) => void
  setSearchLoading: (value: boolean) => void
  quotesFilter: { value: string; label: string; key: string } | undefined
  searchValue: string | undefined | null
}
