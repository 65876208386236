import RichTextEditor from "components/RichTextEditor"
import { Button, Modal, Typography } from "antd"
import { useReferQuoteModal } from "./useReferQuoteModal"

interface IRequestReviewModalProps {
  visible: boolean
  content?: string
  setContent?: (content: string) => void
  onSubmit: (content: string) => void
  onCancel: () => void
  isLoading: boolean
  headerText?: string
}

const { Title } = Typography

export const ReferQuoteModal: React.FC<IRequestReviewModalProps> = ({
  visible = true,
  content,
  setContent,
  onCancel,
  onSubmit,
  isLoading = false,
  headerText = "Reason for Manual Review"
}) => {
  const referQuoteModal = useReferQuoteModal()
  const isInternalState = content === undefined

  const handleUpdate = (value: string) => {
    setContent?.(value)

    if (isInternalState) {
      referQuoteModal.setContent(value)
    }
  }

  const handleSubmit = () => {
    onSubmit(content || referQuoteModal.content)

    if (isInternalState) {
      referQuoteModal.setContent("")
    }
  }

  const handleCancel = () => {
    onCancel()

    if (isInternalState) {
      referQuoteModal.setContent("")
    }
  }

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={
        <>
          <Button onClick={handleCancel} disabled={isLoading}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleSubmit} loading={isLoading}>
            Submit
          </Button>
        </>
      }
    >
      <Title level={4}>{headerText}</Title>
      <RichTextEditor
        content={
          isInternalState ? referQuoteModal.content : (content as string)
        }
        setContent={handleUpdate}
      />
    </Modal>
  )
}
