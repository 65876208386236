export const breakpoint = {
  mobile_small: "320px",
  mobile_medium: "375px",
  mobile_large: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptop_large: "1440px",
  desktop: "1920px",
  desktop_large: "2560px"
}
