import React from "react"
import { useGetNewQuoteUrl } from "contexts/configuration/hooks/use-get-new-quote-url"
import { useToken } from "contexts/authorization/hooks/use-token"
import { RouteComponentProps, withRouter } from "react-router"
import { useApiInfo } from "contexts/configuration/hooks/use-api-info"
import { NewQuote } from ".."
import { useGetProduct } from "contexts/product/use-get-product"

export const HandleNewQuoteRouting: React.FunctionComponent<IHandleNewQuoteRoutingProps> =
  (props) => {
    const { apiInfo } = useApiInfo()
    const { userToken } = useToken()
    const { product } = useGetProduct(
      props.match.params.productRef,
      props.match.params.productCover
    )

    const id = props.match.params.id

    const questionSetName =
      product?.additionalQuestionSets[0]?.referenceID || undefined
    const { finalUrl } = useGetNewQuoteUrl(
      props.match.params.section,
      apiInfo,
      props.match.params.productRef,
      props.match.params.productCover,
      questionSetName,
      props.match.params.policyId,
      id,
      props.match.params.userId
    )

    const section = props.match.params.section
    return (
      <NewQuote
        quoteID={id}
        url={finalUrl}
        token={userToken}
        quoteSection={section}
        history={props.history}
      ></NewQuote>
    )
  }

export type IHandleNewQuoteRoutingProps = RouteComponentProps<{
  id: string
  productRef: string
  productCover: string
  section: string
  quoteId: string
  policyId: string
  userId: string
}>
export default withRouter(HandleNewQuoteRouting)
