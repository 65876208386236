import { DownOutlined } from "@ant-design/icons"
import { Alert, Button, Descriptions, Dropdown, Menu, Modal } from "antd"
import { DateDisplay } from "components/display/DateDisplay"
import { MoneyDisplay } from "components/display/MoneyDisplay"
import { IPricingServiceFee } from "contexts/opus/context"
import { IOrgSettings } from "contexts/organisation/context"
import { useOrgSettings } from "contexts/organisation/hooks/use-org-settings"
import {
  ICompanyInRole,
  IPaymentPlan,
  IPriceSectionHeader
} from "platform-client/types"
import { IQuoteChange, Proposer } from "platform-client/types/quote"
import React from "react"
import styled from "styled-components"
import { IV2PaymentPlan } from "../../index.v2"

const StyledMoneyDescriptions = styled(Descriptions)`
  .ant-descriptions-item.total .ant-descriptions-item-label,
  .ant-descriptions-item.total .ant-descriptions-item-content {
    font-weight: bold;
  }

  .ant-descriptions-item-content {
    justify-content: flex-end;
  }
`

const friendlyPaymentPlanName: Record<string, string> = {
  PayOnAccount: "Pay by invoice",
  FixedTermSinglePayment: "Pay by card",
  ExternalCollection: "Pay on account"
}

const QuoteBindWithConfirmation: React.FC<IQuoteBindConfirmationProps> = ({
  onClose,
  onBindQuote,
  proposer,
  quoteReferenceID,
  quoteChanges = [],
  quoteCompanies = [],
  quotePaymentPlans,
  coverStartDate,
  coverEndDate,
  platformVersion,
  blockBind
}) => {
  const { orgSettings } = useOrgSettings()
  const [visible, setVisible] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [selectedPaymentPlan, setSelectedPaymentPlan] = React.useState<
    IPaymentPlan | IV2PaymentPlan
  >()
  const personName = proposer && "personName" in proposer && proposer.personName
  const introducer = [...quoteCompanies].shift()

  const hasChanges = quoteChanges.length > 0
  const updatedCoverStartDate = quoteChanges.find(
    (item) => item.propertyIdentifier === "CoverStartDate"
  )
  const updatedCoverEndDate = quoteChanges.find(
    (item) => item.propertyIdentifier === "CoverEndDate"
  )

  // Only exists on v3

  const handleBind = () => {
    setLoading(true)
    if (selectedPaymentPlan) {
      if (typeof onBindQuote === "function") {
        onBindQuote(selectedPaymentPlan, () => {
          setVisible(false)
          setLoading(false)
        })
      }
    }
  }

  const handleCancel = () => {
    setVisible(false)
    if (typeof onClose === "function") {
      onClose()
    }
  }

  return (
    <>
      <Dropdown
        disabled={blockBind === true}
        overlay={
          <Menu onClick={() => setVisible(!visible)}>
            {quotePaymentPlans &&
              quotePaymentPlans.map((item) => (
                <Menu.Item
                  key={item.price.pricingPlanReferenceID}
                  aria-label={item.price.planType}
                  onClick={() => {
                    setVisible(true)
                    setSelectedPaymentPlan(item)
                  }}
                >
                  {friendlyPaymentPlanName[item.price.planType]}
                </Menu.Item>
              ))}
          </Menu>
        }
      >
        <Button
          loading={loading}
          aria-label="bind-quote"
          type="primary"
          disabled={blockBind === true}
        >
          Bind <DownOutlined />
        </Button>
      </Dropdown>
      <Modal
        visible={visible}
        footer={[
          <Button
            loading={loading}
            type="primary"
            onClick={handleBind}
            disabled={blockBind === true}
          >
            Bind
          </Button>,
          <Button type="default" onClick={handleCancel}>
            Close
          </Button>
        ]}
        onCancel={handleCancel}
      >
        <h1>Bind Quote</h1>

        {hasChanges && (
          <Alert
            style={{ marginBottom: "14px" }}
            message="The following changes have been made to this quote"
            type="warning"
            description={
              <ol style={{ margin: 0, paddingLeft: "14px" }}>
                {quoteChanges.map((change) => (
                  <li>{change.message}</li>
                ))}
              </ol>
            }
          />
        )}

        <hr />

        <Descriptions size="small" column={1} style={{ margin: "20px 0 0" }}>
          <Descriptions.Item label="Quote Reference">
            {quoteReferenceID}
          </Descriptions.Item>
          {coverStartDate && (
            <Descriptions.Item label="Cover Start Date">
              <DateDisplay
                date={
                  updatedCoverStartDate
                    ? String(updatedCoverStartDate.newValue)
                    : coverStartDate
                }
              />
            </Descriptions.Item>
          )}
          {coverEndDate && (
            <Descriptions.Item label="Cover End Date">
              <DateDisplay
                date={
                  updatedCoverEndDate
                    ? String(updatedCoverEndDate.newValue)
                    : coverEndDate
                }
              />
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Proposer">
            {`${personName.firstName} ${personName.lastName}`}
          </Descriptions.Item>
          {introducer && introducer.company && (
            <Descriptions.Item label="Sub-broker/Introducer">
              {introducer.company.name}
            </Descriptions.Item>
          )}
        </Descriptions>
        <hr />
        {selectedPaymentPlan && orgSettings && (
          <TotalsDisplay
            fees={
              selectedPaymentPlan && "fees" in selectedPaymentPlan
                ? selectedPaymentPlan.fees
                : undefined
            }
            orgSettings={orgSettings}
            priceHeader={
              platformVersion === "v3"
                ? selectedPaymentPlan.price.immediateAmount
                : selectedPaymentPlan.price.total
            }
          />
        )}
      </Modal>
    </>
  )
}

const TotalsDisplay: React.FC<ITotalsDisplayProps> = ({
  fees,
  priceHeader,
  orgSettings
}) => {
  const totalIntroducerCommission =
    priceHeader && "introducerCommission" in priceHeader
      ? priceHeader.introducerCommission
      : undefined

  let totalFees: number

  if (fees) {
    totalFees = fees.reduce((a, v) => a + v.amount.value, 0)
  } else {
    totalFees = priceHeader.rows
      ? priceHeader.rows?.reduce(
          (a, c) => (c.type === "Fee" ? a + c.gross.value : a),
          0
        )
      : 0
  }

  return (
    <StyledMoneyDescriptions
      size="small"
      column={1}
      style={{ margin: "20px 0 0" }}
    >
      {priceHeader.net && (
        <Descriptions.Item label="Insurance Premium">
          <MoneyDisplay value={priceHeader.net} />
        </Descriptions.Item>
      )}
      {priceHeader.tax?.total && (
        <Descriptions.Item label="IPT Total">
          <MoneyDisplay value={priceHeader.tax.total} />
        </Descriptions.Item>
      )}
      {totalFees && (
        <Descriptions.Item label="Underwriting Fee">
          <MoneyDisplay
            value={{
              value: totalFees,
              currencyCode: orgSettings?.currencyCode ?? "GBP"
            }}
          />
        </Descriptions.Item>
      )}
      {totalIntroducerCommission && (
        <Descriptions.Item label="Sub-broker/Introducer Commission">
          <MoneyDisplay value={totalIntroducerCommission} />
        </Descriptions.Item>
      )}
      {priceHeader.gross && (
        <Descriptions.Item className="total" label="Amount Owed">
          <MoneyDisplay value={priceHeader.gross} />
        </Descriptions.Item>
      )}
    </StyledMoneyDescriptions>
  )
}

export default QuoteBindWithConfirmation

interface ITotalsDisplayProps {
  fees?: IPricingServiceFee[]
  priceHeader: IPriceSectionHeader
  orgSettings: IOrgSettings
}

interface IQuoteBindConfirmationProps {
  quoteReferenceID: string
  proposer?: Proposer
  quoteCompanies?: ICompanyInRole[]
  quotePaymentPlans?: IPaymentPlan[]
  onBindQuote?: (paymentPlan: IPaymentPlan, callback?: () => void) => void
  onClose?: () => void
  platformVersion?: "v2" | "v3" | undefined
  blockBind?: boolean
  quoteChanges?: IQuoteChange[]
  coverStartDate?: string
  coverEndDate?: string
}
