import { Alert, Button, Form } from "antd"
import { useFirebaseAuthContext } from "contexts/authorization/firebase-auth-contex"
import React from "react"
import { useHistory } from "react-router"

export const SigninComponent: React.FunctionComponent<ISigninComponent> = (
  props
) => {
  const [email, setEmail] = React.useState<string>("")
  const [showLoginError, setShowLoginError] = React.useState<boolean>(false)
  const [password, setPassword] = React.useState<string>("")

  const history = useHistory()

  const [form] = Form.useForm()

  const authContext = useFirebaseAuthContext()

  const loginWithUsernameAndPassword = () => {
    setShowLoginError(false)

    authContext
      .signin(email, password)
      .then((userCredential) => {
        // Signed in
        history.push("/")
      })
      .catch((error) => {
        setShowLoginError(true)
      })
  }

  return (
    <>
      <h3 className="title">Log in to continue to the Portal</h3>

      {showLoginError && (
        <Alert message="Invalid username or password" type="error" showIcon />
      )}

      <Form
        layout="vertical"
        id="loginForm"
        style={{ margin: "20px 0 0" }}
        form={form}
      >
        <Form.Item
          name="email-address"
          label="Email address"
          rules={[{ required: true, message: "email is required" }]}
        >
          <input
            id="email-address"
            name="email"
            type="email"
            placeholder="Email address"
            onChange={(e) => setEmail(e.target.value)}
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          name="password"
          label="Password"
          rules={[{ required: true, message: "password is required" }]}
        >
          <input
            id="password"
            name="password"
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Button
          type="link"
          onClick={() => {
            setShowLoginError(false)
            props.showResetPasswordForm()
          }}
          style={{ paddingLeft: "0px" }}
        >
          Don't remember your password?
        </Button>

        <div className="buttons">
          <Button
            type="primary"
            size="large"
            onClick={(e) => {
              e.preventDefault()
              form.validateFields().then(() => loginWithUsernameAndPassword())
            }}
          >
            Login
          </Button>
        </div>
      </Form>
    </>
  )
}

interface ISigninComponent {
  showResetPasswordForm: () => void
}
