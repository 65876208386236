import React, { ErrorInfo, ReactNode } from "react"
import { Layout, Result, Button } from "antd"
import { LayoutContainer, LayoutContent } from "components"

interface IAppBoundaryProps {
  children: ReactNode
}

interface IAppBoundaryState {
  hasError: boolean
  error?: Error
  errorInfo?: ErrorInfo
}

export class AppBoundary extends React.Component<
  IAppBoundaryProps,
  IAppBoundaryState
> {
  constructor(props: IAppBoundaryProps) {
    super(props)
    this.state = { hasError: false, error: undefined, errorInfo: undefined }
  }

  static getDerivedStateFromError(): IAppBoundaryState {
    return { hasError: true, error: undefined, errorInfo: undefined }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ hasError: true, error, errorInfo })
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <Layout>
          <LayoutContainer>
            <LayoutContent>
              <Result
                status={"500"}
                title={"Oops"}
                subTitle={"Sorry, there seems to have been a problem"}
                extra={
                  <Button
                    type="primary"
                    onClick={() => window.location.reload()}
                  >
                    {"Reload page"}
                  </Button>
                }
              />
            </LayoutContent>
          </LayoutContainer>
        </Layout>
      )
    }

    return this.props.children
  }
}
