import { Alert, Col, DatePicker, Descriptions, Modal, Row } from "antd"
import { IValidationError } from "models/errors"
import { IRateFile } from "models/ratings/IRateFile"
import { Moment } from "moment"
import { Nullable } from "platform-client/types"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import formatDate from "dateformat"
import moment from "moment-timezone"

const StyledRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const StyledCol = styled(Col)`
  & > * {
    margin-bottom: 8px;
    :last-child {
      margin-bottom: 0;
    }
  }
`

const StyledDiv = styled("div")`
  margin-bottom: 20px;
`

export const UpdateGoLiveDateModal: React.FunctionComponent<IUpdateGoLiveDateModal> =
  ({
    rateFile,
    isVisible,
    onCancel,
    onUpdate,
    isLoading,
    validationErrors,
    timezone
  }) => {
    const [goLiveDateTime, setGoLiveDateTime] = useState<Moment>(
      moment.tz(rateFile?.goLiveDateTime, timezone)
    )

    const originalGoLiveDateTime = moment(rateFile?.goLiveDateTime).tz(
      timezone,
      true
    )

    useEffect(() => {
      setGoLiveDateTime(moment.tz(rateFile?.goLiveDateTime, timezone))
    }, [rateFile, timezone])

    const handleCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
      setGoLiveDateTime(originalGoLiveDateTime)
      onCancel(e)
    }

    const formatTimezone = () => {
      if (timezone === "Etc/GMT-10") return "AEST"

      return timezone
    }

    return (
      <Modal
        title="Update Go Live Date"
        centered
        destroyOnClose
        visible={isVisible}
        onCancel={handleCancel}
        okText="Update"
        okButtonProps={{
          disabled: !goLiveDateTime
        }}
        onOk={() => {
          if (rateFile) {
            onUpdate(goLiveDateTime.format("YYYY-MM-DDTHH:mm:ss"))
          }
        }}
        confirmLoading={isLoading}
      >
        <>
          {validationErrors && validationErrors.length > 0 && (
            <Alert
              description={
                <ul>
                  {validationErrors.map((error) => (
                    <li>{error.message}</li>
                  ))}
                </ul>
              }
              type="error"
              closable
              style={{ marginBottom: "15px" }}
            />
          )}
          <StyledDiv>
            <Descriptions
              size="small"
              column={1}
              style={{ margin: "20px 0 0" }}
            >
              <Descriptions.Item label="Created">
                {rateFile &&
                  formatDate(
                    new Date(rateFile?.dateCreated),
                    "dd/mm/yyyy hh:MM:ss TT"
                  )}
              </Descriptions.Item>
              <Descriptions.Item label="Product">
                {rateFile?.productName}
              </Descriptions.Item>
              <Descriptions.Item label="Filename">
                {rateFile?.filename}
              </Descriptions.Item>
              <Descriptions.Item label="Rates Version">
                {rateFile?.version}
              </Descriptions.Item>
              <Descriptions.Item label="Changelog">
                {rateFile?.changelog}
              </Descriptions.Item>
            </Descriptions>
          </StyledDiv>
          <StyledCol>
            <StyledRow>
              <h4>Go Live Date ({formatTimezone()})</h4>

              <DatePicker
                id="goLiveDate"
                showTime={true}
                allowClear={false}
                format="DD/MM/YYYY hh:mm:ss A"
                defaultValue={goLiveDateTime}
                value={goLiveDateTime}
                disabledDate={(current) =>
                  current.isBefore(moment.tz(timezone).startOf("day"))
                }
                onChange={(date) => {
                  setGoLiveDateTime(date as Moment)
                }}
                disabled={isLoading}
              />
            </StyledRow>
            <StyledRow>
              <p>UTC</p>
              <p>
                {goLiveDateTime.clone().utc().format("DD/MM/YYYY hh:mm:ss A")}
              </p>
            </StyledRow>
          </StyledCol>
        </>
      </Modal>
    )
  }

export interface IUpdateGoLiveDateModal {
  rateFile: Nullable<IRateFile>
  isVisible: boolean
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  onUpdate: (goLiveDate: string) => void
  isLoading: boolean
  validationErrors: IValidationError[] | undefined
  timezone: string
}
