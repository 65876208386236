import { EricBoundary } from "./boundary"
import { EricPolicies, IEric as IEricPolicies } from "./views/EricPolicies"
import { EricUpload } from "./views/EricUpload"
export const VEricPolicies: React.FC<IEricPolicies> = (props) => (
  <EricBoundary>
    <EricPolicies {...props} />
  </EricBoundary>
)

export const VEricUpload: React.FC<IEricPolicies> = (props) => (
  <EricBoundary>
    <EricUpload {...props} />
  </EricBoundary>
)
