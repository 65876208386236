import { useEffect } from "react"
import {
  RouteComponentProps,
  useHistory,
  useParams,
  withRouter
} from "react-router"
import { TAventusQuote, TNewQuoteMessage } from "models/quotes/externalmodels"
import { IFrameContainer } from "components/IFrame"
import { useConfigurationContext } from "contexts/configuration/context"
import usePlatform from "contexts/platform/use-platform"
import { useQuery } from "react-query"

export const SymphonyMTA = (
  props: ISymphonyMTARoutingProps
): JSX.Element | null => {
  const platform = usePlatform()
  const { symphony } = useConfigurationContext()
  const { productId, policyId } = useParams<{
    productId: string
    policyId?: string
  }>()

  const title = "Policy Adjustment"

  const history = useHistory()

  useEffect(() => {
    // Any events that come back from the Iframe will live here
    const eventHandler = (event: TNewQuoteMessage) => {
      const data = event.data

      if (data.event === TAventusQuote.summary && data.quoteId) {
        history.push({
          pathname: `/quote/${data.quoteId}`
        })
      }
    }
    window.addEventListener("message", eventHandler)
    return () => window.removeEventListener("message", eventHandler)
  })

  // In parallel, we need to also get the Product for the given ID.
  // This is primarily so that we can get the Product Reference in order
  // to create an Opportunity. Note that this is going to change in future,
  // and the Opportunity endpoint will be modified to also accept
  // a product ID alongside or instead of the reference.

  const { data: product, isSuccess: productSuccessful } = useQuery(
    ["getProductById", productId],
    async () => await platform.product.getProductById(productId),
    {
      enabled: !!platform.token
    }
  )

  // Create an opportunity for this MTA Quote

  const { data: opportunity, isSuccess: opportunitySuccessful } = useQuery(
    ["createOpportunity", productId],
    async () =>
      await platform.opportunity.createOpportunity({
        openSession: true,
        answers: [],
        partnerID: "",
        name: "Portal",
        partnerReference: "",
        productReferenceID: product?.referenceID || "",
        introducer: null,
        policyholderID: null
      }),
    {
      enabled: !!platform.token && productSuccessful
    }
  )

  const url = `${symphony.baseUrl}/products/${product?.id}/quote/MTA/${opportunity?.questionSetReferenceID}?editSessionID=${opportunity?.sessionID}&token=${platform.token}&chrome=false&policyId=${policyId}`

  if (productSuccessful && opportunitySuccessful && opportunity?.sessionID) {
    return (
      <IFrameContainer
        id={productId}
        url={url}
        title={title}
        name={"MTA"}
      ></IFrameContainer>
    )
  } else {
    return null
  }
}

export type ISymphonyMTARoutingProps = RouteComponentProps<{
  productId: string
  policyId: string
}>

export default withRouter(SymphonyMTA)
