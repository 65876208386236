import { useEffect } from "react"
import {
  RouteComponentProps,
  useHistory,
  useParams,
  withRouter
} from "react-router"
import {
  IGenericEvent,
  TAventusQuote,
  TNewQuoteMessage
} from "models/quotes/externalmodels"
import { IFrameContainer } from "components/IFrame"
import {
  IUrlPieces,
  useConfigurationContext
} from "contexts/configuration/context"
import usePlatform from "contexts/platform/use-platform"
import { useQuery } from "react-query"
import { IPlatformClient } from "contexts/platform/client"
import { LayoutLoading } from "components"

export const NewSymphonyQuote = (
  props: INewSymphonyQuoteRoutingProps
): JSX.Element | null => {
  const platform = usePlatform()
  const { flags, symphony } = useConfigurationContext()
  const { section, productId, productReference, quoteId } = useParams<{
    section: string
    productId: string
    productReference?: string
    quoteId?: string
  }>()

  const titles: { [key: string]: string } = {
    purchase: "Purchase quote",
    new: "New quote",
    duplicate: "Duplicate quote",
    saved: "Edit quote",
    edit: "Edit quote",
    renew: "Edit Renewal Quote"
  }

  const title = titles[section] || ""

  switch (flags.platformVersion) {
    case "v3":
      return (
        <NewSymphonyQuoteV3
          {...props}
          section={section}
          productId={productId}
          symphony={symphony}
          title={title}
          platform={platform}
        />
      )
    case "v2":
      if (productReference) {
        return (
          <NewSymphonyQuoteV2
            section={section}
            productId={productId}
            productReference={productReference}
            symphony={symphony}
            platform={platform}
            title={title}
            quoteid={quoteId}
          />
        )
      }
      throw new Error("Product not configured for symphony quote flow")
    default:
      throw new Error("Platform version not set")
  }
}

export const NewSymphonyQuoteV3 = (
  props: INewSymphoneQuoteV3Props
): JSX.Element | null => {
  //TODO: Leaving this for when we start the exposure work
  const exposure = null

  const history = useHistory()

  useEffect(() => {
    // Any events that come back from the Iframe will live here
    const eventHandler = (event: IGenericEvent) => {
      const data = event.data
      if (data.event === TAventusQuote.createOpportunity) {
        //TODO: This is for the first part of the flow when we have some pre-questions
      }

      if (data.event === TAventusQuote.createQuote) {
        //TODO: Quote Creation will go here
      }

      if (
        data.event === TAventusQuote.saveVariant ||
        data.event === TAventusQuote.completeVariant ||
        data.event === TAventusQuote.discardVariant
      ) {
        history?.push({
          pathname: `/opportunity/${opportunity?.opportunity.id}`
        })
      }
    }
    window.addEventListener("message", eventHandler)
    return () => window.removeEventListener("message", eventHandler)
  })

  // In parallel, we need to also get the Product for the given ID.
  // This is primarily so that we can get the Product Reference in order
  // to create an Opportunity. Note that this is going to change in future,
  // and the Opportunity endpoint will be modified to also accept
  // a product ID alongside or instead of the reference.

  const { data: product, isSuccess: productSuccessful } = useQuery(
    ["getProductById", props.productId],
    async () => await props.platform.product.getProductById(props.productId),
    {
      enabled: !!props.platform.token
    }
  )

  // Once we have the Reference (see `enabled` option defined below)
  // we can create the opportunity. Using the returned data we can
  // now render and embed Symphony quote flow.

  const { data: opportunity, isSuccess: opportunitySuccessful } = useQuery(
    ["createOpportunity", props.productId],
    async () =>
      await props.platform.opportunity.createOpportunity({
        openSession: true,
        answers: [],
        partnerID: "",
        name: "Portal",
        partnerReference: "",
        productReferenceID: product?.referenceID || "",
        introducer: null,
        policyholderID: null
      }),
    {
      enabled: !!props.platform.token && productSuccessful
    }
  )

  const section = props.section

  let url = `${props.symphony.baseUrl}/products/${product?.id}/quote/new-business/${opportunity?.questionSetReferenceID}?editSessionID=${opportunity?.sessionID}&token=${props.platform.token}&chrome=false`

  if (exposure != null) {
    url += `&exposure=${exposure}`
  }

  if (productSuccessful && opportunitySuccessful && opportunity?.sessionID) {
    return (
      <IFrameContainer
        id={props.productId}
        url={url}
        title={props.title}
        name={section}
      ></IFrameContainer>
    )
  } else {
    return null
  }
}

export const NewSymphonyQuoteV2 = (
  props: INewSymphonyQuoteV2Props
): JSX.Element => {
  const history = useHistory()
  const { isLoading, data } = useQuery(
    ["getProductConfiguration", props.productId],
    async () =>
      await props.platform.product.getProductConfigurationById(props.productId),
    {
      enabled: !!props.platform.token
    }
  )

  // These are the standard V2/V6 event listeners so they should work
  // here providing the simple quote is firing the events.
  useEffect(() => {
    const handler = (event: TNewQuoteMessage) => {
      const data = event.data

      if (data.event === TAventusQuote.renewalConfirm && data.quoteId) {
        history.push({
          pathname: `/quote/${data.quoteId}`,
          state: { action: "quote-renewal", quoteState: "PendingPayment" }
        })
      }

      if (data.event === TAventusQuote.bound && data.policyId) {
        history.push({
          pathname: `/policy/${data.policyId}`
        })
      }

      if (data.event === TAventusQuote.summary && data.quoteId) {
        history.push({
          pathname: `/quote/${data.quoteId}`
        })
      }

      if (
        data.event === TAventusQuote.confirm &&
        data.quoteId &&
        props.section.toLowerCase() !== "renew"
      ) {
        history.push({
          pathname: `/quote/${data.quoteId}`
        })
      }

      if (data.event === TAventusQuote.saved && data.quoteId) {
        history.push({
          pathname: `/quote/${data.quoteId}`
        })
      }

      if (data.event === "aventus.quote.questionSetPage") {
        window.scrollTo(0, 0)
      }
    }

    window.addEventListener("message", handler)
    return () => window.removeEventListener("message", handler)
  }, [history, props.section, props.productReference])

  if (isLoading) {
    return <LayoutLoading />
  }

  const [coreQuestionSet] = data?.coreQuestionSets || []

  const url =
    props.section === "edit"
      ? `${props.symphony.baseUrl}/console/${props.productId}/quote/Adjust/${coreQuestionSet.referenceID}?quoteId=${props.quoteid}&chrome=false&token=${props.platform.token}`
      : `${props.symphony.baseUrl}/console/${props.productId}/quote/NewBusiness/${coreQuestionSet.referenceID}?chrome=false&token=${props.platform.token}`

  return (
    <IFrameContainer
      id={`QuoteIframe_${props.productReference}`}
      url={url}
      title={props.title}
      name={props.section}
    ></IFrameContainer>
  )
}

export type INewSymphonyQuoteRoutingProps = RouteComponentProps<{
  section: string
  productId: string
  productReference?: string
}>

interface INewSymphoneQuoteV3Props {
  section: string
  productId: string
  symphony: IUrlPieces
  title: string
  platform: IPlatformClient
}

interface INewSymphonyQuoteV2Props {
  section: string
  productId: string
  productReference: string
  symphony: IUrlPieces
  title: string
  platform: IPlatformClient
  quoteid: string | undefined
}

export default withRouter(NewSymphonyQuote)
