import useOpus from "contexts/opus/hooks/use-opus"
import { useEffect, useState } from "react"
import { decodeJWT, IDecodeJWT } from "utils/pocketknife/decode-jwt"

export const checkUserPermission = (
  userPermission: string | undefined,
  portalPermissions: IDecodeJWT | undefined
): boolean => {
  if (userPermission && portalPermissions) {
    const [key, value] = userPermission.split(":")

    const userPermKey: string = key
    const userPermValue: string = value

    return portalPermissions[userPermKey] === userPermValue
  } else {
    return false
  }
}

export const useOpusPermissions = (
  key?: EOpusPermission | undefined,
  grantValue?: string | undefined
): { havePermission: boolean; permissions: IDecodeJWT | undefined } => {
  const [havePermission, setHavePermission] = useState<boolean>(false)
  const [permissions, setPermissions] = useState<IDecodeJWT | undefined>(
    undefined
  )
  const { opusToken } = useOpus()

  useEffect(() => {
    if (opusToken) {
      const decodedToken = decodeJWT(opusToken)
      if (grantValue && key) {
        decodedToken[key] === grantValue && setHavePermission(true)
      } else {
        setPermissions(decodedToken)
      }
    }
  }, [opusToken, key, grantValue])

  return { havePermission, permissions }
}

export enum EOpusPermission {
  ProcessRefferal = "processreferral",
  Quote = "quote",
  Insuranceorgid = "insuranceorgid",
  NameId = "nameid",
  OrganisationId = "orgid",
  OrganisationName = "orgname",
  UserManagment = "usermanagement",
  EmbargoesManagment = "embargoesmanagement",
  PolicySearch = "policysearch",
  SubjectOrgId = "subjectorgid",
  TokenType = "tokentypes",
  Policy = "policy",
  GroupType = "grouptype",
  EditCardDetails = "editcarddetails",
  ManualPayments = "manualpayments",
  aventusadmin = "aventusadmin"
}
