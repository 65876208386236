import { Breadcrumb, Layout, Steps } from "antd"
import { LayoutLoading, LayoutPageContent, LayoutPageHeader } from "components"
import {
  IActiveLineOfBusiness,
  useGetAppConfig
} from "contexts/configuration/hooks/use-get-app-config"
import React, { useReducer } from "react"
import { RouteComponentProps, withRouter } from "react-router"
import styled from "styled-components"
import ChooseProduct, { IActiveProduct } from "./sub-views/ChooseProduct"
import CreatePolicyHolder from "./sub-views/CreatePolicyholder"
import SelectBroker, { AssignedBroker } from "./sub-views/AssignBroker"
import { ICompany, ICompanyProduct, IPolicyholder } from "platform-client/types"
import usePlatform from "contexts/platform/use-platform"
import { useQuery } from "react-query"
import { ShoppingOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import ReviewCreateOpportunity from "./sub-views/ReviewCreateOpportunity/presenter"

const PaddedWrapper = styled.div`
  height: 100%;
  margin: 0 0 15px 0;
  padding: 20px;
  background: #ffffff;
`

enum OpportunityActions {
  UPDATE_PRODUCT,
  UPDATE_POLICYHOLDER,
  UPDATE_BROKER,
  NEXT_STEP,
  PREV_STEP,
  SELECT_STEP
}

const reducer = (
  state: ICreateOpportunityState,
  action: CreateOpportunityAction
): ICreateOpportunityState => {
  switch (action.type) {
    case OpportunityActions.SELECT_STEP:
      return {
        ...state,
        ...{
          step: action.payload
        }
      }
    case OpportunityActions.NEXT_STEP:
      return {
        ...state,
        ...{
          step: state.step + 1
        }
      }
    case OpportunityActions.PREV_STEP:
      return {
        ...state,
        ...{
          step: state.step > 0 ? state.step - 1 : 0
        }
      }
    case OpportunityActions.UPDATE_PRODUCT:
      return {
        ...state,
        ...{
          product: action.payload
        }
      }
    case OpportunityActions.UPDATE_BROKER:
      return {
        ...state,
        ...{
          broker: action.payload
        }
      }
    case OpportunityActions.UPDATE_POLICYHOLDER:
      return {
        ...state,
        ...{
          policyholder: action.payload
        }
      }
    default:
      return state
  }
}

const CreateOpportunity: React.FC<ICreateOpportunityProps> = ({
  activeLinesOfBusiness = [],
  ...props
}) => {
  const [{ step, product, policyholder, broker }, dispatch] = useReducer(
    reducer,
    {
      step: 0,
      product: undefined,
      policyholder: undefined,
      broker: undefined
    }
  )

  const platform = usePlatform()

  const { applicationConfig } = useGetAppConfig()

  const currentUser = useQuery(
    ["user/me"],
    () => platform.user.getCurrentUser(),
    {
      onSuccess: (response) => {
        if (response.company && response.company.companyType === "Broker") {
          dispatch({
            type: OpportunityActions.UPDATE_BROKER,
            payload: response.company
          })
        }
      }
    }
  )

  const currentUserCompanyID =
    currentUser.data?.company !== null
      ? currentUser.data?.company.id
      : undefined

  const currentUserCompanyType =
    currentUser.data?.company !== null
      ? currentUser.data?.company.companyType
      : "Insurer"

  const { data: companyData } = useQuery(
    ["companies", currentUserCompanyID],
    () => platform.company.getCompany(currentUserCompanyID ?? ""),
    {
      enabled:
        currentUser.data !== undefined &&
        currentUserCompanyID !== undefined &&
        currentUserCompanyType === "Broker"
    }
  )

  const getAvailableProducts = (enabledProducts: ICompanyProduct[]) => {
    return (activeLinesOfBusiness: IActiveLineOfBusiness[]) => {
      return enabledProducts?.reduce(
        (active: IActiveLineOfBusiness[], current: ICompanyProduct) => {
          const config = activeLinesOfBusiness.find(
            (item) => item.id === current.id
          )

          if (config === undefined) {
            return active
          }

          return [
            ...active,
            {
              ...config,
              ...current
            }
          ]
        },
        []
      )
    }
  }

  const linesOfBusiness = [
    /**
     * Merging this in here so we can mock this with storybook/jest.
     */
    ...activeLinesOfBusiness,
    ...(applicationConfig
      ? currentUserCompanyType !== "Broker"
        ? applicationConfig.activeLinesOfBusiness
        : getAvailableProducts(
            companyData && Array.isArray(companyData.enabledProducts)
              ? companyData.enabledProducts
              : []
          )(applicationConfig.activeLinesOfBusiness)
      : [])
  ]

  const activeProducts = linesOfBusiness
    .filter((item) => item.isActive)
    .map((item) => {
      return item.coverTypes.map((coverType) => ({
        ...coverType,
        ...item
      }))
    })
    .flat()
    .sort((a, b) => (a ? a.dashboardOrder : 0) - (b ? b.dashboardOrder : 0))

  const nextStep = () => dispatch({ type: OpportunityActions.NEXT_STEP })
  const prevStep = () => dispatch({ type: OpportunityActions.PREV_STEP })
  const skipToStep = (step: number) =>
    dispatch({ type: OpportunityActions.SELECT_STEP, payload: step })

  const subViews = [
    {
      title: "Assign to broker",
      component: (
        <SelectBroker
          broker={broker}
          onBack={() => prevStep()}
          onSelectBroker={(data) => {
            dispatch({
              type: OpportunityActions.UPDATE_BROKER,
              payload: data
            })
            nextStep()
          }}
        />
      ),
      enabled: true,
      visible: currentUser.data?.company
        ? currentUser.data?.company.companyType !== "Broker"
        : true
    },
    {
      title: "Choose product",
      component: (
        <ChooseProduct
          onChooseProduct={(product: IActiveProduct) => {
            dispatch({
              type: OpportunityActions.UPDATE_PRODUCT,
              payload: product
            })
            product.hasQuickQuote === true
              ? skipToStep(subViews.length - 1)
              : nextStep()
          }}
          activeProducts={activeProducts}
        />
      ),
      enabled: !(broker === undefined),
      visible: true
    },
    {
      title: "Policyholder details",
      component: (
        <CreatePolicyHolder
          policyholder={policyholder}
          onBack={() => prevStep()}
          onSavePolicyholder={(data) => {
            dispatch({
              type: OpportunityActions.UPDATE_POLICYHOLDER,
              payload: data
            })
            nextStep()
          }}
        />
      ),
      enabled: !(product === undefined) && !(broker === undefined),
      visible: true
    },
    {
      title: "Review and create",
      component: (
        <ReviewCreateOpportunity
          product={product as IActiveProduct}
          broker={broker as ICompany}
          policyholder={policyholder}
        />
      ),
      enabled:
        !(product === undefined) &&
        (product.hasQuickQuote === true || policyholder !== undefined) &&
        !(broker === undefined),
      visible: true
    }
  ].filter((item) => item.visible)

  if (currentUser.isLoading) {
    return <LayoutLoading />
  }

  return (
    <Layout>
      <LayoutPageHeader
        ghost={false}
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/opportunities">
                <ShoppingOutlined />
                &nbsp; Opportunities
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Create</Breadcrumb.Item>
          </Breadcrumb>
        }
        title={"Create Opportunity"}
      />

      <LayoutPageContent style={{ height: "100%" }} transparent={true}>
        <PaddedWrapper>
          <Steps
            current={step}
            style={{ padding: "15px" }}
            onChange={skipToStep}
          >
            {subViews.map((item, index) => (
              <Steps.Step
                key={index}
                title={item.title}
                disabled={!item.enabled}
              ></Steps.Step>
            ))}
          </Steps>
        </PaddedWrapper>
        <PaddedWrapper>
          {(subViews[step] && subViews[step].component) || "Page not found"}
        </PaddedWrapper>
      </LayoutPageContent>
    </Layout>
  )
}

export type ICreateOpportunityProps = RouteComponentProps & {
  activeLinesOfBusiness?: IActiveLineOfBusiness[]
}

interface ICreateOpportunityState {
  step: number
  product?: IActiveProduct
  policyholder?: IPolicyholder
  broker?: AssignedBroker
}

type CreateOpportunityAction =
  | {
      type: OpportunityActions.UPDATE_PRODUCT
      payload: IActiveProduct
    }
  | {
      type: OpportunityActions.UPDATE_POLICYHOLDER
      payload: IPolicyholder
    }
  | {
      type: OpportunityActions.UPDATE_BROKER
      payload: AssignedBroker
    }
  | {
      type: OpportunityActions.NEXT_STEP
    }
  | {
      type: OpportunityActions.PREV_STEP
    }
  | {
      type: OpportunityActions.SELECT_STEP
      payload: number
    }

export default withRouter(CreateOpportunity)
