import { Button } from "antd"
import usePlatform from "contexts/platform/use-platform"
import { useMutation, useQueryClient } from "react-query"

const RemoveEndorsementButton: React.FunctionComponent<IRemoveEndorsement> = (
  props
) => {
  const platform = usePlatform()
  const queryClient = useQueryClient()

  const removeCustomEndorsements = useMutation(() =>
    platform.quoteBuilder.removeCustomEndorsementForce(
      props.quoteId,
      props.endorsementId,
      props.componentId,
      "Section"
    )
  )
  return (
    <Button
      onClick={() =>
        removeCustomEndorsements.mutateAsync().then((response) => {
          if (response) {
            queryClient.invalidateQueries(["quote", props.quoteId])
          }
        })
      }
    >
      Remove
    </Button>
  )
}

export interface IRemoveEndorsement {
  endorsementId: string
  componentId: string
  quoteId: string
}

export default RemoveEndorsementButton
