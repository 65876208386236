import { useState } from "react"
import { IPolicyCancellationReason } from "contexts/opus/context"
import {
  Button,
  Descriptions,
  Form,
  Modal,
  Select,
  Spin,
  Row,
  Popconfirm,
  DatePicker,
  Col,
  Checkbox
} from "antd"
import styled from "styled-components"
import { Moment } from "moment"
import { IMoney } from "platform-client/types"
import { PaymentPlanType } from "platform-client/types"
import { MoneyDisplay } from "components/display/MoneyDisplay"

const StyledRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const StyledCol = styled(Col)`
  & > * {
    margin-bottom: 8px;
    :last-child {
      margin-bottom: 0;
    }
  }
`

const Component: React.FC<IPolicyCancellation> = ({
  policyRef,
  refundAmount,
  onCancel,
  onChangeReason,
  cancellationReason,
  cancellationReasons,
  isButtonLoading,
  isLayoutLoading,
  setEffectiveCancellationDate,
  effectiveCancellationDate,
  onProRataRefundChange,
  proRataRefund,
  fetchCancellationInfo,
  coverRange,
  minCancellationDate,
  paymentPlanType
}) => {
  const [visible, setVisible] = useState<boolean>(false)

  const handleCancel = () => {
    setVisible(false)
  }

  return (
    <>
      <Button
        loading={isButtonLoading}
        onClick={() => {
          fetchCancellationInfo(
            cancellationReason,
            effectiveCancellationDate,
            proRataRefund
          )
          setVisible(true)
        }}
      >
        Cancel Policy
      </Button>
      <Modal
        visible={visible}
        onCancel={handleCancel}
        footer={
          <Row justify="space-between">
            <Button onClick={handleCancel}>Exit</Button>
            <Popconfirm
              title="Are you sure you wish to cancel your Policy"
              okButtonProps={{
                htmlType: "submit",
                form: "policyCancellationForm"
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                form="policyCancellationForm"
                disabled={isLayoutLoading}
              >
                Cancel Policy
              </Button>
            </Popconfirm>
          </Row>
        }
      >
        <Spin spinning={isLayoutLoading}>
          <Form
            layout="vertical"
            id="policyCancellationForm"
            onFinish={onCancel}
          >
            <Form.Item
              name="cancellation_reason"
              label="Cancellation reason:"
              rules={[
                {
                  required: true,
                  message: "Please select cancellation reason"
                }
              ]}
            >
              <Select
                placeholder="Select cancellation reason"
                value={cancellationReason?.referenceID}
                onChange={(referenceID) => {
                  const newReason = cancellationReasons?.find(
                    (reason) => reason.referenceID === referenceID
                  )
                  onChangeReason(newReason as IPolicyCancellationReason)
                }}
                options={cancellationReasons.map((reason) => ({
                  label: reason.text,
                  value: reason.referenceID
                }))}
              />
            </Form.Item>
            <Descriptions
              size="small"
              column={1}
              style={{ margin: "20px 0 0" }}
            >
              <Descriptions.Item label="Policy Reference">
                {policyRef}
              </Descriptions.Item>
              <Descriptions.Item label="Cover period start">
                {coverRange ? coverRange[0].format("DD/MM/YYYY") : "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Cover period end">
                {coverRange ? coverRange[1].format("DD/MM/YYYY") : "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Refund Amount">
                {refundAmount === undefined ? (
                  "-"
                ) : (
                  <MoneyDisplay value={refundAmount} />
                )}
              </Descriptions.Item>
            </Descriptions>
            <StyledCol>
              <StyledRow>
                Effective Cancellation Date:
                <DatePicker
                  disabledDate={(current) => {
                    if (minCancellationDate && coverRange) {
                      return !(
                        minCancellationDate.isSameOrBefore(current) &&
                        coverRange[1].isSameOrAfter(current)
                      )
                    }

                    return true
                  }}
                  id="effectiveCancellationDate"
                  format="DD/MM/YYYY"
                  allowClear={false}
                  value={effectiveCancellationDate}
                  onChange={(date) =>
                    setEffectiveCancellationDate(date as Moment)
                  }
                />
              </StyledRow>
              {paymentPlanType === "AnnualMonthlySubscription" && (
                <StyledRow>
                  Pro Rata Customer Refund:
                  <Checkbox
                    id="prorataOverride"
                    onChange={(event) =>
                      onProRataRefundChange(event.target.checked as boolean)
                    }
                    checked={proRataRefund}
                  />
                </StyledRow>
              )}
            </StyledCol>
          </Form>
        </Spin>
      </Modal>
    </>
  )
}

export default Component

interface IPolicyCancellation {
  policyRef: string
  refundAmount?: IMoney
  onCancel: () => void
  onChangeReason: (reason: IPolicyCancellationReason) => void
  cancellationReason: IPolicyCancellationReason | null | undefined
  cancellationReasons: IPolicyCancellationReason[]
  isButtonLoading: boolean
  isLayoutLoading: boolean
  setEffectiveCancellationDate: (date: Moment) => void
  effectiveCancellationDate: Moment
  onProRataRefundChange: (prorata: boolean) => void
  proRataRefund: boolean
  fetchCancellationInfo: (
    reason: IPolicyCancellationReason | null | undefined,
    date: Moment | null | undefined,
    proRataRefund: boolean | null | undefined
  ) => void
  coverRange?: Moment[]
  minCancellationDate?: Moment
  paymentPlanType?: PaymentPlanType
}
