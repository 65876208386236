import React, { useState } from "react"
import { RouteComponentProps, StaticContext } from "react-router"
import { LayoutPageContent } from "../../../../../components/LayoutPageContent"
import { useHistory, Route, Switch, Redirect, Link } from "react-router-dom"
import { Layout, Tabs, Alert, Badge, Space, notification, Button } from "antd"
import { NewNoteModal } from "components"
import {
  IEvent,
  INote,
  IPaymentPlan,
  IProduct,
  IRenewalReview,
  PolicyDetailType,
  IReferral,
  IPolicyholder,
  IPolicyDetailSet,
  CoverPeriodType,
  EndorsementType,
  IMoney
} from "platform-client/types"
import { IConfigurationContext } from "contexts/configuration/context"
import { TErrorState } from "models/errors"
import { QuoteHeader } from "../components/quote-header"
import { QuoteAside } from "../components/quote-aside"
import { Summary } from "../sub-views/summary"
import Review from "../sub-views/review"
import { useDefaultPage } from "utils/hooks/use-default-page"
import {
  useOpusPermissions,
  EOpusPermission
} from "contexts/authorization/hooks/use-opus-permissions"
// These are referrencing the old models because there aren't
// equivalents in the new v3 platform, so we can still support
// the old way directly.
import { IProductItemOpus, IProductOpus } from "models/quotes/productOpus"
import { IQuotePaymentLink as IQuotePaymentLinkModel } from "contexts/opus/context"
import { useNotification } from "services/hooks/use-notification"
import { IEndorsementOpus } from "models/quotes/endorsementOpus"
import { ICompanyInRole } from "platform-client/types/company"

import usePlatform from "contexts/platform/use-platform"
import { useMutation, UseMutationResult, useQuery } from "react-query"
import { ReferQuoteModal } from "../components/refer-quote-modal"
import { useReferQuoteModal } from "../components/refer-quote-modal/useReferQuoteModal"
import QuoteEndorsements from "../sub-views/endorsements"
import styled from "styled-components"
import {
  IOriginalPolicyMeta,
  IQuote,
  IQuoteAction,
  IQuoteBundle,
  IRisk,
  IWithdrawReason
} from "platform-client/types/quote"
import {
  IConfirmReferralQuoteProps,
  IDeclineReferralQuoteProps
} from "platform-client/client/controllers/quote"
import useOpus from "contexts/opus/hooks/use-opus"
import { Notes } from "../../Quote/sub-views/Notes"
import { AxiosError } from "axios"
import { handleValidationErrors } from "utils/pocketknife/handle-validation-errors"
import { ManualReferToggle } from "components/ManualReferToggle"

const AlertWithBorders = styled(Alert)`
  border-top: 1px solid #ffe58f;
  border-bottom: 1px solid #ffe58f;
`

export const QuickQuoteView: React.FC<IQuickQuoteViewProps> = (props) => {
  const history = useHistory()
  const platform = usePlatform()
  const referQuoteModal = useReferQuoteModal()

  const { havePermission: canUseNewQuote } = useOpusPermissions(
    EOpusPermission.Quote,
    "create"
  )

  const { havePermission: canProcessReferral } = useOpusPermissions(
    EOpusPermission.ProcessRefferal,
    "maintain"
  )

  const { quoteClient } = useOpus()

  const [noteContent, setNoteContent] = useState("")
  const [isModalVisible, setModalVisibility] = useState(false)
  const [isInternalNote, setInternalNote] = useState(false)

  useNotification(props.location.state?.action, props.history, "quote")

  useDefaultPage(props.error?.errorNumber, ["404", "400"], {
    status: "404",
    title: `Sorry, we couldn't find a quote that matched those details. `,
    buttonText: "Back to Quotes",
    redirectTo: "/quotes"
  })

  // Since this portal was initially developed with Westcor in mind,
  // we only show the annual payment plan. This will change in the future.
  const [annual] = props.quotePaymentPlans?.paymentPlans || []

  // TODO: This is really fugly a better way of getting these totals needs to be thought out
  const addonNetTotals = props.quotePaymentPlans?.paymentPlans
    ?.filter((x) => x.planType === "FixedTermSinglePayment")[0]
    ?.addonNetTotals?.reduce((partialSum, a) => partialSum + a.value, 0)

  const reviewPrice: IMoney = annual?.price?.total?.net
    ? {
        value:
          (annual.price.total.net?.value -
            (addonNetTotals ? addonNetTotals : 0)) /
          100,
        currencyCode: annual.price.total.net?.currencyCode,
        unit: "Minor"
      }
    : {
        value: 0,
        currencyCode: "GBP",
        unit: "Minor"
      }

  const shouldShowReview =
    (props.configuration.flags.platformVersion === "v3" &&
      ["Referred"].includes(props.quote?.quoteState)) ||
    (canUseNewQuote &&
      props.quote?.quoteType === "Renewal" &&
      props.configuration.flags.showReviewTab &&
      props.quoteRenewalReview?.review?.requiresReview)

  const { data: currentUser } = useQuery(
    ["currentUser"],
    async () => platform.user.getCurrentUser(),
    {
      enabled: props.configuration.flags.platformVersion === "v3"
    }
  )

  const quoteNotes = useQuery(
    ["quoteNotes", props.match.params.id],
    () => platform.quote.getNotes(props.match.params.id || ""),
    {
      onError: () =>
        notification.error({
          message: "Couldn't load the notes, please try again"
        })
    }
  )

  const newNote = useMutation(
    ({
      content = "",
      isInternalOnly = false
    }: {
      content: string
      isInternalOnly: boolean
    }) =>
      platform.quote.createNote(props.match.params.id || "", {
        content,
        isInternalOnly
      }),
    {
      onSuccess: () => {
        setModalVisibility(false)
        setInternalNote(false)
        setNoteContent("")
        quoteNotes.refetch()
      },
      onError: (error: AxiosError) => handleValidationErrors(error)
    }
  )

  const referQuote = useMutation(
    async (comment: string) => {
      if (!comment)
        return notification.error({ message: "Please enter the message" })
      if (props.configuration.flags.platformVersion === "v3") {
        return platform.quote.referQuote(props.quote.id, comment)
      } else {
        return quoteClient.referQuote(props.quote.id, comment)
      }
    },
    {
      onSuccess: () => {
        referQuoteModal.setContent("")
        referQuoteModal.setVisible(false)
        if (typeof props?.onQuoteChange === "function") props?.onQuoteChange()
      },
      onError: () => {
        notification.error({
          message: "Unable to refer the quote, please refresh and try again."
        })
      }
    }
  )

  const revokeManualReferral = useMutation((quoteID: string) =>
    platform.quote.revokeManualReferral(quoteID)
  )

  const handleRevokeManualReferral = () => {
    revokeManualReferral
      .mutateAsync(props.quote.id)
      .then(() => {
        props.onQuoteChange?.()
      })
      .catch(() => {
        notification.error({
          message:
            "Unable to revoke referral, please try again or contact support if this issue persists."
        })
      })
  }

  const convertToQuote = (): void => {
    history.push(`/quick-quote/${props.quote.id}/convert`)
  }

  return (
    <>
      <Layout>
        <NewNoteModal
          visible={isModalVisible}
          content={noteContent}
          setContent={setNoteContent}
          onCancel={() => {
            setModalVisibility(false)
            setInternalNote(false)
            setNoteContent("")
          }}
          onSubmit={() => {
            if (!noteContent)
              return notification.error({
                message: "Please add some text to the note"
              })
            return newNote.mutate({
              content: noteContent,
              isInternalOnly: isInternalNote
            })
          }}
          isLoading={newNote.isLoading}
          onInternalCheck={() => setInternalNote((state) => !state)}
          isInternal={isInternalNote}
        />
        {shouldShowReview && (
          <AlertWithBorders
            message={
              <>
                This quote requires review before you can perform any other
                action.
                {canProcessReferral && (
                  <Link
                    to={`/quote/${props.quote.id}/review`}
                    style={{ marginLeft: "0.25em" }}
                  >
                    Review Quote
                  </Link>
                )}
              </>
            }
            type="warning"
            banner
            closable
            closeText="Dismiss"
          />
        )}
        <QuoteHeader
          configuration={props.configuration}
          isWorking={false}
          title={props.quote.quoteReferenceID}
          companyName={currentUser?.company?.name}
          disableBind={currentUser?.company?.brokerCanBindBusiness === false}
          actions={
            <>
              <Button onClick={() => setModalVisibility(true)}>New Note</Button>
              <ManualReferToggle
                onManualReferral={() => referQuoteModal.setVisible(true)}
                onRevokeManualReferral={() => handleRevokeManualReferral()}
                quoteActions={props.quoteActions}
                isLoading={
                  revokeManualReferral.isLoading || referQuote.isLoading
                }
              />
              <Button onClick={convertToQuote} type="primary">
                Convert to Quote
              </Button>
            </>
          }
          tabs={
            <Tabs
              defaultActiveKey={props.match.params.subpage}
              activeKey={props.match.params.subpage}
              onTabClick={(activeKey) => {
                switch (activeKey) {
                  case "summary":
                    history.push(`/quick-quote/${props.quote.id}/summary`)
                    break
                  case "review":
                    history.push(`/quick-quote/${props.quote.id}/review`)
                    break
                  case "endorsements":
                    history.push(`/quick-quote/${props.quote.id}/endorsements`)
                    break
                  case "notes":
                    history.push(`/quick-quote/${props.quote.id}/notes`)
                    break
                }
              }}
            >
              <Tabs.TabPane tab="Summary" key="summary" />
              <Tabs.TabPane
                tab={
                  <Space align="center">
                    Endorsements
                    <Badge
                      count={
                        (props.quoteEndorsements &&
                          props.quoteEndorsements.length) ??
                        0
                      }
                      showZero
                      style={{
                        marginTop: "-5px",
                        backgroundColor: "var(--ant-primary-color)"
                      }}
                      dot={false}
                    />
                  </Space>
                }
                key="endorsements"
              />
              <Tabs.TabPane
                tab={
                  <Space align="center">
                    Review
                    <Badge
                      count={props.quoteReferrals?.length}
                      style={{
                        marginTop: "-5px",
                        backgroundColor:
                          props.quote.quoteState === "Referred"
                            ? "var(--ant-warning-color)"
                            : "var(--ant-primary-color)"
                      }}
                      dot={false}
                    />
                  </Space>
                }
                key="review"
              />
              <Tabs.TabPane tab="Notes" key="notes" />
            </Tabs>
          }
          quoteState={props.quote.quoteState}
        />
        <Switch>
          <Route
            path={`${props.match.path}/summary`}
            render={() => (
              <LayoutPageContent
                error={props.error}
                transparent
                siderContent={
                  <QuoteAside
                    configuration={props.configuration}
                    notes={props.quoteNotes?.notes}
                    isGettingNotes={props.quoteNotes?.isGettingNotes || false}
                    events={props.quoteEvents?.events}
                    isGettingEvents={
                      props.quoteEvents?.isGettingEvents || false
                    }
                  />
                }
              >
                <Summary
                  configuration={props.configuration}
                  quote={props.quote}
                  risk={props.risk}
                  quoteProduct={props.quoteProduct}
                  quotePaymentPlans={props.quotePaymentPlans}
                  quoteProductReferenceId={props.quote.productReferenceID}
                  quoteProductCoverReference={props.quote.productCoverReference}
                  quotePolicyHolder={props.quotePolicyHolder}
                  policyDetailSet={props.policyDetailSet}
                  quoteLifecycles={props.quoteLifecycles}
                  companies={props.quoteCompanies}
                />
              </LayoutPageContent>
            )}
          />
          <Route
            path={`${props.match.path}/endorsements`}
            render={() => (
              <QuoteEndorsements
                quoteId={props.quote.id}
                quoteState={props.quote.quoteState}
                quoteStatus={props.quote.quoteStatus}
                endorsements={props.quoteEndorsements}
                sections={props.policyDetailSet?.latest.sections || undefined}
                platformVersion={props.configuration.flags.platformVersion}
              />
            )}
          />
          <Route
            path={`${props.match.path}/notes`}
            render={() => (
              <LayoutPageContent error={props.error} transparent>
                <Notes
                  quoteId={props.match.params.id}
                  isLoading={quoteNotes.isFetching || false}
                  data={quoteNotes.data}
                  isEmpty={!(quoteNotes.data || []).length}
                />
              </LayoutPageContent>
            )}
          />
          <Route
            path={`${props.match.path}/review`}
            render={() => (
              <LayoutPageContent error={props.error} transparent>
                <Review
                  configuration={props.configuration}
                  quote={props.quote}
                  isQuoteLoading={props.isFetchingQuote}
                  referrals={props.quoteReferrals}
                  onUpdateReferral={props.onUpdateReferral}
                  confirmReferralQuote={props.confirmReferralQuote}
                  declineReferralQuote={props.declineReferralQuote}
                  quoteRenewalReview={props.quoteRenewalReview}
                  onReviewQuote={props.quoteLifecycles?.onReviewQuote}
                  currentPrice={reviewPrice}
                  isHybrid={false}
                  onQuoteChange={props.onQuoteChange}
                />
              </LayoutPageContent>
            )}
          />
          {!props.match.params.subpage && (
            <Redirect to={`${props.match.path}/summary`} />
          )}
        </Switch>
      </Layout>
      <ReferQuoteModal
        visible={referQuoteModal.visible}
        content={referQuoteModal.content}
        setContent={referQuoteModal.setContent}
        isLoading={referQuote.isLoading}
        onSubmit={() => referQuote.mutate(referQuoteModal.content)}
        onCancel={() => {
          referQuoteModal.setContent("")
          referQuoteModal.setVisible(false)
        }}
      />
    </>
  )
}

export type IQuickQuoteRouteProps = RouteComponentProps<
  {
    id: string
    subpage: string
  },
  StaticContext,
  {
    action?: string
    quoteState?: string
  }
>

export interface IQuickQuoteViewProps extends IQuickQuoteRouteProps {
  configuration: IConfigurationContext
  isWorking: boolean
  error?: TErrorState
  quote: IQuote
  risk?: IRisk
  quoteEndorsements?: EndorsementType[]
  quoteNotes?: IQuoteNotes
  quoteEvents?: IQuoteEvents
  quoteActions?: IQuoteAction[]
  quoteProduct?: IQuoteProduct
  quotePolicyHolder?: IPolicyholder
  quotePaymentPlans?: IQuotePaymentPlans
  quotePaymentLink?: IQuotePaymentLink
  quoteWithdrawReasons?: IQuoteWithdrawReasons
  quoteRenewalReview?: IQuoteRenewalReview
  quoteLifecycles?: IQuoteLifecycles
  quoteCompanies?: ICompanyInRole[]
  originalPolicyMeta?: IOriginalPolicyMeta
  ownerId?: string
  isFetchingQuote?: boolean
  onUpdateReferral?: OnUpdateReferral
  confirmReferralQuote?: UseMutationResult<
    IQuoteBundle,
    unknown,
    IConfirmReferralQuoteProps
  >
  declineReferralQuote?: UseMutationResult<
    IQuoteBundle,
    unknown,
    IDeclineReferralQuoteProps
  >
  onQuoteChange?: () => void
  quoteReferrals?: IReferral[]
  policyDetail?: PolicyDetailType
  policyDetailSet?: IPolicyDetailSet
  coverPeriods?: CoverPeriodType[]
  productReferenceID?: string
  productCoverReference?: string
}

export interface IQuoteProduct {
  product?: IProduct | IProductOpus
  isGettingQuoteProduct: boolean
  productItems_v2?: IProductItemOpus[]
}

export interface IQuoteEvents {
  events?: IEvent[]
  isGettingEvents: boolean
}

export interface IQuoteNotes {
  notes?: INote[]
  isGettingNotes: boolean
}

export interface IQuotePaymentPlans {
  paymentPlans?: IPaymentPlan[]
  isGettingPaymentPlans: boolean
  isPaymentPlansFinished: boolean
}

export interface IQuotePaymentLink {
  paymentLink?: IQuotePaymentLinkModel
  isGettingPaymentLink: boolean
  error?: boolean
}

export interface IQuoteWithdrawReasons {
  withdrawReasons?: IWithdrawReason[]
  isGettingWithdrawReasons: boolean
}

export interface IQuoteRenewalReview {
  review?: IRenewalReview
  isGettingRenewalReview: boolean
}

export interface IQuoteLifecycles {
  onConfirmQuote?: OnConfirmQuote
  onWithdrawQuote?: OnWithdrawQuote
  onAddNote?: OnAddNote
  onReferQuote?: OnReferQuote
  onInvalidatePaymentLink?: OnInvalidatePaymentLink
  onEditEndorsements?: OnEditEndorsements
  onReviewQuote?: OnReviewQuote
}

export type OnConfirmQuote = (
  vars: {
    quoteId: string
    comment?: string
  },
  onSuccess?: (quoteId?: string) => void,
  onFailure?: () => void
) => Promise<void>

export type OnWithdrawQuote = (
  vars: {
    quoteId: string
    comment?: string
    withdrawalReasonReferenceID?: string
  },
  onSuccess?: (quoteId?: string) => void,
  onFailure?: () => void,
  options?: {
    shouldRefetchQuote?: boolean
  }
) => Promise<void>

export type OnAddNote = (
  vars: {
    quoteId: string
    content: string
  },
  onSuccess?: (noteId?: string) => void,
  onFailure?: () => void
) => Promise<void>

export type OnReferQuote = (
  vars: { quoteId: string },
  onSuccess?: (quoteId?: string) => void,
  onFailure?: () => void
) => Promise<void>

export type OnInvalidatePaymentLink = (
  vars: { quoteId: string },
  onSuccess?: () => void,
  onFailure?: () => void
) => Promise<void>

export type OnEditEndorsements = (
  vars: {
    quoteId: string
    addedEndorsements: IEndorsementOpus[]
    comment: string
    deletedEndorsements: IEndorsementOpus[]
  },
  onSuccess?: () => void,
  onFailure?: () => void
) => Promise<void>

export type OnReviewQuote = (
  vars: {
    quoteId: string
    review: boolean
  },
  onSuccess?: () => void,
  onFailure?: () => void
) => Promise<void>

export type OnUpdateReferral = (
  vars: {
    quoteID: string
    referral: IReferral
  },
  onSuccess?: (referral: IReferral) => void,
  onFailure?: () => void
) => Promise<void>

export type OnOverridePremium = (
  vars: { quoteId: string; premium: IMoney },
  onSuccess?: () => void,
  onFailure?: () => void
) => Promise<void>
