import { Modal } from "antd"
import { IOriginalPolicyMeta, QuoteType } from "platform-client/types/quote"
import { debounce } from "debounce"
import { useHistory } from "react-router"
import { OnWithdrawQuote } from "../../view"

export const useEditQuote = (
  quoteId: string,
  quoteType: QuoteType,
  productReference: string | undefined,
  productCoverReference: string | undefined,
  ishybrid: boolean | undefined,
  productId: number,
  ownerId?: string,
  originalPolicyMeta?: IOriginalPolicyMeta,
  withdrawalReasonReferenceID?: string,
  onWithdrawQuote?: OnWithdrawQuote
): IUseEditQuote => {
  const history = useHistory()

  return {
    showEditQuoteModal: () =>
      Modal.confirm({
        title: "Are you sure you want to edit this quote?",
        content:
          quoteType === "Renewal" || ishybrid
            ? "This will edit the existing quote"
            : "This will create new quote reference and withdraw the existing quote",
        centered: true,
        okText: "Edit",
        onOk: debounce(async () => {
          // If we have a renewal quote then lets start the renewal edit flow
          if (
            quoteType === "Renewal" &&
            originalPolicyMeta !== undefined &&
            ownerId // could this be the UserId?
          ) {
            history.push(
              `/opus/renew/${productReference}/${productCoverReference}/${quoteId}/${originalPolicyMeta.policyID}/${ownerId}`
            )
          } else {
            // If hybrid go straight to editing the quote, don't withdraw else
            // carry on as normal and withdraw the quote
            // before reissueing a new quote
            if (ishybrid) {
              history.push(
                `/symphony/edit/${productId}/${productReference}/${quoteId}`
              )
            } else if (quoteId && onWithdrawQuote) {
              await onWithdrawQuote(
                {
                  quoteId,
                  comment: "Quote was edited",
                  withdrawalReasonReferenceID
                },
                (_quoteId) => {
                  if (_quoteId) {
                    history.push(
                      `/opus/edit/${productReference}/${productCoverReference}/${quoteId}`
                    )
                  }
                }
              )
            }
          }
        }, 200)
      })
  }
}

export interface IUseEditQuote {
  showEditQuoteModal: () => void
}
