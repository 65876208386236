import { CompanyType, Nullable } from "."

export type UserStatus = "Disabled" | "Active" | "Locked" | "Stub" | "Deleted"

export enum EUserGroups {
  Insurer = "Insurer",
  Distributor = "Distributor",
  InsurerAdministrator = "InsurerAdministrator"
}

export interface IUserCompany {
  id: string
  referenceID?: string
  name: Nullable<string>
  companyType: CompanyType
  brokerCanBindBusiness: boolean
  professionalIndemnityInsuranceExpired: boolean
}

export interface IUserAccountInfo {
  email: Nullable<string>
  externalID: Nullable<string>
  groupID: Nullable<string>
  groupType?: EUserGroups
  id: string
  username: Nullable<string>
  company: Nullable<IUserCompany>
}

export interface IInviteUserRequestBody {
  companyName: string
  companyId?: string
  externalID: Nullable<string>
  firstName: string
  lastName: string
  userClaims: number[]
  userGroups: string[]
  username: string
}

export interface IOpusUser {
  adminFlags: number
  authID: Nullable<string>
  companyID: Nullable<string>
  companyName: Nullable<string>
  emailVerified: boolean
  externalID: Nullable<string>
  firstName: Nullable<string>
  fullName: Nullable<string>
  groups: unknown
  id: string
  lastLogin: Nullable<string>
  lastName: Nullable<string>
  organizationID: number
  status: UserStatus
  username: Nullable<string>
}

export interface IEditEmailRequestBody {
  email: string
  policyId: string
}

export interface IEditEmailParams {
  ownerId: string
  policyId: string
  emailValue: string
}

export interface IChangeEmailExistingAccountCheckResult {
  hasExistingAccount: boolean
  referenceIDs: string[]
}

export interface IBlacklistPhoneNumberParams {
  phoneNumber: string
}
