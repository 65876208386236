import { Button } from "antd"
import { IQuoteAction } from "platform-client/types/quote"

type ManualReferToggleProps = {
  quoteActions?: IQuoteAction[]
  onManualReferral: () => void
  onRevokeManualReferral: () => void
  isLoading?: boolean
}

export const ManualReferToggle: React.FC<ManualReferToggleProps> = ({
  quoteActions = [],
  onManualReferral,
  onRevokeManualReferral,
  isLoading = false
}) => {
  const canRevokeReferral = quoteActions.some(
    (item) => item.referenceID === "avt_wfs_revokereferral" && item.allowed
  )

  if (canRevokeReferral) {
    return (
      <Button onClick={onRevokeManualReferral} loading={isLoading}>
        Revoke manual review
      </Button>
    )
  }

  return (
    <Button onClick={onManualReferral} loading={isLoading}>
      Request manual review
    </Button>
  )
}
