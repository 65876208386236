import React from "react"
import { Card } from "antd"
import { InboxOutlined } from "@ant-design/icons"
import styled from "styled-components"

const StyledIconWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px 0;
`

const StyledInboxOutlined = styled(InboxOutlined)`
  font-size: 60px;
  color: #efefef;
`

const StyledLabel = styled.label`
  color: #c7c7c7;
`

export const LayoutCardEmpty: React.FunctionComponent<ILayoutCardEmpty> = (
  props
) => (
  <Card
    title={props.title}
    style={props.style}
    bordered={props.bordered}
    className={props.className}
  >
    <StyledIconWrap>
      <StyledInboxOutlined />
      <StyledLabel>{props.emptyText || "No data"}</StyledLabel>
    </StyledIconWrap>
  </Card>
)

export interface ILayoutCardEmpty {
  title?: string | React.ReactNode
  style?: React.CSSProperties
  bordered?: boolean
  emptyText?: string
  className?: string
}
