import React from "react"
import { ShoppingOutlined } from "@ant-design/icons"
import {
  VCreateOpportunity,
  VEditVariant,
  VOpportunitiesList,
  VRiskChanges,
  VSingleOpportunity
} from "./"

export const OPPORTUNITIES_ROUTES = [
  {
    path: "/opportunity/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/:subpage?",
    component: VSingleOpportunity
  },
  {
    path: "/opportunity/create",
    component: VCreateOpportunity
  },
  {
    path: "/opportunities/:opportuityId([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/variants/:variantId([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/edit",
    component: VEditVariant
  },
  {
    path: "/variant/riskchanges/:opportunityId([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/:variantId([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/:opportunityReference",
    component: VRiskChanges
  },
  {
    path: "/opportunities",
    component: VOpportunitiesList
  }
]

export const OPPORTUNITIES_NAVIGATION = [
  {
    title: "Opportunities",
    path: "/opportunities",
    childPaths: ["/opportunity"],
    submenu: false,
    version: "v3",
    icon: <ShoppingOutlined />,
    iconActive: <ShoppingOutlined />
  }
]
