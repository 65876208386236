import { Alert, Card, Spin } from "antd"
import CellContentTitle from "components/Console/CellContent/Title"
import Row from "components/Console/Row"
import { IOracleResult } from "platform-client/types/oracle"
import styled from "styled-components"
import { OracleDisplayRow } from "./Row"

const CentreRow = styled.div`
  padding: 20px;
  text-align: center;
`

export const OracleResultDisplay: React.FunctionComponent<IOracleResultDisplay> =
  (props) => (
    <>
      {props.isLoading && (
        <CentreRow>
          <Spin spinning />
        </CentreRow>
      )}
      {!props.results?.length && !props.isLoading && (
        <Alert
          type="info"
          message="No data oracle results found for this quote"
        />
      )}
      {!!props.results?.length && !props.isLoading && (
        <Card title="Data Oracle Results - Data gathered from external enrichment services">
          <Row
            type="subHeader"
            reference={<CellContentTitle>{"Oracle"}</CellContentTitle>}
            values={[
              <>
                <CellContentTitle>{"Request Time"}</CellContentTitle>
                <CellContentTitle>{"Exposure Id"}</CellContentTitle>
              </>
            ]}
          />
          {props.results?.map((result) => {
            return <OracleDisplayRow result={result} />
          })}
        </Card>
      )}
    </>
  )

export interface IOracleResultDisplay {
  isLoading: boolean
  results: IOracleResult[] | undefined
}
