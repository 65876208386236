import { SelectValue } from "antd/lib/select"
import { ITax } from "models/common/tax"
import { IPolicyDetail, MoneyUnit } from "platform-client/types"

export type Nullable<T> = T | null

export interface IPolicyDetailHeader {
  dateCreated: string
  id: string
  history: IPolicyDetailHistory
}

export interface IPolicyDetailHistory {
  createdByID: string
  dateCreated: string
  id: string
  policyDetail: IPolicyDetail
  policyDetailHeaderID: string
  risk: IRisk
  sourcePolicyDetailHistoryID: string
  version: number
}

export interface IPolicyDetailEditSession {
  createdByID: string
  dateCreated: string
  dateUpdated: string
  ownerID: string
  id: string
  policyDetail: IPolicyDetail
  policyDetailHeaderID: string
  risk: IRisk
  sourcePolicyDetailHistoryID: string
  state: EditState
  version: number
}

export interface IPriceHeader {
  immediateAmount: IPriceSectionHeader
  planType: PaymentPlanType
  pricingPlanReferenceID: string
  recurringAmount: IPriceSectionHeader
  recurringInitial: IPriceSectionHeader
  total: IPriceSectionHeader
}

export interface IPriceSectionHeader {
  accountingDiscount: IMoney
  commission: IMoney
  customerDiscount: IMoney
  gross: IMoney
  net: IMoney
  netDiscount: IMoney
  netPremiumInsurer: IMoney
  retailCommission: IMoney
  retailGross: IMoney
  retailNet: IMoney
  retailTax: IFinanceTaxTaxSet
  rows: IPriceRow[]
  tax: IFinanceTaxTaxSet
}

export interface IPriceRow {
  accountingDiscount: IMoney
  commission: IMoney
  customerDiscount: IMoney
  description: Nullable<string>
  gross: IMoney
  net: IMoney
  netDiscount: IMoney
  netPremiumInsurer: IMoney
  order: number
  policySectionID: Nullable<string>
  retailCommission: IMoney
  retailGross: IMoney
  retailNet: IMoney
  retailTax: IFinanceTaxTaxSet
  subRows: IPriceRow[]
  tax: IFinanceTaxTaxSet
  type: PriceRowType
}

export type PriceRowType =
  | "NotSet"
  | "InsuranceProduct"
  | "Section"
  | "Fee"
  | "Interest"
  | "PaymentAggregate"
  | "Other"
  | "Subsection"

export type EditState = "Active" | "Completed" | "Discarded"

export interface IRisk {
  core: Nullable<IRiskCore>
  exposures: IExposure[] // Nullable
}

/**
 * TODO: Check whether risk core typed correctly
 */
export interface IRiskCore {
  coverStartDate: Nullable<string>
  coverEndDate: Nullable<string>
  productID: number
  productReferenceID: string
  excesses: {
    voluntaryExcess: Nullable<IMoney>
    compulsoryExcess: Nullable<IMoney>
    totalExcess: Nullable<IMoney>
    totalExcessSelection: Nullable<IMoney>
  }
  proposer: {
    personName: {
      firstName: Nullable<string>
      lastName: Nullable<string>
      title: Nullable<string>
    }
    emailAddress: Nullable<string>
  }
  businessDescription: Nullable<string>
}

/**
 * TODO: Need to type exposures correctly
 */
export interface IExposure {
  id: string
  value: Nullable<never>
}

export interface IMoney {
  currencyCode: string
  value: number
  unit?: MoneyUnit
}

export interface IMoneyHighAccuracy {
  currencyCode: Nullable<string>
  value: number
  unit?: MoneyUnit
}

export interface IDeclineReason {
  coverageID: string
  referenceID: string
  sectionID: string
  subSectionID: string
  text: string
  title: string
}

export interface INewEndorsement {
  referenceID: string
  schemeReferenceID: string
  sectionReferenceID: string
  providerReference: string
  title: string
  text: string
  validFromDateTime: string
  validToDateTime: string
}

export interface INewEndorsementResponse {
  id: string
  referenceID: string
  version: number
  versionReferenceID: string
  sectionReferenceID: string
  schemeReferenceID: string
  title: string
  text: string
  providerReference: string
  validFromDateTime: string
  validToDateTime: string
}

export type EModiferOwnerComponentType =
  | "None"
  | "Section"
  | "Subsection"
  | "Coverage"

export enum EModiferOwnerSource {
  Manual = "Manual",
  Rules = "Rules",
  None = "None",
  Ratings = "Ratings"
}

export interface IReferralReason {
  coverageID: string
  referenceID: string
  sectionID: string
  subSectionID: string
  text: string
}

export interface IFinanceFee {
  gross: IMoney
  id: string
  name: string
  net: IMoney
  order: number
  referenceID: string
  tax: IFinanceTaxTaxSet
}

export interface IFinanceTaxTaxSet {
  taxes: ITax[]
  taxRuleID: string
  taxRuleReferenceID: string
  total?: IMoney
}

export interface IFinanceTax {
  amount: Nullable<IMoney>
  applicationMethod: FinanceTaxApplicationMethod
  name: Nullable<string>
  order: number
  referenceID: Nullable<string>
  ruleAmount: number
  taxType: FinanceTaxType
}

export interface IFinanceCommission {
  amount: Nullable<IMoneyHighAccuracy>
  baseRuleAmount: number
  commissionCalculationType: FinanceCommissionCalculationType
  commissionType: FinanceCommissionType
  name: Nullable<string>
  referenceID: Nullable<string>
  ruleAmount: number
  breakdown: IFinanceCommissionBreakdown
}

export interface IFinanceCommissionBreakdown {
  mgaRuleAmount: number
  mgaAmount: IMoneyHighAccuracy
  introducerBaseRuleAmount: number
  introducerRuleAmount: number
  introducerAmount: IMoneyHighAccuracy
}

export interface IFinanceGrossDiscount {
  accountingDiscount: IMoney
  customerDiscount: IMoney
  isAuthorized: boolean
  name: Nullable<string>
  order: number
  ruleAmount: number
  type: FinanceDiscountType
}

export interface IFinanceNetLoadingDiscount {
  amount: Nullable<IMoney>
  baseRuleAmount: number // Might be removed in future, see API docs
  name: Nullable<string>
  order: number
  referenceID: string
  ruleAmount: number
  type: FinanceDiscountType
}

export type FinanceCommissionType = "FixedRate" | "Percentage"

export type FinanceCommissionCalculationType =
  | "NetPremiumInsurer"
  | "NetPremium"

export type FinanceTaxApplicationMethod = "None" | "Cumulative" | "Fixed"

export type FinanceTaxType = "None" | "FixedRate" | "Percentage"

export type FinanceDiscountType = "Fixed" | "Percentage"

export type PaymentPlanType =
  | "NotSet"
  | "FixedTermSinglePayment"
  | "AnnualMonthlyCredit"
  | "AnnualMonthlySubscription"
  | "MonthlySubscription"
  | "ExternalCollection"

export type TEventType = {
  target: { name: string; value: string | SelectValue }
}
