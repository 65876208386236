import React, { useContext } from "react"
import { IUserAccountInfo } from "platform-client/types/user"

type UserContextType = {
  user?: IUserAccountInfo
  canBindBusiness?: boolean
  professionalIndemnityInsuranceExpired?: boolean
}

const UserContext = React.createContext<UserContextType>({
  user: undefined
})

export const useUserContext = (): UserContextType => {
  const userContext = useContext(UserContext)

  if (userContext.user === undefined) {
    throw new Error(
      "You must wrap your component in `UserContext.Provider` before trying to access this."
    )
  }

  return userContext
}

export default UserContext
