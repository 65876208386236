import React, { useState } from "react"
import { Dropdown, Menu, Table } from "antd"
import formatDate from "dateformat"
import { MoneyDisplay } from "components/display/MoneyDisplay"
import styled from "styled-components"
import Column from "antd/lib/table/Column"
import { TPolicyPayment } from "models/policies/policyPayment"
import { convertCamelCaseToSpaces } from "utils/pocketknife/camel-case-to-spaces"
import { DownloadOutlined, MoreOutlined, SendOutlined } from "@ant-design/icons"
import { Nullable } from "platform-client/types"
import { ConfirmEmailModal } from "../../../../../../components/ConfirmEmailModal"

const Styles = styled.div`
  .all-transactions-row td {
    border-top: 1px solid #ccc;
  }
`

const AllTransactionsTable: React.FC<IAllTransactionsTableProps> = ({
  policyPayments,
  isLoading,
  downloadReceipt,
  emailReceipt,
  isDownloading,
  isEmailing,
  linkedEmailAddress
}): JSX.Element => {
  const [payment, setPayment] = useState<Nullable<TPolicyPayment>>(null)

  const onSend = async (emailAddress: string) => {
    if (payment?.id) {
      await emailReceipt(payment.id, emailAddress)
      setPayment(null)
    }
  }

  return (
    <>
      <Styles>
        <Table
          rowClassName={(_record) => "all-transactions-row"}
          loading={isLoading || isDownloading}
          pagination={false}
          dataSource={policyPayments}
          rowKey={(payment) => payment.id}
        >
          <Column
            title="Payment Timestamp"
            dataIndex="localDateCreated"
            render={(date: string) =>
              formatDate(new Date(date), "dd/mm/yyyy hh:MM:ss TT")
            }
          />
          <Column
            title="Payment Type"
            dataIndex="paymentAction"
            render={(action: string) => convertCamelCaseToSpaces(action)}
          />
          <Column title="Reference" dataIndex="paymentReference" />
          <Column
            title="Amount"
            dataIndex="amount"
            render={(_text: unknown, record: TPolicyPayment) => (
              <MoneyDisplay value={record.amount} />
            )}
          />
          <Column title="Status" dataIndex="paymentStatus" />
          <Column title="Transaction Reason" dataIndex="reason" />
          <Column title="Failure Reason" dataIndex="providerFailureMessage" />
          <Column
            title="Actions"
            render={(record: TPolicyPayment) => (
              <>
                {record.paymentStatus === "Settled" && (
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item
                          key="download"
                          onClick={() => downloadReceipt(record.id)}
                          disabled={
                            isDownloading || record.paymentStatus !== "Settled"
                          }
                        >
                          <DownloadOutlined /> Download Receipt
                        </Menu.Item>
                        <Menu.Item
                          key="email"
                          onClick={() => setPayment(record)}
                          disabled={
                            isEmailing || record.paymentStatus !== "Settled"
                          }
                        >
                          <SendOutlined /> Email Receipt
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <MoreOutlined
                      style={{
                        cursor: "pointer",
                        padding: "0 5px",
                        fontSize: 19
                      }}
                    />
                  </Dropdown>
                )}
              </>
            )}
          />
        </Table>
        <ConfirmEmailModal
          emailAddress={linkedEmailAddress}
          title={`Email Receipt - Payment Ref: ${payment?.paymentReference}`}
          description="Please provide the email address you would like to send the
          transaction receipt to. This has been auto-filled with the address
          stored against the policy."
          isVisible={!!payment?.id}
          onCancel={(e) => setPayment(null)}
          onSend={onSend}
          isLoading={isEmailing}
        />
      </Styles>
    </>
  )
}

interface IAllTransactionsTableProps {
  policyPayments: TPolicyPayment[] | undefined
  isLoading: boolean
  downloadReceipt: (paymentId: string) => Promise<void>
  emailReceipt: (paymentId: string, emailAddress: string) => Promise<void>
  isDownloading: boolean
  isEmailing: boolean
  linkedEmailAddress: string
}

export default AllTransactionsTable
