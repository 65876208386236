import React from "react"
import { Button, Dropdown, Menu } from "antd"
import { Link, useHistory } from "react-router-dom"
import {
  useOpusPermissions,
  EOpusPermission
} from "contexts/authorization/hooks/use-opus-permissions"
import { useNotes } from "./use-notes"
import { useAcceptQuote } from "./use-accept-quote"
import { useDuplicateQuote } from "./use-duplicate-quote"
import { useRevalidateQuote } from "./use-revalidate-quote"
import { useEditQuote } from "./use-edit-quote"
import { useReferQuote } from "./use-refer-quote"
import { useAnonymiseQuote } from "./use-anonymise-quote"
import { useWithdrawQuote } from "./use-withdraw-quote"
import { useEditEndorsements } from "./use-edit-endorsements"
import QuoteBindWithConfirmation from "../quote-bind-with-confirmation"
import { ManualReferToggle } from "components/ManualReferToggle"
import { QuoteActionsProps } from "."

export const QuoteActionsLegacy: React.FC<QuoteActionsProps> = (props) => {
  const history = useHistory()

  // If you have this permission it indicates that you are
  // an insurer not an agent. In general, as an insurer with this permission, you will
  // only be allowed to action quotes if they are in a referred state.

  const { havePermission: canProcessReferral } = useOpusPermissions(
    EOpusPermission.ProcessRefferal,
    "maintain"
  )

  // I feel like we can improve this further. As is, we are loading
  // all these hooks into memory, when only a couple are needed at any given
  // time. Let's re-evaluate at some point to improve the performance of this.

  const { noteModal, showNoteModal } = useNotes(
    props.quoteId,
    props.quoteLifecycles?.onAddNote
  )
  const { acceptQuoteModal, showAcceptQuoteModal } = useAcceptQuote(
    props.quoteId,
    props.quoteLifecycles?.onConfirmQuote
  )
  const { showDuplicateQuoteModal } = useDuplicateQuote(
    props.quoteId,
    props.quoteProductReferenceId,
    props.quoteProductCoverReference
  )
  const { showRevalidateQuoteModal } = useRevalidateQuote(
    props.quoteId,
    props.quoteProductReferenceId,
    props.quoteProductCoverReference
  )
  const { showEditQuoteModal } = useEditQuote(
    props.quoteId,
    props.quoteType,
    props.quoteProductReferenceId,
    props.quoteProductCoverReference,
    props.isHybrid,
    props.productId,
    props.userId,
    props.originalPolicyMeta,
    props.configuration.quote.references.withdrawlReferenceID,
    props.quoteLifecycles?.onWithdrawQuote
  )
  const { showReferQuoteModal } = useReferQuote(
    props.quoteId,
    props.quoteLifecycles?.onReferQuote
  )
  const { withdrawQuoteModal, showWithdrawQuoteModal } = useWithdrawQuote(
    props.quoteId,
    props.withdrawReasons,
    props.quoteLifecycles?.onWithdrawQuote
  )
  const { editEndorsementsModal, showEditEndorsementsModal } =
    useEditEndorsements(
      props.quoteId,
      props.quoteProduct?.productItems_v2,
      props.quoteLifecycles?.onEditEndorsements
    )

  const bindQuote = () => {
    history.push(`/opus/checkout/${props.quoteId}`, {
      proposerEmail: props.proposerEmail
    })
  }
  const { showAnonymiseQuoteModal } = useAnonymiseQuote(
    props.quoteId,
    props.quoteLifecycles?.onAnonymiseQuote
  )

  switch (props.quoteState) {
    case "Referred":
      return (
        <>
          <Button onClick={showAnonymiseQuoteModal}>Anonymise</Button>
          <ManualReferToggle
            onManualReferral={() => props.handleManualReferral?.()}
            onRevokeManualReferral={() => props.handleRevokeManualReferral?.()}
            quoteActions={props.quoteActions}
            isLoading={props.isManualReferralLoading}
          />

          {props.renderQuoteDocuments}

          <Button onClick={() => showNoteModal(true)}>Add note</Button>

          {noteModal}

          {canProcessReferral && (
            <>
              <Link to={`/review-quote/${props.quoteId}`}>
                <Button>Review</Button>
              </Link>

              <Button onClick={() => showEditEndorsementsModal(true)}>
                Edit provisions
              </Button>

              {editEndorsementsModal}

              <Button type="primary" onClick={() => showAcceptQuoteModal(true)}>
                Accept
              </Button>

              {acceptQuoteModal}
            </>
          )}

          <Dropdown
            overlay={
              <Menu>
                <Menu.Item onClick={showDuplicateQuoteModal}>
                  Duplicate
                </Menu.Item>
              </Menu>
            }
            placement="bottomLeft"
          >
            <Button>...</Button>
          </Dropdown>
        </>
      )
    case "Purchased":
      return (
        <>
          {props.renderQuoteDocuments}

          <Dropdown
            overlay={
              <Menu>
                <Menu.Item onClick={showDuplicateQuoteModal}>
                  Duplicate
                </Menu.Item>
              </Menu>
            }
            placement="bottomLeft"
          >
            <Button>...</Button>
          </Dropdown>
        </>
      )

    case "Withdrawn":
      return (
        <>
          <Button onClick={showAnonymiseQuoteModal}>Anonymise</Button>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item onClick={showDuplicateQuoteModal}>
                  Duplicate
                </Menu.Item>
              </Menu>
            }
            placement="bottomLeft"
          >
            <Button>...</Button>
          </Dropdown>
        </>
      )

    case "Open":
      return (
        <>
          <Button
            onClick={() => {
              history.push(
                `/opus/saved/${props.quoteProductReferenceId}/${props.quoteProductCoverReference}/${props.quoteId}`
              )
            }}
            type="primary"
          >
            Edit
          </Button>

          <Button onClick={showAnonymiseQuoteModal}>Anonymise</Button>

          <ManualReferToggle
            onManualReferral={() => props.handleManualReferral?.()}
            onRevokeManualReferral={() => props.handleRevokeManualReferral?.()}
            quoteActions={props.quoteActions}
            isLoading={props.isManualReferralLoading}
          />
        </>
      )

    case "Expired":
      return (
        <>
          <Button type="primary" onClick={showRevalidateQuoteModal}>
            Revalidate
          </Button>

          <Button onClick={showAnonymiseQuoteModal}>Anonymise</Button>
          {props.renderQuoteDocuments}
        </>
      )
    case "PendingPayment":
      return (
        <>
          {props.renderQuoteDocuments}

          <Button onClick={showAnonymiseQuoteModal}>Anonymise</Button>

          {(props.configuration.flags?.paymentType === "SymphonyCheckout" ||
            props.configuration.flags?.paymentTypes?.includes(
              "SymphonyCheckout"
            )) && (
            <Button type="primary" onClick={bindQuote}>
              Bind
            </Button>
          )}

          <ManualReferToggle
            onManualReferral={() => props.handleManualReferral?.()}
            onRevokeManualReferral={() => props.handleRevokeManualReferral?.()}
            quoteActions={props.quoteActions}
            isLoading={props.isManualReferralLoading}
          />

          {(props.configuration.flags.paymentType === "PayOnAccount" ||
            props.configuration.flags.paymentTypes?.includes(
              "PayOnAccount"
            )) && (
            <QuoteBindWithConfirmation
              quoteReferenceID={props.quoteReferenceID}
              onBindQuote={props.handleBindQuote}
              quotePaymentPlans={props.paymentPlans}
              proposer={props.proposer}
              quoteCompanies={props.quoteCompanies}
              platformVersion={props.configuration.flags.platformVersion}
              quoteChanges={props.quoteChanges}
              coverEndDate={props.coverEndDate}
              coverStartDate={props.coverStartDate}
            />
          )}

          <Dropdown
            overlay={
              <Menu>
                <Menu.Item onClick={showDuplicateQuoteModal}>
                  Duplicate
                </Menu.Item>
                <Menu.Item onClick={showEditQuoteModal}>Edit</Menu.Item>
              </Menu>
            }
            placement="bottomLeft"
          >
            <Button>...</Button>
          </Dropdown>
        </>
      )
    case "Quoted":
      return (
        <>
          <Button onClick={showAnonymiseQuoteModal}>Anonymise</Button>

          <ManualReferToggle
            onManualReferral={() => props.handleManualReferral?.()}
            onRevokeManualReferral={() => props.handleRevokeManualReferral?.()}
            quoteActions={props.quoteActions}
            isLoading={props.isManualReferralLoading}
          />

          {props.renderQuoteDocuments}

          <Button onClick={showReferQuoteModal}>Refer</Button>

          {(props.configuration.flags.paymentType === "SymphonyCheckout" ||
            props.configuration.flags?.paymentTypes?.includes(
              "SymphonyCheckout"
            )) && (
            <Button
              type="primary"
              onClick={bindQuote}
              disabled={
                // We only really want to activate this check if v3 is enabled
                props.configuration.flags.platformVersion === "v3" &&
                props.disableBind
              }
            >
              Bind
            </Button>
          )}

          {(props.configuration.flags.paymentType === "PayOnAccount" ||
            props.configuration.flags?.paymentTypes?.includes(
              "PayOnAccount"
            )) && (
            <Button type="primary" onClick={props.handleConfirmQuote}>
              Confirm
            </Button>
          )}

          <Dropdown
            overlay={
              <Menu>
                <Menu.Item onClick={() => showWithdrawQuoteModal(true)}>
                  Withdraw
                </Menu.Item>

                <Menu.Item onClick={showDuplicateQuoteModal}>
                  Duplicate
                </Menu.Item>

                <Menu.Item onClick={showEditQuoteModal}>Edit</Menu.Item>
              </Menu>
            }
            placement="bottomLeft"
          >
            <Button>...</Button>
          </Dropdown>

          {withdrawQuoteModal}
        </>
      )
    case "Invalidated":
      return (
        <>
          <Button onClick={showAnonymiseQuoteModal}>Anonymise</Button>
        </>
      )
    default:
      return null
  }
}
