import { Alert, Button, Card, notification } from "antd"
import { EndorsementType, SectionTypes } from "platform-client/types"
import EndorsementsTable from "components/Endorsements"
import { LayoutPageContent } from "components/LayoutPageContent"
import { RichTextEndorsementsEditorModal } from "components/RichTextEndorsementModal"
import { useMutation, useQueryClient } from "react-query"
import usePlatform from "contexts/platform/use-platform"
import { EQuoteState } from "models/quotes/quoteState"
import { useState } from "react"
import { QuoteState, QuoteStatus } from "platform-client/types/quote"
import useOpus from "contexts/opus/hooks/use-opus"
import { checkUserPermission } from "contexts/authorization/hooks/use-opus-permissions"
import { USER_CLAIMS } from "models/user/claims"
import { decodeJWT } from "utils/pocketknife/decode-jwt"

type IsQuoteComplete = (state: QuoteState, status: QuoteStatus) => boolean

const isQuoteComplete: IsQuoteComplete = (state, status) => {
  // Attempting to map what determins an incomplete quote
  // Please add to this where required.

  if (status === "Draft") {
    return false
  } else if (state === "NotSet" || state === "Open") {
    return false
  }

  return true
}

const QuoteEndorsements: React.FC<IQuoteEndorsementsProps> = ({
  endorsements = [],
  ...props
}) => {
  const platform = usePlatform()
  const opusClient = useOpus()
  const queryClient = useQueryClient()

  const canManageEndorsements =
    checkUserPermission(
      USER_CLAIMS.find((claim) => claim.name === "endorsement")?.claim,
      opusClient.opusToken ? decodeJWT(opusClient.opusToken) : undefined
    ) ?? false

  const isCustomEndorsementEnabled = [
    EQuoteState.Open,
    EQuoteState.Quoted,
    EQuoteState.Referred,
    EQuoteState.PendingPayment
  ].some((item) => item === props.quoteState)

  const addCustomEndorsements = useMutation(
    (endorsement: { title: string; content: string; section: string }) =>
      platform.quoteBuilder.addCustomEndorsementForce(
        props.quoteId,
        endorsement.content,
        endorsement.title,
        endorsement.section,
        "Section"
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["quote", props.quoteId])
      }
    }
  )

  const handleSubmitEndorsement = (
    endorsement: {
      title: string
      content: string
      section?: string
    },
    reset: () => void
  ) => {
    setCustomEndorsementLoading(true)

    switch (modalMode) {
      case "EDIT":
        alert("Editing V3 endorsements not currently supported")
        return
      case "ADD":
        if (endorsement.section === undefined) {
          notification.error({
            message: "Please select a section for this endorsement."
          })
          return
        }

        addCustomEndorsements
          .mutateAsync({
            title: endorsement.title,
            content: endorsement.content,
            section: endorsement.section
          })
          .then((response) => {
            if (response) {
              setCustomEndorsementLoading(false)
              setShowModal(false)
              reset()
            }
          })
          .catch(() => {
            setCustomEndorsementLoading(false)
            notification.error({
              message: "Could not add new endorsement. Please try again."
            })
          })
        return
    }
  }

  const [showModal, setShowModal] = useState<boolean>(false)
  const [customEndorsementLoading, setCustomEndorsementLoading] =
    useState<boolean>(false)
  const [modalMode, setModalMode] = useState<"ADD" | "EDIT">("ADD")
  const [editTitle, setEditTitle] = useState<string | undefined>()
  const [editContent, setEditContent] = useState<string | undefined>()

  if (!isQuoteComplete(props.quoteState, props.quoteStatus)) {
    return (
      <Alert message="Endorsements are not available for an incomplete quote" />
    )
  }

  return (
    <LayoutPageContent transparent>
      <RichTextEndorsementsEditorModal
        title={editTitle}
        content={editContent}
        visible={showModal}
        headerText={
          modalMode === "ADD"
            ? "Add custom endorsement"
            : "Edit custom endorsement"
        }
        sections={props.sections || []}
        onSubmit={handleSubmitEndorsement}
        isLoading={customEndorsementLoading}
        onCancel={(reset) => {
          setShowModal(false)
          setEditTitle(undefined)
          setEditContent(undefined)
          reset()
        }}
      />
      {canManageEndorsements && isCustomEndorsementEnabled && (
        <Card>
          <Button
            type={"primary"}
            onClick={() => {
              setShowModal(true)
              setModalMode("ADD")
            }}
          >
            Add custom endorsement
          </Button>
        </Card>
      )}
      <EndorsementsTable
        endorsements={endorsements}
        quoteID={props.quoteId}
        showEditModal={(title, text, referenceID) => {
          setEditTitle(title)
          setEditContent(text)
          setModalMode("EDIT")
          setShowModal(true)
        }}
        canManageEndorsements={canManageEndorsements}
      />
    </LayoutPageContent>
  )
}

interface IQuoteEndorsementsProps {
  sections?: SectionTypes[]
  quoteState: QuoteState
  quoteStatus: QuoteStatus
  endorsements?: EndorsementType[]
  quoteId: string
  platformVersion?: string
}

export default QuoteEndorsements
