import React, { useState, useEffect, useCallback, useRef } from "react"
import { Input } from "antd"
import { debounce } from "debounce"
import { IPolicyTable } from "models/policies/policyTable"
import { useConfigurationContext } from "contexts/configuration/context"
import usePlatform from "contexts/platform/use-platform"
import useOpus from "contexts/opus/hooks/use-opus"

const { Search } = Input

export const SearchComponent: React.FunctionComponent<ISearchComponent> = (
  props
) => {
  const configuration = useConfigurationContext()
  const [isSending, setIsSending] = useState(false)

  const isMounted = useRef(true)
  const { policyClient: policyV2 } = useOpus()
  const { policy: policyV3 } = usePlatform()

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const sendRequest = useCallback(
    async (value: string) => {
      if (isSending) return

      setIsSending(true)
      props.setSearchLoading(true)

      if (value) {
        props.setSearchValue(value)

        if (configuration.flags.platformVersion === "v2") {
          const _quotes = await policyV2.getPolicySearch(value)
          _quotes?.results && props.policiesUpdate(_quotes.results)
        }

        if (configuration.flags.platformVersion === "v3") {
          const _policies = await policyV3.getPolicySearch(value)

          _policies?.results && props.policiesUpdate(_policies.results)
        }

        props.setSearchLoading(false)
      } else if (!value) {
        props.setSearchValue(null)
        props.setSearchLoading(false)
      }

      if (isMounted.current) {
        setIsSending(false)
        props.setSearchLoading(false)
      }
    },
    [props, isSending, policyV2, policyV3, configuration.flags.platformVersion]
  )

  return (
    <Search
      style={{ width: 425 }}
      onSearch={debounce((value: string) => sendRequest(value), 200)}
      placeholder="search by policy number, name, or address"
      enterButton="Search"
      allowClear={true}
      defaultValue={props.searchValue || ""}
    />
  )
}

interface ISearchComponent {
  policiesUpdate: (data: IPolicyTable[] | undefined) => void
  setSearchValue: (value: string | null) => void
  setSearchLoading: (value: boolean) => void
  searchValue: string | undefined | null
}
