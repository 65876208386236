import { Button, Card, Col, Descriptions, Modal, Row, Spin } from "antd"
import { IPolicyPaymentMethod } from "models/policies/policyPaymentMethod"
import { useState } from "react"
import styled from "styled-components"
import { UpdateCardDetails } from "../policy-update-card-details"
import usePlatform from "contexts/platform/use-platform"
import { IFatZebraPaymentRequest } from "models/fatzebra"
import { useManualRefund } from "../policy-header/use-manual-refund"
import { useManualPayment } from "../policy-header/use-manual-payment"
import { PaymentPlanType } from "models/enums/paymentplantype"
import { CreditCardOutlined, EditOutlined } from "@ant-design/icons"

const StyledCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
`

const ModalContainer = styled.div`
  height: 300px;
`

const PaymentMethod: React.FunctionComponent<IPaymentMethod> = ({
  isLoading,
  data,
  policyId,
  policyReferenceId,
  ownerId,
  paymentPlanType,
  refetchTimelineEvents,
  canEditCardDetails,
  canActionManualPayments,
  setRefetchTransactions
}) => {
  const { manualRefundModal, showManualRefundModal } = useManualRefund(
    ownerId,
    policyId,
    policyReferenceId,
    refetchTimelineEvents,
    setRefetchTransactions
  )

  const { manualPaymentModal, showManualPaymentModal } = useManualPayment(
    ownerId,
    policyId,
    refetchTimelineEvents,
    setRefetchTransactions
  )

  const [isCardModalVisible, setIsCardModalVisible] = useState(false)
  const [isCardUpdateComplete, setIsCardUpdateComplete] = useState(false)

  const { policy, fatZebra } = usePlatform()

  const [isUpdatingSavedCard, setIsUpdatingSavedCard] = useState<boolean>(false)

  const [isFormRendered, setIsFormRendered] = useState<boolean>(false)

  const [updatedPaymentMethod, setUpdatedPaymentMethod] =
    useState<IPolicyPaymentMethod>()

  const [paymentRequest, setPaymentRequest] =
    useState<IFatZebraPaymentRequest>()

  const handleEditPaymentDetails = () => {
    setIsCardModalVisible(true)
    setIsCardUpdateComplete(false)
  }

  const handleModalCancel = () => {
    setIsCardModalVisible(false)
  }

  const handleUpdate = () => {
    // fire card form here.
    // On success the tokenization event will fire.
    if (isFormRendered && paymentRequest && !isUpdatingSavedCard) {
      setIsUpdatingSavedCard(true)
      paymentRequest.fatZebra.checkout()
    }
  }

  const onFormRendered = (request: IFatZebraPaymentRequest) => {
    setPaymentRequest(request)
    // Just to allow time for the iframe to display its content
    setTimeout(() => {
      setIsFormRendered(true)
    }, 1500)
  }

  const onComplete = (paymentMethod: IPolicyPaymentMethod) => {
    setUpdatedPaymentMethod(paymentMethod)
    setIsCardModalVisible(false)
    setIsUpdatingSavedCard(false)
    refetchTimelineEvents()

    // Hack to remove modal from dom to clear the form once an update occurs
    setTimeout(() => {
      setIsCardUpdateComplete(true)
      setIsFormRendered(false)
    }, 1500)
  }

  const renderExpiryDate = () => {
    const paymentMethod = updatedPaymentMethod ?? data
    const month =
      paymentMethod?.expiryMonth && paymentMethod.expiryMonth < 10
        ? `0${paymentMethod?.expiryMonth}`
        : paymentMethod?.expiryMonth
    return `${month}/${paymentMethod?.expiryYear}`
  }

  const renderModalFooter = () => {
    return (
      <Row justify="space-between">
        <Button onClick={handleModalCancel}>Cancel</Button>
        <Button
          type="primary"
          onClick={handleUpdate}
          disabled={!isFormRendered || isUpdatingSavedCard}
        >
          Update
        </Button>
      </Row>
    )
  }

  return (
    <>
      <Card
        title="Payment Details & Actions"
        loading={isLoading}
        style={{ marginBottom: "20px" }}
      >
        <Descriptions size="small" column={4} bordered>
          <Descriptions.Item label="Card Number">
            **** **** ****{" "}
            {updatedPaymentMethod?.last4Digits || data?.last4Digits}
          </Descriptions.Item>
          <Descriptions.Item label="Expiry Date" span={2}>
            {renderExpiryDate()}
          </Descriptions.Item>
          {canEditCardDetails && (
            <Descriptions.Item style={{ width: 0 }}>
              <Button type="link" onClick={handleEditPaymentDetails}>
                <EditOutlined /> Edit
              </Button>
            </Descriptions.Item>
          )}
        </Descriptions>
        {(paymentPlanType === "AnnualMonthlySubscription" ||
          paymentPlanType === "FixedTermSinglePayment" ||
          paymentPlanType === "SinglePayment") &&
          canActionManualPayments && (
            <Row gutter={16}>
              <StyledCol span={24}>
                <Button onClick={() => showManualPaymentModal(true)}>
                  <CreditCardOutlined /> Process Payment
                </Button>
                <Button onClick={() => showManualRefundModal(true)}>
                  <CreditCardOutlined /> Issue Refund
                </Button>
              </StyledCol>
            </Row>
          )}
      </Card>
      {!isCardUpdateComplete && (
        <Modal
          title={`Update Card Details - Policy Ref: ${policyReferenceId}`}
          visible={isCardModalVisible}
          onCancel={handleModalCancel}
          onOk={handleUpdate}
          footer={renderModalFooter()}
        >
          <Spin spinning={!isFormRendered || isUpdatingSavedCard}>
            <ModalContainer>
              <UpdateCardDetails
                policyId={policyId}
                policyReferenceId={policyReferenceId}
                lifecycles={{
                  onFatZebraGetConfig: fatZebra.getConfig,
                  onFatZebraGeneratePaymentIntentVerification:
                    fatZebra.createPaymentIntentVerification,
                  onUpdatePaymentDetails: policy.updatePaymentDetails,
                  onFormRendered,
                  setIsUpdatingSavedCard,
                  onComplete
                }}
              />
            </ModalContainer>
          </Spin>
        </Modal>
      )}
      {manualRefundModal}
      {manualPaymentModal}
    </>
  )
}

interface IPaymentMethod {
  isLoading: boolean
  data?: IPolicyPaymentMethod
  policyId: string
  policyReferenceId: string
  ownerId: string
  paymentPlanType?: PaymentPlanType
  refetchTimelineEvents: VoidFunction
  canEditCardDetails: boolean
  canActionManualPayments: boolean
  setRefetchTransactions: React.Dispatch<React.SetStateAction<boolean>>
}

export default PaymentMethod
