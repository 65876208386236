import { Tooltip } from "antd"
import React from "react"

import formatDate from "dateformat"

export const DateDisplay: React.FC<{ date: string; showTime?: boolean }> = ({
  date,
  showTime = false
}) => {
  const tooltipDate = formatDate(date, "d mmm yyyy h:MM:ss tt")
  const displayDateFormat = showTime ? "d mmm yyyy h:MM tt" : "d mmm yyyy"
  const displayDate = formatDate(date, displayDateFormat)

  return (
    <Tooltip placement={"bottomLeft"} title={tooltipDate}>
      {displayDate}
    </Tooltip>
  )
}
