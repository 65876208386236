import React from "react"
import { Input } from "antd"
import { debounce } from "debounce"
import { useModal } from "services/hooks/use-modal"
import { OnConfirmQuote } from "../../view"

export const useAcceptQuote = (
  quoteId: string,
  onConfirmQuote?: OnConfirmQuote
): IUseAcceptQuote => {
  const [acceptQuoteComment, setAcceptQuoteComment] = React.useState<
    string | undefined
  >()

  const { modal: acceptQuoteModal, setIsModalVisible: showAcceptQuoteModal } =
    useModal({
      title: "Accept quote",
      centered: true,
      destroyOnClose: true,
      okText: "Accept quote",
      okButtonProps: { disabled: !acceptQuoteComment },
      onCancel: () => {
        setAcceptQuoteComment(undefined)
        showAcceptQuoteModal(false)
      },
      onOk: debounce(async () => {
        if (quoteId && acceptQuoteComment && onConfirmQuote) {
          await onConfirmQuote(
            { quoteId, comment: acceptQuoteComment.trim() },
            (quoteId) => {
              if (quoteId) {
                setAcceptQuoteComment(undefined)
              }
            }
          )
        }
      }, 200),
      children: (
        <Input.TextArea
          onChange={(e) => setAcceptQuoteComment(e.target.value)}
          maxLength={999}
          style={{ minHeight: 100 }}
          rows={5}
          showCount
        ></Input.TextArea>
      )
    })

  return {
    acceptQuoteModal,
    showAcceptQuoteModal
  }
}

export interface IUseAcceptQuote {
  acceptQuoteModal: React.ReactNode
  showAcceptQuoteModal: (arg: boolean) => void
}
