import { useFirebaseAuthContext } from "./firebase-auth-contex"
import Login from "containers/default/views/Login"

const RequireFirebaseAuthenticatedUser: React.FC = ({ children }) => {
  const authContext = useFirebaseAuthContext()

  if (authContext?.user === undefined) {
    return <Login />
  }

  return <>{children}</>
}

export default RequireFirebaseAuthenticatedUser
