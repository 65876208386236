import { notification } from "antd"
import { AxiosError } from "axios"

export const handleValidationErrors = (
  error: AxiosError<{ [key: string]: unknown }>
): void => {
  const { response: { data: { validationErrors = [] } = {} } = {} } =
    error || {}
  const [{ message = "" } = {}] =
    (validationErrors as Array<{ message: string }>) || []

  notification.error({ message: `Error: ${message || "Something went wrong"}` })
}
