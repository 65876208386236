import React from "react"
import { PolicyholdersBoundary } from "./boundary"
import EditPolicyholder from "./views/EditPolicyholder"

export const VEditPolicyholder: React.FC = (props) => {
  return (
    <PolicyholdersBoundary>
      <EditPolicyholder {...props} />
    </PolicyholdersBoundary>
  )
}
