import React from "react"
import { Typography, Tooltip } from "antd"
import { TooltipProps } from "antd/lib/tooltip/index"
import styled from "styled-components"
import { TextCellAlignmentType } from "../Title"

const StyledText = styled(Typography.Text)<{
  align: TextCellAlignmentType
  $emphasize: boolean
}>`
  position: relative;
  display: inline-block;
  width: 100%;
  font-size: 14px;
  text-align: ${(props) => props.align};
  font-style: ${(props) => (props.$emphasize ? "italic" : "normal")};
  padding: 0 6px;
`

const CellContentText: React.FC<IText> = ({
  type,
  strong = false,
  emphasize = false,
  strikethrough = false,
  children,
  align = "left",
  ...props
}) => {
  if (props.tooltipProps) {
    return (
      <Tooltip {...props.tooltipProps}>
        <StyledText
          type={type || undefined}
          $emphasize={emphasize}
          strong={strong}
          delete={strikethrough}
          align={align}
          {...props}
        >
          {children}
        </StyledText>
      </Tooltip>
    )
  } else {
    return (
      <StyledText
        type={type || undefined}
        $emphasize={emphasize}
        strong={strong}
        delete={strikethrough}
        align={align}
        {...props}
      >
        {children}
      </StyledText>
    )
  }
}

type TextCellType = "danger" | "secondary" | "success" | "warning"

interface IText {
  align?: TextCellAlignmentType
  emphasize?: boolean
  strikethrough?: boolean
  strong?: boolean
  type?: TextCellType
  tooltipProps?: TooltipProps
}

export default CellContentText
