import {
  AddButton,
  DeleteButton,
  DisableButton,
  ExpandButton,
  LockButton,
  WorkingButton
} from "components/Console/CellContent/Button"
import CellContentText from "components/Console/CellContent/Text"
import Row from "components/Console/Row"
import { TPlatformResponse } from "contexts/platform/client"
import { ICoverage } from "platform-client/types"
import CoverageCoverPeriod from "../../../CoverPeriod"

const Coverage: React.FC<ICoverageProps> = ({
  readOnly = false,
  updating = false,
  expanded = false,
  isSplitCoverage = false,
  ...props
}) => (
  <Row
    updating={updating}
    disabled={!props.coverage.enabled}
    key={props.coverage.id}
    {...(isSplitCoverage && {
      toggle: <ExpandButton expanded={expanded} onClick={props.toggle} />
    })}
    reference={
      props.reference || (
        <CellContentText>{props.coverage.name}</CellContentText>
      )
    }
    values={props.cells}
    control={
      !readOnly && (
        <>
          {updating && <WorkingButton />}
          {!isSplitCoverage && props.coverage.enabled && (
            <CoverageCoverPeriod
              componentID={props.coverage.id}
              coverStartDateTime={props.coverage.coverStartDateTime}
              coverEndDateTime={props.coverage.coverEndDateTime}
              onChange={props.onChange}
              onCoverPeriodChange={props.updateCoverPeriod}
            />
          )}
          {props.coverage.enabled ? (
            <DisableButton
              onClick={() => props.toggleCoverage(props.coverage)}
            />
          ) : (
            <AddButton onClick={() => props.toggleCoverage(props.coverage)} />
          )}
          <DeleteButton onClick={props.removeCoverage} />
          <LockButton
            locked={props.coverage.locked}
            onClick={() =>
              props.lockCoverage(
                props.coverage.locked ? false : true,
                props.coverage.id
              )
            }
          />
        </>
      )
    }
  />
)

interface ICoverageProps {
  readOnly?: boolean
  updating?: boolean
  expanded?: boolean
  isSplitCoverage?: boolean
  coverage: ICoverage
  reference?: React.ReactNode
  cells: React.ReactNode[]
  toggle?: () => void
  onChange: (response: TPlatformResponse) => void
  updateCoverPeriod: (
    componentID: string,
    coverStartDateTime: string,
    coverEndDateTime: string
  ) => void
  toggleCoverage: (coverage: ICoverage) => void
  removeCoverage: () => void
  lockCoverage: (lock: boolean, coverageID: string) => void
}

export default Coverage
