import React, { useState } from "react"
import {
  Layout,
  PageHeader,
  Descriptions,
  Row,
  Col,
  Card,
  Divider,
  Table,
  Skeleton,
  Dropdown,
  Menu,
  Button,
  Modal,
  Alert,
  notification,
  Tag,
  Tooltip,
  Breadcrumb
} from "antd"
import { FileTextTwoTone, StopOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import { useGetEmbargo } from "../../services/hooks"
import { useOrgSettings } from "contexts/organisation/hooks/use-org-settings"
import { LayoutPageContent } from "components"
import { RouteComponentProps } from "react-router"
import { isDateAfter } from "utils/pocketknife/date"
import formatDate from "dateformat"
import { SyncOutlined } from "@ant-design/icons"
import { useDefaultPage } from "utils/hooks/use-default-page"
import useOpus from "contexts/opus/hooks/use-opus"
import { IEmbargoesListItem } from "models/embargoes/embargoesList"
import { parseEmbargoStatus } from "../AllEmbargoes/constants"

export const EmbargoDetails: React.FunctionComponent<IEmbargoDetails> = (
  props
) => {
  const columns = [
    {
      title: "Values",
      dataIndex: "value",
      key: "value"
    }
  ]
  const { orgSettings } = useOrgSettings()
  const { embargoesClient } = useOpus()

  const [shoudlEmbargoReload, setShouldEmbargoReload] = useState<boolean>(false)

  const { embargo, isEmbargoLoaded, isError } = useGetEmbargo(
    props.match.params.id,
    shoudlEmbargoReload
  )

  useDefaultPage(isError?.errorNumber, ["404", "400"], {
    status: "404",
    title: "Sorry, we couldn't find a embargo that matched those details.",
    buttonText: "Back to Embargoes",
    redirectTo: "/embargoes"
  })

  const embargoValues =
    embargo?.values && embargo?.values.map((item: string) => ({ value: item }))

  const isEmbargoExpired =
    embargo &&
    orgSettings &&
    isDateAfter(embargo.endTime, orgSettings?.timezone)

  const [embargoLabel, embargoColor] =
    embargo !== undefined && orgSettings !== undefined
      ? parseEmbargoStatus(
          embargo.startTime,
          embargo.endTime,
          orgSettings?.timezone
        )
      : ["", ""]

  const createdBy = ((embargo?: IEmbargoesListItem) => {
    if (embargo === undefined) return ""
    const { firstName, lastName, id, username } = embargo.createdBy
    if (firstName && lastName) {
      return (
        <Tooltip title={username}>
          {firstName} {lastName}
        </Tooltip>
      )
    } else if (username) {
      return username
    } else {
      return id
    }
  })(embargo)

  const modalCancel = () =>
    Modal.confirm({
      title: "Are you sure you want to cancel this embargo?",
      width: 600,
      content: (
        <>
          By cancelling this embargo you are setting the end date to right now,
          lifting the embargo immediately. To add this embargo again you will
          need to create a new embargo if you cancel.
          {!isEmbargoExpired && (
            <>
              <Divider />
              <Alert
                message="This embargo has already expired, are you sure you want to cancel?"
                type="warning"
                showIcon
              />
            </>
          )}
        </>
      ),
      centered: true,
      okText: "Disable",
      onOk: async () => {
        if (embargo?.id) {
          const result = await embargoesClient
            .setEmbargoCancel(embargo?.id)
            .catch(() => {
              notification.error({
                message: null,
                description: "Embargo cancelled or already ended."
              })
            })

          if (result) {
            notification.success({
              message: null,
              description: "Embargo has been cancelled."
            })
            setShouldEmbargoReload(true)
          }
        }
      }
    })

  return (
    <Layout>
      <LayoutPageContent error={isError} transparent>
        <Row gutter={24}>
          <Col span={12}>
            <PageHeader
              ghost={false}
              breadcrumb={
                <Breadcrumb>
                  <Breadcrumb.Item key="/embargoes">
                    <Link to="/embargoes">
                      <StopOutlined />
                      &nbsp;Embargoes
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item key="/embargoes/id">
                    {embargo?.id}
                  </Breadcrumb.Item>
                </Breadcrumb>
              }
              extra={
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item onClick={modalCancel}>Cancel</Menu.Item>
                    </Menu>
                  }
                  placement="bottomLeft"
                >
                  <Button>...</Button>
                </Dropdown>
              }
              avatar={{
                icon: <FileTextTwoTone />,
                style: { background: "transparent", marginRight: 5 }
              }}
              tags={
                orgSettings && embargo ? (
                  <Tag color={embargoColor}>{embargoLabel}</Tag>
                ) : (
                  <Tag icon={<SyncOutlined spin />} color="processing">
                    LOADING
                  </Tag>
                )
              }
              title={embargo?.id}
            >
              <Skeleton loading={!isEmbargoLoaded || !orgSettings}>
                <Descriptions size="small" column={3} layout="vertical">
                  <Descriptions.Item
                    labelStyle={{ fontWeight: 600 }}
                    label="Date Created"
                  >
                    {embargo?.dateCreated
                      ? formatDate(new Date(embargo?.dateCreated), "dd/mm/yyyy")
                      : "-"}
                  </Descriptions.Item>
                  <Descriptions.Item
                    labelStyle={{ fontWeight: 600 }}
                    label="Created by"
                  >
                    {createdBy}
                  </Descriptions.Item>
                  <Descriptions.Item
                    labelStyle={{ fontWeight: 600 }}
                    label="Date Started"
                  >
                    {embargo?.startTime
                      ? formatDate(new Date(embargo?.startTime), "dd/mm/yyyy")
                      : "-"}
                  </Descriptions.Item>
                  <Descriptions.Item
                    labelStyle={{ fontWeight: 600 }}
                    label="Date Ending"
                  >
                    {embargo?.endTime
                      ? formatDate(new Date(embargo?.endTime), "dd/mm/yyyy")
                      : "-"}
                  </Descriptions.Item>
                  <Descriptions.Item
                    labelStyle={{ fontWeight: 600 }}
                    label="Scheme Id"
                  >
                    {embargo?.schemeID}
                  </Descriptions.Item>
                  <Descriptions.Item
                    labelStyle={{ fontWeight: 600 }}
                    label="Scheme Reference"
                  >
                    {embargo?.schemeReferenceID}
                  </Descriptions.Item>
                </Descriptions>
                <Divider style={{ margin: "12px 0" }} />
                <Descriptions size="small" layout="vertical">
                  <Descriptions.Item
                    labelStyle={{ fontWeight: 600 }}
                    label="Risk Path"
                  >
                    {embargo?.riskPath}
                  </Descriptions.Item>
                </Descriptions>
                <Divider style={{ margin: "12px 0" }} />
                <Descriptions size="small" layout="vertical">
                  <Descriptions.Item
                    labelStyle={{ fontWeight: 600 }}
                    label="Embargo message"
                  >
                    {embargo?.errorMessage}
                  </Descriptions.Item>
                </Descriptions>
              </Skeleton>
            </PageHeader>
          </Col>
          <Col span={12}>
            <Card style={{ width: "100%" }}>
              <Table
                loading={!isEmbargoLoaded}
                dataSource={embargoValues}
                columns={columns}
                pagination={false}
              />
            </Card>
          </Col>
        </Row>
      </LayoutPageContent>
    </Layout>
  )
}

export type IEmbargoDetails = RouteComponentProps<{
  id?: string
}>
