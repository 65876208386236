import { Table, Typography } from "antd"
import { AdjustButton } from "components/Console/CellContent/Button"
import DOMPurify from "dompurify"
import {
  EndorsementType,
  isV7Endorsement
} from "platform-client/types/endorsement"
import styled from "styled-components"
import RemoveEndorsementButton from "./RemoveEndorsement"

const { Text } = Typography

const StyledEndorsementText = styled.div`
  display: block;
  position: relative;
  font-size: 1em;
  width: 600px;
  width: 70ch;
  margin-left: 7px;
  padding-left: 41px;
  border-left: 2px solid #1890ff;
`

const IDWrapper = styled.div`
  width: 18ch;
  overflow: visible;
  position: relative;
`
const IDText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  width: 18ch;
  text-overflow: ellipsis;
  display: block;
  user-select: all;
  &:hover {
    position: relative;
    padding: 0.25em 0.5em;
    margin: -0.25em -0.5em;
    box-shadow: 0 2px 10px rgb(0 0 0 / 0.1);
    white-space: normal;
    background: #fafafa;
    z-index: 10000;
    width: max-content;
  }
`

const CustomText = styled(Text)`
  color: rgba(0, 0, 0, 0.45);
  font-style: italic;
`

const EndorsementsTable = ({
  endorsements,
  ...props
}: IEndorsementsTableProps): JSX.Element => {
  return (
    <Table
      rowKey="id"
      expandable={{
        defaultExpandAllRows: false,
        expandedRowRender: (record) => {
          const pureText = DOMPurify.sanitize(record.text, {
            USE_PROFILES: { html: true }
          })

          return (
            <StyledEndorsementText
              dangerouslySetInnerHTML={{ __html: pureText }}
            />
          )
        },
        rowExpandable: (record) => Boolean(record.text)
      }}
      dataSource={endorsements}
      locale={{
        emptyText: "There are currently no endorsements."
      }}
      pagination={false}
    >
      <Table.Column
        title="ID"
        width="20ch"
        key="endorsement-ID"
        render={(_, row: EndorsementType) => {
          if (isV7Endorsement(row) && row.modifierType === "Custom") {
            return <CustomText>Custom</CustomText>
          }

          return isV7Endorsement(row) ? (
            <IDWrapper>
              <IDText>{row.providerReferenceID}</IDText>
            </IDWrapper>
          ) : (
            <IDWrapper>
              <IDText>{row.referenceID}</IDText>
            </IDWrapper>
          )
        }}
      />
      <Table.Column
        title="Endorsement title"
        key="title"
        render={(_, row: EndorsementType) => <Text>{row.title}</Text>}
      />
      {props.canManageEndorsements && (
        <Table.Column
          title=""
          key="actions"
          width={150}
          render={(_, row: EndorsementType) =>
            isV7Endorsement(row) ? (
              props.quoteID &&
              row.modifierType === "Custom" &&
              row.modifierOwners[0].componentID !== null && (
                <RemoveEndorsementButton
                  endorsementId={row.id}
                  quoteId={props.quoteID}
                  componentId={row.modifierOwners[0].componentID}
                />
              )
            ) : props.showEditModal !== undefined ? (
              <>
                <AdjustButton
                  onClick={() =>
                    typeof props.showEditModal === "function" &&
                    props.showEditModal(row.title, row.text, row.referenceID)
                  }
                />
              </>
            ) : (
              ""
            )
          }
        />
      )}
    </Table>
  )
}

interface IEndorsementsTableProps {
  quoteID?: string
  endorsements: EndorsementType[]
  platformVersion?: string
  showEditModal?: (title: string, text: string, referenceID: string) => void
  canManageEndorsements?: boolean
}

export default EndorsementsTable
