import React, { useState, useEffect } from "react"
import { AxiosError } from "axios"
import { Layout, Table, Row, Col, Button, Divider, Breadcrumb } from "antd"
import { LayoutPageContent, LayoutPageHeader } from "components"
import { RouteComponentProps } from "react-router"
import { FileTextOutlined, PlusOutlined } from "@ant-design/icons"
import { QUOTE_COLUMNS } from "./constants"
import { useConfigurationContext } from "contexts/configuration/context"
import { IPortalQuoteTable } from "models/quotes/portalQuoteTable"
import { SearchComponent, FilterComponent } from "./components"
import useOpus from "contexts/opus/hooks/use-opus"
import { useQuery } from "react-query"
import {
  useOpusPermissions,
  EOpusPermission
} from "contexts/authorization/hooks/use-opus-permissions"

const NewQuoteButton = () => {
  const configuration = useConfigurationContext()
  const { havePermission: canUseNewQuote } = useOpusPermissions(
    EOpusPermission.Quote,
    "create"
  )

  return canUseNewQuote && configuration.flags.platformVersion !== "v3" ? (
    <Button type="primary" href={`/choose-quote`} icon={<PlusOutlined />}>
      New quote
    </Button>
  ) : null
}

export const AllQuotes: React.FunctionComponent<IAllQuotes> = (props) => {
  const { quoteClient } = useOpus()
  const configuration = useConfigurationContext()

  const [quotesType, setQuotesTypes] = useState<
    { value: string; label: string; key: string } | undefined
  >(undefined)

  const [quotesData, setQuotesData] = useState<IPortalQuoteTable[] | []>([])
  const [isSearchLoading, setSearchLoading] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string | undefined | null>(
    undefined
  )

  const { isFetching, refetch } = useQuery<
    IPortalQuoteTable[] | undefined,
    AxiosError
  >(
    ["quote-list", props.match.params.status],
    async () =>
      quoteClient.getQuotes(
        configuration.quote.enabledQuoteTypes,
        props.match.params.status
      ),

    {
      onSuccess: (quoteList) => {
        if (quoteList) {
          setQuotesData(quoteList)
        }
      }
    }
  )

  useEffect(() => {
    if (!searchValue) {
      refetch()
    }
  }, [searchValue, refetch])

  return (
    <Layout>
      <LayoutPageHeader
        ghost={false}
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <FileTextOutlined />
              &nbsp; Quotes
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        title="All Quotes"
        extra={<NewQuoteButton />}
      />
      <LayoutPageContent>
        <Row justify="end" align="middle">
          <Col style={{ padding: 16 }}>
            Filter by status:
            <FilterComponent
              quotesType={quotesType}
              quoteStatus={props.match.params.status}
              quoteConfiguration={configuration?.quote}
              setQuotesTypes={setQuotesTypes}
            />
            <Divider style={{ height: 20 }} type="vertical" />
            <SearchComponent
              setSearchValue={setSearchValue}
              setSearchLoading={setSearchLoading}
              searchValue={searchValue}
              quotesUpdate={setQuotesData}
              quotesFilter={quotesType}
            />
          </Col>
        </Row>
        <Table
          scroll={{ x: "max-content" }}
          loading={isFetching || isSearchLoading}
          columns={QUOTE_COLUMNS(
            configuration?.quote?.types,
            configuration?.flags?.platformVersion
          )}
          dataSource={quotesData}
          rowKey="id"
          pagination={{
            defaultPageSize: 10,
            hideOnSinglePage: true,
            position: ["bottomCenter"],
            size: "small"
          }}
        />
      </LayoutPageContent>
    </Layout>
  )
}

export type IAllQuotes = RouteComponentProps<{
  status?: string | undefined
}>
