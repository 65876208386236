import React, { useMemo } from "react"
import styled from "styled-components"

const MarkedText = styled.mark`
  background-color: #ffd580;
  padding: 0;

  /* Screen-reader styles */
  &::before,
  &::after {
    clip-path: inset(100%);
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    width: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
  }

  &::before {
    content: " [highlight start] ";
  }

  &::after {
    content: " [highlight end] ";
  }

  /* Windows High Contrast Mode styles */
  @media screen and (-ms-high-contrast: active) {
    color: HighlightText;
    background-color: Highlight;
  }
`

export interface IHighlighted {
  text?: string
  search?: string
}

export default function Highlighted({
  text = "",
  search = ""
}: IHighlighted): JSX.Element {
  /**
   * The brackets around the re variable keeps it in the array when splitting and does not affect testing
   * @example 'react'.split(/(ac)/gi) => ['re', 'ac', 't']
   */
  const re = useMemo(() => {
    const SPECIAL_CHAR_RE = /([.?*+^$[\]\\(){}|-])/g
    const escapedSearch = search.replace(SPECIAL_CHAR_RE, "\\$1")
    return new RegExp(`(${escapedSearch})`, "i")
  }, [search])

  return (
    <>
      {search === ""
        ? text
        : text
            .split(re)
            .filter((part) => part !== "")
            .map((part, i) =>
              re.test(part) ? (
                <MarkedText key={part + i}>{part}</MarkedText>
              ) : (
                part
              )
            )}
    </>
  )
}
