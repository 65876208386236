import { ColumnsType } from "antd/lib/table"
import { IEricListItem } from "models/eric/ericList"
import { Typography } from "antd"
import formatDate from "dateformat"
import { IMoney } from "platform-client/types/pricing"
import { MoneyDisplay } from "components/display/MoneyDisplay"
import { Link } from "react-router-dom"
import { Dropdown, Menu } from "antd"
import { MoreOutlined } from "@ant-design/icons"
import { ImportPolicyStatus } from "./components/filter"
const { Text } = Typography
export type IStatus = [statusLabel: string, statusColor: string]

export const IMPORT_POLICY_STATUSES: Record<string, IStatus> = {
  PENDING: ["PENDING", "blue"],
  ACTIVE: ["ACTIVE", "green"],
  EXPIRED: ["EXPIRED", "default"]
}

export const parsePolicyStatus = (
  startTime: string,
  endTime: string,
  timezone: string
): IStatus => {
  return IMPORT_POLICY_STATUSES.ACTIVE
  /*const isEmbargoPending = isDateAfter(startTime, timezone)
  const isEmbargoActive = isDateAfter(endTime, timezone)

  if (isEmbargoPending) return IMPORT_POLICY_STATUSES.PENDING
  else if (isEmbargoActive) return IMPORT_POLICY_STATUSES.ACTIVE
  else return IMPORT_POLICY_STATUSES.EXPIRED*/
}

export const ERIC_POLICY_COLUMNS = (
  timezone: string | undefined,
  actions: { sendEmail: (importPolicyID: string) => void }
): ColumnsType<IEricListItem> => [
  {
    title: "Policy Reference",
    dataIndex: "policyReference",
    key: "policyReference",
    render: (policyReference) =>
      policyReference ? <Text>{policyReference}</Text> : "-"
  },
  {
    title: "Customer Name",
    dataIndex: "customerName",
    key: "customerName",
    render: (person: { firstName: string; lastName: string }) =>
      person ? (
        <Text>
          {person.firstName} {person.lastName}
        </Text>
      ) : (
        "-"
      )
  },
  {
    title: "Customer Email",
    dataIndex: "customerEmail",
    key: "customerEmail",
    render: (customerEmail) =>
      customerEmail ? <Text>{customerEmail}</Text> : "-"
  },
  {
    title: "Is a Business",
    dataIndex: "isBusiness",
    key: "isBusiness",
    render: (isBusiness) => (isBusiness ? "Yes" : "No")
  },
  {
    title: "Cover End Date",
    dataIndex: "coverEndDate",
    key: "coverEndDate",
    render: (date: string) => formatDate(new Date(date), "dd/mm/yyyy")
  },
  {
    title: "Product",
    dataIndex: "product",
    key: "product",
    render: (product) => (product ? <Text>{product}</Text> : "-")
  },
  {
    title: "Original Premium",
    dataIndex: "gwp",
    key: "gwp",
    render: (premium) =>
      premium && <MoneyDisplay value={premium} isMajorCurrency={true} />
  },
  {
    title: "Stella Quote Reference",
    dataIndex: "renewalQuoteReferenceID",
    key: "renewalQuoteReferenceID",
    render: (renewalQuoteReferenceID, record) =>
      renewalQuoteReferenceID && (
        <Link to={`/quote/${record.renewalQuoteID}/summary`}>
          {renewalQuoteReferenceID}
        </Link>
      )
  },
  {
    title: "Stella Premium",
    dataIndex: "stellaGWP",
    key: "stellaGWP",
    render: (premium: IMoney) =>
      premium && <MoneyDisplay value={premium} isMajorCurrency={true} />
  },

  {
    title: "Date Imported",
    dataIndex: "dateImported",
    key: "dateImported",
    render: (date: string) => formatDate(new Date(date), "dd/mm/yyyy h:MM:ss")
  },
  {
    title: "Import Status",
    dataIndex: "importStatus",
    key: "importStatus",
    render: (status) => (status ? <Text>{status}</Text> : "-")
  },
  {
    title: "Email Sent",
    dataIndex: "emailSent",
    key: "emailSent",
    render: (emailSent) => (emailSent ? "Yes" : "No")
  },
  {
    title: "Action",
    key: "action",
    render: (importPolicy: { id: string; importStatus: string }) => {
      return importPolicy.importStatus ===
        ImportPolicyStatus.RenewalGenerated ||
        importPolicy.importStatus === ImportPolicyStatus.RenewalDeclined ||
        importPolicy.importStatus === ImportPolicyStatus.ProductDeclined ? (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                key="sendEmail"
                onClick={() => actions.sendEmail(importPolicy.id)}
              >
                Send Email
              </Menu.Item>
            </Menu>
          }
        >
          <MoreOutlined
            style={{ cursor: "pointer", padding: "0 5px", fontSize: 19 }}
          />
        </Dropdown>
      ) : null
    }
  }
  /*{
    title: "",
    dataIndex: "id",
    key: "id",
    render: (embargoID: string) => (
      <Link to={`/embargo/${embargoID}`}>
        <Button>View embargo</Button>
      </Link>
    )
  }*/
]
