import { Card, Table } from "antd"

import { IMoney } from "platform-client/types"
import { ITravelPackage } from "models/common/package"
import { useTravelPackageList } from "./use-travel-package-list"

const tableColumns = [
  {
    title: "Package",
    dataIndex: "packageName",
    key: "packageName"
  },
  {
    dataIndex: "customerDefinedLevel",
    key: "customerDefinedLevel"
  },
  {
    title: "Level",
    dataIndex: "packageLevel",
    key: "packageLevel"
  },
  {
    title: "Excess",
    dataIndex: "excess",
    key: "excess"
  }
]

export interface ITravelPackageListProps {
  packages: { [key: string]: ITravelPackage }
  product: { referenceID: string; version: number }
  excess: IMoney
}

const TravelPackageList: React.FunctionComponent<ITravelPackageListProps> = (
  props
) => {
  const { packageList } = useTravelPackageList(
    props.packages,
    props.product,
    props.excess
  )

  return (
    <Card title="Packages">
      <Table
        columns={tableColumns}
        dataSource={packageList}
        pagination={false}
      />
    </Card>
  )
}

export default TravelPackageList
