import { notification, Spin } from "antd"
import { withRouter, StaticContext, RouteComponentProps } from "react-router"
import usePlatform from "contexts/platform/use-platform"
import { IUpdatePolicyholder } from "platform-client/client/controllers/policyholders"
import { IPolicyholder } from "platform-client/types"
import React from "react"
import { useQuery, useMutation } from "react-query"
import PolicyholderForm, {
  IPolicyholderData
} from "components/PolicyHolder/PolicyholderForm"

const EditPolicyHolder: React.FC<IUpdatePolicyholderProps> = (props) => {
  const policyholderId = props?.match?.params?.policyholderId
  const goBack = () => {
    const params = new URLSearchParams(props.location.search)

    if (params.has("url")) {
      const url = params.get("url") || ""
      return props.history.push(url)
    }

    return props.history.goBack()
  }

  const [policyholder, setPolicyholder] = React.useState<
    IPolicyholder | undefined
  >(undefined)
  const platform = usePlatform()

  const policyholderData = useQuery(
    ["policyholder", policyholderId],
    () => platform.policyholders.getPolicyholder(policyholderId),
    {
      onSuccess: (response) => setPolicyholder(response),
      onError: () => {
        notification.error({
          message: "Something went wrong, please try again later"
        })
        goBack()
      }
    }
  )

  const updatePolicyholder = useMutation(
    (updatePolicyholderRequest: IUpdatePolicyholder) =>
      platform.policyholders.updatePolicyholder(
        policyholderId,
        updatePolicyholderRequest
      )
  )
  const onFinish = async (formData: IPolicyholderData) => {
    if (formData.legalName && formData.policyholderType) {
      updatePolicyholder
        .mutateAsync({
          businessName: formData.businessName || null,
          contact: {
            name: {
              firstName: formData.firstName || null,
              lastName: formData.lastName || null,
              title: formData.title || null
            },
            dateOfBirth: formData.dateOfBirth
              ? formData.dateOfBirth.format("YYYY-MM-DD")
              : null,
            phoneNumber: formData.phoneNumber || null,
            emailAddress: formData.emailAddress || null
          },
          contactAddress: {
            line1: formData?.contactAddress?.line1 || null,
            line2: formData?.contactAddress?.line2 || null,
            line3: formData?.contactAddress?.line3 || null,
            locality: formData?.contactAddress?.locality || null,
            province: formData?.contactAddress?.province || null,
            postalCode: formData?.contactAddress?.postalCode || null,
            country: formData?.contactAddress?.country
              ? JSON.parse(formData?.contactAddress?.country)
              : { referenceID: null, text: null },
            provinceReferenceID: null,
            uniqueReference: null
          },
          legalName: formData.legalName,
          policyholderType: formData.policyholderType,
          id: policyholderId,
          referenceID: policyholder?.referenceID || null
        })
        .then(goBack)
        .catch(() => {
          // Check response errors here and return relevant messages.
          notification.error({
            message: "Unable to create policyholder, please try again."
          })
        })
    } else {
      notification.error({
        message: "Unable to submit form, missing required fields."
      })
    }
  }

  return (
    <Spin spinning={policyholderData.isLoading}>
      <PolicyholderForm
        onFinish={onFinish}
        policyholder={policyholder}
        isLoading={updatePolicyholder.isLoading}
        onBack={goBack}
      />
    </Spin>
  )
}

type IUpdatePolicyholderProps = RouteComponentProps<
  {
    policyholderId: string
    subpage: string
  },
  StaticContext
>

export default withRouter(EditPolicyHolder)
