import React from "react"
import QuotesBoundary from "./boundary"
import { ChooseQuote } from "./views/ChooseQuote"
import { CheckoutQuote } from "./views/CheckoutQuote"
import { ReviewQuote, IReviewQuote } from "./views/ReviewQuote"
import {
  HandleNewQuoteRouting,
  IHandleNewQuoteRoutingProps
} from "./views/NewQuote/HandleNewQuoteRouting" // IHandleNewQuoteRoutingProps
import { AllQuotes, IAllQuotes } from "./views/AllQuotes"
import { Quote } from "./views/Quote"
import { QuickQuote } from "./views/QuickQuote"
import CreateQuote from "./views/CreateQuote"
import {
  INewSymphonyQuoteRoutingProps,
  NewSymphonyQuote
} from "./views/NewSymphonyQuote"
import { IQuickQuoteRouteProps } from "./views/QuickQuote/view"
import NewQuickQuote from "./views/NewQuickQuote"
import ConvertQuickQuote from "./views/ConvertQuickQuote"
import { RouteComponentProps } from "react-router"

export const VAllQuotes: React.FC<IAllQuotes> = (props) => (
  <QuotesBoundary>
    <AllQuotes {...props} />
  </QuotesBoundary>
)

export const VReviewQuote: React.FC<IReviewQuote> = (props) => (
  <QuotesBoundary>
    <ReviewQuote {...props} />
  </QuotesBoundary>
)

export const VChooseQuote: React.FC = (props) => (
  <QuotesBoundary>
    <ChooseQuote {...props} />
  </QuotesBoundary>
)

export const VCreateQuote: React.FC = (props) => (
  <QuotesBoundary>
    <CreateQuote {...props} />
  </QuotesBoundary>
)

export const VNewQuote: React.FC<IHandleNewQuoteRoutingProps> = (props) => (
  <QuotesBoundary>
    <HandleNewQuoteRouting {...props} />
  </QuotesBoundary>
)

export const VCheckoutQuote: React.FC = (props) => (
  <QuotesBoundary>
    <CheckoutQuote {...props} />
  </QuotesBoundary>
)

export const VQuote: React.FC = (props) => (
  <QuotesBoundary>
    <Quote {...props} />
  </QuotesBoundary>
)

export const VSymphony: React.FC<INewSymphonyQuoteRoutingProps> = (props) => (
  <QuotesBoundary>
    <NewSymphonyQuote {...props} />
  </QuotesBoundary>
)

export const VQuickQuote: React.FC<IQuickQuoteRouteProps> = (props) => (
  <QuotesBoundary>
    <QuickQuote {...props} />
  </QuotesBoundary>
)

export const VNewQuickQuote: React.FC = () => (
  <QuotesBoundary>
    <NewQuickQuote />
  </QuotesBoundary>
)

export const VConvertQuickQuote: React.FC<RouteComponentProps<{ id: string }>> =
  (props) => (
    <QuotesBoundary>
      <ConvertQuickQuote {...props} />
    </QuotesBoundary>
  )
