import { Nullable } from "platform-client/types"

export const StringIsEmpty = (str: string): boolean => {
  return str.trim().length < 1
}

export const StringIsEmptyNullable = (
  str: Nullable<string> | undefined
): boolean => {
  if (str === null || str === undefined) {
    return true
  }
  return StringIsEmpty(str)
}
