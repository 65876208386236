import moment, { Moment } from "moment"

export const validateDate = (
  _date: string | null,
  _coverStartDate: string | null,
  _coverEndDate: string | null,
  today: Moment = moment().startOf("d") // adjustable for testing
): boolean => {
  const date = moment(_date).startOf("d")
  const coverEndDate = moment(_coverEndDate).endOf("d")
  const coverStartDate = moment(_coverStartDate).startOf("d")

  return [
    date.isValid(),
    today.isValid(),
    coverStartDate.isValid(),
    coverEndDate.isValid(),
    date.isSameOrAfter(coverStartDate),
    date.isSameOrBefore(today.add(30, "d")),
    date.isSameOrBefore(coverEndDate)
  ].every((item) => !!item)
}
