import React from "react"
import styled from "styled-components"
import { breakpoint } from "utils/styles/breakpoints"
import { SigninComponent } from "./components/sigin"
import { ResetPasswordComponent } from "./components/resetPassword"

const StyledContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
  flex-wrap: no-wrap;
  background-color: #eee;
`

const StyledPopBox = styled.div`
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 50px auto 2rem;
  padding: 2rem;
  background-color: #fff;
  box-shadow: 0 0 10px #ccc;
  border-radius: 3px;

  .title {
    display: block;
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: #3b2053;
    padding-bottom: 1rem;
  }

  .buttons {
    padding: 1rem 0 0;
    text-align: center;
  }

  @media (min-width: ${breakpoint.tablet}) {
    margin-top: 70px;
  }
`

const Login: React.FunctionComponent = (props) => {
  const [showForgottenPassword, setShowForgottenPassword] =
    React.useState<boolean>(false)

  return (
    <>
      <StyledContainer>
        <StyledPopBox>
          {!showForgottenPassword && (
            <SigninComponent
              showResetPasswordForm={() => setShowForgottenPassword(true)}
            />
          )}
          {showForgottenPassword && (
            <ResetPasswordComponent
              showLoginForm={() => setShowForgottenPassword(false)}
            />
          )}
        </StyledPopBox>
      </StyledContainer>
    </>
  )
}

export default Login
