import { useOrgSettings } from "contexts/organisation/hooks/use-org-settings"
import {
  IFatZebraPaymentRequest,
  IFatZebraVerificationProps,
  IFatZebraVerificationResponse
} from "models/fatzebra"
import { IPolicyPaymentMethod } from "models/policies/policyPaymentMethod"
import { FatZebraWrapper } from "./wrapper"
import { notification } from "antd"
import { IFatZebraConfigResponse } from "platform-client/types"
import { useState } from "react"

export const UpdateCardDetails: React.FC<IUpdateCardDetailsProps> = ({
  policyId,
  policyReferenceId,
  lifecycles
}) => {
  const { orgSettings } = useOrgSettings()

  const [hasCardToken, setHasCardToken] = useState(false)

  return (
    <div>
      {orgSettings && (
        <FatZebraWrapper
          payment={{
            amount: 1,
            currency: orgSettings.currencyCode,
            reference: policyReferenceId
          }}
          policyID={policyId}
          verificationProps={{
            policyID: policyId
          }}
          onPayNow={(token) => {
            if (token) {
              if (!hasCardToken) {
                setHasCardToken(true)
                lifecycles
                  .onUpdatePaymentDetails(policyId, token)
                  .then((response) => {
                    lifecycles.onComplete(response)
                    notification.success({
                      message: "Card details updated successfully."
                    })
                  })
                  .catch(() => {
                    notification.error({
                      message:
                        "We weren't able to update your card information, please try again later or contact customer support."
                    })
                    setHasCardToken(false)
                    lifecycles.setIsUpdatingSavedCard(false)
                  })
              }
            } else {
              notification.error({
                message:
                  "We weren't able to update your card information, please try again later or contact customer support."
              })
              lifecycles.setIsUpdatingSavedCard(false)
            }
          }}
          onComplete={(request) => {
            lifecycles.onFormRendered(request)
          }}
          setIsCheckingOut={lifecycles.setIsUpdatingSavedCard}
          fatZebraConfig={lifecycles.onFatZebraGetConfig}
          fatZebraPaymentIntentVerification={
            lifecycles.onFatZebraGeneratePaymentIntentVerification
          }
        />
      )}
    </div>
  )
}

interface ILifecycles {
  onFatZebraGetConfig: (
    quoteID?: string,
    policyID?: string
  ) => Promise<IFatZebraConfigResponse>
  onFatZebraGeneratePaymentIntentVerification: (
    props: IFatZebraVerificationProps
  ) => Promise<IFatZebraVerificationResponse>
  onFormRendered: (paymentRequest: IFatZebraPaymentRequest) => void
  onUpdatePaymentDetails: (
    policyID: string,
    paymentMethodID: string
  ) => Promise<IPolicyPaymentMethod>
  onComplete: (paymentMethod: IPolicyPaymentMethod) => void
  setIsUpdatingSavedCard: (isUpdatingCard: boolean) => void
}

interface IUpdateCardDetailsProps {
  policyId: string
  policyReferenceId: string
  lifecycles: ILifecycles
}
