import React from "react"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { IPolicyBundle } from "models/policies/policy"

import { PolicyCover, PolicyPremium, PolicyInfo } from "./../../v2_components"
import { Row, Col } from "antd"

export const PolicySummarySubViewComponent: React.FunctionComponent<IPolicySummarySubView> =
  (props) => {
    return (
      <>
        <PolicyInfo
          isPolicyLoading={props.isPolicyLoading && props.areActionsLoading}
          address={props.policy?.risk?.property?.address}
          personName={props.policy?.risk?.proposer?.personName}
          emailAddress={props.policy?.risk.proposer.emailAddress || ""}
          phoneNumber={props.policy?.risk.proposer.phoneNumber || ""}
          lineOfBusiness={props.policy?.policy.lineOfBusiness}
          created={props.policy?.policy?.dateCreated}
          policyType={props.policy?.scheme?.description}
          solicitor={props.policy?.risk.seller?.firmName}
          limitOfLiability={props.policy?.risk.limitOfLiability}
          product={props.policy?.product}
          scheme={props.policy?.scheme}
          effectiveDate={props.policy?.policy?.policyHistory?.effectiveDate}
          ownerId={props.policy?.policy?.ownerID}
          policyId={props.policy?.policy?.id}
          isExternal={props.policy?.policy?.isExternal}
          coverStartDate={props.policy?.policy?.coverStartDate}
          refetchPolicyBundle={props.refetchPolicyBundle}
          refetchTimelineEvents={props.refetchTimelineEvents}
          userId={props.policy?.policy.ownerID}
        />
        <Row gutter={24}>
          <Col span={14}>
            <PolicyCover
              isWorking={props.isPolicyLoading}
              policyBundle={props.policy}
            />
          </Col>
          <Col span={10}>
            <PolicyPremium
              gross={props.policy?.totalPaid.total.gross}
              net={props.policy?.totalPaid.total.net}
              iptTotal={props.policy?.totalPaid.total.iptTotal}
              iptPercentage={props.policy?.totalPaid.total.iptPercentage}
            />
          </Col>
        </Row>
      </>
    )
  }

export const PolicySummarySubView = withRouter(PolicySummarySubViewComponent)

export interface IPolicySummarySubView extends RouteComponentProps {
  policy: IPolicyBundle | undefined
  isPolicyLoading: boolean
  areActionsLoading: boolean
  policyPlanReferenceID: string | undefined
  refetchPolicyBundle?: () => void
  refetchTimelineEvents: VoidFunction
}
