import { Blocklist } from "."
import { CloseSquareOutlined } from "@ant-design/icons"

export const BLACKLIST_ROUTES = [
  {
    path: "/blocklist",
    component: Blocklist
  }
]

export const BLACKLIST_NAVIGATION = [
  {
    title: "SMS Blocklist",
    path: "/blocklist",
    submenu: false,
    icon: <CloseSquareOutlined />,
    iconActive: <CloseSquareOutlined />
  }
]
