import RichTextEditor from "components/RichTextEditor"
import { Button, Checkbox, Modal, Typography } from "antd"
import { MouseEventHandler } from "react"
import {
  EOpusPermission,
  useOpusPermissions
} from "contexts/authorization/hooks/use-opus-permissions"

const { Title } = Typography

interface INewNoteModalProps {
  visible: boolean
  content: string
  setContent: (content: string) => void
  onSubmit: MouseEventHandler<HTMLElement>
  onCancel: () => void
  onInternalCheck: () => void
  isInternal: boolean
  isLoading: boolean
  headerText?: string
}

export const NewNoteModal: React.FC<INewNoteModalProps> = ({
  visible = false,
  content = "",
  setContent = () => null,
  onCancel = () => null,
  onSubmit = () => null,
  onInternalCheck = () => null,
  isInternal = false,
  isLoading = false,
  headerText = "Create New Note"
}) => {
  const { havePermission } = useOpusPermissions(
    EOpusPermission.GroupType,
    "Insurer"
  )

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      footer={
        <>
          <Button onClick={onCancel} disabled={isLoading}>
            Cancel
          </Button>
          <Button type="primary" onClick={onSubmit} loading={isLoading}>
            Submit
          </Button>
        </>
      }
    >
      <Title level={4}>{headerText}</Title>
      <RichTextEditor content={content} setContent={setContent} />
      {havePermission && (
        <Checkbox onChange={onInternalCheck} checked={isInternal}>
          Internal Note
        </Checkbox>
      )}
    </Modal>
  )
}
