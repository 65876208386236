import { Tag, Button, Avatar, Typography, Tooltip } from "antd"
import { Link } from "react-router-dom"
import { isDateAfter } from "utils/pocketknife/date"
import {
  AVATARS_COLORS,
  numberFromText
} from "utils/pocketknife/number-from-text"
import { truncate } from "utils/pocketknife/truncate"
import { ColumnsType } from "antd/lib/table"
import { IEmbargoesListItem } from "models/embargoes/embargoesList"
import formatDate from "dateformat"

const { Text } = Typography

export type IEmbargoStatus = [statusLabel: string, statusColor: string]

export const EMBARGO_STATUSES: Record<string, IEmbargoStatus> = {
  PENDING: ["PENDING", "blue"],
  ACTIVE: ["ACTIVE", "green"],
  EXPIRED: ["EXPIRED", "default"]
}

export const parseEmbargoStatus = (
  startTime: string,
  endTime: string,
  timezone: string
): IEmbargoStatus => {
  const isEmbargoPending = isDateAfter(startTime, timezone)
  const isEmbargoActive = isDateAfter(endTime, timezone)

  if (isEmbargoPending) return EMBARGO_STATUSES.PENDING
  else if (isEmbargoActive) return EMBARGO_STATUSES.ACTIVE
  else return EMBARGO_STATUSES.EXPIRED
}

export const EMBARGOES_COLUMNS = (
  timezone: string | undefined
): ColumnsType<IEmbargoesListItem> => [
  {
    title: "",

    key: "createdByUserID",
    render: (row: {
      createdBy: {
        username: string
        firstName: string | null
        lastName: string | null
      }
    }) => {
      if (row.createdBy.firstName && row.createdBy.lastName) {
        const firstLetter = row.createdBy.firstName
          .substring(0, 1)
          .toUpperCase()
        const secondLetter = row.createdBy.lastName
          .substring(0, 1)
          .toUpperCase()

        return (
          <Tooltip
            title={`${row.createdBy.firstName} ${row.createdBy.lastName}`}
          >
            <Avatar
              style={{
                backgroundColor:
                  AVATARS_COLORS[
                    numberFromText(`${firstLetter}${secondLetter}`) %
                      AVATARS_COLORS.length
                  ]
              }}
            >
              {firstLetter}
              {secondLetter}
            </Avatar>
          </Tooltip>
        )
      } else if (row.createdBy.username) {
        return (
          <Tooltip title={row.createdBy.username}>
            <Avatar
              style={{
                backgroundColor:
                  AVATARS_COLORS[
                    numberFromText(
                      row.createdBy.username.substring(0, 3).toUpperCase()
                    ) % AVATARS_COLORS.length
                  ]
              }}
            >
              {row.createdBy.username.substring(0, 3).toUpperCase()}
            </Avatar>
          </Tooltip>
        )
      }
    }
  },
  {
    title: "Start Time",
    dataIndex: "startTime",
    key: "startTime",
    render: (startTime: string) => formatDate(new Date(startTime), "dd/mm/yyyy")
  },
  {
    title: "End Time",
    dataIndex: "endTime",
    key: "endTime",
    render: (endTime: string) => formatDate(new Date(endTime), "dd/mm/yyyy")
  },
  {
    title: "Status",
    key: "enabled",
    align: "center",
    render: (row: { startTime: string; endTime: string }) => {
      if (timezone) {
        const [statusLabel, statusColor] = parseEmbargoStatus(
          row.startTime,
          row.endTime,
          timezone
        )
        return (
          <Tag style={{ marginRight: 0 }} color={statusColor}>
            {statusLabel}
          </Tag>
        )
      }
    }
  },
  {
    title: "Path",
    dataIndex: "riskPath",
    key: "riskPath",
    render: (riskPath: string) => <Text code>{riskPath}</Text>
  },

  {
    title: "Values",
    dataIndex: "values",
    key: "values",
    render: (values: string[]) => {
      const valuesSize = values.length

      return valuesSize > 3 ? (
        <>
          {values.slice(0, 3).map((value: string) => (
            <Text code>{truncate(value, 17)}</Text>
          ))}
          ... {valuesSize - 3} more
        </>
      ) : (
        values
          .slice(0, 3)
          .map((value: string) => <Text code>{truncate(value, 17)}</Text>)
      )
    }
  },
  {
    title: "",
    dataIndex: "id",
    key: "id",
    render: (embargoID: string) => (
      <Link to={`/embargo/${embargoID}`}>
        <Button>View embargo</Button>
      </Link>
    )
  }
]
