import useOpus from "contexts/opus/hooks/use-opus"
import React, { useEffect, useState } from "react"
import createPlatformClient from "./client"
import PlatformContext from "./context"

interface IPlatformProviderProps {
  children?: React.ReactNode
}

const PlatformProvider = (props: IPlatformProviderProps): JSX.Element => {
  /**
   * This is unfortunately necessary until we merge
   * session start into the platform API
   */
  const { opusToken } = useOpus()
  const [token, setToken] = useState<string | undefined>(opusToken)

  useEffect(() => {
    if (opusToken) {
      setToken(opusToken)
    }
  }, [opusToken])

  return (
    <PlatformContext.Provider
      value={{
        token,
        ...createPlatformClient(token)
      }}
    >
      {props.children}
    </PlatformContext.Provider>
  )
}

export default PlatformProvider
