import React, { useState } from "react"
import Row from "components/Console/Row"
import CellContentTitle from "components/Console/CellContent/Title"
import CellContentLinkButton from "components/Console/CellContent/Link"
import RatedSimpleCoverage from "./RatedSimpleCoverage"
import NonRatedCoverage from "./NonRatedCoverage"
import RatedWithUpliftCoverage from "./RatedWithUpliftCoverage"
import FixedCoverage from "./FixedCoverage"
import { IOrgSettings } from "contexts/organisation/context"
import { ICoverage } from "platform-client/types"
import AddCoverage from "./AddCoverage"
import PremiumCoverage from "./PremiumCoverage"
import RatedWithFeeCoverage from "./RatedWithFeeCoverage"
import { IQuoteBuilderResponse } from "platform-client/client/controllers/quote-builder"

const Coverages: React.FC<ICoverages> = ({
  coverages,
  readOnly = false,
  ...props
}) => {
  const [addCoverage, setAddCoverage] = useState<boolean>(false)

  const coverageTypes = coverages.reduce(
    (filtered: string[], coverage: ICoverage) => {
      if (!filtered.includes(coverage.type)) {
        filtered.push(coverage.type)
      }

      return filtered
    },
    []
  )

  const coverageHeaderCells = () => {
    switch (true) {
      case [
        "Rated",
        "NonRated",
        "Fixed",
        "DeclaredValueWithUplift",
        "Premium"
      ].every((v) => coverageTypes.includes(v)):
      case [
        "Rated",
        "Fixed",
        "DeclaredValueWithUplift",
        "Premium",
        "RatedWithFee"
      ].every((v) => coverageTypes.includes(v)):
        return [
          <CellContentTitle align="right">Declared Value</CellContentTitle>,
          <CellContentTitle align="right">Sum Insured/Qty</CellContentTitle>,
          <CellContentTitle align="right">Rate</CellContentTitle>,
          <CellContentTitle align="right">Fee</CellContentTitle>,
          <CellContentTitle align="right">Net Premium</CellContentTitle>
        ]
      case [
        "Rated",
        "DeclaredValueWithUplift",
        "Premium",
        "RatedWithFee"
      ].every((v) => coverageTypes.includes(v)):
        return [
          <CellContentTitle align="right">Declared Value</CellContentTitle>,
          <CellContentTitle align="right">Sum Insured</CellContentTitle>,
          <CellContentTitle align="right">Rate</CellContentTitle>,
          <CellContentTitle align="right">Fee</CellContentTitle>,
          <CellContentTitle align="right">Net Premium</CellContentTitle>
        ]
      case ["Rated", "Fixed", "RatedWithFee"].every((v) =>
        coverageTypes.includes(v)
      ):
        return [
          undefined,
          <CellContentTitle align="right">Sum Insured/Qty</CellContentTitle>,
          <CellContentTitle align="right">Rate</CellContentTitle>,
          <CellContentTitle align="right">Fee</CellContentTitle>,
          <CellContentTitle align="right">Net Premium</CellContentTitle>
        ]
      case ["Rated", "Premium", "RatedWithFee"].every((v) =>
        coverageTypes.includes(v)
      ):
        return [
          <CellContentTitle align="right">Declared Value</CellContentTitle>,
          <CellContentTitle align="right">Sum Insured</CellContentTitle>,
          <CellContentTitle align="right">Rate</CellContentTitle>,
          <CellContentTitle align="right">Fee</CellContentTitle>,
          <CellContentTitle align="right">Net Premium</CellContentTitle>
        ]
      case ["Rated", "RatedWithFee"].some((v) => coverageTypes.includes(v)):
      case coverageTypes.includes("Rated"):
        return [
          undefined,
          <CellContentTitle align="right">Sum Insured</CellContentTitle>,
          <CellContentTitle align="right">Rate</CellContentTitle>,
          <CellContentTitle align="right">Fee</CellContentTitle>,
          <CellContentTitle align="right">Net Premium</CellContentTitle>
        ]
      case coverageTypes.includes("Fixed"):
        return [
          undefined,
          <CellContentTitle align="right">Qty</CellContentTitle>,
          <CellContentTitle align="right">Rate</CellContentTitle>,
          undefined,
          <CellContentTitle align="right">Net Premium</CellContentTitle>
        ]
      case coverageTypes.includes("Premium"):
        return [
          <CellContentTitle align="right">Declared Value</CellContentTitle>,
          undefined,
          undefined,
          undefined,
          <CellContentTitle align="right">Net Premium</CellContentTitle>
        ]
      case coverageTypes.includes("NonRated"):
        return [
          undefined,
          undefined,
          undefined,
          undefined,
          <CellContentTitle align="right" emphasize>
            Net Premium
          </CellContentTitle>
        ]
    }
  }

  const coverageRows = coverages.map((coverage) => {
    switch (coverage.type) {
      case "Rated":
        return (
          <RatedSimpleCoverage
            key={coverage.id}
            onChange={props.onChange}
            coverage={coverage}
            sectionID={props.sectionID}
            subsectionID={props.subsectionID}
            editSessionID={props.editSessionID}
            organisationSettings={props.organisationSettings}
            readOnly={readOnly}
          />
        )
      case "RatedWithFee":
        return (
          <RatedWithFeeCoverage
            key={coverage.id}
            onChange={props.onChange}
            coverage={coverage}
            sectionID={props.sectionID}
            subsectionID={props.subsectionID}
            editSessionID={props.editSessionID}
            organisationSettings={props.organisationSettings}
            readOnly={readOnly}
          />
        )
      case "DeclaredValueWithUplift":
        return (
          <RatedWithUpliftCoverage
            key={coverage.id}
            onChange={props.onChange}
            coverage={coverage}
            sectionID={props.sectionID}
            subsectionID={props.subsectionID}
            editSessionID={props.editSessionID}
            organisationSettings={props.organisationSettings}
            readOnly={readOnly}
          />
        )
      case "NonRated":
        return (
          <NonRatedCoverage
            key={coverage.id}
            onChange={props.onChange}
            coverage={coverage}
            sectionID={props.sectionID}
            subsectionID={props.subsectionID}
            editSessionID={props.editSessionID}
            readOnly={readOnly}
          />
        )
      case "Fixed":
        return (
          <FixedCoverage
            key={coverage.id}
            onChange={props.onChange}
            coverage={coverage}
            sectionID={props.sectionID}
            subsectionID={props.subsectionID}
            editSessionID={props.editSessionID}
            organisationSettings={props.organisationSettings}
            readOnly={readOnly}
          />
        )
      case "Premium":
        return (
          <PremiumCoverage
            key={coverage.id}
            onChange={props.onChange}
            coverage={coverage}
            sectionID={props.sectionID}
            subsectionID={props.subsectionID}
            editSessionID={props.editSessionID}
            organisationSettings={props.organisationSettings}
            readOnly={readOnly}
          />
        )
      default:
        return null
    }
  })

  return (
    <>
      <Row
        reference={<CellContentTitle>Coverage</CellContentTitle>}
        values={coverageHeaderCells()}
      />
      {coverageRows.length && coverageRows}
      {addCoverage && !readOnly && (
        <AddCoverage
          productID={props.productID}
          subsectionID={props.subsectionID}
          editSessionID={props.editSessionID}
          sectionReference={props.sectionReference}
          organisationSettings={props.organisationSettings}
          onChange={props.onChange}
          onRemove={() => {
            setAddCoverage(false)
          }}
        />
      )}
      {!addCoverage && !readOnly && (
        <Row
          reference={
            <CellContentLinkButton onClick={() => setAddCoverage(true)}>
              Add Coverage
            </CellContentLinkButton>
          }
          values={[undefined]}
        />
      )}
    </>
  )
}

export interface ICoverages {
  editSessionID?: string
  productID?: number
  name: string
  coverages: ICoverage[]
  startExpanded?: boolean
  sectionID: string
  subsectionID: string
  sectionReference: string
  subsectionReference: string
  onChange: (response: IQuoteBuilderResponse) => void
  organisationSettings?: IOrgSettings
  readOnly?: boolean
}

export default Coverages
