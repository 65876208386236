const currency_symbols: Record<string, string> = {
  USD: "$", // US Dollar
  EUR: "€", // Euro
  CRC: "₡", // Costa Rican Colón
  GBP: "£", // British Pound Sterling
  ILS: "₪", // Israeli New Sheqel
  INR: "₹", // Indian Rupee
  JPY: "¥", // Japanese Yen
  KRW: "₩", // South Korean Won
  NGN: "₦", // Nigerian Naira
  PHP: "₱", // Philippine Peso
  PLN: "zł", // Polish Zloty
  PYG: "₲", // Paraguayan Guarani
  THB: "฿", // Thai Baht
  UAH: "₴", // Ukrainian Hryvnia
  VND: "₫", // Vietnamese Dong
  RUB: "₽", // Russian Ruble
  AUD: "$" // Australian Dollar
}

const getCurrencySymbol = (currencyCode: string): string => {
  return (
    (currencyCode in currency_symbols && currency_symbols[currencyCode]) || ""
  )
}

export default getCurrencySymbol
