import { Theme } from "antd/lib/config-provider/context"
import { ProductVersionType } from "platform-client/types/product"
import React, { useContext } from "react"

export type IConfigurationContext = {
  app: IAppConfiguration
  auth0: IAuth0
  firebase: IFirebaseAuth
  opus: IOpusUriPieces
  symphony: IUrlPieces
  notification: IConfigurationNotification
  quote: IConfigurationContextQuote
  policy: IConfigurationContextPolicy
  user: IConfigurationContextUser
  underWriting: IUnderWriting
  flags: IFeatureFlags
  products?: IConfigurationProducts
  opportunities: IConfigurationOpportunities
}

export type IConfigurationOpportunities = {
  variants: {
    variant: {
      empty: string
    }
  }
}

export type IConfigurationProducts = {
  [productReferenceId: string]: IConfigurationProduct
}

export type IConfigurationProduct = {
  newQuoteType: "prototype" | "digitalunderwriting"
  productType: ProductVersionType
}

export type IConfigurationNotification = {
  quote: {
    [key: string]: {
      title: string
      content: string
      type: string
    }
  }
}

export type QuoteModelVersion = "v6" | "v7"

export type IUnderWriting = {
  expandMainSections: boolean
  expandSubSections: boolean
}
export type IFeatureFlags = {
  showReviewTab: boolean
  hiddenNavItems: string[]
  showRenewalsTab: boolean
  paymentType?: PaymentTypes
  paymentTypes?: PaymentTypes[]
  platformVersion?: PlatformVersions
  quoteVersion: QuoteModelVersion
}

export type PaymentTypes =
  | "PaymentLink"
  | "SymphonyCheckout"
  | "None"
  | "PayOnAccount"

export type PlatformVersions = "v2" | "v3"

export type StartSectionTypes = "Quotes" | "Opportunities"

export type IAppConfiguration = {
  organisation: string
  logo: string | undefined
  meta: {
    title: string
    description: string | undefined
    favicon: string | undefined
  }
  images: {
    avatar: string | undefined
  }
  theme?: Theme
  startingSection?: StartSectionTypes
}
export type IConfigurationContextUser = {
  state: IConfigurationUserState
}

export interface ITagColorConfig {
  tag: string
  color: string
}

export type IUserStates = "Active" | "Stub" | "Inactive" | "Disabled"
export type IConfigurationUserState = Record<IUserStates, ITagColorConfig>

export type IPolicyStates =
  | "Pending"
  | "Active"
  | "Defaulted"
  | "Cancelled"
  | "Lapsed"

export type IConfigurationPolicyAction = Record<IPolicyStates, string>
export type IConfigurationPolicyState = Record<IPolicyStates, ITagColorConfig>

export type IConfigurationContextPolicy = {
  action: IConfigurationPolicyAction
  state: IConfigurationPolicyState
  details: IConfigurationContextPolicyDetails
}

export type IConfigurationContextPolicyDetails = {
  autoRenewal: {
    title: string
    content: string
    disabledLabel: string
    withdrawnLabel: string
  }
  manualRenewal: {
    title: string
    content: string
    disabledLabel: string
    withdrawnLabel: string
  }
  intentionRenewal: {
    title: string
    content: string
    withdrawnLabel: string
  }
  withdrawRenewal: {
    title: string
    content: string
    disabledLabel: string
    withdrawnLabel: string
  }
  errorRenewal?: string
}

export type IConfigurationContextQuote = {
  references: IConfigurationContextQuoteReferences
  enabledQuoteTypes: string[]
  showIPTLabel?: boolean
  types: { [key: string]: string }
}

export type IConfigurationContextQuoteReferences = {
  withdrawlReferenceID: string | undefined
}

export type IInvoiceStatus = "None" | "Open" | "Paid" | "Cancelled" | "Void"
export type IInvoiceTypes = "None" | "Invoice" | "CreditNote"
export type IInvoicePurpose =
  | "None"
  | "InsurancePurchase"
  | "BrokerCommission"
  | "CapacityPayment"

export type IConfigurationContextInvoice = {
  filterStates: string[]
  state: Record<IInvoiceStatus, ITagColorConfig>
}

export type IAuth0 = {
  clientId: string | undefined
  domain: string | undefined
  audience: string | undefined
}

export type IFirebaseAuth = {
  apiKey: string | undefined
  authDomain: string | undefined
  projectId: string | undefined
  appId: string | undefined
}

//TODO - Nuke this when we finally move off v2
export type IOpusUriPieces = {
  baseOpusUrl: string
}

export type IUrlPieces = {
  baseUrl: string
}

export const defaultConfig: IConfigurationContext = {
  app: {
    organisation: "Aventus Platform",
    logo: undefined,
    meta: {
      title: "Aventus Portal",
      description: "",
      favicon: undefined
    },
    images: {
      avatar: undefined
    }
  },
  auth0: {
    clientId: undefined,
    domain: undefined,
    audience: undefined
  },
  firebase: {
    apiKey: undefined,
    authDomain: undefined,
    projectId: undefined,
    appId: undefined
  },
  opus: {
    baseOpusUrl: ""
  },
  symphony: {
    baseUrl: ""
  },
  notification: {
    quote: {}
  },
  opportunities: {
    variants: {
      variant: {
        empty:
          "No quotes have been created yet for this variant. Quotes will only be created for completed variants. Edit and complete the risk to do this."
      }
    }
  },
  quote: {
    references: {
      withdrawlReferenceID: "zeta_wr_quoteedited"
    },
    types: {
      NewBusiness: "New Business",
      MTA: "Adjustment (MTA)",
      Vri: "Quick Quote",
      Renewal: "Renewal"
    },
    enabledQuoteTypes: ["NewBusiness", "Vri", "Renewal", "MTA", "Cancellation"]
  },
  policy: {
    action: {
      Active: "green",
      Pending: "yellow",
      Defaulted: "red",
      Cancelled: "red",
      Lapsed: "blue"
    },
    state: {
      Active: { tag: "Active", color: "green" },
      Defaulted: { tag: "defaulted", color: "red" },
      Pending: { tag: "pending", color: "yellow" },
      Cancelled: { tag: "cancelled", color: "red" },
      Lapsed: { tag: "lapsed", color: "blue" }
    },
    details: {
      autoRenewal: {
        title: "Auto-renewal",
        content:
          "When set to true, this policies renewal quote will automatically bind and payment collected when the current policy expires.",
        disabledLabel:
          "Renewals have been withdrawn,' with 'The customer has no intention to renew",
        withdrawnLabel: " Renewals have been withdrawn"
      },
      manualRenewal: {
        title: "Manually review renewal",
        content:
          "Marks the renewal quote for this policy as requiring manual review before being sent/made available to customer.",
        disabledLabel:
          "Renewals have been withdrawn,' with 'The customer has no intention to renew",
        withdrawnLabel: " Renewals have been withdrawn"
      },
      intentionRenewal: {
        title: "Manually review renewal",
        content: `Tags the policy indicating the customer has no intention to renew. A renewal quote will not be assigned to the customer, they will receive a "no intent to renew" email. The customer will not receive any renewal pricing or follow-up notifications.`,
        withdrawnLabel: " Renewals have been withdrawn"
      },
      withdrawRenewal: {
        title: "Withdraw",
        content: "Withdrawing a policy",
        disabledLabel: "To undo this withdrawal, please contact us directly",
        withdrawnLabel: "Renewals have been withdrawn"
      }
    }
  },
  user: {
    state: {
      Active: { tag: "Active", color: "green" },
      Stub: { tag: "Invited", color: "blue" },
      Inactive: { tag: "Inactive", color: "red" },
      Disabled: { tag: "Inactive", color: "red" }
    }
  },
  underWriting: {
    expandMainSections: true,
    expandSubSections: true
  },
  flags: {
    showReviewTab: false,
    hiddenNavItems: ["Invoices"],
    showRenewalsTab: true,
    paymentType: "None",
    platformVersion: "v2",
    quoteVersion: "v6"
  }
}

export const ConfigurationContext =
  React.createContext<IConfigurationContext>(defaultConfig)

export const useConfigurationContext = (): IConfigurationContext =>
  useContext(ConfigurationContext)

export default ConfigurationContext
