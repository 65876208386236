import CellContentMoney from "components/Console/CellContent/Money"
import CellContentRate from "components/Console/CellContent/Rate"
import CellContentRateInput from "components/Console/CellContent/RateInput"
import CellContentText from "components/Console/CellContent/Text"
import Row from "components/Console/Row"
import { useSection } from "components/Console/Section/use-section"
import { Table } from "components/Console/Table"
import { useGetApplicationConfiguration } from "contexts/configuration/hooks/use-get-configuration"
import { IOrgSettings } from "contexts/organisation/context"
import { TPlatformResponse } from "contexts/platform/client"
import { IFinanceCommission } from "models/underwriting"
import { ICompanyInRole, SectionTypes } from "platform-client/types"
import { decimalToPercentage } from "utils/pocketknife/converters"
import { COMMISSION_RATE_TYPES } from "../Commission"

const IntroducerCommission: React.FC<IIntroducerCommissionProps> = ({
  onChange,
  editSessionID,
  readOnly = false,
  ...props
}): JSX.Element => {
  const { updateSection, handleIntroducerUpdate } = useSection(
    editSessionID,
    onChange
  )
  const { applicationConfiguration: appConfig } =
    useGetApplicationConfiguration()

  const [introducer] = props.companies

  return (
    <Table.HalfTable
      updating={updateSection.isLoading}
      reference="Commission Split"
      summaryValues={[
        <CellContentText align="right">
          {introducer?.company.name} Commission
        </CellContentText>,
        <CellContentRateInput
          {...(props.commission.commissionType && {
            type: COMMISSION_RATE_TYPES[props.commission.commissionType]
          })}
          currencyCode={props.organisationSettings?.currencyCode}
          value={
            props.commission.commissionType === "Percentage"
              ? decimalToPercentage(
                  props.commission.breakdown.introducerRuleAmount
                )
              : props.commission.breakdown.introducerRuleAmount
          }
          readOnly={readOnly}
          onBlur={(event) => {
            handleIntroducerUpdate(event, props.section, props.commission)
          }}
        />,
        props.commission.breakdown.introducerAmount ? (
          <CellContentMoney {...props.commission.breakdown.introducerAmount} />
        ) : (
          <CellContentText align="right">-</CellContentText>
        )
      ]}
      firstRowValues={[
        <CellContentText align="right">
          {introducer?.company.name} Commission
        </CellContentText>,
        <CellContentRateInput
          {...(props.commission.commissionType && {
            type: COMMISSION_RATE_TYPES[props.commission.commissionType]
          })}
          currencyCode={props.organisationSettings?.currencyCode}
          value={
            props.commission.commissionType === "Percentage"
              ? decimalToPercentage(
                  props.commission.breakdown.introducerRuleAmount
                )
              : props.commission.breakdown.introducerRuleAmount
          }
          readOnly={readOnly}
          onBlur={(event) => {
            handleIntroducerUpdate(event, props.section, props.commission)
          }}
        />,
        props.commission.breakdown.introducerAmount ? (
          <CellContentMoney {...props.commission.breakdown.introducerAmount} />
        ) : (
          <CellContentText align="right">-</CellContentText>
        )
      ]}
    >
      <Row
        type="default"
        values={[
          <CellContentText align="right">
            {appConfig.app.organisation} Commission
          </CellContentText>,
          props.commission.breakdown.mgaRuleAmount ? (
            <CellContentRate
              value={props.commission.breakdown.mgaRuleAmount || 0}
              {...(props.commission.commissionType && {
                type: COMMISSION_RATE_TYPES[props.commission.commissionType]
              })}
            />
          ) : (
            <CellContentText align="right">-</CellContentText>
          ),
          props.commission.breakdown.mgaAmount ? (
            <CellContentMoney {...props.commission.breakdown.mgaAmount} />
          ) : (
            <CellContentText align="right">-</CellContentText>
          )
        ]}
      />
    </Table.HalfTable>
  )
}

interface IIntroducerCommissionProps {
  readOnly: boolean
  editSessionID?: string
  onChange?: (response: TPlatformResponse) => void
  section: SectionTypes
  commission: IFinanceCommission
  companies: ICompanyInRole[]
  organisationSettings?: IOrgSettings
}

export default IntroducerCommission
