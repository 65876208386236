import { DiffViewer } from "components/diffviewer"
import { useQuery } from "react-query"
import usePlatform from "contexts/platform/use-platform"
import { IQuestionSetsDifference } from "platform-client/types/difference"
import { Alert, Row, Spin } from "antd"

export const RiskChanges: React.FunctionComponent<IRiskChanges> = (props) => {
  const { difference } = usePlatform()

  const { isLoading, data, isError } = useQuery<
    IQuestionSetsDifference | undefined
  >(["riskDiffPolicy", props.policyId], async () =>
    difference.policyHistory(props.policyId)
  )

  if (isLoading)
    return (
      <Row justify="center" align="bottom" style={{ height: 100 }}>
        <Spin />
      </Row>
    )

  if (isError)
    return (
      <Alert type="error" message="Something went wrong. Please try again" />
    )

  if (!data) return <Alert message="There is no data available" />

  return (
    <>
      <DiffViewer questionSetDifference={data} />
    </>
  )
}

export interface IRiskChanges {
  policyId: string
}
