import React, { useState } from "react"
import {
  Row,
  Col,
  Alert,
  Button,
  Modal,
  Form,
  Input,
  notification,
  Space,
  Card
} from "antd"
import { IOpportunity } from "platform-client/types/opportunities"
import { isEmpty } from "ramda"
import { useMutation } from "react-query"
import { useHistory } from "react-router"
import usePlatform from "contexts/platform/use-platform"
import styled from "styled-components"
import moment from "moment"

import { VariantQuoteTable } from "./components/VariantQuoteTable"

const StyledRow = styled(Row)`
  background: #fff;
  padding: 16px 15px;
  margin-top: 15px;
`

type DuplicateRiskForm = {
  riskName?: string
  variantID: string | null
}

export const Variants: React.FC<IVariants> = (props) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const history = useHistory()

  const [form] = Form.useForm()

  const { opportunity, quote } = usePlatform()

  const duplicateRisk = useMutation(
    ({ variantID, riskName }: { variantID: string; riskName?: string }) =>
      opportunity.variantDuplicate(variantID, riskName)
  )

  const handleDuplicateRisk = ({ variantID, riskName }: DuplicateRiskForm) => {
    if (variantID === null) {
      notification.error({
        message: "Cannot duplicate risk, missing variantID."
      })
      return
    }

    duplicateRisk.mutateAsync({ variantID, riskName }).then(() => {
      props.refetchVariants()
      form.resetFields()
      setIsModalVisible(false)
    })
  }

  const nominateQuote = useMutation(
    (quoteID: string) => {
      return quote.nominateQuote(props.opportunity.id, quoteID)
    },
    {
      onSuccess: () => {
        props.refetchVariants()
      },
      onError: () =>
        notification.error({ message: "Unable to nominate quote." })
    }
  )

  const hasPurchasedQuote = props.opportunity.variants.some((variant) =>
    variant.quotes.some((quote) => quote.quoteState === "Purchased")
  )

  const handleCancel = () => {
    form.resetFields()
    setIsModalVisible(false)
  }

  const variants = props.opportunity.variants.sort((a, b) => {
    return moment(a.localDateCreated).isSameOrAfter(b.localDateCreated) ? 1 : -1
  })

  return (
    <>
      {!isEmpty(variants) ? (
        variants.map((variant, i) => (
          <>
            <Card
              key={`risk_version_${variant.id}`}
              title={`Version ${i + 1} ${
                variant.name ? `- ${variant.name}` : ``
              }`}
              extra={
                <Space size="small">
                  {!hasPurchasedQuote && props.opportunity.quoteType !== "MTA" && (
                    <Button
                      onClick={() => {
                        form.setFieldsValue({ variantID: variant.id })
                        setIsModalVisible(true)
                      }}
                    >
                      Duplicate Risk
                    </Button>
                  )}
                  {props.opportunity.quoteType === "MTA" &&
                    !!variant?.quotes?.length && (
                      <Button
                        onClick={() => {
                          history.push(
                            `/variant/riskchanges/${props.opportunity.id}/${variant.id}/${props.opportunity.referenceID}`
                          )
                        }}
                      >
                        See Risk changes
                      </Button>
                    )}
                  {!hasPurchasedQuote && (
                    <Button
                      type="primary"
                      onClick={() => {
                        history.push(
                          `/opportunities/${props.opportunity?.id}/variants/${
                            variant.id
                          }/edit?quoteType=${props.opportunity.quoteType || ""}`
                        )
                      }}
                    >
                      Edit
                    </Button>
                  )}
                </Space>
              }
              bodyStyle={isEmpty(variant.quotes) ? {} : { padding: "0" }}
              {...(i > 0 ? { style: { marginTop: "14px" } } : {})}
            >
              {isEmpty(variant.quotes) ? (
                <Alert
                  message={
                    "No quotes have been created yet as the questionnaire is incomplete. Edit the risk version and complete the questionnaire and generate quotes."
                  }
                />
              ) : (
                <VariantQuoteTable
                  quotes={variant.quotes}
                  nominateQuote={nominateQuote}
                />
              )}
            </Card>
          </>
        ))
      ) : (
        <StyledRow justify="start" align="middle">
          <Col span={24}>
            <Alert message="There are no variants. Please try again later or contact support." />
          </Col>
        </StyledRow>
      )}

      <Modal
        title="Create New Copy of Risk"
        onCancel={handleCancel}
        visible={isModalVisible}
        footer={[
          <Button onClick={handleCancel}>Cancel</Button>,
          <Button
            type="primary"
            loading={duplicateRisk.isLoading}
            onClick={form.submit}
            htmlType="submit"
          >
            Create Copy
          </Button>
        ]}
      >
        <Form
          onFinish={handleDuplicateRisk}
          form={form}
          layout="vertical"
          name="duplicate-variant"
          initialValues={{
            riskName: null,
            variantID: null
          }}
        >
          <Form.Item noStyle name="variantID">
            <Input type="hidden" />
          </Form.Item>
          <Form.Item
            rules={[
              {
                whitespace: true,
                message: "Please enter a name for this risk or leave blank"
              }
            ]}
            name="riskName"
            label="What do you want to call this Risk Version?"
          >
            <Input />
          </Form.Item>

          {duplicateRisk.isError && (
            <Alert
              type="error"
              message="Something went wrong. please try again later"
            />
          )}
        </Form>
      </Modal>
    </>
  )
}

export interface IVariants {
  refetchVariants: () => void
  opportunity: IOpportunity
}
