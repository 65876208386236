import { createContext, useContext } from "react"

export interface IOrgSettings {
  currencyCode: string
  currencySymbol: string
  timezone: string
  defaultCountryCode: string
  dateFormat: string
  fatZebraKey: string
  fatZebraIsSandbox: string
  fatZebraCssUrl: string
  fatZebraCssSignature: string
}

export const initialOrgSettingsState = {
  currencyCode: "GBP",
  currencySymbol: "",
  timezone: "GMT",
  defaultCountryCode: "",
  dateFormat: "",
  fatZebraKey: "",
  fatZebraIsSandbox: "false",
  fatZebraCssUrl: "",
  fatZebraCssSignature: ""
}

const OrgSettingsContext = createContext<IOrgSettings>(initialOrgSettingsState)

export const useOrgSettingsContext = (): IOrgSettings =>
  useContext(OrgSettingsContext)

export default OrgSettingsContext
