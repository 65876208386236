export const AVATARS_COLORS = [
  "#00AA55",
  "#009FD4",
  "#B381B3",
  "#939393",
  "#E3BC00",
  "#D47500",
  "#DC2A2A",
  "#373F51",
  "#008DD5",
  "#DFBBB1",
  "#F56476",
  "#E43F6F"
]

export const numberFromText = (text: string): number => {
  const charCodes = text
    .split("")
    .map((char: string) => char.charCodeAt(0))
    .join("")
  return parseInt(charCodes, 10)
}
