import { useEffect } from "react"
import { useHistory } from "react-router-dom"

export const useDefaultPage = (
  toWatch: number | undefined,
  toBe: string[],
  settings: {
    title?: string | unknown
    redirectTo?: string
    buttonText?: string
    status: string | number | undefined
  }
): void => {
  const history = useHistory()

  const checkedStatus =
    typeof settings.status === "number" ? settings.status.toString() : undefined

  const checkedTitle =
    typeof settings.title === "string" ? settings.title : undefined

  useEffect(() => {
    if (settings.title && toWatch && toBe.includes(toWatch.toString())) {
      history.replace(`/error`, {
        ...settings,
        title: checkedTitle,
        status: checkedStatus
      })
    }
  }, [toWatch, settings, toBe, history, checkedTitle, checkedStatus])
}
