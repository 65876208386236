import { useAsyncEffect } from "use-async-effect"
import ConfigurationClient from "contexts/configuration/client"
import { useSessionStorage } from "../../use-session-storage"

export const useApiInfo = (): IUseApiInformation => {
  const [apiInfo, setApiInfo] = useSessionStorage<IApiInformation | undefined>(
    "api_information",
    undefined
  )

  useAsyncEffect(
    async (isMounted) => {
      if (!apiInfo) {
        try {
          const _apiInfo = await ConfigurationClient.getApiInfo()
          if (isMounted()) {
            setApiInfo(_apiInfo)
          }
        } catch (e) {
          if (isMounted()) {
            setApiInfo(undefined)
          }
        }
      }
    },
    [apiInfo]
  )

  return {
    apiInfo
  }
}

interface IUseApiInformation {
  apiInfo: IApiInformation | undefined
}

export interface IApiInformation {
  baseUrl: string
  environmentLabel: string
  instanceName: string
  orgInfo: orgInfo
}

export type orgInfo = {
  organizationID: number
  referenceId: string
  tenantReferenceId: string
}
