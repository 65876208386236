import { useState } from "react"
import { useAsyncEffect } from "use-async-effect"
import { TErrorState } from "models/errors"
import {
  errorValidationMessage,
  IErrorState
} from "utils/pocketknife/error-validation-message"
import { TPolicyRenewalsBundle } from "models/policies/policyRenewals"
import useOpus from "contexts/opus/hooks/use-opus"

export const useGetPolicyRenewals = (
  policyID: string | undefined
): IPolicyHistory => {
  const [policyRenewals, setPolicyRenewals] = useState<
    TPolicyRenewalsBundle | undefined
  >(undefined)
  const [isError, setIsError] = useState<TErrorState>(undefined)
  const [isPolicyRenewalsLoading, setPolicyRenewalsLoading] =
    useState<boolean>(false)
  const { policyClient } = useOpus()

  useAsyncEffect(async () => {
    try {
      setPolicyRenewalsLoading(true)
      if (policyID) {
        const _renewals = await policyClient.getPolicyRenewals(policyID)

        if (_renewals) {
          setPolicyRenewals(_renewals)
          setIsError(undefined)
        }
      }
      setPolicyRenewalsLoading(false)
    } catch (error) {
      setPolicyRenewals(undefined)
      setIsError({
        errorNumber: error?.response?.status,
        validationMessage: errorValidationMessage(error)
      })
      setPolicyRenewalsLoading(false)
    }
  }, [policyID])

  return {
    policyRenewals,
    isError,
    isPolicyRenewalsLoading
  }
}

interface IPolicyHistory extends IErrorState {
  policyRenewals: TPolicyRenewalsBundle | undefined
  isPolicyRenewalsLoading: boolean
}
