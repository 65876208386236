import React, { useState, useEffect } from "react"
import { IPolicyTable } from "models/policies/policyTable"
import { AllPolicies } from "./view"
import { useGetPolicies } from "containers/policies/services/hooks/use-get-policies"
import { IConfigurationContext } from "contexts/configuration/context"

export const AllPoliciesV2: React.FunctionComponent<IAllPolicies> = (props) => {
  const [searchValue, setSearchValue] = useState<string | undefined | null>(
    undefined
  )
  const [isSearchLoading, setSearchLoading] = useState<boolean>(false)

  const { policies, arePoliciesLoading, isError } = useGetPolicies(searchValue)

  const [policiesData, setPoliciesData] = useState<IPolicyTable[] | undefined>()

  useEffect(() => {
    setPoliciesData(policies)
  }, [policies])

  return (
    <AllPolicies
      isPoliciesError={isError}
      isSearchLoading={isSearchLoading}
      arePoliciesLoading={arePoliciesLoading}
      searchValue={searchValue}
      setSearchLoading={setSearchLoading}
      setPoliciesData={setPoliciesData}
      setSearchValue={setSearchValue}
      policies={policiesData}
      configuration={props.configuration}
    />
  )
}

interface IAllPolicies {
  configuration: IConfigurationContext
}
