import React from "react"
import AddonInput, { IAddonProps } from "components/Console/CellContent/Addon"
import styled from "styled-components"
import { convertLocale } from "utils/pocketknife/currency-to-human-readable"

const StyledInputNumber = styled.label.attrs<{
  textAlignment?: string
  header?: boolean
}>((props) => ({
  textAlignment: props.textAlignment || "right",
  header: props.header || false
}))<{
  textAlignment?: string

  header?: boolean
}>`
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0 6px;
  font-size: ${(props) => (props.header ? "18px" : "14px")};
  font-weight: ${(props) => (props.header ? "700" : "500")};
  text-align: ${(props) => props.textAlignment};
`

const StyledInputNumberFocused = styled(StyledInputNumber)``

const CellContentMoney: React.FunctionComponent<ICellContentMoney> = ({
  value,
  currencyCode,
  textAlignment,
  header,
  addonProps
}) => {
  const [isFocused, setFocus] = React.useState<boolean>(false)

  const Formatter = (
    n: number,
    minPrecision?: number,
    maxPrecision?: number,
    locale = "en-GB"
  ) =>
    new Intl.NumberFormat(locale, {
      ...(currencyCode ? { style: "currency", currency: currencyCode } : {}),
      minimumFractionDigits: minPrecision || 2,
      maximumFractionDigits: maxPrecision || 2
    })
      .format(n)
      .replace(/^(\D+)/, "$1 ")

  function changeFocus(focusValue: boolean) {
    setFocus(focusValue)
  }

  const highPrecisionValue = Formatter(
    value,
    10,
    20,
    currencyCode ? convertLocale(currencyCode) : undefined
  )
  const lowPrecisionValue = Formatter(
    value,
    2,
    2,
    currencyCode ? convertLocale(currencyCode) : undefined
  )

  return isFocused ? (
    <StyledInputNumberFocused
      aria-label="money-label-high-accuracy"
      textAlignment={textAlignment}
      header={header}
      onMouseLeave={() => {
        setTimeout(function () {
          changeFocus(false)
        }, 1500)
      }}
    >
      {addonProps && (
        <AddonInput exclude={value < 0 ? ["-", "+"] : []} {...addonProps} />
      )}
      {highPrecisionValue}
    </StyledInputNumberFocused>
  ) : (
    <StyledInputNumber
      aria-label="money-label"
      textAlignment={textAlignment}
      header={header}
      onClick={() => changeFocus(true)}
    >
      {addonProps && (
        <AddonInput exclude={value < 0 ? ["-", "+"] : []} {...addonProps} />
      )}
      {lowPrecisionValue}
    </StyledInputNumber>
  )
}

interface ICellContentMoney {
  value: number
  currencyCode?: string | null
  textAlignment?: "left" | "right"
  addonLeft?: string
  header?: boolean
  addonProps?: IAddonProps
}

export default CellContentMoney
