import React, { ReactNode, useMemo } from "react"
import { Alert, Button, Card, Input, Space, Table } from "antd"
import { IQuestion, TAnswersData } from "platform-client/types/difference"
import { transformRiskRow } from "components/diffviewer/diffViewerInnerRow/helper/riskDisplayer"
import Column from "antd/lib/table/Column"
import styled from "styled-components"
import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons"
import Highlighted from "components/Highlighted"

const MIN_FILTER_CHARACTERS = 3

interface IAnswersProps {
  data?: TAnswersData
  isLoading: boolean
  isError: boolean
}

interface IAnswersTableData {
  key: string
  type: "Questionnaire" | "Page" | "Question"
  label: string
  value?: string | JSX.Element
  oldValue?: string
  children?: IAnswersTableData[]
}

const QuestionCellWrap = styled.span`
  overflow: hidden;
  display: block;
  color: rgba(0, 0, 0, 0.6);
`

const NonQuestionCell = styled.span`
  color: rgba(0, 0, 0, 0.4);
  font-weight: bold;
`

const QuestionCell: React.FC<{ value: ReactNode }> = ({ value }) => (
  <QuestionCellWrap>{value}</QuestionCellWrap>
)

export const Answers: React.FC<IAnswersProps> = ({
  data,
  isError,
  isLoading
}) => {
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([])
  const [questionFilterText, setQuestionFilterText] = React.useState("")
  const [isFiltering, setIsFiltering] = React.useState(false)
  const [preFilterRowKeyState, setPreFilterRowKeyState] = React.useState<
    string[]
  >([])

  const collapseAll = () => setExpandedRowKeys([])
  const expandAll = () => setExpandedRowKeys(allRowKeys)

  const { tableData, allRowKeys } = useMemo(
    () => prepareTableData(data),
    [data]
  )

  React.useEffect(() => {
    if (!isFiltering && questionFilterText.length >= MIN_FILTER_CHARACTERS) {
      setPreFilterRowKeyState(expandedRowKeys)
      setExpandedRowKeys(allRowKeys)
      setIsFiltering(true)
    }
    if (isFiltering && questionFilterText.length < MIN_FILTER_CHARACTERS) {
      setExpandedRowKeys(preFilterRowKeyState)
      setPreFilterRowKeyState([])
      setIsFiltering(false)
    }
  }, [
    questionFilterText,
    isFiltering,
    setIsFiltering,
    setExpandedRowKeys,
    expandedRowKeys,
    preFilterRowKeyState,
    allRowKeys
  ])

  const filteredTableData = React.useMemo(() => {
    if (!isFiltering) return tableData

    const lowerQuestionFilterText = questionFilterText.toLowerCase()

    const newData = tableData
      .map((questionSet) =>
        Object.assign({}, questionSet, {
          children: questionSet.children
            ?.map((questionSetPage) =>
              Object.assign({}, questionSetPage, {
                children: questionSetPage.children
                  ?.filter(
                    (question) =>
                      question.label
                        .toLowerCase()
                        .indexOf(lowerQuestionFilterText) !== -1
                  )
                  .map((question) =>
                    Object.assign({}, question, {
                      label: (
                        <Highlighted
                          text={question.label}
                          search={questionFilterText}
                        />
                      )
                    })
                  )
              })
            )
            .filter(
              (questionSet) =>
                questionSet.children !== undefined &&
                questionSet.children?.length > 0
            )
        })
      )
      .filter(
        (questionSet) =>
          questionSet.children !== undefined && questionSet.children?.length > 0
      )

    return newData
  }, [tableData, questionFilterText, isFiltering])

  if (isError && !isLoading)
    return (
      <Alert
        message="Couldn't retrieve answers, please try again later"
        type="info"
        showIcon
      />
    )

  return (
    <Space
      direction="vertical"
      size="middle"
      style={{ width: "100%", maxWidth: "1200px", display: "flex" }}
    >
      <Card
        bodyStyle={{
          padding: "0 16px 16px",
          backgroundColor: "#fafafa"
        }}
        loading={isLoading}
      >
        <Table
          pagination={false}
          sticky={{ offsetHeader: 60 }}
          size="middle"
          dataSource={filteredTableData}
          summary={() => (
            <Table.Summary fixed="top">
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  <Input
                    placeholder="Search for a question (min 3 chars)"
                    value={questionFilterText}
                    onChange={(e) => setQuestionFilterText(e.target.value)}
                    allowClear
                  />
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1} align="right">
                  <Space>
                    <Button icon={<PlusSquareOutlined />} onClick={expandAll}>
                      Expand All
                    </Button>
                    <Button
                      icon={<MinusSquareOutlined />}
                      onClick={collapseAll}
                    >
                      Collapse All
                    </Button>
                  </Space>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
          expandable={{
            expandedRowKeys: expandedRowKeys,
            expandRowByClick: true,
            onExpandedRowsChange: (expandedRows) =>
              setExpandedRowKeys(expandedRows as string[])
          }}
          showHeader={true}
        >
          <Column
            title="Question"
            dataIndex="label"
            width="50%"
            render={(value: ReactNode, record: IAnswersTableData) => {
              if (record.type !== "Question")
                return <NonQuestionCell>{value}</NonQuestionCell>
              return <QuestionCell value={value} />
            }}
          />
          <Column title="Answer" dataIndex="value" />
        </Table>
      </Card>
    </Space>
  )
}

function getNonQuestionKeys(data: IAnswersTableData[]): string[] {
  return data.flatMap((item) =>
    item.children === undefined || item.type === "Page"
      ? [item.key]
      : [item.key, ...getNonQuestionKeys(item.children)]
  )
}

function processQuestionSet({
  key,
  label,
  questionPages
}: {
  key: string
  label: string
  questionPages: Record<string, IQuestion[]>
}): IAnswersTableData {
  return {
    key,
    label,
    value: "",
    type: "Questionnaire",
    children: Object.entries(questionPages).map(
      ([pageKey, questions], index) => ({
        key: [key, pageKey].join("_"),
        label: `Questionnaire Page ${index + 1}`,
        type: "Page",
        value: "",
        children: questions.map((question) => processQuestionRow(question, key))
      })
    )
  }
}

function prepareTableData(data?: TAnswersData | null): {
  tableData: IAnswersTableData[]
  allRowKeys: string[]
} {
  const allRowKeys: string[] = []
  const tableData: IAnswersTableData[] = []

  if (data === undefined || data === null) return { allRowKeys, tableData }

  // Process core data first
  data.core.map((questionSet) =>
    tableData.push(
      processQuestionSet({
        key: questionSet.questionsetReferenceID,
        label: "Core Questionnaire",
        questionPages: questionSet.questionPages
      })
    )
  )
  data.exposures?.map((exposure) =>
    exposure.questionSets.map((questionSet) =>
      tableData.push(
        processQuestionSet({
          key: [exposure.exposureID, questionSet.questionsetReferenceID].join(
            "_"
          ),
          label: `Exposure Questionnaire (${exposure.exposureReferenceID})`,
          questionPages: questionSet.questionPages
        })
      )
    )
  )

  return {
    allRowKeys: getNonQuestionKeys(tableData),
    tableData
  }
}

function processChildQuestionFields({
  key,
  label,
  questions
}: {
  key: string
  label: string
  questions: IQuestion[]
}): IAnswersTableData {
  return {
    key,
    label,
    value: "",
    type: "Questionnaire",
    children: questions.map((question) => processQuestionRow(question, key))
  }
}

function processQuestionRow(
  question: IQuestion,
  keyPrefix: string
): IAnswersTableData {
  return {
    key: [keyPrefix, question.questionReferenceID].join("_"),
    label: question.questionText?.question || "",
    type: "Question",
    value: transformRiskRow(
      question.riskValue,
      question.dataType,
      question.objectType,
      question.questionType
    ),
    ...(question.childObjects && question.childObjects.length > 0
      ? {
          children: question.childObjects.map((child, i) =>
            processChildQuestionFields({
              key: `${question.questionReferenceID}_${i}`,
              label: `Child Record ${i + 1}`,
              questions: child.childFields || []
            })
          )
        }
      : {})
  }
}
