import React from "react"
import { Card, List } from "antd"
import { LayoutCardLoader, LayoutCardEmpty } from "components"
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons"
// TODO needs some organising
import { IProductItemOpus } from "models/quotes/productOpus"
import { QuoteState } from "platform-client/types/quote"

const CARD_TITLE = "Sections of cover"

const IncludedIcon = (
  <CheckCircleTwoTone style={{ fontSize: 20 }} twoToneColor="#52c41a" />
)
const ExcludedIcon = (
  <CloseCircleTwoTone style={{ fontSize: 20 }} twoToneColor="#F5222D" />
)

export const QuoteCover: React.FC<IQuoteCover> = (props) => {
  if (props.isWorking) {
    return <LayoutCardLoader title={CARD_TITLE} />
  }

  if (
    props.quoteCover &&
    props.quoteCover.length > 0 &&
    props.quoteState !== "Open"
  ) {
    return (
      <Card title={CARD_TITLE}>
        <List
          dataSource={props.quoteCover.map((coverItem) => ({
            title: coverItem.description,
            content: coverItem.active ? IncludedIcon : ExcludedIcon
          }))}
          itemLayout="horizontal"
          size="small"
          renderItem={(
            item: { title: string; content: React.ReactNode },
            i
          ) => (
            <List.Item key={i}>
              <List.Item.Meta description={item.title} />
              <div>{item.content}</div>
            </List.Item>
          )}
        />
      </Card>
    )
  } else {
    const quoteOpenText =
      props.quoteState !== "Open"
        ? undefined
        : "Summary not available for open quotes."
    return <LayoutCardEmpty emptyText={quoteOpenText} title={CARD_TITLE} />
  }
}

export interface IQuoteCover {
  isWorking: boolean
  quoteState: QuoteState
  quoteCover?: IProductItemOpus[]
}
