import { Reports } from "./"
import { FileTextOutlined } from "@ant-design/icons"

export const REPORTS_ROUTES = [
  {
    path: "/reports/:subpage?",
    component: Reports
  }
]

export const REPORTS_NAVIGATION = [
  {
    title: "Reports",
    path: "/reports",
    submenu: false,
    icon: <FileTextOutlined />,
    iconActive: <FileTextOutlined />
  }
]
