import { useState, useRef, useEffect, useCallback } from "react"
import useOpus from "contexts/opus/hooks/use-opus"
import { notification } from "antd"

export const useEmailReceipt = (
  policyID: string | undefined
): IUseSetPolicyRenewals => {
  const [isEmailing, setIsEmailing] = useState(false)
  const [isError, setIsError] = useState(false)

  const isMounted = useRef(true)
  const { policyClient } = useOpus()

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const emailReceipt = useCallback(
    async (paymentId, emailAddress) => {
      if (isEmailing) return

      setIsEmailing(true)

      try {
        if (policyID) {
          await policyClient.emailTransactionReceipt(
            policyID,
            paymentId,
            emailAddress
          )
          notification.success({
            message:
              "An email has been sent to the customer with the transaction receipt attached."
          })
        }
      } catch (error) {
        setIsError(true)
        notification.error({
          message:
            "An error occurred when attempting to email the customer a copy of the transaction receipt. Please try again, or contact support."
        })
      }

      setIsEmailing(false)

      if (isMounted.current) setIsEmailing(false)
    },
    [isEmailing, policyClient, policyID]
  )

  return { emailReceipt, isEmailing, isError }
}

interface IUseSetPolicyRenewals {
  emailReceipt: (paymentId: string, emailAddress: string) => Promise<void>
  isEmailing: boolean
  isError: boolean
}
