import React from "react"
import {
  Button,
  DatePicker,
  Modal,
  notification,
  Space,
  Typography
} from "antd"
import { validateDate } from "./helpers"
// Requires antd moment dep due to type mismatch
// with our imported version.
import moment, { Moment } from "moment"
import { useMutation } from "react-query"
import usePlatform from "contexts/platform/use-platform"
import {
  ICreateMTAOpportunityRequest,
  ICreateOpportunityResponse
} from "platform-client/types"
import { AxiosError } from "axios"
import { useHistory } from "react-router"
import { useUserContext } from "contexts/user/context"

const { Text } = Typography

const PolicyAdjustment: React.FC<IPolicyAdjustmentProps> = ({
  policyID,
  ...props
}) => {
  const platform = usePlatform()
  const { professionalIndemnityInsuranceExpired } = useUserContext()
  const history = useHistory()
  const [visible, setVisible] = React.useState<boolean>(false)
  const [error, setError] = React.useState<string>("")
  const [effectiveStartDate, setEffectiveStartDate] = React.useState<Moment>(
    moment()
  )

  const createMTAOpportunity = useMutation<
    ICreateOpportunityResponse,
    AxiosError,
    ICreateMTAOpportunityRequest
  >((createMTAOpportunityRequest) =>
    platform.opportunity.createMTAOpportunity(
      policyID,
      createMTAOpportunityRequest
    )
  )

  const mutateOpportunity = (effectiveStartDateTime: string) => {
    if (professionalIndemnityInsuranceExpired === true) return

    createMTAOpportunity
      .mutateAsync({
        openSession: true,
        effectiveStartDateTime
      })
      .then((response) => {
        setVisible(false)
        notification.success({
          message: "Opportunity created. Redirecting..."
        })
        setTimeout(() => {
          history.push(`/opportunity/${response.opportunity.id}`)
        }, 2000)
      })
      .catch((error) => {
        notification.error({
          message:
            "Unable to create MTA opportunity. Please try again or contact support."
        })
      })
  }

  const handleSubmit = () => {
    const newEffectiveStartDate = effectiveStartDate.format(
      "YYYY-MM-DDT00:00:00"
    )
    const isDateValid = validateDate(
      newEffectiveStartDate,
      props.coverStartDate,
      props.coverEndDate
    )

    if (isDateValid) {
      setError(() => {
        mutateOpportunity(newEffectiveStartDate)
        return ""
      })
    } else {
      setError("Start date must be within 30 days")
    }
  }

  return (
    <>
      <Button
        disabled={professionalIndemnityInsuranceExpired === true}
        onClick={() => setVisible(true)}
        loading={createMTAOpportunity.isLoading}
      >
        Adjust
      </Button>
      <Modal
        title="Policy adjustment"
        visible={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button
            disabled={professionalIndemnityInsuranceExpired === true}
            key="create-adjustment"
            type="primary"
            loading={createMTAOpportunity.isLoading}
            onClick={handleSubmit}
          >
            Create adjustment
          </Button>,
          <Button
            key="cancel-adjustment"
            type="default"
            onClick={() => setVisible(false)}
          >
            Cancel
          </Button>
        ]}
      >
        <p>
          Please select the effective start date for the adjustment. You will be
          able to change this later. On create you will be taken to a new
          adjustment opportunity.
        </p>
        <Space direction="vertical">
          <Space direction="horizontal" align="baseline">
            <p>Effective start date:</p>
            <DatePicker
              value={effectiveStartDate}
              onChange={(value) => value && setEffectiveStartDate(value)}
            />
          </Space>
          <Text type="danger">{error}</Text>
        </Space>
      </Modal>
    </>
  )
}

interface IPolicyAdjustmentProps {
  policyID: string
  coverEndDate: string | null
  coverStartDate: string | null
}

export default PolicyAdjustment
