import React from "react"
import { LayoutPageHeader, LayoutPageContent } from "components"
import { Layout, Card, Empty } from "antd"
import { useGetAppConfig } from "contexts/configuration/hooks/use-get-app-config"
import { FileTextTwoTone } from "@ant-design/icons"
import { Link } from "react-router-dom"
import {
  numberFromText,
  AVATARS_COLORS
} from "utils/pocketknife/number-from-text"
import styled from "styled-components"
import { useConfigurationContext } from "contexts/configuration/context"
const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const CardStyled = styled(Card)`
  width: 436px;
  margin-left: 25px;
  margin-bottom: 25px;
  &:first-child {
    margin-left: 0;
  }
  &:nth-child(4n) {
    margin-left: 0;
  }
`
const CardStyledAvatar = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  font-weight: bold;
  align-items: center;
  color: #fff;
  background-color: ${(props: { avatarColor: string }) =>
    props.avatarColor || "#f0f2f5"};
`

const { Meta } = Card

export const ChooseQuote: React.FunctionComponent = () => {
  const configuration = useConfigurationContext()
  const { applicationConfig } = useGetAppConfig()

  const quoteCoverTypes = applicationConfig?.activeLinesOfBusiness
    .map((activeLine) => {
      if (activeLine.isActive) {
        return activeLine.coverTypes.map((coverType) => ({
          ...coverType,
          ...{ id: activeLine.id, productType: activeLine.productType }
        }))
      }
      return null
    })
    .flat()
    .filter((item) => item)

  return (
    <Layout>
      <LayoutPageHeader
        avatar={{
          icon: <FileTextTwoTone />,
          style: { background: "transparent", marginRight: 5 }
        }}
        ghost={false}
        title={"Choose Quote Product"}
      />
      <LayoutPageContent transparent>
        {quoteCoverTypes ? (
          <CardWrapper>
            {quoteCoverTypes.map((cover, i) => {
              if (cover) {
                const avatarColorNumber =
                  numberFromText(cover.label.substring(0, 2)) %
                  AVATARS_COLORS.length

                let quoteUrl

                if (configuration.flags.platformVersion === "v3") {
                  if (
                    configuration.products?.[cover.productReferenceId]
                      ?.newQuoteType === "digitalunderwriting"
                  ) {
                    quoteUrl = `/symphony/new/${cover.id}`
                  } else {
                    quoteUrl = `/create-quote/${cover.productReferenceId}`
                  }
                } else {
                  if (cover.productType === "Hybrid") {
                    quoteUrl = `/symphony/new/${cover.id}/${cover.productReferenceId}`
                  } else {
                    quoteUrl = `/opus/new/${cover.productReferenceId}/${cover.name}`
                  }
                }

                let productTitle = cover.label
                if (cover.underwriter) {
                  productTitle = `${cover.label} (${cover.underwriter})`
                }

                return (
                  <Link key={cover.name + i} to={quoteUrl}>
                    <CardStyled actions={[<>Select the product</>]} hoverable>
                      <Meta
                        avatar={
                          <CardStyledAvatar
                            avatarColor={AVATARS_COLORS[avatarColorNumber]}
                          >
                            {cover.label.substring(0, 2).toUpperCase()}
                          </CardStyledAvatar>
                        }
                        title={productTitle}
                        description={cover.desc}
                      />
                    </CardStyled>
                  </Link>
                )
              } else {
                return null
              }
            })}
          </CardWrapper>
        ) : (
          <Empty description="There is currently no active products to select for quotation." />
        )}
      </LayoutPageContent>
    </Layout>
  )
}
