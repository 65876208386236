import styled from "styled-components"
import { Alert, Spin } from "antd"

interface INoteListProps {
  isLoading?: boolean
  isEmpty?: boolean
  isError?: boolean
  children: React.ReactNode
}

const StyledNotesContainer = styled.div`
  & .note {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const NoteList: React.FC<INoteListProps> = (props) => {
  return (
    <Spin spinning={props.isLoading}>
      {!props.isLoading && props.isEmpty && !props.isError && (
        <Alert message="There are no notes yet" type="info" showIcon />
      )}
      {props.isError && !props.isLoading && (
        <Alert message="Unable to load notes, please try again" type="error" />
      )}
      <StyledNotesContainer>{props.children}</StyledNotesContainer>
    </Spin>
  )
}
