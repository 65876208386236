import React from "react"
import Row from "components/Console/Row"
import CellContentText from "components/Console/CellContent/Text"
import { Table } from "components/Console/Table"
import { IRiskScore } from "platform-client/types"

const RiskScores: React.FC<IRiskScoresProps> = ({
  riskScores
}): JSX.Element => {
  return (
    <Table.HalfTable
      reference={"Risk scores"}
      startExpanded={true}
      firstRowValues={[]}
      summaryValues={[]}
    >
      {riskScores !== null && (
        <>
          {riskScores.map((rs) => {
            return (
              <Row
                type="halftable"
                values={[
                  <CellContentText align="right">{rs.name}</CellContentText>,
                  <CellContentText align="right">{rs.score}</CellContentText>,
                  null
                ]}
              />
            )
          })}
        </>
      )}
    </Table.HalfTable>
  )
}

export interface IRiskScoresProps {
  riskScores: IRiskScore[]
}

export default RiskScores
