export const buildLink = ({
  entityID,
  entityReferenceID,
  entityType,
  quoteId
}: {
  entityID?: string
  entityReferenceID?: string
  entityType?: string
  quoteId?: string
}): { url: string; text: string; label: string } | undefined => {
  if (
    !entityID ||
    !entityReferenceID ||
    !entityType ||
    !quoteId ||
    quoteId === entityID
  ) {
    return
  }

  const { directory, label } = ((_entityType) => {
    switch (_entityType) {
      case "Opportunity":
        return {
          directory: "opportunity",
          label: "Opportunity:"
        }
      case "Quote":
        return {
          directory: "quote",
          label: "Quote:"
        }
      case "Policy":
        return {
          directory: "policy",
          label: "Policy:"
        }
      default:
        return {
          directory: "",
          label: ""
        }
    }
  })(entityType)

  return {
    url: `/${directory}/${entityID}`,
    text: entityReferenceID || "",
    label
  }
}
