import { Alert, Layout } from "antd"
import { LayoutLoading, LayoutPageContent } from "components"
import { IFrameContainer } from "components/IFrame"
import { useConfigurationContext } from "contexts/configuration/context"
import usePlatform from "contexts/platform/use-platform"
import { IGenericEvent, TAventusQuote } from "models/quotes/externalmodels"
import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import { useHistory, useParams } from "react-router"
import { Link } from "react-router-dom"

const NewQuickQuote = (): JSX.Element => {
  const { productID, questionSetReferenceID, quoteID } =
    useParams<ICreateQuickQuoteParams>()
  const history = useHistory<ICreateQuickQuoteLocationState>()
  const platform = usePlatform()
  const configContext = useConfigurationContext()
  const [sessionID, setSessionID] = useState<string | undefined>(
    history.location.state?.sessionID || undefined
  )

  useEffect(() => {
    const eventHandler = (event: IGenericEvent) => {
      const data = event.data

      if (data.event === TAventusQuote.completeQuickQuote) {
        history.push({
          pathname: `/quick-quote/${quoteID}/summary`
        })
      }

      // Should we have a goBack handler in here?
    }

    window.addEventListener("message", eventHandler)
    return () => window.removeEventListener("message", eventHandler)
  })

  useQuery(
    ["getActiveEditSessions", quoteID],
    () => platform.getActiveEditSessions(quoteID),
    {
      enabled: !sessionID,
      onSuccess: (activeSessions) => {
        if (activeSessions.length > 0) {
          const session = activeSessions.shift()
          setSessionID(session?.id)
        }
      }
    }
  )

  if (configContext) {
    if (sessionID) {
      return (
        <IFrameContainer
          id={`QuickQuote_${productID}_${questionSetReferenceID}`}
          url={`${configContext.symphony.baseUrl}/products/${productID}/quote/Vri/${questionSetReferenceID}?editSessionId=${sessionID}&token=${platform.token}&chrome=false`}
          title={"Create Quick Quote"}
          name={`qq_${productID}_${questionSetReferenceID}`}
        ></IFrameContainer>
      )
    } else {
      return (
        <Layout>
          <LayoutPageContent>
            <Alert
              message="No session available for this quick quote - please create a new one"
              type="error"
              banner={true}
              action={
                <Link to="/opportunity/create" className="ant-btn">
                  Create new quick quote
                </Link>
              }
            />
          </LayoutPageContent>
        </Layout>
      )
    }
  } else {
    return <LayoutLoading withWrapper />
  }
}

interface ICreateQuickQuoteParams {
  productID: string
  questionSetReferenceID: string
  quoteID: string
}
interface ICreateQuickQuoteLocationState {
  sessionID: string
}

export default NewQuickQuote
