import React from "react"
import { Tag, Dropdown, Menu } from "antd"
import { EUserStatus } from "../../services/hooks/use-get-users"
import { isEmpty, is, isNil } from "ramda"
import { MoreOutlined } from "@ant-design/icons"
import { IUser } from "contexts/opus/context"
import { ColumnsType } from "antd/lib/table"
import { DateDisplay } from "components/display/DateDisplay"

export const COLUMNS = (
  type:
    | {
        [key: string]: {
          color: string
          tag: string
        }
      }
    | undefined,
  modals: { modalDisable: (user: string) => void },
  actions: {
    userResendEmail: (user: string) => void
    getUser: (userID: string) => void
  },
  data: { currentUserID: string | undefined },
  hasPermission: boolean,
  setEditModal: (value: boolean) => void
): ColumnsType<IUser> => [
  {
    title: "Name",
    dataIndex: "fullName",
    key: "name",
    render: (name: string) => name,
    sorter: (a, b) => (a.fullName ? a.fullName.localeCompare(b.fullName) : 0)
  },
  {
    title: "Email",
    dataIndex: "username",
    key: "email",
    render: (email: string) => email
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status: string) => {
      const userType = type && type[status]
      return <Tag color={userType?.color}>{userType?.tag.toUpperCase()}</Tag>
    }
  },

  {
    title: "User role",
    dataIndex: "roles",
    key: "userRoles",
    render: (userRoles: { id: string; type: string; name: string }[]) => {
      if (!isEmpty(userRoles) && is(Array, userRoles) && !isNil(userRoles)) {
        return userRoles
          .map((user) => user?.name)
          .filter(String)
          .join(",")
      }
    }
  },
  {
    title: "Last login",
    dataIndex: "lastLogin",
    key: "lastLogin",
    render: (lastLogin: string) => <DateDisplay date={lastLogin} />
  },
  {
    title: "Action",
    key: "action",
    render: (user: { authID: string; status: string; id: string }) => {
      return hasPermission && data.currentUserID !== user.authID ? (
        <Dropdown
          overlay={
            <Menu>
              {EUserStatus.Disabled !== user.status && (
                <Menu.Item onClick={() => modals.modalDisable(user.id)}>
                  Disable
                </Menu.Item>
              )}
              <Menu.Item
                onClick={async () => {
                  await actions.getUser(user.id)
                  setEditModal(true)
                }}
              >
                Edit
              </Menu.Item>
              {EUserStatus.Invited === user.status && (
                <Menu.Item onClick={() => actions.userResendEmail(user.id)}>
                  Re-send email
                </Menu.Item>
              )}
            </Menu>
          }
        >
          <MoreOutlined
            style={{ cursor: "pointer", padding: "0 5px", fontSize: 19 }}
          />
        </Dropdown>
      ) : null
    }
  }
]
