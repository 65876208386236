import { Dispatch, SetStateAction, useState } from "react"

export const useReferQuoteModal = (): {
  visible: boolean
  content: string
  isLoading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  setContent: Dispatch<SetStateAction<string>>
  setVisible: Dispatch<SetStateAction<boolean>>
} => {
  const [content, setContent] = useState("")
  const [isLoading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)

  return {
    visible,
    content,
    isLoading,
    setLoading,
    setContent,
    setVisible
  }
}
