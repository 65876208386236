import { Button, Form } from "antd"
import { useFirebaseAuthContext } from "contexts/authorization/firebase-auth-contex"
import React from "react"

export const ResetPasswordComponent: React.FunctionComponent<IResetPasswordComponent> =
  (props) => {
    const [email, setEmail] = React.useState<string>("")
    const [forgottenPasswordEmailSent, setForgottenPasswordEmailSent] =
      React.useState<boolean>(false)

    const [form] = Form.useForm()

    const authContext = useFirebaseAuthContext()

    const sendForgottenPasswordEmail = () => {
      setForgottenPasswordEmailSent(false)

      authContext
        .sendForgottenPasswordEmail(email)
        .then(() => {
          setForgottenPasswordEmailSent(true)
        })
        .catch((error) => {
          //show the message & hide the fact an error occurred (ie email doesn't exist)
          setForgottenPasswordEmailSent(true)
        })
    }

    const showLoginForm = () => {
      setForgottenPasswordEmailSent(false)
      props.showLoginForm()
    }

    return (
      <>
        {!forgottenPasswordEmailSent && (
          <>
            <h3 className="title">Reset your password</h3>
            <Form
              layout="vertical"
              id="forgottenPassword"
              style={{ margin: "20px 0 0" }}
              form={form}
            >
              <Form.Item
                name="reset-password-email-address"
                label="Email address"
                rules={[{ required: true, message: "email is required" }]}
              >
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  placeholder="Your email address"
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Form.Item>

              <Button
                type="link"
                onClick={showLoginForm}
                style={{ paddingLeft: "0px" }}
              >
                Back to login
              </Button>

              <div className="buttons">
                <Button
                  type="primary"
                  size="large"
                  onClick={(e) => {
                    e.preventDefault()
                    form
                      .validateFields()
                      .then(() => sendForgottenPasswordEmail())
                  }}
                >
                  Send email
                </Button>
              </div>
            </Form>
          </>
        )}
        {forgottenPasswordEmailSent && (
          <div>
            <p>
              We've sent you an email. Click the link in the email to reset your
              password
            </p>
            <Button
              type="link"
              onClick={showLoginForm}
              style={{ paddingLeft: "0px" }}
            >
              Back to login
            </Button>
          </div>
        )}
      </>
    )
  }

interface IResetPasswordComponent {
  showLoginForm: () => void
}
