import React from "react"
import { VAllInvoices } from "./"
import { FileTextOutlined } from "@ant-design/icons"

export const INVOICES_ROUTES = [
  {
    path: "/invoices",
    component: VAllInvoices
  }
]

export const INVOICES_NAVIGATION = [
  {
    title: "Invoices",
    path: "/invoices",
    submenu: false,
    icon: <FileTextOutlined twoToneColor="#ccc" />,
    iconActive: <FileTextOutlined twoToneColor="#1890ff" />
  }
]
