import React from "react"
import { VAllUsers } from "./"
import { UserOutlined } from "@ant-design/icons"

export const USERS_ROUTES = [
  {
    path: "/users",
    component: VAllUsers
  }
]

export const USERS_NAVIGATION = [
  {
    title: "Users",
    path: "/users",
    submenu: false,
    permission: "usermanagement:maintain",
    groups: [],
    icon: <UserOutlined twoToneColor="#ccc" />,
    iconActive: <UserOutlined twoToneColor="#1890ff" />
  }
]
