import Row from "components/Console/Row"
import CellContentTitle from "components/Console/CellContent/Title"

import React from "react"
import { ClaimRow } from "./Row"
import { Spin } from "antd"
import styled from "styled-components"
import { IPolicyClaim } from "platform-client/types/claims"

export const ClaimTable: React.FC<IClaimTable> = (props) => {
  const CentreRow = styled.div`
    padding: 20px;
    background-color: #fff;
    text-align: center;
  `

  return (
    <>
      <Row
        type="subHeader"
        reference={<CellContentTitle>{"Claim Id"}</CellContentTitle>}
        values={[
          <>
            <CellContentTitle>{"Date of Claim"}</CellContentTitle>
            <CellContentTitle>{"Status"}</CellContentTitle>
          </>
        ]}
      />
      {props.isLoading && (
        <CentreRow>
          <Spin data-testid="spin" />
        </CentreRow>
      )}
      {props.claimSummary !== undefined &&
        props.claimSummary.length > 0 &&
        !props.isLoading &&
        props.claimSummary.map((cs) => {
          return (
            <ClaimRow
              claimSummary={cs}
              policyReferenceId={props.policyReferenceId}
            />
          )
        })}
      {(props.claimSummary === undefined || props.claimSummary.length < 1) &&
        !props.isLoading && <CentreRow data-testid="nodata">No data</CentreRow>}
    </>
  )
}

export type IClaimTable = {
  policyReferenceId: string
  claimSummary?: IPolicyClaim[]
  isLoading: boolean
}
