import { useCallback, useState } from "react"
import { TErrorState } from "models/errors"
import {
  errorValidationMessage,
  IErrorState
} from "utils/pocketknife/error-validation-message"
import { TPolicyPaymentPlan } from "models/policies/policyPaymentPlan"
import useOpus from "contexts/opus/hooks/use-opus"
import useAsyncEffect from "use-async-effect"
import { notification } from "antd"

export const useGetPolicyPaymentPlan = (
  policyID: string | undefined,
  refetchTransactions: React.Dispatch<React.SetStateAction<boolean>>
): IPaymentPlan => {
  const [data, setPaymentPlan] = useState<TPolicyPaymentPlan | undefined>(
    undefined
  )
  const [isError, setIsError] = useState<TErrorState>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const { policyClient } = useOpus()

  const getPaymentPlan = useCallback(async () => {
    try {
      if (policyID) {
        const _paymentPlan = await policyClient.getPolicyPaymentPlan(policyID)

        if (_paymentPlan) {
          setPaymentPlan(_paymentPlan)
          setIsError(undefined)
        }
      }
      setIsProcessing(false)
      setIsLoading(false)
    } catch (error) {
      setPaymentPlan(undefined)
      setIsError({
        errorNumber: error?.response?.status,
        validationMessage: errorValidationMessage(error)
      })
      setIsProcessing(false)
      setIsLoading(false)
    }
  }, [policyID, policyClient])

  useAsyncEffect(() => {
    if (policyID && data === undefined) {
      setIsLoading(true)
      getPaymentPlan()
    }
  }, [getPaymentPlan, policyID, data])

  const payScheduledPayment = async (scheduledPaymentID: string) => {
    try {
      setIsProcessing(true)
      await policyClient.payScheduledPayment(scheduledPaymentID)
      getPaymentPlan()
      refetchTransactions(true)
    } catch (e) {
      setIsProcessing(false)
      setIsError({
        errorNumber: e?.response?.status,
        validationMessage: errorValidationMessage(e)
      })
      notification.error({
        message: errorValidationMessage(e) as string
      })
    }
  }

  return {
    data,
    isError,
    isLoading,
    isProcessing,
    payScheduledPayment
  }
}

interface IPaymentPlan extends IErrorState {
  data: TPolicyPaymentPlan | undefined
  isLoading: boolean
  isProcessing: boolean
  payScheduledPayment: (scheduledPaymentID: string) => void
}

export default useGetPolicyPaymentPlan
