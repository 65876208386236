import React from "react"
import styled from "styled-components"
import { LayoutCardLoader, LayoutCardEmpty } from "components"
import { Card, List } from "antd"
import { IConfigurationContext } from "contexts/configuration/context"
import { IMoney } from "platform-client/types"
import { MoneyDisplay } from "components/display/MoneyDisplay"

const ListStyledFooter = styled.div`
  padding: 8px;
  display: flex;
  background-color: #fafafa;
  justify-content: space-between;
  font-weight: 600;
`

const CARD_TITLE = "Premium"

export const QuotePremium: React.FC<IQuotePremium> = (props) => {
  if (props.isWorking) {
    return <LayoutCardLoader title={CARD_TITLE} />
  }

  if (props.netPremium && props.grossPremium) {
    return (
      <Card title={CARD_TITLE}>
        <List
          itemLayout="horizontal"
          size="small"
          footer={
            <ListStyledFooter>
              <span>Total</span>
              <span>
                <MoneyDisplay value={props.grossPremium} />
              </span>
            </ListStyledFooter>
          }
        >
          <List.Item style={{ padding: 8 }}>
            <List.Item.Meta description="Base premium" />
            <div>
              <MoneyDisplay value={props.netPremium} />
            </div>
          </List.Item>

          <List.Item style={{ padding: 8 }}>
            {props.configuration.quote.showIPTLabel && props.iptPercentage ? (
              <List.Item.Meta
                description={`IPT ${props.iptPercentage * 100}%`}
              />
            ) : (
              <List.Item.Meta description="Total Tax" />
            )}

            <div>
              <MoneyDisplay value={props.taxTotal} />
            </div>
          </List.Item>

          {props.fees && (
            <List.Item style={{ padding: 8 }}>
              <List.Item.Meta description="Underwriting Fee" />
              <div>
                <MoneyDisplay value={props.fees} />
              </div>
            </List.Item>
          )}
        </List>
      </Card>
    )
  }

  return <LayoutCardEmpty title={CARD_TITLE} />
}

export interface IQuotePremium {
  configuration: IConfigurationContext
  isWorking: boolean
  // isLoaded: boolean
  netPremium?: IMoney
  grossPremium?: IMoney
  iptPercentage?: number
  taxTotal?: IMoney
  fees?: IMoney
}
