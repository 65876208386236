import React, { useRef } from "react"
import { CalendarOutlined } from "@ant-design/icons"
import { Button, DatePicker, Space } from "antd"
import PopupForm from "components/Console/CellContent/PopupForm"
import { TPlatformResponse } from "contexts/platform/client"
// Importing moment from Antd
// because it needs to be a specific version
// i don't really like this
import moment from "moment"

const CoverPeriod: React.FC<ICoverPeriodProps> = (props) => {
  const [visible, setVisible] = React.useState<boolean>(false)
  const _coverStartDateTime = useRef(props.coverStartDateTime)
  const _coverEndDateTime = useRef(props.coverEndDateTime)

  const [coverStartDateTime, setCoverStartDateTime] = React.useState(
    moment.utc(props.coverStartDateTime)
  )
  const [coverEndDateTime, setCoverEndDateTime] = React.useState(
    moment.utc(props.coverEndDateTime)
  )

  React.useEffect(() => {
    if (_coverStartDateTime.current !== props.coverStartDateTime) {
      _coverStartDateTime.current = props.coverStartDateTime
      setCoverStartDateTime(moment.utc(props.coverStartDateTime))
    }
    if (_coverEndDateTime.current !== props.coverEndDateTime) {
      _coverEndDateTime.current = props.coverEndDateTime
      setCoverEndDateTime(moment.utc(props.coverEndDateTime))
    }
  }, [
    _coverStartDateTime,
    props.coverStartDateTime,
    _coverEndDateTime,
    props.coverEndDateTime
  ])

  return (
    <PopupForm
      icon={<CalendarOutlined className="calendarIcon" />}
      visible={visible}
      setVisible={setVisible}
    >
      <Space direction="vertical" size={15}>
        <span>Please select cover start date:</span>
        <DatePicker
          value={coverStartDateTime}
          onChange={(value) => value && setCoverStartDateTime(value)}
          showTime={{ format: "HH:mm" }}
        />
        <span>Please select cover end date:</span>
        <DatePicker
          value={coverEndDateTime}
          onChange={(value) => value && setCoverEndDateTime(value)}
          showTime={{ format: "HH:mm" }}
        />
        <Button
          aria-label="save-cover-period"
          type="primary"
          onClick={() => {
            props.onCoverPeriodChange(
              props.componentID,
              coverStartDateTime.toISOString(),
              coverEndDateTime.toISOString()
            )
            setVisible(false)
          }}
        >
          Save
        </Button>
      </Space>
    </PopupForm>
  )
}

export default CoverPeriod

export interface ICoverPeriodProps {
  componentID: string
  coverStartDateTime: string | null
  coverEndDateTime: string | null
  onChange?: (response: TPlatformResponse) => void
  onCoverPeriodChange: (
    componentID: string,
    coverStartDateTime: string,
    coverEndDateTime: string
  ) => void
}
