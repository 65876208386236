export function required(value: unknown): boolean {
  if (Array.isArray(value)) {
    return value.length > 0
  }

  return (
    value !== null &&
    value !== "" &&
    value !== undefined &&
    doesContainAtLeast1LetterOrNumber(value)
  )
}

const doesContainAtLeast1LetterOrNumber = (value: unknown): boolean => {
  if (typeof value === "string") {
    return Boolean(value.replace(/\s/g, "").length)
  }

  return true
}
