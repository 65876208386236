import { useState, useRef, useEffect, useCallback } from "react"
import useOpus from "contexts/opus/hooks/use-opus"
import { notification } from "antd"

export const useResendTransactionalEmail = (): IUseResendTransactionalEmail => {
  const [isResending, setIsResending] = useState(false)
  const [isError, setIsError] = useState(false)

  const isMounted = useRef(true)
  const { policyClient } = useOpus()

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const resendTransactionalEmail = useCallback(
    async (transactionalEmailId, emailAddress) => {
      if (isResending) return

      setIsResending(true)

      try {
        if (transactionalEmailId) {
          const response = await policyClient.resendTransactionalEmail(
            transactionalEmailId,
            emailAddress
          )

          if (response === true) {
            notification.success({
              message: "Email sent successfully."
            })
          } else {
            notification.error({
              message:
                "An error occurred when attempting to re-send the email. Please try again, or contact support."
            })
            setIsError(true)
          }
        }
      } catch (error) {
        notification.error({
          message:
            "An error occurred when attempting to re-send the email. Please try again, or contact support."
        })
        setIsError(true)
      }

      setIsResending(false)

      if (isMounted.current) setIsResending(false)
    },
    [isResending, policyClient]
  )

  return { resendTransactionalEmail, isResending, isError }
}

interface IUseResendTransactionalEmail {
  resendTransactionalEmail: (
    transactionalEmailId: string,
    emailAddress: string
  ) => Promise<void>
  isResending: boolean
  isError: boolean
}
