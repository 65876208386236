import React from "react"
import { VSettings } from "."
import { DatabaseTwoTone } from "@ant-design/icons"

export const SETTINGS_ROUTES = [
  {
    path: "/settings",
    component: VSettings
  }
]

export const SETTINGS_NAVIGATION = [
  {
    title: "Settings",
    path: "/settings",
    submenu: false,
    icon: <DatabaseTwoTone twoToneColor="#ccc" />,
    iconActive: <DatabaseTwoTone twoToneColor="#1890ff" />
  }
]
