import { VAddClaim, VEditClaim } from "."

export const CLAIMS_ROUTES = [
  {
    path: "/claims/add",
    component: VAddClaim
  },
  {
    path: "/claims/:id/edit",
    component: VEditClaim
  }
]
