import React from "react"
import styled from "styled-components"
import { replaceArray } from "utils/pocketknife/replace-array"
import { isEmpty } from "ramda"

const StyledAddon = styled.span<{
  distance: number
}>`
  padding-right: ${(props) => props.distance}px;
`
export const Addon: React.FC<IAddonProps> = ({
  distance = 0,
  children,
  content,
  exclude
}): JSX.Element => {
  const parsedContent =
    !isEmpty(exclude) && content && exclude
      ? replaceArray(exclude, "", content)
      : content
  return (
    <StyledAddon distance={distance}>{parsedContent || children}</StyledAddon>
  )
}

export default Addon

export interface IAddonProps {
  children?: React.ReactNode
  distance?: number
  content?: string
  exclude?: string[]
}
