import * as React from "react"
import useOpus from "./use-opus"
import { useAsyncError } from "utils/hooks/use-catch-async-error"

export const useCreateUser = function (email: string): IUseCreateUser {
  const { userClient } = useOpus()
  const throwError = useAsyncError()

  const [userId, setUserId] = React.useState<string | undefined>(undefined)
  const [isCreatingUser, setIsCreatingUser] = React.useState<boolean>(false)

  React.useEffect(() => {
    ;(async function () {
      if (email && userClient && throwError) {
        setIsCreatingUser(true)

        try {
          const _user = await userClient.createUser(email)
          setUserId(_user?.id)
        } catch (error) {
          throwError(error)
        }

        setIsCreatingUser(false)
      }
    })()
  }, [email, throwError, userClient])

  return {
    userId,
    isCreatingUser
  }
}

export interface IUseCreateUser {
  userId?: string
  isCreatingUser?: boolean
}
