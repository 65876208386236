import React from "react"
import Row from "components/Console/Row"
import CellContentText from "components/Console/CellContent/Text"
import CellContentTitle from "../../CellContent/Title"
import CellContentMoney from "components/Console/CellContent/Money"
import CellContentTextInput from "../../CellContent/TextInput"
import LinkButton from "../../CellContent/Link"
import produce from "immer"
import { DeleteButton, SaveButton } from "../../CellContent/Button"
import { IMoney } from "models/underwriting"
import { StickyTable } from "components/Console/Table/StickyTable"
import CellContentMoneyInput from "components/Console/CellContent/MoneyInput"
import { TPlatformResponse } from "contexts/platform/client"
import useFee from "./useFees"
import FeeRow from "./FeeRow"
import { Nullable, SectionTypes, IFinanceFee } from "platform-client/types"
import { IOrgSettings } from "contexts/organisation/context"

const FeesAndFinalPremium: React.FC<IFeesAndTotals> = ({
  readOnly = false,
  startExpanded = false,
  ...props
}) => {
  const emptyFee = {
    name: "",
    id: "",
    referenceID: "",
    gross: {
      value: 0.0,
      currencyCode: props.organisationSettings?.currencyCode || "GBP"
    },
    net: {
      value: 0.0,
      currencyCode: props.organisationSettings?.currencyCode || "GBP"
    },
    order: 0,
    tax: {
      taxes: [],
      taxRuleID: "",
      taxRuleReferenceID: "",
      total: {
        value: 0.0,
        currencyCode: props.organisationSettings?.currencyCode || "GBP"
      }
    }
  }

  const [isAdding, setisAdding] = React.useState<boolean>(false)
  const [newFee, setNewFee] = React.useState<IFinanceFee>(emptyFee)

  const totalNetPremium = props.sections
    ? props.sections
        .map((s) => ("totalNetPremium" in s ? s.totalNetPremium?.value : 0))
        .reduce((prev, curr) => (prev ? prev : 0) + (curr ? curr : 0), 0)
    : 0

  const totalTax = props.sections
    ? props.sections
        .map((s) => ("totalTax" in s ? s.totalTax?.value : 0))
        .reduce((prev, curr) => (prev ? prev : 0) + (curr ? curr : 0), 0)
    : 0

  const totalGrossPremium = props.sections
    ? props.sections
        .map((s) => ("totalGrossPremium" in s ? s.totalGrossPremium?.value : 0))
        .reduce((prev, curr) => (prev ? prev : 0) + (curr ? curr : 0), 0)
    : 0

  const { addNewFee, updating } = useFee(
    newFee,
    props.editSessionId,
    props.onChange
  )

  const summary = {
    label: <CellContentText align="right">Final Premium</CellContentText>,
    value: props.totalPriceSectionWithFees ? (
      <CellContentMoney
        textAlignment="right"
        header={true}
        value={props.totalPriceSectionWithFees?.value}
        currencyCode={
          props.totalPriceSectionWithFees?.currencyCode ||
          props.organisationSettings?.currencyCode
        }
      ></CellContentMoney>
    ) : null
  }

  return (
    <StickyTable
      startExpanded={startExpanded}
      reference={props.reference}
      summary={summary}
    >
      <Row
        reference={<CellContentTitle>Premium Summary</CellContentTitle>}
        values={[
          null,
          null,
          <CellContentTitle align="right">Premium</CellContentTitle>,
          <CellContentTitle align="right">Total Taxes</CellContentTitle>,
          <CellContentTitle align="right">Final Premium</CellContentTitle>
        ]}
      />
      {props.sections !== null &&
        props.sections.map((section: SectionTypes, i) => {
          return (
            <Row
              name={section.name ? section.name : ""}
              key={section.name}
              reference={<CellContentText>{section.name}</CellContentText>}
              values={[
                null,
                null,
                "totalNetPremium" in section && section.totalNetPremium && (
                  <CellContentMoney
                    textAlignment="right"
                    value={section.totalNetPremium?.value}
                    currencyCode={
                      section.totalNetPremium?.currencyCode ||
                      props.organisationSettings?.currencyCode
                    }
                  />
                ),
                "totalTax" in section && section.totalTax && (
                  <CellContentMoney
                    textAlignment="right"
                    value={section.totalTax?.value}
                    currencyCode={
                      section.totalTax?.currencyCode ||
                      props.organisationSettings?.currencyCode
                    }
                  />
                ),
                "totalGrossPremium" in section && section.totalGrossPremium && (
                  <CellContentMoney
                    textAlignment="right"
                    value={section.totalGrossPremium?.value}
                    currencyCode={
                      section.totalGrossPremium?.currencyCode ||
                      props.organisationSettings?.currencyCode
                    }
                  />
                )
              ]}
            />
          )
        })}

      <Row
        name={"Total Premium from all sections"}
        key={"total-premium"}
        reference={
          <CellContentText strong={true}>
            {"Total Premium from all sections"}
          </CellContentText>
        }
        values={[
          null,
          null,
          <CellContentMoney
            textAlignment="right"
            value={totalNetPremium ? totalNetPremium : 0}
            currencyCode={props.organisationSettings?.currencyCode}
          />,
          <CellContentMoney
            textAlignment="right"
            value={totalTax ? totalTax : 0}
            currencyCode={props.organisationSettings?.currencyCode}
          />,
          <CellContentMoney
            textAlignment="right"
            value={totalGrossPremium ? totalGrossPremium : 0}
            currencyCode={props.organisationSettings?.currencyCode}
          />
        ]}
      />

      {props.fees?.map((fee: IFinanceFee) => {
        return (
          <FeeRow
            fee={fee}
            editSessionId={props.editSessionId}
            onChange={(response) => {
              if (typeof props.onChange === "function") {
                props.onChange(response)
              }
            }}
            readOnly={readOnly}
          />
        )
      })}
      {!isAdding && !readOnly && (
        <Row
          reference={
            <LinkButton
              onClick={() => {
                setisAdding(true)
                setNewFee(emptyFee)
              }}
            >
              Add Fee
            </LinkButton>
          }
        />
      )}
      {isAdding && !readOnly && (
        <Row
          updating={updating}
          reference={
            <CellContentTextInput
              value={newFee.name}
              name={"Fee Title"}
              placeholder={"Fee Title"}
              onChange={({ target: { value } }) =>
                setNewFee(
                  produce((draft) => {
                    if (draft) {
                      draft["name"] = value
                    }
                  })
                )
              }
            />
          }
          values={[
            null,
            null,
            null,
            null,
            <CellContentMoneyInput
              {...(props.organisationSettings?.currencyCode && {
                currencyCode: props.organisationSettings?.currencyCode
              })}
              watchValueChange={true}
              onChange={({ target: { value } }) =>
                setNewFee(
                  produce((draft) => {
                    if (draft) {
                      draft.net = {
                        value: value as unknown as number,
                        currencyCode:
                          draft.gross.currencyCode ||
                          props.organisationSettings?.currencyCode ||
                          "GBP"
                      }
                    }
                  })
                )
              }
              value={newFee.net ? newFee.net.value : 0.0}
            />
          ]}
          control={
            <>
              <SaveButton
                onClick={() => {
                  addNewFee()
                  setisAdding(false)
                }}
              />
              <DeleteButton
                onClick={() => {
                  setisAdding(false)
                }}
              />
            </>
          }
        />
      )}

      <Row
        name={"Total"}
        key={"final-premium"}
        reference={""}
        values={[
          null,
          null,
          null,
          <CellContentText strong={true}>Final Premium</CellContentText>,
          <CellContentMoney
            textAlignment="right"
            value={
              props.totalPriceSectionWithFees?.value
                ? props.totalPriceSectionWithFees?.value
                : 0
            }
            currencyCode={
              props.totalPriceSectionWithFees?.currencyCode ||
              props.organisationSettings?.currencyCode
            }
          ></CellContentMoney>
        ]}
      />
    </StickyTable>
  )
}

export interface IFeesAndTotals {
  reference: string
  editSessionId?: string
  fees: Nullable<IFinanceFee[]>
  sections: Nullable<SectionTypes[]>
  totalPriceSectionWithFees: Nullable<IMoney>
  onChange?: (response: TPlatformResponse) => void
  readOnly?: boolean
  startExpanded?: boolean
  organisationSettings?: IOrgSettings
}

export default FeesAndFinalPremium
