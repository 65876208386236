import { useHistory } from "react-router-dom"
import { getProduct } from "./client"
import { useAsyncEffect } from "use-async-effect"
import { useSessionStorage } from "../use-session-storage"
import { IMoney } from "models/quotes/money"

export const useGetProduct = (
  productReference: string,
  coverType: string
): IUseGetProduct => {
  const history = useHistory()

  const [product, setProduct] = useSessionStorage<IProduct | undefined>(
    `product-${productReference}-${coverType}`,
    undefined
  )

  useAsyncEffect(async () => {
    if (!product) {
      try {
        const _product = await getProduct(productReference, coverType)
        setProduct(_product)
      } catch (error) {
        history.replace(`/error`, {
          title: "Sorry, the quote product does not exist.",
          redirectTo: "/choose-quote",
          buttonText: "Back to Choose"
        })
      }
    }
  }, [productReference, coverType])

  return {
    product
  }
}

interface IUseGetProduct {
  product: IProduct | undefined
}

export interface IProduct {
  id: number
  name: string
  referenceID: string
  version: number
  quoteValidForDays: number
  productItems: IProductItem[]
  excessRange: IMoney[]
  compulsoryExcess: IMoney
  policyDuration: string
  gracePeriodDays: number
  additionalQuestionSets: IAdditionalQuestionSet[]
}

interface IAdditionalQuestionSet {
  referenceID: string
  type: string
}

interface IProductItem {
  id: number
  referenceID: string
  description: string
  productItemType: string
  displayValueType: IMoney
  min: number
  max: number
  displayValue: IMoney
  includedWithProduct: boolean
  optional: boolean
}
