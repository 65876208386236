import React, { useState } from "react"
import { RouteComponentProps, withRouter } from "react-router-dom"
import useConsole, { EditSessionState } from "services/Console/use-console"
import { Alert, Button, PageHeader, Tag } from "antd"
import styled from "styled-components"
import { QuoteStatus } from "models/quotes/quoteStatus"
import {
  ICompanyInRole,
  IPaymentPlan,
  PolicyDetailType
} from "platform-client/types"
import { Proposer, QuoteState } from "platform-client/types/quote"
import usePlatform from "contexts/platform/use-platform"
import { IUnderWriting } from "contexts/configuration/context"
import Console from "components/Console"
import { useQuery } from "react-query"
import { ReferQuoteModal } from "../../components/refer-quote-modal"
import { handleDownload } from "./filedownload"
import useOpus from "contexts/opus/hooks/use-opus"

const StyledConsoleHeader = styled(PageHeader)`
   {
    margin-bottom: 10px;
    background: #fafafa;
    border: 1px solid #ccc;
  }
`

const getSessionState = (state: EditSessionState): React.ReactNode => {
  switch (state) {
    case EditSessionState.ACTIVE:
      return <Tag color="lime">EDIT ACTIVE</Tag>
    case EditSessionState.COMPLETE:
      return <Tag color="green">COMPLETE</Tag>
    case EditSessionState.CONFIRMED:
      return <Tag color="blue">CONFIRMED</Tag>
    case EditSessionState.DISCARDED:
      return <Tag color="red">DISCARDED</Tag>
    case EditSessionState.REFERRED:
      return <Tag color="orange">REFERRED</Tag>
    case EditSessionState.PURCHASED:
      return <Tag color="green">PURCHASED</Tag>
    case EditSessionState.DECLINED:
      return <Tag color="red">DECLINED</Tag>
    default:
      return <Tag color="warning">INCOMPLETE</Tag>
  }
}

export const UnderwritingComponent: React.FunctionComponent<IQuoteUnderwritingSubView> =
  ({
    quoteId,
    quoteState,
    quoteStatus,
    quoteReferenceID,
    policyDetail,
    quoteCompanies,
    onQuoteChange,
    underWritingConfiguration,
    proposer,
    handleBindQuote,
    quotePaymentPlans,
    disableBind
  }) => {
    const {
      editSession,
      sessionState,
      syncEditSession,
      getActiveEditSessions,
      startEditSession,
      saveEditSession,
      handleSaveEditSession,
      discardEditSession,
      handleDiscardEditSession,
      permissions
    } = useConsole(
      quoteId,
      quoteState,
      quoteStatus,
      onQuoteChange,
      quotePaymentPlans
    )

    const { user } = usePlatform()
    const [visible, setVisible] = useState<boolean>(false)

    const { opusToken } = useOpus()

    const { data: currentUser, isLoading: isUserLoading } = useQuery(
      ["user"],
      user.getCurrentUser
    )

    const isOpenByOtherUser =
      sessionState === EditSessionState.ACTIVE &&
      editSession?.ownerID !== currentUser?.id

    const readOnly =
      isOpenByOtherUser ||
      [
        EditSessionState.NOTSTARTED,
        EditSessionState.COMPLETE,
        EditSessionState.CONFIRMED,
        EditSessionState.DISCARDED,
        EditSessionState.REFERRED,
        EditSessionState.DECLINED,
        EditSessionState.PURCHASED
      ].includes(sessionState)

    const activePolicyDetail =
      EditSessionState.ACTIVE === sessionState
        ? editSession?.policyDetail
        : policyDetail

    const endorsements = activePolicyDetail?.endorsements || []
    const excesses = activePolicyDetail?.excesses || []
    const sections = activePolicyDetail?.sections || []
    const fees = activePolicyDetail?.fees || []

    const sessionStateActions = (state: EditSessionState): React.ReactNode => {
      switch (state) {
        case EditSessionState.ACTIVE:
          return [
            <Button
              key="2"
              danger
              loading={discardEditSession.isLoading}
              onClick={() => handleDiscardEditSession()}
              disabled={isOpenByOtherUser}
            >
              Discard changes
            </Button>,
            <Button
              onClick={() => setVisible(true)}
              loading={saveEditSession.isLoading}
              type="primary"
              disabled={isOpenByOtherUser}
            >
              Save changes
            </Button>,
            <Button
              type="primary"
              onClick={() => handleDownload("rating.txt", quoteId, opusToken)}
            >
              View Rating
            </Button>
          ]
        case EditSessionState.COMPLETE:
          return [
            <Button
              loading={
                startEditSession.isLoading || startEditSession.isFetching
              }
              onClick={() => startEditSession.refetch()}
            >
              Edit quote
            </Button>,
            <Button
              type="primary"
              onClick={() => handleDownload("rating.txt", quoteId, opusToken)}
            >
              View Rating
            </Button>
          ]
        case EditSessionState.CONFIRMED:
          return [
            <Button
              loading={
                startEditSession.isLoading || startEditSession.isFetching
              }
              onClick={() => startEditSession.refetch()}
            >
              Edit quote
            </Button>,
            <Button
              type="primary"
              onClick={() => handleDownload("rating.txt", quoteId, opusToken)}
            >
              View Rating
            </Button>
          ]
        case EditSessionState.PURCHASED:
        case EditSessionState.DECLINED:
          return [
            <Button
              type="primary"
              disabled={state === EditSessionState.DECLINED}
              onClick={() => handleDownload("rating.txt", quoteId, opusToken)}
            >
              View Rating
            </Button>
          ]
        case EditSessionState.DISCARDED:
        case EditSessionState.REFERRED:
          return [
            <Button type="primary" onClick={() => startEditSession.refetch()}>
              Edit quote
            </Button>,
            <Button
              type="primary"
              onClick={() => handleDownload("rating.txt", quoteId, opusToken)}
            >
              View Rating
            </Button>
          ]
        default:
          return [
            <Button
              type="primary"
              loading={
                getActiveEditSessions.isFetching ||
                startEditSession.isFetching ||
                isUserLoading
              }
              onClick={() => startEditSession.refetch()}
            >
              Edit quote
            </Button>,
            <Button
              type="primary"
              onClick={() => handleDownload("rating.txt", quoteId, opusToken)}
            >
              View Rating
            </Button>
          ]
      }
    }

    return (
      <div className="console-wrapper">
        <StyledConsoleHeader
          ghost={false}
          subTitle={getSessionState(sessionState)}
          extra={sessionStateActions(sessionState)}
        />

        {readOnly && (
          <Alert
            type="warning"
            message={
              isOpenByOtherUser
                ? "This session is currently being worked on by someone else. You will not be able to make any edits until that session is completed"
                : "The console is in a read-only state. Any changes made will not persist."
            }
            style={{ marginBottom: "10px" }}
          />
        )}
        <Console
          editSessionID={editSession && editSession.id}
          endorsements={endorsements}
          excesses={excesses}
          sections={sections}
          sectionsConfiguration={underWritingConfiguration}
          fees={fees}
          policyDetail={activePolicyDetail}
          companies={quoteCompanies}
          onChange={syncEditSession}
          readOnly={readOnly}
          permissions={permissions}
        />
        <ReferQuoteModal
          headerText="Notes"
          visible={visible}
          isLoading={saveEditSession.isLoading}
          onSubmit={(content: string) => {
            handleSaveEditSession(content).then(() => {
              setVisible(false)
            })
          }}
          onCancel={() => {
            setVisible(false)
          }}
        />
      </div>
    )
  }

export const QuoteUnderwritingSubView = withRouter(UnderwritingComponent)

export interface IQuoteUnderwritingSubView extends RouteComponentProps {
  quoteId: string
  quoteState: QuoteState
  quoteStatus: QuoteStatus
  quoteReferenceID: string
  policyDetail?: PolicyDetailType
  quotePaymentPlans?: IPaymentPlan[]
  onQuoteChange?: () => void
  underWritingConfiguration?: IUnderWriting
  quoteCompanies?: ICompanyInRole[]
  quoteBindConfirmation?: React.ReactNode
  proposer?: Proposer
  handleBindQuote?: (paymentPlan: IPaymentPlan, callback?: () => void) => void
  disableBind?: boolean
}
