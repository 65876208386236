import React from "react"
import { Button } from "antd"
import { TextCellAlignmentType } from "../Title"
import styled from "styled-components"

const StyledButton = styled(Button)<{ align?: TextCellAlignmentType }>`
  width: 100%;
  text-align: ${(props) => props.align || "left"};
  font-size: 14px;
  text-decoration: underline;
  padding: 0 6px;
`

const CellContentLinkButton: React.FC<IExpandButton> = ({
  onClick,
  children,
  align,
  ...props
}): JSX.Element => (
  <StyledButton {...props} type="link" align={align} onClick={onClick}>
    {children}
  </StyledButton>
)

interface IButton {
  onClick?: () => void
  align?: TextCellAlignmentType
  children: React.ReactNode
}

type IExpandButton = IButton

export default CellContentLinkButton
