import * as React from "react"
import { Collapse } from "../../../Collapse"
import Row from "../../Row"
import CellContentTitle from "../../CellContent/Title"
import CellContentText from "../../CellContent/Text"
import { ExpandButton } from "../../CellContent/Button"

const CollapsedRow = (props: IFullTableHeaderRow) => (
  <Row
    type="sticky"
    toggle={<ExpandButton expanded={false} onClick={props.toggle} />}
    reference={
      <CellContentTitle header={true}>{props.reference}</CellContentTitle>
    }
    values={[
      null,
      null,
      null,
      null,
      props.summary ? (
        <div>
          <CellContentText>{props.summary.label}</CellContentText>
          <CellContentText>{props.summary.value}</CellContentText>
        </div>
      ) : null
    ]}
  />
)

const ExpandedRow = (props: IFullTableHeaderRow) => (
  <Row
    type="sticky"
    toggle={<ExpandButton expanded={true} onClick={props.toggle} />}
    reference={
      <CellContentTitle header={true}>{props.reference}</CellContentTitle>
    }
    values={[
      null,
      null,
      null,
      null,
      props.summary ? (
        <div>
          <CellContentText>{props.summary.label}</CellContentText>
          <CellContentText>{props.summary.value}</CellContentText>
        </div>
      ) : null
    ]}
  />
)

export const StickyTable: React.FunctionComponent<IFullTable> = (props) => {
  return (
    <Collapse
      startExpanded={props.startExpanded}
      collapsedPanel={
        <CollapsedRow reference={props.reference} summary={props.summary} />
      }
      onCollapse={props.onCollapse}
      expandedPanel={
        <ExpandedRow reference={props.reference} summary={props.summary} />
      }
    >
      {props.meta && (
        <Row
          type="meta"
          reference={<CellContentText>{props.meta}</CellContentText>}
        />
      )}
      {props.children}
    </Collapse>
  )
}

export interface IFullTable {
  reference: React.ReactNode
  summary?: {
    label: React.ReactNode
    value: React.ReactNode
  }
  meta?: string
  startExpanded?: boolean
  onCollapse?: (isCollapsed: boolean) => void
}

export interface IFullTableHeaderRow {
  reference: React.ReactNode
  summary?: {
    label: React.ReactNode
    value: React.ReactNode
  }
  toggle?: () => void
}
