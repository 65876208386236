import { Alert, notification, Table, Tag } from "antd"
import { IOpusUser, UserStatus } from "platform-client/types"
import usePlatform from "contexts/platform/use-platform"
import React from "react"
import { useQuery } from "react-query"
import { RouteComponentProps, withRouter } from "react-router"
import { AxiosError } from "axios"

const states: Record<UserStatus, { tag: string; color: string }> = {
  Active: { tag: "Active", color: "green" },
  Stub: { tag: "Invited", color: "blue" },
  Disabled: { tag: "Inactive", color: "red" },
  Locked: { tag: "Locked", color: "red" },
  Deleted: { tag: "Deleted", color: "red" }
}

const columns = [
  {
    title: "Name",
    dataIndex: "fullName",
    key: "name",
    render: (name: string) => name,
    sorter: (a: IOpusUser, b: IOpusUser) =>
      a.fullName ? a.fullName.localeCompare(b.fullName || "") : 0
  },
  {
    title: "Email",
    dataIndex: "username",
    key: "email",
    render: (email: string) => email
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status: UserStatus) => {
      return (
        <Tag color={states[status].color}>
          {states[status].tag.toUpperCase()}
        </Tag>
      )
    }
  },
  {
    title: "Last login",
    dataIndex: "lastLogin",
    key: "lastLogin",
    render: (lastLogin: string) =>
      lastLogin && new Date(lastLogin).toLocaleDateString()
  }
]

const ListDistributorUsers: React.FC<ListDistributorUsersProps> = (props) => {
  const platform = usePlatform()
  const userList = useQuery(
    ["listDistributorUsers", props.companyID],
    () => platform.company.listCompanyUsers(props.companyID),
    {
      enabled: !!props.companyID,
      onError: (error: AxiosError) => {
        notification.error({
          message: "Unable to load distributor users."
        })
      }
    }
  )

  return (
    <>
      {userList.data && userList.data.length === 0 && (
        <Alert
          message="No users found for this distributor."
          style={{ marginBottom: "15px" }}
        />
      )}
      <Table
        rowKey="id"
        scroll={{ x: "max-content" }}
        loading={userList.isLoading}
        columns={columns}
        dataSource={userList.data}
        pagination={{
          defaultPageSize: 10,
          hideOnSinglePage: true,
          position: ["bottomCenter"],
          size: "small"
        }}
      />
    </>
  )
}

export type ListDistributorUsersProps = RouteComponentProps & {
  companyID: string
}

export default withRouter(ListDistributorUsers)
