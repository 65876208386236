import { IErrorResponse } from "models/errors"
import { pipe, propOr, pathOr, head } from "ramda"
import { TErrorState } from "models/errors"

export const errorValidationMessage = (
  error: IErrorResponse
): unknown | string => {
  return pipe(
    pathOr([], ["response", "data", "validationErrors"]),
    head,
    propOr("", "message")
  )(error)
}

export interface IErrorState {
  isError: TErrorState
}
