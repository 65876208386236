import { useState } from "react"
import { IEmbargoesListItem } from "models/embargoes/embargoesList"
import { useAsyncEffect } from "use-async-effect"
import useOpus from "contexts/opus/hooks/use-opus"

export const useGetEmabrgoes = (): IUseGetEmbargoes => {
  const [embargoes, setEmbargoes] = useState<IEmbargoesListItem[] | []>([])
  const [isError, setIsError] = useState<number | undefined>(undefined)
  const [embargoesLoaded, setEmbargoesLoaded] = useState<boolean>(false)
  const { embargoesClient } = useOpus()

  useAsyncEffect(
    async (isMounted) => {
      try {
        if (embargoesLoaded === false) {
          const _embargoes = await embargoesClient.getEmbargoes()
          if (isMounted() && _embargoes) {
            setEmbargoes(_embargoes)
            setEmbargoesLoaded(true)
          }
        }
      } catch (error) {
        setEmbargoes([])
        setEmbargoesLoaded(false)
        setIsError(error.response?.status)
      }
    },
    [embargoesLoaded]
  )

  return {
    embargoes,
    isError,
    isEmbargoesLoaded: embargoesLoaded
  }
}

interface IUseGetEmbargoes {
  embargoes: IEmbargoesListItem[] | []
  isError: number | undefined
  isEmbargoesLoaded: boolean
}
