import React from "react"
import { LayoutLoading } from "components"
import { useMutation, useQuery } from "react-query"
import { IQuoteRouteProps, Quote } from "./view"
import { IConfigurationContext } from "contexts/configuration/context"
import { TErrorState } from "models/errors"
import { IProductOpus } from "models/quotes/productOpus"
import { useGetQuoteWithdrawReasons } from "../../services/hooks"
import { IEndorsementOpus } from "models/quotes/endorsementOpus"
import { AxiosError } from "axios"
import {
  IPricingServiceFee,
  IQuoteBundle,
  IQuoteNote
} from "contexts/opus/context"
import { IQuoteEvent } from "models/events/IEvent"
import { IQuoteReviewResult } from "models/quotes/quoteReview"
import useOpus from "contexts/opus/hooks/use-opus"
import { useHistory } from "react-router"
import { IPaymentPlan, Nullable } from "platform-client/types"
import { notification } from "antd"

export const QuoteV2: React.FC<IQuoteV2> = (props) => {
  const { quoteClient, payOnAccount } = useOpus()
  const history = useHistory()
  const quoteId = props.match.params.id

  /**
   * Get Quote Bundle
   */

  const {
    data: quoteBundle,
    isFetching: isGettingQuoteBundle,
    isFetched: hasQuoteBundle,
    error: isErrorWithQuoteBundle,
    refetch: refetchQuoteBundle
  } = useQuery<IQuoteBundle | undefined, AxiosError>(
    ["quote-bundle", quoteId],
    async () => quoteClient.getQuoteBundle(quoteId)
  )

  /**
   * Confirm Quote
   */

  const confirmQuote = useMutation(
    (vars: { quoteId: string; comment?: string }) =>
      quoteClient.setQuoteConfirm(vars.quoteId, vars.comment)
  )

  const handleConfirmQuote = async (
    vars: {
      quoteId: string
      comment?: string
    },
    onSuccess?: (quoteId?: string) => void,
    onFailure?: (error: TErrorState) => void
  ) => {
    confirmQuote
      .mutateAsync(vars)
      .then((response) => {
        onSuccess?.(response?.id)
        refetchQuoteBundle()
      })
      .catch((error) => onFailure?.(error))
  }

  /**
   * Withdraw Quote
   */

  const withdrawQuote = useMutation(
    (vars: {
      quoteId: string
      comment?: string
      withdrawalReasonReferenceID?: string
    }) =>
      quoteClient.setQuoteWithdraw(vars.quoteId, {
        comment: vars.comment,
        withdrawalReasonReferenceID: vars.withdrawalReasonReferenceID
      })
  )

  const handleWithdrawQuote = async (
    vars: {
      quoteId: string
      comment?: string
      withdrawalReasonReferenceID?: string
    },
    onSuccess?: (quoteId?: string) => void,
    onFailure?: (error: TErrorState) => void,
    options?: {
      shouldRefetchQuote?: boolean
    }
  ) => {
    withdrawQuote
      .mutateAsync(vars)
      .then((response) => {
        onSuccess?.(response?.id)
        if (options?.shouldRefetchQuote) {
          refetchQuoteBundle()
        }
      })
      .catch((error) => onFailure?.(error))
  }

  /**
   * Add Note
   */

  const addNote = useMutation((vars: { quoteId: string; content: string }) =>
    quoteClient.setQuoteNote(vars.quoteId, { content: vars.content })
  )

  const handleAddNote = async (
    vars: {
      quoteId: string
      content: string
    },
    onSuccess?: (noteId?: string) => void,
    onFailure?: (error: TErrorState) => void
  ) => {
    addNote
      .mutateAsync(vars)
      .then((response) => {
        onSuccess?.(response?.id)
        refetchNotes()
        refetchEvents()
      })
      .catch((error) => onFailure?.(error))
  }

  /**
   * Refer Quote
   */

  const referQuote = useMutation((vars: { quoteId: string }) =>
    quoteClient.setQuoteRefer(vars.quoteId)
  )

  const handleReferQuote = async (
    vars: {
      quoteId: string
    },
    onSuccess?: (quoteId?: string) => void,
    onFailure?: (error: TErrorState) => void
  ) => {
    referQuote
      .mutateAsync(vars)
      .then((response) => {
        onSuccess?.(response?.id)
        refetchQuoteBundle()
      })
      .catch((error) => onFailure?.(error))
  }

  const handleAnonymiseQuote = async (
    vars: {
      quoteId: string
    },
    onSuccess?: (quoteId?: string) => void,
    onFailure?: (error: TErrorState) => void
  ) => {
    refetchQuoteBundle()
    refetchEvents()
  }

  /**
   * Get Notes
   */

  const {
    data: notes,
    isFetching: isGettingNotes,
    error: isErrorWithNotes,
    refetch: refetchNotes
  } = useQuery<IQuoteNote[] | undefined, AxiosError>(
    ["notes", quoteId],
    async () => {
      return quoteClient.getQuoteNotes(quoteId)
    },
    {
      enabled: !!quoteId
    }
  )

  /**
   * Get Quote Events
   */

  const {
    data: events,
    isFetching: isEventsLoading,
    error: isErrorWithQuoteEvents,
    refetch: refetchEvents
  } = useQuery<IQuoteEvent[] | undefined, AxiosError>(
    ["events", quoteId],
    async () => {
      return quoteClient.getQuoteEvents(quoteId)
    },
    {
      enabled: !!quoteId
    }
  )

  /**
   * Get Quote Product
   */

  const {
    data: quoteProduct,
    isFetching: isGettingQuoteProduct,
    error: isErrorWithQuoteProduct,
    refetch: refetchQuoteProduct
  } = useQuery<IProductOpus | undefined, AxiosError>(
    ["product", quoteId],
    async () => {
      return quoteClient.getQuoteProduct(quoteId)
    },
    { enabled: !!quoteId }
  )

  /**
   * Get Quote Payment Link
   */

  const {
    data: paymentLink,
    isFetching: isPaymentLinkLoading,
    error: isErrorWithPaymentLink
  } = useQuery(
    ["payment-link", quoteId],
    async () => {
      return quoteClient.getQuotePaymentLink(quoteId)
    },
    {
      enabled:
        !!quoteId && quoteBundle?.requestedQuote.quoteState === "PendingPayment"
    }
  )

  /**
   * Pay on account mutation
   */
  const opusPayOnAccount = useMutation((bindProps: IBindQuoteProps) =>
    payOnAccount.bindQuote(
      bindProps.quoteID,
      bindProps.paymentPlanReferenceID,
      bindProps.encryptedDiscount,
      bindProps.paymentReference
    )
  )

  /**
   * Invalidate Quote Payment Link
   */

  const invalidatePaymentLink = useMutation((vars: { quoteId: string }) =>
    quoteClient.invalidateQuotePaymentLink(vars.quoteId)
  )

  const handleInvalidatePaymentLink = async (
    vars: { quoteId: string },
    onSuccess?: () => void,
    onFailure?: () => void
  ) => {
    invalidatePaymentLink
      .mutateAsync(vars)
      .then(() => onSuccess?.())
      .catch(() => onFailure?.())
  }

  /**
   * Edit Endorsement
   */

  const editEndorsement = useMutation(
    (vars: {
      quoteId: string
      addedEndorsements: IEndorsementOpus[]
      comment: string
      deletedEndorsements: IEndorsementOpus[]
    }) =>
      quoteClient.addRemoveEndorsements(
        vars.quoteId,
        vars.addedEndorsements,
        vars.comment,
        vars.deletedEndorsements
      )
  )

  const handleEditEndorsements = async (
    vars: {
      quoteId: string
      addedEndorsements: IEndorsementOpus[]
      comment: string
      deletedEndorsements: IEndorsementOpus[]
    },
    onSuccess?: () => void,
    onFailure?: () => void
  ) => {
    editEndorsement
      .mutateAsync(vars)
      .then(() => {
        onSuccess?.()
        refetchQuoteProduct()
      })
      .catch(() => onFailure?.())
  }

  const createPaymentLink = useMutation(() =>
    quoteClient.setQuotePaymentLink(quoteId)
  )

  const handleCreatePaymentLink = () => {
    createPaymentLink
      .mutateAsync()
      .then()
      .catch(() =>
        notification.error({
          message: "Unable to create payment link. Please try again."
        })
      )
  }

  /**
   * Get Quote Withdraw Reasons
   */

  const {
    withdrawReasons,
    isWithdrawReasonsLoading,
    isError: isErrorWithQuoteReasons
  } = useGetQuoteWithdrawReasons(props.match.params.id)

  /**
   * Review Quote
   */

  const {
    data: renewalReview,
    isFetching: isGettingRenewalReview,
    error: isErrorWithRenewalReview,
    refetch: refetchRenewalReview
  } = useQuery<IQuoteReviewResult | undefined, AxiosError>(
    ["review", quoteId],
    async () => quoteClient.getQuoteRenewalReview(quoteId),
    {
      enabled: !!quoteId
    }
  )

  const reviewQuote = useMutation(
    (vars: { quoteId: string; review: boolean }) =>
      quoteClient.setQuoteReview(vars.quoteId, vars.review)
  )

  const handleReviewQuote = async (
    vars: {
      quoteId: string
      review: boolean
    },
    onSuccess?: () => void,
    onFailure?: () => void
  ) => {
    reviewQuote
      .mutateAsync(vars)
      .then(() => {
        onSuccess?.()
        refetchRenewalReview()
        refetchQuoteBundle()
      })
      .catch(() => onFailure?.())
  }

  const handlePurchaseCallback = () => {
    if (quoteId) {
      history.push(
        `/opus/purchase/${quoteBundle?.risk.productReferenceID}/${quoteBundle?.risk.coverType}/${quoteId}`
      )
    }
  }

  const handlePayOnAccount = (
    pricingPlan: IV2PaymentPlan,
    callback?: () => void
  ) => {
    switch (pricingPlan.price.planType) {
      case "ExternalCollection":
      case "PayOnAccount":
        opusPayOnAccount
          .mutateAsync({
            quoteID: quoteId,
            paymentPlanReferenceID: pricingPlan.price.pricingPlanReferenceID,
            encryptedDiscount: pricingPlan.encryptedDiscount
          })
          .then((response) => {
            notification.success({ message: "Quote bound successfully." })
            if (typeof callback === "function") {
              callback()
            }
            setTimeout(
              () => history.push(`/policy/${response.policy.id}`),
              1500
            )
          })
          .catch((error) => {
            notification.error({
              message: "Sorry there has been an error binding quote."
            })
          })
        break
      default:
        notification.error({
          message: "Payment plan type not supported"
        })
    }
  }

  if (isGettingQuoteBundle && !quoteBundle) {
    return <LayoutLoading withWrapper />
  }

  if (quoteBundle) {
    return (
      <Quote
        {...props}
        configuration={props.configuration}
        isWorking={
          isGettingQuoteBundle || isEventsLoading || isWithdrawReasonsLoading
        }
        error={
          (isErrorWithQuoteBundle && {
            errorNumber: isErrorWithQuoteBundle.response?.status
          }) ||
          (isErrorWithNotes && {
            errorNumber: isErrorWithNotes.response?.status
          }) ||
          (isErrorWithQuoteEvents && {
            errorNumber: isErrorWithQuoteEvents.response?.status
          }) ||
          (isErrorWithQuoteProduct && {
            errorNumber: isErrorWithQuoteProduct.response?.status
          }) ||
          (isErrorWithRenewalReview && {
            errorNumber: isErrorWithRenewalReview.response?.status
          }) ||
          isErrorWithQuoteReasons
        }
        declineReasons={[]}
        quoteDocuments={{
          isGettingDocuments: isGettingQuoteBundle,
          documents: quoteBundle?.requestedQuote.documents.map((document) => ({
            documentUrl: document.documentUrl,
            fileExtension: null,
            fileName: document.filename,
            fileType: document.type,
            policyDocumentID: document.id,
            policyDocumentStatus: document.policyDocumentStatus,
            version: document.version,
            localDateCreated: document.dateCreated,
            referenceID: document.referenceID,
            directDocumentID: document.directDocumentID
          })),
          version: 2
        }}
        quoteEndorsements={quoteBundle.requestedQuote.endorsements}
        quote={quoteBundle.requestedQuote}
        quoteChanges={quoteBundle.changes}
        risk={{
          core: quoteBundle.risk,
          exposures: null
        }}
        originalPolicyMeta={
          quoteBundle.originalPolicyMeta ?? quoteBundle.policyMeta
        }
        ownerId={quoteBundle.requestedQuote.ownerID}
        quoteNotes={{
          notes: notes || undefined,
          isGettingNotes
        }}
        quoteEvents={{
          events: events || undefined,
          isGettingEvents: isEventsLoading
        }}
        quoteProduct={{
          product: quoteProduct,
          isGettingQuoteProduct: isGettingQuoteProduct,
          productItems_v2: quoteProduct?.productItems
        }}
        quoteScheme={quoteBundle.requestedQuoteScheme}
        quotePaymentPlans={{
          paymentPlans:
            (quoteBundle.requestedQuotePricing !== null &&
              quoteBundle.requestedQuotePricing.plans &&
              quoteBundle.requestedQuotePricing.plans.length > 0 &&
              quoteBundle.requestedQuotePricing.plans.map((plan) => ({
                depositPaymentProvider: plan.paymentPlan.depositPaymentProvider,
                paymentProvider: plan.paymentPlan.paymentProvider,
                planType: plan.paymentPlan.type,
                addonNetTotals: plan.primaryRateableComponentFinalPrices
                  .filter((f) => f.referenceID !== "_primary")
                  .map((pr) => pr.price.net),
                price: {
                  immediateAmount: {
                    gross: plan.upfrontPrice
                  },
                  planType: plan.paymentPlan.type,
                  pricingPlanReferenceID:
                    plan.paymentPlan.paymentPlanReferenceID,
                  recurringAmount: {
                    gross: plan.monthlyPrice
                  },
                  recurringInitial: {
                    gross: plan.firstInstalmentAmount
                  },
                  total: {
                    gross: plan.totalPayable.gross,
                    net: plan.totalPayable.net,
                    tax: {
                      taxes: plan.totalPayable.tax.taxes.map((tax) => ({
                        amount: tax.amount,
                        name: tax.name,
                        order: tax.order,
                        referenceID: tax.taxReferenceID,
                        ruleAmount: tax.percentage,
                        applicationMethod: null
                      })),
                      taxRuleID: plan.totalPayable.tax.taxRuleID,
                      taxRuleReferenceID:
                        plan.totalPayable.tax.taxPlanReferenceID,
                      total: plan.totalPayable.tax.total
                    }
                  }
                },
                pricingDetail: {
                  annualPercentageRate: plan.aprRate,
                  interestRate: plan.interestRate,
                  numberOfInstalments: plan.numberOfInstallments,
                  pricingPlanReferenceID:
                    plan.paymentPlan.paymentPlanReferenceID,
                  paymentPlanType: plan.paymentPlan.type
                },
                encryptedDiscount: plan.encryptedState,
                fees: plan.fees
              }))) ||
            [],
          isGettingPaymentPlans: isGettingQuoteBundle,
          isPaymentPlansFinished: hasQuoteBundle
        }}
        quotePaymentLink={{
          paymentLink: paymentLink || createPaymentLink.data || undefined,
          isGettingPaymentLink:
            isPaymentLinkLoading || createPaymentLink.isLoading,
          error:
            isErrorWithPaymentLink || createPaymentLink.error ? true : false
        }}
        quoteWithdrawReasons={{
          withdrawReasons,
          isGettingWithdrawReasons: isWithdrawReasonsLoading
        }}
        quoteRenewalReview={{
          review: renewalReview || undefined,
          isGettingRenewalReview: isGettingRenewalReview || false
        }}
        quoteLifecycles={{
          onConfirmQuote: handleConfirmQuote,
          onWithdrawQuote: handleWithdrawQuote,
          onAddNote: handleAddNote,
          onReferQuote: handleReferQuote,
          onInvalidatePaymentLink: handleInvalidatePaymentLink,
          onEditEndorsements: handleEditEndorsements,
          onReviewQuote: handleReviewQuote,
          onAnonymiseQuote: handleAnonymiseQuote
        }}
        handleConfirmQuote={handlePurchaseCallback}
        handleBindQuote={handlePayOnAccount}
        handleCreatePaymentLink={handleCreatePaymentLink}
        onQuoteChange={refetchQuoteBundle}
        maxQuoteExpiry={quoteBundle.maxQuoteExpiryDate}
      />
    )
  }

  return null
}

export interface IV2PaymentPlan extends IPaymentPlan {
  encryptedDiscount?: Nullable<string>
  fees?: IPricingServiceFee[]
}

interface IBindQuoteProps {
  quoteID: string
  paymentPlanReferenceID: string
  encryptedDiscount?: string | null
  paymentReference?: string | null
}

export interface IQuoteV2 extends IQuoteRouteProps {
  configuration: IConfigurationContext
}

export interface IPaymentLink {
  paymentURL: string
  expiryDateTime: string
  expired: boolean
}
