import React from "react"
import styled from "styled-components"
import AnimateHeight from "react-animate-height"
import CSS from "csstype"

const StyledCollapseContainer = styled.div`
  border-top: 1px solid #ddd;
  width: 100%;
  height: auto;
  ${(props: ICollapseContainer) => props.additionalCSS};
`

export const Collapse: React.FunctionComponent<ICollapse> = ({
  disabled = false,
  startExpanded = false,
  ...props
}) => {
  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(
    disabled ? true : !startExpanded
  )

  const [collapsed, expanded] = [props.collapsedPanel, props.expandedPanel].map(
    (panel: React.ReactNode) => {
      if (React.isValidElement(panel)) {
        return React.cloneElement(panel, {
          toggle: () => {
            setIsCollapsed(disabled ? true : !isCollapsed)
            props.onCollapse && props.onCollapse(disabled ? true : !isCollapsed)
          }
        })
      }
      return panel
    }
  )

  const Panel = isCollapsed ? collapsed : expanded

  return (
    <StyledCollapseContainer additionalCSS={props.additionalCSS}>
      <div>{Panel}</div>
      {!disabled && (
        <AnimateHeight
          duration={props.duration || 500}
          height={isCollapsed ? 0 : "auto"}
        >
          {props.children}
        </AnimateHeight>
      )}
    </StyledCollapseContainer>
  )
}

export interface ICollapse {
  collapsedPanel: React.ReactNode
  expandedPanel: React.ReactNode
  startExpanded?: boolean
  additionalCSS?: string
  style?: CSS.Properties
  duration?: number
  disabled?: boolean
  onCollapse?: (isCollapsed: boolean) => void
}

export interface ICollapseContainer {
  additionalCSS?: string
}
