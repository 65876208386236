import { Note, NoteList } from "components/Notes"
import { INote } from "platform-client/types/notes"
import { Typography } from "antd"
import { buildLink } from "./helpers"

interface INotesProps {
  quoteId: string
  isLoading: boolean
  isEmpty: boolean
  data: INote[] | undefined
}

const { Title } = Typography

export const Notes: React.FC<INotesProps> = (props) => (
  <>
    <Title level={3}>Notes</Title>
    <NoteList isLoading={props.isLoading} isEmpty={props.isEmpty}>
      {(props.data || []).map((noteData) => (
        <Note
          internal={noteData.isInternalOnly}
          content={noteData.content}
          date={noteData.dateCreated}
          author={noteData.createdByName}
          link={
            noteData.entityType === "Opportunity" ||
            noteData.entityType === "Policy"
              ? buildLink({
                  entityID: noteData.entityID,
                  entityReferenceID: noteData.entityReferenceID,
                  entityType: noteData.entityType,
                  quoteId: props.quoteId
                })
              : undefined
          }
        />
      ))}
    </NoteList>
  </>
)
