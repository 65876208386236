import { SyncOutlined, UploadOutlined } from "@ant-design/icons"
import { Alert, Button, Input, Tag } from "antd"
import produce from "immer"
import React from "react"
import { useRef, useState } from "react"
import styled from "styled-components"
import { bytesToMegaBytes } from "utils/pocketknife/bytes-to-mega-bytes"

let StyledGrid = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 80px 80px 100px;
  align-items: center;
  gap: 10px 10px;

  .title {
    font-weight: bold;
  }
`

const StyledInput = styled(Input)`
  &.error {
    border: 1px solid var(--ant-error-color);
  }
`

const StyledUploadButton = styled.label`
  input[type="file"] {
    display: none;
  }
`

const statusColor = (status: UploadFileStatus) => {
  switch (status) {
    case "error":
      return "red"
    case "success":
      return "green"
    default:
      return "processing"
  }
}

const FileUpload = ({
  multiple = false,
  askForLabel = true,
  handleUpload
}: IFileUploadProps): JSX.Element => {
  if (!askForLabel) {
    StyledGrid = styled.div`
      display: grid;
      grid-template-rows: 1fr;
      grid-template-columns: 500px 80px 80px 100px;
      align-items: center;
      gap: 10px 10px;
      .title {
        font-weight: bold;
      }
    `
  }
  const fileInput = useRef<HTMLInputElement>(null)
  const [fileList, setFileList] = useState<UploadFile[] | null>(null)

  const resetFileList = () => setFileList(null)

  const beforeUpload = async () => {
    fileList?.forEach(async (item, i) => {
      //Size check
      if (bytesToMegaBytes(item.size) > 10) {
        // Skip this file.
        return
      }

      // Filename check
      if (item.fileName === "" || !item.fileName) {
        return
      }

      //Skip files already uploaded
      if (item.status === "success") {
        return
      }

      // Update to processing
      setFileList(
        produce((draft) => {
          if (draft) {
            draft[i] = { ...draft[i], ...{ status: "processing" } }
          }
        })
      )

      // Create form data object
      const data = new FormData()

      // Assign values to form data
      for (const key in item) {
        const index = key as KeyOfUploadFile
        switch (index) {
          case "file":
            data.append("file", item["file"])
            break
          case "label":
          case "fileName":
            data.append(index, item[index]?.toString() || "")
            break
          default:
            break
        }
      }

      // Upload handling passed in from parent. Callback allows us to update the status
      // when fired.
      await handleUpload(data, (result) => {
        if (result) {
          setFileList(
            produce((draft) => {
              if (draft) {
                draft[i] = { ...draft[i], ...{ status: "success" } }
              }
            })
          )
        }
      })
    })
  }

  if (fileList === null || fileList.length === 0) {
    return (
      <StyledUploadButton className="ant-btn">
        Choose files to upload <UploadOutlined />
        <input
          aria-label="file-upload"
          type="file"
          multiple={multiple}
          ref={fileInput}
          onChange={() => {
            if (fileInput.current) {
              const files = fileInput.current.files
              if (files) {
                setFileList(
                  Array.from(files).map((file) => ({
                    fileName: file.name,
                    file: file,
                    size: file.size,
                    status:
                      bytesToMegaBytes(file.size) < 10 ? "pending" : "error"
                  }))
                )
                fileInput.current.value = ""
              }
            }
          }}
        />
      </StyledUploadButton>
    )
  }

  return (
    <>
      {fileList &&
        fileList.some((file) => bytesToMegaBytes(file.size) > 10) && (
          <Alert
            type="warning"
            message="Files over 10MB will not be uploaded."
            style={{ marginBottom: "20px" }}
          />
        )}
      {fileList &&
        fileList.some((file) => !file.fileName || file.fileName === "") && (
          <Alert
            type="error"
            message="File name is required."
            style={{ marginBottom: "20px" }}
          />
        )}
      <StyledGrid>
        <span className="title">Filename *</span>
        {askForLabel && <span className="title">Label (optional)</span>}
        <span className="title">File size</span>
        <span className="title">Status</span>
        <span></span>

        {fileList &&
          fileList.length > 0 &&
          fileList.map((file, i) => {
            return (
              <React.Fragment key={`fileForm-${i}`}>
                <StyledInput
                  value={file.fileName}
                  name="fileName"
                  aria-label="fileName"
                  disabled={
                    file.status === "success" || file.status === "error"
                  }
                  maxLength={100}
                  onChange={(event) => {
                    setFileList(
                      produce((draft) => {
                        if (draft) {
                          draft[i].fileName = event.target.value
                        }
                      })
                    )
                  }}
                  required
                  {...(!file.fileName || file.fileName === ""
                    ? { className: "error" }
                    : {})}
                />
                {askForLabel && (
                  <StyledInput
                    value={file.label || ""}
                    name="label"
                    aria-label="file-label"
                    disabled={
                      file.status === "success" || file.status === "error"
                    }
                    maxLength={100}
                    onChange={(event) => {
                      setFileList(
                        produce((draft) => {
                          if (draft) {
                            draft[i].label = event.target.value
                          }
                        })
                      )
                    }}
                  />
                )}
                <span>
                  <Tag
                    color={bytesToMegaBytes(file.size) > 10 ? "red" : "green"}
                  >
                    {bytesToMegaBytes(file.size, true)}MB
                  </Tag>
                </span>
                <span>
                  <Tag
                    {...(file.status === "processing" && {
                      icon: <SyncOutlined spin />
                    })}
                    color={statusColor(file.status)}
                  >
                    {file.status.toUpperCase()}
                  </Tag>
                </span>
                {file.status !== "success" ? (
                  <Button
                    size="small"
                    type="link"
                    onClick={() => {
                      setFileList(
                        produce((draft) => {
                          if (draft) {
                            draft.splice(i, 1)
                          }
                        })
                      )
                    }}
                  >
                    Remove
                  </Button>
                ) : (
                  <span></span>
                )}
              </React.Fragment>
            )
          })}
      </StyledGrid>
      {fileList && fileList.some((file) => file.status !== "success") && (
        <div style={{ padding: "20px 0" }}>
          <Button
            type="primary"
            onClick={beforeUpload}
            loading={fileList.some((file) => file.status === "processing")}
          >
            Upload file{fileList.length > 1 && "s"}
          </Button>
          <Button style={{ marginLeft: "15px" }} onClick={resetFileList}>
            Clear files
          </Button>
        </div>
      )}
      {fileList && fileList.every((file) => file.status === "success") && (
        <div style={{ padding: "20px 0" }}>
          <Button onClick={resetFileList}>Upload more files</Button>
        </div>
      )}
    </>
  )
}

type UploadFile = {
  file: File
  fileName: string
  size: number
  label?: string
  status: UploadFileStatus
}

type UploadFileStatus = "pending" | "processing" | "error" | "success"

type KeyOfUploadFile = keyof UploadFile

interface IFileUploadProps {
  multiple: boolean
  askForLabel: boolean
  handleUpload: (
    uploadData: FormData,
    callback?: (result: boolean) => void
  ) => Promise<void>
}

export default FileUpload
