import React from "react"
import { Button, Col, Row, Select, Space, Typography } from "antd"
import usePlatform from "contexts/platform/use-platform"
import { ICompany } from "platform-client/types"
import { useQuery } from "react-query"

const AssignBroker: React.FC<ISelectBrokerProps> = ({
  broker,
  onBack,
  onSelectBroker,
  ...props
}) => {
  const [selectedBroker, setSelectedBroker] = React.useState<
    AssignedBroker | undefined
  >(broker)

  const { company } = usePlatform()

  const listCompanies = useQuery<unknown, unknown, ICompany[]>(
    ["listCompanies", "Broker"],
    () => company.listCompanies("Broker")
  )

  const selectBroker = (value: string) => {
    const broker = listCompanies.data?.find((item) => item.id === value)

    if (broker) {
      setSelectedBroker(broker)
    }
  }

  return (
    <Row>
      <Col span={10} offset={7} style={{ padding: "50px 0" }}>
        <Space direction="vertical">
          <Typography.Title level={3}>Assign a broker</Typography.Title>
          <Typography.Paragraph>
            If you would like to assign a broker to this opportunity please
            select one from the list below.
          </Typography.Paragraph>
          <Select
            showSearch
            size="large"
            style={{ width: "100%", marginBottom: "15px" }}
            placeholder="Assign a broker to this opportunity"
            loading={listCompanies.isLoading}
            defaultValue={broker?.id || undefined}
            options={listCompanies.data
              ?.filter((lc) => lc.brokerCanBindBusiness === true)
              .map((item) => {
                return {
                  label: item.name,
                  value: item.id
                }
              })}
            filterOption={(value, option) =>
              String(option?.label)
                .toLowerCase()
                .indexOf(value.toLowerCase()) >= 0
            }
            onChange={selectBroker}
            aria-label="broker-search"
          />
          <div>
            <Button size="large" onClick={() => onBack()}>
              Go back
            </Button>
            &nbsp; &nbsp; &nbsp;
            <Button
              size="large"
              type="primary"
              disabled={selectedBroker === undefined}
              onClick={() => {
                if (selectedBroker) {
                  onSelectBroker(selectedBroker)
                }
              }}
            >
              Save and continue
            </Button>
          </div>
        </Space>
      </Col>
    </Row>
  )
}

export type AssignedBroker = Pick<ICompany, "id" | "name" | "companyType">

interface ISelectBrokerProps {
  broker?: AssignedBroker
  onSelectBroker: (data: AssignedBroker) => void
  onBack: () => void
}

export default AssignBroker
