import { OracleResultDisplay } from "components/OracleResultDisplay"
import usePlatform from "contexts/platform/use-platform"
import { useQuery } from "react-query"

export const PolicyOracleResults: React.FunctionComponent<IOracleResultsProps> =
  (props) => {
    const platform = usePlatform()

    const { data, isLoading } = useQuery(
      ["policy", "oracleresults", props.policyId],
      () => platform.policy.getOracleResults(props.policyId)
    )
    return <OracleResultDisplay isLoading={isLoading} results={data} />
  }

interface IOracleResultsProps {
  policyId: string
}
