import { useCallback, useState } from "react"
import { TErrorState } from "models/errors"
import {
  errorValidationMessage,
  IErrorState
} from "utils/pocketknife/error-validation-message"
import useOpus from "contexts/opus/hooks/use-opus"
import useAsyncEffect from "use-async-effect"
import { ITransactionalMessage } from "models/documents/message"

export const useGetTransactionalMessages = (
  entityID: string | undefined,
  refetch: boolean,
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>
): ITransactionalMessages => {
  const [transactionaMessages, setTransactionalMessages] = useState<
    ITransactionalMessage[] | undefined
  >(undefined)
  const [isError, setIsError] = useState<TErrorState>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { policyClient } = useOpus()

  const getMessages = useCallback(async () => {
    try {
      if (entityID) {
        const _messages = await policyClient.getTransactionalMessages(entityID)

        if (_messages) {
          setTransactionalMessages(_messages)
          setIsError(undefined)
        }
      }
      setIsLoading(false)
      refetch && setRefetch(false)
    } catch (error) {
      setTransactionalMessages(undefined)
      setIsError({
        errorNumber: error?.response?.status,
        validationMessage: errorValidationMessage(error)
      })
      setIsLoading(false)
      refetch && setRefetch(false)
    }
  }, [entityID, policyClient, refetch, setRefetch])

  useAsyncEffect(() => {
    if ((entityID && transactionaMessages === undefined) || refetch) {
      setIsLoading(true)
      getMessages()
    }
  }, [getMessages, entityID, transactionaMessages, refetch, setRefetch])

  return {
    data: transactionaMessages,
    isError,
    isLoading
  }
}

interface ITransactionalMessages extends IErrorState {
  data: ITransactionalMessage[] | undefined
  isLoading: boolean
}

export default useGetTransactionalMessages
