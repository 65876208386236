import React from "react"
import { VEricPolicies, VEricUpload } from "./"
import { CarOutlined } from "@ant-design/icons"

export const ERIC_ROUTES = [
  {
    path: "/eric",
    component: VEricPolicies
  },
  {
    path: "/eric/upload",
    component: VEricUpload
  }
]

export const ERIC_NAVIGATION = [
  {
    title: "Eric",
    path: "/eric",
    submenu: false,
    icon: <CarOutlined />,
    iconActive: <CarOutlined />
  }
]
