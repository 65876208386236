import React, { useState } from "react"
import { LayoutPageContent, NewNoteModal } from "components"
import { RouteComponentProps, StaticContext, useRouteMatch } from "react-router"
import {
  IPolicy as IPolicyModel,
  IPriceHeader,
  ICompanyInRole,
  IProduct,
  IPolicyholder,
  IPolicyDetailSet,
  CoverPeriodType,
  PolicyDetailType,
  IPolicyHistorySummary,
  EndorsementType,
  EUserGroups
} from "platform-client/types"
import { useHistory, Route, Switch } from "react-router-dom"
import { TErrorState } from "models/errors"
import { IConfigurationContext } from "contexts/configuration/context"
import { Badge, Layout, notification, Space, Tabs } from "antd"
import { PolicyHeader } from "../components/policy-header"
import { useDefaultPage } from "utils/hooks/use-default-page"

import { Underwriting } from "../sub-views/underwriting"
import { Summary } from "../sub-views/summary"
import { Documents } from "../sub-views/Documents"
import { PolicyAnswers } from "../sub-views/PolicyAnswers"
import { Notes } from "../sub-views/Notes"
import PolicyActions from "../components/policy-actions"
import { RiskChanges } from "../sub-views/riskChanges"
import CoverPeriodSelect from "components/Console/CoverPeriodSelect"
import { PolicyClaims } from "../sub-views/Claims"
import { useMutation, useQuery } from "react-query"
import usePlatform from "contexts/platform/use-platform"
import { handleValidationErrors } from "utils/pocketknife/handle-validation-errors"
import { AxiosError } from "axios"
import { IRisk } from "platform-client/types/quote"
import { PolicyOracleResults } from "../sub-views/oracleResults"
import EndorsementsTable from "components/Endorsements"

export const Policy: React.FC<IPolicy> = (props) => {
  const platform = usePlatform()
  const history = useHistory()
  const { path } = useRouteMatch()

  const [noteContent, setNoteContent] = useState("")
  const [isModalVisible, setModalVisibility] = useState(false)
  const [isInternalNote, setInternalNote] = useState(false)

  useDefaultPage(
    props.error?.errorNumber ? Number(props.error.errorNumber) : undefined,
    ["404", "400"],
    {
      status: "404",
      title: `Sorry, we couldn't find a policy that matched those details.`,
      buttonText: "Back to Policies",
      redirectTo: "/policies"
    }
  )

  const notes = useQuery(
    ["policyNotes", props.match.params.id],
    () => platform.policy.getNotes(props.match.params.id || ""),
    {
      onError: () =>
        notification.error({
          message: "Couldn't load the notes, please try again"
        })
    }
  )

  const newNote = useMutation(
    ({
      content = "",
      isInternalOnly = false
    }: {
      content: string
      isInternalOnly: boolean
    }) =>
      platform.policy.createNote(props.match.params.id || "", {
        content,
        isInternalOnly
      }),
    {
      onSuccess: () => {
        setModalVisibility(false)
        setInternalNote(false)
        setNoteContent("")
        notes.refetch()
      },
      onError: (error: AxiosError) => handleValidationErrors(error)
    }
  )

  return (
    <>
      <Layout>
        <NewNoteModal
          visible={isModalVisible}
          content={noteContent}
          setContent={setNoteContent}
          onCancel={() => {
            setModalVisibility(false)
            setInternalNote(false)
            setNoteContent("")
          }}
          onSubmit={() => {
            if (!noteContent)
              return notification.error({
                message: "Please add some text to the note"
              })
            return newNote.mutate({
              content: noteContent,
              isInternalOnly: isInternalNote
            })
          }}
          isLoading={newNote.isLoading}
          onInternalCheck={() => setInternalNote((state) => !state)}
          isInternal={isInternalNote}
        />
        <PolicyHeader
          configuration={props.configuration}
          policyStatus={props.policy.policyStatus}
          title={props.policy.policyReferenceID || ""}
          isWorking={props.isWorking}
          actions={
            <PolicyActions
              policy={props.policy}
              product={props.product}
              onNoteButtonClick={() => setModalVisibility(true)}
            />
          }
          manualUnderwriting={props.policy.underwritingSourceType === "Manual"}
          imported={props.policy?.imported ?? false}
          tabs={
            <Tabs
              defaultActiveKey={props.match.params.subpage}
              activeKey={props.match.params.subpage}
              onTabClick={(activeKey) => {
                switch (activeKey) {
                  case "summary":
                    history.push(`/policy/${props.policy.id}/summary`)
                    break
                  case "endorsements":
                    history.push(`/policy/${props.policy.id}/endorsements`)
                    break
                  case "documents":
                    history.push(`/policy/${props.policy.id}/documents`)
                    break
                  case "underwriting":
                    history.push(`/policy/${props.policy.id}/underwriting`)
                    break
                  case "riskchanges":
                    history.push(`/policy/${props.policy.id}/riskchanges`)
                    break
                  case "answers":
                    history.push(`/policy/${props.policy.id}/answers`)
                    break
                  case "oracleresults":
                    history.push(`/policy/${props.policy.id}/oracleresults`)
                    break
                  case "claims":
                    history.push(`/policy/${props.policy.id}/claims`)
                    break
                  case "notes":
                    history.push(`/policy/${props.policy.id}/notes`)
                }
              }}
            >
              <Tabs.TabPane tab="Summary" key="summary" />
              <Tabs.TabPane
                tab={
                  <Space align="center">
                    Endorsements
                    <Badge
                      count={
                        (props.policyEndorsements &&
                          props.policyEndorsements.length) ??
                        0
                      }
                      showZero
                      style={{
                        marginTop: "-5px",
                        backgroundColor: "var(--ant-primary-color)"
                      }}
                      dot={false}
                    />
                  </Space>
                }
                key="endorsements"
              />
              {props.configuration.flags.platformVersion === "v3" && (
                <>
                  <Tabs.TabPane tab="Documents" key="documents" />
                  {["Insurer", "InsurerAdministrator"].includes(
                    props.currentUserGroupType || ""
                  ) && <Tabs.TabPane tab="Underwriting" key="underwriting" />}
                  <Tabs.TabPane tab="Risk Changes" key="riskchanges" />
                  <Tabs.TabPane tab="Answers" key="answers" />
                  <Tabs.TabPane tab="Data Oracle Results" key="oracleresults" />
                  <Tabs.TabPane tab="Claims" key="claims" />
                  <Tabs.TabPane tab="Notes" key="notes" />
                </>
              )}
            </Tabs>
          }
        />

        <Switch>
          <Route
            path={`${path}/summary`}
            render={() => (
              <LayoutPageContent transparent error={props.error}>
                <Summary
                  policy={props.policy}
                  risk={props.risk}
                  product={props.product}
                  policyHolder={props.policyHolder}
                  configuration={props.configuration}
                  policyDetailSet={props.policyDetailSet}
                  policyHistorySummary={props.policyHistorySummary}
                  policyCompanies={props.policyCompanies}
                />
              </LayoutPageContent>
            )}
          />
          {props.configuration.flags.platformVersion === "v3" && (
            <>
              <Route
                path={`${path}/documents`}
                render={() => (
                  <LayoutPageContent transparent error={props.error}>
                    <Documents policyID={props.match.params.id} />
                  </LayoutPageContent>
                )}
              />
              <Route
                path={`${path}/claims`}
                render={() => (
                  <LayoutPageContent transparent error={props.error}>
                    <PolicyClaims
                      policyReference={props.policy.policyReferenceID}
                    />
                  </LayoutPageContent>
                )}
              />
              <Route
                path={`${path}/endorsements`}
                render={() => (
                  <LayoutPageContent transparent error={props.error}>
                    <EndorsementsTable
                      endorsements={props.policyEndorsements || []}
                    />
                  </LayoutPageContent>
                )}
              />
              <Route
                path={`${path}/answers`}
                render={() => (
                  <LayoutPageContent transparent error={props.error}>
                    <PolicyAnswers policyId={props.policy.id} />
                  </LayoutPageContent>
                )}
              />
              <Route
                path={`${path}/oracleresults`}
                render={() => (
                  <LayoutPageContent transparent error={props.error}>
                    <PolicyOracleResults policyId={props.policy.id} />
                  </LayoutPageContent>
                )}
              />
              <Route
                path={`${path}/riskchanges`}
                render={() => <RiskChanges policyId={props.policy.id} />}
              />
              <Route
                path={`${path}/notes`}
                render={() => (
                  <LayoutPageContent transparent error={props.error}>
                    <Notes
                      isLoading={notes.isLoading}
                      data={notes.data}
                      isError={notes.isError}
                      isEmpty={!(notes.data || []).length}
                      policyId={props.match.params.id}
                    />
                  </LayoutPageContent>
                )}
              />
              {["Insurer", "InsurerAdministrator"].includes(
                props.currentUserGroupType || ""
              ) && (
                <Route
                  path={`${path}/underwriting`}
                  render={() => (
                    <>
                      {props.coverPeriods && props.coverPeriods.length > 1 && (
                        <CoverPeriodSelect
                          coverPeriods={props.coverPeriods}
                          onChange={props.handleCoverPeriodChange}
                        />
                      )}
                      <LayoutPageContent transparent error={props.error}>
                        <Underwriting
                          policyDetail={props.policyDetail}
                          policyCompanies={props.policyCompanies}
                          manualUnderwriting={
                            props.policy.underwritingSourceType === "Manual"
                          }
                        />
                      </LayoutPageContent>
                    </>
                  )}
                />
              )}
            </>
          )}

          {!props.match.params.subpage &&
            history.push(`/policy/${props.policy.id}/summary`)}
        </Switch>
      </Layout>
    </>
  )
}

export interface IPolicy extends IPolicyRouteProps {
  configuration: IConfigurationContext
  policy: IPolicyModel
  policyPrice: IPriceHeader
  policyCompanies?: ICompanyInRole[]
  policyHistorySummary?: {
    data?: IPolicyHistorySummary[]
    isLoading: boolean
  }
  policyDetail?: PolicyDetailType
  policyHolder?: IPolicyholder
  policyDetailSet?: IPolicyDetailSet
  policyEndorsements?: EndorsementType[]
  risk: IRisk
  product: IProduct
  isWorking: boolean
  error?: TErrorState
  coverPeriods?: CoverPeriodType[]
  handleCoverPeriodChange?: (summary: number) => void
  currentUserGroupType?: EUserGroups
}

export type IPolicyRouteProps = RouteComponentProps<
  {
    id: string
    subpage: string
  },
  StaticContext,
  {
    data?: string
  }
>
