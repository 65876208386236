import React from "react"
import { PageHeader } from "antd"
import { PageHeaderProps } from "antd/lib/page-header"
import styled from "styled-components"

const StyledPageHeader = styled(PageHeader)`
  border-bottom: 1px solid var(--borders);
  margin-bottom: 0px !important;
`

export const LayoutPageHeader: React.FunctionComponent<PageHeaderProps> = (
  props
) => <StyledPageHeader {...props} />
