import { Alert, Col, Layout, notification, Row } from "antd"
import { LayoutPageContent, LayoutPageHeader } from "components"
import useSearchQuery from "utils/hooks/use-search-query"
import { useOrgSettings } from "contexts/organisation/hooks/use-org-settings"
import usePlatform from "contexts/platform/use-platform"
import { useMutation, useQuery } from "react-query"
import {
  ClaimStatus,
  ClaimSubStatus,
  IPolicyClaimAddRequest
} from "platform-client/types/claims"
import { useHistory } from "react-router"
import ClaimForm, {
  IAddClaimForm,
  IClaimForm
} from "containers/claims/components/ClaimForm"
import styled from "styled-components"
import axios from "axios"
import { useState } from "react"
import { IValidationError } from "models/errors"

const StyledFormWrapper = styled.div`
  padding: 30px 0;
`

const isAddClaimForm = (
  values: IClaimForm | IAddClaimForm
): values is IAddClaimForm =>
  (values as IAddClaimForm).policyReferenceID !== undefined

const AddClaim = (props: IAddClaimProps): JSX.Element => {
  const { orgSettings } = useOrgSettings()
  const { claims } = usePlatform()
  const query = useSearchQuery()
  const history = useHistory()

  const [validationErrors, setValidationErrors] = useState<IValidationError[]>()

  const policyReferenceID =
    props.policyReferenceID || query.get("policyReferenceID")
  const productReferenceID =
    props.productReferenceID || query.get("productReferenceID")

  const getProductClaimTypes = useQuery(
    ["getProductByReferenceID", productReferenceID],
    () => {
      if (!productReferenceID) {
        throw new Error("Product reference missing.")
      }

      return claims.getProductClaimTypes(productReferenceID)
    },
    {
      enabled: !!productReferenceID
    }
  )

  const addClaim = useMutation((request: IPolicyClaimAddRequest) =>
    claims.addClaim(request)
  )

  const handleFormSubmit = (formData: IClaimForm | IAddClaimForm) => {
    setValidationErrors([])

    if (isAddClaimForm(formData)) {
      const request: IPolicyClaimAddRequest = {
        claimReference: formData.claimReference,
        claimStage: formData.claimStage,
        claimStatus: formData.claimStatus,
        claimSubStatus: formData.claimSubStatus,
        claimType: formData.claimType,
        claimValue: {
          value: Number(formData.claimValue),
          currencyCode: orgSettings?.currencyCode || "GBP"
        },
        dateOccurred: formData.dateOccurred.format("YYYY-MM-DDT00:00:00"),
        dateReported: formData.dateReported
          ? formData.dateReported.format("YYYY-MM-DDT00:00:00")
          : "",
        dateClosed: formData.dateClosed
          ? formData.dateClosed.format("YYYY-MM-DDT00:00:00")
          : undefined,
        description: formData.description,
        policyReferenceID: formData.policyReferenceID,
        sourceClaimType: formData.sourceClaimType
      }

      if (formData.sameDayReported === true) {
        request.dateReported = request.dateOccurred
      }

      if (!request.claimSubStatus) {
        switch (formData.claimStatus) {
          case ClaimStatus.Open:
          case ClaimStatus.ReOpened:
            request.claimSubStatus = ClaimSubStatus.Open
            break
          case ClaimStatus.Cancelled:
            request.claimSubStatus = ClaimSubStatus.None
            break
        }
      }

      addClaim
        .mutateAsync(request)
        .then((response) => {
          notification.success({ message: "Claim added successfully." })

          if (response.policyID) {
            setTimeout(() =>
              history.push(`/policy/${response.policyID}/claims`)
            )
          }
        })
        .catch((error) => {
          if (axios.isAxiosError(error)) {
            if (
              error.response &&
              error.response.data &&
              error.response.data.validationErrors
            ) {
              setValidationErrors(error.response.data.validationErrors)
            }

            notification.error({
              message:
                "Something went wrong adding this claim. Please try again."
            })

            return
          }

          throw new Error(error)
        })
    } else {
      notification.error({
        message: "Could not find policy reference ID"
      })
    }
  }

  return (
    <Layout>
      <LayoutPageHeader
        ghost={false}
        title={
          policyReferenceID ? `${policyReferenceID} / Add Claim` : "Add Claim"
        }
      />
      <LayoutPageContent>
        <Row justify="center" aria-label="Add new claim">
          <Col span={10}>
            <StyledFormWrapper>
              <h2>
                Add new claim{" "}
                {policyReferenceID ? ` for policy ${policyReferenceID}` : ""}
              </h2>
              {validationErrors && validationErrors.length > 0 && (
                <Alert
                  message="Sorry, we found a problem when submitting this claim:"
                  description={
                    <ul>
                      {validationErrors.map((error) => (
                        <li>{error.message}</li>
                      ))}
                    </ul>
                  }
                  type="error"
                  closable
                  style={{ marginBottom: "15px" }}
                />
              )}
              <ClaimForm
                policyReferenceID={policyReferenceID || undefined}
                handleFormSubmit={handleFormSubmit}
                isSubmitting={addClaim.isLoading}
                claimTypes={getProductClaimTypes.data}
                formButtonText="Add claim"
                currencyCode={orgSettings?.currencyCode}
              />
            </StyledFormWrapper>
          </Col>
        </Row>
      </LayoutPageContent>
    </Layout>
  )
}

export interface IAddClaimProps {
  productReferenceID?: string
  policyReferenceID?: string
}

export default AddClaim
