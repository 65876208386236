import React from "react"
import { Layout, Result, Button } from "antd"
import { LayoutPageContent } from "components"
import { Link } from "react-router-dom"
import { RouteComponentProps } from "react-router-dom"

export const DefaultView: React.FunctionComponent<IDefaultView> = (props) => {
  const STATUSES = [
    403,
    404,
    500,
    "403",
    "500",
    "success",
    "error",
    "info",
    "warning",
    undefined
  ]
  // This is here due when Result component takes other value like 400 instead of 404 or other declared crashes
  const pageStatus = STATUSES.indexOf(props.location.state?.status)
    ? "404"
    : props.location.state?.status

  return (
    <Layout>
      <LayoutPageContent>
        <Result
          status={pageStatus || "404"}
          title={props.location.state?.status || "404"}
          subTitle={
            props.location.state?.title ||
            "Sorry, the page you visited does not exist."
          }
          extra={
            <Link to={props.location.state?.redirectTo || "/quotes"}>
              <Button type="primary">
                {props.location.state?.buttonText || "Back to Quotes"}
              </Button>
            </Link>
          }
        />
      </LayoutPageContent>
    </Layout>
  )
}

export type IDefaultView = RouteComponentProps<
  {
    id: string
  },
  Record<string, unknown>,
  {
    title?: string
    subtTitle?: string
    redirectTo?: string
    buttonText?: string
    status?: TDefaultViewStatus
  }
>

export type TDefaultViewStatus =
  | 403
  | 404
  | 500
  | "403"
  | "500"
  | "success"
  | "error"
  | "info"
  | "warning"
  | undefined
