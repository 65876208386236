import { useCallback, useState } from "react"
import { IPolicyEvent } from "models/events/IEvent"
import { useAsyncEffect } from "use-async-effect"
import { TErrorState } from "models/errors"
import {
  errorValidationMessage,
  IErrorState
} from "utils/pocketknife/error-validation-message"
import useOpus from "contexts/opus/hooks/use-opus"

export const useGetPolicyEvents = (
  policyID: string | undefined
): IPolicyEvents => {
  const [events, setEvents] = useState<IPolicyEvent[] | []>([])
  const [isError, setIsError] = useState<TErrorState>(undefined)
  const [isEventsLoading, setEventsLoading] = useState<boolean>(false)
  const { policyClient } = useOpus()

  const getPolicyEvents = useCallback(async () => {
    setEventsLoading(true)
    try {
      setEventsLoading(true)
      if (policyID) {
        const _quotes = await policyClient.getPolicyEvents(policyID)
        if (_quotes) {
          setEvents(_quotes)
          setIsError(undefined)
        }
      }
      setEventsLoading(false)
    } catch (error) {
      setEvents([])
      setIsError({
        errorNumber: error?.response?.status,
        validationMessage: errorValidationMessage(error)
      })
      setEventsLoading(false)
    }
  }, [policyID, policyClient])

  const reloadEvents = async () => {
    try {
      setEventsLoading(true)
      getPolicyEvents()
      setEventsLoading(false)
    } catch (error) {
      setEvents([])
      setIsError({
        errorNumber: error?.response?.status,
        validationMessage: errorValidationMessage(error)
      })
      setEventsLoading(false)
    }
  }

  useAsyncEffect(async () => {
    try {
      setEventsLoading(true)
      getPolicyEvents()
      setEventsLoading(false)
    } catch (error) {
      setEvents([])
      setIsError({
        errorNumber: error?.response?.status,
        validationMessage: errorValidationMessage(error)
      })
      setEventsLoading(false)
    }
  }, [policyID])

  return {
    events,
    isError,
    isEventsLoading,
    reloadEvents
  }
}

interface IPolicyEvents extends IErrorState {
  events: IPolicyEvent[] | []
  isEventsLoading: boolean
  reloadEvents: () => void
}
