import {
  Breadcrumb,
  Button,
  Form,
  Input,
  Layout,
  Modal,
  Table,
  notification
} from "antd"
import { BlacklistBoundary } from "./boundary"
import { LayoutPageContent, LayoutPageHeader } from "components"
import {
  CloseSquareOutlined,
  DeleteOutlined,
  PlusCircleOutlined
} from "@ant-design/icons"
import { useMutation, useQuery } from "react-query"
import { AxiosError } from "axios"
import useOpus from "contexts/opus/hooks/use-opus"
import { IBlacklistedPhoneNumber } from "models/blocklist/blacklistedphonenumber"
import { useState } from "react"
import Column from "antd/lib/table/Column"
import formatDate from "dateformat"
import styled from "styled-components"
import { mustBeValidPhoneNumber } from "utils/validators/must-be-valid-phone-number"

const FlexDiv = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;

  > div:first-child {
    flex-grow: 1;
  }

  > div:last-child {
    text-align: right;
  }
`

const MarginDiv = styled.div`
  margin-bottom: 10px;
`

export const Blocklist: React.FC = () => {
  const opus = useOpus()
  const [form] = Form.useForm()

  const [isAddModalOpen, setIsAddModalOpen] = useState(false)
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)
  const [addButtonDisabled, setAddButtonDisabled] = useState(true)
  const [isWorking, setIsWorking] = useState(false)

  const [phoneNumber, setPhoneNumber] = useState("")

  const [blacklistedPhoneNumbers, setBlacklistedPhoneNumbers] = useState<
    IBlacklistedPhoneNumber[]
  >([])

  const { isFetching, refetch } = useQuery<
    IBlacklistedPhoneNumber[] | undefined,
    AxiosError
  >(["blocklist"], async () => opus.blacklistClient.getBlacklist(), {
    onSuccess: (result) => {
      result && setBlacklistedPhoneNumbers(result)
    }
  })

  const removeFromBlacklist = useMutation(
    (phoneNumber: string) =>
      opus.blacklistClient.removePhoneNumberFromBlacklist(phoneNumber),
    {
      onMutate: () => {
        setIsWorking(true)
      },
      onSuccess: () => {
        form.resetFields()
        setPhoneNumber("")
        setIsRemoveModalOpen(false)
        setIsWorking(false)
        refetch()
        notification.success({
          message: `Phone number removed from blocklist successfully`
        })
      }
    }
  )

  const addToBlacklist = useMutation(
    (phoneNumber: string) =>
      opus.blacklistClient.blacklistPhoneNumber(phoneNumber),
    {
      onMutate: () => {
        setIsWorking(true)
      },
      onSuccess: () => {
        form.resetFields()
        setPhoneNumber("")
        setIsAddModalOpen(false)
        setIsWorking(false)
        refetch()
        notification.success({
          message: `Phone number blocked successfully`
        })
      }
    }
  )

  const handleAdd = (phoneNumber: string) => addToBlacklist.mutate(phoneNumber)

  const handleRemove = (phoneNumber: string) =>
    removeFromBlacklist.mutate(phoneNumber)

  const openRemoveModal = (phoneNumber: string) => {
    setPhoneNumber(phoneNumber)
    setIsRemoveModalOpen(true)
  }

  const onCancelRemoveModal = () => {
    setPhoneNumber("")
    setIsRemoveModalOpen(false)
  }

  const handlePhoneNumChange = (phoneNumber: string) => {
    setPhoneNumber(phoneNumber.trim())
    form
      .validateFields()
      .then(() => {
        setAddButtonDisabled(false)
      })
      .catch(() => {
        setAddButtonDisabled(true)
      })
  }

  return (
    <BlacklistBoundary>
      <Layout>
        <LayoutPageHeader
          ghost={false}
          breadcrumb={
            <Breadcrumb>
              <Breadcrumb.Item key="/blocklist">
                <CloseSquareOutlined />
                &nbsp;SMS Blocklist
              </Breadcrumb.Item>
            </Breadcrumb>
          }
          title="SMS Blocklist"
        />
        <LayoutPageContent transparent>
          <FlexDiv>
            <div>
              <h3>Blocked Phone Numbers</h3>
            </div>
            <div>
              <Button
                type="primary"
                onClick={() => setIsAddModalOpen(true)}
                icon={<PlusCircleOutlined />}
              >
                Add
              </Button>
            </div>
          </FlexDiv>
          <Table
            loading={isFetching}
            dataSource={blacklistedPhoneNumbers}
            rowKey={(item) => item.id}
            pagination={false}
            style={{ marginTop: "10px" }}
          >
            <Column
              title="Phone Number"
              render={(item: IBlacklistedPhoneNumber) => item.phoneNumber}
            />
            <Column
              dataIndex="dateAdded"
              title="Date Added"
              render={(value: string) =>
                formatDate(new Date(value), "dd/mm/yyyy hh:MM:ss TT")
              }
            />
            <Column
              title="Added By"
              render={(item: IBlacklistedPhoneNumber) => item.createdByName}
            />
            <Column
              key="Actions"
              align="center"
              title="Actions"
              width="5%"
              render={(item: IBlacklistedPhoneNumber) => (
                <Button
                  type="primary"
                  onClick={() => openRemoveModal(item.phoneNumber)}
                  icon={<DeleteOutlined />}
                >
                  Remove
                </Button>
              )}
            />
          </Table>
        </LayoutPageContent>
      </Layout>
      <Modal
        visible={isRemoveModalOpen}
        onCancel={onCancelRemoveModal}
        title={"Remove from blocklist"}
        footer={
          <>
            <Button onClick={onCancelRemoveModal}>Cancel</Button>
            <Button
              type="primary"
              onClick={() => handleRemove(phoneNumber)}
              loading={isWorking}
            >
              Remove from blocklist
            </Button>
          </>
        }
      >
        <MarginDiv>
          Are you sure you wish to remove the below phone number from the
          blocklist?
        </MarginDiv>
        Phone number: <strong>{phoneNumber}</strong>
      </Modal>
      <Modal
        visible={isAddModalOpen}
        onCancel={() => setIsAddModalOpen(false)}
        title={"Add to blocklist"}
        footer={
          <>
            <Button onClick={() => setIsAddModalOpen(false)}>Cancel</Button>
            <Button
              type="primary"
              onClick={() => handleAdd(phoneNumber)}
              disabled={addButtonDisabled || !phoneNumber.trim().length}
              loading={isWorking}
            >
              Add to blocklist
            </Button>
          </>
        }
      >
        <Form form={form}>
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            rules={[
              {
                validator: (_, value) => {
                  const newValue = (value || "").trim()
                  if (newValue) {
                    const result = mustBeValidPhoneNumber(newValue, "AU")

                    if (result === undefined) {
                      return Promise.resolve(true)
                    }

                    return Promise.reject(result)
                  }

                  return Promise.resolve()
                }
              }
            ]}
          >
            <Input
              type="tel"
              value={phoneNumber}
              onChange={(e) => handlePhoneNumChange(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </BlacklistBoundary>
  )
}
