import { VList, VRatingsUpload } from "./"
import { DatabaseOutlined } from "@ant-design/icons"

export const RATINGS_ROUTES = [
  {
    path: "/ratings",
    component: VList
  },
  {
    path: "/ratings/upload",
    component: VRatingsUpload
  }
]

export const RATINGS_NAVIGATION = [
  {
    title: "Ratings",
    path: "/ratings",
    submenu: false,
    permission: "aventusadmin:true",
    icon: <DatabaseOutlined />,
    iconActive: <DatabaseOutlined />
  }
]
