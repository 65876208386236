import React from "react"
import { Layout, Breadcrumb } from "antd"
import { FileTextOutlined } from "@ant-design/icons"
import { RouteComponentProps } from "react-router"
import { LayoutPageHeader, LayoutPageContent } from "components"
import { InvoiceTable } from "components/InvoiceTable"

export const AllInvoices: React.FC<IAllInvoices> = (props) => {
  return (
    <Layout>
      <LayoutPageHeader
        ghost={false}
        breadcrumb={
          <Breadcrumb>
            <Breadcrumb.Item>
              <FileTextOutlined />
              &nbsp; Invoices
            </Breadcrumb.Item>
          </Breadcrumb>
        }
        title="All Invoices"
      />
      <LayoutPageContent>
        <InvoiceTable
          search={props.location.search}
          showFilter={true}
        ></InvoiceTable>
      </LayoutPageContent>
    </Layout>
  )
}

export type IAllInvoices = RouteComponentProps<{
  status?: string
}>
