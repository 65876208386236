import React from "react"
import { LayoutLoading } from "components"
import { IQuickQuoteRouteProps, OnUpdateReferral, QuickQuoteView } from "./view"
import { useConfigurationContext } from "contexts/configuration/context"
import usePlatform from "contexts/platform/use-platform"
import { useMutation, useQuery, useQueryClient } from "react-query"
import {
  IConfirmReferralQuoteProps,
  IDeclineReferralQuoteProps,
  IUpdateReferralProps
} from "platform-client/client/controllers/quote"
import { useHistory } from "react-router"
import { CoverPeriodType } from "platform-client/types"

export const QuickQuote: React.FC<IQuickQuoteRouteProps> = (props) => {
  const configuration = useConfigurationContext()
  const quoteID = props.match.params.id
  const platform = usePlatform()
  const history = useHistory()
  const queryClient = useQueryClient()

  const {
    isLoading,
    isFetching,
    isFetched,
    data: quoteBundle,
    refetch: refetchQuote
  } = useQuery(["quote", quoteID], async () => platform.quote.getQuote(quoteID))

  const quoteCompaniesQuery = useQuery(["quoteCompanies", quoteID], () =>
    platform.quote.quoteCompanies(quoteID)
  )

  const quoteEvents = useQuery(
    ["events", quoteID],
    async () => {
      return platform.quote.getQuoteEvents(quoteID)
    },
    {
      enabled: !!quoteID
    }
  )

  const quoteActions = useQuery(
    ["quote", "actions", quoteID],
    async () => platform.quote.getQuoteActions(quoteID),
    { enabled: !!quoteID }
  )

  const updateReferral = useMutation(
    (updateReferralProps: IUpdateReferralProps) =>
      platform.quote.updateReferral(
        updateReferralProps.quoteID,
        updateReferralProps.referral
      )
  )

  const confirmReferralQuote = useMutation(
    (vars: IConfirmReferralQuoteProps) =>
      platform.quote.confirmReferralQuote(vars.quoteID, vars.note),
    {
      onSuccess: (data, vars) => {
        history.push(`/quote/${vars.quoteID}/summary`)
        queryClient.setQueriesData(["quote", vars.quoteID], data)
        quoteEvents.refetch()
      }
    }
  )

  const declineReferralQuote = useMutation(
    (vars: IDeclineReferralQuoteProps) =>
      platform.quote.declineReferralQuote(
        vars.quoteID,
        vars.declineReasonReferenceID,
        vars.note
      ),
    {
      onSuccess: (data, vars) => {
        history.push(`/quote/${vars.quoteID}/summary`)
        queryClient.setQueriesData(["quote", vars.quoteID], data)
        quoteEvents.refetch()
      }
    }
  )

  const handleAcknowledgeReferral: OnUpdateReferral = async (
    vars,
    onSuccess,
    onFailure
  ) => {
    updateReferral
      .mutateAsync({
        quoteID: vars.quoteID,
        referral: {
          id: vars.referral.id,
          status: "Accepted"
        }
      })
      .then((response) => {
        refetchQuote()
        quoteEvents.refetch()
        if (typeof onSuccess === "function") {
          onSuccess(response)
        }
      })
  }

  const referrals =
    (quoteBundle && quoteBundle.quoteDetails.policyDetail?.referrals) || []
  const coverPeriods: CoverPeriodType[] | undefined =
    quoteBundle?.quoteDetails.policyDetailSet.items.map((item, i) => ({
      policyDetailIndex: i,
      coverStartDate: item.coverStartDateTime || "",
      coverEndDate: item.coverEndDateTime || ""
    }))
  if (isLoading || quoteCompaniesQuery.isLoading) {
    return <LayoutLoading />
  }
  if (quoteBundle && quoteCompaniesQuery.isFetched) {
    return (
      <QuickQuoteView
        {...props}
        configuration={configuration}
        isWorking={isLoading}
        quote={quoteBundle.quoteDetails.quote}
        risk={quoteBundle.quoteDetails.risk}
        quoteProduct={{
          product: quoteBundle.product,
          isGettingQuoteProduct: isLoading
        }}
        quoteEndorsements={
          quoteBundle.quoteDetails.policyDetailSet.latest.endorsements ||
          undefined
        }
        quoteCompanies={quoteCompaniesQuery.data}
        quotePaymentPlans={{
          paymentPlans: quoteBundle.pricingPlans,
          isGettingPaymentPlans: isLoading,
          isPaymentPlansFinished: isFetched
        }}
        quoteEvents={{
          events: quoteEvents.data,
          isGettingEvents: quoteEvents.isLoading
        }}
        quoteReferrals={referrals}
        isFetchingQuote={isFetching}
        onUpdateReferral={handleAcknowledgeReferral}
        confirmReferralQuote={confirmReferralQuote}
        declineReferralQuote={declineReferralQuote}
        onQuoteChange={() => {
          refetchQuote()
          quoteEvents.refetch()
          quoteActions.refetch()
        }}
        policyDetail={quoteBundle.quoteDetails.policyDetailSet.latest}
        policyDetailSet={quoteBundle.quoteDetails.policyDetailSet}
        coverPeriods={coverPeriods}
        quoteActions={quoteActions?.data?.actions}
      />
    )
  }

  return null
}
