import { Nullable } from "./generics"
import { IMoney } from "./pricing"

export interface IPolicyClaimType {
  id: string
  referenceID: Nullable<string>
  productReferenceID: Nullable<string>
  name: Nullable<string>
  description: Nullable<string>
}

export type AdditionalData = {
  [key: string]: unknown
}

export enum ClaimStage {
  "None",
  "Notification",
  "Claim"
}

export enum ClaimStatus {
  "Open" = 1,
  "Closed" = 2,
  "ReOpened" = 3,
  "ReClosed" = 4,
  "Cancelled" = 5
}

export enum ClaimSubStatus {
  "None",
  "Open",
  "ClosedWithdrawn",
  "ClosedSettled",
  "ClosedRepudiated",
  "ClosedTransferred",
  "ClosedError"
}

export type ClaimStatusType = "Open" | "Closed" | "Reopened" | "Cancelled"

export type ClaimSubStatusType =
  | "None"
  | "Open"
  | "ClosedWithdrawn"
  | "ClosedSettled"
  | "ClosedRepudiated"
  | "ClosedTransferred"
  | "ClosedError"

export type ClaimStageType = "None" | "Notification" | "Claim"

export interface IPolicyClaimAddRequest {
  additionalData?: AdditionalData
  claimReference: string
  claimStage: ClaimStage
  claimStatus: ClaimStatus
  claimSubStatus: ClaimSubStatus
  claimType?: string
  claimValue: IMoney
  dateClosed?: string
  dateOccurred: string
  dateReported: string
  description: Nullable<string>
  policyReferenceID: string
  sourceClaimType: string
}
export interface IPolicyClaimEditRequest {
  additionalData?: AdditionalData
  claimReference: string
  claimStage: ClaimStage
  claimStatus: ClaimStatus
  claimSubStatus: ClaimSubStatus
  claimType?: string
  claimValue: IMoney
  dateClosed?: string
  dateOccurred: string
  dateReported: string
  description: Nullable<string>
  sourceClaimType: string
}

export interface IPolicyClaim {
  id: string
  claimReference: Nullable<string>
  claimClosedDateLocal: Nullable<string>
  claimOccurredDateLocal: Nullable<string>
  claimRaisedDateLocal: Nullable<string>
  claimUpdatedDate: Nullable<string>
  createdSource: Nullable<string>
  dateCreated: string
  sourceClaimType: string
  claimStage: ClaimStageType
  claimStatus: ClaimStatusType
  claimSubStatus: ClaimSubStatusType
  claimType?: string
  claimValue: IMoney
  description: Nullable<string>
  additionalData?: AdditionalData
  policyID: string
}
