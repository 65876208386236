import { Alert, Button, Card, notification } from "antd"
import { EndorsementType, SectionTypes } from "platform-client/types"
import EndorsementsTable from "components/Endorsements"
import { LayoutPageContent } from "components/LayoutPageContent"
import { RichTextEndorsementsEditorModal } from "components/RichTextEndorsementModal"
import { useMutation, useQueryClient } from "react-query"
import usePlatform from "contexts/platform/use-platform"
import { EQuoteState } from "models/quotes/quoteState"
import { useState } from "react"
import { QuoteState, QuoteStatus } from "platform-client/types/quote"
import useOpus from "contexts/opus/hooks/use-opus"
import { IEndorsementOpus } from "models/quotes/endorsementOpus"
import { checkUserPermission } from "contexts/authorization/hooks/use-opus-permissions"
import { USER_CLAIMS } from "models/user/claims"
import { decodeJWT } from "utils/pocketknife/decode-jwt"
import { isQuoteComplete } from "utils/pocketknife/is-quote-complete"

const QuoteEndorsements: React.FC<IQuoteEndorsementsProps> = ({
  endorsements = [],
  ...props
}) => {
  const platform = usePlatform()
  const opusClient = useOpus()
  const queryClient = useQueryClient()

  const canManageEndorsements =
    checkUserPermission(
      USER_CLAIMS.find((claim) => claim.name === "endorsement")?.claim,
      opusClient.opusToken ? decodeJWT(opusClient.opusToken) : undefined
    ) ?? false

  const isCustomEndorsementEnabled = [
    EQuoteState.Open,
    EQuoteState.Quoted,
    EQuoteState.Referred,
    EQuoteState.PendingPayment
  ].some((item) => item === props.quoteState)

  const addCustomEndorsements = useMutation(
    (endorsement: { title: string; content: string; section: string }) =>
      platform.quoteBuilder.addCustomEndorsementForce(
        props.quoteId,
        endorsement.content,
        endorsement.title,
        endorsement.section,
        "Section"
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["quote", props.quoteId])
      }
    }
  )

  const addV6Endorsements = useMutation(
    (request: { quoteID: string; endorsements: IEndorsementOpus[] }) =>
      opusClient.quoteClient.addRemoveEndorsements(
        request.quoteID,
        request.endorsements,
        "Add Endorsement",
        []
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["quote-bundle", props.quoteId])
      }
    }
  )

  const editV6Endorsement = useMutation(
    (request: {
      quoteID: string
      endorsement: { referenceID: string; title: string; text: string }
    }) => {
      if (!request.endorsement.referenceID) {
        throw new Error("Missing endorsement reference ID")
      }

      return opusClient.quoteClient.editEndorsement(
        request.quoteID,
        request.endorsement.referenceID,
        request.endorsement.title,
        request.endorsement.text
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["quote-bundle", props.quoteId])
      }
    }
  )

  const handleSubmitEndorsement = (
    endorsement: {
      title: string
      content: string
      section?: string
    },
    reset: () => void
  ) => {
    setCustomEndorsementLoading(true)

    if (props.platformVersion === "v3") {
      switch (modalMode) {
        case "EDIT":
          alert("Editing V3 endorsements not currently supported")
          return
        case "ADD":
          if (endorsement.section === undefined) {
            notification.error({
              message: "Please select a section for this endorsement."
            })
            return
          }

          addCustomEndorsements
            .mutateAsync({
              title: endorsement.title,
              content: endorsement.content,
              section: endorsement.section
            })
            .then((response) => {
              if (response) {
                setCustomEndorsementLoading(false)
                setShowModal(false)
                reset()
              }
            })
            .catch(() => {
              setCustomEndorsementLoading(false)
              notification.error({
                message: "Could not add new endorsement. Please try again."
              })
            })
          return
      }
    } else {
      switch (modalMode) {
        case "EDIT":
          if (!editReferenceID) {
            notification.error({
              message: "Unable to find endorsement reference, please try again."
            })
            return
          }

          editV6Endorsement
            .mutateAsync({
              quoteID: props.quoteId,
              endorsement: {
                title: endorsement.title,
                text: endorsement.content,
                referenceID: editReferenceID
              }
            })
            .then((response) => {
              setCustomEndorsementLoading(false)
              setShowModal(false)
              reset()
            })
            .catch(() => {
              setCustomEndorsementLoading(false)
              notification.error({
                message: "Could not update endorsement, please try again."
              })
            })
          return
        case "ADD":
          addV6Endorsements
            .mutateAsync({
              quoteID: props.quoteId,
              endorsements: [
                {
                  referenceID: null,
                  title: endorsement.title,
                  text: endorsement.content
                }
              ]
            })
            .then((response) => {
              setCustomEndorsementLoading(false)
              setShowModal(false)
              reset()
            })
            .catch(() => {
              setCustomEndorsementLoading(false)
              notification.error({
                message: "Could not add new endorsement. Please try again."
              })
            })
          return
      }
    }
  }

  const [showModal, setShowModal] = useState<boolean>(false)
  const [customEndorsementLoading, setCustomEndorsementLoading] =
    useState<boolean>(false)
  const [modalMode, setModalMode] = useState<"ADD" | "EDIT">("ADD")
  const [editTitle, setEditTitle] = useState<string | undefined>()
  const [editContent, setEditContent] = useState<string | undefined>()
  const [editReferenceID, setEditReferenceID] = useState<string | undefined>()

  if (!isQuoteComplete(props.quoteState, props.quoteStatus)) {
    return (
      <Alert message="Endorsements are not available for an incomplete quote" />
    )
  }

  return (
    <LayoutPageContent transparent>
      <RichTextEndorsementsEditorModal
        title={editTitle}
        content={editContent}
        visible={showModal}
        headerText={
          modalMode === "ADD"
            ? "Add custom endorsement"
            : "Edit custom endorsement"
        }
        sections={props.sections || []}
        onSubmit={handleSubmitEndorsement}
        isLoading={customEndorsementLoading}
        onCancel={(reset) => {
          setShowModal(false)
          setEditTitle(undefined)
          setEditContent(undefined)
          setEditReferenceID(undefined)
          reset()
        }}
      />
      {canManageEndorsements &&
        isCustomEndorsementEnabled &&
        props.isExternal === false && (
          <Card>
            <Button
              type={"primary"}
              onClick={() => {
                setShowModal(true)
                setModalMode("ADD")
              }}
            >
              Add custom endorsement
            </Button>
          </Card>
        )}
      <EndorsementsTable
        endorsements={endorsements}
        quoteID={props.quoteId}
        showEditModal={(title, text, referenceID) => {
          setEditTitle(title)
          setEditContent(text)
          setEditReferenceID(referenceID)
          setModalMode("EDIT")
          setShowModal(true)
        }}
        canManageEndorsements={canManageEndorsements}
      />
    </LayoutPageContent>
  )
}

interface IQuoteEndorsementsProps {
  sections?: SectionTypes[]
  quoteState: QuoteState
  quoteStatus: QuoteStatus
  endorsements?: EndorsementType[]
  quoteId: string
  platformVersion?: string
  isExternal: boolean
}

export default QuoteEndorsements
