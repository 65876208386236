import React from "react"
import { Row, Col, Card, Form, DatePicker, Button, Input, Tag } from "antd"
import { IEmbargoDetailsProps } from "./types"
import {
  dateFromTodayToSpecifiedDate,
  dateAfterTodayOrSpecifiedDate
} from "utils/pocketknife/date-validation"

export const EmbargoDetails: React.FunctionComponent<IEmbargoDetailsProps> = ({
  initialFormValues,
  days,
  form,
  loading,
  embargoes,
  onDatepickerChange,
  onAddEmbargoValues,
  onEmbargoSubmit,
  onEmbargoRemove
}) => {
  const halfWidth = {
    display: "inline-block",
    width: "calc(50% - 8px)",
    marginBottom: 10
  }

  const renderTags = (): React.ReactElement | void => {
    if (embargoes && embargoes.length) {
      return (
        <div>
          <p>Current Embargo Values:</p>
          {embargoes.map((item, k) => {
            return item ? (
              <Tag
                color="blue"
                key={k}
                closable
                onClose={(event) => onEmbargoRemove(event, item)}
              >
                {item}
              </Tag>
            ) : undefined
          })}
        </div>
      )
    }
  }

  return (
    <Form
      form={form}
      name="embargo_details"
      layout="vertical"
      initialValues={initialFormValues}
      onFinish={onEmbargoSubmit}
    >
      <Row gutter={[16, 16]} align="stretch">
        <Col span={24} lg={{ span: 12 }}>
          <Card style={{ height: "100%", marginBottom: "20px" }}>
            <Form.Item style={{ marginBottom: 8 }}>
              <Form.Item
                name="startTime"
                label="Embargo Start Date"
                tooltip="The local time this embargo should start"
                style={{ ...halfWidth, marginRight: 16 }}
                rules={[
                  {
                    required: true,
                    message: "Embargo requires a start date."
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      return dateFromTodayToSpecifiedDate(
                        value,
                        getFieldValue("endTime")
                      )
                        ? Promise.resolve()
                        : Promise.reject(
                            "Embargo cannot start before today or after end date."
                          )
                    }
                  })
                ]}
              >
                <DatePicker
                  disabledDate={(current) =>
                    !dateFromTodayToSpecifiedDate(
                      current,
                      form.getFieldValue("endTime")
                    )
                  }
                  allowClear={false}
                  onChange={() => onDatepickerChange()}
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                name="endTime"
                tooltip="The local time this embargo should no longer apply"
                label="Embargo End Date"
                style={halfWidth}
                rules={[
                  {
                    required: true,
                    message: "Embargo requires an end date."
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      return dateAfterTodayOrSpecifiedDate(
                        value,
                        getFieldValue("startTime")
                      )
                        ? Promise.resolve()
                        : Promise.reject(
                            "Embargo cannot end today or before/on start date."
                          )
                    }
                  })
                ]}
              >
                <DatePicker
                  disabledDate={(current) =>
                    !dateAfterTodayOrSpecifiedDate(
                      current,
                      form.getFieldValue("startTime")
                    )
                  }
                  allowClear={false}
                  onChange={() => onDatepickerChange()}
                  style={{ width: "100%" }}
                />
              </Form.Item>
              {!!days && (
                <Form.Item style={{ marginBottom: 0 }}>
                  <span>
                    Embargo duration: {days} day{days > 1 ? "s" : ""}
                  </span>
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item style={{ marginBottom: 20 }}>
              <Form.Item
                name="schemeID"
                label="Scheme ID"
                style={{ ...halfWidth, marginRight: 16, marginBottom: 10 }}
                rules={[
                  {
                    type: "string",
                    pattern: new RegExp(/^\d+$/),
                    message: "Must be a number"
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value || getFieldValue("schemeReferenceID")) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        "Please complete either Scheme ID or Scheme Reference!"
                      )
                    }
                  })
                ]}
              >
                <Input placeholder="Please enter scheme ID" />
              </Form.Item>
              <Form.Item
                name="schemeReferenceID"
                label="Scheme Reference"
                style={{ ...halfWidth, marginBottom: 10 }}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value || getFieldValue("schemeID")) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        "Please complete either Scheme ID or Scheme Reference!"
                      )
                    }
                  })
                ]}
              >
                <Input placeholder="Please enter scheme reference" />
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <span className="ant-form-item-explain">
                  Use either a schemeID or a schemeReferenceID. Using the
                  reference matches all versions of the scheme.
                </span>
              </Form.Item>
            </Form.Item>
            <Form.Item
              name="errorMessage"
              style={{ marginBottom: 0 }}
              tooltip="The error message that will display to the user. This must be set per embargo (even if its always the same)"
              label="Embargo Message"
              rules={[
                {
                  required: true,
                  message:
                    "Please enter the error message that will be displayed to the user."
                }
              ]}
            >
              <Input.TextArea placeholder="Please enter an error message related to this embargo" />
            </Form.Item>
          </Card>
        </Col>
        <Col span={24} lg={{ span: 12 }}>
          <Card style={{ height: "100%", marginBottom: "20px" }}>
            <Form.Item
              name="riskPath"
              tooltip="Path in the risk to check the embargo against."
              label="Risk Path"
              style={{ marginBottom: 20 }}
              rules={[{ required: true, message: "Please enter a risk path" }]}
            >
              <Input placeholder="Please enter a risk path" />
            </Form.Item>
            <Form.Item
              name="values"
              tooltip="Enter values separated by a ',' and click 'Add Values' button"
              label="Add Embargo Values"
              style={{ marginBottom: 8 }}
              rules={[
                () => ({
                  validator(_, value) {
                    if (embargoes && embargoes.length) {
                      return Promise.resolve()
                    }
                    return Promise.reject("Please add values to this embargo.")
                  }
                })
              ]}
            >
              <Input.TextArea
                placeholder="The values to match against the risk path separated with a comma. e.g: 1456, 3456, 2934"
                style={{ marginBottom: 8 }}
              ></Input.TextArea>
            </Form.Item>
            <Button
              style={{ marginRight: 8, marginBottom: 10 }}
              type="primary"
              htmlType="button"
              onClick={onAddEmbargoValues}
            >
              Add Values
            </Button>
            {renderTags()}
          </Card>
        </Col>
        <Col span={24}>
          <Card style={{ height: "100%" }}>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                style={{ marginRight: 8 }}
                loading={loading}
                type="primary"
                htmlType="submit"
              >
                Create Embargo
              </Button>
            </Form.Item>
          </Card>
        </Col>
      </Row>
    </Form>
  )
}
