import { Button, DatePicker, notification, Space } from "antd"
import { AxiosError } from "axios"
import useOpus from "contexts/opus/hooks/use-opus"
import React, { useEffect } from "react"
import { useMutation } from "react-query"
import styled from "styled-components"
import { handleValidationErrors } from "utils/pocketknife/handle-validation-errors"

import { IUpdatePolicyCoverStartDateParams } from "platform-client/types/policy"
import moment from "moment"
import { DateDisplay } from "components/display/DateDisplay"

const StyledFloatRightButton = styled(Button)`
  float: right;
`

const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

export const PolicyCoverStartDate: React.FunctionComponent<IPolicyCoverStartDate> =
  (props) => {
    const { policyClient } = useOpus()
    const originalCoverStartDate = props.coverStartDate

    const updateCoverStartDate = useMutation(
      (params: IUpdatePolicyCoverStartDateParams) =>
        policyClient.updateCoverStartDate(
          params.policyId,
          params.coverStartDate
        ),
      {
        onSuccess: () => {
          notification.success({
            message: `Cover Start date updated`
          })
          setisEdit(false)
          setIsLoading(false)
          if (typeof props.refetchPolicyBundle === "function") {
            props.refetchPolicyBundle()
          }
        },
        onError: (error: AxiosError) => {
          handleValidationErrors(error)
          setisEdit(true)
          setIsLoading(false)
        }
      }
    )

    const submitChange = (params: IUpdatePolicyCoverStartDateParams) => {
      setIsLoading(true)
      updateCoverStartDate.mutate(params)
    }
    const [isEdit, setisEdit] = React.useState<boolean>(false)
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [coverStartDate, setCoverStartDate] = React.useState(
      moment(props.coverStartDate)
    )

    useEffect(() => {
      setCoverStartDate(moment(props.coverStartDate))
    }, [props.coverStartDate])

    return (
      <InputWrapper>
        {isEdit && (
          <>
            <DatePicker
              value={moment(coverStartDate).startOf("day")}
              onChange={(value) =>
                setCoverStartDate(
                  value || moment(props.coverStartDate).startOf("day")
                )
              }
              allowClear={false}
            />
            <StyledFloatRightButton
              type="primary"
              onClick={() => {
                submitChange({
                  policyId: props.policyId,
                  coverStartDate: coverStartDate.format("YYYY-MM-DD")
                })
              }}
              loading={isLoading}
            >
              Save
            </StyledFloatRightButton>
            <StyledFloatRightButton
              onClick={() => {
                setisEdit(false)
                setCoverStartDate(moment(originalCoverStartDate))
              }}
            >
              Cancel
            </StyledFloatRightButton>
          </>
        )}{" "}
        {!isEdit && (
          <>
            <Space size="middle">
              <DateDisplay date={coverStartDate.toISOString()} />
              {props.canEdit && (
                <StyledFloatRightButton
                  type="primary"
                  onClick={() => setisEdit(true)}
                >
                  Edit
                </StyledFloatRightButton>
              )}
            </Space>
          </>
        )}
      </InputWrapper>
    )
  }

export interface IPolicyCoverStartDate {
  policyId: string
  coverStartDate: string
  canEdit: boolean
  refetchPolicyBundle?: () => void
}
