import { Button, Select, Space, Table, notification } from "antd"
import { useEffect, useMemo, useState } from "react"
import formatDate from "dateformat"
import { useQuery } from "react-query"
import { AxiosError } from "axios"
import usePlatform from "contexts/platform/use-platform"
import Column from "antd/lib/table/Column"
import { ITransactionalMessage } from "models/documents/message"
import {
  DownloadOutlined,
  FileTextOutlined,
  SendOutlined
} from "@ant-design/icons"
import moment from "moment"
import { Nullable } from "platform-client/types"
import { ConfirmEmailModal } from "components/ConfirmEmailModal"
import { useResendTransactionalEmail } from "contexts/opus/hooks/use-resend-transactional-email"
import styled from "styled-components"
import { convertCamelCaseToSpaces } from "utils/pocketknife/camel-case-to-spaces"

const StyledDiv = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 10px;

  > div:first-child {
    flex-grow: 1;
  }

  > div:last-child {
    text-align: right;
  }
`

interface ISelectOption {
  label: string
  value: string
}

export const BouncedEmails: React.FunctionComponent = () => {
  const [bouncedMessages, setBouncedMessages] = useState<
    ITransactionalMessage[] | []
  >([])
  const platform = usePlatform()

  const options = useMemo(() => {
    return [
      {
        label: "last 24 hours",
        value: moment().subtract(1, "days").format("YYYY-MM-DDT00:00:00")
      },
      {
        label: "last 7 days",
        value: moment().subtract(7, "days").format("YYYY-MM-DDT00:00:00")
      },
      {
        label: "last 14 days",
        value: moment().subtract(14, "days").format("YYYY-MM-DDT00:00:00")
      },
      {
        label: "last 30 days",
        value: moment().subtract(30, "days").format("YYYY-MM-DDT00:00:00")
      }
    ]
  }, [])

  const [selectedEmail, setSelectedEmail] =
    useState<Nullable<ITransactionalMessage>>(null)

  const [selectedSentAfterOption, setSelectedSentAfterOption] = useState<
    Nullable<ISelectOption>
  >(options[0])

  const { isFetching, refetch } = useQuery<
    ITransactionalMessage[] | undefined,
    AxiosError
  >(
    ["bounced-emails"],
    async () =>
      platform.report.getTransactionalEmails(
        "Bounced",
        selectedSentAfterOption?.value ||
          moment().subtract(7, "days").format("YYYY-MM-DDT00:00:00") // 7 days worth of bounced emails
      ) || [],
    {
      onSuccess: (result) => {
        if (result) {
          setBouncedMessages(result)
        }
      }
    }
  )

  const onDownloadReportSuccess = (response: {
    data: ArrayBuffer
    headers: {
      "x-filename": string
    }
  }) => {
    const a = document.createElement("a")
    a.style.display = "none"
    document.body.appendChild(a)

    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: "text/csv" })
    )
    a.href = url
    a.download = response.headers["x-filename"]
    a.click()
    window.URL.revokeObjectURL(url)

    notification.success({
      message:
        "Report downloaded successfully. Please check your downloads folder."
    })
  }

  const {
    isFetching: isDownloadingAllTimeReport,
    refetch: downloadAllTimeReport
  } = useQuery<
    { data: ArrayBuffer; headers: { "x-filename": string } },
    AxiosError
  >(
    ["bounced-emails-download-all-time"],
    async () =>
      platform.report.downloadTransactionalEmails(
        "Bounced",
        moment().subtract(5, "years").format("YYYY-MM-DDT00:00:00")
      ),
    {
      onSuccess: onDownloadReportSuccess,
      enabled: false
    }
  )

  const {
    isFetching: isDownloadingInViewReport,
    refetch: downloadInViewReport
  } = useQuery<
    { data: ArrayBuffer; headers: { "x-filename": string } },
    AxiosError
  >(
    ["bounced-emails-download-in-view"],
    async () =>
      platform.report.downloadTransactionalEmails(
        "Bounced",
        selectedSentAfterOption?.value ?? ""
      ),
    {
      onSuccess: onDownloadReportSuccess,
      enabled: false
    }
  )

  const { isResending, resendTransactionalEmail } =
    useResendTransactionalEmail()

  const viewEmail = (email: ITransactionalMessage) => {
    return () => {
      if (typeof email.emailUrl === "string") {
        window.open(email.emailUrl, "_blank")
      }
    }
  }

  const renderMessage = (message: ITransactionalMessage) => {
    let msg = ""
    if (message.type === "Email" && message.subject) {
      msg = message.subject
    } else if (message.type === "SMS" && message.message) {
      msg = message.message
    }

    return msg
  }

  const renderLastModified = (message: ITransactionalMessage) => {
    const date: string =
      message.lastModified !== "1970-01-01T10:00:00"
        ? message.lastModified
        : message.localDateCreated
    return formatDate(new Date(date), "dd/mm/yyyy hh:MM:ss TT")
  }

  const resendEmail = async (emailAddress: string) => {
    selectedEmail &&
      (await resendTransactionalEmail(selectedEmail.id, emailAddress))
    setSelectedEmail(null)
    refetch()
  }

  const handleOnSentAfterChange = (_: string, option: unknown) => {
    setSelectedSentAfterOption(option as ISelectOption)
  }

  useEffect(() => {
    if (isFetching === false && bouncedMessages === null) {
      refetch()
    }
  })

  useEffect(() => {
    refetch()
  }, [selectedSentAfterOption, refetch])

  return (
    <>
      <StyledDiv>
        <div>
          <h4>
            {selectedSentAfterOption?.label} | {bouncedMessages.length} bounced
            emails
          </h4>
        </div>
        <div>
          <Button
            type="primary"
            onClick={() => downloadAllTimeReport()}
            loading={isDownloadingAllTimeReport}
            icon={<DownloadOutlined />}
          >
            Download all-time report
          </Button>
        </div>
      </StyledDiv>

      <Table
        loading={isFetching}
        dataSource={bouncedMessages}
        rowKey={(message) => message.id}
        pagination={false}
        style={{ marginTop: "10px" }}
      >
        <Column
          dataIndex="localDateCreated"
          title="Timestamp"
          render={(value: string) =>
            formatDate(new Date(value), "dd/mm/yyyy hh:MM:ss TT")
          }
        />
        <Column title="Sent To" dataIndex="sentTo" />
        <Column
          title="Subject"
          render={(message: ITransactionalMessage) => renderMessage(message)}
        />
        <Column
          title="Delivery Status"
          render={(message: ITransactionalMessage) => (
            <>
              {message.deliveryStatus &&
                convertCamelCaseToSpaces(message.deliveryStatus)}{" "}
              {message.deliveryStatusDetail &&
              message.deliveryStatusDetail !== "NotSet" &&
              message.deliveryStatusDetail !== "SpamComplaint"
                ? `(${convertCamelCaseToSpaces(message.deliveryStatusDetail)})`
                : ""}
            </>
          )}
        />
        <Column
          title="Last Modified"
          render={(message: ITransactionalMessage) =>
            renderLastModified(message)
          }
        />
        <Column
          key="Actions"
          align="right"
          title={
            <StyledDiv>
              <div>
                <Button
                  onClick={() => downloadInViewReport()}
                  loading={isDownloadingInViewReport}
                  icon={<DownloadOutlined />}
                >
                  Download
                </Button>
              </div>
              <div>
                <Select
                  options={options}
                  onChange={handleOnSentAfterChange}
                  defaultValue="last 24 hours"
                  dropdownMatchSelectWidth
                />
              </div>
            </StyledDiv>
          }
          render={(message: ITransactionalMessage) => (
            <>
              {message.type === "Email" && (
                <>
                  <Space>
                    <Button
                      icon={<FileTextOutlined />}
                      size="small"
                      onClick={viewEmail(message)}
                      disabled={!(typeof message.emailUrl === "string")}
                    >
                      View
                    </Button>
                    <Button
                      icon={<SendOutlined />}
                      size="small"
                      onClick={() => setSelectedEmail(message)}
                    >
                      Attempt Resend
                    </Button>
                  </Space>
                </>
              )}
            </>
          )}
        />
      </Table>
      <ConfirmEmailModal
        emailAddress={selectedEmail?.sentTo ?? ""}
        title={`Resend email ${
          selectedEmail?.subject ? `- ${selectedEmail.subject}` : ""
        }`}
        description="Please provide the email address you would like to send the
      email to."
        isVisible={!!selectedEmail}
        onCancel={(e) => setSelectedEmail(null)}
        onSend={resendEmail}
        isLoading={isResending}
      />
    </>
  )
}
