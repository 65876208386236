import { IQuoteWithdrawReason } from "contexts/opus/context"
import { useState } from "react"
import { useAsyncEffect } from "use-async-effect"
import { TErrorState } from "models/errors"
import {
  errorValidationMessage,
  IErrorState
} from "utils/pocketknife/error-validation-message"
import useOpus from "contexts/opus/hooks/use-opus"

export const useGetQuoteWithdrawReasons = (quoteID?: string): IUseGetQuotes => {
  const [withdrawReasons, setWithdrawReasons] = useState<
    IQuoteWithdrawReason[] | []
  >([])
  const [isError, setIsError] = useState<TErrorState>(undefined)
  const [isWithdrawReasonsLoading, setWithdrawReasonsLoading] =
    useState<boolean>(false)

  const { quoteClient } = useOpus()

  useAsyncEffect(
    async (isMounted) => {
      try {
        setWithdrawReasonsLoading(true)
        if (quoteID) {
          const _reasons = await quoteClient.getQuotesWithdrawReasons(quoteID)
          if (isMounted() && _reasons) {
            setWithdrawReasons(_reasons)
            setIsError(undefined)
          }
        }
        setWithdrawReasonsLoading(false)
      } catch (error) {
        setWithdrawReasons([])
        setIsError({
          errorNumber: error?.response?.status,
          validationMessage: errorValidationMessage(error)
        })
        setWithdrawReasonsLoading(false)
      }
    },
    [quoteID]
  )

  return {
    withdrawReasons,
    isError,
    isWithdrawReasonsLoading
  }
}

interface IUseGetQuotes extends IErrorState {
  withdrawReasons: IQuoteWithdrawReason[] | []
  isWithdrawReasonsLoading: boolean
}
