import React, { useCallback, useMemo } from "react"
import { Menu } from "antd"
import { Link, matchPath, useLocation } from "react-router-dom"
import {
  checkUserPermission,
  useOpusPermissions
} from "contexts/authorization/hooks/use-opus-permissions"
import { useConfigurationContext } from "contexts/configuration/context"
import { NAVIGATION, TNavigation } from "routes"
import { useNavItems } from "utils/useNavItems"
import styled from "styled-components"
import useOpus from "contexts/opus/hooks/use-opus"
import { getUserTokenValue } from "utils/pocketknife/get-user-token-value"

const buildMenuItem = (
  navItem: TNavigation,
  isCurrentPath: (path: string) => boolean
) =>
  !navItem.submenu ? (
    <Menu.Item
      key={navItem.path}
      icon={isCurrentPath(navItem.path) ? navItem.iconActive : navItem.icon}
    >
      <Link to={navItem.path || ""}>{navItem.title}</Link>
    </Menu.Item>
  ) : (
    <Menu.SubMenu key={navItem.path} title={<span>{navItem.title}</span>}>
      {Array.isArray(navItem.submenu) &&
        navItem.submenu.map((subItem, key) => (
          <Menu.Item key={`${navItem.title}${key}`}>
            <Link to={subItem.path || ""}>{subItem.title}</Link>
          </Menu.Item>
        ))}
    </Menu.SubMenu>
  )

const StyledMenu = styled(Menu)`
  border-bottom: none;
  background: transparent !important;
  &.ant-menu-horizontal > .ant-menu-item::after,
  &.ant-menu-horizontal > .ant-menu-submenu::after {
    left: 14px;
    right: 14px;
  }
  &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
  &.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    padding: 0 14px;
  }

  // Override display:flex with display:inherit as display:flex prevents
  // collapsing the menu in firefox
  &.ant-menu-overflow {
    display: inherit;
  }
`

export const HeaderMenu: React.FC = () => {
  const location = useLocation()
  const { permissions } = useOpusPermissions()
  const { opusToken } = useOpus()
  const configuration = useConfigurationContext()

  const userGroup = getUserTokenValue("grouptype", opusToken)

  const navItems = useNavItems(NAVIGATION)

  const currentPath = location.pathname
  const isCurrentPath = useCallback(
    (path: string) => matchPath(currentPath, { path }) !== null,
    [currentPath]
  )
  const getCurrentPath = useMemo(() => {
    for (const navItem of navItems) {
      if (matchPath(currentPath, { path: navItem.path }) !== null)
        return navItem.path
      if (navItem.childPaths !== undefined)
        for (const subKey of navItem?.childPaths) {
          if (matchPath(currentPath, { path: subKey }) !== null)
            return navItem.path
        }
    }
    return currentPath
  }, [currentPath, navItems])

  return (
    <StyledMenu
      mode="horizontal"
      selectedKeys={[getCurrentPath ?? ""]}
      inlineIndent={12}
    >
      {navItems.map((navItem: TNavigation) => {
        const { permission, groups, version } = navItem

        const _version = version
          ? version === configuration.flags.platformVersion
          : true

        const _permission = permission
          ? checkUserPermission(permission, permissions)
          : true

        const _groups =
          groups && groups.length > 0
            ? groups.includes(String(userGroup))
            : true

        const hasPermission = _version && _permission && _groups

        if (hasPermission) {
          return buildMenuItem(navItem, isCurrentPath)
        }

        return false
      })}
    </StyledMenu>
  )
}
