import { useState } from "react"
import {
  Button,
  Descriptions,
  Form,
  Modal,
  notification,
  Select,
  Spin,
  Row,
  Popconfirm,
  Input,
  Checkbox
} from "antd"
import styled from "styled-components"
import { useHistory } from "react-router"
import {
  IPolicy,
  IPolicyCancellationResponse,
  IPreviewCancellationProps,
  ICancellationProps
} from "platform-client/types"
import { useMutation, useQuery } from "react-query"
import usePlatform from "contexts/platform/use-platform"
import { useOrgSettings } from "contexts/organisation/hooks/use-org-settings"
import getCurrencySymbol from "utils/pocketknife/get-currency-symbol"
import formatDate from "dateformat"
import { MoneyDisplay } from "components/display/MoneyDisplay"

const StyledMoneyDescriptions = styled(Descriptions)`
  .ant-descriptions-item.total .ant-descriptions-item-label,
  .ant-descriptions-item.total .ant-descriptions-item-content {
    font-weight: bold;
  }

  .ant-descriptions-item-content {
    justify-content: flex-end;
  }
`

const PolicyCancellationWithSummary: React.FC<IPolicyCancellationWithSummary> =
  (props) => {
    const platform = usePlatform()
    const { orgSettings } = useOrgSettings()
    const history = useHistory()
    const [visible, setVisible] = useState<boolean>(false)
    const [cancellationReason, setCancellationReason] = useState<string>("")
    const [overrideFeeValue, setOverrideFeeValue] = useState<string>("")
    const [isFeeOverriden, setFeeOverride] = useState<boolean>(false)
    const [policyPreview, setPolicyPreview] =
      useState<IPolicyCancellationResponse>()

    // You might want to use Form.useForm to manage the form data
    // and events when updating fields.

    // I would use the form onSubmit to send the final cancellation request
    // that way you can use the built in validation from AntD.

    const cancellationReasons = useQuery(
      ["policy.cancellationReasons", { id: props.policy.id }],
      () => platform.policy.cancellationReasons(props.policy.id)
    )

    const previewCancellation = useMutation(
      (vars: IPreviewCancellationProps) =>
        platform.policy.previewCancellation(
          vars.policyID,
          vars.cancellationDetails
        ),
      {
        onSuccess: (response) => {
          setPolicyPreview(response)
        },

        onError: () => {
          notification.error({
            message: "Unable to get policy cancellation preview. "
          })
        }
      }
    )

    const cancelPolicy = useMutation(
      (vars: ICancellationProps) =>
        platform.policy.cancelPolicy(vars.policyID, vars.cancellationDetails),
      {
        onSuccess: () => {
          history.push("/policies")
        },
        onError: () => {
          notification.error({
            message: "Unable to cancel the policy"
          })
        }
      }
    )

    const handleCancel = () => {
      setVisible(false)
    }

    // NOTE: ask wether we should use gross or net
    const { gross: cancellationFee } =
      previewCancellation.data?.policyBundle.policyDetails.policyDetail.fees.find(
        ({ referenceID = "" }) => referenceID.includes("_f_cancellationfee")
      ) || {}

    const handleCancellation = () => {
      cancelPolicy
        .mutateAsync({
          policyID: props.policy.id,
          cancellationDetails: {
            cancellationReasonReferenceID: cancellationReason,
            effectiveCancellationDate: undefined,
            requestCancellationDate: undefined,
            feeOverride: isFeeOverriden
              ? {
                  value: Number(overrideFeeValue) || 0,
                  currencyCode:
                    cancellationFee?.currencyCode ||
                    orgSettings?.currencyCode ||
                    "GBP"
                }
              : null
          }
        })
        .then(() => setVisible(false))
    }

    return (
      <>
        <Button
          loading={cancellationReasons.isLoading}
          onClick={() => setVisible(true)}
        >
          Cancel Policy
        </Button>
        <Modal
          visible={visible}
          onCancel={handleCancel}
          onOk={handleCancellation}
          footer={
            <Row justify="space-between">
              <Button onClick={handleCancel}>Exit</Button>
              <Popconfirm
                title="Are you sure you wish to cancel your Policy"
                disabled={
                  previewCancellation.isLoading || cancelPolicy.isLoading
                }
                okButtonProps={{
                  htmlType: "submit",
                  form: "policyCancellationForm"
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  form="policyCancellationForm"
                  disabled={
                    previewCancellation.isLoading || cancelPolicy.isLoading
                  }
                >
                  Cancel Policy
                </Button>
              </Popconfirm>
            </Row>
          }
        >
          <Spin
            spinning={previewCancellation.isLoading || cancelPolicy.isLoading}
          >
            <Form
              layout="vertical"
              id="policyCancellationForm"
              onFinish={handleCancellation}
            >
              <Form.Item
                name="cancellation_reason"
                label="Cancellation reason:"
                rules={[
                  {
                    required: true,
                    message: "Please select cancellation reason"
                  }
                ]}
              >
                <Select
                  placeholder="Select cancellation reason"
                  onChange={(reason) => {
                    setCancellationReason(reason as string)
                    previewCancellation.mutateAsync({
                      policyID: props.policy.id,
                      cancellationDetails: {
                        cancellationReasonReferenceID: reason as string,
                        effectiveCancellationDate: undefined,
                        requestCancellationDate: undefined
                      }
                    })
                  }}
                  options={cancellationReasons.data?.map((item) => ({
                    label: item.text,
                    value: item.referenceID
                  }))}
                />
              </Form.Item>
              <Row justify="space-between">
                <Form.Item
                  name="cancellation_fee"
                  label="Cancellation fee:"
                  rules={[
                    {
                      required: isFeeOverriden,
                      message: "Please enter fee override value"
                    }
                  ]}
                >
                  {isFeeOverriden ? (
                    <Row>
                      <Input
                        autoFocus
                        type="number"
                        style={{ width: "120px" }}
                        prefix={getCurrencySymbol(
                          cancellationFee?.currencyCode ||
                            orgSettings?.currencyCode ||
                            "GBP"
                        )}
                        value={overrideFeeValue}
                        onChange={(event) =>
                          setOverrideFeeValue(event.target.value)
                        }
                      />
                    </Row>
                  ) : (
                    <Row>
                      {cancellationFee && cancellationFee.value > 0 ? (
                        <MoneyDisplay value={cancellationFee} />
                      ) : (
                        "-"
                      )}
                    </Row>
                  )}
                </Form.Item>
                <Checkbox
                  disabled={!policyPreview}
                  checked={isFeeOverriden}
                  onChange={() => setFeeOverride((state) => !state)}
                >
                  Override fee
                </Checkbox>
              </Row>
            </Form>
            <Descriptions
              size="small"
              column={1}
              style={{ margin: "20px 0 0" }}
            >
              <Descriptions.Item label="Policy Reference">
                {policyPreview?.policyBundle.policyDetails.policy
                  .policyReferenceID || "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Policy Start">
                {policyPreview
                  ? formatDate(
                      new Date(
                        policyPreview.policyBundle.policyDetails.policy
                          .coverStartDate || ""
                      ),
                      "dd/mm/yyyy h:MMtt"
                    )
                  : "-"}
              </Descriptions.Item>
              <Descriptions.Item label="Policy End">
                {policyPreview
                  ? formatDate(
                      new Date(
                        policyPreview.policyBundle.policyDetails.policy
                          .coverEndDate || ""
                      ),
                      "dd/mm/yyyy h:MMtt"
                    )
                  : "-"}
              </Descriptions.Item>
            </Descriptions>
            <hr />
            <StyledMoneyDescriptions
              size="small"
              column={1}
              style={{ margin: "20px 0 0" }}
            >
              <Descriptions.Item label="Insurance Premium">
                {policyPreview ? (
                  <MoneyDisplay
                    value={policyPreview?.policyBundle.price.total.net}
                  />
                ) : (
                  "-"
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Tax">
                {policyPreview ? (
                  <MoneyDisplay
                    value={policyPreview.policyBundle.price.total.tax?.total}
                  />
                ) : (
                  "-"
                )}
              </Descriptions.Item>
              <Descriptions.Item className="total" label="Total">
                {policyPreview ? (
                  <MoneyDisplay
                    value={policyPreview?.policyBundle.price.total.gross}
                  />
                ) : (
                  "-"
                )}
              </Descriptions.Item>
            </StyledMoneyDescriptions>
          </Spin>
        </Modal>
      </>
    )
  }

export default PolicyCancellationWithSummary

interface IPolicyCancellationWithSummary {
  policy: IPolicy
}
