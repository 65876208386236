import { useState, useRef, useEffect, useCallback } from "react"
import { TQuoteRenewal } from "models/quotes/quoteRenewal"
import useOpus from "contexts/opus/hooks/use-opus"

export const useSetPolicyRenewalQuote = (
  policyID: string | undefined,
  successCallback: () => void
): IUseSetPolicyRenewalQuote => {
  const [isSending, setIsSending] = useState(false)
  const [isError, setIsError] = useState(false)
  const [renewalQuote, setRenewalQuote] = useState<TQuoteRenewal | undefined>(
    undefined
  )
  const isMounted = useRef(true)
  const { policyClient } = useOpus()

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const sendRequest = useCallback(async () => {
    if (isSending) return

    setIsSending(true)

    try {
      if (policyID) {
        const _renewalQuote = await policyClient.setPolicyRenewalQuote(policyID)
        setRenewalQuote(_renewalQuote)
        successCallback()
      }
    } catch (error) {
      setIsError(true)
    }

    setIsSending(false)

    if (isMounted.current) setIsSending(false)
  }, [isSending, policyClient, policyID, successCallback])

  return { renewalQuote, sendRequest, isSending, isError }
}

interface IUseSetPolicyRenewalQuote {
  sendRequest: () => Promise<void>
  renewalQuote: TQuoteRenewal | undefined
  isError: boolean
  isSending: boolean
}
