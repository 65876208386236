import { useState, useRef, useEffect, useCallback } from "react"
import useOpus from "contexts/opus/hooks/use-opus"
import { notification } from "antd"
import { Nullable } from "platform-client/types"

export const useRegenerateDocument = (): IUseRegenerateDocument => {
  const [isRegenerating, setIsRegenerating] = useState(false)
  const [isError, setIsError] = useState(false)

  const isMounted = useRef(true)
  const { commandClient } = useOpus()

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const regenerateDocument = useCallback(
    async (quoteId, policyId, isCancellation) => {
      if (isRegenerating) return

      setIsRegenerating(true)

      try {
        if (quoteId || policyId) {
          const response = await commandClient.regenerateDocument(
            quoteId,
            policyId,
            isCancellation
          )

          if (response?.results?.length && response?.results[0].successful) {
            notification.success({
              message:
                "Document regenerated started. PLEASE NOTE: The document may take a few moments to regenerate. Please wait a few moments before refreshing the page to preview the newly generated document."
            })
          } else {
            notification.error({
              message:
                "An error occurred when attempting to regenerate the document. Please try again, or contact support."
            })
            setIsError(true)
          }
        }
      } catch (error) {
        notification.error({
          message:
            "An error occurred when attempting to regenerate the document. Please try again, or contact support."
        })
        setIsError(true)
      }

      setIsRegenerating(false)

      if (isMounted.current) setIsRegenerating(false)
    },
    [isRegenerating, commandClient]
  )

  return { regenerateDocument, isRegenerating, isError }
}

interface IUseRegenerateDocument {
  regenerateDocument: (
    quoteId: Nullable<string>,
    policyId: Nullable<string>,
    isCancellation: boolean
  ) => Promise<void>
  isRegenerating: boolean
  isError: boolean
}
