import { notification } from "antd"
import usePlatform from "contexts/platform/use-platform"
import { ICreateQuickQuoteRequest } from "platform-client/client/controllers/quick-quote"
import { ICompany, IPolicyholder } from "platform-client/types"
import { ICreateOpportunityRequest } from "platform-client/types/opportunities"
import { useMutation } from "react-query"
import { useHistory } from "react-router"
import { IActiveProduct } from "../ChooseProduct"
import ReviewCreateOpportunityView from "./view"

const ReviewCreateOpportunity: React.FC<IReviewCreateOpportunity> = ({
  broker,
  policyholder,
  product
}) => {
  const platform = usePlatform()
  const history = useHistory()

  const createOpportunity = useMutation(
    (createPolicyholderRequest: ICreateOpportunityRequest) =>
      platform.opportunity.createOpportunity(createPolicyholderRequest)
  )

  const createQuickQuote = useMutation((request: ICreateQuickQuoteRequest) =>
    platform.quickQuote.createQuickQuote(request)
  )

  const handleCreateQuickQuote = async (
    product: IActiveProduct,
    broker: ICompany
  ) => {
    createQuickQuote
      .mutateAsync({
        openSession: true,
        introducer: {
          companyID: broker.id,
          role: "Broker"
        },
        productReferenceID: product.productReferenceId,
        externalID: null,
        partnerID: null,
        partnerReference: null,
        policyholderID: null
      })
      .then((response) => {
        notification.success({
          message: "Quick quote created. Redirecting..."
        })
        setTimeout(() => {
          history.push(
            `/quick-quote/${product.id}/${response.questionSetReferenceID}/${response.quickQuote.id}/create/`,
            {
              sessionID: response.sessionID
            }
          )
        }, 2000)
      })
      .catch(() => {
        notification.error({
          message:
            "Sorry we were not able to create this quick quote, please try again."
        })
      })
  }

  const handleCreateOpportunity = async (
    product: IActiveProduct,
    broker: ICompany,
    policyholder?: IPolicyholder
  ) => {
    createOpportunity
      .mutateAsync({
        name: `Opportunity for ${policyholder?.referenceID}`,
        openSession: true,
        productReferenceID: product.productReferenceId,
        introducer: {
          companyID: broker.id,
          role: "Broker"
        },
        policyholderID: (policyholder && policyholder.id) || null
      })
      .then((response) => {
        notification.success({
          message: "Opportunity created. Redirecting..."
        })
        setTimeout(() => {
          history.push(
            `/opportunities/${response.opportunity.id}/variants/${response.variant.id}/edit?skipSummary=true`
          )
        }, 2000)
      })
      .catch((error) => {
        // Generic error for now.
        notification.error({
          message:
            "Sorry we were not able to create this opportunity, please try again."
        })
      })
  }

  return (
    <ReviewCreateOpportunityView
      product={product}
      policyholder={policyholder}
      broker={broker}
      onCreate={() => {
        if (product.hasQuickQuote === true) {
          handleCreateQuickQuote(product, broker)
        } else {
          handleCreateOpportunity(product, broker, policyholder)
        }
      }}
      isLoading={createOpportunity.isLoading || createQuickQuote.isLoading}
      isQuickQuote={Boolean(product.hasQuickQuote)}
    />
  )
}

interface IReviewCreateOpportunity {
  product: IActiveProduct
  policyholder?: IPolicyholder
  broker: ICompany
}

export default ReviewCreateOpportunity
