import { DiffViewer } from "components/diffviewer"
import { useQuery } from "react-query"
import usePlatform from "contexts/platform/use-platform"
import { IQuestionSetsDifference } from "platform-client/types/difference"
import { Alert, Breadcrumb, Layout, PageHeader } from "antd"
import { RouteComponentProps } from "react-router"
import { Link } from "react-router-dom"
import { LayoutLoading, LayoutPageContent } from "components"
import styled from "styled-components"
import { ShoppingOutlined } from "@ant-design/icons"

export const RiskChanges: React.FunctionComponent<IRiskChanges> = (props) => {
  const { difference } = usePlatform()

  const { isLoading, data, isError } = useQuery<
    IQuestionSetsDifference | undefined
  >(["riskDiffOpportunity", props.match.params.variantId], async () =>
    difference.mtaVariant(props.match.params.variantId)
  )

  const StyledOpportunityHeader = styled.div`
    background-color: #ffffff;
  `

  if (isLoading) return <LayoutLoading />

  if (isError)
    return (
      <Alert type="error" message="Something went wrong. Please try again" />
    )

  if (!data) return <Alert message="There is no data available" />

  return (
    <Layout>
      <StyledOpportunityHeader>
        <PageHeader
          title={"Risk changes"}
          breadcrumb={
            <Breadcrumb>
              <Breadcrumb.Item key="/opportunities">
                <Link to="/opportunities">
                  <ShoppingOutlined />
                  &nbsp; Opportunities
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/opportunity/${props.match.params.opportunityId}`}>
                  {props.match.params.opportunityReference}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{"Risk Changes"}</Breadcrumb.Item>
            </Breadcrumb>
          }
          ghost={false}
        />
      </StyledOpportunityHeader>
      <LayoutPageContent transparent>
        <DiffViewer questionSetDifference={data} />
      </LayoutPageContent>
    </Layout>
  )
}

export type IRiskChanges = RouteComponentProps<{
  variantId: string
  opportunityReference: string
  opportunityId: string
}>
