import React from "react"
import styled from "styled-components"
import { Layout, Spin } from "antd"

const StyledLayout = styled(Layout)`
  min-width: 100%;
  min-height: 100%;
  display: flex;
  flex: auto;
  justify-content: center;
  align-items: center;
`

const FullHeightWrapper = styled.div`
  height: 100vh;
  display: flex;
`

const Wrapper: React.FC<{ withWrapper?: boolean }> = (props) =>
  props.withWrapper ? (
    <FullHeightWrapper>{props.children}</FullHeightWrapper>
  ) : (
    <>{props.children}</>
  )

export const LayoutLoading: React.FunctionComponent<{ withWrapper?: boolean }> =
  ({ withWrapper }) => (
    <Wrapper withWrapper={withWrapper}>
      <StyledLayout>
        <Spin size="large" />
      </StyledLayout>
    </Wrapper>
  )
