import React from "react"
import { PoliciesBoundary } from "./boundary"
import AllPolicies from "./views/AllPolicies"
import {
  HandleMTAPolicyRouting,
  IHandleMTAPolicyRoutingProps
} from "./views/MTAPolicy/HandleMTAPolicyRouting"
import PolicyDetails from "./views/PolicyDetails"
import { ISymphonyMTARoutingProps, SymphonyMTA } from "./views/SymphonyMTA"

export const VAllPolicies: React.FC = (props) => (
  <PoliciesBoundary>
    <AllPolicies {...props} />
  </PoliciesBoundary>
)

export const VSinglePolicy: React.FC = (props) => (
  <PoliciesBoundary>
    <PolicyDetails {...props} />
  </PoliciesBoundary>
)

export const VMTAPolicy: React.FC<IHandleMTAPolicyRoutingProps> = (props) => (
  <PoliciesBoundary>
    <HandleMTAPolicyRouting {...props} />
  </PoliciesBoundary>
)

export const VSymphonyMTA: React.FC<ISymphonyMTARoutingProps> = (props) => (
  <PoliciesBoundary>
    <SymphonyMTA {...props} />
  </PoliciesBoundary>
)
