import React from "react"
import {
  LayoutHeader,
  LayoutContent,
  LayoutContainer,
  LayoutPageHeader
} from "components"
import { Layout } from "antd"

const Settings: React.FunctionComponent<ISettings> = (props) => {
  return (
    <Layout>
      <Layout>
        <LayoutHeader />
        <LayoutContainer>
          <LayoutPageHeader
            ghost={false}
            onBack={() => window.history.back()}
            title="Settings"
          />

          <LayoutContent>Here go settings</LayoutContent>
        </LayoutContainer>
      </Layout>
    </Layout>
  )
}

export default Settings

export interface ISettings {
  children?: React.ReactNode
}
