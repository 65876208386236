import { Form, Input, Modal } from "antd"
import React, { useEffect, useState } from "react"

export const ConfirmEmailModal: React.FunctionComponent<IConfirmEmailModal> = ({
  title,
  description,
  emailAddress: defaultEmailAddress,
  isVisible,
  onCancel,
  onSend,
  isLoading
}) => {
  const [emailAddress, setEmailAddress] = useState<string>("")

  useEffect(() => {
    setEmailAddress(defaultEmailAddress)
  }, [defaultEmailAddress])

  const handleCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setEmailAddress(defaultEmailAddress)
    onCancel(e)
  }

  return (
    <Modal
      title={title}
      centered
      destroyOnClose
      visible={isVisible}
      onCancel={handleCancel}
      okText="Send"
      okButtonProps={{
        disabled: !emailAddress
      }}
      onOk={() => onSend(emailAddress)}
      confirmLoading={isLoading}
    >
      <p>{description}</p>
      <Form
        layout="vertical"
        id="emailAddressForm"
        style={{ margin: "20px 0 0" }}
      >
        <Form.Item
          name="Email Address"
          label="Email Address:"
          rules={[{ required: true }]}
        >
          <Input
            name="emailAddress"
            type="string"
            value={emailAddress}
            defaultValue={emailAddress}
            onChange={(event) => {
              setEmailAddress(event.target.value)
            }}
            disabled={isLoading}
          />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export interface IConfirmEmailModal {
  emailAddress: string
  title: string
  description: string
  isVisible: boolean
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  onSend: (emailAddress: string) => void
  isLoading: boolean
}
