import { Select } from "antd"
import styled from "styled-components"
import moment from "moment"
import { CoverPeriodType } from "platform-client/types"

const StyledBanner = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding: 10px 24px;
  margin-top: -3px;
  align-items: center;
  background: #e6f7ff;
  border-top: 2px solid #258df0;
  border-bottom: 2px solid #258df0;
`

const CoverPeriodSelect: React.FC<ICoverPeriodSelectProps> = (props) => {
  const mapCoverPeriods = props.coverPeriods.map((item) => {
    const startDate = moment(item.coverStartDate).format("Do MMM YYYY")
    const endDate = moment(item.coverEndDate).format("Do MMM YYYY")

    return {
      label: `${startDate} - ${endDate}`,
      value: item.policyDetailIndex
    }
  })

  if (!mapCoverPeriods || mapCoverPeriods.length === 0) {
    return null
  }

  return (
    <StyledBanner>
      <p style={{ margin: "0 15px 0 0" }}>Viewing coverage period:</p>
      <Select
        aria-label="cover-period-select"
        style={{ width: "250px" }}
        defaultValue={-1}
        onChange={props.onChange}
      >
        <Select.Option value={-1}>Latest</Select.Option>
        {mapCoverPeriods.map((item) => (
          <Select.Option value={item.value}>{item.label}</Select.Option>
        ))}
      </Select>
    </StyledBanner>
  )
}

export default CoverPeriodSelect

interface ICoverPeriodSelectProps {
  onChange?: (selected: number) => void
  coverPeriods: CoverPeriodType[]
}
