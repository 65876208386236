import { InvoiceTable } from "components/InvoiceTable"
import React from "react"

export const Invoice: React.FC<IInvoice> = (props) => {
  return (
    <>
      <InvoiceTable
        search={`?companyId=${props.companyId}`}
        showFilter={false}
      ></InvoiceTable>
    </>
  )
}

export interface IInvoice {
  companyId: string
}
