import { notification } from "antd"
import { Collapse } from "components/Collapse"
import {
  SaveButton,
  DeleteButton,
  AddButton,
  RemoveButton,
  ExpandButton
} from "components/Console/CellContent/Button"
import CellContentLinkButton from "components/Console/CellContent/Link"
import CellContentText from "components/Console/CellContent/Text"
import CellContentTitle from "components/Console/CellContent/Title"
import Row from "components/Console/Row"
import { TPlatformResponse } from "contexts/platform/client"
import usePlatform from "contexts/platform/use-platform"
import { INewEndorsement } from "models/underwriting"
import {
  EModiferOwnerComponentType,
  ICoverage,
  IModifierOwner,
  SectionTypes
} from "platform-client/types"
import { IEndorsement } from "platform-client/types/endorsement"
import { IEndorsementParams } from "platform-client/types/quote-builder"
import { ISubsection } from "platform-client/types"
import React from "react"
import { useMutation, useQuery } from "react-query"
import styled from "styled-components"
import CellContentSelect from "../CellContent/Select"

const StyledEndorsements = styled.div<{ $isMainSection: boolean }>`
  background: ${(props) => (!props.$isMainSection ? "none" : "transparent")};
  border: ${(props) => (!props.$isMainSection ? "none" : "1px solid #ccc")};
  margin-bottom: ${(props) => (!props.$isMainSection ? "none" : "10px")};
`
const StyledEndorsementsHeader = styled.div<{
  $isOpen: boolean
  $isMainSection: boolean
}>`
  background: none;
  border-bottom: ${(props) =>
    props.$isOpen && props.$isMainSection ? "1px solid #ccc" : "none"};
`

const StyledEndorsementsSummary = styled.span`
  min-width: 155px;
`

const EndorsementsHeader: React.FC<IEndorsementsHeader> = ({
  title,
  aside,
  isOpen = false,
  toggle,
  isMainSection,
  endorsmentsStatus
}) => {
  return (
    <StyledEndorsementsHeader $isMainSection={isMainSection} $isOpen={isOpen}>
      <Row
        type={isMainSection ? "header" : "subHeader"}
        toggle={<ExpandButton expanded={isOpen} onClick={toggle} />}
        isFirstRow={true}
        reference={
          <>
            <CellContentTitle
              aside={aside}
              {...(isMainSection && {
                header: true
              })}
            >
              {title}
            </CellContentTitle>
            {endorsmentsStatus && (
              <StyledEndorsementsSummary>
                (
                {`${endorsmentsStatus?.applied} Applied / ${endorsmentsStatus?.revoked} Revoked`}
                )
              </StyledEndorsementsSummary>
            )}
          </>
        }
      />
    </StyledEndorsementsHeader>
  )
}

const Endorsements: React.FC<IEndorsements> = ({
  readOnly = false,
  isMainSection = false,
  ...props
}): JSX.Element => {
  const [newEndorsement, setNewEndorsement] = React.useState<boolean>(false)
  const [selectedEndorsement, setSelectedEndorsement] = React.useState<
    string | undefined
  >()
  const { endorsement: endorsementController, quoteBuilder } = usePlatform()

  const endorsementsList = useQuery(
    ["endorsements", props.section && props.section.id],
    async () => {
      if (props.section) {
        const data = await endorsementController.listEndorsements(
          props.section.schemeReferenceID,
          props.section.referenceID
        )

        return data.map((endorsement) => {
          return {
            label:
              endorsement.title ||
              endorsement.providerReference ||
              endorsement.id,
            value: endorsement.id
          }
        })
      }
    },
    { enabled: !isMainSection && props.section && !!newEndorsement }
  )

  const addEndorsement = useMutation((addEndorsement: IEndorsementParams) =>
    quoteBuilder.addEndorsement(
      addEndorsement.editSessionID,
      addEndorsement.componentID,
      addEndorsement.componentType,
      addEndorsement.endorsementID
    )
  )

  const enableEndorsement = useMutation((params: IEndorsementParams) =>
    quoteBuilder.enableEndorsement(
      params.editSessionID,
      params.componentID,
      params.componentType,
      params.endorsementID
    )
  )

  const disableEndorsement = useMutation((params: IEndorsementParams) =>
    quoteBuilder.disableEndorsement(
      params.editSessionID,
      params.componentID,
      params.componentType,
      params.endorsementID
    )
  )

  const handleAddEndorsement = () => {
    // Currently this only handles adding section endorsements
    // It is unlikely that we will need to add
    // subsection and coverage but we will need
    // to allow for global endorsements at some stage.
    if (props.editSessionID && props.section && selectedEndorsement) {
      addEndorsement
        .mutateAsync({
          editSessionID: props.editSessionID,
          componentID: props.section.id,
          componentType: "Section",
          endorsementID: selectedEndorsement
        })
        .then((response) => {
          if (typeof props.onChange === "function") {
            props.onChange(response)
            setSelectedEndorsement(undefined)
            setNewEndorsement(false)
          } else {
            throw new Error("Unable to update console state")
          }
        })
        .catch((error) => {
          notification.error({
            message: "Unable to add endorsement, please try again"
          })
        })
    }
  }

  const handleEnableEndorsement = (
    endorsementID: string,
    owner: IModifierOwner
  ) => {
    if (props.editSessionID) {
      enableEndorsement
        .mutateAsync({
          editSessionID: props.editSessionID,
          componentID: owner.componentID,
          componentType: owner.componentType,
          endorsementID: endorsementID
        })
        .then((response) => {
          if (typeof props.onChange === "function") {
            props.onChange(response)
          } else {
            throw new Error("Unable to update console state")
          }
        })
        .catch((error) => {
          notification.error({
            message: "Unable to enable endorsement, please try again"
          })
        })
    }
  }

  const handleDisableEndorsement = (
    endorsementID: string,
    owner: IModifierOwner
  ) => {
    if (props.editSessionID && props.section) {
      disableEndorsement
        .mutateAsync({
          editSessionID: props.editSessionID,
          componentID: owner.componentID,
          componentType: owner.componentType,
          endorsementID: endorsementID
        })
        .then((response) => {
          if (typeof props.onChange === "function") {
            props.onChange(response)
          } else {
            throw new Error("Unable to update console state")
          }
        })
        .catch((error) => {
          notification.error({
            message: "Unable to disable endorsement, please try again"
          })
        })
    }
  }

  const componentIDCheck = (
    id: string | undefined,
    type: EModiferOwnerComponentType
  ) => {
    switch (type) {
      case "Section":
        return props.section && id === props.section.id
      case "Subsection":
        return (
          props.section &&
          props.section.subsections.some(
            (subsection: ISubsection) => subsection.id === id
          )
        )
      case "Coverage":
        return (
          props.section &&
          props.section.subsections.some((subsection: ISubsection) =>
            subsection.coverages.some(
              (coverage: ICoverage) => coverage.id === id
            )
          )
        )
      default:
        return false
    }
  }

  const filteredEndorsements = isMainSection
    ? props.endorsements
    : props.endorsements.filter((endorsement) =>
        endorsement.modifierOwners.some(
          (owner) =>
            owner.componentID &&
            componentIDCheck(owner.componentID, owner.componentType)
        )
      )

  const isEndorsementRevoked = (endorsement: IEndorsement) =>
    endorsement.modifierOwners.every((owner) => !owner.enabled)

  const isEndorsementApplied = (endorsement: IEndorsement) =>
    endorsement.modifierOwners.some((owner) => owner.enabled)

  const endorsmentsStatus = {
    revoked: filteredEndorsements.reduce(
      (acc, endorsement) => acc + (isEndorsementRevoked(endorsement) ? 1 : 0),
      0
    ),
    applied: filteredEndorsements.reduce(
      (acc, endorsement) => acc + (isEndorsementApplied(endorsement) ? 1 : 0),
      0
    )
  }

  return (
    <StyledEndorsements $isMainSection={isMainSection}>
      <Collapse
        startExpanded={props.startExpanded}
        collapsedPanel={
          <EndorsementsHeader
            title="Endorsements"
            isMainSection={isMainSection}
            endorsmentsStatus={endorsmentsStatus}
          />
        }
        expandedPanel={
          <EndorsementsHeader
            title="Endorsements"
            isOpen
            isMainSection={isMainSection}
            endorsmentsStatus={endorsmentsStatus}
          />
        }
      >
        <Row
          reference={
            <CellContentTitle>Endorsement Reference / Name</CellContentTitle>
          }
          values={[
            null,
            <CellContentTitle>Status</CellContentTitle>,
            <CellContentTitle>Type</CellContentTitle>,
            null,
            null
          ]}
        />
        {filteredEndorsements &&
          filteredEndorsements.map((endorsement) => {
            const [modifierOwner] = endorsement.modifierOwners
            let endorsementStatus

            if (
              endorsement.modifierOwners.length === 1 &&
              modifierOwner.source === "Manual" &&
              modifierOwner?.enabled
            ) {
              endorsementStatus = {
                status: "Manually applied",
                controls: (
                  <>
                    <DeleteButton
                      onClick={() =>
                        handleDisableEndorsement(endorsement.id, modifierOwner)
                      }
                    />
                  </>
                )
              }
            } else if (
              endorsement.modifierOwners.length >= 1 &&
              modifierOwner?.enabled
            ) {
              endorsementStatus = {
                status: "Applied",
                controls: (
                  <>
                    <RemoveButton
                      onClick={() =>
                        handleDisableEndorsement(endorsement.id, modifierOwner)
                      }
                    />
                  </>
                )
              }
            } else {
              endorsementStatus = {
                status: "Manually revoked",
                controls: (
                  <>
                    <AddButton
                      onClick={() =>
                        handleEnableEndorsement(endorsement.id, modifierOwner)
                      }
                    />
                  </>
                )
              }
            }

            return (
              <Row
                key={endorsement.referenceID}
                reference={
                  <CellContentText strikethrough={!modifierOwner?.enabled}>
                    {endorsement.providerReferenceID
                      ? `${endorsement.providerReferenceID} - ${endorsement.title}`
                      : endorsement.title}
                  </CellContentText>
                }
                values={[
                  null,
                  <CellContentText>{endorsementStatus.status}</CellContentText>,
                  <CellContentText strikethrough={!modifierOwner?.enabled}>
                    {endorsement.modifierType}
                  </CellContentText>,
                  null,
                  null
                ]}
                control={
                  !readOnly && !isMainSection && endorsementStatus.controls
                }
              />
            )
          })}

        {!newEndorsement && !isMainSection && !readOnly && (
          <Row
            reference={
              <CellContentLinkButton onClick={() => setNewEndorsement(true)}>
                Add Endorsement
              </CellContentLinkButton>
            }
          />
        )}

        {newEndorsement && !readOnly && (
          <Row
            updating={addEndorsement.isLoading}
            reference={
              <CellContentSelect
                loading={endorsementsList.isLoading}
                name="endorsement"
                defaultValue={selectedEndorsement}
                options={endorsementsList.data || []}
                onChange={(e) => {
                  setSelectedEndorsement(e.target.value?.toString())
                }}
              />
            }
            values={[
              null,
              null,
              <CellContentText>Library</CellContentText>,
              null,
              null
            ]}
            control={
              <>
                <SaveButton onClick={handleAddEndorsement} />
                <DeleteButton onClick={() => setNewEndorsement(false)} />
              </>
            }
          />
        )}
      </Collapse>
    </StyledEndorsements>
  )
}

interface IEndorsementsHeader {
  title: string
  aside?: string
  isOpen?: boolean
  toggle?: () => boolean
  isMainSection: boolean
  endorsmentsStatus?: { revoked: number; applied: number }
}

export interface IEndorsements {
  editSessionID?: string
  isMainSection?: boolean
  endorsements: IEndorsement[]
  addEndorsement?: (endorsementToAdd: INewEndorsement) => void
  section?: SectionTypes
  readOnly?: boolean
  onChange?: (response: TPlatformResponse) => void
  startExpanded?: boolean
}

export default Endorsements
