import { Note, NoteList } from "components/Notes"
import { INote } from "platform-client/types/notes"
import { Typography } from "antd"

interface INotesProps {
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
  data: INote[] | undefined
}

const { Title } = Typography

export const Notes: React.FC<INotesProps> = (props) => {
  return (
    <>
      <Title level={3}>Notes</Title>
      <NoteList
        isLoading={props.isLoading}
        isEmpty={props.isEmpty}
        isError={props.isError}
      >
        {(props.data || []).map((noteData) => (
          <Note
            internal={noteData.isInternalOnly}
            content={noteData.content}
            date={noteData.dateCreated}
            author={noteData.createdByName}
          />
        ))}
      </NoteList>
    </>
  )
}
