import React from "react"
import { StopOutlined } from "@ant-design/icons"
import { VAllEmbargoes, VNewEmbargo, VEmbargoDetails } from "./"

export const EMBARGOES_ROUTES = [
  {
    path: "/embargoes",
    component: VAllEmbargoes
  },
  {
    path: "/embargoes/new",
    component: VNewEmbargo
  },
  {
    path: "/embargo/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    component: VEmbargoDetails
  }
]

export const EMBARGOES_NAVIGATION = [
  {
    title: "Embargoes",
    path: "/embargoes",
    childPaths: ["/embargo"],
    submenu: false,
    permission: "embargoesmanagement:maintain",
    icon: <StopOutlined />,
    iconActive: <StopOutlined />
  }
]
