import React from "react"
import MTAPolicy from ".."
import { RouteComponentProps, withRouter } from "react-router"
import useGetMTAPolicyUrl from "../hooks/use-get-mta-policy-url"

export const HandleMTAPolicyRouting: React.FunctionComponent<IHandleMTAPolicyRoutingProps> =
  (props) => {
    const { opusFinalUrl } = useGetMTAPolicyUrl(
      props.match.params.productRef,
      props.match.params.productCover,
      props.match.params.id,
      props.match.params.userId
    )

    return <MTAPolicy url={opusFinalUrl} history={props.history} />
  }

export type IHandleMTAPolicyRoutingProps = RouteComponentProps<{
  id: string
  productRef: string
  productCover: string
  userId: string
}>
export default withRouter(HandleMTAPolicyRouting)
