import { useHistory } from "react-router"
import {
  Breadcrumb,
  Button,
  Dropdown,
  Layout,
  Menu,
  notification,
  Table
} from "antd"
import { useState } from "react"
import { LayoutPageContent, LayoutPageHeader } from "components"
import {
  CalendarOutlined,
  DatabaseOutlined,
  DownloadOutlined,
  MoreOutlined,
  PlusOutlined
} from "@ant-design/icons"
import useOpus from "contexts/opus/hooks/use-opus"
import { IRateFile } from "models/ratings/IRateFile"
import { RatingsBoundary } from "containers/ratings/boundary"
import Column from "antd/lib/table/Column"
import formatDate from "dateformat"
import { Nullable } from "platform-client/types"
import { UpdateGoLiveDateModal } from "./components/update-go-live-date"
import useAsyncEffect from "use-async-effect"
import axios from "axios"
import { IValidationError } from "models/errors"
import { useOrgSettings } from "contexts/organisation/hooks/use-org-settings"
import moment from "moment-timezone"

export const RatingsList: React.FC = () => {
  const { ratingsClient } = useOpus()
  const history = useHistory()
  const { orgSettings } = useOrgSettings()

  const [rateFileData, setRateFileData] = useState<IRateFile[] | undefined>(
    undefined
  )
  const [rateFile, setRateFile] = useState<Nullable<IRateFile>>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const [validationErrors, setValidationErrors] = useState<IValidationError[]>()

  const getRateFiles = async () => {
    setIsLoading(true)
    const ratingFiles = await ratingsClient.list()
    if (ratingFiles) {
      setRateFileData(ratingFiles)
    }
    setIsLoading(false)
  }

  useAsyncEffect(() => {
    if (rateFileData === undefined) {
      getRateFiles()
    }
  }, [getRateFiles, rateFileData])

  const updateGoLiveDate = async (goLiveDate: string) => {
    if (rateFile?.id) {
      setIsUpdating(true)
      await ratingsClient
        .updateGoLiveDate(rateFile.id, goLiveDate)
        .then((result) => {
          setValidationErrors(undefined)
          setIsUpdating(false)
          if (result === true) {
            setRateFile(null)

            notification.success({
              message: "Go Live Date updated successfully."
            })
            getRateFiles()
          } else {
            notification.error({
              message:
                "An error occurred when attempting to update the go live date. Please try again."
            })
          }
        })
        .catch((error) => {
          setIsUpdating(false)

          if (axios.isAxiosError(error)) {
            if (
              error.response &&
              error.response.data &&
              error.response.data.validationErrors &&
              error.response.data.validationErrors.length > 0
            ) {
              setValidationErrors(error.response.data.validationErrors)
            }
          } else {
            notification.error({
              message:
                "An error occurred when attempting to update the go live date. Please try again."
            })
          }
        })
    }
  }

  return (
    <RatingsBoundary>
      <Layout>
        <LayoutPageHeader
          ghost={false}
          breadcrumb={
            <Breadcrumb>
              <Breadcrumb.Item key="/ratings">
                <DatabaseOutlined />
                &nbsp;Ratings
              </Breadcrumb.Item>
            </Breadcrumb>
          }
          title="Ratings"
          extra={
            <Button
              type="primary"
              onClick={() => history.replace("/ratings/upload")}
              icon={<PlusOutlined />}
            >
              Upload Rate File
            </Button>
          }
        />

        <LayoutPageContent>
          {orgSettings && (
            <>
              <Table
                loading={isLoading}
                dataSource={rateFileData}
                rowKey="id"
                pagination={false}
                style={{ marginTop: "10px" }}
              >
                <Column
                  title="Created"
                  dataIndex="dateCreated"
                  render={(value: string) =>
                    formatDate(new Date(value), "dd/mm/yyyy hh:MM:ss TT")
                  }
                />
                <Column
                  title="Uploaded By"
                  render={(item: IRateFile) => item.createdByName}
                />
                <Column
                  title="Product"
                  render={(item: IRateFile) => item.productName}
                />
                <Column
                  title="Filename"
                  render={(item: IRateFile) => item.filename}
                />
                <Column
                  title="Rates Version"
                  render={(item: IRateFile) => item.version}
                />
                <Column
                  title="Changelog"
                  render={(item: IRateFile) => item.changelog}
                />
                <Column
                  title="Last Updated"
                  dataIndex="lastUpdatedDate"
                  render={(value: string) =>
                    formatDate(new Date(value), "dd/mm/yyyy hh:MM:ss TT")
                  }
                />
                <Column
                  title="Status"
                  render={(item: IRateFile) => item.status}
                />
                <Column
                  title="Go Live Date"
                  render={(item: IRateFile) =>
                    item.status === "TestPassed" && (
                      <>
                        {formatDate(
                          new Date(item.goLiveDateTime),
                          "dd/mm/yyyy hh:MM:ss TT"
                        )}
                        <br />(
                        {moment
                          .tz(item.goLiveDateTime, orgSettings.timezone)
                          .utc()
                          .format("DD/MM/YYYY hh:mm:ss A")}{" "}
                        UTC)
                      </>
                    )
                  }
                />
                <Column
                  title="Actions"
                  render={(item: IRateFile) => (
                    <>
                      {(item.status === "TestPassed" ||
                        item.testResultsDownloadUrl) && (
                        <Dropdown
                          overlay={
                            <Menu>
                              <Menu.Item
                                key="download"
                                onClick={() =>
                                  window.open(
                                    item.testResultsDownloadUrl,
                                    "_blank"
                                  )
                                }
                                disabled={!item.testResultsDownloadUrl}
                              >
                                <DownloadOutlined /> Download Test Results
                              </Menu.Item>
                              <Menu.Item
                                key="updateGoLive"
                                onClick={() => setRateFile(item)}
                                disabled={
                                  !(
                                    item.goLiveDateTime &&
                                    moment(item.goLiveDateTime).isAfter(
                                      moment()
                                    )
                                  )
                                }
                              >
                                <CalendarOutlined /> Update Go Live Date
                              </Menu.Item>
                            </Menu>
                          }
                        >
                          <MoreOutlined
                            style={{
                              cursor: "pointer",
                              padding: "0 5px",
                              fontSize: 19
                            }}
                          />
                        </Dropdown>
                      )}
                    </>
                  )}
                />
              </Table>
              <UpdateGoLiveDateModal
                rateFile={rateFile}
                isVisible={!!rateFile?.id}
                onCancel={(e) => {
                  setRateFile(null)
                  setValidationErrors(undefined)
                }}
                onUpdate={updateGoLiveDate}
                isLoading={isUpdating}
                validationErrors={validationErrors}
                timezone={orgSettings.timezone}
              />
            </>
          )}
        </LayoutPageContent>
      </Layout>
    </RatingsBoundary>
  )
}
