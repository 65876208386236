// .avtportal.dev is our development domains, this regex will breakdown
// the definitions of these such that:
// 1: `subdomain` usually the org and sometimes with api env override
// 3: `apiEnv` optional api environment overide
// 4: `env` is the portal environment
const DEV_URL_RE =
  /^([\w][\w-_]+?[\w])(-(local|dev|uat))?\.(local|dev|uat)\.avtportal\.dev(:\d+)?$/
//export const LOCALHOST_API_URL = "https://stelladev.dev.avtportal.dev/api"
export const LOCALHOST_API_URL = "https://localhost:44361"
export const LOCALDEV_API_URL = "https://localhost:44361"

type IGetActiveHostname = {
  subdomain?: string
  apiEnv?: string
  env?: string
  apidomain?: string
  activeHostname: string
}

export const getActiveHostname = (hostname: string): IGetActiveHostname => {
  if (hostname === undefined || hostname === null)
    throw new Error("hostname is required")

  if (DEV_URL_RE.test(hostname) !== true) {
    if (hostname === "local.avtportal.dev:3000") {
      return { activeHostname: hostname }
    }
    return { activeHostname: `${hostname}` }
  } else {
    const regexResult = DEV_URL_RE.exec(hostname)

    const subdomain = regexResult?.[1]
    const apiEnv = regexResult?.[3]
    const env = regexResult?.[4]

    // If the api environment is not provided as override, default it to the
    // portal environment.
    const apidomain = apiEnv ?? env

    return {
      subdomain,
      apiEnv,
      env,
      apidomain,
      activeHostname: `${subdomain}.${apidomain}.avtportal.dev`
    }
  }
}

/**
 * getApiUrl returns the applicable API url for the given hostname
 * @param hostname Current active hostname of the application (usually
 *                 window.location.hostname)
 */
export const getApiUrl = (hostname: string): string => {
  if (hostname === undefined || hostname === null)
    throw new Error("hostname is required")

  if (DEV_URL_RE.test(hostname) !== true) {
    if (hostname === "local.avtportal.dev:3000") {
      return LOCALHOST_API_URL
    }
    return `https://${hostname}/api`
  } else {
    const { apidomain, activeHostname } = getActiveHostname(hostname)

    // Connect to a locally-running api instance
    if (apidomain === "local") return LOCALDEV_API_URL

    return `https://${activeHostname}/api`
  }
}
