import moment, { Moment } from "moment"

export function validateMinAge(
  date: string,
  message = "Must be at least 18 years old",
  minAge = 18
): boolean | string {
  const formats = ["YYYY-MM-DDTHH:mm:ss", "YYYY-MM-DDT00:00:00", "DD/MM/YYYY"]
  const today = moment().endOf("d")
  const toValidate = moment(date, formats, true).startOf("d")

  const validDate = (date: string | Moment): boolean => {
    return moment(date).isValid()
  }

  if (validDate(toValidate) !== true) {
    return "Invalid date"
  }

  return toValidate.isBefore(today.subtract(minAge, "y")) ? true : message
}
