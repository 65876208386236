import React from "react"
import { Row, Col, Space } from "antd"
import { IConfigurationContext } from "contexts/configuration/context"
import { IQuote, IRisk } from "platform-client/types/quote"
import {
  ICompanyInRole,
  IPolicyDetailSet,
  IPolicyholder
} from "platform-client/types"
import {
  IQuoteProduct,
  IQuotePaymentPlans,
  IQuotePaymentLink,
  IQuoteLifecycles
} from "../../view"
import { QuoteCover } from "../../components/quote-cover"
import { QuotePremium } from "../../components/quote-premium"
import { QuoteDeclinedAlert } from "../../components/quote-declined-alert"
import SummaryOfCover from "components/SummaryOfCover"
import { QuoteReferredAlert } from "../../components/quote-referred-alert"
import ListCard from "components/ListCard"
import { DateDisplay } from "components/display/DateDisplay"
import styled from "styled-components"

const FullHeightListCard = styled(ListCard)`
  height: 100%;
`

export const Summary: React.FC<ISummary> = (props) => {
  // Since this portal was initially developed with Westcor in mind,
  // we only show the annual payment plan. This will change in the future.
  const [annual] = props.quotePaymentPlans?.paymentPlans || []

  const insuranceProduct = annual?.price?.total?.rows?.find(
    (item) => item.type === "InsuranceProduct"
  )
  const fees = annual?.price?.total?.rows?.find((item) => item.type === "Fee")

  const basePremium = insuranceProduct
    ? {
        value: insuranceProduct.net.value,
        currencyCode: insuranceProduct.net.currencyCode
      }
    : annual?.price.total.net

  const taxTotal = insuranceProduct
    ? insuranceProduct.tax.total
    : annual?.price.total.tax?.total

  const totalPremium =
    insuranceProduct && fees
      ? {
          value:
            insuranceProduct.net.value +
            insuranceProduct.tax.total.value +
            fees.gross.value,
          currencyCode: insuranceProduct.net.currencyCode
        }
      : annual?.price.total.gross

  return (
    <Space
      direction="vertical"
      size={24}
      style={{ width: "100%", maxWidth: "1200px", display: "flex" }}
    >
      {props.configuration.flags.platformVersion === "v2" && (
        <QuoteReferredAlert
          isReferred={props.quote.referred}
          referralReasons={props.quote.referralReasons}
        />
      )}
      <QuoteDeclinedAlert
        isDeclined={props.quote.declined}
        declineReasons={props.quote.declineReasons}
      />

      <Row gutter={[24, 24]} style={{ gridAutoRows: "1fr" }}>
        <Col xs={24} lg={12}>
          <FullHeightListCard title="Quote details">
            <ListCard.Item label="Created">
              {props.quote.dateCreated && (
                <DateDisplay date={props.quote.dateCreated} showTime />
              )}
            </ListCard.Item>
            <ListCard.Item label="Expiry">
              {props.quote.expiryDate && (
                <DateDisplay date={props.quote.expiryDate} showTime />
              )}
            </ListCard.Item>
            {props.companies && props.companies.length > 0 && (
              <>
                {props.companies.map((company) => (
                  <ListCard.Item
                    key={company.companyRoleID}
                    label={company.role}
                  >
                    {company.company.name}
                  </ListCard.Item>
                ))}
              </>
            )}
          </FullHeightListCard>
        </Col>
        <Col xs={24} lg={12}>
          <QuotePremium
            configuration={props.configuration}
            isWorking={props.quotePaymentPlans?.isGettingPaymentPlans || false}
            grossPremium={
              props.quote.quoteType === "MTA"
                ? props.quote.estimateGross
                : totalPremium
            }
            netPremium={
              props.quote.quoteType === "MTA"
                ? props.quote.estimateNet
                : basePremium
            }
            taxTotal={
              props.quote.quoteType === "MTA"
                ? props.quote.tax?.total
                : taxTotal
            }
            fees={fees?.gross}
            iptPercentage={undefined}
          />
        </Col>
      </Row>

      <Row gutter={24}>
        {props.configuration.flags.platformVersion === "v3" &&
        props.policyDetailSet ? (
          <Col span={14}>
            <SummaryOfCover policyDetailSet={props.policyDetailSet} />
          </Col>
        ) : (
          <Col span={14}>
            <QuoteCover
              isWorking={props.quoteProduct?.isGettingQuoteProduct || false}
              quoteState={props.quote.quoteState}
              quoteCover={props.quoteProduct?.productItems_v2}
            />
          </Col>
        )}
      </Row>
    </Space>
  )
}

export interface ISummary {
  configuration: IConfigurationContext
  quote: IQuote
  risk?: IRisk
  quoteProduct?: IQuoteProduct
  quotePaymentPlans?: IQuotePaymentPlans
  quotePaymentLink?: IQuotePaymentLink
  quoteLifecycles?: IQuoteLifecycles
  quoteProductReferenceId?: string
  quoteProductCoverReference?: string
  quotePolicyHolder?: IPolicyholder
  policyDetailSet?: IPolicyDetailSet
  companies?: ICompanyInRole[]
  handleCreatePaymentLink?: () => void
}
