import React, { useState } from "react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { Table, Button, Space } from "antd"
import { ITransactionalMessage } from "models/documents/message"
import formatDate from "dateformat"
import { FileTextOutlined, SendOutlined } from "@ant-design/icons"
import useGetTransactionalMessages from "contexts/opus/hooks/use-get-transactional-messages"
import { convertCamelCaseToSpaces } from "utils/pocketknife/camel-case-to-spaces"
import Column from "antd/lib/table/Column"
import { useResendTransactionalEmail } from "contexts/opus/hooks/use-resend-transactional-email"
import { ConfirmEmailModal } from "components/ConfirmEmailModal"
import { Nullable } from "platform-client/types"

export const TransactionalMessagesComponent: React.FunctionComponent<ITransactionalMessagesSubView> =
  (props) => {
    const viewEmail = (email: ITransactionalMessage) => {
      return () => {
        if (typeof email.emailUrl === "string") {
          window.open(email.emailUrl, "_blank")
        }
      }
    }

    const [selectedEmail, setSelectedEmail] =
      useState<Nullable<ITransactionalMessage>>(null)

    const [refetchMessages, setRefetchMessages] = useState(false)

    const { data: messages, isLoading } = useGetTransactionalMessages(
      props?.entityID,
      refetchMessages,
      setRefetchMessages
    )

    const { isResending, resendTransactionalEmail } =
      useResendTransactionalEmail()

    const renderStatus = (message: ITransactionalMessage) => {
      let status = ""
      if (message.type === "Email" && message.emailStatus) {
        status = message.emailStatus
      } else if (message.type === "SMS" && message.smsStatus) {
        status = message.smsStatus
      }

      return convertCamelCaseToSpaces(status)
    }

    const renderMessage = (message: ITransactionalMessage) => {
      let msg = ""
      if (message.type === "Email" && message.subject) {
        msg = message.subject
      } else if (message.type === "SMS" && message.message) {
        msg = message.message
      }

      return msg
    }

    const renderLastModified = (message: ITransactionalMessage) => {
      const date: string =
        message.lastModified !== "1970-01-01T10:00:00"
          ? message.lastModified
          : message.localDateCreated
      return formatDate(new Date(date), "dd/mm/yyyy hh:MM:ss TT")
    }

    const resendEmail = async (emailAddress: string) => {
      selectedEmail &&
        (await resendTransactionalEmail(selectedEmail.id, emailAddress))
      setSelectedEmail(null)
      setRefetchMessages(true)
    }

    return (
      <>
        <Table
          loading={isLoading}
          dataSource={messages}
          rowKey={(message) => message.id}
          pagination={false}
        >
          <Column
            dataIndex="localDateCreated"
            title="Timestamp"
            render={(value: string) =>
              formatDate(new Date(value), "dd/mm/yyyy hh:MM:ss TT")
            }
          />
          <Column
            title="Message Type"
            dataIndex="type"
            render={(type: string) => convertCamelCaseToSpaces(type)}
          />
          <Column title="Sent To" dataIndex="sentTo" />
          <Column
            title="Message / Subject"
            render={(message: ITransactionalMessage) => renderMessage(message)}
          />
          <Column
            title="Status"
            render={(message: ITransactionalMessage) => renderStatus(message)}
          />
          <Column
            title="Delivery Status"
            render={(message: ITransactionalMessage) => (
              <>
                {message.deliveryStatus &&
                  convertCamelCaseToSpaces(message.deliveryStatus)}{" "}
                {message.deliveryStatusDetail &&
                message.deliveryStatusDetail !== "NotSet" &&
                message.deliveryStatusDetail !== "SpamComplaint"
                  ? `(${convertCamelCaseToSpaces(
                      message.deliveryStatusDetail
                    )})`
                  : ""}
              </>
            )}
          />
          <Column
            title="Last Modified"
            render={(message: ITransactionalMessage) =>
              renderLastModified(message)
            }
          />
          <Column
            key="Actions"
            align="right"
            render={(message: ITransactionalMessage) => (
              <>
                {message.type === "Email" && (
                  <>
                    <Space>
                      <Button
                        icon={<FileTextOutlined />}
                        size="small"
                        onClick={viewEmail(message)}
                        disabled={!(typeof message.emailUrl === "string")}
                      >
                        View
                      </Button>
                      <Button
                        icon={<SendOutlined />}
                        size="small"
                        onClick={() => setSelectedEmail(message)}
                      >
                        Resend
                      </Button>
                    </Space>
                  </>
                )}
              </>
            )}
          />
        </Table>
        <ConfirmEmailModal
          emailAddress={selectedEmail?.sentTo ?? ""}
          title={`Resend email ${
            selectedEmail?.subject ? `- ${selectedEmail.subject}` : ""
          }`}
          description="Please provide the email address you would like to send the
          email to."
          isVisible={!!selectedEmail}
          onCancel={(e) => setSelectedEmail(null)}
          onSend={resendEmail}
          isLoading={isResending}
        />
      </>
    )
  }

export const TransactionalMessagesSubView = withRouter(
  TransactionalMessagesComponent
)

export interface ITransactionalMessagesSubView extends RouteComponentProps {
  entityID: string
}
