import React from "react"
import { IConfigurationContext } from "contexts/configuration/context"
import { LayoutPageHeader } from "components"
import { Breadcrumb, Skeleton } from "antd"
import { BookOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { Nullable } from "platform-client/types/generics"

const PolicyHeaderBackground = styled.div`
  background: ${(props: { isWorking?: boolean }) =>
    !props.isWorking ? "transparent" : "#fff"};
  padding: ${(props: { isWorking?: boolean }) =>
    !props.isWorking ? 0 : "16px 24px"};
`

export const Header: React.FC<IHeader> = (props) => {
  return (
    <PolicyHeaderBackground isWorking={props.isWorking}>
      <Skeleton active loading={props.isWorking}>
        <LayoutPageHeader
          ghost={false}
          breadcrumb={
            <Breadcrumb>
              <Breadcrumb.Item key="/distributors">
                <Link to="/distributors">
                  <BookOutlined />
                  &nbsp;Distributors
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {props.breadcrumb ?? props.title}
              </Breadcrumb.Item>
            </Breadcrumb>
          }
          title={props.title}
          extra={props.actions}
          footer={props.tabs}
        >
          {props.children}
        </LayoutPageHeader>
      </Skeleton>
    </PolicyHeaderBackground>
  )
}

export interface IHeader {
  configuration: IConfigurationContext
  title?: string
  isWorking?: boolean
  actions?: React.ReactNode
  tabs?: React.ReactNode
  breadcrumb?: Nullable<string>
}
