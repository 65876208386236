import React from "react"
import styled from "styled-components"
import AddonInput, { IAddonProps } from "components/Console/CellContent/Addon"

const StyledInputNumber = styled.label.attrs<{
  textAlignment?: string
}>((props) => ({
  textAlignment: props.textAlignment || "right"
}))<{
  textAlignment?: string
}>`
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 0 6px;
  font-size: 14px;
  text-align: ${(props) => props.textAlignment};
`

const StyledInputNumberFocused = styled(StyledInputNumber)``

const CellContentRate: React.FunctionComponent<IRate> = ({
  type,
  value,
  textAlignment,
  addonProps,
  currencyCode
}) => {
  const [isFocused, setFocus] = React.useState<boolean>(false)
  const precision = type === "Percentage" ? 2 : 4

  function changeFocus(focusValue: boolean) {
    setFocus(focusValue)
  }

  const Formatter = (
    value: number,
    minPrecision?: number,
    maxPrecision?: number,
    locale?: "en-GB"
  ) =>
    new Intl.NumberFormat(locale, {
      ...(type === "Percentage" && { style: "percent" }),
      ...(type === "Fixed" &&
        currencyCode && { style: "currency", currency: currencyCode }),
      minimumFractionDigits: minPrecision || 2,
      maximumFractionDigits: maxPrecision || 2
    })
      .format(value)
      .replace(/^([0-9.+-]+)([%]?)$/, "$1 $2") // Can add more symbols to match if required

  const highPrecisionValue = Formatter(value, 10, 20)
  const lowPrecisionValue = Formatter(value, precision, precision)

  return isFocused ? (
    <StyledInputNumberFocused
      aria-label="rate-label-high-accuracy"
      textAlignment={textAlignment}
      onMouseLeave={() => {
        setTimeout(function () {
          changeFocus(false)
        }, 2000)
      }}
    >
      {addonProps && (
        <AddonInput exclude={value < 0 ? ["-", "+"] : []} {...addonProps} />
      )}
      {highPrecisionValue}
    </StyledInputNumberFocused>
  ) : (
    <StyledInputNumber
      textAlignment={textAlignment}
      aria-label="rate-label"
      onClick={() => changeFocus(true)}
    >
      {addonProps && (
        <AddonInput exclude={value < 0 ? ["-", "+"] : []} {...addonProps} />
      )}
      {lowPrecisionValue}
    </StyledInputNumber>
  )
}

export type RateType = "Percentage" | "Fixed" | "None"

export interface IRate {
  value: number
  type?: RateType
  textAlignment?: "left" | "right"
  addonProps?: IAddonProps
  currencyCode?: string
}

export default CellContentRate
