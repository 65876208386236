import React from "react"
import {
  IPolicy,
  IProduct,
  IPolicyholder,
  IPolicyDetailSet,
  IPolicyHistorySummary,
  ICompanyInRole
} from "platform-client/types"
import { PolicyHolder } from "components/PolicyHolder"
import {
  IConfigurationContext,
  useConfigurationContext
} from "contexts/configuration/context"
import SummaryOfCover from "components/SummaryOfCover"
import PolicyHistorySummary from "../../components/policy-history-summary"
import { IRisk } from "platform-client/types/quote"
import { Col, Row, Space, Tag } from "antd"
import ListCard from "components/ListCard"
import { DateDisplay } from "components/display/DateDisplay"
import styled from "styled-components"

const FullHeightListCard = styled(ListCard)`
  height: 100%;
`

const FullHeightPolicyHolder = styled(PolicyHolder)`
  height: 100%;
`

export const Summary: React.FC<ISummary> = (props) => {
  const config = useConfigurationContext()

  const status = config.policy.state[props.policy.policyStatus]
  return (
    <Space
      direction="vertical"
      size={24}
      style={{ width: "100%", maxWidth: "1200px", display: "flex" }}
    >
      <Row gutter={[24, 24]} style={{ gridAutoRows: "1fr" }}>
        <Col xs={24} lg={12}>
          <FullHeightListCard title="Policy Details">
            <ListCard.Item label="Product">
              {props.product?.name} (v{props.product?.version})
            </ListCard.Item>
            <ListCard.Item label="Policy Status">
              <Tag color={status?.color}>{status?.tag?.toUpperCase()}</Tag>
            </ListCard.Item>
            <ListCard.Item label="Created">
              {props.policy?.dateCreated && (
                <DateDisplay date={props.policy?.dateCreated} showTime />
              )}
            </ListCard.Item>
            {props.policy?.cancelledOnDate && (
              <ListCard.Item label="Cancelled On">
                <DateDisplay date={props.policy?.cancelledOnDate} showTime />
              </ListCard.Item>
            )}
            <ListCard.Item label="Cover start date">
              <DateDisplay date={props.policy.coverStartDate || ""} showTime />
            </ListCard.Item>
            <ListCard.Item label="Cover end date">
              <DateDisplay date={props.policy.coverEndDate || ""} showTime />
            </ListCard.Item>
            {props.policyCompanies && props.policyCompanies.length > 0 && (
              <>
                {props.policyCompanies.map((company) => (
                  <ListCard.Item
                    key={company.companyRoleID}
                    label={company.role}
                  >
                    {company.company.name}
                  </ListCard.Item>
                ))}
              </>
            )}
          </FullHeightListCard>
        </Col>
        <Col xs={24} lg={12}>
          <FullHeightPolicyHolder policyHolder={props.policyHolder} />
        </Col>
      </Row>
      {props.configuration.flags.platformVersion === "v3" &&
        props.policyDetailSet && (
          <SummaryOfCover policyDetailSet={props.policyDetailSet} />
        )}
      {props.configuration.flags.platformVersion === "v3" &&
        props.policyHistorySummary &&
        props.policyHistorySummary.data && (
          <PolicyHistorySummary
            policyHistorySummary={props.policyHistorySummary.data}
            isLoading={props.policyHistorySummary.isLoading}
          />
        )}
    </Space>
  )
}

export interface ISummary {
  policy: IPolicy
  risk: IRisk
  product: IProduct
  policyHolder?: IPolicyholder
  policyDetailSet?: IPolicyDetailSet
  policyCompanies?: ICompanyInRole[]
  policyHistorySummary?: {
    data?: IPolicyHistorySummary[]
    isLoading: boolean
  }
  configuration: IConfigurationContext
}
