import * as React from "react"
import { StaticContext } from "react-router"
import { RouteComponentProps, withRouter } from "react-router"
import { LayoutPageHeader, LayoutPageContent } from "components"
import { Layout } from "antd"
import { FileTextTwoTone } from "@ant-design/icons"
import { useConfigurationContext } from "contexts/configuration/context"
import { useApiInfo } from "contexts/configuration/hooks/use-api-info"
import { useToken } from "contexts/authorization/hooks/use-token"
import { useCreateUser } from "contexts/opus/hooks/use-create-user"
import { useAssignQuoteToUser } from "contexts/opus/hooks/use-assign-quote-to-user"
import { useQuery } from "react-query"
import { notification } from "antd"
import { AxiosError } from "axios"
import { IQuoteBundle } from "contexts/opus/context"
import useOpus from "contexts/opus/hooks/use-opus"

type AventusCheckoutTypes =
  | "aventus.checkout.abandon"
  | "aventus.checkout.start"
  | "aventus.checkout.fail"
  | "aventus.policy.bind"

interface IAventusEvent {
  data: {
    event: AventusCheckoutTypes
    policyId: string
  }
}

const _CheckoutQuote: React.FunctionComponent<ICheckoutQuote> = (props) => {
  const configContext = useConfigurationContext()
  const { apiInfo } = useApiInfo()
  const { userToken } = useToken()
  const { quoteClient } = useOpus()
  const { userId } = useCreateUser(props.location?.state?.proposerEmail)
  const { isQuoteAssigned } = useAssignQuoteToUser(
    props.match.params.quoteId,
    userId
  )

  const [url, setUrl] = React.useState<string>()

  const { data: quoteBundle } = useQuery<IQuoteBundle | undefined, AxiosError>(
    ["quote-bundle", props.match.params.quoteId],
    async () => quoteClient.getQuoteBundle(props.match.params.quoteId)
  )

  React.useEffect(() => {
    if (
      configContext &&
      quoteBundle &&
      apiInfo &&
      userToken &&
      isQuoteAssigned === true &&
      props.match.params.quoteId
    ) {
      setUrl(
        `${configContext.opus.baseOpusUrl}/checkout/${props.match.params.quoteId}?` +
          `productReference=${quoteBundle.risk.productReferenceID}&` +
          `productCoverReference=${quoteBundle.risk.coverType}&` +
          `tenantReference=${apiInfo.orgInfo.tenantReferenceId}&` +
          `tenantOrgReference=${apiInfo.orgInfo.referenceId}&` +
          `token=${userToken}&` +
          `userId=${userId}`
      )
    }
  }, [
    configContext,
    apiInfo,
    userToken,
    userId,
    isQuoteAssigned,
    props.match.params.quoteId,
    quoteBundle
  ])

  React.useEffect(() => {
    const handler = (event: IAventusEvent) => {
      if (event.data.event === "aventus.policy.bind" && event.data.policyId) {
        props.history.push(`/policy/${event.data.policyId}`)
        notification.success({
          message: "Policy bound",
          description: "The policy was successfully bound"
        })
      }
      if (event.data.event === "aventus.checkout.fail") {
        props.history.push(`/quote/${props.match.params.quoteId}`)
        notification.error({
          message: "Something went wrong",
          description:
            "There was an issue binding the policy. Please try again or contact us."
        })
      }
    }
    window.addEventListener("message", handler)
    return () => window.removeEventListener("message", handler)
  }, [props.history, props.match.params.quoteId])

  return (
    <Layout>
      <LayoutPageHeader
        avatar={{
          icon: <FileTextTwoTone />,
          style: { background: "transparent", marginRight: 5 }
        }}
        ghost={false}
        title={"Checkout"}
        onBack={() => {
          props.history.push(`/quote/${props.match.params.quoteId}`)
        }}
      />
      <LayoutPageContent>
        {url && (
          <iframe
            src={url}
            /*Stops the Iframe caching in Chrome
              https://stackoverflow.com/questions/25732519/preventing-iframe-caching-in-chrome*/
            name={"Checkout" + Date.now()}
            title={"Checkout"}
            id="checkout-iframe"
            frameBorder="0"
            height={960}
            width="100%"
          ></iframe>
        )}
      </LayoutPageContent>
    </Layout>
  )
}

export const CheckoutQuote = withRouter(_CheckoutQuote)

export interface ICheckoutQuote
  extends RouteComponentProps<
    { quoteId: string },
    StaticContext,
    { proposerEmail: string }
  > {
  className?: string
}
