import { DatabaseOutlined, UploadOutlined } from "@ant-design/icons"
import {
  Alert,
  Breadcrumb,
  Button,
  Card,
  Form,
  Input,
  Layout,
  notification,
  Select,
  Skeleton,
  Tag
} from "antd"
import { LayoutPageContent, LayoutPageHeader } from "components"
import { RatingsBoundary } from "containers/ratings/boundary"
import useOpus from "contexts/opus/hooks/use-opus"
import { useMutation, useQuery } from "react-query"
import { useRef, useState } from "react"
import { IProductRateVersion } from "models/ratings/IProductRateVersion"
import { AxiosError } from "axios"
import { useHistory } from "react-router"
import { bytesToMegaBytes } from "utils/pocketknife/bytes-to-mega-bytes"
import styled from "styled-components"

const StyledGrid = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 300px 80px 100px;
  align-items: center;
  gap: 10px 10px;
  .title {
    font-weight: bold;
  }
`

const StyledUploadButton = styled.label`
  input[type="file"] {
    display: none;
  }
`
interface IRatingsUploadForm {
  product: string
  version: number
  changelog: string
}

export const RatingsUpload: React.FC = () => {
  const [form] = Form.useForm<IRatingsUploadForm>()
  const history = useHistory()
  const opus = useOpus()

  const [products, setProducts] = useState<IProductRateVersion[]>([])
  const [selectedProduct, setSelectedProduct] = useState<
    IProductRateVersion | undefined
  >(undefined)
  const [selectedVersion, setSelectedVersion] = useState<number | undefined>(
    undefined
  )
  const [isWorking, setIsWorking] = useState<boolean>(false)

  const { isFetching } = useQuery<
    IProductRateVersion[] | undefined,
    AxiosError
  >(["productVersions"], async () => opus.ratingsClient.productRateVersions(), {
    onSuccess: (result) => {
      result && setProducts(result)
    }
  })
  const uploadRatings = useMutation((formData: FormData) =>
    opus.ratingsClient.uploadRatings(formData)
  )

  const fileInput = useRef<HTMLInputElement>(null)
  const [file, setFile] = useState<File | null>(null)

  const onSubmit = async (values: IRatingsUploadForm) => {
    setIsWorking(true)

    const data = new FormData()
    data.append("productReferenceID", values.product)
    data.append("version", values.version.toString())
    data.append("changelog", values.changelog)

    if (file !== null) {
      data.append("file", file)
    }

    uploadRatings
      .mutateAsync(data)
      .then((result) => {
        setIsWorking(false)
        notification.success({
          message: "Rate file uploaded succesfully."
        })
        history.replace("/ratings")
      })
      .catch(() => {
        setIsWorking(false)
        notification.error({
          message: "Unable to upload ratings file, please try again."
        })
      })
  }

  return (
    <RatingsBoundary>
      <Layout>
        <LayoutPageHeader
          ghost={false}
          breadcrumb={
            <Breadcrumb>
              <Breadcrumb.Item key="/ratings">
                <DatabaseOutlined />
                &nbsp;Ratings
              </Breadcrumb.Item>
            </Breadcrumb>
          }
          title="Upload Rate File"
        />
        <LayoutPageContent>
          <Skeleton active loading={isFetching}>
            <Form layout="vertical" form={form} onFinish={onSubmit}>
              <Card style={{ height: "100%", marginBottom: "20px" }}>
                <Form.Item
                  name="product"
                  label="Product"
                  rules={[
                    {
                      required: true,
                      message: "Please select a Product"
                    }
                  ]}
                >
                  <Select
                    placeholder="Select a Product"
                    value={selectedProduct?.productReferenceID}
                    onChange={(referenceID) => {
                      const newProduct = products?.find(
                        (p) => p.productReferenceID === referenceID
                      )
                      setSelectedVersion(undefined)
                      setSelectedProduct(newProduct as IProductRateVersion)
                    }}
                    options={products.map((product) => ({
                      label: product.productName,
                      value: product.productReferenceID
                    }))}
                  />
                </Form.Item>
                <Form.Item
                  name="version"
                  label="Rate Version"
                  rules={[
                    {
                      required: true,
                      message: "Please select a Rate Version"
                    }
                  ]}
                >
                  <Select
                    disabled={selectedProduct === undefined}
                    placeholder="Select a Rate Version"
                    value={selectedVersion}
                    onChange={(version) => {
                      setSelectedVersion(version)
                    }}
                    options={selectedProduct?.rateVersions?.map((v) => ({
                      label: v,
                      value: v
                    }))}
                  />
                </Form.Item>
                <Form.Item name="changelog" label="Changelog">
                  <Input.TextArea placeholder="Please enter the changelog for this rating file" />
                </Form.Item>
                <Form.Item
                  name="file"
                  label="File"
                  rules={[
                    {
                      required: true,
                      message: "Please upload a ratings file",
                      validator: (_, value) => {
                        if (file !== null) {
                          return Promise.resolve()
                        } else {
                          return Promise.reject("Failed")
                        }
                      }
                    }
                  ]}
                >
                  {file === null && (
                    <>
                      <StyledUploadButton className="ant-btn">
                        Choose file to upload <UploadOutlined />
                        <input
                          aria-label="file-upload"
                          type="file"
                          multiple={false}
                          ref={fileInput}
                          onChange={() => {
                            if (fileInput.current) {
                              const files = fileInput.current.files
                              if (files) {
                                const f = Array.from(files)[0]
                                setFile(f)
                                fileInput.current.value = ""
                              }
                            }
                          }}
                        />
                      </StyledUploadButton>
                    </>
                  )}
                  {file && (
                    <>
                      {bytesToMegaBytes(file.size) > 10 && (
                        <Alert
                          type="warning"
                          message="Files over 10MB will not be uploaded."
                          style={{ marginBottom: "20px" }}
                        />
                      )}
                      <StyledGrid>
                        <span>{file.name}</span>

                        <span>
                          <Tag
                            color={
                              bytesToMegaBytes(file.size) > 10 ? "red" : "green"
                            }
                          >
                            {bytesToMegaBytes(file.size, true)}MB
                          </Tag>
                        </span>
                        <Button
                          size="small"
                          type="link"
                          onClick={() => setFile(null)}
                        >
                          Remove
                        </Button>
                      </StyledGrid>
                    </>
                  )}
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button
                    style={{ marginRight: 8 }}
                    loading={isWorking}
                    type="primary"
                    htmlType="submit"
                  >
                    Upload
                  </Button>
                </Form.Item>
              </Card>
            </Form>
          </Skeleton>
        </LayoutPageContent>
      </Layout>
    </RatingsBoundary>
  )
}
