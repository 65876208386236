import styled from "styled-components"
import { Row, Typography } from "antd"
import classnames from "classnames"
import { Link } from "react-router-dom"
import moment from "moment"

interface INoteProps {
  content: string
  author: string
  date: string
  internal?: boolean
  link?: {
    text: string
    url: string
    label: string
  }
}

const { Text, Title } = Typography

const Container = styled.div`
  padding: 12px 24px;
  border-radius: 8px;
  max-width: 70ch;
  background: white;
  &.internal {
    background: #fff3df;
  }
`
const StyledInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 8px;
  &.internal {
    padding-bottom: 0;
  }
`

const StyledTag = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 8px;
`

const StyledContent = styled.div`
  word-break: break-all;
`

export const Note: React.FC<INoteProps> = ({
  content = "",
  date = "",
  author = "",
  internal = false,
  link
}) => (
  <Container className={classnames({ internal }, "note")}>
    <StyledInfo className={classnames({ internal })}>
      <Title level={5} style={{ margin: 0 }}>
        {author}
      </Title>
      {date && <Text>{moment(date).fromNow()}</Text>}
    </StyledInfo>
    {internal && (
      <StyledTag>
        <Text italic>internal</Text>
      </StyledTag>
    )}
    <StyledContent dangerouslySetInnerHTML={{ __html: content || "" }} />
    {link && (
      <Row justify="end">
        <Text>
          {link.label}{" "}
          <Link to={link.url} target="_blank">
            {link.text}
          </Link>
        </Text>
      </Row>
    )}
  </Container>
)
