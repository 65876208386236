import React from "react"
import { Card, Space, Table } from "antd"
import { IPolicyHistory } from "models/policies/policyHistory"
import { DateDisplay } from "components/display/DateDisplay"
import { IMoney } from "platform-client/types"
import { MoneyDisplay } from "components/display/MoneyDisplay"

export const PolicyHistory: React.FunctionComponent<IPolicyHistoryComponent> = (
  props
) => {
  return (
    <Card bodyStyle={{ padding: 0 }}>
      <Table
        loading={props.isPolicyHistoryLoading}
        dataSource={props.policyHistory}
        scroll={{ x: "max-content" }}
        rowKey="id"
        pagination={{
          defaultPageSize: 10,
          hideOnSinglePage: true,
          position: ["bottomCenter"],
          size: "small"
        }}
      >
        <Table.Column
          title="Date"
          dataIndex="dateCreated"
          render={(value: string) => <DateDisplay date={value} />}
        />
        <Table.Column title="Type" dataIndex="policyHistoryType" />
        <Table.Column
          title="Change in GWP"
          dataIndex="priceGrossChange"
          align="right"
          render={(value: IMoney) => <MoneyDisplay value={value} />}
        />
        <Table.Column
          key="actions"
          align="right"
          render={(value: IPolicyHistory) =>
            value.document && (
              <Space>
                <a
                  href={value.document.documentUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {value.document.filename}
                </a>
              </Space>
            )
          }
        />
      </Table>
    </Card>
  )
}

export interface IPolicyHistoryComponent {
  policyHistory: IPolicyHistory[] | undefined
  isPolicyHistoryLoading: boolean
}
