import { DistributorsBoundary } from "./boundary"
import CreateDistributor from "./views/CreateDistributor"
import ListDistributors from "./views/ListDistributors"
import Distributor from "./views/SingleDistributor"

export const VDistributorsList: React.FC = (props) => (
  <DistributorsBoundary>
    <ListDistributors {...props} />
  </DistributorsBoundary>
)

export const VDistributor: React.FC = (props) => (
  <DistributorsBoundary>
    <Distributor {...props} />
  </DistributorsBoundary>
)

export const VDistributorsCreate: React.FC = (props) => (
  <DistributorsBoundary>
    <CreateDistributor {...props} />
  </DistributorsBoundary>
)
