import { ISection, ISectionNoPremium, ISubsection } from "platform-client/types"

export const mockSection: ISectionNoPremium = {
  tax: [],
  commission: {
    commissionCalculationType: "NetPremiumInsurer",
    commissionType: "FixedRate",
    baseRuleAmount: 0,
    amount: null,
    name: "Commission",
    referenceID: "avt_comm_base",
    ruleAmount: 0,
    breakdown: {
      introducerAmount: { value: 0, currencyCode: "GBP " },
      introducerBaseRuleAmount: 0,
      introducerRuleAmount: 0.12,
      mgaAmount: { value: 0, currencyCode: "GBP " },
      mgaRuleAmount: 0
    }
  },

  netLoadingDiscounts: [],
  grossDiscounts: [],
  totalGrossPremium: null,
  totalNetPremium: null,
  totalTax: null,
  totalCommission: null,
  totalNetPremiumInsurer: null,
  totalNetDiscount: null,
  totalGrossDiscount: null,
  totalCoverageFees: null,
  id: "eb20ec73-2528-f53f-dffd-6c4dcdd0712f",
  referenceID: "avt_sect_employersliability",
  name: "Employers Liability",
  schemeID: 180,
  enabled: true,
  totalSubsectionPremium: null,
  schemeReferenceID: "this-is-a-string",
  subsections: [],
  coverStartDateTime: "2021-01-01 10:00:00",
  coverEndDateTime: "2021-01-01 10:00:00"
}

export const mockSubsection: ISubsection = {
  netLoadingDiscounts: [
    {
      id: "asdasd",
      amount: {
        value: 999.0,
        currencyCode: "GBP"
      },
      name: "Location Load",
      type: "Percentage",
      ruleAmount: 0.15,
      order: 1,
      referenceID: "avt_ld_location",
      baseRuleAmount: 0.15
    },
    {
      id: "asdasd",
      amount: {
        value: 999.0,
        currencyCode: "GBP"
      },
      name: "Location Type Load",
      type: "Percentage",
      ruleAmount: 0.05,
      order: 2,
      referenceID: "avt_ld_locationtype",
      baseRuleAmount: 0.05
    }
  ],
  riskScores: [{ name: "Cheese", score: 20 }],
  totalPremium: {
    value: 999.0,
    currencyCode: "GBP"
  },
  totalNetDiscount: {
    value: 999.0,
    currencyCode: "GBP"
  },
  totalCoverageFees: null,
  id: "1df0c1ee-aeb6-39bf-bd82-f354355c897c",
  referenceID: "avt_sect_employersliability",
  name: "Employers' Liability",
  enabled: true,
  dependentOnSectionReference: null,
  coverages: [],
  exposureIDs: [],
  coverStartDateTime: "",
  coverEndDateTime: ""
}

export const mockSectionWithValues: ISection = {
  tax: [],
  commission: {
    commissionCalculationType: "NetPremiumInsurer",
    commissionType: "FixedRate",
    baseRuleAmount: 4.12,
    amount: null,
    name: "Commission",
    referenceID: "avt_comm_base",
    ruleAmount: 4.12,
    breakdown: {
      mgaRuleAmount: 9,
      mgaAmount: {
        value: 400,
        currencyCode: "GBP"
      },
      introducerBaseRuleAmount: 3,
      introducerRuleAmount: 69,
      introducerAmount: {
        value: 500,
        currencyCode: "GBP"
      }
    }
  },
  netLoadingDiscounts: [],
  grossDiscounts: [],
  totalGrossPremium: {
    value: 400,
    currencyCode: "GBP"
  },
  totalNetPremium: {
    value: 500,
    currencyCode: "GBP"
  },
  totalTax: {
    value: 100,
    currencyCode: "GBP"
  },
  totalCommission: {
    value: 50,
    currencyCode: "GBP"
  },
  totalCoverageFees: {
    value: 50,
    currencyCode: "GBP"
  },
  totalNetPremiumInsurer: null,
  totalSubsectionPremium: null,
  totalNetDiscount: null,
  totalGrossDiscount: null,
  id: "cb36a050-d400-6a4a-3420-c02a5783a827",
  referenceID: "avt_sect_materialDamages",
  name: "Material Damages",
  enabled: true,
  schemeID: 180,
  schemeReferenceID: "avt_s_ref",
  subsections: [mockSubsection],
  coverStartDateTime: "",
  coverEndDateTime: ""
}
