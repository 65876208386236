import React from "react"
import { IConfigurationContext } from "contexts/configuration/context"
import { QuoteState } from "platform-client/types/quote"
import { Alert, Breadcrumb, Skeleton, Tag, Tooltip } from "antd"
import { FileTextOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import { LayoutPageHeader } from "components"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { Nullable } from "platform-client/types"
import { QuoteStateLabels } from "containers/quotes/constants"

const QuoteHeaderBackground = styled.div`
  background: ${(props: { isWorking?: boolean }) =>
    !props.isWorking ? "transparent" : "#fff"};
  padding: ${(props: { isWorking?: boolean }) =>
    !props.isWorking ? 0 : "16px 24px"};
`

export const QuoteHeader: React.FC<IQuoteHeader> = (props) => (
  <>
    <QuoteHeaderBackground isWorking={props.isWorking}>
      <Skeleton active loading={props.isWorking}>
        <LayoutPageHeader
          ghost={false}
          breadcrumb={
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/quotes">
                  <FileTextOutlined />
                  &nbsp; Quotes
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Viewing</Breadcrumb.Item>
            </Breadcrumb>
          }
          title={props.title}
          tags={
            <>
              {props.quoteState && (
                <Tag color={QuoteStateLabels[props.quoteState].color}>
                  {QuoteStateLabels[props.quoteState].label.toUpperCase()}
                </Tag>
              )}
              <Tag color={"geekblue"}>
                Quick Quote&nbsp;
                <Tooltip
                  placement="bottom"
                  title="This quote will need to be converted into a full quote before it can be bound."
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </Tag>
            </>
          }
          extra={props.actions}
          footer={props.tabs}
        >
          {props.disableBind && (
            <Alert
              banner
              message={`You are unable to bind as ${props.companyName} is not allowed to bind policies. Please contact ${props.configuration.app.organisation} to enable this permission.`}
              type="warning"
            />
          )}
        </LayoutPageHeader>
      </Skeleton>
    </QuoteHeaderBackground>
  </>
)

export interface IQuoteHeader {
  configuration: IConfigurationContext
  isWorking?: boolean
  title?: string
  actions?: React.ReactNode
  tabs?: React.ReactNode
  quoteState?: QuoteState
  companyName?: Nullable<string>
  disableBind?: boolean
}
