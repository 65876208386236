import React, { useState } from "react"
import {
  Layout,
  Table,
  Button,
  Input,
  Modal,
  notification,
  Breadcrumb,
  Row,
  Col
} from "antd"
import { LayoutPageContent, LayoutPageHeader } from "components"
import { RouteComponentProps } from "react-router"
import { PlusOutlined, UserOutlined } from "@ant-design/icons"
import { COLUMNS } from "./constants"
import { useConfigurationContext } from "contexts/configuration/context"
import useOpus from "contexts/opus/hooks/use-opus"
import usePlatform from "contexts/platform/use-platform"
import { useMutation, useQuery } from "react-query"
import { checkUserPermission } from "contexts/authorization/hooks/use-opus-permissions"
import { decodeJWT } from "utils/pocketknife/decode-jwt"
import { EUserGroups } from "platform-client/types"
import { UserModal } from "../UserModals"
import { IUserInsurer } from "contexts/opus/context"
import { useAuthenticatedUserContext } from "contexts/authorization/authenticated-user-context"

export const AllUsers: React.FunctionComponent<IAllUsers> = () => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const { userClient, opusToken } = useOpus()
  const configuration = useConfigurationContext()
  const authUser = useAuthenticatedUserContext()

  const {
    data: users,
    isLoading,
    refetch: refetchUsers
  } = useQuery(["users", "all"], () => userClient.getUsers())

  const [showAddModal, setShowAddModal] = useState<boolean>()
  const [showEditModal, setShowEditModal] = useState<boolean>()
  const [editingUser, setEditingUser] = useState<IUserInsurer>()

  const platform = usePlatform()

  const searchUsers = useMutation(
    ({ query, userRoles }: { query: string; userRoles: EUserGroups[] }) =>
      userClient.search(query, userRoles)
  )

  const searchUsersV3 = useMutation(
    ({ query, userRoles }: { query: string; userRoles: EUserGroups[] }) =>
      platform.user.search(query, userRoles)
  )

  const searchableGroups = [
    EUserGroups.Insurer,
    EUserGroups.InsurerAdministrator
  ]

  const searchData =
    configuration.flags.platformVersion === "v3" ? searchUsersV3 : searchUsers

  const canManageUsers =
    (opusToken &&
      checkUserPermission("usermanagement:maintain", decodeJWT(opusToken))) ||
    false

  const userResendEmail = async (userID: string) => {
    if (userID) {
      const userResendEmailRespnse = await userClient.setUserResend(userID)
      if (userResendEmailRespnse) {
        notification.success({
          message: "Success",
          description: "Email has been successfully resend",
          placement: "bottomLeft",
          duration: 3
        })
      }
    }
  }

  const getUser = async (userID: string) => {
    setEditingUser(undefined)
    if (userID) {
      const user = await userClient.getUser(userID)
      setEditingUser(user)
    }
  }

  const addModal = (
    <UserModal
      isEditing={false}
      refetchUsers={refetchUsers}
      isModalVisible={showAddModal}
      setIsModalVisible={setShowAddModal}
      user={undefined}
    />
  )

  const editModal = (
    <UserModal
      isEditing={true}
      refetchUsers={refetchUsers}
      isModalVisible={showEditModal}
      setIsModalVisible={setShowEditModal}
      user={editingUser?.user}
    />
  )

  const modalDisable = (userID: string) =>
    Modal.confirm({
      title: "Are you sure you want to disable this user?",
      content: "You won't be able to undo this action",
      centered: true,
      okText: "Disable",
      okButtonProps: {
        danger: true
      },
      onOk: async () => {
        if (userID) {
          const userDisbaleResponse = await userClient.setUserDisable(userID)
          if (userDisbaleResponse) {
            refetchUsers()
          }
        }
      }
    })

  return (
    <>
      <Layout>
        <LayoutPageHeader
          ghost={false}
          breadcrumb={
            <Breadcrumb>
              <Breadcrumb.Item>
                <UserOutlined />
                &nbsp; Users
              </Breadcrumb.Item>
            </Breadcrumb>
          }
          title="All Users"
          extra={
            canManageUsers && (
              <Button
                type="primary"
                onClick={() => setShowAddModal(true)}
                icon={<PlusOutlined />}
              >
                Add new user
              </Button>
            )
          }
        />
        <LayoutPageContent>
          <Row justify="end" align="middle">
            <Col style={{ padding: 16 }}>
              <Input.Search
                style={{ width: 425 }}
                onSearch={(value: string) => {
                  if (configuration.flags.platformVersion === "v3") {
                    searchUsersV3.mutateAsync({
                      query: value || "",
                      userRoles: searchableGroups
                    })
                  } else {
                    searchUsers.mutateAsync({
                      query: value || "",
                      userRoles: searchableGroups
                    })
                  }
                }}
                placeholder="Search by name, company or email"
                enterButton="Search"
                defaultValue={""}
                allowClear={true}
              />
            </Col>
          </Row>
          <Table
            scroll={{ x: "max-content" }}
            loading={isLoading || searchData.isLoading}
            columns={COLUMNS(
              configuration?.user?.state,
              { modalDisable },
              { userResendEmail, getUser },
              { currentUserID: authUser?.user?.id },
              canManageUsers,
              setShowEditModal
            )}
            dataSource={searchData.data !== undefined ? searchData.data : users}
            rowKey="id"
            pagination={{
              defaultPageSize: 10,
              style: { marginRight: 20 },
              position: ["bottomCenter"],
              size: "small",
              hideOnSinglePage: true,
              defaultCurrent: currentPage,
              onChange: (pageNumber) => setCurrentPage(pageNumber)
            }}
          />
        </LayoutPageContent>
      </Layout>
      {addModal}
      {editModal}
    </>
  )
}

export type IAllUsers = RouteComponentProps<{
  status?: string | undefined
}>
