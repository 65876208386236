import Card, { CardProps } from "antd/lib/card"
import React from "react"
import styled from "styled-components"

const StyledDL = styled.dl`
  margin: 0;
`

const StyledDT = styled.dt`
  margin: 0;
  color: rgba(0, 0, 0, 0.45);
`
const StyledDD = styled.dd`
  margin: 0;
  grid-column: span 2 / span 2;
`
const StyledInfoLine = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  padding: 8px 24px;
  border-bottom: 1px solid #f0f0f0;
  gap: 1rem;
`

export const ListCardItem: React.FunctionComponent<{ label: string }> = ({
  label,
  children
}) => (
  <StyledInfoLine>
    <StyledDT>{label}</StyledDT>
    <StyledDD>{children}</StyledDD>
  </StyledInfoLine>
)

const ListCardComponent: React.FunctionComponent<ILayoutCardListProps> = ({
  children,
  ...cardProps
}) => {
  return (
    <>
      <Card bodyStyle={{ padding: 0 }} {...cardProps}>
        <StyledDL>{children}</StyledDL>
      </Card>
    </>
  )
}

type InternalType = typeof ListCardComponent
interface ILayoutCardList extends InternalType {
  Item: typeof ListCardItem
}

const ListCard = ListCardComponent as ILayoutCardList

ListCard.Item = ListCardItem

export default ListCard

export type ILayoutCardListProps = CardProps
