import { Form, Input, Select } from "antd"
import { AddressAutocomplete } from "components/AddressAutocomplete"
import { IUniversalAddress } from "platform-client/types"
import React, { useState } from "react"
import styled from "styled-components"
import { AddressServiceType } from "utils/addressService"
import countries from "utils/data/countries.json"

interface IUniversalAddressValue extends Omit<IUniversalAddress, "country"> {
  country?: string
}

type UniversalAddressProps = {
  name?: string | string[]
  onChange?: (address?: IUniversalAddressValue) => void
  value?: IUniversalAddress
  manual?: boolean
  useAddressService?: AddressServiceType
}

const StyledSwitchButton = styled.span<{ $manual: boolean }>`
  display: inline-block;
  cursor: pointer;
  padding-top: ${(props) => (!props.$manual ? "15px" : 0)};

  &:hover {
    color: var(--ant-primary-color, #1890ff);
  }
`

export const UniversalAddress: React.FC<UniversalAddressProps> & {
  ManualInput: React.FC
} = ({
  value,
  onChange,
  name,
  manual = false,
  useAddressService,
  children
}) => {
  const [showManual, setShowManual] = useState<boolean>(manual)
  const hasChildren = React.Children.count(children) > 0

  const childrenWithProps = React.Children.map(children, (child) => {
    return typeof child !== "string" && React.isValidElement(child)
      ? React.cloneElement(child, { parentName: name, address: value })
      : child
  })

  return (
    <>
      {(!hasChildren || showManual === false) && (
        <AddressAutocomplete
          address={value}
          setAddress={(address) => {
            if (address) {
              onChange?.({
                ...address,
                country: JSON.stringify(address?.country)
              })
            }
          }}
          useAddressService={useAddressService}
        />
      )}

      {hasChildren && showManual === true && childrenWithProps}

      {hasChildren && (
        <StyledSwitchButton
          $manual={showManual}
          onClick={() => setShowManual(!showManual)}
        >
          {showManual ? "Return to address lookup" : "Enter address manually"}
        </StyledSwitchButton>
      )}
    </>
  )
}

type ManualUniversalAddressProps = {
  parentName?: string | string[]
  address?: IUniversalAddressValue
}

const ManualUniversalAddress: React.FC<ManualUniversalAddressProps> = ({
  parentName,
  address
}) => {
  const fieldName = (
    ...args: (string[] | string | undefined | null)[]
  ): string[] =>
    args.reduce((a: string[], c) => {
      if (!c) {
        return a
      }

      return [...a, ...(Array.isArray(c) ? c : [c])]
    }, [])

  return (
    <>
      <Form.Item
        name={fieldName(parentName, "line1")}
        label="Address line 1:"
        rules={[
          {
            whitespace: true,
            message: "Address line 1 cannot be empty"
          },
          {
            required: true,
            message: "Address line 1 is required"
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={fieldName(parentName, "line2")}
        label="Address line 2:"
        rules={[
          {
            whitespace: true,
            message: "Address line 2 cannot be empty"
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={fieldName(parentName, "line3")}
        label="Address line 3:"
        rules={[
          {
            whitespace: true,
            message: "Address line 3 cannot be empty"
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={fieldName(parentName, "locality")}
        label="Town:"
        rules={[
          { whitespace: true, message: "Locality cannot be empty" },
          {
            required: true,
            message: "Locality is required"
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={fieldName(parentName, "province")}
        label="County:"
        rules={[{ whitespace: true, message: "Province cannot be empty" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={fieldName(parentName, "postalCode")}
        label="Postcode:"
        rules={[
          {
            whitespace: true,
            message: "Postcode cannot be empty"
          },
          {
            required: true,
            message: "Postcode is required"
          }
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name={fieldName(parentName, "country")} label="Country:">
        <Select showSearch>
          {countries.map((item) => (
            <Select.Option value={JSON.stringify(item)}>
              {item.text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </>
  )
}

UniversalAddress.ManualInput = ManualUniversalAddress
