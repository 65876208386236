import React, { useState } from "react"
import { RouteComponentProps, StaticContext } from "react-router"
import { LayoutPageContent } from "../../../../../components/LayoutPageContent"
import { useHistory, Route, Switch, Redirect, Link } from "react-router-dom"
import { Layout, Tabs, Alert, Badge, Space, notification } from "antd"
import {
  IEvent,
  INote,
  IDocument,
  IPaymentPlan,
  IProduct,
  IRenewalReview,
  PolicyDetailType,
  IReferral,
  IPolicyholder,
  IPolicyDetailSet,
  CoverPeriodType,
  EndorsementType,
  IMoney,
  EUserGroups,
  IDeclineReason,
  IExcess
} from "platform-client/types"
import { IConfigurationContext } from "contexts/configuration/context"
import { TErrorState } from "models/errors"
import { QuoteHeader } from "../components/quote-header"
import { QuoteActions } from "../components/quote-actions"
import { QuoteAside } from "../components/quote-aside"
import { Summary } from "../sub-views/summary"
import { QuoteUnderwritingSubView } from "../sub-views/underwriting"
import { Documents } from "../sub-views/documents"
import Review from "../sub-views/review"
import { QuoteAnswers } from "../sub-views/quoteAnswers"
import { useDefaultPage } from "utils/hooks/use-default-page"
import {
  useOpusPermissions,
  EOpusPermission
} from "contexts/authorization/hooks/use-opus-permissions"
// These are referrencing the old models because there aren't
// equivalents in the new v3 platform, so we can still support
// the old way directly.
import { IProductItemOpus, IProductOpus } from "models/quotes/productOpus"
import { IQuotePaymentLink as IQuotePaymentLinkModel } from "contexts/opus/context"
import { useNotification } from "services/hooks/use-notification"
import { IEndorsementOpus } from "models/quotes/endorsementOpus"
import { ICompanyInRole } from "platform-client/types/company"

import usePlatform from "contexts/platform/use-platform"
import { useMutation, UseMutationResult, useQuery } from "react-query"
import { ReferQuoteModal } from "../components/refer-quote-modal"
import { useReferQuoteModal } from "../components/refer-quote-modal/useReferQuoteModal"
import QuoteEndorsements from "../sub-views/endorsements"
import { RiskChanges } from "../sub-views/riskChanges"
import CoverPeriodSelect from "components/Console/CoverPeriodSelect"
import { QuoteOracleResults } from "../sub-views/oracleResults"
import {
  IOriginalPolicyMeta,
  IQuote,
  IQuoteAction,
  IQuoteBundle,
  IQuoteChange,
  IRisk,
  IWithdrawReason
} from "platform-client/types/quote"
import {
  IConfirmReferralQuoteProps,
  IDeclineReferralQuoteProps
} from "platform-client/client/controllers/quote"
import { useGetAppConfig } from "contexts/configuration/hooks/use-get-app-config"
import useOpus from "contexts/opus/hooks/use-opus"
import { Notes } from "../sub-views/Notes"
import { NewNote } from "../components/new-note"
import { ExcessesView } from "../sub-views/excesses"
import { IScheme } from "models/quotes/scheme"
import { TransactionalMessagesSubView } from "components/Messages"

export const Quote: React.FC<IQuoteProps> = (props) => {
  const history = useHistory()
  const platform = usePlatform()
  const referQuoteModal = useReferQuoteModal()

  const [isModalVisible, setModalVisibility] = useState(false)

  const { havePermission: canUseNewQuote } = useOpusPermissions(
    EOpusPermission.Quote,
    "create"
  )

  const { havePermission: canProcessReferral } = useOpusPermissions(
    EOpusPermission.ProcessRefferal,
    "maintain"
  )

  const { quoteClient } = useOpus()

  const { applicationConfig } = useGetAppConfig()

  const activeLines = applicationConfig?.activeLinesOfBusiness
    .map((activeLine) => {
      if (activeLine.isActive) {
        return activeLine.coverTypes.map((coverType) => ({
          ...coverType,
          ...{ id: activeLine.id, productType: activeLine.productType }
        }))
      }
      return null
    })
    .flat()
    .filter((item) => item)

  const isHybrid =
    activeLines?.find(
      (qct) => qct?.productReferenceId === props.quote.productReferenceID
    )?.productType === "Hybrid"

  const coverType = activeLines?.find(
    (qct) => qct?.productReferenceId === props.quote.productReferenceID
  )?.name

  useNotification(props.location.state?.action, props.history, "quote")

  useDefaultPage(props.error?.errorNumber, ["404", "400"], {
    status: "404",
    title: `Sorry, we couldn't find a quote that matched those details. `,
    buttonText: "Back to Quotes",
    redirectTo: "/quotes"
  })

  // Since this portal was initially developed with Westcor in mind,
  // we only show the annual payment plan. This will change in the future.
  const [annual] = props.quotePaymentPlans?.paymentPlans || []

  // TODO: This is really fugly a better way of getting these totals needs to be thought out
  const addonNetTotals = props.quotePaymentPlans?.paymentPlans
    ?.filter((x) => x.planType === "FixedTermSinglePayment")[0]
    ?.addonNetTotals?.reduce((partialSum, a) => partialSum + a.value, 0)

  const reviewPrice: IMoney = annual?.price?.total?.net
    ? {
        value:
          (annual.price.total.net?.value -
            (addonNetTotals ? addonNetTotals : 0)) /
          100,
        currencyCode: annual.price.total.net?.currencyCode,
        unit: "Minor"
      }
    : {
        value: 0,
        currencyCode: "GBP",
        unit: "Minor"
      }

  const shouldShowReview =
    (props.configuration.flags.platformVersion === "v3" &&
      ["Referred"].includes(props.quote?.quoteState)) ||
    (canUseNewQuote &&
      props.quote?.quoteType === "Renewal" &&
      props.configuration.flags.showReviewTab &&
      props.quoteRenewalReview?.review?.requiresReview)

  const { data: currentUser } = useQuery(
    ["currentUser"],
    async () => platform.user.getCurrentUser(),
    {
      enabled: props.configuration.flags.platformVersion === "v3"
    }
  )

  const currentUserGroupType = currentUser?.groupType || EUserGroups.Distributor

  const referQuote = useMutation(
    async (comment: string) => {
      if (!comment)
        return notification.error({ message: "Please enter the message" })
      if (props.configuration.flags.platformVersion === "v3") {
        return platform.quote.referQuote(props.quote.id, comment)
      } else {
        return quoteClient.referQuote(props.quote.id, comment)
      }
    },
    {
      onSuccess: () => {
        referQuoteModal.setVisible(false)
        handleQuoteChange()
      },
      onError: () => {
        notification.error({
          message: "Unable to refer the quote, please refresh and try again."
        })
      }
    }
  )

  const quoteNotes = useQuery(
    ["quoteNotes", props.match.params.id],
    () => platform.quote.getNotes(props.match.params.id || ""),
    {
      onError: () =>
        notification.error({
          message: "Couldn't load the notes, please try again"
        })
    }
  )

  // We need to create a wrapper here
  // because some queries that need to be refetched have
  // been put in at this level rather than the parent.
  const handleQuoteChange = () => {
    props.onQuoteChange?.()
    quoteNotes.refetch()
  }

  return (
    <>
      <Layout>
        <NewNote
          setModalVisability={setModalVisibility}
          isModalVisible={isModalVisible}
          id={props.match.params.id}
          quoteNotes={quoteNotes}
        />
        {shouldShowReview && (
          <Alert
            message={
              <>
                This quote requires review before you can perform any other
                action.
                {canProcessReferral && (
                  <Link
                    to={`/quote/${props.quote.id}/review`}
                    style={{ marginLeft: "0.25em" }}
                  >
                    Review Quote
                  </Link>
                )}
              </>
            }
            type="warning"
            banner
            closable
            closeText="Dismiss"
            style={{ textAlign: "center" }}
          />
        )}
        <QuoteHeader
          configuration={props.configuration}
          isWorking={false}
          title={props.quote.quoteReferenceID}
          companyName={currentUser?.company?.name}
          disableBind={currentUser?.company?.brokerCanBindBusiness === false}
          actions={
            <QuoteActions
              configuration={props.configuration}
              quoteId={props.quote.id}
              quoteType={props.quote.quoteType}
              quoteState={props.quote.quoteState}
              quoteReferenceID={props.quote.quoteReferenceID}
              quoteProductReferenceId={props.quote.productReferenceID}
              quoteProductCoverReference={props.quote.productCoverReference}
              quoteProductCoverType={coverType}
              quoteChanges={props.quoteChanges}
              quoteActions={props.quoteActions}
              proposerEmail={
                props.risk?.core.proposer.emailAddress ||
                props.risk?.core.proposer.email
              }
              withdrawReasons={props.quoteWithdrawReasons?.withdrawReasons}
              quoteProduct={props.quoteProduct}
              quoteLifecycles={props.quoteLifecycles}
              originalPolicyMeta={props.originalPolicyMeta}
              userId={props.ownerId}
              handleBindQuote={props.handleBindQuote}
              paymentPlans={props.quotePaymentPlans?.paymentPlans}
              proposer={props.risk?.core.proposer}
              policyDetail={props.policyDetail}
              quoteCompanies={props.quoteCompanies}
              handleConfirmQuote={props.handleConfirmQuote}
              disableBind={
                props.isWorking ||
                currentUser?.company?.brokerCanBindBusiness === false
              }
              handleManualReferral={() => referQuoteModal.setVisible(true)}
              handleRevokeManualReferral={() =>
                props.handleRevokeManualReferral?.()
              }
              isManualReferralLoading={
                props.isManualReferralLoading || referQuote.isLoading
              }
              showReferQuoteModal={() => referQuoteModal.setVisible(true)}
              isHybrid={isHybrid}
              isExternal={props.quote.isExternal}
              productId={props.quote.productID}
              coverStartDate={
                props.configuration.flags.platformVersion === "v3" &&
                props.policyDetail
                  ? "coverStartDateTime" in props.policyDetail
                    ? props?.policyDetail?.coverStartDateTime
                    : undefined
                  : props?.risk?.core?.coverStartDate
              }
              coverEndDate={
                props.configuration.flags.platformVersion === "v3" &&
                props.policyDetail
                  ? "coverEndDateTime" in props.policyDetail
                    ? props?.policyDetail?.coverEndDateTime
                    : undefined
                  : props?.risk?.core?.coverEndDate
              }
              onNoteButtonClick={() => setModalVisibility(true)}
            />
          }
          tabs={
            <Tabs
              defaultActiveKey={props.match.params.subpage}
              activeKey={props.match.params.subpage}
              onTabClick={(activeKey) => {
                switch (activeKey) {
                  case "summary":
                    history.push(`/quote/${props.quote.id}/summary`)
                    break
                  case "review":
                    history.push(`/quote/${props.quote.id}/review`)
                    break
                  case "documents":
                    history.push(`/quote/${props.quote.id}/documents`)
                    break
                  case "messages":
                    history.push(`/quote/${props.quote.id}/messages`)
                    break
                  case "endorsements":
                    history.push(`/quote/${props.quote.id}/endorsements`)
                    break
                  case "excesses":
                    history.push(`/quote/${props.quote.id}/excesses`)
                    break
                  case "underwriting":
                    history.push(`/quote/${props.quote.id}/underwriting`)
                    break
                  case "riskchanges":
                    history.push(`/quote/${props.quote.id}/riskchanges`)
                    break
                  case "answers":
                    history.push(`/quote/${props.quote.id}/answers`)
                    break
                  case "oracleresults":
                    history.push(`/quote/${props.quote.id}/oracleresults`)
                    break
                  case "notes":
                    history.push(`/quote/${props.quote.id}/notes`)
                    break
                }
              }}
            >
              <Tabs.TabPane tab="Summary" key="summary" />
              <Tabs.TabPane tab="Documents" key="documents" />
              <Tabs.TabPane tab="Messages" key="messages" />
              <Tabs.TabPane
                tab={
                  <Space align="center">
                    Endorsements
                    <Badge
                      count={
                        (props.quoteEndorsements &&
                          props.quoteEndorsements.length) ??
                        0
                      }
                      showZero
                      style={{
                        marginTop: "-5px",
                        backgroundColor: "var(--ant-primary-color)"
                      }}
                      dot={false}
                    />
                  </Space>
                }
                key="endorsements"
              />
              {props.configuration.flags.platformVersion === "v3" && (
                <Tabs.TabPane
                  tab={
                    <Space align="center">
                      Excesses
                      <Badge
                        count={
                          (props.quoteExcesses && props.quoteExcesses.length) ??
                          0
                        }
                        showZero
                        style={{
                          marginTop: "-5px",
                          backgroundColor: "var(--ant-primary-color)"
                        }}
                        dot={false}
                      />
                    </Space>
                  }
                  key="excesses"
                />
              )}
              {props.configuration.flags.platformVersion === "v3" && (
                <>
                  {["Insurer", "InsurerAdministrator"].includes(
                    currentUserGroupType
                  ) && <Tabs.TabPane tab="Underwriting" key="underwriting" />}

                  {props.quote.quoteType === "MTA" && (
                    <Tabs.TabPane tab="Risk Changes" key="riskchanges" />
                  )}
                </>
              )}
              {(isHybrid ||
                props.configuration.flags.platformVersion === "v3") && (
                <>
                  <Tabs.TabPane tab="Answers" key="answers" />
                  <Tabs.TabPane tab="Data Oracle Results" key="oracleresults" />
                </>
              )}
              <Tabs.TabPane
                tab={
                  <Space align="center">
                    Review
                    <Badge
                      count={props.quoteReferrals?.length}
                      style={{
                        marginTop: "-5px",
                        backgroundColor:
                          props.quote.quoteState === "Referred"
                            ? "var(--ant-warning-color)"
                            : "var(--ant-primary-color)"
                      }}
                      dot={false}
                    />
                  </Space>
                }
                key="review"
              />
              <Tabs.TabPane tab="Notes" key="notes" />
            </Tabs>
          }
          quoteState={props.quote.quoteState}
        />
        <Switch>
          <Route
            path={`${props.match.path}/answers`}
            render={() => (
              <LayoutPageContent error={props.error} transparent>
                <QuoteAnswers quoteId={props.match.params.id} />
              </LayoutPageContent>
            )}
          />
          <Route
            path={`${props.match.path}/oracleresults`}
            render={() => (
              <LayoutPageContent error={props.error} transparent>
                <QuoteOracleResults quoteId={props.match.params.id} />
              </LayoutPageContent>
            )}
          />
          <Route
            path={`${props.match.path}/notes`}
            render={() => (
              <LayoutPageContent error={props.error} transparent>
                <Notes
                  quoteId={props.match.params.id}
                  isLoading={quoteNotes.isFetching || false}
                  data={quoteNotes.data}
                  isEmpty={!(quoteNotes.data || []).length}
                />
              </LayoutPageContent>
            )}
          />
          <Route
            path={`${props.match.path}/summary`}
            render={() => (
              <LayoutPageContent
                error={props.error}
                transparent
                siderContent={
                  <QuoteAside
                    configuration={props.configuration}
                    isGettingNotes={props.quoteNotes?.isGettingNotes || false}
                    events={props.quoteEvents?.events}
                    isGettingEvents={
                      props.quoteEvents?.isGettingEvents || false
                    }
                  />
                }
              >
                <Summary
                  configuration={props.configuration}
                  quote={props.quote}
                  risk={props.risk}
                  quoteProduct={props.quoteProduct}
                  quoteScheme={props.quoteScheme}
                  quotePaymentPlans={props.quotePaymentPlans}
                  quotePaymentLink={props.quotePaymentLink}
                  quoteProductReferenceId={props.quote.productReferenceID}
                  quoteProductCoverReference={props.quote.productCoverReference}
                  quotePolicyHolder={props.quotePolicyHolder}
                  quotePolicyMeta={props.originalPolicyMeta}
                  policyDetailSet={props.policyDetailSet}
                  quoteLifecycles={props.quoteLifecycles}
                  handleCreatePaymentLink={props.handleCreatePaymentLink}
                  companies={props.quoteCompanies}
                  quoteOpportunity={props.quoteOpportunity}
                  maxQuoteExpiry={props.maxQuoteExpiry}
                />
              </LayoutPageContent>
            )}
          />

          <Route
            path={`${props.match.path}/documents`}
            render={() => (
              <LayoutPageContent error={props.error} transparent>
                <Documents
                  quoteId={props.quote.id}
                  documents={props.quoteDocuments}
                  handleDocumentUpload={props.handleDocumentUpload}
                  emailAddress={
                    props.risk?.core.proposer.emailAddress ||
                    props.risk?.core.proposer.email
                  }
                />
              </LayoutPageContent>
            )}
          />
          <Route
            path={`${props.match.path}/endorsements`}
            render={() => (
              <QuoteEndorsements
                quoteId={props.quote.id}
                quoteState={props.quote.quoteState}
                quoteStatus={props.quote.quoteStatus}
                endorsements={props.quoteEndorsements}
                sections={props.policyDetailSet?.latest.sections || undefined}
                platformVersion={props.configuration.flags.platformVersion}
                isExternal={props.quote.isExternal}
              />
            )}
          />
          <Route
            path={`${props.match.path}/messages`}
            render={() => (
              <LayoutPageContent error={props.error} transparent>
                <TransactionalMessagesSubView entityID={props.quote.id} />
              </LayoutPageContent>
            )}
          />
          {props.configuration.flags.platformVersion === "v3" && (
            <Route
              path={`${props.match.path}/excesses`}
              render={() => (
                <ExcessesView
                  quoteID={props.quote.id}
                  quoteState={props.quote.quoteState}
                  quoteStatus={props.quote.quoteStatus}
                  excesses={props.quoteExcesses}
                  sections={props.policyDetailSet?.latest.sections || undefined}
                  platformVersion={props.configuration.flags.platformVersion}
                  onQuoteChange={props.onQuoteChange}
                />
              )}
            />
          )}
          {props.configuration.flags.platformVersion === "v3" &&
            ["Insurer", "InsurerAdministrator"].includes(
              currentUserGroupType
            ) && (
              <Route
                path={`${props.match.path}/underwriting`}
                render={() => (
                  <>
                    {props.coverPeriods &&
                      props.coverPeriods.length > 1 &&
                      typeof props.handleCoverPeriodChange === "function" && (
                        <CoverPeriodSelect
                          coverPeriods={props.coverPeriods}
                          onChange={props.handleCoverPeriodChange}
                        />
                      )}
                    <LayoutPageContent error={props.error} transparent>
                      <QuoteUnderwritingSubView
                        quoteId={props.quote.id}
                        quoteState={props.quote.quoteState}
                        quoteStatus={props.quote.quoteStatus}
                        quoteReferenceID={props.quote.quoteReferenceID}
                        quotePaymentPlans={
                          props.quotePaymentPlans?.paymentPlans
                        }
                        onQuoteChange={handleQuoteChange}
                        policyDetail={props.policyDetail}
                        quoteCompanies={props.quoteCompanies}
                        underWritingConfiguration={
                          props.configuration.underWriting || {
                            expandMainSections: true,
                            expandSubSections: true
                          }
                        }
                        proposer={props.risk?.core.proposer}
                        handleBindQuote={props.handleBindQuote}
                        disableBind={
                          currentUser?.company?.brokerCanBindBusiness === false
                        }
                      />
                    </LayoutPageContent>
                  </>
                )}
              />
            )}
          <Route
            path={`${props.match.path}/review`}
            render={() => (
              <LayoutPageContent error={props.error} transparent>
                <Review
                  configuration={props.configuration}
                  quote={props.quote}
                  isQuoteLoading={props.isFetchingQuote}
                  referrals={props.quoteReferrals}
                  onUpdateReferral={props.onUpdateReferral}
                  confirmReferralQuote={props.confirmReferralQuote}
                  declineReferralQuote={props.declineReferralQuote}
                  quoteRenewalReview={props.quoteRenewalReview}
                  onReviewQuote={props.quoteLifecycles?.onReviewQuote}
                  currentPrice={reviewPrice}
                  isHybrid={isHybrid}
                  declines={props.declineReasons}
                  onQuoteChange={handleQuoteChange}
                />
              </LayoutPageContent>
            )}
          />
          {props.quote.quoteType === "MTA" && (
            <Route
              path={`${props.match.path}/riskchanges`}
              render={() => (
                <LayoutPageContent error={props.error} transparent>
                  <RiskChanges quoteId={props.quote.id} />
                </LayoutPageContent>
              )}
            />
          )}

          {!props.match.params.subpage && (
            <Redirect to={`${props.match.path}/summary`} />
          )}
        </Switch>
      </Layout>
      <ReferQuoteModal
        visible={referQuoteModal.visible}
        isLoading={referQuote.isLoading}
        onSubmit={(content: string) => referQuote.mutate(content)}
        onCancel={() => {
          referQuoteModal.setVisible(false)
        }}
      />
    </>
  )
}

export type IQuoteRouteProps = RouteComponentProps<
  {
    id: string
    subpage: string
  },
  StaticContext,
  {
    action?: string
    quoteState?: string
  }
>

export interface IQuoteProps extends IQuoteRouteProps {
  configuration: IConfigurationContext
  isWorking: boolean
  error?: TErrorState
  quote: IQuote
  risk?: IRisk
  quoteDocuments?: {
    documents: IDocument[] | [] | undefined
    isGettingDocuments: boolean
    version: number
  }
  quoteEndorsements?: EndorsementType[]
  quoteExcesses?: IExcess[]
  quoteChanges?: IQuoteChange[]
  quoteNotes?: IQuoteNotes
  quoteEvents?: IQuoteEvents
  quoteProduct?: IQuoteProduct
  quoteScheme?: IScheme
  quotePolicyHolder?: IPolicyholder
  quotePaymentPlans?: IQuotePaymentPlans
  quotePaymentLink?: IQuotePaymentLink
  quoteWithdrawReasons?: IQuoteWithdrawReasons
  quoteRenewalReview?: IQuoteRenewalReview
  quoteLifecycles?: IQuoteLifecycles
  quoteCompanies?: ICompanyInRole[]
  quoteActions?: IQuoteAction[]
  quoteOpportunity?: IQuoteOpportunity
  originalPolicyMeta?: IOriginalPolicyMeta
  ownerId?: string
  isFetchingQuote?: boolean
  onUpdateReferral?: OnUpdateReferral
  confirmReferralQuote?: UseMutationResult<
    IQuoteBundle,
    unknown,
    IConfirmReferralQuoteProps
  >
  declineReferralQuote?: UseMutationResult<
    IQuoteBundle,
    unknown,
    IDeclineReferralQuoteProps
  >
  onQuoteChange?: () => void
  quoteReferrals?: IReferral[]
  declineReasons: IDeclineReason[]
  policyDetail?: PolicyDetailType
  policyDetailSet?: IPolicyDetailSet
  coverPeriods?: CoverPeriodType[]
  productReferenceID?: string
  productCoverReference?: string
  handleBindQuote?: (pricingPlan: IPaymentPlan, callback?: () => void) => void
  handleConfirmQuote?: () => void
  handleCoverPeriodChange?: (selected: number) => void
  handleCreatePaymentLink?: () => void
  handleDocumentUpload?: (
    document: FormData,
    callback?: (result: boolean) => void
  ) => Promise<void>
  handleRevokeManualReferral?: () => void
  isManualReferralLoading?: boolean
  maxQuoteExpiry?: string
}

export interface IQuoteDocuments {
  renderDocumentsAction?: React.ReactNode
}

export interface IQuoteProduct {
  product?: IProduct | IProductOpus
  isGettingQuoteProduct: boolean
  productItems_v2?: IProductItemOpus[]
}

export interface IQuoteEvents {
  events?: IEvent[]
  isGettingEvents: boolean
}

export interface IQuoteNotes {
  notes?: INote[]
  isGettingNotes: boolean
}

export interface IQuoteOpportunity {
  id: string
  reference: string
}

export interface IQuoteDocuments {
  documents?: IDocument[]
  isGettingDocuments: boolean
}

export interface IQuotePaymentPlans {
  paymentPlans?: IPaymentPlan[]
  isGettingPaymentPlans: boolean
  isPaymentPlansFinished: boolean
}

export interface IQuotePaymentLink {
  paymentLink?: IQuotePaymentLinkModel
  isGettingPaymentLink: boolean
  error?: boolean
}

export interface IQuoteWithdrawReasons {
  withdrawReasons?: IWithdrawReason[]
  isGettingWithdrawReasons: boolean
}

export interface IQuoteRenewalReview {
  review?: IRenewalReview
  isGettingRenewalReview: boolean
}

export interface IQuoteLifecycles {
  onConfirmQuote?: OnConfirmQuote
  onWithdrawQuote?: OnWithdrawQuote
  onAddNote?: OnAddNote
  onReferQuote?: OnReferQuote
  onInvalidatePaymentLink?: OnInvalidatePaymentLink
  onEditEndorsements?: OnEditEndorsements
  onReviewQuote?: OnReviewQuote
  onAnonymiseQuote?: OnAnonymiseQuote
}

export type OnConfirmQuote = (
  vars: {
    quoteId: string
    comment?: string
  },
  onSuccess?: (quoteId?: string) => void,
  onFailure?: () => void
) => Promise<void>

export type OnWithdrawQuote = (
  vars: {
    quoteId: string
    comment?: string
    withdrawalReasonReferenceID?: string
  },
  onSuccess?: (quoteId?: string) => void,
  onFailure?: () => void,
  options?: {
    shouldRefetchQuote?: boolean
  }
) => Promise<void>

export type OnAddNote = (
  vars: {
    quoteId: string
    content: string
  },
  onSuccess?: (noteId?: string) => void,
  onFailure?: () => void
) => Promise<void>

export type OnReferQuote = (
  vars: { quoteId: string },
  onSuccess?: (quoteId?: string) => void,
  onFailure?: () => void
) => Promise<void>

export type OnAnonymiseQuote = (
  vars: { quoteId: string },
  onSuccess?: (quoteId?: string) => void,
  onFailure?: () => void
) => Promise<void>

export type OnInvalidatePaymentLink = (
  vars: { quoteId: string },
  onSuccess?: () => void,
  onFailure?: () => void
) => Promise<void>

export type OnEditEndorsements = (
  vars: {
    quoteId: string
    addedEndorsements: IEndorsementOpus[]
    comment: string
    deletedEndorsements: IEndorsementOpus[]
  },
  onSuccess?: () => void,
  onFailure?: () => void
) => Promise<void>

export type OnReviewQuote = (
  vars: {
    quoteId: string
    review: boolean
  },
  onSuccess?: () => void,
  onFailure?: () => void
) => Promise<void>

export type OnUpdateReferral = (
  vars: {
    quoteID: string
    referral: IReferral
  },
  onSuccess?: (referral: IReferral) => void,
  onFailure?: () => void
) => Promise<void>

export type OnOverridePremium = (
  vars: { quoteId: string; premium: IMoney },
  onSuccess?: () => void,
  onFailure?: () => void
) => Promise<void>
