import * as React from "react"
import { Collapse } from "../../../Collapse"
import Row, { IRow } from "../../Row"
import CellContentTitle from "../../CellContent/Title"
import { ExpandButton } from "../../CellContent/Button"

const HeaderRow = ({
  notExpandable = false,
  isFirstRow = false,
  isOpen = false,
  ...props
}: IHalfTableHeaderRow) => (
  <Row
    type="halftable"
    {...(notExpandable
      ? {}
      : {
          toggle: <ExpandButton expanded={isOpen} onClick={props.toggle} />
        })}
    reference={<CellContentTitle>{props.reference}</CellContentTitle>}
    isFirstRow={isFirstRow}
    values={props.values}
    highLight={props.highLight}
    updating={props.updating}
  />
)

export const HalfTable: React.FunctionComponent<IHalfTable> = ({
  updating = false,
  ...props
}) => {
  return (
    <Collapse
      {...(props.notExpandable
        ? { startExpanded: true }
        : { startExpanded: props.startExpanded })}
      collapsedPanel={
        <HeaderRow
          updating={updating}
          reference={props.reference}
          values={props.summaryValues}
          {...props.summaryProps}
        />
      }
      expandedPanel={
        <HeaderRow
          isOpen
          updating={updating}
          reference={props.reference}
          values={props.firstRowValues}
          notExpandable={props.notExpandable}
          isFirstRow
          {...props.firstRowProps}
        />
      }
    >
      {props.children}
    </Collapse>
  )
}

export interface IHalfTable {
  reference: string
  firstRowValues: React.ReactNode[]
  firstRowProps?: Omit<IRow, "reference" | "values" | "toggle">
  summaryValues: React.ReactNode[]
  summaryProps?: Omit<IRow, "reference" | "values" | "toggle">
  startExpanded?: boolean
  updating?: boolean
  notExpandable?: boolean
}

export interface IHalfTableHeaderRow
  extends Omit<IRow, "reference" | "values"> {
  reference: string
  toggle?: () => void
  values: React.ReactNode[]
  updating?: boolean
  isFirstRow?: boolean
  notExpandable?: boolean
  isOpen?: boolean
}
