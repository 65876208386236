import { Table } from "components/Console/Table"
import { IQuestionDifference } from "platform-client/types/difference"
import Row from "components/Console/Row"
import CellContentText from "components/Console/CellContent/Text"
import { DiffViewerRow } from "components/diffviewer/diffViewerInnerRow"
import React from "react"

export const DiffViewerTable: React.FunctionComponent<IDiffViewerTable> = (
  props
) => {
  return (
    <>
      <Table.FullTable
        reference={props.title}
        startExpanded={true}
        customValueSpan={3}
      >
        <Row
          type={"header"}
          reference={<CellContentText>{"Question"}</CellContentText>}
          customValueSpan={3}
          values={[
            <CellContentText>Old Value</CellContentText>,
            <CellContentText>New Value</CellContentText>
          ]}
        />
        {props.questionData.map((qd, i) => {
          return (
            <DiffViewerRow
              key={`${qd.questionReferenceID}_${i}`}
              questionData={qd}
              openChildState={true}
              itteration={0}
              parentDataType={null}
            />
          )
        })}
      </Table.FullTable>
    </>
  )
}

export interface IDiffViewerTable {
  questionData: IQuestionDifference[]
  title: string
}
