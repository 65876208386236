import React from "react"
import { Alert, Card, Skeleton, Button } from "antd"
import { useSetPolicyRenewalQuote } from "containers/policies/services/hooks/use-set-policy-renewal-quote"
import { IQuoteState } from "models/quotes/quoteState"
import { IMoney } from "models/quotes/money"
import { TErrorState } from "models/errors"
import { IPolicyRenewalQuote } from "contexts/opus/context"
import RenewalsTable from "./table"
import styled from "styled-components"
import { useOrgSettings } from "contexts/organisation/hooks/use-org-settings"

const CardStyleTitle = styled.div`
  display: flex;
  justify-content: space-between;
`

const PolicyRenewalQuotes: React.FC<IPolicyRenewalQuotesProps> = ({
  policyId,
  loading = false,
  isPolicyCancelled,
  noIntentionToRenew,
  isPolicyRenewalsWithdrawed,
  isError,
  isLoading,
  isFirstLoad,
  renewalQuotes,
  setAssignedQuote,
  duplicateRenewalQuote,
  reloadQuotes
}): JSX.Element => {
  const {
    sendRequest: createPolicyRenewalQuote,
    isSending,
    isError: isRenewalQuotErorr
  } = useSetPolicyRenewalQuote(policyId, reloadQuotes)

  const { orgSettings } = useOrgSettings()

  const renewalRows: IRenewalQuoteRow[] | undefined =
    renewalQuotes &&
    renewalQuotes.map((item) => {
      return {
        policyId,
        quoteId: item.quote ? item.quote.id : "",
        quoteReferenceID: item.quote ? item.quote.quoteReferenceID : "",
        assigned: item.assignedToPolicyAsActive,
        created: item.renewalQuote.dateGenerated,
        createdBy: item.renewalQuote.createdBy,
        quoteState: item.quote ? item.quote.quoteState : "Failed",
        requiresReview: item.renewalQuote.requiresReview,
        reviewApproved: item.renewalQuote.reviewApproved,
        estimatedPremium: item.quote
          ? item.quote.estimateGross
          : {
              value: 0,
              currencyCode: orgSettings ? orgSettings.currencyCode : "GBP"
            }
      }
    })

  const hasAssignedQuote = renewalRows?.some((row) => row.assigned === true)

  const hasBindeddQuote = renewalRows?.some(
    (row) => row.quoteState === "Purchased"
  )

  const isGenerateQuoteDisabled =
    isPolicyCancelled || hasBindeddQuote || noIntentionToRenew

  if (isError || isRenewalQuotErorr) {
    return (
      <Card title={"Renewal Quotes"}>
        <Alert
          message={"Something went wrong. please try again later"}
          type="error"
        />
      </Card>
    )
  }

  if (loading || isFirstLoad) {
    return (
      <Card title={"Renewal Quotes"}>
        <Skeleton loading></Skeleton>
      </Card>
    )
  }

  return (
    <Card
      title={
        <CardStyleTitle>
          Renewal Quotes
          {!isGenerateQuoteDisabled && !isPolicyRenewalsWithdrawed && (
            <Button
              onClick={createPolicyRenewalQuote}
              loading={isSending}
              type="primary"
              size="small"
            >
              Generate renewal quote
            </Button>
          )}
        </CardStyleTitle>
      }
    >
      <Card.Grid
        hoverable={false}
        style={{
          width: "calc(100% - 2px)",
          minWidth: "100%",
          padding: 0,
          margin: "1px 1px 0 1px",
          border: "none",
          boxShadow: "none"
        }}
      >
        <RenewalsTable
          hasAssignedQuote={hasAssignedQuote}
          isLoadingQuotes={isLoading}
          renewalRows={renewalRows || []}
          setActiveRenewalQuote={setAssignedQuote}
          duplicateRenewalQuote={duplicateRenewalQuote}
          isPolicyCancelled={isPolicyCancelled}
        />
      </Card.Grid>
    </Card>
  )
}

export interface IRenewalQuoteRow {
  policyId: string
  quoteId: string
  quoteReferenceID: string
  assigned: boolean
  created: string
  createdBy: string
  quoteState: IQuoteState
  requiresReview: boolean
  reviewApproved: boolean
  estimatedPremium: IMoney
}

interface IPolicyRenewalQuotesProps {
  policyId: string
  loading?: boolean
  isPolicyCancelled: boolean
  noIntentionToRenew: boolean | undefined
  isPolicyRenewalsWithdrawed: boolean
  isError: TErrorState
  isLoading: boolean
  isFirstLoad: boolean
  renewalQuotes: IPolicyRenewalQuote[] | undefined
  setAssignedQuote: (quoteID: string) => void
  reloadQuotes: () => void
  duplicateRenewalQuote: (quoteID: string) => void
}

export default PolicyRenewalQuotes
