import React from "react"
import { Typography } from "antd"
import styled from "styled-components"

const StyledText = styled(Typography.Text)<{
  align: TextCellAlignmentType
  $header: boolean
  $emphasize: boolean
}>`
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: ${(props) => props.align};
  font-size: ${(props) => (props.$header ? "18px" : "14px")};
  font-style: ${(props) => (props.$emphasize ? "italic" : "normal")};
  padding: 0 6px;
`

const StyledAsideText = styled(Typography.Text)`
  font-style: italic;
  font-size: 12px;
  text-align: left;
  padding-left: 15px;
  vertical-align: baseline;
`

const CellContentTitle: React.FC<IText> = ({
  align = "left",
  header = false,
  emphasize = false,
  strikethrough = false,
  children,
  aside
}) => {
  return (
    <StyledText
      $emphasize={emphasize}
      $header={header}
      align={align}
      strong
      delete={strikethrough}
    >
      {children}
      {aside && <StyledAsideText>{aside}</StyledAsideText>}
    </StyledText>
  )
}

export type TextCellAlignmentType = "left" | "center" | "right"

interface IText {
  align?: TextCellAlignmentType
  header?: boolean
  emphasize?: boolean
  strikethrough?: boolean
  aside?: React.ReactNode
}

export default CellContentTitle
