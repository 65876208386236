import { Modal } from "antd"
import { useHistory } from "react-router"

export const useDuplicateQuote = (
  quoteId: string,
  productReference: string | undefined,
  productCoverReference: string | undefined
): IUseDuplicateQuote => {
  const history = useHistory()

  return {
    showDuplicateQuoteModal: () => {
      Modal.confirm({
        title: "Are you sure you want to duplicate this quote?",
        centered: true,
        okText: "Duplicate",
        onOk: () => {
          if (quoteId) {
            history.push(
              `/opus/duplicate/${productReference}/${productCoverReference}/${quoteId}`
            )
          }
        }
      })
    }
  }
}

export interface IUseDuplicateQuote {
  showDuplicateQuoteModal: () => void
}
