import Coverages from "./Coverages"
import React from "react"
import { TPlatformResponse } from "contexts/platform/client"
import Row from "../Row"
import CellContentText from "../CellContent/Text"
import CellContentMoney from "../CellContent/Money"
import { FullTable } from "../Table/FullTable"
import {
  IFinanceNetLoadingDiscount,
  ISubsection,
  SectionTypes
} from "platform-client/types"
import CellContentRateInput from "../CellContent/RateInput"
import { useMutation } from "react-query"
import usePlatform from "contexts/platform/use-platform"
import {
  IToggleSubsection,
  IUpdateSubsection,
  IUpdateSubsectionCoverPeriod
} from "platform-client/types/quote-builder"
import produce from "immer"
import { notification } from "antd"
import { IOrgSettings } from "contexts/organisation/context"
import {
  decimalToPercentage,
  percentageToDecimal
} from "utils/pocketknife/converters"

import Cell, { SpacerCell } from "../Cell"
import { AddButton, DisableButton, WorkingButton } from "../CellContent/Button"
import CoverPeriod from "../CoverPeriod"
import CellContentCoverPeriod from "../CellContent/CoverPeriod"
import CellContentTitle from "../CellContent/Title"
import RiskScores from "./RiskScores"

const SubsectionComponent: React.FC<ISubsectionProps> = ({
  section,
  subsection,
  editSessionID,
  productID,
  onChange,
  startExpanded = true,
  organisationSettings,
  readOnly = false
}) => {
  const coverages = subsection.coverages || []
  const netLoadingDiscounts = subsection.netLoadingDiscounts || []

  const { quoteBuilder } = usePlatform()

  const updateSubsection = useMutation((vars: IUpdateSubsectionVars) =>
    quoteBuilder.updateSubsection(
      vars.editSessionID,
      vars.subsectionID,
      vars.subsection
    )
  )

  const toggleSubsection = useMutation((props: IToggleSubsection) =>
    quoteBuilder.toggleSubsection(
      props.editSessionID,
      props.subsectionID,
      props.enable
    )
  )

  const updateCoverPeriod = useMutation((props: IUpdateSubsectionCoverPeriod) =>
    quoteBuilder.updateSubsectionCoverPeriod(
      props.editSessionID,
      props.subsectionID,
      props.data
    )
  )

  const handleUpdateCoverPeriod = (
    subsectionID: string,
    coverStartDateTime: string,
    coverEndDateTime: string
  ) => {
    if (editSessionID) {
      updateCoverPeriod
        .mutateAsync({
          editSessionID,
          subsectionID,
          data: {
            coverStartDateTime,
            coverEndDateTime
          }
        })
        .then((response) => {
          if (typeof onChange === "function") {
            onChange(response)
          }
        })
        .catch((error) => {
          notification.error({
            message: "Unable to update subsection cover dates, please try again"
          })
        })
    }
  }

  const handleUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (editSessionID) {
      const dataArray = produce(netLoadingDiscounts, (draft) => {
        draft = draft.map((item) => {
          if (item.id === event.target.name) {
            item["ruleAmount"] =
              item.type === "Percentage"
                ? percentageToDecimal(Number(event.target.value))
                : Number(event.target.value)
          }
          return item
        })
      })
      updateSubsection
        .mutateAsync({
          editSessionID: editSessionID,
          subsectionID: subsection.id,
          subsection: {
            name: subsection.name,
            netLoadingDiscounts: dataArray
          }
        })
        .then((response) => onChange(response))
        .catch(() => {
          notification.error({
            message: "Unable to update subsection, please try again."
          })
        })
    }
  }

  const handleToggle = (subsection: ISubsection) => {
    if (editSessionID) {
      toggleSubsection
        .mutateAsync({
          editSessionID,
          subsectionID: subsection.id,
          enable: !subsection.enabled
        })
        .then((response) => {
          if (typeof onChange === "function") {
            onChange(response)
          }
        })
        .catch((error) => {
          notification.error({
            message: "Unable to update subsection, please try again"
          })
        })
    }
  }

  return (
    <>
      <FullTable
        reference={subsection.name}
        key={subsection.id}
        {...(subsection.coverStartDateTime &&
          subsection.coverEndDateTime && {
            coverPeriod: (
              <CellContentCoverPeriod
                align="left"
                coverStartDateTime={subsection.coverStartDateTime}
                coverEndDateTime={subsection.coverEndDateTime}
              />
            )
          })}
        summary={{
          label: "",
          value: subsection.totalPremium ? (
            <CellContentMoney {...subsection.totalPremium} />
          ) : (
            <CellContentText align="right">-</CellContentText>
          )
        }}
        startExpanded={startExpanded}
        controls={
          !readOnly && (
            <>
              {(toggleSubsection.isLoading || updateSubsection.isLoading) && (
                <WorkingButton />
              )}
              {subsection.enabled &&
                subsection.coverStartDateTime &&
                subsection.coverEndDateTime && (
                  <CoverPeriod
                    componentID={subsection.id}
                    coverStartDateTime={subsection.coverStartDateTime}
                    coverEndDateTime={subsection.coverEndDateTime}
                    onChange={onChange}
                    onCoverPeriodChange={handleUpdateCoverPeriod}
                  />
                )}
              {subsection.enabled ? (
                <DisableButton onClick={() => handleToggle(subsection)} />
              ) : (
                <AddButton onClick={() => handleToggle(subsection)} />
              )}
            </>
          )
        }
        isLoading={updateSubsection.isLoading}
        disabled={!subsection.enabled}
      >
        {coverages && coverages.length > 0 && (
          <Coverages
            name="Coverages"
            startExpanded
            sectionID={section.id}
            subsectionID={subsection.id}
            editSessionID={editSessionID}
            productID={productID}
            sectionReference={section.referenceID}
            subsectionReference={subsection.referenceID}
            coverages={coverages}
            onChange={onChange}
            organisationSettings={organisationSettings}
            readOnly={readOnly}
          />
        )}
        <Row
          type="default"
          values={[
            <CellContentTitle align="right">
              Loadings &amp; Discounts
            </CellContentTitle>,
            <CellContentTitle align="right">Amount</CellContentTitle>,
            null
          ]}
        />
        {netLoadingDiscounts && netLoadingDiscounts.length > 0 && (
          <Row
            type="halftable"
            values={[
              <CellContentText align="right">
                Cover Items Subtotal
              </CellContentText>,
              null,
              subsection.totalPremium && subsection.totalNetDiscount ? (
                <CellContentMoney
                  value={
                    subsection.totalPremium.value -
                    subsection.totalNetDiscount.value
                  }
                  currencyCode={
                    subsection.totalPremium.currencyCode ||
                    organisationSettings?.currencyCode
                  }
                />
              ) : (
                <CellContentText align="right">-</CellContentText>
              )
            ]}
          />
        )}
        {netLoadingDiscounts &&
          netLoadingDiscounts.length > 0 &&
          netLoadingDiscounts.map((discount, index) => (
            <Discount
              key={index}
              discount={discount}
              onBlur={handleUpdate}
              organisationSettings={organisationSettings}
              readOnly={readOnly}
            />
          ))}
        {subsection.riskScores && subsection.riskScores.length > 0 && (
          <RiskScores riskScores={subsection.riskScores} />
        )}
        <Row type="halftable">
          <Cell key="premium-label" type="value" span={3}>
            <CellContentText align="right" strong>
              {subsection.name} Premium
            </CellContentText>
          </Cell>
          <SpacerCell />
          <Cell key="premium-value" type="value">
            {subsection.totalPremium ? (
              <CellContentMoney {...subsection.totalPremium} />
            ) : (
              <CellContentText align="right">-</CellContentText>
            )}
          </Cell>
        </Row>
      </FullTable>
    </>
  )
}

export const Discount: React.FC<IDiscountProps> = ({
  discount,
  onChange,
  onBlur,
  organisationSettings,
  readOnly = false
}) => {
  const currencyCode =
    discount.amount?.currencyCode || organisationSettings?.currencyCode
  return (
    <Row
      type="halftable"
      values={[
        <CellContentText align="right">{discount?.name}</CellContentText>,
        <CellContentRateInput
          name={discount.id}
          value={
            discount.type === "Percentage"
              ? decimalToPercentage(discount?.ruleAmount)
              : discount?.ruleAmount
          }
          {...(discount.type && { type: discount.type })}
          currencyCode={currencyCode}
          onChange={(event) => {
            if (typeof onChange === "function") {
              onChange(event)
            }
          }}
          onBlur={(event) => {
            if (typeof onBlur === "function") {
              onBlur(event)
            }
          }}
          readOnly={readOnly}
        />,
        discount.amount ? (
          <CellContentMoney
            addonProps={{ content: "+" }}
            {...discount.amount}
          />
        ) : (
          <CellContentText align="right">-</CellContentText>
        )
      ]}
    />
  )
}

interface IDiscountProps {
  discount: IFinanceNetLoadingDiscount
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void
  organisationSettings?: IOrgSettings
  readOnly?: boolean
}

interface IUpdateSubsectionVars {
  editSessionID: string
  subsectionID: string
  subsection: IUpdateSubsection
}

interface ISubsectionProps {
  section: SectionTypes
  subsection: ISubsection
  editSessionID?: string
  productID?: number
  onChange: (response: TPlatformResponse) => void
  startExpanded?: boolean
  organisationSettings?: IOrgSettings
  readOnly?: boolean
}

export default SubsectionComponent
