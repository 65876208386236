import React, { useState } from "react"
import styled from "styled-components"
import { Input } from "antd"
import { InputProps } from "antd/lib/input/index"
import CellContentRate, { RateType } from "../Rate"
import getCurrencySymbol from "utils/pocketknife/get-currency-symbol"
import { percentageToDecimal } from "utils/pocketknife/converters"

const RateInput = styled(Input)`
  width: 100%;
  font-size: 14px;
  text-align: ${(props: { align?: string }) => props.align || "right"};
  padding: 3px 5px;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #000;
  }

  .ant-input {
    text-align: ${(props: { align?: string }) => props.align || "right"};
  }
`

const CellContentRateInput: React.FunctionComponent<IRate> = ({
  value,
  precision = 4,
  readOnly = false,
  type,
  currencyCode,
  ...props
}) => {
  const [isActive, setIsActive] = useState<boolean>(false)
  const [inputValue, setInputvalue] = useState<number | string>(String(value))
  const VIEW_DEPTH = precision

  React.useEffect(() => {
    if (value !== undefined) {
      setInputvalue(String(value))
    }
  }, [value])

  const Formatter = (value: number, precision?: number, locale?: "en-GB") =>
    new Intl.NumberFormat(locale, {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision
    }).format(value)

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (/^[+-]?([0-9,]*[.])?[0-9]*$/g.test(String(event.target.value))) {
      setInputvalue(event.target.value)
      props.onChange && props.onChange(event)
    }
  }

  if (readOnly) {
    return (
      <CellContentRate
        currencyCode={currencyCode}
        type={type}
        value={
          type === "Percentage"
            ? percentageToDecimal(Number(value))
            : Number(value)
        }
      />
    )
  }

  return (
    <RateInput
      {...props}
      value={
        isActive
          ? inputValue
          : inputValue && Formatter(Number(inputValue), VIEW_DEPTH)
      }
      role="RateInput"
      onChange={handleOnChange}
      onFocus={() => {
        setIsActive(true)
      }}
      onBlur={(e) => {
        setIsActive(false)
        if (typeof props.onBlur === "function") {
          props.onBlur(e)
        }
      }}
      {...(type === "Percentage" && { suffix: "%" })}
      {...(type === "Fixed" &&
        currencyCode && { prefix: getCurrencySymbol(currencyCode) })}
    />
  )
}

interface IRate extends InputProps {
  align?: RateAligment
  precision?: number
  disabled?: boolean
  readOnly?: boolean
  type?: RateType
  currencyCode?: string
}

export type RateAligment = "left" | "right"

export default CellContentRateInput
