import { FormOutlined } from "@ant-design/icons"
import { Button, Popover } from "antd"
import React from "react"
import styled from "styled-components"

const StyledPopupForm = styled(Popover)`
  .anticon {
    color: grey;
  }

  .addIcon,
  .minusIcon,
  .deleteIcon,
  .adjustIcon,
  .calendarIcon,
  .saveIcon {
    transition: all 0.3s linear;
  }

  :hover,
  :focus {
    background-color: transparent;

    .addIcon,
    .minusIcon,
    .deleteIcon,
    .adjustIcon,
    .calendarIcon,
    .saveIcon {
      transform: scale(1.1);
    }
  }
`

const PopupForm: React.FC<IPopupFormProps> = ({
  visible = false,
  ...props
}) => {
  const [_visible, _setVisible] = React.useState<boolean>(visible)

  React.useEffect(() => {
    if (visible !== _visible) {
      _setVisible(visible)
    }
  }, [_visible, visible])

  return (
    <StyledPopupForm
      placement="bottomRight"
      visible={_visible}
      content={props.children}
    >
      <Button
        type="text"
        aria-label="toggle-popup-form"
        icon={props.icon || <FormOutlined />}
        onClick={() => {
          _setVisible(!_visible)

          if (typeof props.setVisible === "function") {
            props.setVisible(!_visible)
          }
        }}
      />
    </StyledPopupForm>
  )
}

export default PopupForm

export interface IPopupFormProps {
  visible?: boolean
  setVisible?: (visible: boolean) => void
  icon?: React.ReactNode
  children: React.ReactNode
}
