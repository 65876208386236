import { Alert } from "antd"
import { PlatformVersions } from "contexts/configuration/context"
import { useUserContext } from "contexts/user/context"

type LayoutAlertsType = {
  platformVersion?: PlatformVersions
}

export const LayoutAlerts: React.FC<LayoutAlertsType> = ({
  platformVersion
}) => {
  const { professionalIndemnityInsuranceExpired } = useUserContext()

  if (
    platformVersion === "v3" &&
    professionalIndemnityInsuranceExpired === true
  ) {
    return (
      <Alert
        type="error"
        banner={true}
        showIcon={false}
        style={{ textAlign: "center" }}
        message="Quote and bind action suspended due to expired professional indemnity insurance - Please contact your system administrator."
      />
    )
  }

  return null
}
