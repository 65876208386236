import styled from "styled-components"
import Row from "components/Console/Row"
import {
  AdjustButton,
  ExpandButton
} from "components/Console/CellContent/Button"
import CellContentText from "components/Console/CellContent/Text"
import { currencyToHumanReadable } from "utils/pocketknife/currency-to-human-readable"
import React from "react"
import { IPolicyClaim } from "platform-client/types/claims"
import { useHistory } from "react-router"
import { DateDisplay } from "components/display/DateDisplay"

export const ClaimRow: React.FunctionComponent<IClaimRow> = (props) => {
  const [expanded, setExpanded] = React.useState<boolean>(false)
  const history = useHistory()

  const Left = styled.div`
    position: relative;
    left: 35px;
  `

  const Right = styled.div`
    left: 50%;
    position: relative;
    bottom: 92px;
  `
  const InfoPanel = styled.div`
    padding: 20px;
    background-color: #fff;
  `

  const BoldLabel = styled.label`
    font-weight: 700;
  `

  return (
    <>
      <Row
        reference={props.claimSummary.claimReference}
        toggle={
          <ExpandButton
            expanded={expanded}
            onClick={() => setExpanded(!expanded)}
          />
        }
        values={[
          <>
            <CellContentText>
              {props.claimSummary.claimRaisedDateLocal != null ? (
                <DateDisplay date={props.claimSummary.claimRaisedDateLocal} />
              ) : (
                "-"
              )}
            </CellContentText>
            <CellContentText>{props.claimSummary.claimStatus}</CellContentText>
          </>
        ]}
        control={
          <AdjustButton
            onClick={() =>
              history.push(`/claims/${props.claimSummary.id}/edit`)
            }
          />
        }
      />
      {expanded && (
        <InfoPanel
          data-testid={`info-panel-${props.claimSummary.claimReference}`}
        >
          <Left>
            <div>
              <BoldLabel>Policy Reference: </BoldLabel>
              <label>{props.policyReferenceId}</label>
            </div>
            <div>
              <BoldLabel>Type: </BoldLabel>
              <label>{props.claimSummary.claimType}</label>
            </div>
            <div>
              <BoldLabel>Sub Status: </BoldLabel>
              <label>{props.claimSummary.claimSubStatus}</label>
            </div>
            <div>
              <BoldLabel>Value: </BoldLabel>
              <label>
                {currencyToHumanReadable(props.claimSummary.claimValue, false)}
              </label>
            </div>
          </Left>
          <Right>
            <div>
              <BoldLabel>Stage: </BoldLabel>
              <label>{props.claimSummary.claimStage}</label>
            </div>
            <div>
              <BoldLabel>Source Type: </BoldLabel>
              <label>{props.claimSummary.sourceClaimType}</label>
            </div>
            <div>
              <BoldLabel>Date Occured: </BoldLabel>
              {props.claimSummary.claimOccurredDateLocal != null ? (
                <DateDisplay date={props.claimSummary.claimOccurredDateLocal} />
              ) : (
                "-"
              )}
            </div>
            <div>
              <BoldLabel>Description: </BoldLabel>
              <label>{props.claimSummary.description}</label>
            </div>
          </Right>
        </InfoPanel>
      )}
    </>
  )
}

export interface IClaimRow {
  policyReferenceId: string
  claimSummary: IPolicyClaim
}
