import React, { useEffect } from "react"
import { LayoutPageContent, LayoutLoading, LayoutPageHeader } from "components"
import { Layout } from "antd"
import { History } from "history"
import { useGetApplicationConfiguration } from "contexts/configuration/hooks/use-get-configuration"
import {
  ENewQuoteState,
  TAventusApp,
  TAventusQuote,
  TAventusQuoteSection,
  TNewQuoteMessage
} from "models/quotes/externalmodels"
export interface INewQuote {
  quoteID?: string | undefined
  url: string | undefined
  token?: string | undefined
  quoteSection: string
  history?: History
}

export const NewQuote: React.FunctionComponent<INewQuote> = (props) => {
  const titles: { [key: string]: string } = {
    purchase: "Purchase quote",
    new: "New quote",
    duplicate: "Duplicate quote",
    saved: "Edit quote",
    edit: "Edit quote",
    renew: "Edit Renewal Quote"
  }

  const config = useGetApplicationConfiguration()

  useEffect(() => {
    const handler = (event: TNewQuoteMessage) => {
      const data = event.data

      if (data.event === TAventusQuote.renewalConfirm && data.quoteId) {
        props.history?.push({
          pathname: `/quote/${data.quoteId}`,
          state: { action: "quote-renewal", quoteState: "PendingPayment" }
        })
      }

      if (data.event === TAventusQuote.bound && data.policyId) {
        props.history?.push({
          pathname: `/policy/${data.policyId}`
        })
      }

      if (
        data.event === TAventusQuote.confirm &&
        data.quoteId &&
        props.quoteSection?.toLowerCase() !== "renew"
      ) {
        props.history?.push({
          pathname: `/quote/${data.quoteId}`
        })
      }

      if (data.event === TAventusQuote.saved && data.quoteId) {
        props.history?.push({
          pathname: `/quote/${data.quoteId}`
        })
      }

      if (data.event === TAventusApp.scroll) {
        const eventIframeHeight = Number(data.height)
        if (eventIframeHeight > 380) {
          const iframeEl = document.getElementById("quoteIframe")

          iframeEl?.setAttribute("height", eventIframeHeight.toString())
        }
      }
      if (data.event === "aventus.quote.questionSetPage") {
        window.scrollTo(0, 0)
      }
    }

    window.addEventListener("message", handler)
    return () => window.removeEventListener("message", handler)
  }, [props.history, props.quoteSection, config])

  if (props.url && props.token) {
    let url = `${props.url}&token=${props.token}`

    if (props.quoteID && props.quoteSection === ENewQuoteState.NewQuote) {
      url += `&userId=${props.quoteID}`
    }

    if (
      props.quoteID &&
      (props.quoteSection === TAventusQuoteSection.purchase ||
        props.quoteSection === TAventusQuoteSection.duplicate)
    ) {
      url += `&quoteId=${props.quoteID}`
    } else if (props.quoteSection === TAventusQuoteSection.edit) {
      const regex = /edit/gi
      url = url.replace(regex, "duplicate")
      url += `&quoteId=${props.quoteID}`
    } else if (props.quoteSection === TAventusQuoteSection.saved) {
      url += `&quoteId=${props.quoteID}`
    }

    const title = titles[props.quoteSection] || ""

    const goBack = () => {
      if (
        props.quoteSection === TAventusQuoteSection.duplicate ||
        props.quoteSection === TAventusQuoteSection.edit
      ) {
        props.history?.push({
          pathname: `/quote/${props.quoteID}`
        })
      } else {
        props.history?.push({
          pathname: `/quotes/`
        })
      }
    }

    return (
      <Layout>
        <LayoutPageHeader ghost={false} title={title} onBack={() => goBack()} />
        <LayoutPageContent>
          <iframe
            src={url}
            /*Stops the Iframe caching in Chrome
            https://stackoverflow.com/questions/25732519/preventing-iframe-caching-in-chrome*/
            name={props.quoteSection + Date.now()}
            title={title}
            id="quoteIframe"
            frameBorder="0"
            height={960}
            width="100%"
          ></iframe>
        </LayoutPageContent>
      </Layout>
    )
  } else {
    return <LayoutLoading />
  }
}
