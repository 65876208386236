import { IUniversalAddress } from "platform-client/types"
import { TAddress } from "./types"

export const formatAddress = (
  address: TAddress | IUniversalAddress | null
): { value?: string | null; key?: string }[] => {
  return address
    ? [
        { value: address?.line1, key: "line1" },
        { value: address?.line2, key: "line2" },
        { value: address?.line3, key: "line3" },
        { value: address?.locality, key: "locality" },
        { value: address?.province, key: "province" },
        { value: address?.postalCode, key: "postal-code" },
        { value: address?.country?.text, key: "country" }
      ].filter((item) => !!item?.value)
    : []
}
