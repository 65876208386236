/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import CellContentText from "components/Console/CellContent/Text"
import { Nullable } from "platform-client/types"
import {
  ETobesDataType,
  ETobesObjectType,
  ETobesQuestionType
} from "platform-client/types/tobes"
import { IRiskValue } from "platform-client/types/difference"
import { transformRiskValue } from "../../../../utils/pocketknife/transform-risk"

// We need this because the oracle has its own set of types
export function transformOracleValue(oracleValue: unknown, type: string) {
  switch (type) {
    case "Money":
      return transformRiskRow(oracleValue, "Object", "Money", null)
    case "Date":
      return transformRiskRow(oracleValue, "DateTime", "NotSet", null)
    default:
      return transformRiskRow(oracleValue, "String", "NotSet", null)
  }
}

export function transformRiskRow(
  risk: unknown,
  dataType: ETobesDataType,
  objectType: ETobesObjectType,
  questionType: Nullable<ETobesQuestionType>
) {
  const tRisk = transformRiskValue(risk, dataType, objectType, questionType)
  return <CellContentText data-testid="riskValue">{tRisk}</CellContentText>
}

export function transformDiffRow(
  oldRisk: unknown,
  newRisk: unknown,
  showNewValue: boolean,
  isDifferent: boolean,
  dataType: ETobesDataType,
  objectType: ETobesObjectType,
  questionType: Nullable<ETobesQuestionType>,
  parentDataType: string | null
) {
  if (newRisk === null && showNewValue) {
    return <CellContentText>{""}</CellContentText>
  }

  if (oldRisk === null && !showNewValue) {
    return <CellContentText>{""}</CellContentText>
  }

  if (parentDataType === "Array") {
    const risk1 = oldRisk as IRiskValue | null
    const risk2 = newRisk as IRiskValue | null
    return displayValue(
      risk1 === null ? "<Added>" : risk1.description,
      risk2 === null ? "<Removed>" : risk2.description,
      showNewValue
    )
  }

  const risk1 = transformRiskValue(oldRisk, dataType, objectType, questionType)
  const risk2 = transformRiskValue(newRisk, dataType, objectType, questionType)
  return displayValue(risk1, risk2, showNewValue)
}

function displayValue(
  oldRisk: string | null,
  newRisk: string | null,
  displayNewValue: boolean
) {
  return (
    <CellContentText data-testid="nodiff">
      {displayNewValue ? newRisk : oldRisk}
    </CellContentText>
  )
}
