import { CheckCircleTwoTone } from "@ant-design/icons"
import { Button, Table, Tag } from "antd"
import Column from "antd/lib/table/Column"
import { useHistory } from "react-router"
import { Link } from "react-router-dom"
import {
  useOpusPermissions,
  EOpusPermission
} from "contexts/authorization/hooks/use-opus-permissions"
import { IVariantQuote, IMoney } from "platform-client/types"
import { UseMutationResult } from "react-query"
import styled from "styled-components"
import { MoneyDisplay } from "components/display/MoneyDisplay"
import { QuoteStateLabels } from "containers/quotes/constants"
import { INominateQuote, QuoteState } from "platform-client/types/quote"

const SelectedQuoteIcon = styled(CheckCircleTwoTone)`
  font-size: 1.5em;
`

const UnselectedQuoteIcon = styled(SelectedQuoteIcon)`
  opacity: 0.25;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`

export const VariantQuoteTable: React.FC<{
  quotes: IVariantQuote[]
  nominateQuote: UseMutationResult<INominateQuote, unknown, string, unknown>
}> = ({ quotes, nominateQuote }) => {
  const history = useHistory()

  const { havePermission: canUseNewQuote } = useOpusPermissions(
    EOpusPermission.Quote,
    "create"
  )

  return (
    <Table dataSource={quotes} pagination={false}>
      <Column
        dataIndex="isNominatedQuote"
        width="4em"
        render={(isNominated: boolean, row: IVariantQuote) =>
          isNominated ? (
            <SelectedQuoteIcon
              twoToneColor="#1890ff"
              title="Quote selected/nominated"
            />
          ) : (
            <UnselectedQuoteIcon
              twoToneColor="gray"
              title="Select/Nominate this Quote"
              onClick={() => nominateQuote.mutate(row.quoteID)}
            />
          )
        }
      />
      <Column
        title="Quotes"
        dataIndex="quoteReferenceID"
        render={(column, row: IVariantQuote) => (
          <Link to={`/quote/${row.quoteID}`}>{row.quoteReferenceID}</Link>
        )}
      />
      <Column title="Insurer" dataIndex="scaffoldName" />
      <Column
        title="Status"
        dataIndex="quoteState"
        align="center"
        render={(quoteState: QuoteState) => (
          <Tag color={QuoteStateLabels[quoteState].color}>
            {QuoteStateLabels[quoteState].label.toUpperCase()}
          </Tag>
        )}
      />
      <Column
        title="Premium"
        dataIndex="quoteGross"
        align="right"
        render={(quoteGross: IMoney) =>
          quoteGross ? <MoneyDisplay value={quoteGross} /> : ""
        }
      />
      <Column
        key="actions"
        align="right"
        render={(quote: IVariantQuote) => (
          <>
            {!quote.isNominatedQuote && quote.quoteState !== "Referred" && (
              <Button
                onClick={() => nominateQuote.mutate(quote.quoteID)}
                type="primary"
                size="small"
              >
                Nominate
              </Button>
            )}

            {quote.quoteState === "Referred" && canUseNewQuote && (
              <Button
                onClick={() => history.push(`/quote/${quote.quoteID}/review`)}
                type="primary"
                size="small"
              >
                Review
              </Button>
            )}
          </>
        )}
      />
    </Table>
  )
}
