import { Rule, RuleObject } from "antd/lib/form"
import { IQuestionFieldValidation } from "platform-client/types"
import { required } from "./required"

const validators: IValidators = {
  required
}

/**
 * Simple validation framework
 *
 * Validators should return a boolean. This will need
 * to be extended to allow for additional validation
 * params. The structure of this in IQuestionFieldValidation
 * is still TBD.
 *
 * @param rules
 * @return IValidationRule[]
 */
const validationRules = (rules: IQuestionFieldValidation[]): Rule[] => {
  return rules.map((rule): Rule => {
    switch (rule.referenceID) {
      case "required":
        return {
          required: true,
          message: rule.message || ""
        }
      default:
        return {
          validator: (_: RuleObject, value: unknown) => {
            return validators[rule.referenceID](value)
              ? Promise.resolve()
              : Promise.reject(new Error(rule.message || ""))
          }
        }
    }
  })
}

interface IValidators {
  [validatorFunctionName: string]: (value: unknown) => boolean
}

export default validationRules
