import React, { useState } from "react"
import { Input, InputProps } from "antd"
import styled from "styled-components"
import CellContentMoney from "../Money"
import getCurrencySymbol from "utils/pocketknife/get-currency-symbol"
import CellContentText from "../Text"

type TStyledInputProps = {
  align?: string
}

const StyledInputNumber = styled(Input).attrs<TStyledInputProps>((props) => ({
  align: props.align || "right"
}))<TStyledInputProps>`
  width: 100%;
  font-size: 14px;
  text-align: ${(props) => props.align};
  padding: 3px 5px;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: #000;
  }

  & .ant-input {
    text-align: ${(props) => props.align};
  }
`

const CellContentMoneyInput: React.FC<IMoneyInput> = ({
  value,
  currencyCode,
  readOnly = false,
  watchValueChange = false,
  ...props
}) => {
  const [isActive, setIsActive] = useState<boolean>(false)
  const [inputValue, setInputvalue] = useState<number | string>(String(value))
  const VIEW_DEPTH = 2

  React.useEffect(() => {
    if (value !== undefined && watchValueChange === true) {
      setInputvalue(String(value))
    }
  }, [value, watchValueChange])

  const Formatter = (
    value: number,
    minPrecision?: number,
    maxPrecision?: number,
    locale?: "en-GB"
  ) =>
    new Intl.NumberFormat(locale, {
      minimumFractionDigits: minPrecision || 2,
      maximumFractionDigits: maxPrecision || 2
    }).format(value)

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (/^[+-]?([0-9,]*[.])?[0-9]*$/g.test(String(event.target.value))) {
      setInputvalue(event.target.value)
      props.onChange && props.onChange(event)
    }
  }

  if (readOnly) {
    return value === null || value === undefined || value === "" ? (
      <CellContentText align="right">-</CellContentText>
    ) : (
      <CellContentMoney currencyCode={currencyCode} value={Number(value)} />
    )
  }

  return (
    <StyledInputNumber
      {...props}
      value={
        isActive
          ? inputValue
          : inputValue && Formatter(Number(inputValue), VIEW_DEPTH)
      }
      role="MoneyInput"
      onChange={handleOnChange}
      prefix={currencyCode && getCurrencySymbol(currencyCode)}
      onFocus={() => {
        setIsActive(true)
      }}
      onBlur={(e) => {
        setIsActive(false)
        if (typeof props.onBlur === "function") {
          props.onBlur(e)
        }
      }}
    />
  )
}

export interface IMoneyInput extends InputProps {
  align?: "left" | "right"
  disabled?: boolean
  readOnly?: boolean
  currencyCode?: string
  watchValueChange?: boolean
}

export default CellContentMoneyInput
