import React from "react"
import { Layout, Table, Row, Col } from "antd"
import { LayoutPageContent, LayoutPageHeader } from "components"
import { useGetQuoteReview } from "../../services/hooks/use-get-quote-review"
import { RouteComponentProps } from "react-router"
import { FileTextTwoTone } from "@ant-design/icons"
import { useHistory } from "react-router-dom"
import { COLUMNS } from "./constants"
import styled from "styled-components"

const ReviewQuoteTable = styled(Table)`
  margin: 30px 5px;
`

const ReviewQuoteHeader = styled.div`
  background-color: #4b74ff;
  box-sizing: border-box;
  padding: 17px 0;
  text-align: center;
  width: 100%;
  h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #ffffff;
    margin: 0;
  }
  p {
    font-size: 14px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.65);
    margin: 0;
  }
`

export const ReviewQuote: React.FunctionComponent<IReviewQuote> = (props) => {
  const { reviewQuestions, isError, isReviewLoading } = useGetQuoteReview(
    props.match.params.id
  )
  const history = useHistory()

  return (
    <Layout>
      <LayoutPageHeader
        ghost={false}
        title="Review"
        onBack={history.goBack}
        style={{ marginBottom: 20 }}
        avatar={{
          icon: <FileTextTwoTone />,
          style: { background: "transparent", marginRight: 5 }
        }}
      />
      <LayoutPageContent error={isError}>
        <Row>
          <Col span={24}>
            <ReviewQuoteHeader>
              <h4>Statement of Fact</h4>
            </ReviewQuoteHeader>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <ReviewQuoteTable
              loading={isReviewLoading}
              columns={COLUMNS()}
              dataSource={reviewQuestions}
              pagination={{ defaultPageSize: 10 }}
            />
          </Col>
        </Row>
      </LayoutPageContent>
    </Layout>
  )
}

export type IReviewQuote = RouteComponentProps<{ id: string }>
