import { useState } from "react"
import { getItem, setItem } from "utils/pocketknife/storage-functions"

export function useSessionStorage<SessionValue>(
  key: string,
  initialValue: SessionValue
): [SessionValue, (value: SessionValue) => void] {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = getItem<SessionValue>(key)
      return item ? item : initialValue
    } catch (error) {
      return initialValue
    }
  })

  const setValue = (value: unknown) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      setItem(key, valueToStore)
    } catch (error) {
      console.error(error)
    }
  }

  return [storedValue, setValue]
}

export const ClearSessionStorage = (): void => {
  window.sessionStorage.clear()
}
