import React from "react"
import CSS from "csstype"
import styled from "styled-components"
import { omit } from "ramda"

type ISpacerCell = Partial<ICell>

export const SpacerCell: React.FC<ISpacerCell> = ({
  type = "value",
  hideTopBorder = true,
  ...props
}) => {
  return <Cell type={type} {...props}></Cell>
}

const Cell: React.FC<ICell> = ({
  type,
  style,
  children,
  highlighted,
  span,
  hideTopBorder = false,
  disabled = false
}) => {
  const cellStyles = {
    ...(highlighted
      ? { ...omit(["backgroundColor", "background"]) }
      : { ...style })
  }

  return (
    <StyledCell
      type={type}
      span={span}
      hideTopBorder={hideTopBorder}
      $disabled={disabled}
      highlighted={highlighted}
      style={cellStyles}
    >
      {children}
    </StyledCell>
  )
}

const singleCellWidths: Record<TCellType, number> = {
  toggle: 4,
  reference: 25,
  value: 12.5,
  control: 8.5
}

const StyledCell = styled.div.attrs<ICell>((props) => {
  const span = props.span ?? 1
  const singleWidth = singleCellWidths[props.type]

  return {
    width: props.width ? props.width : `${singleWidth * span}%`,
    minWidth: `${singleWidth * span * 10}px`
  }
})<{
  type?: TCellType
  width?: string
  minWidth?: string
  highlighted?: boolean
  span?: number
  hideTopBorder?: boolean
  $disabled?: boolean
}>((props) => ({
  ...props.style,
  ...{
    position: "relative",
    display: "flex",
    alignItems: "center",
    width: props.width,
    minHeight: "40px",
    padding: "5px 10px",
    minWidth: props.minWidth,
    borderTop: props.hideTopBorder ? "1px solid transparent" : "1px solid #ddd",
    opacity: `${props.$disabled ? 0.6 : 1}`
  },
  ...(props.highlighted
    ? { background: `#ffffb8`, backgroundColor: `#ffffb8` }
    : {}),
  ...(props.type === "control" ? { justifyContent: "flex-end" } : {})
}))

type TCellType = "toggle" | "reference" | "value" | "control"

export interface ICell {
  type: TCellType
  span?: number
  style?: CSS.Properties
  highlighted?: boolean
  hideTopBorder?: boolean
  disabled?: boolean
  width?: number
}

export default Cell
