import { ExpandButton } from "components/Console/CellContent/Button"
import CellContentText from "components/Console/CellContent/Text"
import Row from "components/Console/Row"
import { IQuestionDifference } from "platform-client/types/difference"
import React from "react"
import { transformDiffRow } from "./helper/riskDisplayer"

export const DiffViewerRow: React.FunctionComponent<IDiffRowViewer> = (
  props
) => {
  const [childIsOpen, setChildIsOpen] = React.useState<boolean>(false)

  function showExpand(questionData: IQuestionDifference) {
    //For some bizzare reason shorthand really didnt work here
    if (
      questionData.childObjectDifferences &&
      questionData.childObjectDifferences !== null &&
      questionData.childObjectDifferences?.length > 0
    ) {
      return true
    }
    if (
      questionData.childFieldDifferences &&
      questionData.childFieldDifferences !== null &&
      questionData.childFieldDifferences?.length > 0
    ) {
      return true
    }
    return false
  }

  return (
    <>
      {props.openChildState && (
        <Row
          referenceWidth={35}
          valueWidth={30}
          customValueSpan={3}
          toggle={
            showExpand(props.questionData) && (
              <ExpandButton
                expanded={childIsOpen}
                onClick={() => setChildIsOpen(!childIsOpen)}
              />
            )
          }
          reference={
            <CellContentText>
              {props.questionData.questionText?.question}
            </CellContentText>
          }
          {...(props.questionData.isDifferent ? { type: "changesmade" } : {})}
          values={[
            transformDiffRow(
              props.questionData.riskValue1,
              props.questionData.riskValue2,
              false,
              false,
              props.questionData.dataType,
              props.questionData.objectType,
              props.questionData.questionType,
              props.parentDataType
            ),
            transformDiffRow(
              props.questionData.riskValue1,
              props.questionData.riskValue2,
              true,
              props.questionData.isDifferent,
              props.questionData.dataType,
              props.questionData.objectType,
              props.questionData.questionType,
              props.parentDataType
            )
          ]}
        />
      )}

      {props.questionData.childFieldDifferences !== null &&
        props.questionData.childFieldDifferences?.map((qd, i) => {
          return (
            <DiffViewerRow
              key={`${qd.questionReferenceID}_${i}`}
              questionData={qd}
              openChildState={childIsOpen && props.openChildState}
              itteration={props.itteration + 1}
              parentDataType={props.questionData.dataType}
            ></DiffViewerRow>
          )
        })}
      {props.questionData.childObjectDifferences !== null &&
        props.questionData.childObjectDifferences?.map((qd, i) => {
          return (
            <DiffViewerRow
              key={`${qd.questionReferenceID}_${i}`}
              questionData={qd}
              openChildState={childIsOpen && props.openChildState}
              itteration={props.itteration + 1}
              parentDataType={props.questionData.dataType}
            ></DiffViewerRow>
          )
        })}
    </>
  )
}

export interface IDiffRowViewer {
  questionData: IQuestionDifference
  openChildState: boolean
  itteration: number
  parentDataType: string | null
}
