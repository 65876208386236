import { useState } from "react"
import { useMutation, useQuery } from "react-query"
import CellContentSelect, {
  SelectedOptionType
} from "components/Console/CellContent/Select"
import Row from "components/Console/Row"
import usePlatform from "contexts/platform/use-platform"
import {
  IAddCoverage,
  ICoverage,
  ICoverageDefinition
} from "platform-client/types"
import produce from "immer"
import CellContentTextInput from "components/Console/CellContent/TextInput"
import {
  DeleteButton,
  SaveButton,
  WorkingButton
} from "components/Console/CellContent/Button"
import CellContentMoneyInput from "components/Console/CellContent/MoneyInput"
import { IOrgSettings } from "contexts/organisation/context"
import { TPlatformResponse } from "contexts/platform/client"
import { notification } from "antd"
import CellContentText from "components/Console/CellContent/Text"
import Cell from "components/Console/Cell"
import { Option } from "antd/lib/mentions"
import styled from "styled-components"

const StyledCoverageType = styled.i`
  color: #888;
`

const AddCoverage: React.FC<IAddCoverageProps> = (props) => {
  const platform = usePlatform()
  const [coverage, setCoverage] = useState<AddCoverageType>()
  const [name, setName] = useState<string>("")

  const listCoverageDefinitions = useQuery(
    ["listCoverageDefinitions", props.productID],
    () => {
      if (props.productID) {
        return platform.coverage.listCoverageDefinitions(
          props.productID,
          props.sectionReference
        )
      }
    },
    {
      enabled: !!props.productID
    }
  )

  const addCoverage = useMutation((addCoverageProps: IAddCoverage) =>
    platform.quoteBuilder.addCoverage(
      addCoverageProps.editSessionID,
      addCoverageProps.data
    )
  )

  const handleSelectCoverage = ({ target }: SelectedOptionType) => {
    const definition: ICoverageDefinition = JSON.parse(target.value as string)
    setCoverage({
      subsectionID: props.subsectionID,
      sumInsured: {
        value: 0,
        currencyCode: props.organisationSettings?.currencyCode || "GBP"
      },
      declaredAmount: {
        value: 0,
        currencyCode: props.organisationSettings?.currencyCode || "GBP"
      },
      type: definition.type,
      name: name || definition.name,
      referenceID: definition.referenceID
    })
  }

  const handleAddCoverage = () => {
    if (props.editSessionID && coverage) {
      const formData = produce(
        {
          editSessionID: props.editSessionID,
          data: { ...coverage }
        },
        (draft) => {
          switch (draft.data.type) {
            case "Rated":
              draft.data.declaredAmount = draft.data.sumInsured
              break
            case "DeclaredValueWithUplift":
              draft.data.sumInsured = null
              break
            default:
              break
          }

          return draft
        }
      )

      addCoverage
        .mutateAsync(formData)
        .then((response) => {
          props.onChange(response)
          setCoverage(undefined)
          props.onRemove()
        })
        .catch((error) => {
          notification.error({
            message: "Unable to add coverage, please try again"
          })
        })
    }
  }

  if (coverage === undefined) {
    return (
      <Row
        updating={addCoverage.isLoading}
        reference={
          <CellContentTextInput
            name="name"
            placeholder="Coverage name"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        }
        control={
          <>
            {addCoverage.isLoading && <WorkingButton />}
            <DeleteButton
              onClick={() => {
                setCoverage(undefined)
                props.onRemove()
              }}
            />
          </>
        }
      >
        <Cell type="value" span={2}>
          <CellContentSelect
            name="coverage_definitions"
            placeholder="Select coverage definition"
            autoFocus
            showSearch
            loading={listCoverageDefinitions.isLoading}
            onChange={handleSelectCoverage}
          >
            {listCoverageDefinitions.data?.map((item: ICoverageDefinition) => (
              <Option value={JSON.stringify(item)}>
                {item.name}{" "}
                <StyledCoverageType>({item.type})</StyledCoverageType>
              </Option>
            ))}
          </CellContentSelect>
        </Cell>
        <Cell type="value" span={3}></Cell>
      </Row>
    )
  }

  return (
    <Row
      updating={addCoverage.isLoading}
      reference={
        <CellContentTextInput
          name="name"
          value={coverage.name || ""}
          onChange={(event) =>
            setCoverage(
              produce((draft) => {
                if (draft) {
                  draft.name = event?.target.value
                }
              })
            )
          }
        />
      }
      values={[
        coverage.type === "DeclaredValueWithUplift" ? (
          <CellContentMoneyInput
            name="declaredAmount.value"
            value={coverage.declaredAmount ? coverage.declaredAmount.value : ""}
            watchValueChange={true}
            onChange={(event) =>
              setCoverage(
                produce((draft) => {
                  if (draft) {
                    draft.declaredAmount = {
                      value: Number(event.target.value),
                      currencyCode:
                        props.organisationSettings?.currencyCode || "GBP"
                    }
                  }
                })
              )
            }
            currencyCode={props.organisationSettings?.currencyCode}
          />
        ) : undefined,
        ["Rated", "Fixed"].includes(coverage.type) ? (
          <CellContentMoneyInput
            name="sumInsured.value"
            watchValueChange={true}
            value={coverage.sumInsured ? coverage.sumInsured.value : ""}
            onChange={(event) =>
              setCoverage(
                produce((draft) => {
                  if (draft) {
                    draft.sumInsured = {
                      value: Number(event.target.value),
                      currencyCode:
                        props.organisationSettings?.currencyCode || "GBP"
                    }
                  }
                })
              )
            }
            currencyCode={props.organisationSettings?.currencyCode}
          />
        ) : (
          <CellContentText align="right">-</CellContentText>
        ),
        <CellContentText align="right">-</CellContentText>,
        <CellContentText align="right">-</CellContentText>,
        <CellContentText align="right">-</CellContentText>
      ]}
      control={
        <>
          {addCoverage.isLoading && <WorkingButton />}
          <SaveButton onClick={handleAddCoverage} />
          <DeleteButton
            onClick={() => {
              setCoverage(undefined)
              props.onRemove()
            }}
          />
        </>
      }
    />
  )
}

export default AddCoverage

type AddCoverageType = Pick<
  ICoverage,
  "declaredAmount" | "name" | "referenceID" | "sumInsured" | "type"
> & { subsectionID: string }

export interface IAddCoverageProps {
  productID?: number
  subsectionID: string
  editSessionID?: string
  sectionReference: string
  organisationSettings?: IOrgSettings
  onRemove: () => void
  onChange: (response: TPlatformResponse) => void
}
