import React from "react"
import { PolicyStatus } from "platform-client/types"
import { IConfigurationContext } from "contexts/configuration/context"
import { LayoutPageHeader } from "components"
import { Breadcrumb, Skeleton, Tag, Tooltip } from "antd"
import { FileProtectOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom"
import styled from "styled-components"

const PolicyHeaderBackground = styled.div`
  background: ${(props: { isWorking?: boolean }) =>
    !props.isWorking ? "transparent" : "#fff"};
  padding: ${(props: { isWorking?: boolean }) =>
    !props.isWorking ? 0 : "16px 24px"};
`

export const PolicyHeader: React.FC<IPolicyHeader> = (props) => {
  const status = props.configuration.policy.state[props.policyStatus]
  return (
    <PolicyHeaderBackground isWorking={props.isWorking}>
      <Skeleton active loading={props.isWorking}>
        <LayoutPageHeader
          ghost={false}
          breadcrumb={
            <Breadcrumb>
              <Breadcrumb.Item key="/opportunities">
                <Link to="/policies">
                  <FileProtectOutlined />
                  &nbsp;Policies
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>View policy</Breadcrumb.Item>
            </Breadcrumb>
          }
          title={props.title}
          tags={
            <>
              <Tag color={status?.color}>{status?.tag?.toUpperCase()}</Tag>
              {props.imported && (
                <Tag color={"geekblue"}>
                  Imported Policy&nbsp;
                  <Tooltip
                    placement="bottom"
                    title="This policy was imported and some functionality may not be available; when this policy renews it will regain all functionality."
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Tag>
              )}
              {props.manualUnderwriting && (
                <Tag color={"gold"}>
                  Manual Underwriting&nbsp;
                  <Tooltip
                    placement="bottom"
                    title="Policy is flagged for manual underwriting, this means no underwriting rules or rates lookup is performed and any adjustments will be referred, requiring underwriting review."
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Tag>
              )}
            </>
          }
          extra={props.actions}
          footer={props.tabs}
        >
          {props.children}
        </LayoutPageHeader>
      </Skeleton>
    </PolicyHeaderBackground>
  )
}

export interface IPolicyHeader {
  configuration: IConfigurationContext
  policyStatus: PolicyStatus
  title?: string
  isWorking?: boolean
  actions?: React.ReactNode
  tabs?: React.ReactNode
  manualUnderwriting?: boolean
  imported?: boolean
}
