import { Form, Input, Modal, Select, notification } from "antd"
import useOpus from "contexts/opus/hooks/use-opus"
import produce from "immer"
import { useState } from "react"
import styled from "styled-components"
import { useOrgSettings } from "contexts/organisation/hooks/use-org-settings"
import { handleValidationErrors } from "utils/pocketknife/handle-validation-errors"
import { AxiosError } from "axios"
import MoneyInput from "components/MoneyInput"
import TextArea from "antd/lib/input/TextArea"

const StyledText = styled.span<{ secondary?: boolean; emphasise?: boolean }>`
  color: ${(props) => (props.secondary ? "#bfbfbf" : "inherit")};
  font-style: ${(props) => (props.emphasise ? "italic" : "inherit")};
`

export const useManualRefund = (
  ownerID: string | undefined,
  policyID: string | undefined,
  policyReferenceID: string | undefined,
  refetchTimelineEvents: VoidFunction,
  setRefetchTransactions: React.Dispatch<React.SetStateAction<boolean>>
): IUseManualRefund => {
  const { policyClient } = useOpus()
  const { orgSettings } = useOrgSettings()
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [isWorking, setIsWorking] = useState<boolean>(false)

  const [refund, setRefund] = useState<IManualRefundStore>({
    amount: 0,
    reference: null,
    reason: null,
    category: null
  })

  const [errors, setErrors] = useState<{
    amount: null | string
    reference: null | string
    reason: null | string
    category: null | string
  }>({
    amount: null,
    reference: null,
    reason: null,
    category: null
  })

  const clearErrors = () => {
    setErrors({
      amount: null,
      reference: null,
      reason: null,
      category: null
    })
  }

  const clearState = () => {
    setRefund({
      amount: 0,
      reference: null,
      reason: null,
      category: null
    })
    clearErrors()
  }

  const submitRefund = async () => {
    setIsWorking(true)
    let hasError = false

    if (refund.amount === undefined || refund.amount <= 0) {
      setErrors(
        produce((draft) => {
          draft.amount = "Refund amount must be greater than $0"
        })
      )
      hasError = true
    }

    if (
      refund.reference === null ||
      refund.reference === undefined ||
      refund.reference === ""
    ) {
      setErrors(
        produce((draft) => {
          draft.reference = "Unique reference is required"
        })
      )
      hasError = true
    }

    if (refund.category === null || refund.category === undefined) {
      setErrors(
        produce((draft) => {
          draft.category = "Category is required"
        })
      )
      hasError = true
    }

    if (!hasError && ownerID) {
      const result = await policyClient
        .manualRefund(ownerID, {
          policyID: policyID || "",
          amount: {
            value: Number(refund.amount),
            currencyCode: orgSettings?.currencyCode || "AUD"
          },
          reference: refund.reference,
          reason: refund.reason,
          action: refund.category
        })
        .catch((error: AxiosError) => {
          handleValidationErrors(error)
        })

      if (result && result.paymentStatus === "Settled") {
        notification.success({
          message: null,
          description: `Refund issued successfully: ${result.paymentReference}`
        })
        setIsModalVisible(false)
        refetchTimelineEvents()
        setRefetchTransactions(true)
      } else {
        notification.error({
          message: null,
          description: `Error issuing the refund`
        })
      }
    }

    setIsWorking(false)
  }

  const showManualRefundModal = async (isVisible: boolean) => {
    clearState()
    setIsModalVisible(isVisible)
  }

  const modal = (
    <Modal
      title="Manual Refund"
      centered
      destroyOnClose
      visible={isModalVisible}
      onCancel={(e) => {
        setIsModalVisible(false)
      }}
      okText="Refund"
      onOk={submitRefund}
      confirmLoading={isWorking}
    >
      <StyledText>
        Issue a manual refund against this policy:{" "}
        {policyReferenceID && `${policyReferenceID}`}
      </StyledText>
      <Form
        layout="vertical"
        id="manualRefundForm"
        style={{ margin: "20px 0 0" }}
      >
        <Form.Item
          name="amount"
          label="Amount:"
          help={errors.amount}
          validateStatus={errors.amount ? "error" : "success"}
        >
          <MoneyInput
            currencyCode={orgSettings?.currencyCode}
            value={refund.amount ? Number(refund.amount) : undefined}
            valueChanged={(newValue) => {
              setRefund(
                produce((draft) => {
                  draft.amount = newValue ? Number(newValue) : 0
                })
              )
              clearErrors()
            }}
          />
        </Form.Item>
        <Form.Item
          name="Unique Reference"
          label="Unique Reference:"
          extra="This is your reference (e.g. Credit Note reference, or similar). This will not show up on the customer's statement."
          help={errors.reference}
          validateStatus={errors.reference ? "error" : "success"}
          rules={[{ required: true }]}
        >
          <Input
            name="reference"
            type="string"
            value={refund.reference ? refund.reference : undefined}
            onChange={(event) => {
              setRefund(
                produce((draft) => {
                  draft.reference = event.target.value
                    ? event.target.value
                    : null
                })
              )
              clearErrors()
            }}
          />
        </Form.Item>
        <Form.Item
          name="Category"
          label="Category:"
          extra="Please select a category for this refund"
          help={errors.category}
          validateStatus={errors.category ? "error" : "success"}
          rules={[{ required: true }]}
        >
          <Select
            placeholder="Please select..."
            options={[
              {
                value: "RoadsideRefund",
                label: "Roadside"
              },
              {
                value: "AdminFeeRefund",
                label: "Admin Fee"
              },
              {
                value: "ManualRefund",
                label: "Other"
              }
            ]}
            onChange={(selectedValue: string) => {
              setRefund(
                produce((draft) => {
                  draft.category = selectedValue
                })
              )
              clearErrors()
            }}
          />
        </Form.Item>
        <Form.Item
          name="Reason"
          label="Reason:"
          extra="Please provide a reason for issuing this manual refund"
          help={errors.reason}
          validateStatus={errors.reference ? "error" : "success"}
          rules={[{ required: true }]}
        >
          <TextArea
            name="reason"
            rows={3}
            value={refund.reason ? refund.reason : undefined}
            onChange={(event) => {
              setRefund(
                produce((draft) => {
                  draft.reason = event.target.value ? event.target.value : null
                })
              )
              clearErrors()
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  )

  return {
    manualRefundModal: modal,
    showManualRefundModal
  }
}

interface IManualRefundStore {
  amount: number | 0
  reference: string | null
  reason: string | null
  category: string | null
}

export interface IUseManualRefund {
  manualRefundModal: React.ReactNode
  showManualRefundModal: (arg: boolean) => void
}
