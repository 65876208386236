import { BookOutlined } from "@ant-design/icons"
import { VDistributor, VDistributorsCreate, VDistributorsList } from "."

export const DISTRIBUTORS_ROUTES = [
  {
    path: "/distributors",
    component: VDistributorsList
  },
  {
    path: "/distributors/:subpage/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})?",
    component: VDistributorsCreate
  },
  {
    path: "/distributor/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/:subpage?",
    component: VDistributor
  }
]

export const DISTRIBUTORS_NAVIGATION = [
  {
    title: "Distributors",
    path: "/distributors",
    childPaths: ["/distributor"],
    submenu: false,
    version: "v3",
    icon: <BookOutlined />,
    iconActive: <BookOutlined />
  }
]
