import React from "react"

export const AventusLogo: React.FunctionComponent<
  React.SVGProps<SVGSVGElement>
> = ({ className, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 205.57 63"
    {...props}
    className={className}
  >
    <defs>
      <linearGradient
        id="b"
        x1="-45.75"
        y1="182.61"
        x2="-44.75"
        y2="182.61"
        gradientTransform="matrix(181.42 0 0 -181.42 8280.64 33161.14)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#84cde3" />
        <stop offset=".21" stop-color="#83cae1" />
        <stop offset=".34" stop-color="#7fc2da" />
        <stop offset=".45" stop-color="#79b3ce" />
        <stop offset=".55" stop-color="#709fbd" />
        <stop offset=".63" stop-color="#6584a7" />
        <stop offset=".72" stop-color="#57638c" />
        <stop offset=".79" stop-color="#463d6c" />
        <stop offset=".84" stop-color="#3a2054" />
        <stop offset="1" stop-color="#3a2054" />
      </linearGradient>
      <clipPath id="a">
        <path
          d="M29.15 32.39c1.52.31 3.14.69 4.87 1.13a32.77 32.77 0 0 1 16.38 9.57L34.55 15.72Zm31.5-21.7-5.1 12.56-7.47-12.56h-7.89l15.36 28.36 13-28.36Zm11.75 0v27.2h22v-6.41H79.82v-4.32h13.05v-5.94H79.82V17.1h14.41v-6.41Zm44.17 0v14.37l-11.21-14.37h-7v27.2h7.42V23l11.64 14.92H124V10.69Zm10.72 0v6.6h8.16v20.6H143v-20.6h8.16v-6.6Zm44.44 0V26a5.79 5.79 0 0 1-1.35 4.23 4.83 4.83 0 0 1-3.62 1.36 4.74 4.74 0 0 1-3.62-1.42 6.13 6.13 0 0 1-1.35-4.37V10.69h-7.69V26a16 16 0 0 0 .9 5.5 10 10 0 0 0 2.51 3.88 10.14 10.14 0 0 0 4 2.27 17.36 17.36 0 0 0 5.24.74 17.61 17.61 0 0 0 5.27-.74 10.37 10.37 0 0 0 4-2.27 10.25 10.25 0 0 0 2.56-3.92 16 16 0 0 0 .92-5.7V10.69Zm17.53.18a9.69 9.69 0 0 0-3.33 1.8 7.55 7.55 0 0 0-2.09 2.75 8.38 8.38 0 0 0-.72 3.49V19a7.09 7.09 0 0 0 .79 3.51 7 7 0 0 0 2.18 2.36 12.5 12.5 0 0 0 3.24 1.56c1.24.4 2.59.77 4 1.1q1.4.32 2.28.6a8.31 8.31 0 0 1 1.39.6 2 2 0 0 1 .72.64 1.46 1.46 0 0 1 .2.76v.08a1.52 1.52 0 0 1-.8 1.37 4.41 4.41 0 0 1-2.31.49 13.83 13.83 0 0 1-8.81-3.28l-4.2 5a17 17 0 0 0 5.81 3.4 21.26 21.26 0 0 0 6.94 1.13 15.76 15.76 0 0 0 4.5-.6 10 10 0 0 0 3.49-1.72 7.88 7.88 0 0 0 2.23-2.77 8.34 8.34 0 0 0 .8-3.72v-.08a7.15 7.15 0 0 0-.7-3.28 6.72 6.72 0 0 0-2-2.34 12.48 12.48 0 0 0-3.2-1.65 32.57 32.57 0 0 0-4.24-1.16q-1.4-.32-2.31-.6a9 9 0 0 1-1.44-.58 2 2 0 0 1-.73-.64 1.5 1.5 0 0 1-.22-.82v-.08a1.51 1.51 0 0 1 .68-1.24 3.44 3.44 0 0 1 2-.5 11.38 11.38 0 0 1 3.79.68 15.23 15.23 0 0 1 3.82 2l3.77-5.34a15.73 15.73 0 0 0-5-2.72 19.62 19.62 0 0 0-6.23-.92 13.83 13.83 0 0 0-4.39.65M25.45 0 0 43.09C1.69 52.42 13.56 63 13.56 63c2.57-19.91 19.7-49.51 19.7-49.51Z"
          fill="none"
        />
      </clipPath>
    </defs>
    <g clip-path="url(#a)">
      <path d="M0 0h205.57v63H0z" fill="url(#b)" />
    </g>
  </svg>
)
