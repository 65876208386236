import { Dispatch, SetStateAction, useState } from "react"
import { Button, Table, Row, Input } from "antd"
import {
  ICommissionSetup,
  ISchemeAllocation
} from "platform-client/types/commission"
import { QueryObserverResult } from "react-query"
import styled from "styled-components"

const StyledCell = styled.div`
  width: 100%;
  text-align: right;
`

interface ICommissionTableProps {
  schemeAllocations: ISchemeAllocation[]
  setOverride: (
    amount: number,
    schemeReferenceID: string
  ) => Promise<QueryObserverResult<ICommissionSetup[], unknown>>

  isLoading: boolean
  companyName?: string
  organisation?: string
}

const toPercentage = (number: number, sign = "%"): string | number => {
  const newNumber = (Number(number) || 0) * 100
  return sign ? `${newNumber.toFixed(2)}${sign}` : newNumber
}

const getCommissions = (schemes: ISchemeAllocation[]) => {
  return schemes.reduce((acc, scheme) => {
    return {
      ...acc,
      [scheme.schemeReferenceID]:
        Number(scheme.overrideDistributorCommission) * 100
    }
  }, {})
}

const COMMISSION_COLUMNS = (tableProps: {
  values: { [key: string]: string | number }
  setValues: Dispatch<SetStateAction<{ [key: string]: string | number }>>
  editModeReferenceID: { [key: string]: boolean }
  editCommission: Dispatch<SetStateAction<{ [key: number]: boolean }>>
  setOverride: (
    amount: number,
    schemeReferenceID: string
  ) => Promise<QueryObserverResult<ICommissionSetup[], unknown>>
  companyName: string
  organisation: string
}) => {
  return [
    {
      title: "Scheme Name",
      dataIndex: "schemeName",
      key: "schemeName"
    },
    {
      title: "Insurer/Binder",
      dataIndex: "binderName",
      key: "binderName"
    },
    {
      title: "Commission from Insurer",
      dataIndex: "commissionFromInsurer",
      key: "commissionFromInsurer",
      render: (number: number) => (
        <StyledCell>{toPercentage(number)}</StyledCell>
      )
    },
    {
      title: `${tableProps.companyName} Commission (Default)`,
      dataIndex: "defaultDistributorCommission",
      key: "defaultDistributorCommission",
      render: (number: number) => (
        <StyledCell>{toPercentage(number)}</StyledCell>
      )
    },
    {
      title: `${tableProps.companyName} Commission Override`,
      key: "overrideDistributorCommission",
      render: (data: ISchemeAllocation) => {
        const isEditable =
          tableProps.editModeReferenceID[data.schemeReferenceID]
        return isEditable ? (
          <Row align="middle">
            <Input
              autoFocus
              type="number"
              style={{ maxWidth: 100, marginRight: "12px" }}
              onChange={({ target }) =>
                tableProps.setValues(
                  (state: { [key: string]: string | number }) => ({
                    ...state,
                    [data.schemeReferenceID]: target.value
                  })
                )
              }
              value={tableProps.values[data.schemeReferenceID]}
            />
            %
          </Row>
        ) : (
          <StyledCell>
            {toPercentage(data.overrideDistributorCommission)}
          </StyledCell>
        )
      }
    },
    {
      title: `${tableProps.organisation} Commission Retained`,
      dataIndex: "commissionRetained",
      key: "commissionRetained",
      render: (number: number) => (
        <StyledCell>{toPercentage(number)}</StyledCell>
      )
    },
    {
      title: "Actions",
      key: "actions",
      render: (data: ISchemeAllocation) => {
        const isEditable =
          tableProps.editModeReferenceID[data.schemeReferenceID]
        return isEditable ? (
          <Row>
            <Button
              type="primary"
              style={{ marginRight: 12 }}
              onClick={() => {
                tableProps
                  .setOverride(
                    Number(tableProps.values[data.schemeReferenceID]) / 100,
                    data.schemeReferenceID
                  )
                  .finally(() =>
                    tableProps.editCommission(
                      (state: { [key: number]: boolean }) => ({
                        ...state,
                        [data.schemeReferenceID]: false
                      })
                    )
                  )
              }}
            >
              Save
            </Button>
            <Button
              onClick={() => {
                tableProps.editCommission(
                  (state: { [key: number]: boolean }) => ({
                    ...state,
                    [data.schemeReferenceID]: false
                  })
                )
                tableProps.setValues(
                  (state: { [key: number]: string | number }) => ({
                    ...state,
                    [data.schemeReferenceID]:
                      Number(data.overrideDistributorCommission) * 100
                  })
                )
              }}
            >
              Cancel
            </Button>
          </Row>
        ) : (
          <Button
            onClick={() =>
              tableProps.editCommission(
                (state: { [key: number]: boolean }) => ({
                  ...state,
                  [data.schemeReferenceID]: true
                })
              )
            }
          >
            Edit
          </Button>
        )
      }
    }
  ]
}

export const CommissionTable: React.FC<ICommissionTableProps> = (props) => {
  const [editModeReferenceID, setEditModeReferenceID] = useState<{
    [key: string]: boolean
  }>({})
  const [values, setValues] = useState<{ [key: string]: string | number }>(
    getCommissions(props.schemeAllocations)
  )

  console.log(values)

  return (
    <div>
      <Table
        bordered
        columns={COMMISSION_COLUMNS({
          values,
          setValues,
          editModeReferenceID,
          editCommission: setEditModeReferenceID,
          setOverride: props.setOverride,
          companyName: props.companyName || "Distributor",
          organisation: props.organisation || "MGA/Broker"
        })}
        loading={props.isLoading}
        scroll={{ x: "max-content" }}
        dataSource={props.schemeAllocations}
        pagination={{ hideOnSinglePage: true }}
      />
    </div>
  )
}
