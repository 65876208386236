import React from "react"
import Row from "components/Console/Row"
import CellContentMoney from "components/Console/CellContent/Money"
import CellContentMoneyInput from "components/Console/CellContent/MoneyInput"
import CellContentRateInput from "components/Console/CellContent/RateInput"
import useCoverage from "../use-coverage"
import { WorkingButton } from "components/Console/CellContent/Button"
import CellContentText from "components/Console/CellContent/Text"
import { TPlatformResponse } from "contexts/platform/client"
import { IOrgSettings } from "contexts/organisation/context"
import { decimalToPercentage } from "utils/pocketknife/converters"
import { ICoverage } from "platform-client/types"
import CoverageCoverPeriod from "../../../CoverPeriod"
import CellContentTitle from "components/Console/CellContent/Title"
import { Collapse } from "components/Collapse"
import CellContentCoverPeriod from "components/Console/CellContent/CoverPeriod"
import Coverage from "../Coverage"

const RatedSimpleCoverage: React.FC<IRatedSimpleCoverage> = ({
  coverage,
  onChange,
  subsectionID,
  editSessionID,
  readOnly = false,
  organisationSettings
}): JSX.Element => {
  const {
    _coverage,
    updating,
    syncCoverage,
    updateCoverage,
    removeCoverage,
    toggleCoverage,
    updateCoverPeriod,
    lockCoverage
  } = useCoverage(coverage, onChange, subsectionID, editSessionID)

  const currencyCode =
    (_coverage.sumInsured && _coverage.sumInsured.currencyCode) ||
    organisationSettings?.currencyCode
  const isSplitCoverage = _coverage.prorataFraction < 1
  const coverageReference = isSplitCoverage ? (
    <>
      {_coverage.name}
      {_coverage.coverStartDateTime && _coverage.coverEndDateTime && (
        <CellContentCoverPeriod
          coverStartDateTime={_coverage.coverStartDateTime}
          coverEndDateTime={_coverage.coverEndDateTime}
        />
      )}
    </>
  ) : (
    _coverage.name
  )

  const cells = [
    undefined,
    <CellContentMoneyInput
      name="sumInsured.value"
      value={_coverage.sumInsured ? _coverage.sumInsured.value : ""}
      watchValueChange={true}
      onChange={updateCoverage}
      onBlur={() => {
        syncCoverage()
      }}
      readOnly={readOnly || !_coverage.enabled}
      {...(currencyCode && {
        currencyCode: currencyCode
      })}
    />,
    <CellContentRateInput
      name="rateMultiplier"
      value={decimalToPercentage(_coverage.rateMultiplier) || ""}
      type="Percentage"
      onChange={updateCoverage}
      onBlur={() => {
        syncCoverage()
      }}
      readOnly={readOnly || !_coverage.enabled}
    />,
    isSplitCoverage && _coverage.annualisedPremium && (
      <CellContentMoney {..._coverage.annualisedPremium} />
    ),
    _coverage.premium ? (
      <CellContentMoney {..._coverage.premium} />
    ) : (
      <CellContentText align="right">-</CellContentText>
    )
  ]

  return (
    <Collapse
      disabled={!isSplitCoverage}
      collapsedPanel={
        <Coverage
          isSplitCoverage={isSplitCoverage}
          coverage={_coverage}
          readOnly={readOnly}
          updating={updating}
          onChange={onChange}
          reference={coverageReference}
          cells={
            !isSplitCoverage
              ? cells
              : [
                  _coverage.premium ? (
                    <CellContentMoney {..._coverage.premium} />
                  ) : (
                    <CellContentText align="right">-</CellContentText>
                  )
                ]
          }
          updateCoverPeriod={updateCoverPeriod}
          toggleCoverage={toggleCoverage}
          removeCoverage={removeCoverage}
          lockCoverage={lockCoverage}
        />
      }
      expandedPanel={
        isSplitCoverage && (
          <Coverage
            expanded
            isSplitCoverage
            coverage={_coverage}
            readOnly={readOnly}
            updating={updating}
            onChange={onChange}
            reference={coverageReference}
            cells={[
              _coverage.premium ? (
                <CellContentMoney {..._coverage.premium} />
              ) : (
                <CellContentText align="right">-</CellContentText>
              )
            ]}
            updateCoverPeriod={updateCoverPeriod}
            toggleCoverage={toggleCoverage}
            removeCoverage={removeCoverage}
            lockCoverage={lockCoverage}
          />
        )
      }
    >
      {isSplitCoverage && (
        <>
          <Row
            reference={<CellContentTitle>Cover Period</CellContentTitle>}
            values={[
              undefined,
              undefined,
              undefined,
              <CellContentTitle align="right">Annualised</CellContentTitle>,
              <CellContentTitle align="right">Prorated</CellContentTitle>
            ]}
          />
          <Row
            updating={updating}
            disabled={!_coverage.enabled}
            key={_coverage.id}
            reference={
              <CellContentCoverPeriod
                coverStartDateTime={_coverage.coverStartDateTime}
                coverEndDateTime={_coverage.coverEndDateTime}
              />
            }
            values={cells}
            control={
              !readOnly && (
                <>
                  {updating && <WorkingButton />}
                  {_coverage.enabled &&
                    _coverage.coverStartDateTime &&
                    _coverage.coverEndDateTime && (
                      <CoverageCoverPeriod
                        componentID={_coverage.id}
                        coverStartDateTime={_coverage.coverStartDateTime}
                        coverEndDateTime={_coverage.coverEndDateTime}
                        onChange={onChange}
                        onCoverPeriodChange={updateCoverPeriod}
                      />
                    )}
                </>
              )
            }
          />
        </>
      )}
    </Collapse>
  )
}

interface IRatedSimpleCoverage {
  sectionID: string
  subsectionID: string
  editSessionID?: string
  coverage: ICoverage
  onChange: (data: TPlatformResponse) => void
  readOnly?: boolean
  organisationSettings?: IOrgSettings
}

export default RatedSimpleCoverage
