import {
  Alert,
  Button,
  Card,
  Form,
  Input,
  notification,
  Select,
  Space,
  Table,
  Tag
} from "antd"
import {
  EOpusPermission,
  useOpusPermissions
} from "contexts/authorization/hooks/use-opus-permissions"
import usePlatform from "contexts/platform/use-platform"
import {
  IConfirmReferralQuoteProps,
  IDeclineReferralQuoteProps
} from "platform-client/client/controllers/quote"
import {
  IDeclineReason,
  IReferral,
  ReferralStatus
} from "platform-client/types"
import { IQuote, IQuoteBundle } from "platform-client/types/quote"
import { useState } from "react"
import { UseMutationResult, useQuery } from "react-query"
import styled from "styled-components"
import { OnUpdateReferral } from "../../view"

const ReferralStatusLabel = ({
  status
}: {
  status: ReferralStatus
}): JSX.Element => {
  switch (status) {
    case "Accepted":
      return <Tag color="green">Accepted</Tag>
    case "ActionRequired":
      return <Tag color="gold">Action Required</Tag>
    case "Declined":
      return <Tag color="red">Declined</Tag>
    case "Revoked":
      return <Tag color="gold">Revoked</Tag>
    default:
      return <></>
  }
}

const ActionRequiredOverlay = styled.div`
  position: absolute;
  top: 1px;
  left: 0;
  width: 100%;
  height: 100%;

  text-align: center;
  background: hsl(0 0% 99% / 0.85);
  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background: hsl(0 0% 98% / 0.15);
    backdrop-filter: blur(1px);
  }
  & > div {
    padding: 1.5em;
    padding-bottom: 5em;
    font-weight: bold;
  }
`

const ReviewV3: React.FC<IReviewV3Props> = ({
  referrals = [],
  isQuoteLoading,
  onUpdateReferral,
  confirmReferralQuote,
  declineReferralQuote,
  declines = [],
  ...props
}) => {
  const { product } = usePlatform()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [activeTab, setActiveTab] = useState<string>("approve")
  const canApproveQuote =
    referrals.filter((referral) => referral.status !== "Accepted").length === 0
  const [form] = Form.useForm<IDeclineReferralQuoteProps>()

  // If you have this permission it indicates that you are
  // an insurer not an agent. In general, as an insurer with this permission, you will
  // only be allowed to action quotes if they are in a referred state.

  const { havePermission: canProcessReferral } = useOpusPermissions(
    EOpusPermission.ProcessRefferal,
    "maintain"
  )

  const productDeclineReasons = useQuery(
    ["productDeclineReasons", props.quote.productID],
    () => product.getProductDeclineReasons(props.quote.productID.toString()),
    {
      onSuccess: () => {
        form.setFieldsValue({
          declineReasonReferenceID: "avt_d_custom"
        })
      }
    }
  )

  const hasReferrals = referrals && referrals.length > 0
  const hasDeclines = declines && declines.length > 0

  return (
    <Space direction="vertical" size="large" style={{ width: "100%" }}>
      {!hasReferrals && !hasDeclines && (
        <Alert type="info" message="No referrals or declines for this quote" />
      )}
      {hasReferrals && !hasDeclines && (
        <Card
          title="Referrals"
          bodyStyle={{
            padding: "0 16px 16px",
            backgroundColor: "#fafafa"
          }}
        >
          <Table
            loading={isQuoteLoading || isLoading}
            rowKey="id"
            size="middle"
            locale={{
              emptyText: "This quote currently has no referrals."
            }}
            columns={[
              {
                title: "Referral reason",
                dataIndex: "text",
                key: "referral"
              },
              {
                title: "Type",
                dataIndex: "modifierType",
                width: 150,
                align: "center"
              },
              {
                title: "Status",
                dataIndex: "status",
                width: 150,
                align: "center",
                render: (value: ReferralStatus) => (
                  <ReferralStatusLabel status={value} />
                )
              },
              {
                title: "",
                key: "actions",
                width: 200,
                align: "right",
                render: (value, record) => (
                  <>
                    {canProcessReferral && (
                      <Button
                        type="primary"
                        disabled={!(record.status === "ActionRequired")}
                        onClick={() => {
                          if (typeof onUpdateReferral === "function") {
                            setIsLoading(true)
                            onUpdateReferral(
                              {
                                quoteID: props.quote.id,
                                referral: record
                              },
                              () => {
                                setIsLoading(false)
                              }
                            )
                          }
                        }}
                      >
                        Accept Referral
                      </Button>
                    )}
                  </>
                )
              }
            ]}
            dataSource={referrals}
            pagination={false}
          />
        </Card>
      )}

      {hasDeclines && (
        <Card
          title="Declines"
          bodyStyle={{
            padding: "0 16px 16px",
            backgroundColor: "#fafafa"
          }}
        >
          <Table
            loading={isQuoteLoading || isLoading}
            rowKey="id"
            size="middle"
            locale={{
              emptyText: "This quote currently has no declines."
            }}
            columns={[
              {
                title: "Decline reason",
                dataIndex: "text",
                key: "declineReason"
              },
              {
                title: "Source",
                dataIndex: ["modifierOwner", "source"],
                width: 150,
                align: "center"
              }
            ]}
            dataSource={declines}
            pagination={false}
          />
        </Card>
      )}

      {canProcessReferral && props.quote.quoteState === "Referred" && (
        <Card
          tabList={[
            { key: "approve", tab: "Approve" },
            { key: "decline", tab: "Decline" }
          ]}
          bodyStyle={{ position: "relative" }}
          onTabChange={(key) => setActiveTab(key)}
          loading={isQuoteLoading || isLoading}
        >
          {activeTab === "approve" && (
            <>
              <Form
                layout="vertical"
                initialValues={{ quoteID: props.quote.id }}
                onFinish={(values: IConfirmReferralQuoteProps) => {
                  confirmReferralQuote?.mutateAsync(values).catch((error) =>
                    notification.error({
                      message:
                        "Unable to move quote to confirmed. Please check the review and try again."
                    })
                  )
                }}
                onFinishFailed={() =>
                  notification.error({
                    message:
                      "Unable to submit form. Please check the notes field and try again."
                  })
                }
              >
                <Form.Item name="quoteID" noStyle>
                  <Input
                    type="hidden"
                    value={props.quote.id}
                    disabled={!canApproveQuote}
                  />
                </Form.Item>
                <Form.Item name="note" label="Review notes:">
                  <Input.TextArea rows={6} disabled={!canApproveQuote} />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={confirmReferralQuote?.isLoading}
                    disabled={!canApproveQuote}
                  >
                    Approve quote
                  </Button>
                </Form.Item>
              </Form>
              {!canApproveQuote && (
                <ActionRequiredOverlay>
                  <div>
                    Unable to approve this quote as there are referrals
                    requiring action.
                  </div>
                </ActionRequiredOverlay>
              )}
            </>
          )}

          {activeTab === "decline" && (
            <Form
              form={form}
              layout="vertical"
              initialValues={{
                quoteID: props.quote.id
              }}
              onFinish={(values: IDeclineReferralQuoteProps) => {
                declineReferralQuote?.mutateAsync(values).catch((error) =>
                  notification.error({
                    message:
                      "Unable to decline quote. Please check the review and try again."
                  })
                )
              }}
              onFinishFailed={() =>
                notification.error({
                  message:
                    "Unable to submit form. Please check the notes field and try again."
                })
              }
            >
              <Form.Item name="quoteID" noStyle>
                <Input type="hidden" value={props.quote.id} />
              </Form.Item>
              <Form.Item
                name="declineReasonReferenceID"
                label="Decline reason:"
                required
                rules={[
                  {
                    required: true,
                    message: "You must select a decline reason."
                  }
                ]}
              >
                <Select
                  showSearch
                  size="large"
                  loading={productDeclineReasons.isLoading}
                  options={productDeclineReasons.data?.map((item) => {
                    return {
                      label: item.text,
                      value: item.referenceID
                    }
                  })}
                  filterOption={(value, option) =>
                    String(option?.label)
                      .toLowerCase()
                      .indexOf(value.toLowerCase()) >= 0
                  }
                />
              </Form.Item>
              <Form.Item name="note" label="Decline notes:">
                <Input.TextArea rows={6} />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={declineReferralQuote?.isLoading}
                >
                  Decline quote
                </Button>
              </Form.Item>
            </Form>
          )}
        </Card>
      )}
    </Space>
  )
}

export default ReviewV3

interface IReviewV3Props {
  quote: IQuote
  isQuoteLoading?: boolean
  referrals?: IReferral[]
  declines: IDeclineReason[]
  onUpdateReferral?: OnUpdateReferral
  canProcessReferral: boolean
  confirmReferralQuote?: UseMutationResult<
    IQuoteBundle,
    unknown,
    IConfirmReferralQuoteProps
  >
  declineReferralQuote?: UseMutationResult<
    IQuoteBundle,
    unknown,
    IDeclineReferralQuoteProps
  >
}
