import { RatingsBoundary } from "./boundary"
import { RatingsList } from "./views/List"
import { RatingsUpload } from "./views/Upload"
export const VList: React.FC = (props) => (
  <RatingsBoundary>
    <RatingsList {...props} />
  </RatingsBoundary>
)

export const VRatingsUpload: React.FC = (props) => (
  <RatingsBoundary>
    <RatingsUpload {...props} />
  </RatingsBoundary>
)
